import {useLocation} from 'react-router-dom'
import {downloadApp} from '../../../Utils/utils'
import {searchUrl} from '../../../Utils/constants'

import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import icPillCalendar from '../../../assets/img/i_pill_calendar.svg'
import imgResultError from '../../../assets/img/i_error.svg'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setPrescriptions} from '../../../redux/modules/app'
import {useHistory} from 'react-router'

function SubscriptionResult() {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const result = params.get('result')
  const history = useHistory()

  const onKeepShopping = () => {
    if (result === 'success') {
      history.push('/my_shopping')
    } else {
      window.location.href = searchUrl
    }
  }

  useEffect(() => {
    dispatch(setPrescriptions([]))
  }, [])

  return (
    <div className="content-responsive mx-auto px-3 mb-5">
      {result === 'error' ? (
        <div className={`maxw-300 card-messege mx-auto mt-5 mb-4`}>
          <img
            id={'gtm-webapp-checkout-result-img-error'}
            src={imgResultError}
            alt="Success"
            className="mb-4 img-message"
          />
          <h4 className={`txt-1--bold-db mb-3 text-center`}>
            No hemos procesado tu inscripción al convenio con éxito...
          </h4>
          
        </div>
      ) : (
        <div className="maxw-436 mx-auto mt-5">
          <div className="simple-card pt-5 pb-4 px-3 mb-3">
            <p className="txt-1--bold-db mb-4 text-center">
              ¡Tu inscripción al convenio ha sido exitosa!
            </p>
            <div className="d-flex justify-content-center mb-4">
              <img
                src={icPillCalendar}
                style={{width: '8.75rem', height: '6.75rem'}}
                alt="Calendario"
              />
            </div>
            <p className="txt-5--regular-db text-center mb-3 px-4">
              El despacho se cobrará en cada envío y el costo total se realizará{' '}
              <span className="txt-5--bold-db">48 hrs antes</span> de cada
              compra.
            </p>
            <p className="txt-5--regular-db text-center mb-5 px-4">
              Para gestionar tus compra, ingresa a la App de{' '}
              <span className="txt-5--bold-db">Yapp</span>
            </p>
            <ButtonYapp
              className="mx-auto size-content-responsive-btn w-100"
              onClick={downloadApp}
              color="--theme-color"
              title={'Ir a la App'}
            />
          </div>
        </div>
      )}
      <ButtonYapp
        className="mx-auto size-content-responsive-btn w-100"
        onClick={onKeepShopping}
        color="--theme-color"
        title={result === 'success' ? 'Ir a Tus compras' : 'Volver al inicio'}
        stroke
      />
    </div>
  )
}

export default SubscriptionResult
