import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import { existUserClient, getTokenValidation } from '../../Utils/api'
import { signOut } from '../../Utils/auth'
import { getJWTdata } from '../../Utils/tools'
import { logEvent } from '../../Utils/utils'
import { clearUserState, setBasicInfo } from '../../redux/modules/user'
import { clearAppState, changeInitialLoading, setEnabledInsurance, setFormData } from '../../redux/modules/app'
import {setFreeShipping, setDeliveryCode} from '../../redux/modules/delivery'

const clientName = process.env.REACT_APP_CLIENT_NAME  || 'Yapp Chile';
const jwtClients = {
  biceVida: 'Bice Vida',
  vidaSecurity: 'Vida Security'
}

function LoginJwt(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    loginByToken()
  },[location])

  const loginByToken = async () => {
    const biceVidaJwt = new URLSearchParams(location.search).get('bv-token');
    const vidaSecurityJwt = new URLSearchParams(location.search).get('vs-token');
    dispatch(changeInitialLoading(true));
    dispatch(clearUserState());
    dispatch(clearAppState());
    let invalidReason = 'Empty token';
    let nextPage = '/no-access';
    let jwt = null;

    if (biceVidaJwt && clientName === jwtClients.biceVida) {
      jwt = getJWTdata(biceVidaJwt);
    }
    else if (vidaSecurityJwt && clientName === jwtClients.vidaSecurity) {
      jwt = getJWTdata(vidaSecurityJwt);
    }

    if (jwt) {
      await signOut();
      let isTokenValid = await validateJwt(jwt, biceVidaJwt || vidaSecurityJwt);
      if (isTokenValid) {
        let currentIdentity = jwt.rut;

        let response = await existUserClient(currentIdentity);
        if (response?.data?.length > 0) {
          sessionStorage.setItem('USER_DATA', JSON.stringify(response.data[0]));
          setUserData(response.data[0]);
          setDeliveryData(response.data[0]);
          dispatch(changeInitialLoading(false));
          sendLogEvent(true, 'Valid token');
          nextPage = '/signin';
        }
        else {
          invalidReason = 'Invalid user';
        }
      }
      else {
        invalidReason = 'Invalid token';
      }
    }

    if (nextPage === '/no-access') {
      sendLogEvent(false, invalidReason, biceVidaJwt || vidaSecurityJwt);
    }
    dispatch(changeInitialLoading(false));
    history.push(nextPage);
  }

  const validateJwt = async (jwt, token) => {
    let isValid = false;

    if (clientName === jwtClients.biceVida) {
      const apiValidation = await getTokenValidation('bv', token);
      isValid = apiValidation?.data == 'Token is valid!';
    }
    else if (clientName === jwtClients.vidaSecurity) {
      let dateNow = new Date().getTime();
      let expired = false;

      if (!(jwt.exp >= dateNow && jwt.iat <= dateNow &&
        (jwt.nbf <= dateNow || jwt.auth_time <= dateNow))) {
        expired = true;
      }

      if (!expired && jwt.iss === 'https://sso.security.cl/auth/realms/VS-Clients' && jwt.aud === 'account'
        && jwt.rut && jwt.resource_access?.account?.roles?.includes('view-profile')) {
        isValid = true;
      }
    }

    return isValid;
  }

  const setDeliveryData = (data) => {
    dispatch(
      setDeliveryCode({
        code: data?.delivery_code,
        value: data?.delivery_value,
      }),
    )

    dispatch(
      setFreeShipping({
        code: data?.free_shipping,
        value: data?.free_shipping_value,
      }),
    )
  }

  const setUserData = (data) => {
    dispatch(setEnabledInsurance(true))

    props.setFormData({
      ...props.formData,
      full_name: { valid: true, value: data.name || '' },
      titular_identity: { valid: true, value: '' },
      rut: { valid: true, value: data.identity || '' },
      location_name: { valid: true, value: data.location_name || '' },
      location_number: { valid: true, value: data.location_number || ''},
      address_additional: { valid: true, value: data.address_additional || '' },
      phone: { valid: true, value: data.phone || '' },
      email: { valid: true, value: data.email || '' },
      checkTerms: { valid: true, value: data.check_terms || false },
      discount: { valid: true, value: '' },
    });
    dispatch(setBasicInfo(data.email || null, data.identity));
  }

  const sendLogEvent = (valid, details, token = '') => {
    logEvent('Session Started', {
      'Validated Insurance Policy': valid ? 'TRUE' : 'FALSE',
      'Insurance Type': 'Api',
      'Token': valid ? 'OK' : token,
      'Result': details
    });
  }

  return (
    <div style={{ paddingTop: '30px', textAlign: 'center', color: 'var(--theme-color)' }}>
      <h5>Espera un momento...</h5>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: state.app?.formData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFormData: (formData) => dispatch(setFormData(formData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginJwt)
