import React from 'react'
import {
  View,
  Text,
  Document,
  Page,
  Font,
  Image,
  Svg,
  Path,
} from '@react-pdf/renderer'
import QRCode from 'qrcode-svg'
import {productsUrl} from '../../Utils/constants'
import {_formatPrice} from '../../Utils/tools'
import NunitoRegular from '../../assets/fonts/Nunito-Regular.ttf'
import NunitoBold from '../../assets/fonts/Nunito-Bold.ttf'
import NunitoLight from '../../assets/fonts/Nunito-Light.ttf'
import Truck from '../../assets/img/truckimage.png'

Font.registerHyphenationCallback((word) => [word])

Font.register({
  family: 'Nunito',

  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'light',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoLight,
      fontWeight: 'ultralight',
    },
  ],
})

function AgreementPDF(props) {
  const {product} = props
  const qrcode = new QRCode({
    content: props.link,
    width: 110,
    height: 110,
    join: true,
    ecl: 'Q',
    container: 'svg-viewbox',
  })
  const qrCodeSvgString = qrcode.svg({container: 'none'})
  const pathStartIndex = qrCodeSvgString.indexOf(' d="') + 4

  const pathEndIndex = qrCodeSvgString.indexOf('"', pathStartIndex)
  const qrCodeSvgPathString = qrCodeSvgString.slice(
    pathStartIndex,
    pathEndIndex,
  )

  const elmexAntiText =
    'Tecnología avanzada para tener dientes saludables toda la vida. Elmex anticaries previene la caries y el envejecimiento precoz de tus dientes'

  const elmexSensiText =
    'Tecnología avanzada para tener dientes saludables toda la vida. Elmex sensitive te otorga alivio inmediato y prolongado contra la sensibilidad'

  const periogardText =
    'Ayuda a prevenir el sangrado de encías, placa bacteriana y gingivitis. Recomendamos usar productos especializados para el cuidado de tus encías'

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 20,
          paddingHorizontal: 16,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 100,
            height: 32,
            marginTop: 4,
            marginHorizontal: 12,
            marginBottom: 4,
            paddingVertical: 2,
            paddingHorizontal: 2,
          }}
          fixed>
          <Image
            style={{width: 94, height: 33, marginBottom: 10}}
            src="https://s3.us-east-2.amazonaws.com/s3.yapp/logo-yapp.png"
          />
        </View>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 24,
          }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              lineHeight: 1.9,
              marginBottom: 8,
            }}>
            Ahorra con este beneficio que te ha compartido tu odontólogo
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Nunito',
              fontWeight: 400,
              lineHeight: 1.6,
              marginBottom: 24,
            }}>
            Aprovecha este descuento y compra tu producto al mejor precio en
            Yapp.
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              maxWidth: 768,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 8,
              backgroundColor: 'white',
              flexDirection: 'row',
              borderWidth: 1.5,
              borderStyle: 'solid',
              borderColor: '#dde0ec',
            }}>
            <View style={{display: 'flex', alignItems: 'center'}}>
              <Image
                style={{
                  width: 200,
                  marginRight: 18,
                }}
                source={{
                  uri: productsUrl + product.product_img,
                  method: 'GET',
                  headers: {'Cache-Control': 'no-cache'},
                  body: '',
                }}
              />
            </View>
            <View style={{display: 'flex'}}>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  maxWidth: 250,
                  lineHeight: 1.4,
                }}>
                {product.product_name}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  maxWidth: 250,
                  lineHeight: 1.4,
                }}>
                {product.laboratory_name}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  lineHeight: 1.4,
                  marginTop: 6,
                }}>
                Precio por unidad
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 2,
                }}>
                <Text
                  style={{
                    flexWrap: 'wrap',
                    fontSize: 14,
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    maxWidth: 364,
                    lineHeight: 1.4,
                    paddingRight: 16,
                  }}>
                  {_formatPrice(product.discount_price)}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    backgroundColor: '#6578ff',
                    maxWidth: 364,
                    lineHeight: 1.4,
                    color: '#fff',
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: 2,
                  }}>
                  -40%
                </Text>
              </View>
              <Text
                style={{
                  flexWrap: 'wrap',
                  fontSize: 14,
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  maxWidth: 364,
                  lineHeight: 1.4,
                  paddingRight: 16,
                  textDecoration: 'line-through',
                }}>
                {_formatPrice(product.list_price)}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Image
                  style={{
                    marginVertical: 15,
                    width: 31,
                  }}
                  src={Truck}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: 'Nunito',
                    fontWeight: '400',
                    marginTop: 12,
                    maxWidth: 454,
                    marginLeft: 6,
                    lineHeight: 1.4,
                  }}>
                  Despacho en menos de 4 días
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 12,
          }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontFamily: 'Nunito',
              lineHeight: 1.6,
              textAlign: 'left',
              paddingHorizontal: 16,
              marginBottom: 35,
              maxWidth: 520,
              marginTop: 30,
            }}>
            {product?.product_family === 'elmex anticaries'
              ? elmexAntiText
              : null}
            {product?.product_family === 'elmex sensitive'
              ? elmexSensiText
              : null}
            {product?.product_family === 'periogard' ? periogardText : null}
          </Text>
          <View
            style={{
              marginBottom: 12,
            }}>
            <Svg
              style={{
                display: 'flex',
                width: 110,
                height: 110,
                alignItems: 'center',
              }}>
              <Path d={qrCodeSvgPathString} fill="black" />
            </Svg>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default AgreementPDF
