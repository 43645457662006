import React from 'react'
import Modal from 'react-modal'
import { deliveryFrequencies } from '../../Utils/constants'

function ModalBottomSimple(props) {
  const {showModal, setShowModal, onChange, currentFrequency} = props

  function setFrequency(frequency) {
    setShowModal(false)
    onChange(frequency)
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        className="modal-from-bottom p-0"
        closeTimeoutMS={500}
        onRequestClose={() => setShowModal(false)}
        overlayClassName="modal-custom-bg modal-bottom-transition">
          <div className="p-3">
            <p className="txt-5--bold-db">Recibir cada</p>
          </div>
          <hr className="hr-bottom-gray-2 m-0" />
          <div style={{maxHeight: '10rem', overflowY: 'auto'}}>
          { deliveryFrequencies.map((frequency, index) => (
            <div onClick={ () => setFrequency(frequency)} style={{height: '2.5rem'}} key={ index.toString() }
              className={ "d-flex justify-content-center py-2 hr-bottom-gray-2" + (frequency?.value === currentFrequency?.value ? " modal-selection-active" : "")}>
              <div className="d-flex align-items-center">
                <p className="txt-5--regular-db">{ frequency?.label || '' }</p>
              </div>
            </div>
          ))}
          </div>
      </Modal>
    </>
  )
}

export default ModalBottomSimple
