import {useState, memo} from 'react'
import StepperLib from 'react-stepper-primitive'
import icSub from '../assets/img/ic_sub.png'
import icAdd from '../assets/img/ic_add.png'

function Stepper(props) {
  const [counter, setCounter] = useState(0)

  return (
    <StepperLib
      min={0}
      max={props.max ?? (['Sura', 'Vida Security'].includes(process.env.REACT_APP_CLIENT_NAME)? 3 : 6 )}
      value={props.counterType === 'internal' ? counter : props.counter}
      onChange={(value) => {
        if (props.counterType === 'internal') {
          setCounter(value)
        } else {
          props?.clickUpdateCounter(value)
        }
      }}
      render={({
        getFormProps,
        getInputProps,
        getIncrementProps,
        getDecrementProps,
      }) => (
        <form
          className={`d-flex align-items-start mt-2 ${props.className}`}
          {...getFormProps()}>
          <button
            className="btn-stepper btn-stepper--left"
            {...getDecrementProps()}>
            -
          </button>
          <input
            className="input-stepper"
            onChange={() => {}}
            {...getInputProps()}
          />
          <button
            className="btn-stepper btn-stepper--right"
            {...getIncrementProps()}>
            +
          </button>
        </form>
      )}
    />
  )
}

export default memo(Stepper)
