import icErrorHash from '../../assets/img/error_hash.svg'

function NoAccess() {
  return (
    <div className="wrapper-location">
      <div className="d-flex flex-column justify-content-center align-items-center mb-5 pt-5">
        <img
          src={icErrorHash}
          alt="Error"
          className="mb-35"
          id="gtm-webapp-error-no-access"
        />
        <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
        <p className="title-primary--xl mb-30">
          No puedes acceder a este sitio.
        </p>
      </div>
    </div>
  )
}

export default NoAccess
