import { useEffect, useState, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Modal from 'react-modal'
import { getHash, _formatPrice } from '../../../Utils/tools'
import { setUserExtraInfo } from '../../../redux/modules/user'
import { getShoppingCart, saveShoppingCart } from '../../../Utils/service'
import ModalMessage from '../../../Components/Modal.Message'
import StepperLocal from '../../../Components/Stepper'
import ProductSearch from '../../../Components/ProductSearch'
import CommunesSearch from '../../../Components/CommunesSearch'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import SearchTypeToggle from '../../../Components/Search/SearchTypeToggle'

import { changeLogo, showLogo, setThemeColor } from '../../../redux/modules/app'

import loadingYapp from '../../../assets/img/yapp_loading.gif'
import productImg from '../../../assets/img/prescription.png'
import minsalUso from '../../../assets/img/minsal-uso-racional.png'
import minsalVenta from '../../../assets/img/minsal_ventas_directas.png'

import { IconTutorial } from '../icTutorial'
import { IconTutorialRefund } from '../icTutorialRefund'
import { IconTutorialMobile } from '../icTutorialMobile'
import { IconTutorialRefundMobile } from '../icTutorialRefundMobile'
import { addEventNameWithParams, cleanText, logEvent } from '../../../Utils/utils'
import { ENUM_MENU, suraBenefitsPublicUrl } from '../../../Utils/constants'
import parse from 'html-react-parser'
import ModalTeledocSelectProduct from '../../../Components/Modals/ModalTeledocSelectProduct'
import ModalPreview from '../../../Components/Modals/ModalPreview'
import BadgesRefundable from '../../../Components/Badges/BadgesRefundable'
import Tutorial from '../../../Components/Tutorial/Tutorial'

import firstStep from '../../../assets/img/tutorial_sura1(2).png'
import secondStep from '../../../assets/img/tutorial_sura2.png'
import thirdStep from '../../../assets/img/tutorial_sura3.png'
import firstInfoSura from '../../../assets/img/info-sura1.png'
import secondInfoSura from '../../../assets/img/info-sura2.png'
import thirdInfoSura from '../../../assets/img/info-sura3.png'
import ModalAlertLocation from '../../../Components/Modals/ModalAlertLocation'
import { fetchCommunes } from '../../../redux/modules/delivery'
import TypeSidetab from '../../../Components/TypeForm/TypeSidetab'
import SectionBenefitCards from '../../../Components/BenefitCards/SectionBenefitCards'
import farmaciaFacil from '../../../assets/img/farmacia-facil.png'

const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes'
const _ = require('lodash')

function ShoppingBag(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const modalRef = useRef()

  const [appState, setAppState] = useState({ loading: true, data: [] })
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [show, setShow] = useState({ status: false })

  const [productDelete, setProductDelete] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [visibleAlertLocation, setVisibleAlertLocation] = useState(false)

  const [modalTeledoc, setModalTeledoc] = useState(false)
  const [productsWithSuraBenefits, setProductsWithSuraBenefits] = useState([])
  const [changingCommune, setChangingCommune] = useState(false)

  const themeColor = useSelector((state) => state.app.themeColor)
  const onePharmacy = useSelector((state) => state.app.one_pharmacy)
  const isProHash = useSelector((state) => state.app.isPro)
  const refundElement = useSelector((state) => state.app.refund_element)
  const userExtraInfo = useSelector((state) => state.user.userExtraInfo)
  const [showPreview, setShowPreview] = useState(false)
  const handleClose = () => setShowPreview(false)
  const handleShow = () => setShowPreview(true)
  const enabledInsurance = useSelector((state) => state.app?.enabledInsurance)
  const textExtraInfoSearch = useSelector(
    (state) => state.app.text_extra_info_search,
  )

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function clickShowDetail(productId, product) {
    addEventNameWithParams('gtm-shopping-bag-show-detail', {
      product_id: product?.product_id,
    })
    history.push(`/product/detail/${productId}`, {
      product_id: productId,
      external_vademecum: 0,
      resultSearch: product?.resultSearch,
    })
  }

  const [isDesktopWidth, setIsDesktopWidth] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      setLoadingProducts(true)
      let arrFirestore = await getShoppingCart()
      setAppState({ loading: false, data: arrFirestore })
      setLoadingProducts(false)

      if (userExtraInfo?.alternatives?.products) {
        let alternatives = []
        let newAlternative

        for (let item of userExtraInfo.alternatives.products) {
          let foundIndex = -1
          if (alternatives.length > 0) {
            foundIndex = alternatives.findIndex((i) => i.id === item.formula_id)
            if (foundIndex >= 0) {
              alternatives[foundIndex].products.push(item)
            }
          }

          if (foundIndex === -1) {
            newAlternative = {
              id: item.formula_id,
              name: item.formula_name,
              selectedProduct: null,
              products: [item],
            }
            alternatives.push(newAlternative)
          }
        }

        setAlternatives(alternatives)
        setModalTeledoc(true)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (props.disabledThemeCheckout === true) {
      dispatch(changeLogo(props.clientLogo))
      dispatch(showLogo(true))
      dispatch(setThemeColor(props.themeColor))
    }
  }, [])

  useEffect(() => {
    try {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    } catch (error) {
      console.log(error)
    }
  }, [isDesktopWidth])

  const handleWindowSizeChange = () => {
    setIsDesktopWidth(window.innerWidth < 768)
  }

  const clickUpdateCounter = async (element, value, confirm = false) => {
    let arrFirestore = await getShoppingCart()
    let newArr = []
    if (value === 0) {
      setProductDelete(element)
      openModal()
      if (confirm === true) {
        newArr = arrFirestore.filter((el) => {
          if (el.product_id !== element.product_id) {
            return el
          }
        })

        saveShoppingCart(newArr)
        setAppState({ data: newArr })
        closeModal()
      }
    } else {
      newArr = arrFirestore.map((el) => {
        if (el.product_id === element.product_id) {
          el.counter = value
        }

        return el
      })

      saveShoppingCart(newArr)
      setAppState({ data: newArr })
    }
  }

  const updateShoppingCart = async () => {
    let shoppingCart = await getShoppingCart()
    let currentUserInfo = Object.assign({}, userExtraInfo)
    currentUserInfo.alternatives = null

    for (const item of alternatives) {
      if (item.selectedProduct !== 'none')
        shoppingCart.push(item.selectedProduct)
    }

    saveShoppingCart(shoppingCart)
    setAppState({ data: shoppingCart })
    dispatch(setUserExtraInfo(currentUserInfo))
  }

  /*   const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    }; */

  async function actionNext() {
    await saveShoppingCartTag()

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })

    // let enableBeneficiaries = process.env.REACT_APP_PROFILE === 'INSURANCE'

    // if (enableBeneficiaries) {
    //   history.push('/cia/beneficiaries')
    // } else {
    history.push('/shopping_bag/quotation')
    // }
  }

  const onClickSearch = async (newValue) => {
    if (newValue?.value) {
      // let path =
      //   '/search/shopping_bag/' +
      //   Math.floor(Math.random() * Math.floor(100000000000000000000))
      // history.replace(path, { product: newValue, from: 'shopping_bag' })
      setLoadingProducts(true)
      let arrFirestore = await getShoppingCart()
      let product = newValue

      if (Array.isArray(arrFirestore) && product) {
        if (
          arrFirestore.find((el) => el.product_id === product.value) ===
          undefined
        ) {
          product.data.counter = 1
          arrFirestore.push(product.data)
          saveShoppingCart(arrFirestore)
        }
      }

      setAppState({ loading: false, data: arrFirestore })
      setLoadingProducts(false)
    } else {
      console.log('Error value')
    }
  }

  function bioequivalentReplace(props) {
    let path =
      '/search/bioequivalent/' +
      Math.floor(Math.random() * Math.floor(100000000000000000000))
    history.push(path, { productSelect: props })
  }

  async function saveShoppingCartTag() {
    let shoppingCart = await getShoppingCart()
    if (shoppingCart) {
      let productQuantity = 0

      for (const product of shoppingCart) {
        productQuantity += product.counter
      }

      logEvent('Product Added', {
        'Total Cart Size': productQuantity,
      })
    }
  }

  useEffect(() => {
    if (getHash()) {
      dispatch(fetchCommunes())
    }
    getProductsWithSuraBenefits()
  }, [])

  const [direction, setDirection] = useState(window.innerWidth)
  useEffect(() => {
    if (direction !== window.innerWidth) {
      setDirection(window.innerWidth)
    }
  }, [direction])

  const listener = () => setDirection(window.innerWidth)
  window.addEventListener('resize', listener)

  const getProductsWithSuraBenefits = async () => {
    try {
      if (productsWithSuraBenefits.length == 0) {
        let result = await fetch(suraBenefitsPublicUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        let data = await result.json();
        setProductsWithSuraBenefits(data)
      }
    }
    catch (error) {
      console.log('GetProductsWithSuraBenefits Error')
    }
  }

  const showProductBadge = (product) => {
    let badge = null

    if (![null, undefined].includes(product?.id)
      && (process.env.REACT_APP_PROFILE === 'INSURANCE' || enabledInsurance)
    ) {
      if (clientName === 'Vida Security') {
        badge = <BadgesRefundable label="Reembolsable" color={process.env.REACT_APP_CLIENT_BENEFIT_COLOR} />
      } else {
        badge = <BadgesRefundable label="Reembolsable" color={process.env.REACT_APP_THEME_PRIMARY_COLOR} />
      }
    } 
    else if ((product?.client_benefit)
      && (![null, undefined, 'FALSE'].includes(process.env.REACT_APP_CLIENT_BENEFIT))) {
      badge = (
        <BadgesRefundable
          label={process.env.REACT_APP_CLIENT_BENEFIT}
          color={process.env.REACT_APP_CLIENT_BENEFIT_COLOR}
          dataTip="Beneficio de hasta 50% de descuento"
        />
      )
    } 
    else if (clientName === 'Vida Security' && !product.id) {
      badge = <BadgesRefundable label="Sin reembolso online" color={process.env.REACT_APP_CLIENT_BENEFIT_COLOR_SECUNDARY} />
    } 
    else if (clientName === 'Bice Vida' && !product.id) {
      badge = <BadgesRefundable label="No reembolsable" color={process.env.REACT_APP_THEME_SECONDARY_COLOR} />
    }

    return badge
  }

  return (
    <>
      <ModalMessage ref={modalRef} />
      {/* Modal Delete product */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        {productDelete && (
          <>
            <h4 className="txt-3--bold-db text-center mt-3">
              ¿Deseas eliminar este producto de tu carrito?
            </h4>
            <div className="d-flex flex-column justify-content-center mb-5">
              <img
                src={
                  productDelete?.product_logo
                    ? productDelete.product_logo
                    : productImg
                }
                alt="titulo del producto"
                className="modalt-img"
              />
              <p className="modal-text-product text-center">
                {productDelete?.product_name}
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <ButtonYapp
                className="w-100 mb-3"
                id="gtm-webapp-shopping-bag-btn-delete"
                onClick={() => clickUpdateCounter(productDelete, 0, true)}
                color={'--theme-color'}
                title="Eliminar"
              />
              {/* <Button
                id="gtm-webapp-shopping-bag-btn-delete"
                className="btn-default--small btn-next mb-3"
                variant="primary"
                onClick={() => clickUpdateCounter(productDelete, 0, true)}>
                Eliminar
              </Button> */}
              <ButtonYapp
                className="w-100 mb-3"
                id="gtm-webapp-shopping-bag-btn-leave"
                onClick={closeModal}
                stroke
                color={'--theme-color'}
                title="Dejar en el carrito"
              />
              {/*  <Button
                id="gtm-webapp-shopping-bag-btn-leave"
                className="btn-default--small btn-next btn-stroke"
                variant="primary"
                onClick={closeModal}>
                Dejar en el carrito
              </Button> */}
            </div>
          </>
        )}
      </Modal>

      {isProHash && (
        <>
          <div className="d-flex justify-content-between py-3 px-3">
            <div
              className="content-responsive mx-auto breadcrumbs c-pointer"
              onClick={() => history.push('/pro/menu')}>
              <a className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse"></i>
              </a>
              <span className="title-primary--regular line-14">Volver</span>
            </div>
          </div>
        </>
      )}
      <div
        className="flex-grow-1 pb-4"
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-responsive mt-2">
          <div className="d-flex justify-content-center">
            <SearchTypeToggle
              medicaments={true}
              examsOnClick={() => {
                logEvent('Medical Exam Opened')
                history.push('/search/exam')
              }}
            />
          </div>

          { clientName === 'Bice Vida' && (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img src={farmaciaFacil} class={"mw-100"} alt="Farmacia fácil" />
            </div>
          )}

          <div>
            {process.env.REACT_APP_PROFILE === 'INSURANCE' ? (
              <div
                className={`d-flex justify-content-center align-items-center ${direction >= 415 ? 'flex-row mt-3 mb-4' : 'flex-column mt-3'
                  }`}>
                <Tutorial
                  className={`pt-1 ${direction >= 766
                    ? 'tutorial-item'
                    : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                    }`}
                  src={firstStep}
                  alt={'Primer paso, cotizar medicamentos'}
                  step={1}
                  caption={'Cotiza tus medicamentos en distintas farmacias'}
                />
                <Tutorial
                  className={`${direction >= 766
                    ? 'tutorial-item'
                    : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                    }`}
                  src={secondStep}
                  alt={'Segundo paso, compra con reembolso en linea'}
                  step={2}
                  caption={'Compra con reembolso en línea'}
                />
                <Tutorial
                  className={`pt-1 ${direction >= 766
                    ? 'tutorial-item'
                    : direction > 415
                      ? 'tutorial-item-tablet'
                      : 'tutorial-item-mobile'
                    }`}
                  src={thirdStep}
                  alt={'Tercer paso, recibir en casa'}
                  step={3}
                  caption={'Recíbelos en tu casa'}
                />
              </div>
            ) : (
              <div className="text-center ic-tutorial">
                {![null, undefined, false].includes(refundElement) ? (
                  <>
                    <IconTutorialRefund
                      className="ic-tutorial--item mt-3"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : '#282E55'
                      }
                    />
                    <IconTutorialRefundMobile
                      className="ic-tutorial--small-mobile"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : '#282E55'
                      }
                    />
                  </>
                ) : (
                  <>
                    <IconTutorial
                      className="ic-tutorial--item"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : '#282E55'
                      }
                    />
                    <IconTutorialMobile
                      className="ic-tutorial--small-mobile"
                      fill={
                        themeColor && themeColor.icon_tutorial
                          ? themeColor.icon_tutorial
                          : '#282E55'
                      }
                    />
                  </>
                )}
              </div>
            )}
            {![null, undefined, ''].includes(textExtraInfoSearch) && (
              <div className="mb-4">
                <p className="txt-5--regular mt-3">
                  {parse(textExtraInfoSearch)}
                </p>
              </div>
            )}
            <div className="">
              <div className="mb-2">
                {/* <Location /> */}
                <CommunesSearch styleSecondary setChangingCommune={setChangingCommune} />
              </div>
              <ModalTeledocSelectProduct
                visible={modalTeledoc}
                setVisible={setModalTeledoc}
                alternatives={alternatives}
                setAlternatives={setAlternatives}
                updateShoppingCart={updateShoppingCart}
              />

              <ProductSearch
                themeColor={'--theme-color'}
                styleSecondary
                onlyProducts={false}
                onClickSearch={onClickSearch}
                from="search"
                changingCommune={changingCommune}
                request_from={ENUM_MENU.medication_buy}
              />
            </div>
          </div>
        </div>

        {/** Mobile */}
        <div className="content-responsive mb-4 mt-2">
          {appState.data.length > 0 && !loadingProducts && (
            <h5 className="txt-4--bold-db">{ clientName === 'Bice Vida' ? 'Tus medicamentos para cotizar' : 'Tu carrito' }</h5>
          )}
          <div className="d-flex flex-wrap bag-content pt-2 mb-4">
            {appState.data && !loadingProducts ? (
              appState.data?.map((el, key) => {
                return (
                  <div
                    key={key}
                    className="product-detail-new mb-2 c-pointer position-relative">
                    {showProductBadge(el)}
                    <div
                      id="gtm-webapp-mobile-product-detail"
                      // onClick={() => clickShowDetail(el.product_id)}
                      className="">
                      <div className="product-detail-new-img text-center">
                        <img
                          src={el.product_logo}
                          className="product-detail-img mx-auto"
                          alt={el.product_name}
                          onClick={() => setShowPreview(true)}
                        />
                        <ModalPreview
                          show={showPreview}
                          onClose={handleClose}
                          img={el.product_logo}
                        />
                      </div>
                      <div
                        className="content-txt mb-3"
                        onClick={() => clickShowDetail(el.product_id)}>
                        <p className="txt-4--regular-db mt-2 mb-2  text-ellipsis--2">
                          {cleanText(el.product_name)}
                        </p>
                        <p className="txt-paragraph line-14">
                          {cleanText(el.presentation)}
                        </p>
                        <p className="txt-paragraph line-14">
                          {cleanText(el.laboratory_name)}
                        </p>
                      </div>
                    </div>
                    <StepperLocal
                      className="mt-auto justify-content-center"
                      counter={el.counter}
                      style={localStyles.stepper}
                      clickUpdateCounter={(event) => {
                        clickUpdateCounter(el, event)
                      }}
                    />
                    <div className="d-flex justify-content-center mt-3">
                      <p
                        id="gtm-webapp-shoping-bag-bioequivalent-mobile"
                        className="txt-5--bold color-theme-secondary text-center"
                        onClick={() => bioequivalentReplace(el)}>
                        Ver alternativas similares
                      </p>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="d-flex justify-content-center w-100 py-5">
                <img src={loadingYapp} alt="loading" height={70} />
              </div>
            )}
          </div>
          {!loadingProducts && appState.data?.length > 0 && (
            <div className="d-flex justify-content-center mt-2">
              <ButtonYapp
                className="px-5"
                id="gtm-webapp-shopping-bag-btn-next"
                color={'--theme-color'}
                title={
                  ![undefined, null, false].includes(onePharmacy)
                    ? 'Cotizar'
                    : 'Cotizar en farmacias'
                }
                onClick={() => {
                  if (!props.commune) {
                    setVisibleAlertLocation(true)
                  } else {
                    actionNext()
                  }
                }}
              />
            </div>
          )}
          <SectionBenefitCards products={productsWithSuraBenefits} />
        </div>

        {clientName === 'Vida Security' && (
          <TypeSidetab
            id='jjdc5aSF'
            buttonColor={'#282E55'}
            buttonText={'Sugerencias'}
          />
        )}
        {clientName === 'Sura' && (
          <TypeSidetab
            id='wea0XraP'
            buttonColor={'#0033A0'}
            buttonText={'Sugerencias!'}
          />
        )}

        {process.env.REACT_APP_PROFILE === 'INSURANCE' && clientName === 'Sura' && (
          <div className={`content-responsive mx-20 d-flex mt-5 mb-4`}>

            <div
              className={`d-flex justify-content-between title-primary--regular 
            ${direction >= 415
                  ? 'flex-row align-items-start'
                  : 'flex-column align-items-center'
                }`}>
              <div
                className={`text-center justify-content-center ${direction >= 766
                  ? 'info-sura-container mt-5 mb-5'
                  : direction >= 415
                    ? 'info-sura-container mt-3 mb-5'
                    : 'info-sura-container-mobile'
                  }`}>
                <img
                  src={firstInfoSura}
                  alt="Reembolsará máximo 3 unidades de tu medicamento"
                />
                <figcaption className="text-left mt-3">
                  <p>
                    Seguros {clientName + ' '}
                    <span className="title-primary">
                      reembolsará máximo 3 unidades de tu medicamento
                    </span>{' '}
                    por mes a través de Yapp. Para comprar más unidades debes
                    solicitar tu reembolso post compra.
                  </p>
                </figcaption>
              </div>
              <div
                className={`text-center justify-content-center ${direction >= 766
                  ? 'info-sura-container mt-5 mb-5'
                  : direction >= 415
                    ? 'info-sura-container mt-3 mb-5'
                    : 'info-sura-container-mobile'
                  }`}>
                <img
                  src={secondInfoSura}
                  alt="Solo debes elegir la alternativa de farmacias más económica"
                />
                <figcaption className="text-left mt-3">
                  <p>
                    El porcentaje de reembolso de tu medicamento es el mismo en
                    todas las farmacias,{' '}
                    <span className="title-primary">
                      solo debes elegir la alternativa de farmacias más
                      económica.
                    </span>
                  </p>
                </figcaption>
              </div>
              <div
                className={`text-center justify-content-center ${direction >= 766
                  ? 'info-sura-container mt-5 mb-5'
                  : direction >= 415
                    ? 'info-sura-container mt-3 mb-5'
                    : 'info-sura-container-mobile'
                  }`}>
                <img src={thirdInfoSura} alt="Vigencia máxima de 6 meses" />
                <figcaption className="text-left mt-3">
                  <p>
                    Tu receta tiene una{' '}
                    <span className="title-primary">
                      vigencia máxima de 6 meses
                    </span>{' '}
                    desde la fecha de emisión.
                  </p>
                </figcaption>
              </div>
            </div>
          </div>
        )
        }

        <div
          className="maxw-584 mx-auto d-flex justify-content-center minsal-img-container mt-4"
          style={{ gap: '10%' }}>
          <a
            rel="noreferrer"
            className=""
            href="https://www.minsal.cl/wp-content/uploads/2016/04/MAGNETO-USO-RACIONAL-DE-MEDICAMENTOS-PARA-IMPRESION.pdf"
            target="_blank">
            <img
              src={minsalUso}
              alt="minsal uso racional"
              className="minsal-img w-100"
            />
          </a>
          <a
            className=""
            rel="noreferrer"
            href="https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf"
            target="_blank">
            <img
              src={minsalVenta}
              alt="minsal venta directa"
              className="minsal-img w-100 "
            />
          </a>
        </div>

        {clientName === 'Sura' &&
          <div className="d-flex justify-content-center align-items-center flex-row m-5 title-primary--regular text-center">
            *El beneficio de Seguros SURA podrá consistir en reembolsos correspondiente al
            monto bonificado por Seguros SURA según su plan de seguro complementario que tenga contratado,
            o al descuento otorgado por SEGUROS SURA para sus clientes, según corresponda.
          </div>
        }

      </div>
      {clientName === 'UC Christus' && (
        <div className="d-flex justify-content-center background-white">
          <button
            className="px-5 btn-default--small btn-stroke font--14 btn-next mx-auto"
            onClick={() => history.push('/legal')}>
            Más Información
          </button>
        </div>
      )}

      <ModalAlertLocation
        title="Necesitamos una ubicación"
        message="Ingresa una comuna para continuar con la búsqueda de tus medicamentos."
        visibleAlertLocation={visibleAlertLocation}
        setVisibleAlertLocation={setVisibleAlertLocation}
      />
    </>
  )
}

const localStyles = {
  stepper: {
    input: { borderColor: 'transparent', width: '30%', padding: '0.4rem' },
    buttons: {
      backgroundColor: '#46CEBE',
      borderColor: 'transparent',
      padding: '0.4rem',
      width: '3rem',
    },
  },
  rowButton: {
    justifyContent: 'center',
    marginTop: '1rem',
  },
}

const mapStateToProps = (state) => {
  return {
    products: state.cart.products,
    commune: state.app.commune,
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
    themeColor: state.app.config?.custom_styles?.theme_color,
    clientLogo: state.app.config?.logo,
    config: state.app.config,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBag)
