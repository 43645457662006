import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import ImageUploader from 'react-images-upload'
import check from '../assets/img/ic_check.svg'

import productImg from '../assets/img/prescription.png'


const ModalUploader = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [showSelectProductos, setSelectProducts] = useState(false)

  const [payload, setPayload] = useState({})
  const [filesSaved, setFiles] = useState({})

  const [productsChecked, setProductsChecked] = useState([])
  const [productsWithOther, setProductsWithOther] = useState([])

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useImperativeHandle(ref, () => ({
    showAlert(payload) {
      setSelectProducts(false)

      let tmpCheck = []
      let tmpWithOter = []

      payload.products.map((item) => {
        if (payload.productId === item.product?.product_id) {
          tmpCheck.push(item.product?.product_id)
        } else {
          if (
            payload.prescriptionProduct.some(
              (el) => el.product_id === item.product?.product_id,
            )
          ) {
            tmpWithOter.push(item.product?.product_id)
          }
        }
      })

      setProductsChecked(tmpCheck)
      setProductsWithOther(tmpWithOter)
      setPayload(payload)
      handleShow()
    },
  }))

  function onDrop(files, images) {
    let leftPrescription = payload?.products?.length - payload.prescriptionProduct?.length === 1

    if (payload?.products?.length > 1 && !leftPrescription && files.length > 0) {
      setSelectProducts(true)
      setFiles({ images, files })
    } else if (images.length > 0) {
      props.onSelect({ images, files, productsChecked })
      handleClose()
    }
  }

  function clickSelectProduct() {
    setSelectProducts(false)
    props.onSelect({
      images: filesSaved?.images,
      files: filesSaved?.files,
      productsChecked,
    })
    handleClose()
  }

  function checkProduct(status, item) {
    let statusCheck = status.target.checked
    let productId = item.product.product_id
    if (statusCheck) {
      if (!productsChecked.includes(productId)) {
        setProductsChecked([...productsChecked, productId])
      }
    } else {
      setProductsChecked([...productsChecked.filter((el) => el !== productId)])
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {!showSelectProductos ? (
          <>
            <Modal.Header closeButton className='d-block'>
              <Modal.Title className="title-primary--xl-bold">
                {'Subir archivo'}
                <div className='mt-3 txt-paragraph--lg-bold'>
                  <p className='mb-2'>Debes considerar:</p>
                  <ul>
                    <li className='mb-1'>
                      <img className='mr-1' width='15px' src={check} alt='check'></img>
                      Receta vigente
                    </li>
                    <li className='mb-1'>
                      <img className='mr-1' width='15px' src={check} alt='check'></img>
                      Nombre y firma del médico legibles
                    </li>
                    <li>
                      <img className='mr-1' width='15px' src={check} alt='check'></img>
                      La receta debe estar a nombre del paciente que compra en Yapp
                    </li>
                  </ul>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ImageUploader
                id="gtm-webapp-modal-prescrption-upload"
                withIcon={true}
                buttonText="Elegir imagen"
                onChange={onDrop}
                label={'Seleccionar receta médica.'}
                imgExtension={['.jpg', '.jpeg', '.png', '.pdf']}
                accept="image/*,.pdf"
                maxFileSize={5242880}
                singleImage={true}
                buttonClassName={'gtm-webapp-checkout-add-prescription'}
                fileSizeError={'El tamaño de la imagen es muy grande. Por favor agrega una más pequeña (máximo 5 MB)'}
                fileTypeError={'El formato de la imagen no es válido, por favor agrega un archivo en jpg, png, pdf o jpeg.'}
                errorStyle={{ color: 'var(--color-error-ds)' }}
                errorClass={'txt-5--bold-db'}
              />
              <div className="w-100">
                <p className="txt-5--bold-db mt-1">* Por favor agrega un archivo en jpg, png, pdf o jpge.</p>
                <p className="txt-5--bold-db mt-1">* Peso máximo por imagen es de 5 MB.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              
            </Modal.Footer>
          </>
        ) : (
          <div className="p-4">
            <h4 className="title-primary--regular mb-3">
              Selecciona los productos para los que aplica esta receta:
            </h4>
            {payload?.products.map((item) => {
              if (productsWithOther.includes(item.product?.product_id)) return
              return (
                <div className="d-flex align-items-center py-1">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={
                        productsChecked.includes(item.product?.product_id) ||
                        productsWithOther.includes(item.product?.product_id)
                      }
                      disabled={
                        payload.productId === item.product?.product_id ||
                        productsWithOther.includes(item.product?.product_id)
                      }
                      onChange={(status) => checkProduct(status, item)}
                    />
                    <span className="checkmark">
                      <i className="icon-checkmark"></i>
                    </span>
                  </label>
                  <div className="custom-checkbox-img">
                    <img
                      src={item.product?.logo ? item.product.logo : productImg}
                      height="30"
                      className="img-check"
                      alt="products"
                    />
                  </div>
                  <div className="w-50 ml-2">
                    <p className="txt-paragraph--small">{item.product?.name}</p>
                  </div>
                </div>
              )
            })}

            <div className="d-flex justify-content-center align-items-center flex-column mt-3">
              <Button
                className="btn-default--small btn-next"
                variant="primary"
                onClick={() => clickSelectProduct()}>
                GUARDAR
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
})

export default ModalUploader
