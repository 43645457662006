import React from 'react'
import Modal from 'react-modal'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import CardInteracion from '../CardInteraction/CardInteracion'
import {getBioequivalents} from '../../Utils/api'
import ImgProduct from '../../assets/img/prescription.png'
import {logEvent, addEventNameWithParams} from '../../Utils/utils'
import {ArrCardInteraction} from '../../Utils/constants'

function FooterDetail(props) {
  const {product} = props
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const isProHash = useSelector((state) => state.app.isPro)
  const [bio, setBio] = useState(null)
  const [contactLabClicked, setContactLabClicked] = useState(false)
  const [modalLaboratory, setModalLaboratory] = useState(false)

  useEffect(() => {
    getBioequivalentsProducts(product?.product_id)
  }, [])

  function goToPage(item) {
    if (item.link) {
      return window.open(item.link)
    } else if (item.page !== '') {
      switch (item.page) {
        case '/pro/drug_interactions':
          logEvent('Drug Interaction Opened', {
            Origin: 'Medication Sheet',
            'Product Id': product.product_id,
            'Product Name': product?.product_name,
            'Lab Name': product?.laboratory_name,
          })
          break
        default:
          break
      }
      history.push(item.page, {
        product_id: product.product_id,
        product: product,
        isComesFromMedicationSheet: true,
      })
    }
  }
  const getBioequivalentsProducts = async (product_id) => {
    try {
      let bioProducts = await getBioequivalents(product_id)
      if (bioProducts?.data) {
        setBio(bioProducts?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function findBioequivalents() {
    logEvent('Bioequivalent Checked', {
      Origin: 'Medication Sheet',
      'Product Id': product.product_id,
      'Product Name': product?.product_name,
      'Lab Name': product.laboratory_name,
    })
    history.push('/product/bioequivalent', {bio: bio, path: path})
  }

  function onContactLab() {
    setModalLaboratory(true)
  }

  function closeModalLaboratoryl(params) {
    setModalLaboratory(false)
  }

  function clickOnContactLab(type) {
    if (!contactLabClicked) {
      addEventNameWithParams('gtm-detail-contact-lab', {
        labName: product?.laboratory_name,
        labId: props.contactLaboratory?.laboratory_id,
      })
      logEvent('Laboratory Contacted', {
        Origin: 'Medication Sheet',
        'Lab Name': product?.laboratory_name,
        'Lab Id': props.contactLaboratory?.laboratory_id,
        'Contact Type': type,
      })
      if (type === 'web') {
        window.open(props.contactLaboratory.laboratory_link)
      }
      setContactLabClicked(true)
    }
  }

  return (
    <>
      <Modal
        isOpen={modalLaboratory}
        //onAfterOpen={onAfterOpenLaboratory(props)}
        onRequestClose={closeModalLaboratoryl}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalLaboratory"
        ariaHideApp={false}>
        <button className="btn-circle-close" onClick={closeModalLaboratoryl}>
          <i className="icon-close"></i>
        </button>
        {true && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="content-img mb-3">
              <img
                alt="laboratorio"
                src={
                  props.contactLaboratory?.laboratory_logo
                    ? props.contactLaboratory?.laboratory_logo
                    : ImgProduct
                }
                className="img-product"
              />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              {product?.laboratory_name && (
                <h2 className="title-primary--xl text-center">
                  {product?.laboratory_name}
                </h2>
              )}
              {props.contactLaboratory?.laboratory_link ? (
                <a
                  onClick={() => clickOnContactLab('web')}
                  id="gtm-webapp-pro-laboratory-phone"
                  href={`tel: ${props.contactLaboratory?.laboratory_phone}`}
                  className="txt-paragraph--light color-lilac mb-2">
                  Contactar en sitio web
                </a>
              ) : (
                <>
                  {props.contactLaboratory?.laboratory_phone && (
                    <a
                      onClick={() => clickOnContactLab('phone')}
                      id="gtm-webapp-pro-laboratory-phone"
                      href={`tel: ${props.contactLaboratory?.laboratory_phone}`}
                      className="txt-paragraph--light color-lilac mb-2">
                      {props.contactLaboratory?.laboratory_phone}
                    </a>
                  )}

                  {props.contactLaboratory?.laboratory_email && (
                    <a
                      onClick={() => clickOnContactLab('mail')}
                      id="gtm-webapp-pro-laboratory-email"
                      href={`mailto: ${props.contactLaboratory?.laboratory_email}`}
                      className="txt-paragraph--light color-lilac ">
                      {props.contactLaboratory?.laboratory_email}
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Modal>
      <div className="mb-5">
        {isProHash && (
          <div className="px-3 ">
            <div className="mb-4 content-responsive content-responsive-btns mx-auto">
              {/* <hr className="hr--gray-4 w-100" /> */}
              {bio?.length > 0 && (
                <button
                  id="gtm-webapp-product-detail-pro-btn-view-biequivalent"
                  className="btn-default--small btn-stroke--altern btn-next mt-4"
                  onClick={() => findBioequivalents()}>
                  Alternativas similares
                </button>
              )}
              {props.contactLaboratory && (
                <button
                  id="gtm-webapp-product-detail-pro-btn-lab-contact"
                  className="btn-default--small txt-3--bold btn-stroke--altern btn-next mt-4"
                  onClick={() => onContactLab()}>
                  Contactar laboratorio
                </button>
              )}
            </div>
          </div>
        )}
        <h4 className="txt-2--bold line-14 mb-3">
          También te podría interesar...
        </h4>
        <div className="d-flex flex-column flex-md-row flex-wrap">
          {ArrCardInteraction.map((item, key) => {
            let flag = path.includes(item.page)
            if (!flag) {
              if (
                item.id === 'medication-buy' &&
                process.env.REACT_APP_PROFILE === 'WIDGET'
              ) {
                return <></>
              } else {
                return (
                  <CardInteracion
                    key={key}
                    id={item.id}
                    className="py-4"
                    doAction={() => goToPage(item)}>
                    {item?.icon && (
                      <img src={item.icon} className="mw-40" alt="Interaciones medicamentosa" />
                    )}
                    <div className="ml-2">
                      <h2 className="txt-2--bold mb-1 line-16">
                        {item.title}
                      </h2>
                    </div>
                  </CardInteracion>
                )
              }
            }
          })}
        </div>
      </div>
    </>
  )
}
export default FooterDetail
