import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import {logEvent} from "../../Utils/utils"
function ModalSendEmail(props) {
  const {
    sendMailIsOpen,
    closeEmailModal,
    sendProgramsToPatient,
    setPatientEmail,
    sendEmailStatus,
    setCheckbox,
    patientEmail,
    currentProgram,
    appState,
  } = props
  const [stage, setStage] = useState(1)

  useEffect(() => {
    if (sendEmailStatus) {
      setStage(3)
    }
  }, [sendEmailStatus])

  function closeModalSendMail() {
    setStage(1)
    closeEmailModal()
  }

  function sendEmail() {
    sendProgramsToPatient()
    logEvent('Item Shared', {
      'Shared Type':  'Mail',
      'Element Shared': 'Program',
      'Product Id': appState?.data?.product_id,
      'Product Name': appState?.data?.product_name,
      'Lab Name': appState?.data?.laboratory_name,
    })
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  return (
    <Modal
      isOpen={sendMailIsOpen}
      onAfterOpen={null}
      onRequestClose={closeModalSendMail}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalSendMail"
      ariaHideApp={false}>
      <button className="btn-circle-close" onClick={closeModalSendMail}>
        <i className="icon-close"></i>
      </button>
      {stage === 1 && (
        <div className="d-flex flex-column ">
          <h4 className="title-primary--xxl line-14 mb-2">
            Correo para el paciente
          </h4>
          <p className="title-primary--light mb-2">
            Hola, nos ha llegado una solicitud por parte del Dr./Dra para
            enviarle información sobre el programa de paciente{' '}
            <span className="title-primary">
              {currentProgram?.program_title}
            </span>{' '}
            del laboratorio {appState?.data?.laboratory_name} relacionado a su
            tratamiento.
          </p>
          <p className="title-primary--light mb-2">
            A continuación se mostrará un listado de documentos relevantes, para
            que usted pueda acceder a los beneficios del programa{' '}
            <span className="title-primary">
              {currentProgram?.program_title}
            </span>
            . Al hacer click en cada documento, podrá descargarlo para así
            utilizarlo de la forma que sea necesaria.
          </p>
          <p className="title-primary--light mb-2">Se despide atte.</p>
          <p className="title-primary--light mb-2">
            Esperamos que esta información sea de mucha utilidad para ti.
          </p>
          <p className="title-primary--light mb-5">
            Atentamente, Todo nuestro gran equipo Yapp!
          </p>
          <button
            onClick={() => setStage(2)}
            id="gtm-webapp-modal-send-email-next"
            className="btn-default btn-next">
            Enviar correo
          </button>
        </div>
      )}
      {stage === 2 && (
        <div className="content-form-send-email pt-3">
          <button
            onClick={() => setStage(1)}
            className="btn-trasparent btn-modal-back">
            <i className="icon-arrow-back color-theme"></i>
          </button>
          <h4 className="title-primary--xl mb-4">
            Enviar información a un paciente
          </h4>
          <h4 className="title-primary">Correo paciente</h4>
          <div>
            <input
              type="email"
              className="custom-input-email mb-1"
              placeholder="Ejemplo@yapp.cl"
              onChange={(evt) => setPatientEmail(evt.target.value)}
            />
            {sendEmailStatus === false && (
              <p className="text-danger txt-paragraph--small">
                *Correo inválido
              </p>
            )}
            <label
              className="custom-checkbox-altern pl-0 mb-4 mt-2 "
              id="gtm-webapp-ger-mail-copy">
              <input
                ref={null}
                disabled={false}
                type="checkbox"
                onChange={(evt) => setCheckbox(evt.target.checked)}
              />
              <span className="checkmark-altern">
                <i className="icon-checkmark"></i>
              </span>
              <span className="txt-paragraph--small custom-checkbox-txt">
                Quiero recibir una copia en mi correo
              </span>
            </label>
            <button
              onClick={sendEmail}
              id="gtm-webapp-modal-send-email-patient"
              className="btn-default btn-next w-100">
              Enviar
            </button>
          </div>
        </div>
      )}
      {stage === 3 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="modal-icon-send-mail mb-2">
            <i className="icon-send-2"></i>
          </span>
          <h4 className="title-primary--light mb-3 text-center">
            Enviamos el correo al paciente con la información del programa al
            mail {patientEmail}
          </h4>
          <button
            id="gtm-webapp-modal-send-email-success"
            className="btn-default btn-next w-100"
            onClick={closeModalSendMail}>
            ¡Entendido!
          </button>
        </div>
      )}
    </Modal>
  )
}

export default ModalSendEmail
