import React from 'react'
import './styles/RadioGroup.css'


const RadioGroup = ({name, options, value, onChange}) => {
  function options_() {
    return options.map((option) => {
      if (option.label && option.value)
        return option
      return {label: option, value: option}
    })
  }

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <div className="radiogroup">
      {
        options_().map((option, index) =>
         <div key={index}>
          <input
            id={`${name}-${index}`}
            type="radio"
            name={name}
            checked={option.value === value}
            onChange={handleChange}
            value={option.value}
          />
          <label htmlFor={`${name}-${index}`}>
            <span className="radio-label">{option.label}</span>
          </label>
        </div>
        )
      }
    </div>
  )
}

export default RadioGroup
