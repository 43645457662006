import React from 'react'

import IcPillFail from '../../assets/img/i_pill_fail.svg'

function FailDrugsInteraction(props) {
  return (
    <div className="wrapper-location">
      <div className="maxw-436 mx-auto px-4 px-md-0 mt-4 mt-md-5">
        <div className="text-center mb-5">
          <img
            id="gtm-webapp-drugs-interactive-img-fail"
            src={IcPillFail}
            alt="Fail"
            className=""
          />
        </div>
        <p className="title-primary--regular line-16 mb-4 text-center">
          No hemos logrado con conectarnos con nuestro proveedor de información
          sobre interacciones. Por favor inténtalo nuevamente en unos minutos...
        </p>
        <div className="mb-5 d-flex flex-column align-items-center">
          <button
            id="gtm-webapp-drugs-interactive-btn-resend"
            className="btn-default--small btn-next font--14 mb-3 maxw-300 w-100 ">
            {' '}
            Reintentar
          </button>
          <button
            id="gtm-webapp-drugs-interactive-btn-back"
            className="btn-default--small font--14 btn-stroke maxw-300 w-100">
            {' '}
            Volver al inicio
          </button>
        </div>
      </div>
    </div>
  )
}

export default FailDrugsInteraction
