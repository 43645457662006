import React from 'react'
import Modal from 'react-modal'

import ImgAccountRegistered from '../../assets/img/account_registered.svg'
import ButtonYapp from '../Buttons/ButtonYapp'

function ModalAccountRegistered(props) {

  const {modalAccountR, setModalAccountR, onSignIn} = props

  function closeModalAccount(params) {
    setModalAccountR(false)
  }

  return (
    <div>
      <>
        <Modal
          isOpen={modalAccountR}
          onAfterOpen={null}
          onRequestClose={closeModalAccount}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="ModalReported"
          ariaHideApp={false}>
          <button id={`gtm-webapp-modal-account-registered-closed-btn`} className="btn-circle-close" onClick={closeModalAccount}>
            <i id={`gtm-webapp-account-registered-closed-icon`} className="icon-close"></i>
          </button>
          <div className="d-flex flex-column align-items-center justify-content-center mb-3">
            <img src={ImgAccountRegistered} alt='account registered' className='mb-3' />
            <div>
              <h3 className="title-primary--xxl text-center mb-2">Tu correo ya se encuentra registrado</h3>
              <p className="title-primary--light text-center mb-0">Intenta iniciando tu sesión</p>
            </div>     
          </div>

          <ButtonYapp
            id={`gtm-webapp-account-registered-signin-btn`}
            onClick={onSignIn}
            title='Iniciar sesión'
          />
            
        </Modal>
      </>
    </div>
  )
}


export default ModalAccountRegistered