import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import LegalList from '../../Components/Lists/LegalList'
import { getValues } from '../../Utils/rc_get_instance'

import loadingYapp from '../../assets/img/yapp_loading.gif'

function Legal() {
  const [menu, setMenu] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.state) {
      setMenu(location.state.item)
    } else {
      setLoading(true)
      getValues('APP_MENU_LEGAL')
        .then((resp) => {
          setMenu({ items: JSON.parse(resp?._value) })
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [])

  return (
    <div className="flex-grow-1 px-0 pb-4">
      <div className="content-responsive mx-auto">
        <div className="breadcrumbs">
          <div
            className="d-flex align-items-center"
            onClick={() => history.goBack()}>
            <i className="icon-arrow-back"></i>
          </div>{' '}
          <span>Más información</span>
        </div>
        {loading ? (
          <img
            src={loadingYapp}
            alt="loading"
            className="loading-yapp--alternative"
            style={localStyle.loading}
          />
        ) : (
          <div className="content-card-light mt-2 py-6px">
            <LegalList title={null} data={menu} />
          </div>
        )}
        {process.env.REACT_APP_VERSION && (
          <p className="txt-paragraph">Versión {process.env.REACT_APP_VERSION}</p>
        )}
      </div>
    </div>
  )
}

const localStyle = {
  loading: {
    top: '30%',
  },
}

export default Legal
