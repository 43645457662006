import Modal from 'react-modal'

import icPillDoctor from '../../assets/img/i_pill_doctor_yay.svg'

function ModalWelcome(props) {
  const {modalWelcomeIsOpen, setWelcomeIsOpen} = props

  function closeModalWelcomeHome() {
    setWelcomeIsOpen(false)
  }

  function onFocus() {}

  return (
    <>
      <Modal
        isOpen={modalWelcomeIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalWelcomeHome}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalWelcomeHome"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={props.icon ? props.icon : icPillDoctor}
            alt="Error"
            className="mb-4"
          />
          <h4 className="itle-primary--3xl-regular text-center  mb-3">
            {props.title}{' '}
          </h4>
          <p className="title-primary--regular text-center mb-30">
            {props.message}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <button
            className="btn-default btn-next mb-3"
            onClick={() => closeModalWelcomeHome()}>
            Comenzar
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ModalWelcome
