import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { base_url, ENUM_MENU, shareMessages, Menus } from '../../Utils/constants'
import { addEvent, logEvent, shareWithColleagues } from '../../Utils/utils'
import { useSelector, useDispatch } from 'react-redux'
import ModalShareWithColleages from '../Modals/ModalShareWithColleages'
import { changeInitialLoading, setMenu } from '../../redux/modules/app'

function Sidenav(props) {
  const history = useHistory()
  const menu = useSelector((state) => state.app.menu)

  const [modalState, setModalState] = useState(false)

  const url = base_url
  const { shareProduct } = shareMessages

  function goToPage(item) {
    addEvent(item.id, true)
    logEvent(item.eventName, item.extraData)
    if (item.page) {
      props.setIsOpenSidenav(false)
      return history.push(item.page)
    }
  }

  function modalShare() {
    props.setIsOpenSidenav(false)
    onShare()
  }

  const onShare = () => {
    let result = shareWithColleagues(url)
    if (result === false) {
      setModalState(true)
    }
  }

  return (
    <>
      <div className={`content-sidenav ${props.isOpen && 'is-open'}`}>
        <div className="inner-sidenav">
          <button
            className="btn-close"
            onClick={() => props.setIsOpenSidenav(false)}>
            <i className="icon-close"></i>
          </button>
          <h2 className="txt-title color-white text-center mb-30">MENÚ</h2>
          <div className="content-scroll-sidenav">
            {menu.map((menu, key) => {
              if (!menu.isDisabled)
                return <div
                  key={key}
                  className="items-sidenav c-pointer"
                  id={menu.id}
                  onClick={() => {
                    goToPage(menu)
                  }}>
                  {menu.isNew && <span className="tag-sidenav mb-2">NUEVO</span>}
                  {menu.isCommin && (
                    <span className="tag-sidenav comming-soon mb-2">
                      PRÓXIMAMENTE
                    </span>
                  )}
                  <h3
                    className={`title-primary--xxl-regular text-center mb-0 ${menu.isCommin ? 'color-gray-4' : 'color-white'
                      }`}>
                    {menu.title}
                  </h3>
                </div>
            })}
            <button
              id="btn-share-with-colleagues"
              className="btn-default btn-sidenav mx-auto mt-3"
              onClick={() => modalShare()}>
              <i className="icon-share mr-2"></i> Compartir con colegas
            </button>
          </div>
        </div>
        <span
          className={`lightbox ${props.isOpen ? 'is-show' : 'd-none'}`}
          onClick={() => props.setIsOpenSidenav(false)}></span>
      </div>
      <ModalShareWithColleages
        modalState={modalState}
        url={url}
        setModalState={setModalState}
        title={shareProduct.title}
        description={shareProduct.description}
      />
    </>
  )
}

export default Sidenav
