import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setThemeColor, showLogo, changeLogo} from './redux/modules/app'
import {theme} from './Utils/constants'

const ThemeProvider = (props) => {
  const themeColor = useSelector((state) => state.app.themeColor)
  const dispatch = useDispatch()

  useEffect(() => {
    let themeColor = {
      background_color: process.env.REACT_APP_THEME_BACKGROUND_COLOR,
      icon_tutorial: process.env.REACT_APP_THEME_ICON_TUTORIAL_COLOR,
      primary: process.env.REACT_APP_THEME_PRIMARY_COLOR,
      secondary: process.env.REACT_APP_THEME_SECONDARY_COLOR,
    }

    if (![undefined, null, 'NULL'].includes(process.env.REACT_APP_LOGO)) {
      dispatch(changeLogo(process.env.REACT_APP_LOGO))
      dispatch(showLogo(true))
    }
    dispatch(setThemeColor(themeColor))
  }, [])

  useEffect(() => {
    const root = document.documentElement

    let fontName = process.env.REACT_APP_FONT
    let maxScreenResposive = ['PATIENT', 'MEDICAL'].includes(
      process.env.REACT_APP_PROFILE,
    )
      ? '888px'
      : '1245px'
    root.style.setProperty('--font-regular', fontName + '-Regular')
    root.style.setProperty('--font-bold', fontName + '-Bold')
    root.style.setProperty('--font-light', fontName + '-Light')
    root.style.setProperty('--border-radius', theme.border_radius)
    root.style.setProperty('--border-radius-input', theme.border_radius_input)
    root.style.setProperty(
      '--color-background-primary',
      themeColor.background_color ||
        process.env.REACT_APP_THEME_BACKGROUND_COLOR,
    )

    root.style.setProperty('--max-screen-resposive', maxScreenResposive)

    if (themeColor && themeColor.primary) {
      root.style.setProperty(
        '--theme-color',
        themeColor.primary || process.env.REACT_APP_THEME_PRIMARY_COLOR,
      )
      if (themeColor.secondary) {
        root.style.setProperty(
          '--theme-color-secondary',
          themeColor.secondary || process.env.REACT_APP_THEME_SECONDARY_COLOR,
        )
      }
    }
  }, [themeColor])

  return <div className="App">{props.children}</div>
}

export default ThemeProvider
