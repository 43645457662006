import React from 'react'
import Modal from 'react-modal'
import Remover from '../../assets/img/remover.svg'
import icErrorInsurance from '../../assets/img/error_insurance.png'

function ModalNoValidInsurance(props) {
  const {
    modalNoValidInsurance,
    setModalNoValidInsurance,
  } = props

  return (
    <>
      <Modal
        isOpen={modalNoValidInsurance}
        onAfterOpen={null}
        onRequestClose={() => setModalNoValidInsurance(false)}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        ariaHideApp={false}>
        <div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
            <a onClick={() => setModalNoValidInsurance(false)}>
              <img src={Remover} className="icon-remover" alt="Cerrar" />
            </a>
            <img src={icErrorInsurance} alt="Error" className="mb-3" style={{width: '4.9375rem', height: '6.1875rem'}} />
            <h4 className="txt-3--light-db mb-4 text-center">
              No tienes póliza de Sura vigente en estos momentos
            </h4>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalNoValidInsurance
