import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import app from './modules/app'
import cart from './modules/cart'
import delivery from './modules/delivery'
import user from './modules/user'
import search from './modules/search'

function saveToSessionStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state', serializedState)
  } catch (error) {
    console.log(error)
  }
}

function loadFromSessionStorage() {
  try {
    const serializedState = sessionStorage.getItem('state')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

const reducer = combineReducers({
  app,
  cart,
  delivery,
  user,
  search,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedState = loadFromSessionStorage()
const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk)),
)

store.subscribe(() => saveToSessionStorage(store.getState()))

export default store
