import {useEffect} from 'react'
import Stepper from '../../Components/Stepper'
import { _formatPrice } from '../../Utils/tools'

function CheckboxControl(props) {
  
  function setChecked(productIndex, checked){
    let productList = [...props.productList]
    productList[productIndex].checked = checked
    props.setProductList(productList)
  }

  function setQuantity(productIndex, quantity){
    let productList = [...props.productList]
    productList[productIndex].quantity = quantity
    productList[productIndex].checked = quantity > 0 ? true : false
    props.setProductList(productList)
  }

  return (
    props.productList && props.productList.map((item, productIndex) => (
      <div key={productIndex.toString()}>
        <div className='d-flex align-items-center price-mobile-container mb-4'>
          <label className='custom-checkbox'>
            <input type='checkbox' defaultChecked={item.checked} onChange={(checkbox) => setChecked(productIndex, checkbox.target.checked)}/>
            <span className='checkmark'>
              <i className='icon-checkmark'></i>
            </span>
          </label>
          <div className='d-flex align-items-end price-mobile'>
            <div className='ml-2 mw-100 mr-2'>
              { item.product?.name &&
                <p className='txt-5--bold-db dots-on-100'>{ item.product.name }</p>
              }

              { item.product?.formula_name &&
                <p className='txt-6--regular-g1 dots-on-100'>{ item.product.formula_name }</p>
              }
            </div>
            <div>
              <p className='txt-5--regular-g1'>{ _formatPrice(item.price * item.quantity) }</p>
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-center w-100'>
            <Stepper key={ productIndex.toString() } className='mt-zero' counter={item.quantity} clickUpdateCounter={ (quantity) => setQuantity(productIndex, quantity) }/>
          </div>
        </div>
      </div>
    ))
  )
}

export default CheckboxControl