import ReactTooltip from 'react-tooltip'
import ButtonYapp from '../Buttons/ButtonYapp'
import check from '../../assets/img/ic_check.svg'

function ProductPrescription(props) {
  return (
    <>
      <div className="content-add-prescription mb-1">
        <h3 className="text-truncate w-100">{props.product.name}</h3>
        {!props.prescriptionProduct?.find(
          (product) =>
            product?.product_id == props.product?.product_id ||
            product?.product_id == props.product?.client_product_id,
        ) ? (
          <>
            <ButtonYapp
              title="Agregar receta"
              onClick={() =>
                props.onClickLoadPrescription(
                  props.product.product_id,
                  props.products,
                )
              }
              color={'--color-green-3'}
              noWrap
              id={`gtm-webapp-checkout-product-prescription${props.product}`}
              className="mw-100 tooltip-hover"
              small
              chip
              dataTip
              dataFor="tootip-prescription-desktop"
              dataMultiline
              dataPlace="right"
            />
            <ReactTooltip
              id="tootip-prescription-desktop"
              className="tooltip-style shadow rounded-3 title-primary--regular p-3 maxw-290"
              effect="solid"
              place="bottom"
              backgroundColor="#ffffff"
              textColor="#000000"
              arrowColor="#FFFFFF"
            >
              <p className='mb-1'>Debes considerar:</p>
              <ul>
                <li>
                  <img className='mr-1' width='15px' src={check} alt='check'></img>
                  Receta vigente
                </li>
                <li>
                  <img className='mr-1' width='15px' src={check} alt='check'></img>
                  Nombre y firma del médico legibles
                </li>
                <li>
                  <img className='mr-1' width='15px' src={check} alt='check'></img>
                  La receta debe estar a nombre del paciente que compra en Yapp
                </li>
              </ul>
            </ReactTooltip>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-end flex-fill">
            <ButtonYapp
              title="Cambiar receta"
              onClick={() =>
                props.onClickLoadPrescription(
                  props.product.product_id,
                  props.products,
                )}
              color={'--color-green-3'}
              noWrap
              stroke
              className="mw-100"
              small
              chip
              dataTip
              dataFor="tootip-prescription-desktop"
              dataMultiline
              dataPlace="right"
            />
            {/* <i
              className="icon-circle-check mr-2"
              style={{
                fontSize: '24px',
                color: 'var(--color-green-3)',
                paddingLeft: '8px',
              }}></i> */}
          </div>
        )}
      </div>
      {/* <div className="card-info">
        <div className="d-flex align-items-center text-truncate w-100">
          <span className="txt-paragraph text-truncate w-100">
            {props.product.name}
          </span>
        </div>
        <div
          className="d-flex align-items-center justify-content-end flex-fill"
          style={{minWidth: '100px'}}>
          {!props.prescriptionProduct.find(
            (product) => product.product_id === props.product.product_id,
          ) ? (
            <div
              onClick={() =>
                props.onClickLoadPrescription(
                  props.product.product_id,
                  props.products,
                )
              }>
              <div
                id={`gtm-webapp-checkout-product-prescription${props.product}`}
                className="txt-paragraph color-theme">
                + Agregar receta
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-end flex-fill">
              <i className="icon-check mr-2 color-theme"></i>
              <p className="txt-paragraph color-theme">¡Receta agregada!</p>
            </div>
          )}
        </div>
      </div> */}
    </>
  )
}

export default ProductPrescription
