import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import {getConfigPage} from '../Utils/api'

import {changeInitialLoading, changeConfig} from '../redux/modules/app'

import {getJWTdata} from '../Utils/tools'
import {fetchUser, setUserExtraInfo} from '../redux/modules/user'
import {loginAnonymoust, signOut} from '../Utils/auth'
import {getShoppingCart, saveShoppingCart} from '../Utils/service'

const _get = require('lodash/get')

function Page() {
  const {hash} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const location = useSelector((state) => state.app.location)
  const userInfo = useSelector((state) => state.user.userInfo)

  const skipLogin =
    process.env.REACT_APP_ONBOARDING_REQUIRED === 'TRUE'
      ? 'TRUE'
      : process.env.REACT_APP_SKIP_LOGIN

  useEffect(() => {
    localStorage.setItem(
      'fromYappApp',
      navigator.userAgent.search('YappApp') > -1,
    )

    dispatch(changeInitialLoading(true))
    getConfigPage(hash)
      .then(async (res) => {
        if (!res) {
          return history.push('/error')
        }

        let localData = {}
        let arrBag = null

        localStorage.setItem('JWT', res?.data?.token)
        localStorage.setItem(
          'LOCAL_DATA',
          JSON.stringify({
            hash: hash,
            origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
            site: res?.data,
            client_config: {
              delivery_time: _get(res?.data, 'config[0].config.delivery_time'),
            },
          }),
        )

        if (skipLogin === 'TRUE') {
          if (!userInfo?.signInMethod) {
            arrBag = await getShoppingCart()
            await signOut()
            let response = await loginAnonymoust()
            dispatch(fetchUser())
            let user_id = response?.data?.uid

            localData = localStorage.getItem('LOCAL_DATA')
            localData = JSON.parse(localData)
            localData.hash = user_id
            localStorage.setItem('LOCAL_DATA', JSON.stringify(localData))

            if (arrBag?.length > 0) {
              await saveShoppingCart(arrBag)
            }
          }
        }

        try {
          let jwtData = getJWTdata()
          // if(userId !== null)  {
          //   jwtData.user_id = userId
          // }

          window.gtag('set', 'user_id', jwtData?.user_id)
          window.gtag('set', 'user_type', _get(res, 'data.user_type', 'user'))
        } catch (error) {
          console.log('Error set user gtag.')
        }

        await dispatch(changeConfig(res))
        dispatch(setUserExtraInfo(_get(res?.data, 'info', {})))
        let route = res?.data?.route
        if (route === '/search') {
          if (location?.label && location?.nro) {
            route =
              '/search/shopping_bag/' +
              Math.floor(Math.random() * Math.floor(100000000000000000000))
            return history.replace(route, {product: null, from: 'page'})
          }
        }

        let productId = localStorage.getItem('product')
        if (productId) {
          history.push(`/product/detail/${productId}`, {
            product_id: productId,
            external_vademecum: true,
          })
          localStorage.removeItem('product')
        } else {
          history.replace(route, {site: res?.data})
        }
      })
      .catch((err) => {
        dispatch(changeInitialLoading(false))
        console.log(err)
      })
  }, [])

  return <div className="wrapper-location" />
}

export default Page
