import React from 'react'
import ButtonYapp from './Buttons/ButtonYapp'
import {useHistory} from 'react-router'
import FooterUC from './Theme/UC/FooterUC'
/* Imgs */
import yappImg from '../assets/img/ic_yapp_svg.svg'
import facebook from '../assets/img/footer-facebook.svg'
import instagram from '../assets/img/footer-instagram.svg'
import linkedin from '../assets/img/footer-linkedin.svg'
import {rrss} from '../Utils/constants'
import {logEvent} from '../Utils/utils'

function NewFooter() {
  const history = useHistory()
  const faq = '/faq'

  const goTo = () => {
    history.push('/legal')
  }

  function openContactMail(isProfessional) {
    logEvent('Yapp Contacted', {'Is Medical': isProfessional})
    document.location = isProfessional
      ? 'mailto:soportemedicos@yapp.cl'
      : 'mailto:soporte@yapp.cl'
  }

  return process.env.REACT_APP_CLIENT_NAME === 'UC Christus' ? (
    <>
      <FooterUC />
    </>
  ) : (
    <div className="main-footer px-5">
      <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-center h-100 pt-3">
        <div className="d-flex flex-column flex-lg-row align-items-center">
          <img
            src={yappImg}
            alt="Logo Yapp"
            className="logo-new-footer my-3 my-lg-0 mr-3"
          />
          <ButtonYapp
            className="font--14 mb-3 mb-lg-0"
            title={
              ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE)
                ? 'Más información'
                : 'Lo que necesitas saber'
            }
            onClick={() => goTo()}
            small
            stroke
          />
        </div>
        <p className="txt-footer mb-3 mb-lg-0 text-center">
          ¿Tienes alguna duda?{' '}
          <span className="txt-footer">
            {' '}
            <a className="color-lilac" onClick={() => window.open(faq)}>
              Contáctanos a soporte
            </a>
          </span>
        </p>
        <div className="d-flex align-items-center pb-3 pb-lg-0">
          <img
            onClick={() => window.open(rrss.facebook)}
            src={facebook}
            className="pointer"
            alt="Logo Facebook"
          />
          <img
            onClick={() => window.open(rrss.linkedin)}
            src={linkedin}
            className="ml-4 pointer"
            alt="Logo Linkedin"
          />
          <img
            onClick={() => window.open(rrss.instagram)}
            src={instagram}
            className="ml-4 pointer"
            alt="Logo Instagram"
          />
        </div>
      </div>
    </div>
  )
}

export default NewFooter
