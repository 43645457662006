import {useState, useImperativeHandle, forwardRef} from 'react'
import {Modal, Button} from 'react-bootstrap'

const ModalMessage = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [payload, setPayload] = useState({
    title: null,
    message: null,
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useImperativeHandle(ref, () => ({
    showAlert(payload) {
      setPayload(payload)
      handleShow()
    },
  }))
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{payload.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{payload.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default ModalMessage
