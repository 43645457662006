import React from 'react'
import Modal from 'react-modal'

function ModalForgotPassSendEmail(props) {
  const {
    modalForgotPassSendEmail,
    setModalForgotPassSendEmail,
    onClick,
    email,
  } = props

  return (
    <>
      <Modal
        isOpen={modalForgotPassSendEmail}
        onAfterOpen={null}
        onRequestClose={() => setModalForgotPassSendEmail(false)}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalSendMail"
        ariaHideApp={false}>
        <div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
            <span className="modal-icon-send-mail mb-4 d-flex">
              <i className="icon-send-2"></i>
            </span>
            <h4 className="txt-paragraph--light line-14 mb-4 text-center">
              Te enviaremos las instrucciones al correo {email} para que puedas
              cambiar tu contraseña.
            </h4>
          </div>
          <button
            id={"gtm-webapp-forgot-password-confirm-btn"}
            className="btn-default--small font--14 btn-next mx-auto"
            onClick={onClick}>
            ¡Ok!
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ModalForgotPassSendEmail
