import React from 'react'
import Modal from 'react-modal'

function ModalReportedAccount(props) {
  const { modalReportedState, setModalReportedState } = props

  function closeModalReported(params) {
    setModalReportedState(false)
  }

  return (
    <>
      <Modal
        isOpen={modalReportedState}
        onAfterOpen={null}
        onRequestClose={closeModalReported}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalReported"
        ariaHideApp={false}>
        <button id="gtm-webapp-reported-account-closed-btn" className="btn-circle-close" onClick={closeModalReported}>
          <i className="icon-close"></i>
        </button>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100">
          <div className="alert-icon">
            <i id="gtm-webapp-reported-account-icon" className="icon-alert color-red"></i>
          </div>
          <h2 className="title-primary mb-2">¡Ups! Tenemos un problema</h2>
          <p className="txt-paragraph line-16 mb-4 text-center">
            El RUT que ingresaste se encuentra en proceso de revisión. Para
            hacer seguimiento a la revisión te contactaremos por al correo que
            nos indicaste en el reporte.
          </p>
        </div>
        <button
          id="gtm-webapp-reported-account-btn"
          className="btn-default--small font--14 btn-next mx-auto"
          onClick={() => setModalReportedState(false)}>
          ¡Entendido!
        </button>
      </Modal>
    </>
  )
}

export default ModalReportedAccount
