import React from 'react'

function ButtonFalseInput(props) {
  return (
    <button className="btn-false-input ripple txt-5--regular-db mb-2" onClick={props.onClick}>
      {props.text}
    </button>
  )
}

export default ButtonFalseInput
