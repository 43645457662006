import React, {useContext, useRef} from 'react'
import {
  Accordion,
  Card,
  useAccordionToggle,
  AccordionContext,
} from 'react-bootstrap'
import './AccordionSelectProduct.css'
import productImg from '../../assets/img/prescription.png'
import icCheck from '../../assets/img/ic_check.svg'

function ContextAwareToggle({formula, showError, eventKey, callback}) {
  const nodeRef = useRef(null)
  const activeEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <>
      <div onClick={() => decoratedOnClick()}>
        <div className="d-flex justify-content-between w-100">
          <h3
            style={{transition: 'all .3s linear'}}
            className={
              !isCurrentEventKey ? 'txt-4--bold-g1 m-0' : 'txt-4--bold-db m-0'
            }>
            { formula }
          </h3>
          <div className="ml-auto d-flex align-items-center">
            {false && !isCurrentEventKey && <img src={icCheck} alt="check" className="mr-3" />}
            <i
              className={`icon-chevron-down color-theme ${
                isCurrentEventKey ? 'icon-up' : ''
              }`}></i>
          </div>
        </div>
        { showError && (
          <p className="txt-6--regular-er m-0">
            *Debes seleccionar una opción.
          </p>
        )}
      </div>
    </>
  )
}

function AccordionSelectProduct({ alternatives, setAlternatives, showErrors }) {
  const nodeRef = useRef(null)

  const checkProduct = (product, index = 0) => {
    let newAlternatives = [...alternatives]
    newAlternatives[index].selectedProduct = product
    setAlternatives(newAlternatives)
  }

  return (
    <>
      { alternatives.length === 1 && (
        <div className="pt-3 px-3">
          <h3 className="txt-4--bold-db mb-2">{ alternatives[0].name }</h3>
          { alternatives[0].selectedProduct === null &&
            <p className="txt-6--regular-er mb-3">
              *Debes seleccionar una opción.
            </p>
          }
          <div className="content-select-scroll">
            { alternatives[0].products.map((product, key) => (
              <div key={key} className="d-flex align-items-start mb-4">
                <div className="d-flex w-100">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={ alternatives[0].selectedProduct?.product_id === product.product_id }
                      disabled={false}
                      onChange={() => checkProduct(product)}
                    />
                    <span className="checkmark">
                      <i className="icon-checkmark"></i>
                    </span>
                  </label>
                  <div className="flex-fill ml-2">
                    <p className="txt-5--bold-db m-0 pr-3"> { product.product_name } </p>
                    <p className="txt-6--regular-g1 m-0"> { alternatives[0].name } </p>
                    <p className="txt-6--regular-g1 m-0"> { product.presentation } </p>
                    <p className="txt-6--regular-g1 m-0"> { product.laboratory_name } </p>
                  </div>
                </div>
                <div className="">
                  <img
                    src={ product.product_logo ? product.product_logo : productImg }
                    height="30"
                    className="img-check mr-2"
                    alt="products"
                  />
                </div>
              </div>
            ))}
            <div className="d-flex align-items-center mb-4">
              <div className="d-flex align-items-center w-100">
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={ alternatives[0].selectedProduct === 'none' }
                    disabled={false}
                    onChange={() => checkProduct('none')}
                  />
                  <span className="checkmark">
                    <i className="icon-checkmark"></i>
                  </span>
                </label>
                <div className="w-100 ml-2">
                  <p className="txt-5--bold-db m-0">
                    Ninguna de las anteriores
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      { alternatives.length > 1 && (
        <Accordion
          defaultActiveKey="0"
          className="content-accordion-list-product ">
          { alternatives.map((item, key) => {
            return (
              <Card key={key.toString()}>
                <Card.Header>
                  <ContextAwareToggle eventKey={`${key}`} formula={ item.name } showError={ showErrors && alternatives[key].selectedProduct === null } />
                </Card.Header>
                <Accordion.Collapse eventKey={`${key}`} ref={nodeRef}>
                  <Card.Body>
                    { item.products.map((product, index) => (
                      <div key={index} className="d-flex align-items-start mb-4 ">
                        <div className="d-flex w-100">
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              checked={ alternatives[key].selectedProduct?.product_id === product.product_id }
                              disabled={false}
                              onChange={() => checkProduct(product, key)}
                            />
                            <span className="checkmark">
                              <i className="icon-checkmark"></i>
                            </span>
                          </label>
                          <div className="flex-fill ml-2">
                            <p className="txt-5--bold-db m-0 pr-3"> { product.product_name } </p>
                            <p className="txt-6--regular-g1 m-0"> { item.name } </p>
                            <p className="txt-6--regular-g1 m-0"> { product.presentation } </p>
                            <p className="txt-6--regular-g1 m-0"> { product.laboratory_name } </p>
                          </div>
                        </div>
                        <div className="">
                          <img
                            src={ product.product_logo ? product.product_logo : productImg }
                            height="30"
                            className="img-check mr-2"
                            alt="products"
                          />
                        </div>
                      </div>
                    ))}
                    <div className="d-flex align-items-center mb-4">
                      <div className="d-flex align-items-center w-100">
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            checked={ alternatives[key].selectedProduct === 'none' }
                            disabled={false}
                            onChange={() => checkProduct('none', key)}
                          />
                          <span className="checkmark">
                            <i className="icon-checkmark"></i>
                          </span>
                        </label>
                        <div className="w-100 ml-2">
                          <p className="txt-5--bold-db m-0">
                            Ninguna de las anteriores
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
        </Accordion>
      )}
    </>
  )
}

export default AccordionSelectProduct
