import React from 'react'
import './LoadingSura.css'

function LoadingSura() {
  return (
    <div>
      <div id="overlayer"></div>
      <div className="sura-loader">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingSura
