import firebase from "firebase"
import "firebase/remote-config"

async function getInstance() {
  // [START rc_get_instance]
  const remoteConfig = firebase.remoteConfig()
  // [END rc_get_instance]
}

async function setMinimumFetchTime() {
  const remoteConfig = firebase.remoteConfig()
  // [START rc_set_minimum_fetch_time]
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000
  // [END rc_set_minimum_fetch_time]
}

async function setDefaultValues() {
  const remoteConfig = firebase.remoteConfig()
  // [START rc_set_default_values]
  remoteConfig.defaultConfig = {
    "Banner_web_patients": []
  };
  // [END rc_set_default_values]
}

export async function getValues(key, count = 0) {
  try {
    const remoteConfig = firebase.remoteConfig()
    let outCount = count === 0 ? 0 : count
    // [START rc_get_values]
    let val = remoteConfig.getValue(key)
    if (outCount === 10) return val
    if (!val._value) {
      outCount++
      await fetchConfigCallback()
      let resp = await getValues(key, outCount)
      return resp
    } else {
      return val
    }
  } catch (error) {
    console.error(error)
  }

  // [END rc_get_values]
}

export async function fetchConfigCallback() {
  try {
    const remoteConfig = firebase.remoteConfig()
    setMinimumFetchTime()
    setDefaultValues()
    // [START rc_fetch_config_callback]
    let result = await remoteConfig.fetchAndActivate()
    return result

    // [END rc_fetch_config_callback]
  } catch (error) {
    console.error(error)
  }

}