import React, {useRef, useState, useEffect} from 'react'
import ReactTooltip from 'react-tooltip'
import Toasts from '../Toasts/Toasts'
import * as tools from '../../Utils/tools'
import {icon_edit, icon_copy} from '../../assets/icons/icon-svg'

const Input = ({
  classData,
  data,
  placeholder,
  error,
  label,
  externalLabel,
  id,
  showPass,
  doChange,
  type,
  codeArea,
  defaultValue,
  showIconEdit,
  showCopyButton,
  showEdit,
  secondaryValue,
  ...props
}) => {
  const [viewPass, setViewPass] = useState(false)
  const [labelActive, setLabelActive] = useState(false)
  const [currentValue, setCurrentValue] = useState('')
  const [stateToasts, setStateToasts] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef?.current?.value.length > 0) {
      setLabelActive(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef?.current?.value])

  useEffect(() => {
    setCurrentValue(defaultValue || '')
    if (!defaultValue) {
      setLabelActive(false)
    } else {
      setLabelActive(true)
    }
  }, [defaultValue])

  useEffect(() => {
    if (![null, undefined].includes(inputRef.current.value)) {
      if (inputRef.current.value.length > 0) {
        setLabelActive(true)
      } else {
        setLabelActive(false)
      }
    } else {
      setLabelActive(false)
    }
  }, [inputRef])

  function handleCopy() {
    navigator.clipboard.writeText(inputRef.current.value)
    setStateToasts(true)
  }
  function ViewPassFn() {
    setViewPass((prevState) => !prevState)
  }

  function changeValue(el) {
    if ([undefined, null].includes(el.target.value)) {
      return
    }
    setCurrentValue(el.target.value)
    if (doChange) {
      doChange(el.target.value)
    }
  }

  return (
    <>
      <div className="input-yapp">
        <div className="content-input-bg">
          {externalLabel && (
            <label className="txt-3--bold m-0 mb-2" htmlFor={id}>
              {label}
            </label>
          )}
          <div className="position-relative">
            <input
              id={id}
              ref={inputRef}
              type={showPass ? (!viewPass ? 'password' : 'text') : 'text'}
              className={`input-custom-secondary font-regular w-100 ${classData || ''} ${
                label && (externalLabel ? '' : 'input-label-center')
              }`}
              style={type === 'tel' && codeArea ? {paddingLeft: '45px'} : {}}
              placeholder={externalLabel ? placeholder : ''}
              value={currentValue}
              onChange={changeValue}
              readOnly={props.disabled}
              // codeArea={codeArea}
              {...props}
            />
            {codeArea && (
              <span
                className={
                  'txt-5--regular' +
                  (externalLabel ? ' tel-codearea-light' : ' tel-codearea')
                }>
                {codeArea}
              </span>
            )}
          </div>
          {externalLabel
            ? null
            : label && (
                <label
                  className={`label ${labelActive && 'active'} ${
                    codeArea && 'active'
                  }`}
                  htmlFor={id}>
                  {labelActive ? label : placeholder ? placeholder : label}
                </label>
              )}
          {showPass && (
            <span
              className="view-pass c-pointer z-index-99"
              onClick={() => ViewPassFn()}>
              {' '}
              <i
                className={
                  viewPass ? 'icon-eye-on ' : 'icon-eye-on color-gray-4'
                }
              />
            </span>
          )}
          { inputRef?.current?.value.includes("@") && showCopyButton && (
            <div className="input-icon-right">
              <span
                className="view-pass c-pointer z-index-99"
                data-tip="Copiar correo"
                data-for="tooltip-share-prices"
                onClick={() => handleCopy()}>
                {icon_copy}
              </span>
              <ReactTooltip
                id="tooltip-share-prices"
                className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                effect="solid"
                place="top"
                backgroundColor="#ffffff"
                textColor="#000000"
                arrowColor="#FFFFFF"
              />
              <Toasts
                text="Correo copiado exitosamente"
                timer="5000"
                icon="icon-check"
                stateToasts={stateToasts}
                setStateToasts={setStateToasts}
              />
            </div>
          )}
          {showIconEdit && (
            <div className="input-icon-right">
              <span className="color-theme icon-edit-datepiker">
                {icon_edit}
              </span>
            </div>
          )}
          {showEdit && (
            <>
              <div className="label-edit">
                <span className="color-theme">Editar</span>
              </div>
              <p className="txt-paragraph d-flex justify-content-end mr-3 secondary-value">
                {secondaryValue}
              </p>
            </>
          )}
        </div>
        {error && (
          <p className="txt-6--regular-er mt-1 mb-2">{error.message}</p>
        )}
      </div>
    </>
  )
}

export default Input
