import { Fragment } from "react"
import './Tutorial.css'

function Tutorial ({className, src, alt, caption, step}) {
    return (
        <Fragment>
            <div className={className}> 
                <div className="figure">
                    <div className="img-container">
                        <img
                            className="step img-fluid mb-1"
                            src={src}
                            alt={alt}
                        />
                    </div>
                    <figcaption>
                        <div className="ellipse">
                            <p>{step}</p>
                        </div>
                        <span className="caption">{caption}</span>
                    </figcaption>
                </div>
            </div>
        </Fragment>
    )
}

export default Tutorial