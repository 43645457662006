const menuUC = {
  information: { title: 'Información al Paciente' },
  specialities: { title: 'Médicos y Especialidades' },
  procedures: { title: 'Exámenes y Procedimientos' },
  careNetwork: { title: 'Red de Atención' },
  agreements: { title: 'Convenios' },
  healthPlan: { title: 'Planes de Salud', link: 'planes-de-salud-uc' },
}

const subMenuUC = {
  information: {
    title: 'Información',
    parent: menuUC.information,
    items: [
      { text: 'Ley de Atención Preferente', link: 'informacion-al-paciente/atencion-preferente' },
      { text: 'Ley Derechos y Deberes', link: 'informacion-al-paciente/ley-de-derechos-y-deberes' },
      { text: 'Paciente de Regiones', link: 'informacion-al-paciente/paciente-de-regiones' },
      { text: 'Paciente Internacional', link: 'informacion-al-paciente/paciente-internacional' },
      { text: 'Servicio de Urgencias', link: 'especialidades-y-servicios/servicios/urgencia' }
    ]
  },
  ucchristus: {
    title: 'UC CHRISTUS',
    parent: menuUC.information,
    items: [
      { text: 'Apoyo Emocional Colaboradores', link: 'apoyoemocional' },
      { text: 'CHRISTUS Health / Compliance', link: 'informacion-al-paciente/christus-health' },
      { text: 'Quiénes Somos', link: 'informacion-al-paciente/quienes-somos' },
      { text: 'Misión y Responsabilidad', link: 'informacion-al-paciente/mision-y-responsabilidad-social' },
      { text: 'Trabaja con nosotros', link: 'contacto-trabaja-con-nosotros' }
    ]
  },
  indicators: {
    title: 'Indicadores',
    parent: menuUC.information,
    items: [
      { text: 'Foco Calidad', link: 'informacion-al-paciente/indicadores-clinicos/foco-calidad' },
      { text: 'Satisfacción', link: 'informacion-al-paciente/indicadores-clinicos/satisfaccion' },
      { text: 'Seguridad del Paciente', link: 'informacion-al-paciente/indicadores-clinicos/seguridad-del-paciente' }
    ]
  },
  connect: {
    title: 'Conéctate',
    parent: menuUC.information,
    items: [
      { text: 'Blog', link: 'blog-salud-uc' },
      { text: 'Charlas', link: 'blog-salud-uc/uc-christus-conecta/home-charlas-y-talleres' },
      { text: 'Podcast', link: 'informacion-al-paciente/podcast-de-salud' },
      { text: 'Red de Mensajes', link: 'informacion-al-paciente/red-de-mensajes' }
    ]
  },
  clinicalServices: {
    title: 'Servicios Clínicos',
    parent: menuUC.information,
    items: [
      { text: 'Unidad de Rescate', link: 'especialidades-y-servicios/unidades/unidad-de-rescate' },
      { text: 'Enfermería', link: 'daisy' },
      { text: 'Farmacia en línea', link: 'yapp' },
      { text: 'Telemedicina', link: 'red-de-atencion/centro-medico-virtual/telemedicina' },
      { text: 'Telemedicina Internacional', link: 'especialidades-y-servicios/servicios/telemedicina-internacional' },
      { text: 'Todo los Servicios Clínicos', link: 'red-de-atencion/hospital-clinico/servicios' },
      { text: 'UC CHRISTUS en Casa', link: 'informacion-al-paciente/uc-christus-en-casa' },
      { text: 'Banco de Sangre', link: 'especialidades-y-servicios/servicios/banco-de-sangre' },
      { text: 'Vacunatorio', link: 'especialidades-y-servicios/servicios/vacunatorios/vacunatorio' }
    ]
  },
  professionals: {
    parent: menuUC.specialities,
    items: [
      { text: 'Nuestros Profesionales', link: 'busca-tu-profesional/nuestros-profesionales' },
      { text: 'Especialidades', link: 'especialidades-y-servicios/especialidades', type: 'button' }
    ]
  },
  programs: {
    title: 'Programas y Centros',
    parent: menuUC.specialities,
    items: [
      { text: 'Centros', link: 'especialidades-y-servicios/centros' },
      { text: 'Programas', link: 'especialidades-y-servicios/programas' },
      { text: 'Unidades', link: 'especialidades-y-servicios/unidades' }
    ]
  },
  procedures: {
    parent: menuUC.procedures,
    items: [
      { text: 'Anatomía Patológica', link: 'especialidades-y-servicios/servicios/anatomia/anatomia-patologica' },
      { text: 'Diagnóstico por Imágenes', link: 'examenes-y-procedimientos/diagnostico-por-imagenes' },
      { text: 'Procedimientos Clínicos', link: 'examenes-y-procedimientos/procedimientos-clinicos' },
      { text: 'Atención Domiciliaria', link: 'examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras/domicilios' },
      { text: 'Laboratorio Clínico', link: 'examenes-y-procedimientos/laboratorio-clinico/sobre-el-laboratorio/laboratorios', type: 'button' }
    ]
  },
  results: {
    title: 'Resultado de Exámenes',
    parent: menuUC.procedures,
    items: [
      { text: 'Resultados Imágenes', link: '', host: 'https://imagenologia.ucchristus.cl/' },
      { text: 'Resultados Laboratorios', link: '', host: 'https://laboratorio.ucchristus.cl/' }
    ]
  },
  hospitals: {
    title: 'Clínicas y Hospitales',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Clínica San Carlos', link: 'red-de-atencion/clinica-san-carlos-de-apoquindo' },
      { text: 'Hospital Clínico', link: 'red-de-atencion/hospital-clinico' }

    ]
  },
  laboratory: {
    title: 'Laboratorio y Toma de Muestras',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Atención domiciliaria', link: 'examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras/domicilios' },
      { text: 'Laboratorio Clínico', link: 'examenes-y-procedimientos/laboratorio-clinico/sobre-el-laboratorio/laboratorios' },
      { text: 'Unidad de Toma de Muestras', link: 'examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras', type: 'button' }
    ]
  },
  medicalCenters: {
    title: 'Centros Médicos',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Centro Médico Alcántara', link: 'red-de-atencion/edificio-medico-alcantara' },
      { text: 'Centro Médico Bilbao', link: 'red-de-atencion/centro-de-diagnostico-bilbao' },
      { text: 'Centro Médico Escuela Militar', link: 'seguimos-creciendo' },
      { text: 'Centro Médico Irarrázaval', link: 'red-de-atencion/centro-medico-irarrazaval' },
      { text: 'Centro Médico Lira', link: 'red-de-atencion/centro-medico-lira' },
      { text: 'Centro Médico Marcoleta', link: 'red-de-atencion/hospital-clinico/servicios' },
      { text: 'Centro Médico Providencia', link: 'seguimos-creciendo' },
      { text: 'Centro Médico San Jorge', link: 'red-de-atencion/centro-medico-san-jorge' },
      { text: 'Centro Médico San Joaquín', link: 'red-de-atencion/centro-medico-san-joaquin' },
      { text: 'Centro Médico Santa Lucía', link: 'centros-medicos/centro-medico-santa-lucia' }
    ]
  },
  regionalMedicalCenters: {
    title: 'Centros Médicos Regiones',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Centro Médico Rancagua', link: 'red-de-atencion/centro-médico-rancagua' }
    ]
  },
  cesfam: {
    title: 'Cesfam',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Centro Ancora Juan Pablo II', link: 'red-de-atencion/cesfam-juan-pablo-ii' },
      { text: 'Centro Ancora San Alberto Hurtado', link: 'red-de-atencion/cesfam-san-alberto-hurtado' },
      { text: 'Centro Ancora Teresa de Calcuta', link: 'red-de-atencion/cesfam-madre-teresa-de-calcuta' }
    ]
  },
  virtualAssitant: {
    title: 'Atención virtual',
    parent: menuUC.careNetwork,
    items: [
      { text: 'Centro Médico Virtual', link: 'red-de-atencion/centro-medico-virtual' }
    ]
  },
  agreement: {
    title: 'Convenios',
    parent: menuUC.agreements,
    items: [
      { text: 'Convenios', link: 'convenios-uc-christus' },
      { text: 'Mi Plan Salud Integral (Colaboradores)', link: '', host: 'https://www.miplanintegral.cl' },
      { text: 'FONASA', link: 'convenios/fonasa' },
      { text: 'PAD Fonasa', link: 'convenios/pad-fonasa' },
      { text: 'Isapres', link: 'convenios/isapres' }
    ]
  },
  duty: {
    title: 'Aranceles',
    parent: menuUC.agreements,
    items: [
      { text: 'Aranceles', link: 'informacion-al-paciente/aranceles' },
      { text: 'Paga tu Cuenta', link: 'cuenta', host: 'https://pagos.ucchristus.cl/' },
      { text: 'Pide tu Presupuesto', link: 'presupuesto' }
    ]
  }
}

export { menuUC, subMenuUC }