import React from 'react'
import {useDispatch, connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {setOpenMenu} from '../../redux/modules/app'
import {arraySidenav} from '../../Utils/constants'
import AccordionSideMenu from './AccordionSideMenu'
import './SidenavMenu.css'
import {logEvent} from '../../Utils/utils'

function SidenavMenu(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const goTo = (page) => {
    if (page === '/pro/new_agreement') {
      logEvent('Yapp Benefit Opened', {
        Enabled: props?.professionalDiscountCode?.products?.length > 0,
      })
    }
    if (page === '/my_shopping') {
      logEvent('Purchase Opened')
    }
    dispatch(setOpenMenu(false))
    history.push(page)
  }

  return (
    <div className="pt-3 px-12">
      {arraySidenav.map((item, key) => {
        if (item.userType === process.env.REACT_APP_PROFILE) {
          return item.subMenu ? (
            <AccordionSideMenu
              key={key}
              doAction={goTo}
              header={item}
              arrayMenu={item.subMenu}
            />
          ) : (
            <button
              key={key}
              className={`btn-sidenav-explore mb-2 ${
                location.pathname === item.page ? 'btn-sidenav-active' : ''
              }`}
              onClick={() => goTo(item?.page)}>
              <i className={`${item.icon}` + ' color-theme'}></i>
              <span className="color-theme">{item.name}</span>
            </button>
          )
        }
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    professionalDiscountCode: state.user.professional_discount_code,
  }
}

export default connect(mapStateToProps)(SidenavMenu)
