import React from 'react'
import {
  View,
  Text,
  Document,
  Page,
  Font,
  Image,
  Svg,
  Path,
  Link,
} from '@react-pdf/renderer'
import QRCode from 'qrcode-svg'
import {productsUrl} from '../../Utils/constants'
import NunitoRegular from '../../assets/fonts/Nunito-Regular.ttf'
import NunitoBold from '../../assets/fonts/Nunito-Bold.ttf'
import NunitoLight from '../../assets/fonts/Nunito-Light.ttf'

Font.registerHyphenationCallback((word) => [word])

Font.register({
  family: 'Nunito',

  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'light',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoLight,
      fontWeight: 'ultralight',
    },
  ],
})

function ProgramPDF(props) {
  const {product} = props
  const qrcode = new QRCode({
    content: props.link,
    width: 70,
    height: 70,
    join: true,
    ecl: 'Q',
    container: 'svg-viewbox',
  })
  const qrCodeSvgString = qrcode.svg({container: 'none'})
  const pathStartIndex = qrCodeSvgString.indexOf(' d="') + 4

  const pathEndIndex = qrCodeSvgString.indexOf('"', pathStartIndex)
  const qrCodeSvgPathString = qrCodeSvgString.slice(
    pathStartIndex,
    pathEndIndex,
  )

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 20,
          paddingHorizontal: 16,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 8,
              marginHorizontal: 12,
              marginBottom: 8,
              paddingVertical: 2,
              paddingHorizontal: 2,
              width: 100,
              height: 32,
            }}
            fixed>
            <Image
              source={{
                uri: product?.program_logo,
                method: 'GET',
                headers: {'Cache-Control': 'no-cache'},
                body: '',
              }}
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 8,
              marginHorizontal: 12,
              marginBottom: 8,
              paddingVertical: 2,
              paddingHorizontal: 2,
              width: 100,
              height: 32,
            }}
            fixed>
            <Image src="https://s3.us-east-2.amazonaws.com/s3.yapp/logo-yapp.png" />
          </View>
        </View>

        {product?.product_id === 'a37eff9d-dad3-11ea-8ff7-0a57' ? (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: 744,
              marginVertical: 0,
              marginHorizontal: 12,
              paddingBottom: 0,
              paddingTop: 0,
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                order: 1,
              }}>
              <Text
                style={{
                  fontSize: 16,
                  marginTop: 24,
                  fontWeight: 'bold',
                  lineHeight: 1.9,
                }}>
                Ahorra con estos beneficios que te ha compartido tu médico
              </Text>
              <Text
                style={{
                  flexWrap: 'wrap',
                  fontSize: 12,

                  fontWeight: 'light',
                  maxWidth: 364,
                  lineHeight: 1.4,
                  marginBottom: 50,
                  letterSpacing: 0.3,
                }}>
                Compra en Yapp y recibe este programa de paciente en la puerta
                de tu casa. Escanea el código QR para acceder a Yapp.
              </Text>
            </View>
            <View style={{display: 'flex', alignItems: 'center', order: 2}}>
              <Svg
                style={{
                  display: 'flex',
                  width: 70,
                  height: 70,
                  alignItems: 'center',
                  marginBottom: 20,
                }}>
                <Path d={qrCodeSvgPathString} fill="black" />
              </Svg>
            </View>
          </View>
        ) : (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: 744,
              marginVertical: 0,
              marginHorizontal: 12,
              paddingBottom: 0,
              paddingTop: 0,
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                order: 1,
              }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    marginTop: 24,
                    fontWeight: 'bold',
                    lineHeight: 1.9,
                  }}>
                  Programa {product.program_name}
                </Text>
              </View>

              <Text
                style={{
                  flexWrap: 'wrap',
                  fontSize: 12,

                  fontWeight: 'light',
                  maxWidth: 700,
                  lineHeight: 1.4,
                  marginBottom: 50,
                  letterSpacing: 0.3,
                }}>
                Tu doctor te ha compartido este programa de paciente para{' '}
                {product.product_name}.
                {product.program_link ? (
                  <>
                    <Text
                      style={{
                        flexWrap: 'wrap',
                        fontSize: 12,

                        fontWeight: 'bold',
                        maxWidth: 700,
                        lineHeight: 1.4,
                        marginBottom: 50,
                        letterSpacing: 0.3,
                      }}>
                      Para inscribirte ingresa aquí
                    </Text>
                    <Link
                      style={{
                        fontWeight: 'bold',
                        lineHeight: 1.9,
                        fontSize: 12,
                        color: '#6578ff',
                      }}>
                      {product.program_link}
                    </Link>
                  </>
                ) : null}
              </Text>
            </View>
          </View>
        )}
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              maxWidth: 380,
              padding: 24,
              borderRadius: 8,
              backgroundColor: 'white',
              flexDirection: 'column',
              borderWidth: 1.5,
              borderStyle: 'solid',
              borderColor: '#dde0ec',
              justifyContent: 'space-between',
            }}>
            <Text
              style={{
                flexWrap: 'wrap',
                fontSize: 12,
                fontFamily: 'Nunito',
                fontWeight: '600',
                maxWidth: 364,
                lineHeight: 1.4,

                letterSpacing: 0.3,
              }}>
              {product?.product_name}
            </Text>
            <Text
              style={{
                flexWrap: 'wrap',
                fontSize: 12,
                fontFamily: 'Nunito',
                fontWeight: '600',
                maxWidth: 364,
                lineHeight: 1.4,
                letterSpacing: 0.3,
              }}>
              {product?.presentation}
            </Text>
            <Text
              style={{
                flexWrap: 'wrap',
                fontSize: 12,
                fontFamily: 'Nunito',
                fontWeight: '600',
                maxWidth: 364,
                lineHeight: 1.4,
                letterSpacing: 0.3,
              }}>
              {product?.formula_name}
            </Text>
            <Text
              style={{
                flexWrap: 'wrap',
                fontSize: 12,
                fontFamily: 'Nunito',
                fontWeight: '600',
                maxWidth: 364,
                lineHeight: 1.4,
                letterSpacing: 0.3,
              }}>
              {product?.laboratory}
            </Text>
            <Image
              style={{
                marginVertical: 15,
                marginHorizontal: 20,
                width: 230,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              source={{
                uri: productsUrl + product?.picture,
                method: 'GET',
                headers: {'Cache-Control': 'no-cache'},
                body: '',
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ProgramPDF
