import React from 'react'
import loadingYapp from '../../assets/img/yapp_loading.gif'

function LoadingCheckout(props) {
  return (
    <div className="flex-grow-1">
      <div className="content-responsive mx-auto">
        <div className="py-4 px-3 px-md-0">
          <div className="maxw-320 mx-auto text-center pt-4">
            {loadingYapp && (
              <img
                src={loadingYapp}
                alt="loading"
                height="80"
                className=" m-0"
              />
            )}
            <p className="txt-3--light-db m-0">
              {props.prePay
                ? 'Te estamos redirigiendo a la plataforma de pago... Solo demorará unos segundos.'
                : 'Estamos revisando tu pedido para enviarlo a la farmacia... Solo demorará unos segundos.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingCheckout
