import React, { useMemo } from 'react'
import ButtonYapp from '../Buttons/ButtonYapp'
/* imgs */
import iComming from '../../assets/img/i_comming.svg'
/* styles */
import './Comming.css'
import { useHistory } from 'react-router'
import { downloadApp } from '../../Utils/utils'

function Comming(props) {
  const history = useHistory()

  return (
    <div className='content-card-comming'>
      <img src={iComming} alt='Comming soon' className='img-comming ' />
      <h4 className='txt-1--bold-db mb-3 text-center'>Próximamente</h4>
      <p className='txt-4--regular-db mb-4 text-center'>Semana a semana estamos trabajando en nuevas funcionalidades para ti.</p>
      {props.showBtnGoLink && <div className='w-100 mb-2 px-3 '>
        <ButtonYapp
          className='w-100 mb-2'
          id='gtm-back-btn-share'
          title='Ir a la App'
          onClick={downloadApp}
        />
        {/* <ButtonYapp
          className='w-100'
          id='gtm-back-btn-share'
          title='Seguimiento de tu compra'
          onClick={() => goToLink('https://yapp.cl')}
        /> */}
      </div>}
      {/* <div className='px-3 w-100'>
        <ButtonYapp
          className='w-100'
          id='gtm-back-btn-share'
          title='Volver'
          onClick={() => history.goBack()}
        />
      </div> */}
    </div>
  )
}

export default Comming