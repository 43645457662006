import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Modal from 'react-modal'
import {connect, useDispatch} from 'react-redux'
import {getBioequivalents} from '../../../Utils/api'
import {getShoppingCart, saveShoppingCart} from '../../../Utils/service'
import loadingYapp from '../../../assets/img/yapp_loading.gif'
import {_formatPrice} from '../../../Utils/tools'
import {addEventNameWithParams} from '../../../Utils/utils'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import ModalPreview from '../../../Components/Modals/ModalPreview'
import {setGoRouter} from '../../../redux/modules/app'

function Bioequivalent(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [dataProduct, setdataProduct] = useState(null)
  const [equivalents, setEquivalents] = useState([])
  const TEXT_MAX_LENGTH = 35
  const [modalRemplaceIsOpen, setRemplaceIsOpen] = useState(false)
  const [productSelectedData, setProductSelected] = useState(null)
  const [pathname, setPathname] = useState('')
  const [loading, setLoading] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [modalImage, setModalImage] = useState([])

  useEffect(() => {
    try {
      if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
        let router = []
        router.push(
          {
            go: 'https://www.ucchristus.cl/yapp',
            target: '_blank',
            description: 'Red de Salud UC CHRISTUS',
          },
          {
            go: '/search',
            title: 'Bioequivalentes',
            description: 'Buscador de medicamentos',
          },
        )
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) {}

    let product = history.location.state?.productSelect
    let pathname = history.location.state?.pathname
    setPathname(pathname)
    setdataProduct(product)
    getBioequivalents(product.product_id)
      .then((el) => {
        if (el?.data) {
          setEquivalents(el?.data)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [])

  async function changeProductCart(productSelected) {
    try {
      let arrFirestore = await getShoppingCart()

      if (
        !arrFirestore.some((el) => el.product_id === productSelected.product_id)
      ) {
        let newProducts = arrFirestore.map((product) => {
          if (product.product_id === dataProduct.product_id) {
            return {...product, ...productSelected}
          } else {
            return product
          }
        })

        await saveShoppingCart(newProducts)

        addEventNameWithParams('bioequivalent_product_remplace', {
          new_product: productSelected.product_name,
          new_product_id: productSelected.product_id,
          replaced_product: dataProduct.product_name,
          replaced_product_id: dataProduct.product_id,
        })

        history.goBack()
      } else {
        console.log('Producto existente.')
      }
    } catch (error) {
      console.log('changeProductCart', error)
    }
  }

  const handleClose = () => {
    setShowPreview(false)
    setModalImage({})
  }

  function onClickShowPreview(product_logo) {
    setModalImage(product_logo)
    setShowPreview(true)
  }

  function openReplaceModal(props) {
    setProductSelected(props)
    setRemplaceIsOpen(true)
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setRemplaceIsOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={modalRemplaceIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="modal-custom py-4"
        overlayClassName="modal-custom-bg"
        contentLabel="RemplaceModal"
        ariaHideApp={false}>
        {productSelectedData && (
          <div className="product-detail-item ">
            <div
              onClick={() => null}
              className="d-flex flex-column h-100"
              id="gtm-webapp-bioequivalent-modal-open">
              <div className="product-detail-content-img d-flex justify-content-center text-center">
                <img
                  src={productSelectedData.product_logo}
                  className="product-detail-img mx-auto"
                  alt={productSelectedData.product_name}
                />
              </div>
              <div className="content-txt">
                <p className="txt-paragraph--bold line-14 mt-2 mb-2 break-word">
                  {productSelectedData.product_name}
                </p>
                <p className="txt-paragraph color-gray-4">
                  {productSelectedData.presentation}{' '}
                </p>
                <p className="txt-paragraph color-gray-4 mb-2">
                  {productSelectedData.laboratory_name}{' '}
                </p>
                <p className="txt-paragraph--small txt-bold mb-1">
                  Desde: {_formatPrice(productSelectedData.price_from)}
                </p>
                <p className="txt-paragraph--small txt-bold">
                  Hasta: {_formatPrice(productSelectedData.price_to)}
                </p>
              </div>
            </div>
          </div>
        )}
        <h4 className="title-primary--xl my-4 px-2">
          ¿Deseas reemplazar tu producto por este bioequivalente?
        </h4>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <ButtonYapp
            className="w-100 mb-3"
            id="gtm-webapp-bioequivalent-btn-remplace-modal"
            onClick={() => changeProductCart(productSelectedData)}
            title={'Reemplazar'}
            color={'--theme-color-secondary'}
          />
          <ButtonYapp
            className="w-100"
            id="gtm-webapp-bioequivalent-btn-back-modal"
            onClick={closeModal}
            title={'Mantener producto original'}
            color={'--theme-color'}
            stroke
          />
        </div>
      </Modal>
      <div
        className={`content-responsive flex-grow-1 px-md-0  ${
          true && 'patients'
        }`}
        id="gtm-webapp-bioequivalent">
        {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && (
          <div className="d-flex justify-content-between py-3">
            <div className="breadcrumbs c-pointer" onClick={history.goBack}>
              <div className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse"></i>
              </div>{' '}
              <span
                className="txt-4--regular-pr"
                style={{color: 'var(--theme-color)'}}>
                Volver
              </span>
            </div>
          </div>
        )}
        <div className="content-bioequivalent">
          <div>
            <h4 className="title-primary--xl mt-3">Tu producto</h4>
            <div className="pt-2">
              {dataProduct && (
                <div className="product-detail mb-4 ">
                  <div onClick={() => null} className="">
                    <div className="product-detail-content-img d-flex justify-content-center text-center">
                      <img
                        src={dataProduct.product_logo}
                        className="product-detail-img mx-auto"
                        alt={dataProduct.product_name}
                        onClick={() =>
                          onClickShowPreview(dataProduct.product_logo)
                        }
                      />
                    </div>
                    <div className="content-txt">
                      <p className="txt-paragraph--bold line-14 mt-2 mb-2 break-word">
                        {dataProduct.product_name.length > TEXT_MAX_LENGTH
                          ? `${dataProduct.product_name.substring(
                              0,
                              TEXT_MAX_LENGTH,
                            )}...`
                          : dataProduct.product_name}
                      </p>
                      <p className="txt-paragraph color-gray-4">
                        {dataProduct.presentation}{' '}
                      </p>
                      <p className="txt-paragraph color-gray-4 mb-2">
                        {dataProduct.laboratory_name}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {equivalents && equivalents.length > 0 ? (
              <h4 className="title-primary--xl mt-3">Alternativas similares</h4>
            ) : (
              !loading && (
                <div>
                  <h4 className="title-primary--xl mt-3">Alternativas similares</h4>
                  <h4 className="txt-5--regular-db m-0 mt-2">
                    Lo sentimos, no encontramos bioequivalentes para tu
                    medicamento.
                  </h4>
                </div>
              )
            )}
            <div
              className="d-flex flex-wrap bag-content bag-bioequivalent pt-2"
              style={{paddingLeft: '0px'}}>
              {loading ? (
                <div className="w-100 text-center">
                  <img
                    src={loadingYapp}
                    alt="loading"
                    className="loading-yapp--normal m-0"
                  />
                </div>
              ) : (
                equivalents &&
                equivalents.length > 0 &&
                equivalents.map((bioProduct, key) => {
                  return (
                    <div key={key} className="product-detail mb-4 ">
                      <div
                        onClick={() => null}
                        className="d-flex flex-column  h-100 w-100">
                        <div className="product-detail-content-img d-flex justify-content-center text-center">
                          <img
                            src={bioProduct.product_logo}
                            className="product-detail-img mx-auto"
                            alt={bioProduct.product_name}
                            onClick={() =>
                              onClickShowPreview(bioProduct.product_logo)
                            }
                          />
                        </div>
                        <div className="content-txt mb-3">
                          <p className="txt-paragraph--bold line-14 mt-2 mb-2 break-word">
                            {bioProduct.product_name}
                          </p>
                          <p className="txt-paragraph color-gray-4">
                            {bioProduct.presentation}{' '}
                          </p>
                          <p className="txt-paragraph color-gray-4 mb-2">
                            {bioProduct.laboratory_name}{' '}
                          </p>
                          <p className="txt-paragraph--small txt-bold mb-1">
                            Desde: {_formatPrice(bioProduct.price_from)}
                          </p>
                          <p className="txt-paragraph--small txt-bold">
                            Hasta: {_formatPrice(bioProduct.price_to)}
                          </p>
                        </div>
                        <div className="mt-auto w-100 product-btn">
                          <ButtonYapp
                            id={`gtm-webapp-bioequivalent-btn-remplace-${key}`}
                            onClick={() => openReplaceModal(bioProduct)}
                            title={'Reemplazar'}
                            color={'--theme-color-secondary'}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
        <ModalPreview
          show={showPreview}
          onClose={handleClose}
          img={modalImage}
        />
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGoRouter: (router) => dispatch(setGoRouter(router)),
  }
}
export default connect(null, mapDispatchToProps)(Bioequivalent)
