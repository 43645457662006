import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {connect, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import TextTruncate from 'react-text-truncate'
import {
  downloadFile,
  programPatientMessage,
  addEventNameWithParams,
  logEvent,
} from '../../Utils/utils'
import {base_url, shareMessages, productsUrl} from '../../Utils/constants'
import Modal from 'react-modal'
import Iframe from 'react-iframe'

import ModalShareWithColleages from '../../Components/Modals/ModalShareWithColleages'

import {_validateMail} from '../../Utils/tools'
import {changeLogo} from '../../redux/modules/app'
import {
  getProfessionalContent,
  getS3File,
  sendPatientProgramsDocuments,
  getVademecumDetail,
  getBioequivalents,
  getSubscriptionInfoByProductId,
} from '../../Utils/api'
import ProductDetailItems from '../../Components/ShoppingBag/ProductDetail'
import Video from '../../Components/VideoCustom/Video'
import ModalSendEmail from '../../Components/Modals/ModalSendEmail'
import loadingYapp from '../../assets/img/yapp_loading.gif'

import proIconLoading from '../../assets/img/proIconLoading.svg'

import './CardActionSmall.css'
import ModalTypeForms from '../../Components/Modals/ModalTypeForms'
import Toasts from '../../Components/Toasts/Toasts'
import ModalRecemed from '../../Components/Modals/ModalRecemed'
import HeaderDetail from '../../Components/Product/HeaderDetail'

function CardActionSmall(props) {
  return (
    <>
      <div className="card-action-small">
        <div className="w-100 pr-2">{props.children}</div>
        <div
          className={
            !props.hasFile && !props.hasFile
              ? 'card-action-small-btns pr-50'
              : 'card-action-small-btns'
          }>
          {props.hasFile && (
            <button
              id={props.type && `gtm-product-detail-${props.type}`}
              className="btn-default--small btn-next btn-ca-vmore px-4 mw-60"
              onClick={props.doAction}>
              {props.label ? props.label : 'Ver más'}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

function ProductDetail(props) {
  const {shareProduct} = shareMessages
  const [appState, setAppState] = useState({loading: false, data: null})
  const [productId, setProductId] = useState(null)
  const [externalVademecum, setExternalVademecum] = useState(null)
  const [bio, setBio] = useState(null)
  const [intervalId, setIntervalId] = useState(0)
  const [count, setCount] = useState(0)

  const [video, setVideo] = useState(null)
  const [medicalSample, setMedicalSample] = useState(null)
  const [studies, setStudies] = useState([])
  const [materials, setMaterials] = useState([])
  const [patientEmail, setPatientEmail] = useState(null)
  const [patientPrograms, setPatientPrograms] = useState(null)
  const [currentProgram, setCurrentProgram] = useState(null)
  const [labContact, setLabContact] = useState(null)
  const [hasProgramFiles, setHasProgramFiles] = useState(false)

  const [sendEmailStatus, setSendEmailStatus] = useState(null)
  const [checkbox, setCheckbox] = useState(false)

  const [modalState, setModalState] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [sendMailIsOpen, setSendMailIsOpen] = useState(false)
  const [modalLaboratory, setModalLaboratory] = useState(false)

  const [productDiscount, setProductDiscount] = useState(null)

  const [modalTypeFormsState, setModalTypeFormsState] = useState(false)
  const [urlTypeForm, setUrlTypeForm] = useState(null)
  const [stateToasts, setStateToasts] = useState(false)

  const [sendDiscountIsOpen, setSendDiscountIsOpen] = useState(false)
  const [modalRecemed, setModalRecemed] = useState(false)

  const isProHash = useSelector((state) => state.app.isPro)
  const resultSearch = useSelector((state) => state.search.result)

  const themeColor = useSelector((state) => state.app.themeColor)
  const subscriptionProProduct = useSelector(
    (state) => state.app.subscriptionProProduct,
  )

  const history = useHistory()

  const url = `${base_url}/pro/home/${localStorage.getItem(
    'hash',
  )}?product=${productId}`

  const {id} = useParams()

  useEffect(() => {
    if (id) {
      loadProduct(id)
    }
  }, [id])

  useEffect(() => {
    if (props?.results?.product_id) {
      loadProduct(props?.results?.product_id)
    }
  }, [props?.results])

  useEffect(() => {
    if (count === 10) materialCheck('Video')
  }, [count])

  async function loadProduct(product_id) {
    try {
      let result = resultSearch
      let isMounted = true
      setAppState({loading: true})
      getVademecumDetail(
        product_id,
        history.location.state?.external_vademecum,
        result,
      )
        .then((resp) => {
          if (isMounted) setAppState({loading: false, data: resp?.data})
        })
        .catch((err) => {
          console.log(err)
        })
      getProfessionalContentData(product_id, true)
      getBioequivalentsProducts(product_id)
      setProductId(product_id)

      if (props?.professionalDiscountCode?.products?.length > 0) {
        let product = props.professionalDiscountCode?.products.filter(
          (product) => product.product_id === product_id,
        )

        if (product.length > 0) {
          let subscriptionPrice = await getSubscriptionInfoByProductId(
            product_id,
          )

          if (
            subscriptionPrice?.data?.length > 0 &&
            subscriptionPrice?.data[0]?.min_price
          ) {
            let minPrice = subscriptionPrice.data[0].min_price
            product[0].subscription_price = minPrice
            product[0].subscription_price_with_discount =
              (1 - product[0].discount_value / 100) * minPrice
          }
          setProductDiscount(product[0])
        }
      }
    } catch (err) {}
  }

  function handlePlay(value) {
    if (value) {
      addEventNameWithParams('gtm-product-detail-video', {
        product_name: appState?.data?.product_name,
        product_id: productId,
      })
      const newIntervalId = setInterval(() => {
        setCount((prevCount) => prevCount + 1)
      }, 1000)
      setIntervalId(newIntervalId)
    } else {
      clearInterval(intervalId)
      setIntervalId(0)
    }
  }

  function goToAction(program) {
    logEvent('Patient Program Modal Opened', {
      'Material Type': 'Program',
      'Origin Material': 'Medication Sheet',
      'Material Name': program.program_title,
      'Material Id': program.program_id,
      'Product Name': props?.results?.product?.product_name,
      'Laboratory Name': props?.results?.product?.laboratory_name,
    })

    addEventNameWithParams('gtm-detail-open-program', {
      program_title: program.program_title,
      product_id: productId,
    })
    for (let file of program.file) {
      if (![undefined, null, ''].includes(file.file_link)) {
        setHasProgramFiles(true)
        break
      }
    }
    setCurrentProgram(program)
    setIsOpen(true)
  }

  function closeEmailModal() {
    setIsOpen(false)
    setSendEmailStatus(null)
    setSendMailIsOpen(false)
    setCheckbox(false)
    setPatientEmail(null)
    setHasProgramFiles(false)
  }

  function openSendMailModal(program) {
    addEventNameWithParams('gtm-detail-send-email', {
      program_title: program.program_title,
      product_id: productId,
    })
    setIsOpen(false)
    setSendMailIsOpen(true)
  }

  function downloadProgram(program) {
    addEventNameWithParams('gtm-detail-download-program', {
      program_title: program.program_title,
      product_id: productId,
    })
    materialCheck('Program', {
      materialName: program?.program_title,
      materialId: program?.program_id,
    })
    currentProgram?.file.map((file) => {
      return downloadFile(file.file_link, file.file_name, 'pdf')
    })
    setIsOpen(false)
  }

  async function sendProgramsToPatient() {
    if (_validateMail(patientEmail)) {
      materialCheck('Program', {
        MaterialName: currentProgram.program_title,
        MaterialId: currentProgram.program_id,
      })
      addEventNameWithParams('gtm-detail-send-program-to-patient', {
        title: currentProgram.program_title,
        product_id: productId,
        checkbox: checkbox,
      })
      const template = 'd-1f1167f0cbfd41d6883cfbd7672fb2fa'
      const sender = 'soporte@yapp.cl'
      const {topic, description} = programPatientMessage(
        currentProgram,
        appState,
      )
      const result = await sendPatientProgramsDocuments(
        template,
        sender,
        patientEmail,
        checkbox,
        labContact,
        currentProgram,
        topic,
        description,
      )
      setSendEmailStatus(true)
    } else {
      setSendEmailStatus(false)
    }
  }

  async function openFiles(item, type) {
    addEventNameWithParams('gtm-detail-open-file-' + type, {
      title: item?.title,
      product_id: productId,
    })
    materialCheck(type, {
      materialId:
        type === 'Material'
          ? item?.laboratory_material_id
          : item?.clinical_study_id,
      materialName: item?.title,
    })
    window.open(item?.file, '_blank')
  }

  // Getters
  function getStudies(item) {
    item.content.forEach((data) => {
      getS3File(data.file, process.env.REACT_APP_BUCKETS_DESIGN)
        .then((res) => {
          if (![undefined, null, ''].includes(data.file)) {
            setStudies((prevStudies) => [
              ...prevStudies,
              {...data, file: res.data.url},
            ])
          }
        })
        .catch((err) => console.log(err))
    })
  }

  function getMaterials(item) {
    item.content.forEach((data) => {
      if (['PDF'].includes(data.format)) {
        getS3File(data.file, process.env.REACT_APP_BUCKETS_DESIGN)
          .then((res) => {
            if (![undefined, null, ''].includes(data.file)) {
              setMaterials((prevMaterial) => [
                ...prevMaterial,
                {...data, file: res.data.url},
              ])
            }
          })
          .catch((err) => console.log(err))
      } else {
        let fileLink =
          data.file &&
          (data.file.includes('http://') || data.file.includes('https://'))
            ? data.file
            : 'https://' + data.file
        if (![undefined, null, ''].includes(data.file)) {
          setMaterials((prevMaterial) => [
            ...prevMaterial,
            {...data, file: fileLink},
          ])
        }
      }
    })
  }

  const getProfessionalContentData = async (id, isMounted) => {
    try {
      const result = await getProfessionalContent(
        id,
        [undefined, null, false].includes(externalVademecum) ? 0 : 1,
        isProHash === true ? 1 : 0,
      )

      if (result?.data?.length > 0 && isMounted) {
        await setProfessionalContentData(result.data)
      }
      if (result.data?.length > 1) {
        const dataLab = result?.data[2]
        if (dataLab.type === 'laboratory_contact' && dataLab.content !== null)
          props.setContactLaboratory(dataLab.content)
      }
      /* if (result?.data) */
    } catch (error) {
      console.log(error)
    }
  }

  const getBioequivalentsProducts = async (product_id) => {
    try {
      let bioProducts = await getBioequivalents(product_id)
      if (bioProducts?.data) {
        setBio(bioProducts?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Setters
  const setProfessionalContentData = async (data) => {
    if (data.length > 0) {
      for (const item of data) {
        if (item.content) {
          switch (item.type) {
            case 'video':
              let link = await getS3File(
                item.content.video,
                process.env.REACT_APP_BUCKETS_DESIGN,
              )
              let url = link.data?.url
              if (url) {
                setVideo(link)
              }
              break
            case 'medical_sample':
              setMedicalSample(item.content.medical_samples_form)
              break
            case 'laboratory_contact':
              setLabContact({
                phone: item.content.laboratory_phone,
                email: item.content.laboratory_email,
                logo: item.content.laboratory_logo,
              })
              break
            case 'clinical_study':
              getStudies(item)
              break
            case 'material':
              if (item.content) getMaterials(item)
              break
            case 'program':
              let programs = []
              item.content.forEach((data) => {
                let flag = false
                // checking if a file has a null file link
                for (let i = 0; i < data.program.file?.length; i++) {
                  if (
                    !['', undefined, null].includes(
                      data.program.file[i].file_link,
                    )
                  ) {
                    flag = true
                    break
                  }
                }
                programs.push({
                  ...data.program,
                  file: flag ? data.program.file : null,
                })
              })
              setPatientPrograms(programs)
              break
            default:
              break
          }
        }
      }
    }
  }

  function onContactLab() {
    setModalLaboratory(true)
  }

  function copyCodeToClipboard() {
    logEvent('Item Copied', {
      'Item Type': 'Yapp Benefit Code',
      'Element Copied': props.professionalDiscountCode.code,
    })
    const tempInput = document.createElement('input')
    tempInput.value = props.professionalDiscountCode.code
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setStateToasts(true)
  }

  function closeDiscountModal() {
    setSendDiscountIsOpen(false)
  }

  function materialCheck(type, data) {
    logEvent('Material Checked', {
      'Material Type': type,
      'Origin Material': 'Medication Sheet',
      'Material Id': data?.materialId,
      'Material Name': data?.materialName,
      'Product Id': productId,
      'Product Name': appState?.data?.product_name,
      'Lab Id': props.contactLaboratory?.laboratory_id,
      'Lab Name': appState.data?.laboratory_name,
    })
  }
  function goBack() {
    history.goBack()
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closeEmailModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}>
        <h4 className="title-primary--xl-bold line-14 text-center">Programa</h4>
        <h4 className="title-primary--xl-bold line-14 text-center">
          {currentProgram?.program_title}
        </h4>
        <div className="d-flex flex-column justify-content-center mb-3">
          <p className="title-primary--regular text-center mb-4">
            {currentProgram?.program_description}
            {/*[breve texto dinámico descripción del programa por parte del laboratorio ] máximo 9 líneas de texto*/}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <button
            id="gtm-webapp-modal-send-email-action"
            className="btn-default btn-next btn-next-icon mb-3 "
            variant="primary"
            onClick={() => openSendMailModal(currentProgram)}>
            <i
              id="gtm-webapp-modal-send-email-action"
              className="icon-send-pro"></i>{' '}
            Enviar correo a paciente
          </button>
          {hasProgramFiles && (
            <button
              id="gtm-webapp-modal-download-program"
              className="btn-default btn-next btn-stroke btn-next-icon"
              variant="primary"
              onClick={() => downloadProgram(currentProgram)}>
              <i
                id="gtm-webapp-modal-download-program"
                className="icon-download"></i>{' '}
              Descargar programa
            </button>
          )}
        </div>
      </Modal>
      <ModalSendEmail
        data={null}
        sendEmailStatus={sendEmailStatus}
        patientEmail={patientEmail}
        setPatientEmail={setPatientEmail}
        sendMailIsOpen={sendMailIsOpen}
        closeEmailModal={closeEmailModal}
        sendProgramsToPatient={sendProgramsToPatient}
        setCheckbox={setCheckbox}
        appState={appState}
        currentProgram={currentProgram}
      />
      <div
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }></div>
      <div
        className={`flex-grow-1 px-3 ${isProHash ? '' : 'pb-5'}`}
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="product-details-content">
          {appState?.loading ? (
            <div className="text-center image-center">
              <h2 className="title-primary--3xl-bold mt-3">
                En breve verás tu búsqueda
              </h2>
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--pro m-0"
              />
              <img
                src={proIconLoading}
                alt="Loading icon"
                className="maxw-290"
              />
            </div>
          ) : (
            <>
              <div className="">
                {id && (
                  <>
                    <div className="py-3">
                      <div className="d-flex justify-content-between py-1 px-0 px-md-0">
                        <div className="breadcrumbs c-pointer" onClick={goBack}>
                          <a className="d-flex align-items-center">
                            <i className="icon-chevron-right icon-reverse"></i>
                          </a>
                          <span className="txt-4--regular-pr">Volver</span>
                        </div>
                      </div>
                    </div>
                    {appState?.data && (
                      <HeaderDetail
                        product={{
                          ...appState?.data,
                          product_logo: appState?.data?.product_image,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <ModalTypeForms
        btnClose={true}
        modalState={modalTypeFormsState}
        setModalState={setModalTypeFormsState}>
        <Iframe
          url={urlTypeForm}
          width="100%"
          id="myId"
          className="content-type-form"
        />
      </ModalTypeForms>
      <ModalShareWithColleages
        modalState={modalState}
        setModalState={setModalState}
        url={url}
        title={shareProduct.title}
        description={shareProduct.description}
      />
      <ModalRecemed isOpen={modalRecemed} setIsOpen={setModalRecemed} />
      {stateToasts && (
        <Toasts
          text="Código copiado"
          timer="5000"
          icon="icon-check"
          stateToasts={stateToasts}
          setStateToasts={setStateToasts}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    products: state.cart.products,
    professionalDiscountCode: state.user.professional_discount_code,
    userInfo: state.user.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
