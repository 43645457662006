import React from 'react'
import Select, { components } from 'react-select'

function SimpleSelect(props) {
  const CustomSelectStyle = {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      height: '48px',
      zIndex: 99,
      boxShadow: '0 rgba(0,0,0,0)',
      border: '1px solid #DDE0EC;',
      display: 'flex',
      fontFamily: 'var(--font-regular)',
      fontSize: 'var(--font-12)',
      lineHeight: 'var(--font-16)',
      color: 'var(--color-darkblue-ds)',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      width: '100%',
      paddingTop: '8px',
      marginLeft: '0px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '16px',
      fontSize: '10px',
      position: 'unset',
      color: '#A9ABBB',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'block',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '31px',
      zIndex: 999,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      padding: 0,
      overflowY: 'auto',
      maxHeight: '10rem',
      borderRadius: '0.5rem',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      width: '100%',
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(77, 186, 182, 0.2)' : null,
      borderBottom: '1px solid #DDE0EC',
      color: '#282E55',
      padding: 0,
      fontFamily: 'var(--font-regular)',
      fontSize: 'var(--font-12)',
      lineHeight: 'var(--font-16)',
      color: 'var(--color-darkblue-ds)',
      height: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(77, 186, 182, 0.2)',
        fontSize: '12px',
      }
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="box-arrow-select mt-1">
            <i className="icon-chevron-down color-lilac" />
          </div>
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <div className="position-relative">
      {/* {props?.value && props?.placeholder && (<div className="label-text-frecuency">
        {props?.placeholder}
      </div>)} */}
      <Select
        components={{ DropdownIndicator }}
        options={props.options}
        styles={CustomSelectStyle}
        value={props?.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        isSearchable={false}
        className={props.className}
      />
    </div>
  )
}

export default SimpleSelect
