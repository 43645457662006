import Modal from 'react-modal'

import icAlertLocation from '../../assets/img/i_alert_location.svg'
import ButtonYapp from '../Buttons/ButtonYapp'

function ModalAlertLocation(props) {
  const { visibleAlertLocation, setVisibleAlertLocation } = props

  function closeModalAlertLocation() {
    setVisibleAlertLocation(false)
  }

  function onFocus() { }

  return (
    <>
      <Modal
        isOpen={visibleAlertLocation}
        onAfterOpen={null}
        onRequestClose={closeModalAlertLocation}
        className="modal-custom modal-custom-expand"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalWelcomeHome"
        ariaHideApp={false}>
        <button className="btn-circle-close" onClick={closeModalAlertLocation}>
          <i className="icon-close"></i>
        </button>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={props.icon ? props.icon : icAlertLocation}
            alt="Error location"
            className="mb-3"
            width='76'
            height='70'
          />
          <h4 className="txt-4--bold-db text-center  mb-2">
            {props.title}{' '}
          </h4>
          <p className="txt-4--regular-db text-center mb-4">
            {props.message}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <ButtonYapp
            className='mb-3 w-100 maxw-214'
            id='web-patiens-close-alert-location'
            title='¡Entiendo!'
            onClick={() => closeModalAlertLocation()}
          />
        </div>
      </Modal>
    </>
  )
}

export default ModalAlertLocation
