import React, {useEffect, useState} from 'react'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {setHeaderName, setCommune} from '../../../redux/modules/app'
import {fetchCommunes} from '../../../redux/modules/delivery'
import ProductCard from './components/ProductCard'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import ModalSelectComune from './components/ModalSelectComune'
import { getProductsByCategoryFromApi, getConfigSite } from '../../../Utils/api'
import { loginAnonymoust } from '../../../Utils/auth'
import {fetchUser} from '../../../redux/modules/user'
const _get = require('lodash/get')


function LandingByCategory() {

  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {category_id} = useParams()
  const dispatch = useDispatch()
  const communeInStore = useSelector((state) => state?.app?.commune)
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [categoryName, setCategoryName] = useState('Medicamentos')

  if ( !communeInStore ) {
    dispatch(setCommune({
      id: 13101,
      name: "Santiago"
    }))
  }

  useEffect(() => {
    dispatch(setHeaderName('Compra tus medicamentos'))
  }, []);

  useEffect(() => {
    const singInAnonymously = async () => {
      let response = await loginAnonymoust()
      dispatch(fetchUser())
      let localData = {}
      let res = null
      res = await getConfigSite()
      localData = {
        hash: response?.data?.uid,
        origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
        site: res?.data,
        client_config: {
          delivery_time: _get(
            res?.data,
            'config[0].config.delivery_time',
          ),
        },
      }
      localStorage.setItem('LOCAL_DATA', JSON.stringify(localData))
      dispatch(fetchCommunes())

    }
    singInAnonymously().catch(console.error);


    getProductsByCategoryFromApi(category_id, communeInStore?.id)
    .then((response) => {
      let productos = response?.data ?? []
      setProducts(productos)
      setCategoryName(productos?.[0]?.category_name ?? "Medicamentos")
      setIsLoading(false)
    })
    .catch((err) => {
      console.log('Error in api getProductsByCategoryFromApi()')
    })
    .finally(() => {
      setIsLoading(false)
    })

  }, [communeInStore]);


  return (
    <div className="container-fluid" style={{ backgroundColor: '#F4F6FF', height: '100%', overflow: 'auto' }}>
      {
        isLoading && (
          <div>Cargando...</div>
        )
      }

      {
        !isLoading && (
          <>
            <ModalSelectComune 
              modalIsOpen={modalIsOpen} 
              setModalIsOpen={setModalIsOpen}  
            />

            <h4 className="title-quotation color-secondary my-4 ml-3">
              {categoryName} para ti
            </h4>
            <div className='d-flex container-fluid'>
              <div className='row'>
                {products.length < 1 && (
                  <>
                    <div className='col-12'>
                      No se encontraron productos para la comuna seleccionada...
                    </div>
                    <div className='col-12 mt-3'>
                      <ButtonYapp
                        className=""
                        onClick={() => setModalIsOpen(true)}
                        color={'--theme-color'}
                        title="Seleccione otra comuna"
                      />
                    </div>
                  </>
                )}
                { products.map((e, i) => <ProductCard product={e} key={i} />) }
              </div>
            </div>
          </>
        )
      }



    </div>
  )
}


  
export default LandingByCategory