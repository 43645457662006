import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TrackStep from '../../Components/TrackStep/TrackStep'
import { trackingOrder } from '../../Utils/api'
import { _formatPrice } from '../../Utils/tools'
import { useDispatch } from 'react-redux'
import icTrackingRechazo from '../../assets/img/ic_tracking_rechazo.svg'
import icTrackingCancelado from '../../assets/img/ic_tracking_cancelado.svg'
import { changeLogo, showLogo, setThemeColor } from '../../redux/modules/app'

import loadingYapp from '../../assets/img/yapp_loading.gif'
import biceVidaPet from '../../assets/img/bice-vida-welcome.png'
import farmaciaFacil from '../../assets/img/farmacia-facil.png'

const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes';
const ERROR_STATES_OC = ['rechazado', 'cancelado']

function Tracking(props) {
  const { hash } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState(null)
  const [status, setStatus] = useState(null)
  const [data, setData] = useState([
    { text: 'Pedido pagado', icon: 'icon-store-check', status: 'active' },
    { text: 'Confirmado por farmacia', icon: 'icon-pharmacy-check', status: '' },
    { text: 'Pedido en camino', icon: 'icon-delivery', status: '' },
    { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
  ])

  useEffect(() => {
    trackingOrder(hash)
      .then((resp) => {
        let info = resp?.data?.response?.info
        let status = info?.status?.toLocaleLowerCase()
        setStatus(status)
        setInfo(info)

        if (process.env.REACT_APP_DISABLED_THEME_CLIENT === 'TRUE') {
          dispatch(changeLogo(null));
          dispatch(showLogo(false));
          dispatch(setThemeColor({ primary: '#6578FF', secondary: '#4DBAB6' }));
        } 
        else {
          if (['NULL', undefined].includes(process.env.REACT_APP_LOGO)) {
            dispatch(showLogo(false))
          } 
          else {
            dispatch(showLogo(true))
            dispatch(changeLogo(process.env.REACT_APP_LOGO))
            dispatch(setThemeColor({ 
              primary: process.env.REACT_APP_THEME_PRIMARY_COLOR,
              secondary: process.env.REACT_APP_THEME_SECONDARY_COLOR
            }));
          }
        }
        if (!!info) {
          let tracking = resp?.data?.response?.tracking
          if (tracking && !['rechazado'].includes()) {
            tracking = data.map((el, index) => {
              return {
                ...el,
                status: tracking[index]?.completed === 1 ? 'active' : '',
              }
            })
            setData(tracking)
          }
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [])

  const stepNumberActive = data.filter((item) => {
    let active = 'active'
    return item.status.toLocaleLowerCase().indexOf(active) > -1
  })

  return (
    <div className="wrapper-location mt-2 position-relative">
      {loading && (
        <img
          src={loadingYapp}
          alt="loading"
          className="loading-yapp--alternative m-0"
        />
      )}
      {!loading && (
        <>
          { clientName === 'Bice Vida' && (
          <div className={`d-flex justify-content-center mx-auto mt-4 maxw-200`}>
            <img className='w-100' src={biceVidaPet} alt={'Bice Vida'} />
          </div>
          )}

          {' '}
          {!ERROR_STATES_OC.includes(status) && (
            <>
              <h2 className="title-primary--xxl text-center mb-2 mt-3">
                ¡Sigue tu pedido!
              </h2>
              <div>
                <TrackStep active={stepNumberActive.length} data={data} />
              </div>
            </>
          )}
          {status === 'rechazado' && (
            <div className="content-msg-tracking">
              <img
                src={icTrackingRechazo}
                alt="pedido fue rechazado"
                className="mx-auto mb-2"
              />
              <h2 className="title-primary--xxl-regular text-center mb-0">
                Tu pedido fue rechazado por la farmacia
              </h2>
            </div>
          )}
          {status === 'cancelado' && (
            <div className="content-msg-tracking">
              <img
                src={icTrackingCancelado}
                alt="cancelado tu pedido"
                className="mx-auto mb-2"
              />
              <h2 className="title-primary--xxl-regular text-center mb-0">
              Tu pedido fue cancelado
              </h2>
            </div>
          )}
          {info ? (
            <div className="">
              <div
                className={`content-order ${ERROR_STATES_OC.includes(status) && 'content-order-disabled'
                  }`}>
                <div className="mr-3 content-order-icon">
                  <i className="icon-wallet"></i>
                </div>
                <div>
                  <h4 className="txt-paragraph txt-bold">
                    Orden Nº{info?.purchase_order_id}
                  </h4>
                  <h4 className="txt-paragraph--light ">
                    Comprado el{' '}
                    {moment.utc(info?.purchase_at).format('DD-MM-YYYY')}
                  </h4>
                  <h4 className="txt-paragraph--light ">
                    {info?.quantity} productos comprados
                  </h4>
                  <h4 className="txt-paragraph--light  ">
                    Total pagado{' '}
                    <span className="">{_formatPrice(info?.total)}</span>
                  </h4>
                </div>
              </div>
              {!ERROR_STATES_OC.includes(status) && (
                <div className="content-order">
                  <div className="mr-3 content-order-icon">
                    <i className="icon-home-check"></i>
                  </div>
                  <div>
                    <h4 className="txt-paragraph txt-bold">
                      Dirección de envío
                    </h4>
                    <h4 className="txt-paragraph--light ">{info?.address}</h4>
                    <h4 className="txt-paragraph--light ">
                      Teléfono: {info?.phone}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="content-order">Sin información de tracking</div>
          )}
          <div>
            {!ERROR_STATES_OC.includes(status) ? (
              <a
                href={`mailto:soporte@yapp.cl?Subject=N° Orden ${info?.purchase_order_id}`}
                id="gtm-webapp-track-support"
                className="btn-default--small btn-stroke btn-more-info mt-5 mx-auto link-mail maxw-250"
                onClick={() => null}>
                Contactar soporte
              </a>
            ) : (
              <>
                <p className="txt-paragraph text-center mt-4 mb-3">
                  ¿Necesitas ayuda?
                </p>
                <a
                  href={`mailto:soporte@yapp.cl?Subject=N° Orden ${info?.purchase_order_id}`}
                  id="gtm-webapp-track-support"
                  className="btn-default--small btn-stroke btn-more-info mx-auto link-mail maxw-135"
                  onClick={() => null}>
                  Contáctanos
                </a>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Tracking
