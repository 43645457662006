import './TrackStep.css'
function TrackStep(props) {
  const stepsArray = props.data
  return (
    <div className="step-tracker-content">
      <ul className="step-tracker">
        {stepsArray &&
          stepsArray.map((step, key) => {
            return (
              <li key={key} className={`step-tracker-item ${step.status}`}>
                <div
                  className={`step-track--by-step ${
                    props.active === key + 1 && 'step-track--by-step-active'
                  }`}
                />
                <div className={`step-track--separation`}>
                  <span className="middle" />
                  <span className="center" />
                </div>
                <div className="step-track--description text-truncate w-100">
                  <i className={step.icon}></i>
                  <span className="w-100 text-truncate">{step.text}</span>
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default TrackStep
