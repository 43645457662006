import React from 'react'

import pillyHappy from '../../assets/img/i_pill_success.svg'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'

function RegisterSuccess() {
  return (
    <div className="flex-grow-1">
      <div className="content-responsive mx-auto px-3 px-md-0 pt-5 pb-4">
        <h2 className="txt-1--bold-db text-center mb-0">¡Has creado tu cuenta con éxito!</h2>
        <div className="d-flex justify-content-center">
          <img id={"gtm-webapp-register-success-img"} src={pillyHappy} alt="Registro exitoso" className="pill-happy my-4" />
        </div>
        <ButtonYapp
          id={"gtm-webapp-register-success-btn"}
          className="txt-4--regular-li size-content-responsive-btn mx-auto w-100 mb-5"
          disabled={false}
          title={'Continuar'}
          onClick={() => null}
        />
      </div>
    </div>
  )
}

export default RegisterSuccess
