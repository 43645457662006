import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

/* Imgs */
import logoYapp from '../assets/img/logo_yapp.svg'
import IcAdmin from '../assets/img/avatar_admin.svg'
import {setOpenMenu} from '../redux/modules/app'
import InstallButton from './InstallButton/InstallButton'

function NewHeader(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const isOpenMenu = useSelector((state) => state?.app?.openMenu)
  const headerName = useSelector((state) => state?.app?.headerName)

  const openModal = () => {
    isOpenMenu ? dispatch(setOpenMenu(false)) : dispatch(setOpenMenu(true))
  }

  return (
    <div className="bg-white top-section ">
      <div className="brand-space">
        <div
          className="content-menu-mobile c-pointer"
          onClick={() => openModal()}>
          <i className="icon-menu-hamb"></i>
        </div>
        <div className="content-brand">
          <img className="brand" src={logoYapp} alt="Yapp" />
        </div>
      </div>
      <div className="content-titles-header d-flex align-items-center justify-content-between">
        <p
          className={`${
            ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE)
              ? 'txt-1--regular-db'
              : 'txt-1--bold-db'
          } `}>
          {headerName}
        </p>
        {process.env.REACT_APP_CLIENT_NAME === 'Médicos' &&
          <>
            <hr className="hr-vertical d-none d-lg-block"/>
            <div className="d-none d-lg-block">
              <p className="txt-3--bold">
                Descarga Yapp Médicos en tu computador
              </p>
              <InstallButton text={'Instalar Yapp Médicos'}/>
            </div>
          </>
        }
        {/* { ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE) && <div className='position-relative d-flex align-items-center'>
          <i className="icon-bell notification-icon" />
          <div className="notification-container position-absolute d-flex justify-content-center align-items-center">
            <p className="notification-number mb-0">1</p>
          </div>
        </div>} */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    logo: state.app?.logo,
    showLogo: state.app?.show_logo,
    showAvatar: state.app?.show_avatar,
    config: state.app?.config,
  }
}

export default NewHeader
