import { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { _formatPrice } from '../../../Utils/tools'
import { getLocalData } from '../../../Utils/storage'
import {
  getGenerateQuotation,
  getSubscriptionInfoByProductId,
} from '../../../Utils/api'
import { isMaxScreenResposive } from '../../../Utils/constants'
import { getShoppingCart, saveRecentlyViewed } from '../../../Utils/service'
import {
  changeLogo,
  showLogo,
  setThemeColor,
  setGoRouter,
} from '../../../redux/modules/app'
import { addEventNameWithParams, logEvent } from '../../../Utils/utils'
import Modal from 'react-modal'
import loadingYapp from '../../../assets/img/yapp_loading.gif'
import icErrorProducts from '../../../assets/img/error_products.svg'
import pillSeeking from '../../../assets/img/pill_seeking.svg'
import PharmacyCard from '../../../Components/PharmacyCard/PharmacyCard'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import ProductCard from '../../Category/LandingByCategory/components/ProductCard'

import ModalLogin from '../../../Components/Modals/ModalLogin'
import SubscriptionCard from '../../../Components/Card/SubscriptionCard'

import LoadingSura from '../../../Components/Loading/LoadingSura'
import TypeSidetab from '../../../Components/TypeForm/TypeSidetab'
import EmptyView from '../../../Components/EmptyView/EmptyView'
import useScreenSize from '../../../Hooks/useScreenSize'

import biceVidaPet from '../../../assets/img/bice-vida-result.png'
import biceVidaBandAid from '../../../assets/img/bice-vida-empty-result.png'

const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes';
const allowSubscriptions = clientName === 'Pacientes';
const showNotFoundProducts = clientName === 'Vida Security';
const suraLoader = clientName === 'Sura';

const isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE';
const patientProfile = process.env.REACT_APP_PROFILE === 'PATIENT';
const _ = require('lodash')

function ShoppingBagQuotation(props) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { width } = useScreenSize()

  const [appQuotationState, setAppQuotationState] = useState({ data: null })
  const [loading, setLoading] = useState(true)
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [isDesktopWidth, setIsDesktopWidth] = useState(null)
  const [modalErrorProductIsOpen, setModalErrorProductIsOpen] = useState(false)
  const [bioequivalentList, setBioequivalentList] = useState([])
  const [modalLoginVisible, setModalLoginVisible] = useState(false)
  const [subscriptionProductList, setSubscriptionProductList] = useState([])

  const userInfo = useSelector((state) => state.user.userInfo)
  const locationStorage = useSelector((state) => state.app.commune)
  const onePharmacy = useSelector((state) => state.app.one_pharmacy)
  const themeColor = useSelector((state) => state.app.themeColor)
  const textSubscriptionSection = useSelector(
    (state) => state.app.text_subscription_section,
  )

  useEffect(() => {
    try {
      if (clientName === 'UC Christus') {
        let router = []
        router.push(
          {
            go: 'https://www.ucchristus.cl/yapp',
            target: '_blank',
            description: 'Red de Salud UC CHRISTUS',
          },
          {
            go: '/search',
            title: 'Cotizador',
            description: 'Buscador de medicamentos',
          },
        )
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) { }

    if (props.disabledThemeCheckout === true) {
      dispatch(changeLogo(props.clientLogo))
      dispatch(showLogo(true))
      dispatch(setThemeColor(props.themeColor))
    }
  }, [])

  useEffect(async () => {
    let arrBag = await getShoppingCart()
    let auxArraBag = [...arrBag]

    try {
      if (arrBag?.length > 0) {
        let auxArr = auxArraBag.splice(0, 9)
        saveRecentlyViewed(auxArr)
      }
    } catch (error) { }

    if (!arrBag) history.replace('/search/shopping_bag/000000')

    searchData(arrBag)
    let data = getLocalData()
    setDeliveryTime(_.get(data?.client_config, 'delivery_time', null))
    if (arrBag?.length > 0 && allowSubscriptions) {
      await getSubscriptionInfo(arrBag)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [isDesktopWidth])

  function goBack() {
    history.push('/search')
  }

  const getSubscriptionInfo = async (productList) => {
    let subscriptionProductList = []
    let products = []
    let promises = []

    try {
      for (const product of productList) {
        if (product.is_product) {
          products.push(product)
          promises.push(
            getSubscriptionInfoByProductId(
              product.product_id,
              locationStorage.id,
            ),
          )
        }
      }

      const response = await Promise.all(promises)
      for (let index = 0; index < response.length; index++) {
        let result = response[index]
        let product = products[index]
        if (result?.data?.length > 0 && result.data[0].prices) {
          product.name = product.product_name
          if (result?.data?.length > 0 && result.data[0].prices) {
            result.data[0].prices = JSON.parse(result.data[0].prices)
            result.data[0].program_price = JSON.parse(
              result.data[0].program_price,
            )
            result.data[0].delivery = JSON.parse(result.data[0]?.delivery)
            result.data[0].product = product

            if (result.data[0].program_price?.length > 0) {
              result.data[0].program_price.sort(
                (a, b) => parseFloat(a.priority) - parseFloat(b.priority),
              )
            }

            subscriptionProductList.push(result.data[0])
          }
        }
      }
    } catch (error) {
      console.log('Error:', error)
    }

    setSubscriptionProductList(subscriptionProductList)
  }

  const handleWindowSizeChange = () => {
    setIsDesktopWidth(window.innerWidth > 1024)
  }

  function searchData(arrBag) {
    let productList = [];
    let productIds = arrBag.map((el) => {
      productList.push(el.product_id)
      return { id: el.product_id, result_id: el?.resultSearch?.result_id }
    })

    getGenerateQuotation(productIds, locationStorage)
      .then((resp) => {
        if (!resp) {
          arrBag.map((product) => {
            let refundableProduct = isInsurance && ![null, undefined].includes(product.id)
            let refundType = refundableProduct
              ? 'Refundable'
              : ![null, undefined].includes(product.client_benefit)
                ? 'Benefit'
                : 'None'

            logEvent('Product Checked', {
              'Product Name': product?.product_name,
              'Product Id': product?.product_id,
              'Lab Name': product?.laboratory_name,
              'Category Name': product?.category,
              'Active Principle Id': product?.formula_id,
              'Active Principle Name': product?.formula_name,
              'Product Quantity': product?.counter,
              Origin: product?.origin,
              'Purchase Enabled': 'False',
              'Min Price': null,
              Found: 'False',
              'Refundable Type': refundType,
              'Refundable Product': refundableProduct ? 'True' : 'False',
            })
          })
          return history.push('/error')
        }

        let results = resp.data
        arrBag.map((product) => {
          let found = 'False'
          try {
            found = results?.find((p) => {
              return p.quotation_detail?.find(
                (c) => c.product.product_id === product.product_id,
              )
            })
          } catch (ignore) { }

          let deliveryResults = results?.filter(
            (result) => result.delivery.cost !== null,
          )
          let purchaseEnabled = 'False'
          let minPrice = null

          if (deliveryResults?.length > 0) {
            purchaseEnabled = 'True'

            for (const data of deliveryResults) {
              if (minPrice === null || data.total < minPrice) {
                minPrice = data.total
              }
            }
          }

          let refundableProduct = isInsurance && ![null, undefined].includes(product.id)
          let refundType = refundableProduct
            ? 'Refundable'
            : ![null, undefined].includes(product.client_benefit)
              ? 'Benefit'
              : 'None'

          logEvent('Product Checked', {
            'Product Name': product?.product_name,
            'Product Id': product?.product_id,
            'Lab Name': product?.laboratory_name,
            'Category Name': product?.category,
            'Active Principle Id': product?.formula_id,
            'Active Principle Name': product?.formula_name,
            'Product Quantity': product?.counter,
            'Purchase Enabled': purchaseEnabled,
            'Min Price': minPrice,
            Origin: product?.origin,
            Found: [undefined, null, false].includes(found) ? 'False' : 'True',
            'Refundable Type': refundType,
            'Refundable Product': refundableProduct ? 'True' : 'False',
          })
        })

        for (let index = 0; index < results?.length; index++) {
          const elements = results[index].quotation_detail

          for (let indexQuotation = 0; indexQuotation < elements.length; indexQuotation++) {
            const element = elements[indexQuotation]

            let product = arrBag.find(
              (el) =>
                el.product_id === element?.product.product_id ||
                el.product_id === element?.product_id_old,
            )
            if (product) {
              let remoteQuantity = element.quantity
              results[index].quotation_detail[indexQuotation].quantity = remoteQuantity * product.counter

              let clientBenefit = product.client_benefit_pharmacy === 0 ||
                results[index].pharmacy_chain_id === product.client_benefit_pharmacy
                ? product.client_benefit
                : 0

              let regularPrice = element.price
              let clientDiscount = clientBenefit > 0 && clientBenefit < 100
                ? Math.floor(remoteQuantity * regularPrice * clientBenefit / 100)
                : 0
              let clientPrice = clientDiscount > 0
                ? regularPrice - clientDiscount
                : regularPrice

              if (product.id && results[index].sell && element.product?.prescription_id === 0) {
                results[index].quotation_detail[indexQuotation].product.prescription_id = 1
                results[index].quotation_detail[indexQuotation].product.prescription = 'Receta Simple'
              }

              results[index].quotation_detail[indexQuotation].client_benefit = clientBenefit
              results[index].quotation_detail[indexQuotation].client_discount = clientDiscount
              results[index].quotation_detail[indexQuotation].client_price = clientPrice

            }
          }

          let subTotal = _.sumBy(
            resp.data[index].quotation_detail,
            (pharmacyEl) => pharmacyEl.client_price * pharmacyEl.quantity,
          )

          resp.data[index]['total'] = subTotal
          resp.data[index]['total_order'] = subTotal + (resp.data[index]?.delivery?.cost || 0)
        }

        if (resp?.data) {
          orderByFilterQuotation(resp?.data, arrBag, productList)
        } else {
          setModalErrorProductIsOpen(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        arrBag.map((product) => {
          let refundableProduct = isInsurance && ![null, undefined].includes(product.id)
          let refundType = refundableProduct
            ? 'Refundable'
            : ![null, undefined].includes(product.client_benefit)
              ? 'Benefit'
              : 'None'

          logEvent('Product Checked', {
            'Product Name': product?.product_name,
            'Product Id': product?.product_id,
            'Lab Name': product?.laboratory_name,
            'Category Name': product?.category,
            'Active Principle Id': product?.formula_id,
            'Active Principle Name': product?.formula_name,
            'Product Quantity': product?.counter,
            'Purchase Enabled': 'False',
            'Min Price': null,
            Origin: product?.origin,
            Found: 'False',
            'Refundable Type': refundType,
            'Refundable Product': refundableProduct ? 'True' : 'False',
          })
        })
        setModalErrorProductIsOpen(true)
      })
  }

  function orderByFilterQuotation(data, bag, productIds = []) {
    let presentKeys = []
    let newArr = [] //=_.chain(data).groupBy("quantity_results").value()
    newArr.push(data)

    for (const key in newArr) {
      if (Object.hasOwnProperty.call(newArr, key)) {
        const element = newArr[key]
        presentKeys.push(Number(key))
        newArr[key] = _.orderBy(
          element,
          ['quantity_results', 'sell', 'total_order'],
          ['desc', 'desc', 'asc'],
        )
      }
    }

    presentKeys = presentKeys
      .sort()
      .reverse()
      .map((el) => newArr[el])

    for (let i = 0; i < presentKeys.length; i++) {
      for (let j = 0; j < presentKeys[i].length; j++) {
        presentKeys[i][j].quotation_detail.sort((a, b) =>
          a.product.name.localeCompare(b.product.name),
        )
      }
    }

    let fullResults = presentKeys[0].filter(
      (pharmacy) =>
        pharmacy.quantity_products === pharmacy.quantity_results &&
        pharmacy.sell === 1,
    )
    fullResults = _.orderBy(
      fullResults,
      ['quantity_results', 'total'],
      ['desc', 'asc'],
    )

    let someResults = presentKeys[0].filter(
      (pharmacy) =>
        pharmacy.quantity_products !== pharmacy.quantity_results &&
        pharmacy.quantity_results > 0 &&
        pharmacy.sell === 1,
    )

    someResults = _.orderBy(
      someResults,
      ['quantity_results', 'total'],
      ['desc', 'asc'],
    )

    let otherResults = presentKeys[0].filter((pharmacy) => pharmacy.sell === 0)
    let productsNotFound = []
  
    if (showNotFoundProducts && fullResults.length === 0) {
      productsNotFound = [...productIds];
      for (let quote of someResults) {
        if (quote.quotation_detail?.length > 0) {
          for (let item of quote.quotation_detail) {
            if (item?.product?.product_id && productsNotFound.includes(item.product.product_id)) {
              productsNotFound = productsNotFound.filter(x => x !== item.product.product_id)
            }
          }
        }
      }

      if (productsNotFound.length > 0) {
        productsNotFound = bag.filter((item) => productsNotFound.includes(item.product_id))
      }
    }

    otherResults = _.orderBy(
      otherResults,
      ['quantity_results', 'total'],
      ['desc', 'asc'],
    )

    setAppQuotationState({
      loading: false,
      fullResults: fullResults?.length > 0 ? [fullResults] : [],
      someResults: someResults?.length > 0 ? [someResults] : [],
      otherResults: otherResults?.length > 0 ? [otherResults] : [],
      notFound: productsNotFound,
    })
  }

  function sendData(item) {
    logEvent('Checkout Started', {
      'Total Cart Size': item.quantity_results,
      'Total Cart Value': item.total,
      'Total Discounts': 0,
      'Pharmacy Id': item.pharmacy_chain_id,
      'Pharmacy Name': item.pharmacy_chain_name,
      'Purchase Type': 'Spot',
    })

    try {
      addEventNameWithParams('click_selected_quotation', {
        pharmacy_chain_id: item?.pharmacy_chain_id,
        pharmacy_chain_name: item?.pharmacy_chain_name,
        products: item?.quotation_detail?.map((el) => {
          return {
            product_id: el?.product?.product_id,
            name: el?.product?.name,
            lab: el?.product?.lab,
            formula_name: el?.product?.formula_name,
            quantity: el?.quantity,
            price: el?.price,
          }
        }),
      })
    } catch (ignore) { }

    localStorage.setItem('selectedQuotationItem', JSON.stringify(item))

    history.push('/shopping_bag/checkout')
  }

  function sendDataSubscription() {
    history.push('/shopping_bag/checkout')
  }

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}>
      <i className="icon-chevron-right"></i>
    </div>
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}>
      <i className="icon-chevron-right"></i>
    </div>
  )

  function bioequivalentRemplace(props) {
    let path =
      '/search/bioequivalent/' +
      Math.floor(Math.random() * Math.floor(100000000000000000000))
    history.push(path, { productSelect: props, pathname: location.pathname })
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  }

  return (
    <>
      {loading && suraLoader && <LoadingSura />}
      <div
        className="content-wrap px-0"
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        {clientName !== 'UC Christus' && (
          <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
            <div className="breadcrumbs c-pointer" onClick={goBack}>
              <div className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse"></i>
              </div>
              <span
                className="txt-4--regular-pr"
                style={{ color: 'var(--theme-color)' }}>
                Volver
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        className={`flex-grow-1 ${clientName === 'UC Christus' ? 'bg-white' : ''
          }`}
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-responsive mt-2">
          {loading && !suraLoader ? (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative m-0"
            />
          ) : (
            <>
              <div className="d-flex justify-content-between py-1">
                <div>
                  <h4 className="txt-title color-secondary mb-0 mb-md-2 mb-lg-3">
                    Tus opciones de compra
                  </h4>
                </div>
              </div>
              {subscriptionProductList?.length > 0 && (
                <div>
                  <div className="d-flex justify-content-between py-2">
                    <div>
                      <h4 className="d-none d-sm-block title-big color-secondary mb-3 ">
                        Programa tu tratamiento y ahorra con Convenio Yapp
                      </h4>
                      <h4 className="d-block d-sm-none title-big color-secondary mb-3">
                        Medicamentos con Convenio Yapp
                      </h4>
                    </div>
                  </div>

                  <div className="content-quotetation">
                    <div className="content-quotetation--item">
                      <div className="content-pharmacies">
                        {subscriptionProductList.map((subscriptionInfo) => {
                          return (
                            <SubscriptionCard
                              key={subscriptionInfo.subscription_product_id}
                              data={subscriptionInfo}
                              sendDataSubscription={sendDataSubscription}
                              totalProducts={subscriptionProductList.length}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`${subscriptionProductList?.length > 0
                  ? 'd-flex justify-content-between py-3'
                  : 'd-none'
                  }`}>
                {[undefined, null, false].includes(onePharmacy) &&
                  !patientProfile && (
                    <div>
                      <h4
                        className={`txt-2--bold-db mb-0 ${subscriptionProductList?.length > 0 ? 'mt-3' : ''
                          }`}>
                        Lista de farmacias
                      </h4>
                    </div>
                  )}
                <div
                  id="gtm-webapp-quotation-filter"
                  className="select-custom d-none">
                  <select
                    style={localStyle.customSelect}
                    className={props.class}
                    name={props.name}
                    id={props.id}>
                    <option value={props.value}>Más económica</option>
                  </select>
                </div>
              </div>
              <div>
                {(appQuotationState.fullResults?.length > 0 ||
                  appQuotationState.someResults?.length > 0 ||
                  appQuotationState.otherResults?.length > 0) ? (
                  <div
                    id="container-quotation"
                    className="content-quotetation mb-5">
                    {(appQuotationState.fullResults?.length > 0 ||
                      appQuotationState.someResults?.length > 0) && (
                        <div
                          id="first-container"
                          className={`${!appQuotationState.fullResults?.length &&
                            !appQuotationState.someResults?.length &&
                            appQuotationState.otherResults?.length
                            ? 'd-none'
                            : 'container-pharmacies'
                            }`}>

                          { clientName === 'Bice Vida' && (
                            <div className={`d-flex justify-content-end pb-4 ${width <= 768 ? '' : 'mr-4'}`} style={{ marginTop: '-120px',
                              paddingTop: (width <= 768 ? '10%' : '0'), marginBottom: (width <= 768 ? '0' : '-80px')}}
                            >
                              <img src={biceVidaPet} alt={'Bice Vida'} />
                            </div>
                          )}

                          { clientName === 'Pacientes' &&
                            <h4 className="title-quotation color-secondary mb-3 mb-md-4 mb-lg-4 ">
                              Compra tu medicamento una vez
                            </h4>
                          }

                          { clientName === 'Bice Vida' &&
                            <h4 className="title-quotation color-secondary mb-3 mb-md-4 mb-lg-4 ">
                              Solo puedes comprar en 1 farmacia a la vez
                            </h4>
                          }

                          <div
                            id="second-container"
                            className={`${'container-boxcard'}`}>
                            {appQuotationState.fullResults &&
                              appQuotationState.fullResults.map((e, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`content-quotetation--item`}>
                                    {e.length > 0 && (
                                      <p className="title-primary--xxl w-100 mb-3 mb-md-4 mb-lg-4">
                                        Farmacias con todos los productos que buscas
                                      </p>
                                    )}
                                    <div
                                      className={`content-pharmacies  ${e.length > 0 ? 'container-results' : ''
                                        }
                            `}
                                      key={i}>
                                      {e.map((pharmacyEl, key) => {
                                        let subtotal = _.sumBy(
                                          pharmacyEl.quotation_detail,
                                          (pharmacyEl) =>
                                            pharmacyEl.client_price * pharmacyEl.quantity,
                                        )
                                        let total =
                                          subtotal + pharmacyEl.delivery.cost

                                        //bioequivalent === 0
                                        return (
                                          <PharmacyCard
                                            key={key}
                                            keyValue={key}
                                            firstKey={i}
                                            pharmacyEl={pharmacyEl}
                                            total={total}
                                            subtotal={subtotal}
                                            sendData={sendData}
                                            deliveryTime={
                                              pharmacyEl.delivery?.benefit === 1
                                            }
                                            bioequivalentRemplace={
                                              bioequivalentRemplace
                                            }
                                            bioequivalentList={bioequivalentList}
                                            setBioequivalentList={
                                              setBioequivalentList
                                            }
                                          />
                                        )
                                      })}
                                    </div>
                                  </div>
                                )
                              })}
                            {appQuotationState.someResults &&
                              appQuotationState.someResults.map((e, i) => {
                                return (
                                  <div
                                    className={`content-pharmacies  ${e.length > 0 ? 'container-results' : ''
                                      }`}
                                    key={i}>
                                    {e.length > 0 && (
                                      <p className="title-primary--xxl w-100 mb-3 mb-md-4 mb-lg-4">
                                        Farmacias con algunos de los productos que
                                        buscas
                                      </p>
                                    )}
                                    {e.map((pharmacyEl, key) => {
                                      let subtotal = _.sumBy(
                                        pharmacyEl.quotation_detail,
                                        (pharmacyEl) =>
                                          pharmacyEl.client_price * pharmacyEl.quantity,
                                      )
                                      let total = subtotal + pharmacyEl.delivery.cost

                                      //bioequivalent === 0
                                      return (
                                        <PharmacyCard
                                          key={key}
                                          keyValue={key}
                                          firstKey={i + 1}
                                          pharmacyEl={pharmacyEl}
                                          total={total}
                                          subtotal={subtotal}
                                          sendData={sendData}
                                          deliveryTime={
                                            pharmacyEl.delivery?.benefit === 1
                                          }
                                          bioequivalentRemplace={
                                            bioequivalentRemplace
                                          }
                                          bioequivalentList={bioequivalentList}
                                          setBioequivalentList={setBioequivalentList}
                                        />
                                      )
                                    })}
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      )}

                    {appQuotationState.otherResults &&
                      appQuotationState.otherResults.map((e, i) => {
                        return (
                          <div className={`content-pharmacies `} key={i}>
                            {e.length > 0 && (
                              <p className="title-primary--xxl w-100 mb-4 mt-3">
                                Farmacias solo disponibles para cotizar
                              </p>
                            )}
                            {e.map((pharmacyEl, key) => {
                              let subtotal = _.sumBy(
                                pharmacyEl.quotation_detail,
                                (pharmacyEl) =>
                                  pharmacyEl.price * pharmacyEl.quantity,
                              )
                              let total = subtotal + pharmacyEl.delivery.cost

                              //bioequivalent === 0
                              return (
                                <PharmacyCard
                                  key={key}
                                  keyValue={key}
                                  firstKey={i + 1}
                                  pharmacyEl={pharmacyEl}
                                  total={total}
                                  subtotal={subtotal}
                                  sendData={sendData}
                                  deliveryTime={pharmacyEl.delivery?.benefit === 1}
                                  bioequivalentRemplace={bioequivalentRemplace}
                                  bioequivalentList={bioequivalentList}
                                  setBioequivalentList={setBioequivalentList}
                                />
                              )
                            })}
                          </div>
                        )
                      })}

                    { appQuotationState.notFound.length > 0 && (
                      <div className={`content-pharmacies`} key={'not-found'}>
                        <p className="title-primary--xxl w-100 mb-4 mt-3">
                          Medicamentos sin stock en la comuna seleccionada
                        </p>
                        { appQuotationState.notFound.map((e, i) => {
                          return <ProductCard product={e} key={i} small={true}/>
                        })}
                      </div> 
                    )}

                  </div>
                ) : (
                  <EmptyView
                    title
                    type={clientName === 'Bice Vida'? 1 : 0}
                    image={clientName == 'Bice Vida' ? biceVidaBandAid : pillSeeking}
                    extraInfo="Puedes volver a intentarlo buscando alternativas similares"
                  >
                    <h4>¡Lo sentimos!<br />Los medicamentos que buscaste no tienen stock en farmacias.</h4>
                  </EmptyView>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {clientName === 'Vida Security' && (
        <TypeSidetab
          id='jjdc5aSF'
          buttonColor={'#282E55'}
          buttonText={'Sugerencias'}
        />
      )}
      {clientName === 'Sura' && (
        <TypeSidetab
          id='wea0XraP'
          buttonColor={'#0033A0'}
          buttonText={'Sugerencias!'}
        />
      )}
      {clientName === 'Pacientes' && (
        <TypeSidetab
          id='I1p4HO0C'
          buttonColor={'#497AD7'}
          buttonText={'Sugerencias!'}
        />
      )}

      <Modal
        isOpen={modalErrorProductIsOpen}
        onAfterOpen={null}
        onRequestClose={() => goBack()}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={icErrorProducts}
            alt="Error"
            className="mb-3"
            id="gtm-webapp-error-search-no-found"
          />
          <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
          {![undefined, null, false].includes(onePharmacy) ? (
            <>
              <p className="title-primary--xl text-center">
                No hemos encontrado los productos que buscas en
              </p>
              <p className="title-primary--xl text-center mb-30">
                {clientName}
              </p>
            </>
          ) : (
            <p className="title-primary--xl text-center mb-30">
              No hemos encontrado resultados para tu búsqueda.
            </p>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <ButtonYapp
            className="maxw-214 w-100"
            onClick={() => goBack()}
            color={'--theme-color'}
            title="Volver atrás"
          />
        </div>
      </Modal>

      <ModalLogin
        modalLoginVisible={modalLoginVisible}
        setModalLoginVisible={setModalLoginVisible}
      />
    </>
  )
}

const localStyle = {
  spinner: {
    position: 'relative',
    height: '40px',
    width: '40px',
    top: '10rem',
    left: 'calc(50% - 20px)',
    color: 'var(--color-primary)',
  },
}

const mapStateToProps = (state) => {
  return {
    products: state.cart.products,
    logo: state.app.logo,
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
    themeColor: state.app.config?.custom_styles?.theme_color,
    clientLogo: state.app.config?.logo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
    setGoRouter: (router) => dispatch(setGoRouter(router)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShoppingBagQuotation)
