import {useSelector} from 'react-redux'
import loadingBar from '../assets/img/loading_bar.gif'

function Loading() {
  const initialLoading = useSelector((state) => state.app.initial_loading)

  return (
    <>
      {initialLoading && (
        <img src={loadingBar} className="w-100" style={{height: '5px'}} />
      )}
    </>
  )
}

export default Loading
