import React from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import Remover from '../../assets/img/remover.svg'

function ModalRecemed(props) {
  const { isOpen, setIsOpen } = props
  const recemedModal = useSelector((state) => state.app.recemedModal)

  function closeModal() {
    setIsOpen(false)
  }

  function goToRecemed() {
    setIsOpen(false)
    window.open(recemedModal?.link, "_blank")
  }  

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalRecemed"
      ariaHideApp={false}>
      <a onClick={closeModal}>
        <img src={Remover} className="icon-remover" alt="Cerrar" />
      </a>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={recemedModal?.logo} style={{width: '10.9375rem', height: '2.4375rem'}} alt="Logo recemed" />
      </div>
      <p className="txt-2--bold-db my-3">{recemedModal?.title}</p>
      <p className="txt-5--regular-db mb-3">{recemedModal?.content1}</p>
      <p className="txt-5--regular-db mb-3">{recemedModal?.content2}</p>
      <p className="txt-5--bold-db mb-3">{recemedModal?.content3}</p>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <ButtonYapp
          className="btn-default w-100"
          onClick={goToRecemed}
          title={recemedModal?.button}
        />
      </div>
    </Modal>
  )
}

export default ModalRecemed
