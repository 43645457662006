import React from 'react'
import {connect} from 'react-redux'
import ButtonYapp from '../Buttons/ButtonYapp'

const InputButtonDiscount = ({
  btnText,
  error,
  onApply,
  onRemove,
  setDiscountCode,
  discountCode,
  titleButton,
  defaultCodeForDiscount,
  formData,
  ...props
}) => {

  if ( !(formData?.discount) && defaultCodeForDiscount ) {
    setDiscountCode(defaultCodeForDiscount) 
    onApply()
  }

  return (
    <>
      <div className="position-relative input-yapp">
        <div className="content-input-bg">
          <input
            style={{cursor: props.discount && 'auto'}}
            type="text"
            value={discountCode}
            readOnly={props.discount ? true : false}
            className="input-custom-secondary font-regular w-100"
            placeholder="Ingresar código"
            onChange={(inputText) => setDiscountCode(inputText.target.value)}
            {...props}
          />
          <ButtonYapp
            title={!props.discount ? titleButton || 'Aplicar' : 'Descuento aplicado'}
            onClick={onApply}
            // disabled={props.discount ? true : false}
            color={!props.discount ? '--theme-color' : '--color-green-3'}
            className="z-index-99 mw-90 p-2 position-absolute input-btn-position"
            noWrap
            small
          />

          {
            // props.discount ? (
            //   <div className="mw-115 position-absolute input-btn-position d-flex align-items-center justify-content-end flex-fill">
            //     {/* Remove discount
            //       <i className="icon-close txt-1--bold mr-2 txt-red" onClick={ onRemove }></i> */}
            //     <i className="icon-circle-check txt-1--bold mr-2 color-theme"></i>
            //   </div>
            // ) : (
            //   <ButtonYapp
            //     title={titleButton || 'Aplicar'}
            //     onClick={onApply}
            //     color={'--theme-color'}
            //     className="z-index-99 mw-90 p-2 position-absolute input-btn-position"
            //     noWrap
            //     small
            //   />
            // )
          }
        </div>
        {error && (
          <p className="txt-6--regular-er mt-1 mb-2">{error.message}</p>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: state.app?.formData ?? null,
  }
}

export default connect(mapStateToProps)(InputButtonDiscount)

