import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import NewTrackingStep from '../../Components/NewTrackingStep/NewTrackingStep'
import {trackingInfoByUid} from '../../Utils/api'
import {_formatPrice} from '../../Utils/tools'
import {formatDate} from '../../Utils/utils'
import {changeLogo, showLogo, setThemeColor} from '../../redux/modules/app'

import loadingYapp from '../../assets/img/yapp_loading.gif'
import BgHeaderTracking from '../../assets/img/bg_card_tracking_header.svg'
import IcTrackingRechazo from '../../assets/img/ic_tracking_rechazo.svg'
import icTrackingCancelado from '../../assets/img/ic_tracking_cancelado.svg'
import icTrackingErrorPago from '../../assets/img/ic_tracking_error_pago.svg'
import biceVidaPet from '../../assets/img/bice-vida-welcome.png'
import farmaciaFacil from '../../assets/img/farmacia-facil.png'
import powerByYapp from '../../assets/img/power_by_header_new.svg'

function ExternalTracking(props) {
  const history = useHistory()
  const {purchaseOrder} = useParams()
  const requestType = 'purchaseOrder';
  const defaultMessage = 'Un operador de Yapp se contactará contigo a la brevedad';

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [purchaseOrderInfo, setPurchaseOrderInfo] = useState(null);
  const [rejectedComment, setRejectedComment] = useState(null);
  const [rejectedImage, setRejectedImage] = useState(null);
  const [rejectedMessage, setRejectedMessage] = useState(null);
  const [rejectedTitle, setRejectedTitle] = useState(null);

  const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes';
  const borderStyle = clientName === 'Pacientes'
    ? { backgroundImage: `url(${BgHeaderTracking})` }
    : clientName === 'UC Christus'
      ? { borderRadius: 'var(--border-radius)', backgroundColor: process.env.REACT_APP_THEME_PRIMARY_COLOR }
      : { backgroundColor: process.env.REACT_APP_THEME_PRIMARY_COLOR };

  useEffect(async () => {
    showTrackingTheme();

    if (purchaseOrder) {
      let purchaseOrderData = await trackingInfoByUid(requestType, purchaseOrder)

      if (purchaseOrderData) {
        setPurchaseOrderInfo(purchaseOrderData);

        if (purchaseOrderData.status ==='Cancelado' || purchaseOrderData.status === 'Rechazado') {
          let rejectedData = getRejectedData(purchaseOrderData.is_prepaid, purchaseOrderData.status, purchaseOrderData.rejected_status);
          setRejectedTitle(rejectedData.title);
          setRejectedImage(rejectedData.image);
          setRejectedMessage(rejectedData.message);
          setRejectedComment(rejectedData.comment);
        }
      }
    }
    setLoading(false);
  }, [])

  const getRejectedData = (isPrepaid, status, rejectedStatus = null) => {
    let title = status === 'Cancelado' ? 'Tu pedido fue cancelado' : 'Tu pedido fue rechazado por la farmacia';
    let image = status === 'Cancelado' ? icTrackingCancelado : IcTrackingRechazo;
    let comment = null;
    let message = null;

    switch (rejectedStatus) {
      case 'RECETA ILEGIBLE':
        message = 'Problemas con la receta médica';
        comment = 'Debes agregar una imagen clara y completa de tu receta para que puedas recibir tu medicamento.';
        break;
      case 'RECETA NO CORRESPONDE':
        message = 'Problemas con la receta médica';
        comment = 'Debes agregar una receta que corresponda al medicamento para poder recibirlo.';
        break;
      case 'RECETA RETENIDA':
        message = 'Problemas con la receta médica';
        comment = 'Uno de tus medicamentos es de venta bajo receta médica retenida.';
        break;
      case 'RECETA VENCIDA':
        message = 'Problemas con la receta médica';
        comment = 'Debes agregar una receta vigente para que puedas recibir tu medicamento.';
        break;
      case 'SIN RECETA':
        message = 'Problemas con la receta médica';
        comment = 'Debes agregar una receta para que puedas recibir tu medicamento.';
        break;
      case 'PROBLEMA CON EL MEDIO DE PAGO':
      case 'TC RECHAZADA':
        image = icTrackingErrorPago;
        message = 'No pudimos procesar tu pago';
        comment = 'Para que se pueda realizar la entrega debes verificar tu saldo o actualizar el medio de pago en la app.';
        break;
      case 'SIN STOCK':
        message = 'Falta de stock';
        break;
      default:
        break;
    }

    if (status === 'Cancelado')
      comment = isPrepaid
        ? 'Se realizará reembolso al medio de pago utilizado en la compra en máximo 10 días hábiles.'
        : 'El cobro a tu tarjeta no se realizará.';

    return { comment, image, message, title };
  }

  const showTrackingTheme = () => {
    if (process.env.REACT_APP_DISABLED_THEME_CLIENT === 'TRUE') {
      dispatch(changeLogo(null));
      dispatch(showLogo(false));
      dispatch(setThemeColor({ primary: '#6578FF', secondary: '#4DBAB6' }));
    } 
    else {
      if (['NULL', undefined].includes(process.env.REACT_APP_LOGO)) {
        dispatch(showLogo(false));
      } 
      else {
        dispatch(showLogo(true));
        dispatch(changeLogo(process.env.REACT_APP_LOGO));
      }
      dispatch(setThemeColor({ 
        primary: process.env.REACT_APP_THEME_PRIMARY_COLOR,
        secondary: process.env.REACT_APP_THEME_SECONDARY_COLOR
      }));
    }
  }

  return (
    <div className="wrapper-location mt-2 position-relative">
      {loading ? (
        <img
          src={loadingYapp}
          alt="loading"
          className="loading-yapp--alternative m-0"
        />
      ) : (
        <>
          { clientName === 'Bice Vida' && (
            <>
              <div className="justify-content-center text-center mx-auto mt-3">
                <img src={farmaciaFacil} className={"mw-100"} alt="Farmacia fácil" />
              </div>
              <div className={`d-flex justify-content-center mx-auto mt-4 maxw-200`}>
                <img className='w-100' src={biceVidaPet} alt={'Bice Vida'} />
              </div>
              </>
          )}

          { clientName === 'UC Christus' && (
            <div className="d-flex justify-content-end mb-4 pb-2">
              <img src={powerByYapp} alt="Yapp" className="power-by-yapp" />
            </div>
          )}
          <div className="flex-grow-1 px-3 pb-4">
            {purchaseOrderInfo ? (
              <div className="content-responsive mx-auto h-100">
                <div className="content-light-wrapper">
                  {!['Cancelado', 'Rechazado'].includes(
                    purchaseOrderInfo.status,
                  ) && (
                    <div
                      className="content-light-wrapper-header"
                      style={borderStyle}>
                      <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                    </div>
                  )}

                  <div className="content-light-wrapper-body">
                    {!['Cancelado', 'Rechazado'].includes(
                      purchaseOrderInfo.status,
                    ) ? (
                      <div className="pt-4 pb-2">
                        <NewTrackingStep
                          status={purchaseOrderInfo.status}
                          isPrepaid={purchaseOrderInfo.is_prepaid}
                        />
                      </div>
                    ) : (
                      <div className="content-card-light mb-3">
                        <>
                          <div className="text-center pt-2 mx-auto maxw-350">
                            <img src={rejectedImage} alt="" />
                            <h3 className="txt-2--regular-db m-0 mb-2">
                              { rejectedTitle }
                            </h3>
                          </div>
                          <div className="text-center mt-4 mx-auto maxw-350">
                            { rejectedMessage && (
                              <h6 className="txt-4--regular-db mb-0">
                                Razón de { purchaseOrderInfo.status === 'Rechazado'? 'rechazo' : 'cancelación' }:
                              </h6>
                            )}
                            <h6 className="txt-3--regular-er">
                              { rejectedMessage ? rejectedMessage : defaultMessage }
                            </h6>
                          </div>

                          { rejectedComment && (
                            <div className="text-center mt-3 mx-auto maxw-350">
                              <h6 className="txt-4--regular-db">
                                { rejectedComment }
                              </h6>
                            </div>
                          )}
                        </>
                      </div>
                    )}
                  </div>
                </div>
                <div className="content-card-light content-space-horizontal-bite">
                  <h3 className="txt-3--bold-db m-0 mb-3 ">
                    Información de tu pedido
                  </h3>
                  <div className="contet-wrapper-bite mb-2">
                    <div className="content-card-bite mr-md-2 mb-2 mb-md-0">
                      <i className="icon-wallet" />
                      <div className="ml-3">
                        <h4 className="txt-5--bold-db m-0 mb-1">
                          Orden de compra N°{' '}
                          {purchaseOrderInfo.purchase_order_id}
                        </h4>
                        {purchaseOrderInfo.delivery_date && (
                          <p className="txt-6--regular-db m-0">
                            Fecha de entrega:{' '}
                            {formatDate(purchaseOrderInfo.delivery_date, false)}
                          </p>
                        )}
                        <p className="txt-6--regular-db m-0">
                          {purchaseOrderInfo.product_quantity} comprados
                        </p>
                        <p className="txt-6--regular-db m-0">
                          Total del pedido:{' '}
                          <b className="txt-6--bold-db">
                            {_formatPrice(purchaseOrderInfo.product_total)}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="content-card-bite ml-md-2">
                      <i className="icon-home-check" />
                      <div className="ml-3">
                        <h4 className="txt-5--bold-db m-0 mb-1">
                          Dirección de Despacho
                        </h4>
                        <p className="txt-6--regular-db m-0">
                          {purchaseOrderInfo.address}
                        </p>
                        <p className="txt-6--regular-db m-0">
                          Teléfono: {purchaseOrderInfo.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="content-responsive mx-auto h-100">
                <div className="content-light-wrapper">
                  <div
                    className="content-light-wrapper-header"
                    style={borderStyle}>
                    <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                  </div>
                  <div className="content-light-wrapper-body">
                    <div className="text-center pt-2 mx-auto maxw-214">
                      <img src={icTrackingCancelado} alt="" />
                      <h3 className="txt-2--regular-db m-0 mb-2">
                        Hemos tenido un problema al cargar la información de tu
                        pedido
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ExternalTracking
