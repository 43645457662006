import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {_formatRut, _validateRut, _validateMail} from '../../Utils/tools'

import {signOut} from '../../Utils/auth'

import minsalUso from '../../assets/img/minsal-uso-racional.png'
import minsalVenta from '../../assets/img/minsal_ventas_directas.png'

import ModalWelcome from '../../Components/Modals/ModalWelcome'

import {
  changeInitialLoading,
  setIsPro,
  clearAppState,
} from '../../redux/modules/app'
import {getCampaign} from '../../Utils/service'
import {clearUserState} from '../../redux/modules/user'
import ModalReportedAccount from '../../Components/Modals/ModalReportedAccount'
import DiscountCard from '../../Components/Card/DiscountCard'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import NewRegisterPro from '../../Pages/Login/NewRegisterPro'
import {logEvent} from '../../Utils/utils'

const tokenLogin = process.env.REACT_APP_EXTERNAL_VALID_TYPE === 'API'
const _ = require('lodash')

function LogIn(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = new URLSearchParams(location.search)
  const campaign = params.get('campaign')
  const history = useHistory()

  const [modalWelcomeIsOpen, setWelcomeIsOpen] = useState(false)
  const [campaignInfo, setCampaignInfo] = useState(null)
  const [modalReportedState, setModalReportedState] = useState(false)

  const [isProfessional, setIsProfessional] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const skipLogin =
    process.env.REACT_APP_ONBOARDING_REQUIRED === 'TRUE'
      ? 'TRUE'
      : process.env.REACT_APP_SKIP_LOGIN
  const hideBannerMisal = process.env.REACT_APP_HIDE_BANNER_MINSAL === 'TRUE'
  const hidePromotionMsg = process.env.REACT_APP_HIDE_PROMOTION === 'TRUE'

  useEffect(async () => {
    dispatch(clearUserState())
    dispatch(clearAppState())
    signOut()
    dispatch(changeInitialLoading(true))
    setIsLoading(true)

    try {
      if (campaign) {
        let data = await getCampaign(campaign)
        setCampaignInfo(data)
        if (data) setWelcomeIsOpen(true)
      }

      try {
        localStorage.clear()
        sessionStorage.clear()
      } catch (error) {
        console.log(error)
      }

      localStorage.setItem('client_id', process.env.REACT_APP_CLIENT_ID)

      let isProfessional = process.env.REACT_APP_IS_PROFESSIONAL
      if (isProfessional === 'TRUE') {
        setIsProfessional(true)
        dispatch(setIsPro(true))
      } else {
        dispatch(setIsPro(null))
      }
    } catch (ignore) {}
    dispatch(changeInitialLoading(false))
    setIsLoading(false)
  }, [])

  function goTo(page) {
    if (page === 'register') {
      if (isProfessional) {
        logEvent('Create Account Started')
        history.push('/register_validate', {hasOrigin: true})
      } else {
        logEvent('Create Account Started')
        history.push('/register')
      }
    } else {
      history.push('/signin', {hasOrigin: true})
    }
  }

  return (
    <>
      <ModalReportedAccount
        modalReportedState={modalReportedState}
        setModalReportedState={() => setModalReportedState()}
      />
      {/* Mensaje Marketing */}
      <ModalWelcome
        modalWelcomeIsOpen={modalWelcomeIsOpen}
        setWelcomeIsOpen={setWelcomeIsOpen}
        title={campaignInfo?.title}
        message={campaignInfo?.message}
      />
      {[undefined, null, true].includes(isLoading) ? null : (
        <div
          className={`flex-grow-1 ${isProfessional ? '' : 'px-3'} ${
            process.env.REACT_APP_CLIENT_NAME === 'UC Christus'
              ? 'bg-white'
              : 'bg-home-pro'
          }`}>
          {isProfessional && <NewRegisterPro />}
          {/* `content-home content-home-pro mb-4 ${
              hidePromotionMsg
                ? ''
                // 'h-100  d-flex flex-column justify-content-center '
                : ''
                // ''
            }` */}
            {/* the upper content, belongs to the div on line 126 (the one below) */}
          <div>
            {!isProfessional && (
              <>
                <h4 className="txt-1--bold-db text-center">
                  Cotiza y ahorra en tu salud
                </h4>
                <p className="txt-3--light-db text-center">
                  ¡Te ayudaremos a encontrar los mejores <br />
                  precios en farmacias, siempre!
                </p>
                {!hidePromotionMsg && <DiscountCard color={'--theme-color'} />}
              </>
            )}

            { !tokenLogin && (
              <div className="justify-content-center px-3 px-md-0">
                {skipLogin === 'TRUE' ? (
                  <>
                    <ButtonYapp
                      id={null}
                      className={`txt-4--regular-li size-content-responsive-btn mx-auto w-100 mb-4 mt-4 pt-4}`}
                      disabled={false}
                      title={'Comenzar'}
                      color={'--theme-color'}
                      onClick={() => {
                        logEvent('Session Started')
                        goTo('signin')
                      }}
                    />
                  </>
                ) : (
                  !isProfessional &&
                  <>
                    <ButtonYapp className={`txt-4--regular-li size-content-responsive-btn mx-auto w-100 mb-4 mt-4`}
                      disabled={false}
                      title={'Crear cuenta'}
                      onClick={() => goTo('register')}
                    />
                    <h4 className="txt-3--regular-db text-center">
                      ¿Ya tienes una cuenta en Yapp?
                    </h4>
                    <ButtonYapp
                      id={isProfessional ? 'gtm-webapp-home-pro-btn-login' : null}
                      className="txt-3--regular-pr semi-bold color-lilac size-content-responsive-btn mx-auto w-100 mb-2"
                      disabled={false}
                      stroke
                      title={'Iniciar sesión'}
                      onClick={() => goTo('signin')}
                    />
                  </>
                )}
              </div>
            )}

            {process.env?.REACT_APP_ON_LOGIN !== 'TRUE' && !hideBannerMisal && (
              <div className="d-flex justify-content-center minsal-img-container mt-2 mt-md-4 px-3 px-md-0">
                <a
                  href="https://www.minsal.cl/wp-content/uploads/2016/04/MAGNETO-USO-RACIONAL-DE-MEDICAMENTOS-PARA-IMPRESION.pdf"
                  target="_blank">
                  <img
                    src={minsalUso}
                    alt="minsal uso racional"
                    className="minsal-img mr-2"
                  />
                </a>
                <a
                  href="https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf"
                  target="_blank">
                  <img
                    src={minsalVenta}
                    alt="minsal venta directa"
                    className="minsal-img"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default LogIn
