import React, { useContext, useEffect } from 'react'
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './SidenavMenu.css'

function ContextAwareToggle({ header, eventKey, callback }) {
  const activeEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className='header-sidenav'
      onClick={decoratedOnClick}
    >
      <div className='d-flex align-items-center'>
        {header?.icon && <i className={`${header?.icon ? header.icon : ''} color-theme mr-2 icon-sidenav-header`}></i>} <span className='color-theme'>{header?.name}</span>
      </div>
      <i className='icon-chevron-down color-theme'></i>
    </div>
  );
}

function AccordionSideMenu(props) {
  const location = useLocation()
  const { doAction, header, arrayMenu, path } = props

  return (
    <Accordion className='sidenav-accordion' defaultActiveKey="0">
      <Card>
        <Card.Header>
          <ContextAwareToggle header={header} eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className='contente-left-inner-sidenav pt-2'>
              {arrayMenu && arrayMenu.map((item, key) => (
                <div key={key} className={`txt-4--regular-db btn-submenu-sidenav c-pointer mt-0 m-0 b-0${item.action === location.pathname ? 'btn-sidenav-active' : ''}`} onClick={() => doAction(item.action)}><span className='color-theme'>{item.name}</span></div>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default AccordionSideMenu