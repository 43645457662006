import 'firebase/auth'
import {sha256, setUserProperties} from '../../Utils/utils'
import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/react'
import {getHolidayList, getInsurances, refreshToken} from '../../Utils/api'
import {setHolidayList, setInsuranceList} from '../../redux/modules/app'
import {formatDate} from '../../Utils/tools'

const SET_USER_PRO = 'SET_USER_PRO'
const CLEAR_USER_STATE = 'CLEAR_USER_STATE'
const SET_BASIC_INFO = 'SET_BASIC_INFO'
const SET_DOCUMENTS_PENDING = 'SET_DOCUMENTS_PENDING'
const SET_USER_INFO = 'SET_USER_INFO'
const SET_USER_EXTRA_INFO = 'SET_USER_EXTRA_INFO'
const SET_PROFESSIONAL_DISCOUNT_CODE = 'SET_PROFESSIONAL_DISCOUNT_CODE'
const SET_COPAY_USER_DATA = 'SET_COPAY_USER_DATA'
const SET_INSURANCE = 'SET_INSURANCE'
const SET_USER_REFUND = 'SET_USER_REFUND'

const initialState = {
  pro: false,
  email: '',
  identity: '',
  documents_pending: false,
  userInfo: null,
  userExtraInfo: null,
  userRefund: {},
  professional_discount_code: [],
  copay_user_data: '',
  insurance: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PRO:
      return {
        ...state,
        pro: action.status,
      }
    case CLEAR_USER_STATE:
      return initialState
    case SET_BASIC_INFO:
      return {
        ...state,
        email: action.email,
        identity: action.identity,
      }
    case SET_DOCUMENTS_PENDING:
      return {
        ...state,
        documents_pending: action.documents_pending,
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      }
    case SET_USER_EXTRA_INFO:
      return {
        ...state,
        userExtraInfo: action.userExtraInfo,
      }
    case SET_USER_REFUND:
      return {
        ...state,
        userRefund: action.userRefund,
      }
    case SET_PROFESSIONAL_DISCOUNT_CODE:
      return {
        ...state,
        professional_discount_code: action.professionalDiscountCode,
      }
    case SET_COPAY_USER_DATA:
      return {
        ...state,
        copay_user_data: action.value,
      }
    case SET_INSURANCE:
      return {
        ...state,
        insurance: action.value,
      }

    default:
      return state
  }
}

export function setUserPro(status) {
  return {
    type: SET_USER_PRO,
    status,
  }
}

export function clearUserState() {
  return {
    type: CLEAR_USER_STATE,
  }
}

export function setBasicInfo(email, identity) {
  return {
    type: SET_BASIC_INFO,
    email,
    identity,
  }
}

export function setDocumentsPending(documents_pending) {
  return {
    type: SET_DOCUMENTS_PENDING,
    documents_pending,
  }
}

export function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    userInfo,
  }
}
export function setUserExtraInfo(userExtraInfo) {
  return {
    type: SET_USER_EXTRA_INFO,
    userExtraInfo,
  }
}

export function setUserRefund(userRefund) {
  return {
    type: SET_USER_REFUND,
    userRefund,
  }
}

export function setProfessionalDiscountCode(professionalDiscountCode) {
  return {
    type: SET_PROFESSIONAL_DISCOUNT_CODE,
    professionalDiscountCode,
  }
}

export function setCopayUserData(value) {
  return {
    type: SET_COPAY_USER_DATA,
    value,
  }
}

export function setInsurance(value) {
  return {
    type: SET_INSURANCE,
    value,
  }
}

export function fetchUser() {
  return async (dispatch) => {
    try {
      let currentUser = localStorage.getItem('login_temp_data')
      if (currentUser) {
        currentUser = JSON.parse(currentUser)
        if (currentUser?.profile?.uid) {
          var user_id = currentUser?.profile.uid
          window.gtag('config', process.env.REACT_APP_GTAG, {
            user_id: user_id,
          })
          Sentry.setUser({id: user_id})
          window.gtag('set', 'user_id', user_id)
          if (
            ![undefined, null, 'anonymously'].includes(
              currentUser?.signInMethod,
            ) ||
            process.env.REACT_APP_PROFILE === 'INSURANCE'
            ||
            process.env.REACT_APP_PROFILE === 'WIDGET'
          ) {
            amplitude.getInstance().setUserId(user_id)
          }
        }

        if (currentUser?.profile?.email) {
          let hash = sha256(currentUser?.profile?.email)
          if (hash) {
            setUserProperties({Email: hash})
          }
        }
        await dispatch({type: SET_USER_INFO, userInfo: currentUser})

        getHolidayList()
          .then((result) => {
            if (result?.data?.length) {
              dispatch(
                setHolidayList(
                  result.data.map((c) => formatDate(c.date, false)),
                ),
              )
            }
          })
          .catch((err) => {
            console.log('Error trying to get holiday list', err)
          })

        getInsurances()
          .then((result) => {
            if (result?.data?.length) {
              let insurance = []
              insurance.push({
                name: 'Sin seguro',
                logo: null,
                used: false,
                insurance_discount_id: null,
                id: null,
              })

              insurance = insurance.concat(
                result.data.map((c) => ({
                  ...c,
                  used: false,
                })),
              )
              dispatch(setInsuranceList(insurance))
            }
          })
          .catch((err) => {
            console.log('Error trying to get insurance list', err)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }
}
