import React from 'react'
import ButtonYapp from '../../../../Components/Buttons/ButtonYapp'
import {useHistory} from 'react-router'
import { productsUrl } from '../../../../Utils/constants'
import { saveShoppingCart } from '../../../../Utils/service'



const ProductCard = (props) => {
    const {product, small} = props
    const history = useHistory()

    async function handleProductSelected(){
      await saveShoppingCart([
        {
          ...product,
          product_logo: `${removeSlashAtTheEnd(productsUrl)}/${product.picture}`,
          active_principle_id: null,
          active_principle_name: null,
          is_product: 1,
          reference_price: null,
          benefit_price: null,
          url_benefit: null,
          info_benefit: null,
          benefit_percentage: null,
          counter: 1,
          origin: null
        }
      ])
      history.push('/shopping_bag/quotation')
    }

    function getNumberAsCurrency(amount){
      return new Intl.NumberFormat('es-cl', {
        style: 'currency',
        currency: 'CLP',
      })
      .format(amount);
    }

    function removeSlashAtTheEnd(url)     
    {     
      if (url) { return url.replace(/\/$/, ""); }
      return null;
    } 

    const minPrice = getNumberAsCurrency( product.min_price );
    const maxPrice = getNumberAsCurrency( product.max_price );

    return (

      <div className='product-detail-new mb-2 c-pointer position-relative reverting-separated-card'>
        <div className='gtm-webapp-mobile-product-detail'>
          <div className='product-detail-new-img text-center'>
            <img className='product-detail-img mx-auto' style={{maxWidth: "120px"}}
              src={small ? product.product_logo : `${removeSlashAtTheEnd(productsUrl)}/${product.picture}`}
            />
          </div>
          <div className='content-txt mb-3'>
            <p className='txt-4--regular line-14 mt-2 mb-2 text-ellipsis--2 color-darkblue-ds'>
            {product.product_name}
            </p>
            <p className='txt-paragraph--medium line-14 color-darkblue-ds'>
            {product.formula_name}
            </p>
            <p className='txt-paragraph--medium line-14 color-darkblue-ds'>
            {product.presentation}
            </p>

            { !small && (
              <>
                <p className='mt-2 txt-paragraph--medium line-14 color-darkblue-ds'>
                  <b>Desde: {minPrice}</b>
                </p>
                <p className='txt-paragraph--medium line-14 color-darkblue-ds'>
                  <b>Hasta: {maxPrice}</b>
                </p>
              </>
            )}
          </div>
        </div>

        { !small && (
          <ButtonYapp
            className="mt-auto"
            small
            onClick={handleProductSelected}
            color={'--theme-color'}
            title="Cotiza"
          />
        )}

      </div>
    )
}

  export default ProductCard