import {sendDoctorVerificationEmail, sendEmail} from './api'

export const sendDoctorValidationEmail = async (rut, email) => {
  let data = {
    rut: rut.replace('.', ''),
    bcc: null,
    receiver: email,
    sender: 'soportemedicos@yapp.cl',
    templateId: 'd-a636c0cc7d264447af51caff1ae2b43a',
    dynamic_template_data: {},
  }
  return await sendDoctorVerificationEmail(data)
}

export const sendReportAccountEmail = async (email) => {
  let data = {
    bcc: null,
    receiver: email,
    sender: 'soportemedicos@yapp.cl',
    templateId: 'd-69ebe29307b54582b74bc0027679b037',
    dynamic_template_data: {},
  }
  return await sendEmail(data)
}
