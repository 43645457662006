import react from 'react'
import alertIcon from '../../assets/img/alert-icon.svg'

function PrescriptionAlert({alt, text}) {
  return(
    <div className='d-flex align-items-start text-center'>
      <p className='txt-paragraph-lh txt-red'>
        <img
          className='align-items-start' 
          src={alertIcon}
          alt={alt}
        />
        {text}        
      </p>
    </div>
  )
}

export default PrescriptionAlert