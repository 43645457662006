import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import loadingYapp from '../../assets/img/yapp_loading.gif'

const throttle = require('lodash/throttle')

function RederPDF(props) {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div className="content-pdf">
        <Viewer
          defaultScale={SpecialZoomLevel.PageWidth}
          style={{ width: props.initialWidth }}
          fileUrl={props.pdfToShow}
        />
      </div>
    </Worker>
  )
}

const PDFViewer = () => {
  const history = useHistory()
  const location = useLocation()
  const [pdfToShow, setPdf] = useState(location.state?.url)
  const [initialWidth, setInitialWidth] = useState(null)
  const [resizedWidth, setResizedWidth] = useState(null)
  const [loading, setLoading] = useState(true)

  const pdfWrapper = useRef(null)

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 500))
    setPdfSize()
    setLoading(false)
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 500))
    }
  }, [])

  function goBack() {
    history.goBack()
  }

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      let screenWidth = pdfWrapper.current.getBoundingClientRect().width
      setInitialWidth(screenWidth)
      if (initialWidth !== screenWidth) {
        setResizedWidth(false)
        setInterval(() => {
          setResizedWidth(true)
        }, 300)
      }
    }
  }

  return (
    <>
      <div className=' py-3 px-3'>
        <div className="content-responsive mx-auto d-flex justify-content-between">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span className="title-primary--regular line-14">Volver</span>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 px-3">
        <div className="content-responsive mx-auto">
          {loading && (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative mx-auto mt-5"
            />
          )}
          <div ref={pdfWrapper} className="mb-5 position-relative">
            {resizedWidth && (
              <RederPDF initialWidth={initialWidth} pdfToShow={pdfToShow} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PDFViewer
