import React, { useEffect, useState } from 'react'

import {useHistory, useLocation} from 'react-router-dom'

/* IMGS */
import BgHeaderTracking from '../../assets/img/bg_card_tracking_header.svg'
import NewTrackingStep from '../../Components/NewTrackingStep/NewTrackingStep'
import {_formatPrice} from '../../Utils/tools'
import IcTrackingRechazo from '../../assets/img/ic_tracking_rechazo.svg'
import icTrackingCancelado from '../../assets/img/ic_tracking_cancelado.svg'
import icTrackingErrorPago from '../../assets/img/ic_tracking_error_pago.svg'

function MyShoppingTracking() {
  const history = useHistory()
  const location = useLocation()
  const [purchaseOrder, setPurchaseOrder] = useState(null)

  useEffect(() => {
    let data = location?.state? location.state : null
    setPurchaseOrder(data)
  }, [location])

  return (
    <>
      <div className="d-flex justify-content-between py-3 px-3">
        <div
          className="content-responsive mx-auto breadcrumbs c-pointer"
          onClick={() => history.goBack()}>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse color-lilac"></i>
          </a>
          <span className="title-primary--regular line-14 color-lilac">
            Volver
          </span>
        </div>
      </div>
      <div className="flex-grow-1 px-3 pb-4">
        { purchaseOrder
          ? <div className="content-responsive mx-auto h-100">
              <div className="content-light-wrapper">
                {!['Cancelado', 'Rechazado'].includes(purchaseOrder.status) && (
                  <div
                    className="content-light-wrapper-header"
                    style={{backgroundImage: `url(${BgHeaderTracking})`}}>
                    <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                  </div>
                )}
                <div className="content-light-wrapper-body">
                  {!['Cancelado', 'Rechazado'].includes(purchaseOrder.status) ? (
                    <div className="pt-4 pb-2">
                      <NewTrackingStep status={purchaseOrder.status} />
                    </div>
                  ) : (
                    <div className="content-card-light mb-3">
                      {['PROBLEMA CON EL MEDIO DE PAGO', 'TC RECHAZADA'].includes(
                        purchaseOrder.reject_status,
                      ) && (
                        <div className="text-center pt-2 mx-auto maxw-214">
                          <img src={icTrackingErrorPago} alt="Error pago" />
                          <h3 className="txt-2--regular-db m-0 mb-2">
                            No pudimos procesar tu pago
                          </h3>
                          <p className="txt-5--regular-db m-0">
                            Para que se pueda realizar la entrega debes verificar tu
                            saldo o actualizar el medio de pago en la app.
                          </p>
                          <p className="txt-5--bold-db m-0 mb-2">
                            Menú &gt; Tus medios de pago
                          </p>
                        </div>
                      )}
                      {purchaseOrder.reject_status === 'SIN STOCK' && (
                        <div className="text-center pt-2 mx-auto maxw-214">
                          <img
                            src={IcTrackingRechazo}
                            alt="Tu pedido fue rechazado por la farmacia<"
                          />
                          <h3 className="txt-2--regular-db m-0 mb-2">
                            Tu pedido fue rechazado por la farmacia
                          </h3>
                          <p className="txt-5--regular-db m-0">Razón de rechazo:</p>
                          <p className="txt-5--regular-er m-0 mb-2">
                            Falta de stock
                          </p>

                          <p className="txt-6--bold-db m-0 mb-2">
                            El cobro a tu tarjeta no se realizará.
                          </p>
                        </div>
                      )}
                      {[
                        'RECETA ILEGIBLE',
                        'RECETA NO CORRESPONDE',
                        'RECETA RETENIDA',
                        'RECETA VENCIDA',
                        'SIN RECETA',
                      ].includes(purchaseOrder.reject_status) && (
                        <div className="text-center pt-2 mx-auto maxw-214">
                          <img
                            src={IcTrackingRechazo}
                            alt="Tu pedido fue rechazado por la farmacia<"
                          />
                          <h3 className="txt-2--regular-db m-0 mb-2">
                            Tu pedido fue rechazado por la farmacia
                          </h3>
                          <p className="txt-5--regular-db m-0">Razón de rechazo:</p>
                          <p className="txt-5--regular-er m-0 mb-2">
                            Problemas con la receta médica
                          </p>

                          <p className="txt-6--bold-db m-0 mb-2">
                            El cobro a tu tarjeta no se realizará.
                          </p>
                        </div>
                      )}
                      {purchaseOrder.status === 'Cancelado' && (
                        <div className="text-center pt-2 mx-auto maxw-214">
                          <img src={icTrackingCancelado} alt="" />
                          <h3 className="txt-2--regular-db m-0 mb-2">
                            Tu pedido fue cancelado
                          </h3>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="content-card-light content-space-horizontal-bite">
                <h3 className="txt-3--bold-db m-0 mb-3 ">
                  Información de tu pedido
                </h3>
                <div className="contet-wrapper-bite mb-2">
                  <div className="content-card-bite mr-md-2 mb-2 mb-md-0">
                    <i className="icon-wallet" />
                    <div className="ml-3">
                      <h4 className="txt-5--bold-db m-0 mb-1">
                        Orden de compra N° {purchaseOrder.id}
                      </h4>
                      <p className="txt-6--regular-db m-0">
                        Fecha de entrega: {purchaseOrder.date}
                      </p>
                      <p className="txt-6--regular-db m-0">
                        {purchaseOrder.product} comprados
                      </p>
                      <p className="txt-6--regular-db m-0">
                        Total del pedido:{' '}
                        <b className="txt-6--bold-db">
                          {_formatPrice(purchaseOrder.total)}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="content-card-bite ml-md-2">
                    <i className="icon-home-check" />
                    <div className="ml-3">
                      <h4 className="txt-5--bold-db m-0 mb-1">
                        Dirección de Despacho
                      </h4>
                      <p className="txt-6--regular-db m-0">
                        {purchaseOrder.address}
                      </p>
                      <p className="txt-6--regular-db m-0">
                        Teléfono: {purchaseOrder.phone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          : <div className="content-responsive mx-auto h-100">
              <div className="content-light-wrapper">
                <div className="content-light-wrapper-header" style={{backgroundImage: `url(${BgHeaderTracking})`}}>
                  <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                </div>
                <div className="content-light-wrapper-body">
                  <div className="text-center pt-2 mx-auto maxw-214">
                    <img src={icTrackingCancelado} alt="" />
                    <h3 className="txt-2--regular-db m-0 mb-2">
                      Hemos tenido un problema al cargar la información de tu pedido
                    </h3>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default MyShoppingTracking
