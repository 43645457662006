import { useContext, useEffect, useState } from 'react'
import { Col, Accordion, Card, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import parse from 'html-react-parser'
import './SubscriptionCard.css'
import ButtonYapp from '../Buttons/ButtonYapp'
import ModalPreview from '../../Components/Modals/ModalPreview'
import defaultMedication from '../../assets/img/default-medication.png'
import icYappApproved from '../../assets/img/ic_yapp_approved.svg'
import extremeRadiusBase from '../../assets/img/extreme_radius_base.svg'
import { _formatPrice } from '../../Utils/tools'
import { logEvent } from '../../Utils/utils'


function SubscriptionCard(props) {
  const history = useHistory()
  const subscriptionSpecialPrice = 'Precio convenio'
  const subscriptionStandardPrice = 'Precio normal'

  const [cardType, setCardType] = useState(1)
  const [subscriptionInfo, setSubscriptionInfo] = useState(props.data)
  const [prices, setPrices] = useState([])
  const [isCurrentEventKey, setIsCurrentEventKey] = useState(null)
  const [totalProducts, setTotalProducts] = useState(props.totalProducts)
  const [showPreview, setShowPreview] = useState(false)
  const [modalImage, setModalImage] = useState([])

  const textSubscriptionTitle = useSelector((state) => state.app.text_subscription_title)
  const textSubscriptionContent1 = useSelector((state) => state.app.text_subscription_content1)
  const textSubscriptionContent2 = useSelector((state) => state.app.text_subscription_content2)
  const textSubscriptionContent3 = useSelector((state) => state.app.text_subscription_content3)
  const textSubscriptionContent4 = useSelector((state) => state.app.text_subscription_content4)
  const textSubscriptionPrice1 = useSelector((state) => state.app.text_subscription_price1)
  const textSubscriptionPrice2 = useSelector((state) => state.app.text_subscription_price2)
  const textSubscriptionPrice3 = useSelector((state) => state.app.text_subscription_price3)
  const textSubscriptionPrice4 = useSelector((state) => state.app.text_subscription_price4)
  const textSubscriptionDetail1 = useSelector((state) => state.app.text_subscription_detail1)
  const textSubscriptionDetail2 = useSelector((state) => state.app.text_subscription_detail2)
  const textSubscriptionDetail3 = useSelector((state) => state.app.text_subscription_detail3)
  const textSubscriptionDetail4 = useSelector((state) => state.app.text_subscription_detail4)
  const showQuotationDeliveryCost = useSelector((state) =>
    state?.app?.config.show_quotation_delivery_cost
    ? state.app.config.show_quotation_delivery_cost
    : false
  )

  useEffect(() => {
    if (props?.data?.prices?.length > 0) {
      let prices = []
      let cardType = 0

      for (const item of props.data.prices) {
        if ((prices.length === 0) || (prices[prices.length - 1].price !== item.price)) {
          prices.push(item)
        }
      }

      if (prices.length > 1) {
        cardType = 1
      }
      else if (prices[0].discount > 0) {
        cardType = 2
      }
      else {
        cardType = 3
      }

      setPrices(prices)
      setCardType(cardType)
    }
  }, [subscriptionInfo])

  const handleClose = () => {
    setShowPreview(false)
    setModalImage({})
  }

  function onClickShowPreview(product_logo) {
    setModalImage(product_logo)
    setShowPreview(true)
  }

  const onCheckout = () => {
    let initialPrice = subscriptionInfo.prices[0].price
    let initialDelivery = subscriptionInfo.delivery[0].cost

    let subscription = {
      default_code: subscriptionInfo.default_code ?? "",
      is_subscription: true,
      products_complementary: [],
      delivery: subscriptionInfo.delivery[0],
      pharmacy_chain_id: subscriptionInfo.pharmacy_chain_id,
      quantity_products: subscriptionInfo.product.counter,
      quantity_results: 1,
      quotation: 0,
      sell: 1,
      total: subscriptionInfo.product.counter * initialPrice,
      total_amount: initialPrice,
      total_order: (subscriptionInfo.product.counter * initialPrice) + initialDelivery,
      quotation_detail: [{
        product: subscriptionInfo.product,
        subscription_product_id: subscriptionInfo.subscription_product_id,
        identity_card_type: subscriptionInfo.identity_card_type,
        max_quantity: subscriptionInfo.max_quantity,
        prescription_id: subscriptionInfo.prescription_type,
        name: subscriptionInfo.product.product_name,
        price: initialPrice
      }]
    }

    logEvent('Checkout Started', {
      'Total Cart Size': subscription.quantity_products,
      'Total Cart Value': subscription.total,
      'Total Discounts': 0,
      'Pharmacy Id': subscriptionInfo.pharmacy_chain_id,
      'Pharmacy Name': subscriptionInfo.pharmacy_chain_name,
      'Purchase Type': 'Benefit Yapp'
    })

    localStorage.setItem('selectedQuotationItem', JSON.stringify(subscription))
    props.sendDataSubscription()
  }

  function ContextAwareToggle({ eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    )
    const isCurrentEventKey = currentEventKey === eventKey

    setIsCurrentEventKey(currentEventKey)

    return (
      <Accordion.Toggle
        onClick={() => decoratedOnClick()}
        as={Col}
        variant="link">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="d-flex justify-content-between w-100 mb-2">
            <div className="d-flex align-items-center">
              <i className="icon-circle-check color-highlight mr-2" />
              <p className="txt-4--bold-db">{subscriptionInfo.product.product_name}</p>
            </div>
            <div className="d-flex align-items-center">
              <span className="txt-4--bold-db ml-2">
              {_formatPrice(subscriptionInfo.prices[0].price * subscriptionInfo.product.counter)}
              </span>
            </div>
          </div>
          <div className="d-flex mt-2">
            <span className="txt-4--regular-pr">Ver detalles</span>
            <i className={`icon-chevron-down color-lilac ml-1 ${isCurrentEventKey ? 'icon-up' : ''}`}></i>
          </div>
        </div>
      </Accordion.Toggle>
    )
  }

  return (
      <div key={subscriptionInfo.subscription_product_id} className="subscription-card" >
        <div className="alert-price">
          <i className="icon-flag-pharmacy pharmacy-flag-subscription" />
          <p className="txt-4--bold-li">{textSubscriptionTitle}</p>
          <img src={icYappApproved} className='subscription-approved-icon' alt='Convenio Yapp' />
        </div>
        <div className="px-3 pt-3">
          { subscriptionInfo.summary_title && <p className="txt-4--bold-db pb-1">{subscriptionInfo.summary_title}</p> }

          {(cardType === 1 || cardType === 2) && (
            <>
              { !subscriptionInfo.summary_title &&
                <p className="txt-4--bold-db pb-1">Obtén hasta un {subscriptionInfo.max_discount}% de descuento</p>
              }
              { !subscriptionInfo.summary_text &&
                <p className="txt-4--regular color-darkblue-ds text-justify">{cardType === 1 ? textSubscriptionContent1 : textSubscriptionContent2}</p>
              }
            </>
          )}
          {(cardType === 3 || cardType === 4) && (!subscriptionInfo.summary_title || !subscriptionInfo.summary_text) &&(
            <p className="txt-4--regular color-darkblue-ds">
              {  cardType === 3 ? textSubscriptionContent3 : parse(textSubscriptionContent4)}
            </p>
          )}

          { subscriptionInfo.summary_text && <p className="txt-4--regular color-darkblue-ds text-justify">{ parse(subscriptionInfo.summary_text)}</p> }

          { subscriptionInfo.professional_discount &&
            <p className="txt-4--regular color-darkblue-ds text-justify my-2">
              Además, al compartir tu código con tus pacientes obtendrán un { subscriptionInfo.professional_discount }% de descuento por sobre este precio.
            </p>
          }
        </div>
        <div className={"d-flex justify-content-between pl-3 " + (props.isProfessional ? "pt-2" : "py-2")}>
          <div className="d-flex align-items-center">
            <p className="txt-4--bold-db">
              { props.isProfessional ? subscriptionSpecialPrice :
                  subscriptionInfo.program_title ? subscriptionStandardPrice :
                    cardType === 1 ? textSubscriptionPrice1 :
                      cardType === 2 ? textSubscriptionPrice2 :
                        cardType === 3 ? textSubscriptionPrice3 :
                          textSubscriptionPrice4
              }
            </p>
          </div>

          <div className="subscription-card--header-right d-flex flex-column align-items-end justify-content-center">
            <div className={ (subscriptionInfo.program_title && subscriptionInfo.program_price.length > 0)
              || (subscriptionInfo.professional_discount) ? "content-price bg-light" : "content-price"
            }>
              <i className="icon-circle-price"></i>
              <p className="txt-4--bold-db">{_formatPrice(subscriptionInfo.prices[0].price * subscriptionInfo.product.counter)}</p>
            </div>
          </div>
        </div>

        { props.isProfessional &&
          <div className="d-flex pl-3 pb-2">
            <p className="txt-5--bold-db">Por caja</p>
          </div>
        }

        { subscriptionInfo.professional_discount &&
          <div className="d-flex flex-column pb-2">
            <div className="px-3 pb-1">
              <p className="txt-4--bold-db">Precio con tu código de Convenio</p>
            </div>

            <div className="d-flex justify-content-between pt-1 pl-3">
              <div className="d-flex align-items-center">
                <p className="txt-5--regular-db"> </p>
              </div>

              <div className="d-flex subscription-card--header-right flex-column align-items-end justify-content-center">
                <div className="content-price">
                  <i className="icon-circle-price"></i>
                  <p className="txt-4--bold-db">
                    { subscriptionInfo.professional_discount + '% dcto ' + _formatPrice(subscriptionInfo.professional_discount_price) }
                  </p>
                </div>
              </div>

            </div>
          </div>
        }
        
        { subscriptionInfo.program_title && subscriptionInfo.program_price.length > 0 &&
          <div className="d-flex flex-column pb-2">
            <div className="px-3 pb-1">
              <p className="txt-4--bold-db">{ subscriptionInfo.program_title}</p>
            </div>

            { subscriptionInfo.program_price.map((item) =>
              <div className="d-flex justify-content-between pt-1 pl-3">
                <div className="d-flex align-items-center" key={item.purchase_price}>
                  <p className="txt-4--regular-db">{item.purchase_count}</p>
                </div>

                <div className="d-flex subscription-card--header-right flex-column align-items-end justify-content-center">
                  <div className="content-price">
                    <i className="icon-circle-price"></i>
                    <p className="txt-4--bold-db">{_formatPrice(item.purchase_price)}</p>
                  </div>
                </div>

              </div>
              )
            }
          </div>
        }

        { !props.isProfessional &&
          <div className="subscription-card--center">
            <Accordion>
              <Card>
                <Card.Header className="p-3">
                  <ContextAwareToggle eventKey={1} />
                </Card.Header>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body>
                    <div className="content-detail">
                      <img
                        src={subscriptionInfo?.product?.product_logo || defaultMedication}
                        alt="producto"
                        width="50"
                        className="mb-2 content-detail-img"
                        onClick={() =>
                          onClickShowPreview(subscriptionInfo?.product?.product_logo || defaultMedication)}
                      />
                      <div className="d-flex align-items-start mb-3">
                        <div>
                          <p className="txt-4--regular-db">{subscriptionInfo.product.counter}x</p>
                        </div>
                        <div className="ml-2 ">
                          <p className="txt-4--regular-db mb-1">
                            {subscriptionInfo.product.product_name}
                          </p>
                          {subscriptionInfo.product.formula_name &&
                            <p className="txt-5--regular-g1 color-darkblue-ds">
                              {subscriptionInfo.product.formula_name}
                            </p>
                          }

                          {subscriptionInfo.product.laboratory_name &&
                            <p className="txt-5--regular-g1 mb-3 color-darkblue-ds">
                              {subscriptionInfo.product.laboratory_name}
                            </p>
                          }

                          {(cardType === 1) && (
                            <>
                              <div className="py-2 pr-3 d-inline-block position-relative content-detail-subscription mb-3">
                                <p className="txt-4--bold-db">{cardType === 1 ? textSubscriptionDetail1 : textSubscriptionDetail2}</p>
                                <img src={icYappApproved} className='approved-icon-sm' alt='Convenio Yapp' />
                              </div>

                              {prices.map((item) =>
                                <div className="container-percentage-change d-flex" key={item.delivery_count}>
                                  <div className="container-percentage-change--left">
                                    <div className="percentage-change--left-content">
                                      {item.delivery_count === 1
                                        ? <p className="txt-5--regular-db">1º compra</p>
                                        : <>
                                          <p className="txt-4--regular-db">{item.delivery_count}° compra</p>
                                          <p className="txt-5--regular-db">en adelante</p>
                                        </>
                                      }
                                    </div>
                                    <img src={extremeRadiusBase} className="percentage-change--left-base" alt='Rectángulo' />
                                  </div>
                                  <div className="container-percentage-change--right">
                                    <p className="txt-4--bold-pr">{item.discount}% de dcto.</p>
                                    <p className="txt-4--regular-db">Pagas {_formatPrice(item.price)}</p>
                                  </div>
                                </div>
                              )
                              }
                            </>
                          )}
                          {(cardType === 2 || cardType === 3 || cardType === 4) && (
                            <div className="py-2 pr-3 d-inline-block position-relative content-detail-subscription d-flex">
                              <p className="txt-5--regular-db mr-3">{cardType === 3 ? textSubscriptionDetail3 : textSubscriptionDetail4}</p>
                              <p className="txt-5--bold-db">{_formatPrice(subscriptionInfo.min_price * subscriptionInfo.product.counter)}</p>
                              <img src={icYappApproved} className='approved-icon-sm' alt='Convenio Yapp' />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            { subscriptionInfo.delivery?.length > 0 &&
              <Card>
                <Card.Header className="px-3 pt-3 pb-2">
                  <div className="d-flex">
                    <div className="d-flex w-100 justify-content-between mb-3">
                      <div className="d-flex content-icon-pharmacy">
                        <i className="icon-circle-time mr-2" />
                        <p className="txt-4--bold-db">
                          Despacho en {subscriptionInfo.delivery[0].message}
                        </p>
                      </div>
                      { showQuotationDeliveryCost && (
                        <p className="txt-4--bold-db">
                          + {_formatPrice(subscriptionInfo.delivery[0].cost)}
                        </p>
                      )}
                    </div>
                  </div>
                </Card.Header>
              </Card>
            }
          </div>
        }

        <div className="subscription-card--bottom">
          { props.sendDataSubscription &&
            <ButtonYapp
              className="mx-auto px-5"
              small
              onClick={onCheckout}
              color={'--theme-color'}
              title="Comprar"
            />
          }

          { subscriptionInfo.professional_discount &&
            <div className="content-yapp-agreement-share mt-2">
              <ButtonYapp
                className="btn-share-send-email mt-md-2"
                onClick={() => {
                  logEvent('Item Share Opened', {
                    'Shared Type': 'Yapp Benefit Code',
                    'Element Shared': subscriptionInfo.professional_discount_code,
                    'Lab Name': subscriptionInfo.product.laboratory_name,
                    'Product Id': subscriptionInfo.product.product_id,
                    'Product Name': subscriptionInfo.product.product_name,
                    'Category Id': subscriptionInfo.product.category_id
                  })
                  props.setSendDiscountIsOpen(true)
                }}
                title={ subscriptionInfo.share_button_text }
              />
            </div>
          }
        </div>
        <ModalPreview show={showPreview} onClose={handleClose} img={modalImage} />
      </div>
  )
}

export default SubscriptionCard
