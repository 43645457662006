import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ENUM_MENU } from '../../Utils/constants'

import ProductSearchPrincipleActive from '../../Components/ProductSearch/SearchPrincipleActive'
import { addEventNameWithParams, logEvent } from '../../Utils/utils'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import { _formatPrice } from '../../Utils/tools'
import { getBioequivalents, getProductByExternalId } from '../../Utils/api'
import productImg from '../../assets/img/prescription.png'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import proIconLoading from '../../assets/img/proIconLoading.svg'

const _orderBy = require('lodash/orderBy')

function PriceFinderProduct() {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [productId, setProductId] = useState(history.location.state?.product_id)
  const [product, setProduct] = useState(history.location.state?.product)
  const [bio, setBio] = useState(null)
  const [infoProduct, setInfoProduct] = useState(null)

  useEffect(() => {
    if (productId) {
      getBioequivalentsProducts(productId)
      getInfoProduct(productId)
    } else {
      history.push('/error')
    }
  }, [])

  const onClickSearch = async (newValue) => {
    setLoading(true)
    if (
      newValue?.data?.is_product === 1 ||
      [undefined, null].includes(newValue?.data?.is_product)
    ) {
      if (newValue?.value) {
        let id = newValue.data.product_id
        addEventNameWithParams('price_finder_product_select_product', {
          product_id: id,
          product_name: newValue?.label,
        })
        clearLocalStates()

        setProductId(id)
        setProduct(newValue.data)
        getBioequivalentsProducts(newValue.data.product_id)

        history.replace('/pro/price_finder_product', {
          product_id: id,
          product: newValue.data
        })
      } else {
        console.log('Error value')
        setLoading(false)
      }
    } else {
      addEventNameWithParams('price_finder_product_select_active_principle', {
        product_name: newValue?.label,
        active_principle_id: newValue?.data?.active_principle_id,
      })

      history.push('/pro/price_finder_result', {
        product_input: newValue,
      })
    }
  }

  const getInfoProduct = async (dataProdctId) => {
    try {
      let infoProduct = await getProductByExternalId(dataProdctId)
      if (infoProduct?.data) {
        setInfoProduct(infoProduct?.data[0])
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getBioequivalentsProducts = async (dataProductId) => {
    try {
      let bioProducts = await getBioequivalents(dataProductId)
      if (bioProducts?.data) {
        let filterBio = _orderBy(bioProducts?.data, ['price_from'], ['asc'])
        setBio(filterBio)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const clearLocalStates = () => {
    setProductId(null)
    setProduct(null)
  }

  const onClickOption = (itemProductId, itemProduct, typeSelected) => {
    logEvent('Price Finder Checked', {
      'Origin': 'Menu',
      'Product Id': itemProductId,
      'Product Name': itemProduct?.product_name,
      'Lab Name': itemProduct?.laboratory_name,
      'Search Type': typeSelected === 'product_result' ? 'Product Result' : 'Product Similar'
    })
    switch (typeSelected) {
      case 'product_result':
        addEventNameWithParams('price-finder-products--product-result', {
          product_id: itemProductId,
        })
        break
      case 'product_similar':
        addEventNameWithParams('price-finder-products--product-similar', {
          product_id: itemProductId,
        })
        break
    }

    history.push(`/pro/price_finder_detail/${itemProductId}`, {
      product_id: itemProductId,
      product: itemProduct,
    })
  }

  function goBack() {
    history.goBack()
  }

  return (
    <>
      {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
        <div className="breadcrumbs c-pointer" onClick={goBack}>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </a>
          <span className="title-primary--regular line-14">Volver</span>
        </div>
      </div>}
      <div className="flex-grow-1">
        <div className="content-responsive mx-auto">
          <div className="py-2 px-3 px-md-0">
            <h2 className="title-primary--xxl m-0">Buscador de precios</h2>
            <div className="">
              <ProductSearchPrincipleActive
                principleActive={true}
                request_from={ENUM_MENU.price_finder}
                className="hpt-16"
                onClickSearch={onClickSearch}
                isNotShow={true}
                vidal={2}
                placeholder={'Busca por marca o principio activo'}
              />
            </div>
          </div>
        </div>
        <hr className="hr--gray" />
        <div className="content-responsive mx-auto">
          {loading ? (
            <div className='text-center image-center'>
              <h2 className='title-primary--3xl-bold mt-3'>En breve verás tu búsqueda</h2>
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--pro m-0"
              />
              <img
                src={proIconLoading}
                alt='Loading icon' 
                className='maxw-290'
              />
            </div>
          ) : (
            <div className="py-4 px-3 px-md-0">
              <h2 className="title-primary--xl-bold mb-3">Resultado</h2>
              {product && (
                <CardInteracion
                  id={`gtm-webapp-price-finder-product-selected`}
                  className="mb-4"
                  doAction={() =>
                    onClickOption(
                      product?.product_id,
                      product,
                      'product_result',
                    )
                  }>
                  <div className="content-img-medication-sheet">
                    <img
                      src={
                        product?.product_logo
                          ? product?.product_logo
                          : productImg
                      }
                      alt={`nombre del producto`}
                      className="img-mw-35"
                    />
                  </div>
                  <div className="ml-14 overflow-hidden">
                    <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2">
                      {product?.product_name}
                    </h2>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.formula_name}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.presentation}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.laboratory_name}
                    </p>
                    {infoProduct?.priceFrom && (
                      <p className="txt-paragraph--bold m-0 line-16 text-ellipsis--2">
                        Desde {_formatPrice(infoProduct?.priceFrom)}
                      </p>
                    )}
                  </div>
                </CardInteracion>
              )}
              <h2 className="title-primary--xl-bold mb-3">
                Productos relacionados
              </h2>
              <div className="content-card-product">
                {bio?.map((item, key) => (
                  <CardInteracion
                    key={key}
                    id={`gtm-webapp-price-finder-product-relations-${key}`}
                    doAction={() =>
                      onClickOption(item?.product_id, item, 'product_similar')
                    }>
                    <div className="content-img-medication-sheet">
                      <img
                        src={
                          item?.product_logo ? item?.product_logo : productImg
                        }
                        alt={`nombre del producto`}
                        className="img-mw-35"
                      />
                    </div>
                    <div className="ml-14 overflow-hidden">
                      <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2">
                        {item?.product_name}
                      </h2>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.formula_name}
                      </p>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.presentation}
                      </p>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.laboratory_name}
                      </p>
                      <p className="txt-paragraph--bold m-0 line-16 text-ellipsis--2">
                        Desde {_formatPrice(item?.price_from)}
                      </p>
                    </div>
                  </CardInteracion>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PriceFinderProduct
