import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {_formatPrice} from '../../../Utils/tools'
import {getExamsCart, saveExamsCart} from '../../../Utils/service'

import SearchTypeToggle from '../../../Components/Search/SearchTypeToggle'
import ExamSearch from '../../../Components/Search/ExamSearch'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'

import {cleanText} from '../../../Utils/utils'

import loadingYapp from '../../../assets/img/yapp_loading.gif'

import {changeLogo, showLogo, setThemeColor} from '../../../redux/modules/app'

import minsalUso from '../../../assets/img/minsal-uso-racional.png'
import minsalVenta from '../../../assets/img/minsal_ventas_directas.png'
import ellipse from '../../../assets/img/ic_ellipse.svg'
import {logEvent} from '../../../Utils/utils'

const _ = require('lodash')

function SearchExam(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loadingExams, setLoadingExams] = useState(false)
  const [appState, setAppState] = useState({loading: true, data: []})

  const themeColor = useSelector((state) => state.app.themeColor)
  const isProHash = useSelector((state) => state.app.isPro)

  const [isDesktopWidth, setIsDesktopWidth] = useState(null)

  async function actionNext() {
    history.push('/exam/quotation')
  }

  const clickUpdateCounter = async (element, value) => {
    let arrFirestore = await getExamsCart()
    let newArr = []
    newArr = arrFirestore.filter((el) => {
      if (el.code_fonasa !== element.code_fonasa) {
        return el
      }
    })

    saveExamsCart(newArr)
    setAppState({data: newArr})
  }

  useEffect(async () => {
    try {
      setLoadingExams(true)
      let arrFirestore = await getExamsCart()
      setAppState({loading: false, data: arrFirestore})
      setLoadingExams(false)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const onClickSearch = async (newValue) => {
    if (newValue?.value) {
      setLoadingExams(true)
      let arrFirestore = await getExamsCart()
      let exam = newValue

      if (Array.isArray(arrFirestore) && exam) {
        if (
          arrFirestore.find((el) => el.code_fonasa === exam.value) === undefined
        ) {
          exam.data.counter = 1
          arrFirestore.push(exam.data)
          saveExamsCart(arrFirestore)
        }
      }

      setAppState({loading: false, data: arrFirestore})
      setLoadingExams(false)
    } else {
      console.log('Error value')
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (props.disabledThemeCheckout === true) {
      dispatch(changeLogo(props.clientLogo))
      dispatch(showLogo(true))
      dispatch(setThemeColor(props.themeColor))
    }
  }, [])

  useEffect(() => {
    try {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    } catch (error) {
      console.log(error)
    }
  }, [isDesktopWidth])

  const handleWindowSizeChange = () => {
    setIsDesktopWidth(window.innerWidth < 768)
  }

  return (
    <>
      {isProHash && (
        <>
          <div className="d-flex justify-content-between py-3 px-3">
            <div
              className="content-responsive mx-auto breadcrumbs c-pointer"
              onClick={() => history.push('/pro/menu')}>
              <a className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse"></i>
              </a>
              <span className="title-primary--regular line-14">Volver</span>
            </div>
          </div>
        </>
      )}
      <div
        className="flex-grow-1 px-3 px-md-0 pb-4"
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-responsive mx-auto mt-2">
          <div className="d-flex justify-content-center">
            <SearchTypeToggle
              exams={true}
              medicamentsOnClick={() => {
                logEvent('Product Opened')
                history.push('/search')
              }}
            />
          </div>
          <ExamSearch
            themeColor={'--theme-color'}
            styleSecondary
            onClickSearch={onClickSearch}
          />
        </div>

        {/** Mobile */}
        <div className="content-responsive mx-auto mb-4 mt-2">
          <div className="d-flex flex-wrap bag-content pt-2 mb-4">
            {appState.data && !loadingExams ? (
              appState.data?.map((el, key) => {
                return (
                  <div key={key} className="exam-detail mb-2 c-pointer">
                    <div
                      id="gtm-webapp-mobile-exam-detail"
                      onClick={() => null} //clickShowDetail(el.product_id)}
                      className="">
                      <div className="d-flex">
                        <div className="d-flex align-items-center w-100 content-icon-pharmacy">
                          <img
                            src={ellipse}
                            alt="Ellipse"
                            className={'color-theme mr-2'}
                          />
                          <p className="txt-paragraph line-14 mt-2 mb-2">
                            {' '}
                            {cleanText(el.exam_name)}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <i
                            className="icon-delete mr-2 color-theme-secondary"
                            onClick={(event) => {
                              clickUpdateCounter(el, event)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="d-flex justify-content-center w-100 py-5">
                <img src={loadingYapp} alt="loading" height={70} />
              </div>
            )}
          </div>
          {!loadingExams && appState.data?.length > 0 && (
            <div className="d-flex justify-content-center mt-2">
              <ButtonYapp
                className="px-5"
                id="gtm-webapp-exam-bag-btn-next"
                color={'--theme-color'}
                title={'Ir a Cotizar'}
                onClick={actionNext}
              />
            </div>
          )}
        </div>

        <div className="maxw-584 mx-auto d-flex justify-content-center minsal-img-container mt-4">
          <a
            rel="noreferrer"
            className="pr-2"
            href="https://www.minsal.cl/wp-content/uploads/2016/04/MAGNETO-USO-RACIONAL-DE-MEDICAMENTOS-PARA-IMPRESION.pdf"
            target="_blank">
            <img
              src={minsalUso}
              alt="minsal uso racional"
              className="minsal-img w-100"
            />
          </a>
          <a
            className=""
            rel="noreferrer"
            href="https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf"
            target="_blank">
            <img
              src={minsalVenta}
              alt="minsal venta directa"
              className="minsal-img w-100 "
            />
          </a>
        </div>
      </div>
    </>
  )
}

const localStyles = {
  stepper: {
    input: {borderColor: 'transparent', width: '30%', padding: '0.4rem'},
    buttons: {
      backgroundColor: '#46CEBE',
      borderColor: 'transparent',
      padding: '0.4rem',
      width: '3rem',
    },
  },
  rowButton: {
    justifyContent: 'center',
    marginTop: '1rem',
  },
}

export default SearchExam
