import React, {useState, useEffect} from 'react'
import './Content.css'
import {Tabs, Tab} from 'react-bootstrap'
import ProductDetail from '../../Pages/Product/Detail'
import PriceFinderDetail from '../../Pages/Pro/PriceFinderDetail'
import {logEvent} from '../../Utils/utils'

function Content(props) {
  const [key, setKey] = useState('prices')
  const [limitTag, setLimitTag] = useState(1)

  useEffect(() => {
    if (props?.results) {
      setLimitTag(1)
    }
  }, [props?.results])

  function onSelectTab(key) {
    setKey(key)
    if (key === 'medication-card') {
      if (limitTag === 1) {
        logEvent('Medication Sheet Checked', {
          Origin: 'Search Option',
          'Product Id': props?.results?.product?.product_id,
          'Product Name': props?.results?.product?.product_name,
          'Lab Name': props?.results?.product?.laboratory_name,
          'From Active Principle': props?.results?.fromActivePrinciple,
        })
      }
      setLimitTag(2)
    }
  }

  return (
    <Tabs
      activeKey={key}
      onSelect={(k) => onSelectTab(k)}
      id="uncontrolled-tab-example"
      className="tabs mb-3 mt-3 align-items-start justify-content-around;">
      <Tab eventKey="prices" title="Precios" className="txt-2--bold">
        <PriceFinderDetail results={props.results} programList={props.programList} productList={props.productList}/>
      </Tab>
      <Tab
        eventKey="medication-card"
        title="Ficha del medicamento"
        className="txt-2--bold">
        <ProductDetail
          results={props.results}
          setContactLaboratory={props.setContactLaboratory}
        />
      </Tab>
    </Tabs>
  )
}

export default Content
