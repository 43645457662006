import amplitude from 'amplitude-js'
import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {
  loginAnonymoust,
  getRedirectResult,
  signOut,
  loginCustomToken,
} from '../../Utils/auth'
import {changeInitialLoading, changeConfig} from '../../redux/modules/app'
import {setUserExtraInfo} from '../../redux/modules/user'

import {fetchUser} from '../../redux/modules/user'
import {logEvent, setUserProperties} from '../../Utils/utils'
import {usedProfessional, setSessionIds} from '../../Utils/api'

const _get = require('lodash/get')

async function sendTrackingIds(rut) {
  if (process.env.REACT_APP_ENV !== 'staging') {
    await window.gtag(
      'get',
      process.env.REACT_APP_GTAG,
      'session_id',
      async (analytics_session_id) => {
        await setSessionIds({
          rut: rut,
          amplitude_session_id: amplitude.getInstance()._sessionId,
          analytics_session_id: analytics_session_id,
          origin: process.env.REACT_APP_CLIENT_NAME,
        })
      },
    )
  }
}

function SignInWidget() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [loginError, setLoginError] = useState(false)

  const isProfessional = process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'
  const [isLoading, setIsLoading] = useState(false)
  const skipLogin =
    location.state?.requiredValidate === true
      ? 'FALSE'
      : process.env.REACT_APP_ONBOARDING_REQUIRED === 'TRUE'
      ? 'TRUE'
      : process.env.REACT_APP_SKIP_LOGIN

  const rut = useSelector((state) => state.user.identity)
  const userInfo = useSelector((state) => state.user.userInfo)
  const customToken = location.state?.custom_token
  function goBack() {
    history.goBack()
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        dispatch(changeInitialLoading(true))
        const response = await getRedirectResult()
        if (response) {
          actionNext(response, true)
        } else {
          if (
            skipLogin === 'TRUE' ||
            (process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE' &&
              [undefined, null, 'NULL'].includes(
                process.env.REACT_APP_EXTERNAL_VALID_TYPE,
              )) ||
            process.env.REACT_APP_PROFILE === 'INSURANCE'
          ) {
            if (userInfo?.signInMethod) {
              if (process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE') {
                setIsLoading(false)
                dispatch(changeInitialLoading(false))
                logEvent('Logged In')
                return history.push('/pro/menu')
              }
            } else {
              let response = {}
              await signOut()
              if (customToken) {
                response = await loginCustomToken(customToken)
              } else {
                response = await loginAnonymoust()
                if (rut) {
                  await sendTrackingIds(rut)
                }
              }
              actionNext(response)
            }
            return
          } else {
            dispatch(changeInitialLoading(false))
            setIsLoading(false)
          }
        }
      } catch (error) {
        // handle error here
        console.log('fail:', error)

        signOut().then()
        setIsLoading(false)
        dispatch(changeInitialLoading(false))
      }
    }

    fetchData()
  }, [customToken, dispatch, history, rut, skipLogin, userInfo?.signInMethod])

  const actionNext = async (response, skipConfig = false) => {
    try {
      switch (response.status) {
        case 'success':
          dispatch(fetchUser())
          let user_id = response?.data?.uid
          let localData = {}
          let res = null

          /* if (skipConfig === false || isProfessional) {
            res = await getConfigSite()
            if (!res) {
              return history.push('/pro/menu')
            }
          } */

          localStorage.setItem('LOCAL_DATA', JSON.stringify(localData))

          try {
            let userProperties = null

            if (isProfessional && response?.data?.rut) {
              usedProfessional(response?.data?.rut).then((professional) => {
                let user_type = _get(professional, 'data[0].user_type', 'user')
                window.gtag('set', 'user_type', user_type)
                userProperties = {
                  'User Type': user_type,
                  'Account Type': response?.data?.provider,
                }

                setUserProperties(userProperties)
              })
            } else {
              window.gtag('set', 'user_type', 'user')
              userProperties = {
                'User Type': skipLogin === 'TRUE' ? 'anonymous' : 'user',
                'Account Type': response?.data?.provider,
              }

              setUserProperties(userProperties)
            }

            let accountType = response?.data?.provider
              ? response?.data?.provider.split('.')[0]
              : 'anonymous'
            if (accountType !== 'anonymous') {
              logEvent('Logged In', {
                'Account Type': accountType,
                'Logged In Success': true,
                'User Type': 'Existing User',
              })
              history.push('/pro/menu')
            }
          } catch (error) {
            console.log('Error set user gtag.')
          }

          if (skipConfig === false || isProfessional) {
            dispatch(changeConfig(res))
            dispatch(setUserExtraInfo(_get(res?.data, 'info', {})))
            let route = res?.data?.route

            history.replace(route, {site: res?.data})
          }
          break
        case 'no-account': // data en authentication
          dispatch(fetchUser())
          logEvent('Logged In', {
            'Account Type': response?.data?.provider.split('.')[0],
            'Logged In Success': false,
            'User Type': 'New User',
          })
          history.replace(
            process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'
              ? '/register_validate'
              : '/register',
          )
          break
        case 'no-professional': // data en la bd / data en authentication
          dispatch(fetchUser())
          logEvent('Logged In', {
            'Account Type': response?.data?.provider.split('.')[0],
            'Logged In Success': false,
            'User Type': 'Existing User',
          })
          history.replace('/register_validate')
          break
        default:
          dispatch(changeInitialLoading(false))
          setLoginError(true)
          setIsLoading(false)
          break
      }
    } catch (error) {
      console.log(error)
      dispatch(changeInitialLoading(false))
      setLoginError(true)
      setIsLoading(false)
    }
  }

  if (skipLogin === 'TRUE')
    return isLoading ? null : (
      <div className="content-responsive d-flex justify-content-between py-3 px-3 px-md-0">
        <div className="breadcrumbs c-pointer" onClick={goBack}>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </a>
          <span className="txt-4--regular-pr">Volver</span>
        </div>
      </div>
    )

  return (
    <>
      <div className="flex-grow-1"></div>
    </>
  )
}

export default SignInWidget
