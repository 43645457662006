import {useEffect, useState, memo} from 'react'
import {connect, useDispatch} from 'react-redux'
import {
  deliveryFrequencies,
  subscriptionTypes,
  base_url,
} from '../../../Utils/constants'
import {getPreOrder, removeShoppingCart} from '../../../Utils/service'
import {logEvent} from '../../../Utils/utils'
import {createSubscription} from '../../../Utils/api'
import CheckboxControl from '../../../Components/Forms/CheckboxControl'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import SimpleSelect from '../../../Components/Forms/SimpleSelect'
import ModalBottomSimple from '../../../Components/Modals/ModalBottomSimple'
import imgPlane from '../../../assets/img/ic_plane.svg'
import imgResultError from '../../../assets/img/i_error.svg'
import {useHistory} from 'react-router'
import {setPrescriptions} from '../../../redux/modules/app'

function PostShopping(props) {
  const dispatch = useDispatch()
  const [showModalBottomSimple, setShowModalBottomSimple] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [productList, setProductList] = useState([])
  const [frequency, setFrequency] = useState(deliveryFrequencies[2])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(async () => {
    let currentBag = localStorage.getItem('selectedQuotationItem')
    dispatch(setPrescriptions([]))

    if (currentBag) {
      currentBag = JSON.parse(currentBag)
      if (currentBag.quotation_detail?.length > 0) {
        setProductList(currentBag.quotation_detail)

        let preOrder = await getPreOrder()
        if (preOrder?.data?.user) {
          let address = null
          let addressLatitude = null
          let addressLongitude = null

          if (preOrder.data.delivery.address) {
            address = preOrder.data.delivery.address.description.replace(
              ' null ',
              ' ',
            )
            addressLatitude = preOrder.data.delivery.address.lat
            addressLongitude = preOrder.data.delivery.address.lng
          }

          let customer = {
            name: preOrder.data.user.name,
            tax_id: preOrder.data.user.identity,
            address: address,
            address_id: null,
            address_latitude: addressLatitude,
            address_longitude: addressLongitude,
            commune: props.commune.name,
            phone: preOrder.data.user.phone,
            comment: '',
          }

          let subscriptionInfo = {
            user_id: preOrder.data.user.user_id,
            payment_id: preOrder.data.payment_card.token,
            pharmacy_chain_id: currentBag.pharmacy_chain_id,
            pharmacy_chain_name: currentBag.pharmacy_chain_name,
            first_delivery: currentBag.delivery.next_date,
            delivery_cost: currentBag.delivery.cost,
            customer: customer,
            prescription_files: preOrder.data.prescriptions,
          }

          setSubscription(subscriptionInfo)
        }
      }
      localStorage.removeItem('selectedQuotationItem')
      await removeShoppingCart()
    }
  }, [])

  const isSubscriptionValid = () => {
    let disableSubscription = true
    let validProducts = productList.filter(
      (product) => product.checked === true && product.quantity > 0,
    )

    if (frequency && validProducts.length > 0) {
      disableSubscription = false
    }
    return disableSubscription
  }

  const onCancelSubscription = () => {
    //window.location.href =  base_url
    history.push('/shopping_bag/success')
  }

  const onCreateSubscription = () => {
    let selectedProducts = productList.filter(
      (product) => product.checked === true && product.quantity > 0,
    )
    let subscriptionInfo = Object.assign({}, subscription)
    let products = []

    for (const item of selectedProducts) {
      let product = {
        type: subscriptionTypes.regular,
        product_id: item.product.product_id,
        pharmacy_chain_id: subscriptionInfo.pharmacy_chain_id,
        quantity: item.quantity,
      }
      products.push(product)
    }

    subscriptionInfo.products = products
    subscriptionInfo.frequency = frequency.value
    subscriptionInfo.origin = 'yapp_web'
    saveSubscription(subscriptionInfo)
  }

  const saveSubscription = async (subscription) => {
    try {
      setLoading(true)
      const result = await createSubscription(subscription)
      if (result?.data?.length > 0 && result.data[0].subscriptionId) {
        saveTag(result.data[0].subscriptionId)
        window.location.href += '/success'
      } else {
        saveTag(false)
        setError(true)
      }
    } catch (err) {
      console.error(err)
      saveTag(false)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const saveTag = (subscriptionId) => {
    let validProducts = productList.filter(
      (product) => product.checked === true && product.quantity > 0,
    )
    let isSuccess = subscriptionId ? true : false
    let productQuantity = 0
    let totalPrice = 0

    for (const product of validProducts) {
      productQuantity += product.quantity
      totalPrice += product.quantity * product.price

      logEvent('Recurrent Product Selected', {
        'Product Quantity': product.quantity,
        'Subscription Id': subscriptionId ? subscriptionId : 0,
        'Recurrent Purchase Frequency': frequency.value,
        'Product Name': product.product.name,
        'Product Id': product.product.product_id,
        'Lab Name': product.product.lab,
        'Product Price': product.price,
        'Total Price': product.quantity * product.price,
        'Total Discounts': 0,
        'Pharmacy Id': subscription.pharmacy_chain_id,
        'Pharmacy Name': subscription.pharmacy_chain_name,
        'Active Principle Name': product.product.formula_name,
      })
    }

    logEvent('Recurrent Purchase Scheduled', {
      'Success Recurrent Purchase': isSuccess,
      'Pharmacy Id': subscription.pharmacy_chain_id,
      'Pharmacy Name': subscription.pharmacy_chain_name,
      'Subscription Id': subscriptionId ? subscriptionId : 0,
      'Total Price': totalPrice,
      'Total Discounts': 0,
      'Product Quantity': productQuantity,
      'Recurrent Purchase Frequency': frequency.value,
    })
  }

  return (
    <div className="content-responsive mx-auto px-3 mb-5">
      {error ? (
        <div className={`maxw-300 card-messege mx-auto mt-5 mb-4`}>
          <img
            id={'gtm-webapp-checkout-result-img-error'}
            src={imgResultError}
            alt="Success"
            className="mb-4 img-message"
          />
          <h4 className={`txt-1--bold-db mb-3 text-center`}>
            No hemos procesado tu pedido con éxito...
          </h4>
          
        </div>
      ) : (
        <div className="maxw-436 mx-auto mt-5">
          <div className="d-flex justify-content-center">
            <img
              src={imgPlane}
              style={{width: '3.4375rem', height: '1.875rem'}}
              alt="Avión de papel"
            />
          </div>
          <p className="txt-2--bold-db mt-3 mb-2 text-center">
            ¡Gracias por tu compra!
          </p>
          <p className="txt-4--regular-db text-center mb-4">
            Por favor espera la confirmación de la <br />
            farmacia por correo.{' '}
          </p>
          <div className="simple-card px-3 py-4">
            <p className="txt-3--bold-db mb-2">
              Programa tus siguientes compras
            </p>
            <p className="txt-5--regular-db mb-4">
              Selecciona los medicamentos que quieras programar, elige cada
              cuántos días y <span className="txt-5--bold-db">¡Listo!</span>
            </p>
            <CheckboxControl
              productList={productList}
              setProductList={setProductList}
            />
            <hr />
            <div className="d-flex align-items-center">
              <div className="w-25">
                <p className="txt-5--regular-pr">Recibir cada</p>
              </div>
              <div className="w-75">
                <SimpleSelect
                  value={frequency}
                  options={deliveryFrequencies}
                  onChange={setFrequency}
                  className="tablet-desktop"
                />
                <div
                  onClick={() => setShowModalBottomSimple(true)}
                  className="btn-open-modal-bottom-full d-flex align-items-center justify-content-between px-3">
                  <div className="d-flex align-items-center justify-content-center w-100">
                    <p className="txt-5--regular-db pl-2">{frequency?.label || ''}</p>
                  </div>
                  <i className="icon-chevron-down color-theme"></i>
                </div>
                <ModalBottomSimple
                  currentFrequency={frequency}
                  showModal={showModalBottomSimple}
                  onChange={setFrequency}
                  setShowModal={setShowModalBottomSimple}
                />
              </div>
            </div>
            <p className="txt-6--regular-db mb-4 mt-2">
              *El despacho se cobrará en cada envío y el costo total se
              realizará <span className="txt-6--bold-db">48 hrs antes</span> de
              cada compra.
            </p>
            <ButtonYapp
              className="mx-auto w-230 px-0"
              onClick={onCreateSubscription}
              disabled={isSubscriptionValid() || loading}
              color="--theme-color"
              title={'Programar siguientes compras'}
            />
          </div>
          <ButtonYapp
            className="mx-auto w-230 px-0 mt-3"
            disabled={loading}
            onClick={onCancelSubscription}
            color="--theme-color"
            title={'No me interesa'}
            stroke
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    commune: state.app.commune,
  }
}

export default connect(mapStateToProps, null)(memo(PostShopping))
