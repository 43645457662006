import React from 'react'

function CardInteracion(props) {
  return (
    <>
      <div
        id={props.id}
        className={`btn-card-interaction c-pointer ${props.className}`}
        onClick={props.doAction}>
        <div className="d-flex align-items-center maxw-90p">
          {props.children}
        </div>
        <div className="btn-card-interaction-next ">
          <i className="icon-chevron-right"></i>
        </div>
      </div>
    </>
  )
}

export default CardInteracion
