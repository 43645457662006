import React, {useState, useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import icSearchSecondary from '../../assets/img/ic_search_secondary.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import {getProgramsByType, getProductsWithProgram} from '../../Utils/api'
import { logEvent} from '../../Utils/utils'
import {theme} from '../../Utils/constants'
const debounce = require('lodash/debounce')
const type = 'PATIENTS'
const filters = ['Descuento', 'Educación al paciente']

function Programs(props) {
  const history = useHistory()
  const [programs, setPrograms] = useState([])
  const [productList, setProductList] = useState([])
  const [appState, setAppState] = useState({
    loading: true,
    exams: [],
    inputValue: '',
    groupedOptions: [],
  })

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    getProgramsByType(type)
      .then((resp) => {
        setPrograms(resp.data)
        setAppState({loading: false})
      })
      .catch((ignore) => {
        setAppState({loading: false})
      })
  }, [])

  const filteredPrograms = useMemo(() => {
    return programs.filter((program) => {
      return filters.some((filter) => {
        return program?.program_tag?.includes(filter)
      })
    })
  }, [programs])

  function goBack() {
    history.goBack()
  }

  const getProducts = async () => {
    try {
      let allProducts = await getProductsWithProgram()
      if (allProducts?.data) {
        setProductList(allProducts?.data.data[0])
      }
    } catch (ignore) {}
  }
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const promiseOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let prod = productList?.filter((product) =>
        product.product_name.toLowerCase().includes(inputValue.toLowerCase()),
      )
      if (prod.length > 0) {
        let group = prod.map((p) => {
          return {value: p.product_id, label: p.product_name, data: p}
        })
        let options = []
        options.push({
          label: '',
          options: group,
        })
        setAppState({groupedOptions: options})
        callback(options)
      }
    }
  }

  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  const borderColor = theme?.border_color
    ? `1px solid ${theme?.border_color}`
    : props.themeColor
    ? `1px solid var(${props.themeColor})`
    : '1px solid #6578FF'

  const searchProductsStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius,
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: borderColor,
      '&:hover': {
        border: borderColor,
      },
      ':before': {
        backgroundImage: `url(${icSearchSecondary})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 0,
        height: '41px',
        width: '41px',
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '20px',
      zIndex: 1049,
      border: '1px solid #E9E6FE',
      paddingTop: '20px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '14px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      padding: '4px 10px',
      position: 'relative',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
            top: '50%',
            transform: 'translateY(-50%)',
          }
        : {},
    }),
  }

  const onChange = (item) => {
    history.push(`/pro/programs-sheet-product/${item.value}`, {
      product_id: item.data.product_id,
      product_name: item.data.product_name,
      formula_name: item.data.formula_name,
      presentation: item.data.presentation,
      laboratory: item.data.laboratory_name,
      program_id: item.data.program_id,
      program_name: item.data.program_name,
      program_logo: item.data.program_logo,
      program_link: item.data.program_link,
      program_file: item.data.program_file,
      picture: item.data.picture,
      tag: item.data.product_tag,
    })
  }

  const onClickProgram = (item) => {
    logEvent('Program Information Checked', {
      'Type': 'Program',
      'Origin Material': 'Programs',
      'Program Id': item?.data?.id,
      'Program Name': item?.name,
      'Program Label': item?.program_tag,
    })
    history.push(`/pro/programs/detail/${item.name}`, {
      item: item,
      program_id: item.id,
      program_name: item.name,
      program_file: item.program_file,
      program_link: item.program_link,
      tag: item.product_tag,
    })
  }

  return (
    <>
      <div className="py-3">
        <div
          id={'gtm-medication-sheet-result'}
          className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center" href="#/">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span
              className="txt-4--regular-pr"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>
      </div>

      <div className="flex-grow-1">
        <div className="content-responsive">
          {appState.loading ? (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative mx-auto mt-5"
            />
          ) : (
            <>
              <p className="txt-2--bold mb-3">
                Comparte programas para pacientes y ayúdalos en sus tratamientos
                crónicos
              </p>
              <h2 className="txt-title font--18">Buscador de medicamentos</h2>

              <div className="">
                <AsyncSelect
                  className="search-product"
                  id="gtm-webapp-search-products-legacy"
                  isClearable
                  cacheOptions
                  onChange={onChange}
                  noOptionsMessage={() => 'Sin resultados...'}
                  placeholder="Busca tu producto"
                  formatGroupLabel={formatGroupLabel}
                  defaultOptions={appState.groupedOptions}
                  loadOptions={loadSuggestions}
                  styles={searchProductsStyle}
                />
              </div>

              <div className="py-4">
                <h2 className="txt-title mb-4 font--18">Lista de programas</h2>

                <div className="content-card-product">
                  {filteredPrograms.length > 0
                    ? filteredPrograms.map((item, key) => (
                        <CardInteracion
                          key={key}
                          id={`gtm-webapp-programs-result-${key}`}
                          doAction={() => onClickProgram(item)}>
                          <div className="content-img-medication-sheet">
                            <img
                              src={item?.logo}
                              alt={`nombre del producto`}
                              className="card-programs-img"
                            />
                          </div>
                          <div className="ml-14 overflow-hidden">
                            <h2 className="txt-title mb-2 pr-2 text-ellipsis--2 font--18">
                              {item?.name}{' '}
                            </h2>

                            <div
                              className={`${
                                item?.program_tag === 'Educación al paciente'
                                  ? 'chip-tag-green'
                                  : item?.program_tag === 'Descuento'
                                  ? 'chip-tag-blue'
                                  : null
                              }`}>
                              {item?.program_tag}
                            </div>
                          </div>
                        </CardInteracion>
                      ))
                    : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '1em',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

export default Programs
