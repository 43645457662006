import React from 'react'
import Select, {components} from 'react-select'

function SelectPaymentMethod(props) {
  const CustomSelectStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      height: '40px',
      zIndex: 99,
      boxShadow: '0 rgba(0,0,0,0)',
      border: '1px solid #DDE0EC;',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      },
      display: 'flex'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      width: '100%'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A9ABBB',
      paddingLeft: '1rem',
      fontFamily: 'var(--font-bold)',
      fontSize: 'var(--font-12)',
      lineHeight: 'var(--font-16)',
      color: 'var(--color-gray1-ds)',
      margin: 0
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
      position: 'unset',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'block',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '31px',
      zIndex: 99,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
      padding: 0,
      overflowY: 'auto',
      height: '10.7rem',
      borderRadius: '0.5rem',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(77, 186, 182, 0.2)' : null,
      borderBottom: '1px solid #DDE0EC',
      color: '#282E55',
      padding: 0,
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(77, 186, 182, 0.2)',
        fontSize: '12px',
      },
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="box-arrow-select">
            <i className="icon-chevron-down color-lilac" />
          </div>
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <Select
      components={{DropdownIndicator}}
      isDisabled={props.options ? false : true}
      value={props.value}
      options={props.options}
      styles={CustomSelectStyle}
      placeholder={props.placeholder}
      onChange={props.onChange}
      isSearchable={false}
      className={props.className}
    />
  )
}

export default SelectPaymentMethod
