import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';

import FailtValidate from '../../assets/img/alert.svg'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import { _validateMail } from '../../Utils/tools';
import { logEvent } from '../../Utils/utils';
import Input from '../Forms/Input';

function ModalFailValidate(props) {
  const { modalFailValidateState, setModalFailValidateState, retry, sendEmail } = props
  const [email, setEmail] = useState('')
  const [stage, setStage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)

  function onChangeEmail(email) {
    setEmail(email)
    if (_validateMail(email)) setIsValid(email)
  }

  async function handleSendEmail() {
    setLoading(true)
    if (_validateMail(email)) {
      let value = await sendEmail(email)
      value ? setStage(3) : setStage(4)

      logEvent('Email Validated', {
        'Sent': value ? 'True' : 'False'
      })
    }
    onChangeEmail(email)
    setLoading(false)
  }

  function closeModalFailValidate() {
    setStage(1)
    setEmail('')
    setLoading(false)
    setModalFailValidateState(false)
  }

  return (
    <Modal
      isOpen={modalFailValidateState}
      onAfterOpen={null}
      onRequestClose={closeModalFailValidate}
      className='modal-custom'
      overlayClassName="modal-custom-bg"
      contentLabel="ModalTypeFormsHome"
      ariaHideApp={false}
    >
      <>
        <button className='btn-circle-close' onClick={() => closeModalFailValidate()}><i className='icon-close'></i></button>
        {(stage === 1) &&
          <div>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-2'>
              <img id={"gtm-webapp-validation-failed-img"} src={FailtValidate} alt='' className='img-fail-validate mb-4' />
              <h2 className='title-primary mb-4 line-16 mb-3 text-center'>Ha ocurrido un error al intentar validarte, por favor vuelve a intentarlo</h2>
            </div>
            <button id={"gtm-webapp-validation-failed-retry-btn"} className='btn-default--small font--14 btn-next mx-auto mb-3' onClick={() => retry()}>Reintentar</button>
            <button id={"gtm-webapp-validation-failed-email-validation-btn"} className='btn-default--small btn-stroke font--14 btn-next mx-auto' onClick={() => setStage(2)}>Validarme por correo</button>
          </div>
        }
        {(stage === 2) &&
          <div>
            {loading ?
              <div className='w-100 d-flex flex-column justify-content-center align-items-center mt-3 mb-4'>
                <img src={loadingYapp} alt='loading' className='loading-yapp--normal m-0' />
              </div>
              :
              <div>
                <div className='w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3'>
                  <h2 className='title-primary mb-2'>Validarme por correo</h2>
                  <p className='txt-paragraph--light line-14 text-center'>Indícanos tu correo para intentar validarte como médico con nuestro equipo de soporte.</p>
                  <div className='mb-2 w-100 mt-3 mb-4'>
                    <Input
                      id='gtm-webapp-validation-email-input'
                      error={(email.length > 0 && !isValid) && { message: '*Correo inválido' }}
                      label='Correo electrónico'
                      placeholder='Correo electrónico'
                      doChange={value => onChangeEmail(value)} />
                  </div>
                </div>
                <button id={"gtm-webapp-validation-email-btn"} className='btn-default--small font--14 btn-next mx-auto' onClick={handleSendEmail}>Enviar</button>
              </div>
            }
          </div>
        }
        {stage === 3 &&
          <div>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3'>
              <span className='modal-icon-send-mail mb-4 d-flex'><i id={"gtm-webapp-validation-sent-icon"} className='icon-send-2'></i></span>
              <h4 className='txt-paragraph--light line-14 mb-4 text-center'>Te hemos enviado las instrucciones para validarte como médico desde soportemedicos@yapp.cl</h4>
            </div>
            <button id={"gtm-webapp-validation-sent-btn"} className='btn-default--small font--14 btn-next mx-auto' onClick={() => { closeModalFailValidate() }}>¡Genial!</button>
          </div>
        }
        {stage === 4 &&
          <div>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3'>
              <span className='modal-icon-send-mail mb-4 d-flex'><i id={"gtm-webapp-validation-sent-error-icon"} className='icon-send-2'></i></span>
              <h4 className='txt-paragraph--light line-14 mb-4 text-center'>Lo sentimos! Se ha alcanzado el límite de correos permitidos. Inténtalo más tarde</h4>
            </div>
            <button id={"gtm-webapp-validation-sent-error-btn"} className='btn-default--small font--14 btn-next mx-auto' onClick={() => { closeModalFailValidate() }}>Cerrar</button>
          </div>
        }
      </>
    </Modal>
  )
}

export default ModalFailValidate
