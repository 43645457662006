import {useDispatch, useSelector} from 'react-redux'
import {connect} from 'react-redux'
import {useEffect, useState, useRef} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import Modal from 'react-modal'
import ReactTooltip from 'react-tooltip'
import {Application, DatePicker} from 'react-rainbow-components'
import DefaultCard from '../../../assets/img/default-card.png'
import American from '../../../assets/img/american-logo.png'
import Diners from '../../../assets/img/diners-logo.png'
import Magna from '../../../assets/img/magna-logo.png'
import Mastercard from '../../../assets/img/mastercard-logo.png'
import Redcompra from '../../../assets/img/redcompra-logo.png'
import Visa from '../../../assets/img/visa-logo.png'
import ModalUploadHome from '../../../Components/Modals/ModalUploadHome'
import ModalSelectPaymentMethod from '../../../Components/Modals/ModalSelectPaymentMethod'
import Location from '../../../Components/Location'
import Toasts from '../../../Components/Toasts/Toasts'
import SimpleSelect from '../../../Components/Forms/SimpleSelect'
import ModalBottomSimple from '../../../Components/Modals/ModalBottomSimple'
import ModalError from '../../../Components/Modals/ModalError'
import ModalBeneficiaries from '../../../Components/Modals/ModalBeneficiaries'

import dots from '../../../assets/img/dots.png'

import Stepper from '../../../Components/Stepper'
import * as Sentry from '@sentry/react'
import {fetchUser, setCopayUserData, setInsurance, setUserRefund} from '../../../redux/modules/user'

import {
  logEvent,
  addEventNameWithParams,
  encrypt,
  normalizeRut,
  decrypt,
} from '../../../Utils/utils'
import {
  acceptedFileTypes,
  deliveryFrequencies,
  subscriptionTypes,
  toastConfig,
  base_url,
} from '../../../Utils/constants'
import {Viewer, Worker, SpecialZoomLevel} from '@react-pdf-viewer/core'
import loadingYapp from '../../../assets/img/yapp_loading.gif'
import {
  addCreditCard,
  getCreditCard,
  getCreditCardResult,
  getSubscriptionDiscountByCode,
  createSubscription,
  sendToPay,
  uploadFile,
  getInsuranceLiquidation,
  getBeneficiaries,
  existUserClient
} from '../../../Utils/api'
import {
  setGoRouter,
  setLocation,
  changeLogo,
  showLogo,
  setThemeColor,
  setFormData,
  setPrescriptions,
  setApplicationNumber,
  setEnabledInsurance,
  setProvider,
} from '../../../redux/modules/app'
import {
  getShoppingCart,
  createPreOrder,
  saveShoppingCart,
  getComplementaryCart,
  removeShoppingCart,
} from '../../../Utils/service'
import {getKeyObject} from '../../../Utils/storage'
import {
  getJWTdata,
  _formatPrice,
  _formatRut,
  getHash,
  _validateRut,
  _validateMail,
  _validPhone,
  formatDate,
  encode64,
  getLocationTool,
  _getDv,
  _capitalize,
  base64MimeType,
  _isWeekEnd,
  _handleRegisterValidation,
} from '../../../Utils/tools'

import ModalUploader from '../../../Components/Modal.Uploader'
import ProductPrescription from '../../../Components/ShoppingBag/ProductPrescription'
import ProductSummary from '../../../Components/ShoppingBag/ProductSummary'
import ProductSupplementary from '../../../Components/ShoppingBag/ProductSupplementary'
import ModalLegal from '../../../Components/ShoppingBag/ModalLegal'
import LoadingCheckout from '../../../Components/ShoppingBag/LoadingCheckout'

import icErrorPay from '../../../assets/img/error_pay.svg'

import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import Input from '../../../Components/Forms/Input'
import InputButtonDiscount from '../../../Components/Forms/InputButtonDiscount'
import InputButton from '../../../Components/Forms/InputButton'
import SelectPaymentMethod from '../../../Components/Forms/SelectPaymentMethod'
import SelectInsurance from '../../../Components/Forms/SelectInsurance'
import LoadingSura from '../../../Components/Loading/LoadingSura'
import LoadingScreen from 'react-loading-screen'

import {setContext} from '@sentry/react'
import LoadingCreateAccount from '../../../Components/Loading/LoadingCreateAccount'
import LoadingLoginAccount from '../../../Components/Loading/LoadingLoginAccount'
import ModalLoginCheckout from '../../../Components/Modals/ModalLoginCheckout'
import { getUserInfo, registerPassword, registerUser } from '../../../Utils/auth'
import TypeSidetab from '../../../Components/TypeForm/TypeSidetab'

const _ = require('lodash')
const moment = require('moment-timezone')
const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes';
const isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE';

function NewShoppingBagCheckout(props) {
  const modalRef = useRef()
  const inputRef = useRef(null)
  const modalSupplement = useRef(null)
  const modalTerms = useRef(null)
  const modalPrivacy = useRef(null)
  const modalInsurance = useRef(null)
  const modalHomeRef = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = new URLSearchParams(location.search)
  const paymentSubscriptionId = params.get('subscriptionId')

  const [step, setStep] = useState(1)
  const [showModalLogin, setShowModalLogin] = useState({show: false, title: 'Inicia tu sesion'})
  const [showLoadingCreateAccount, setShowloadingCreateAccount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [policies, setPolicies] = useState(false)
  const [loginData, setLoginData] = useState({show: true})
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [rut, setRut] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formIsValid, setFormIsValid] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const [hasComplementary, setHasComplementary] = useState(false)
  const [complementaryDelivery, setComplementaryDelivery] = useState(null)
  const [products, setProducts] = useState([])
  const [productsComplementary, setProductComplementary] = useState([])
  const [creditCards, setCreditCards] = useState([])

  const [totalCart, setTotalCart] = useState(0)
  const [subTotalCart, setSubTotalCart] = useState(0)
  const [totalComplementaryCart, setTotalComplementaryCart] = useState(0)
  const [delivery, setDelivery] = useState({cost: 0})
  const [deliveryDiscount, setDeliveryDiscount] = useState(null)
  const [date, setDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [modalDocument, setModalDocument] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [openModalBeneficiaries, setOpenModalBeneficiaries] = useState(false)
  const [benefit, setBenefit] = useState(null)

  const [otherDocuments, setOtherDocuments] = useState([])
  const userInfo = useSelector((state) => state.user.userInfo)
  const prescriptionProduct = useSelector(
    (state) => state.app.prescriptions || [],
  )
  const [purchase, setPurchase] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [discountCode, setDiscountCode] = useState(null)
  const [accountError, setAccountError] = useState(false)
  const [clientDiscountBenefits, setClientDiscountBenefits] = useState(0)
  const [chainBusinessName, setChainBusinessName] = useState('la farmacia')
  const [chainIdentityNumber, setChainIdentityNumber] = useState(null);

  const [insurancePolicyLimit, setInsurancePolicyLimit] = useState(false)
  const [insuranceType, setInsuranceType] = useState(null)
  const [insuranceError, setInsuranceError] = useState(false)
  const [applyDeductible, setApplyDeductible] = useState(false)
  const [refund, setRefund] = useState({})
  const [deductible, setDeductible] = useState(0)
  const [pressedButton, setPressedButton] = useState(false)

  const [formError, setFormError] = useState({})
  const [errorPrescription, setErrorPrescription] = useState(false)
  const [errorPhoneEmpty, setErrorPhoneEmpty] = useState(false)
  const [errorPhoneFormat, setErrorPhoneFormat] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorCheckTerms, setErrorCheckTerms] = useState(false)

  const [sending, setSending] = useState(false)
  const [modalErrorPayIsOpen, setModalErrorPayIsOpen] = useState(false)
  const [stateToasts, setStateToasts] = useState(false)
  const [toastData, setToastData] = useState({})
  const [invalidDate, setInvalidDate] = useState(null)
  const [invalidFrequency, setInvalidFrequency] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState([])

  const [isSubscription, setIsSubscription] = useState(false)
  const [frequency, setFrequency] = useState(null)
  const [showModalPaymentMethod, setShowModalPaymentMethod] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingConfirmBuy, setLoadingConfirmBuy] = useState(null)
  const [loadingGlobal, setLoadingGlobal] = useState(false)

  const [item, setItem] = useState(null)
  const [errorCode, setErrorCode] = useState(null)

  const [showPdf, setShowPdf] = useState(false)
  const [pdfToShow, setPdf] = useState('')

  const [defaultCodeForDiscount, setDefaultCodeForDiscount] = useState('')

  const [showModalBottomSimple, setShowModalBottomSimple] = useState(false)
  const [disabledButtonPay, setDisabledButtonPay] = useState(false)

  const locationStorage = useSelector((state) => state.app.location)
  const userStorage = useSelector((state) => state.user)
  const [updatedPrescription, setUpdatedPrescription] = useState([])
  const textExtraPrescription = useSelector(
    (state) => state.app.text_extra_prescription,
  )

  const deliveryCode = useSelector((state) => state?.delivery?.delivery_code)

  const refundElement = useSelector((state) => state.app.refund_element)
  const textSubscriptionCheckout = useSelector(
    (state) => state.app.text_subscription_checkout,
  )
  const loadingCheckout = useSelector((state) => state.app.login_checkout_loading)

  const [loadingInsurance, setLoadingInsurance] = useState(false)
  const [showInsurance, setShowInsurance] = useState(
    process.env.REACT_APP_ENABLED_INSURANCE === 'TRUE',
  )
  const [titleInsurance] = useState(
    isInsurance
      ? 'Datos del paciente'
      : 'Datos del Seguro complementario',
  )
  const [insuranceDiscountId] = useState(
    ![null,undefined,''].includes(props?.insurance?.insurance_discount_id)
      ? props.insurance.insurance_discount_id
      : null
  )

  const [isStyleLight, setIsStyleLight] = useState(
    process.env.REACT_APP_BORDER === 'LIGHT',
  )

  useEffect(async () => {
    try {
      if (clientName === 'UC Christus') {
        let router = []
        router.push(
          {
            go: 'https://www.ucchristus.cl/yapp',
            target: '_blank',
            description: 'Red de Salud UC CHRISTUS',
          },
          {
            go: '/search',
            from: '',
            description: 'Buscador de medicamentos',
          },
          {
            go: '/shopping_bag/quotation',
            title: 'Checkout',
            description: 'Cotización de medicamentos',
          },
        )
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) {}
    if (
      userInfo?.profile?.uid &&
      paymentSubscriptionId &&
      userInfo?.signInMethod !== 'anonymously'
    ) {
      let creditCardSubscription = await getCreditCardResult(
        paymentSubscriptionId,
      )
      if (creditCardSubscription?.status === 'success') {
        logEvent('Payment Method Added', {
          Added: 'True',
        })
        setToast(true, toastConfig.newCardSuccess)
        getPaymentMethod()
      } else {
        logEvent('Payment Method Added', {
          Added: 'False',
        })
        console.error('Credit card subscription error')
      }
    }
    setLoading(false)
  }, [window.location.href])

  useEffect(() => {
    if (props.formData) {
      setDisabledButtonPay(
        [undefined, null, ''].includes(props.formData.location_name.value) ||
          [undefined, null, ''].includes(
            props.formData.location_number.value,
          ) ||
          [undefined, null, ''].includes(props.formData.full_name.value) ||
          [undefined, null, ''].includes(props.formData.phone.value) ||
          !_validateRut(props.formData.rut.value) ||
          !_validateMail(props.formData.email.value) ||
          isUserYapp
          ? false
          : !props.formData.checkTerms.value,
      )
    }
  }, [props.formData])

  useEffect(() => {
    if (props.disabledThemeCheckout === true) {
      dispatch(setApplicationNumber(null))
      dispatch(changeLogo(null))
      dispatch(showLogo(false))
      dispatch(
        setThemeColor({
          primary: '#6578FF',
          secondary: '#4DBAB6',
        }),
      )
    }

    if (isInsurance) {
      let clientId = process.env.REACT_APP_CLIENT_ID
      let titularIdentity = getFieldFromInsuranceData('identity_titular');
      let insurance = titularIdentity !== null && insuranceType == null
        ? 'Group'
        : deliveryCode
          ? 'General'
          : null
      
      setLocalInsurance(clientId)
      dispatch(
        setInsurance(props?.insuranceList?.filter((insurance) => insurance.id === clientId)[0]),
      )

      setInsuranceType(insurance)
      if (!insuranceType) {
        dispatch(setEnabledInsurance(true))
      }
    }
  }, [])

  useEffect(async () => {
    if (userInfo?.profile?.uid && userInfo?.signInMethod !== 'anonymously') {
      getPaymentMethod()
    }
  }, [userInfo])

  useEffect(async () => {
    if (pressedButton) {
      onClickRefund(false)
    }
  }, [updatedPrescription])

  useEffect(() => {
    props.setFormData({
      ...props.formData,
      location_name: {
        ...props.formData.location_name,
        value: props.location?.label,
      },
    })
  }, [props.location])

  useEffect(() => {
    let localData = getKeyObject('LOCAL_DATA', 'client_config')
    let localDataSite = getKeyObject('LOCAL_DATA', 'site')

    let userData = sessionStorage.getItem('USER_DATA')

    setDeliveryTime(_.get(localData, 'delivery_time', null))

    userData = JSON.parse(userData)
    let email = _.get(
      localDataSite,
      'info.email',
      _validateMail(userData?.email)
        ? userData?.email
        : _validateMail(userStorage?.email)
        ? userStorage?.email
        : '',
    )

    props.setFormData({
      ...props.formData,
      location_name: {
        ...props.formData.location_name,
        value: ![undefined, null, ''].includes(
          props.formData.location_name.value,
        )
          ? props.formData.location_name.value
          : locationStorage?.label,
      },
      location_number: {
        ...props.formData.location_number,
        value: ![undefined, null, ''].includes(
          props.formData.location_number.value,
        )
          ? props.formData.location_number.value
          : locationStorage?.nro,
      },
      email: {
        ...props.formData.email,
        value: ![undefined, null, ''].includes(props.formData.email.value)
          ? props.formData.email.value
          : email,
      },
      full_name: {
        ...props.formData.full_name,
        value: ![undefined, null, ''].includes(props.formData.full_name.value)
          ? props.formData.full_name.value
          : userData?.name,
      },
      rut: {
        ...props.formData.rut,
        value: ![undefined, null, ''].includes(props.formData.rut.value)
          ? props.formData.rut.value
          : _formatRut(userData?.identity),
      },
      phone: {
        ...props.formData.phone,
        value: ![undefined, null, ''].includes(props.formData.phone.value)
          ? props.formData.phone.value
          : _validPhone(userData?.phone)
          ? userData?.phone
          : '',
      },
      discount: ![undefined, null, ''].includes(props.formData.discount)
        ? props.formData.discount
        : null,
      address_additional: {
        ...props.formData.address_additional,
        value: ![undefined, null, ''].includes(
          props.formData.address_additional.value,
        )
          ? props.formData.address_additional.value
          : [undefined, null, ''].includes(userData?.address_additional)
          ? ''
          : ' ' + userData.address_additional,
      },
      checkTerms: {
        ...props.formData.checkTerms,
        valid: props.formData.checkTerms.value,
      },
    })
  }, [locationStorage])

  // Load user data.
  useEffect(async () => {
    let userData = sessionStorage.getItem('USER_DATA')

    if (userData) {
      userData = JSON.parse(userData)

      if (userData?.address) {
        let location = await getLocationTool(userData?.address)
        let communeFilter = props.communes?.filter(
          (el) => el.name === location.locality,
        )
        if (communeFilter?.length > 0) {
          dispatch(setLocation({...location, commune_id: communeFilter[0].id}))
        }
      }
    }
  }, [])

  useEffect(async () => {
    let item = localStorage.getItem('selectedQuotationItem')
  
    if (item) {
      item = JSON.parse(item)
      setItem(item)
      
      if (item.default_code?.length) {
        setDefaultCodeForDiscount(item.default_code)
      }
  
      let remoteBag = await getShoppingCart()
      if (remoteBag) {
        let quotationDetail = item?.quotation_detail
        let loadIsSubscription = item?.is_subscription ? true : false
  
        handleDeliveryDate( item?.delivery?.next_date)
        setIsSubscription(loadIsSubscription)
        if (showInsurance) {
          setShowInsurance(!loadIsSubscription)
        }
  
        let quotationDetailProducts = getQuotationDetailProducts(quotationDetail, remoteBag)
        let deliveryValue = setDeliveryValue(item?.delivery)
        let discountBenefits = calculateProductDiscountBenefits(quotationDetail, item)
        let total = quotationDetailProducts.subTotal - discountBenefits + deliveryValue
  
        setDiscountData(loadIsSubscription, quotationDetailProducts.products)
        setSubTotalCart(quotationDetailProducts.subTotal)
        setTotalCart(total)

        let dataComplementary = await getComplementaryCart()
        if (dataComplementary)
          setComplementaryCartValues(dataComplementary)
      } 
      else {
        console.log('Error al obtener la bolsa remota.')
      }
    } else {
      console.log('Error al recibir la cotización seleccionada.')
    }
    dispatch(setLocation(null))
  }, [])

  useEffect(() => {
    if (otherDocuments.length > 0) {
      clickGenerateLink()
    }

  }, [otherDocuments])

  useEffect(() => {
    if (userInfo && userInfo.signInMethod !== 'anonymously') {
      setStep(2)
      props.setFormData({
        ...props.formData,
        email: {
          value: props.userInfo?.userInfo.profile.email,
          valid: _validateMail(props.userInfo?.userInfo.profile.email),
        },
        full_name: {
          value: props.userInfo?.userInfo.profile.name ? props.userInfo?.userInfo.profile.name : props.userInfo?.userInfo?.profile?.first_name + ' ' + props.userInfo?.userInfo?.profile?.last_name,
          valid: props.userInfo?.userInfo.profile.name || props.userInfo?.userInfo?.profile?.first_name ? true : false
        },
        rut: {
          value: props.userInfo?.userInfo.profile.rut ? props.userInfo?.userInfo.profile.rut : '',
          valid: _validateRut(props.userInfo?.userInfo.profile.rut)
        }
      })
      setLoginData({
        ...loginData,
        full_name: props.userInfo?.userInfo.profile.name ? props.userInfo?.userInfo.profile.name : props.userInfo?.userInfo?.profile?.first_name + ' ' + props.userInfo?.userInfo?.profile?.last_name,
        email: props.userInfo?.userInfo.profile.email,
        rut: props.userInfo?.userInfo.profile.rut ? props.userInfo?.userInfo.profile.rut : ''
      })
    } else if (clientName === 'Sura') {
      setStep(2)
    } else {
      setStep(1)
    }
  }, [userInfo])

  useEffect(() => {
    let result = _handleRegisterValidation(
      name,
      lastname,
      email,
      password,
      passwordConfirmation,
      policies,
      userInfo,
    )
    setFormIsValid(result.formIsValid)
  }, [name, lastname, email, password, passwordConfirmation, policies,userInfo])

  useEffect(() => {
    setIsMobile(window.screen.width < 380)
  }, [window]) 

  useEffect(() => {
    getUserInfo(userInfo.profile.uid)
      .then(resp => {
        if (resp?.rut) {
          setRut(resp.rut)
        }
      })
  }, [userInfo])

  const setCounter = (index, quantity) => {
    let newProducts = [...products]
    newProducts[index].counter = quantity

    setProducts(newProducts)
    setTotalCart(newProducts[index].price * quantity)
  }

  /*
  async function updateProducts(quotation, remoteBag) {
    let productToUpdate = quotation.filter((el) => el?.product_id_old)
    for (const key in productToUpdate) {
      if (Object.hasOwnProperty.call(productToUpdate, key)) {
        const element = productToUpdate[key]
        // Update remoteBag
        let idx = _.findIndex(remoteBag, {product_id: element.product_id_old})
        if (idx !== -1) {
          remoteBag[idx] = {
            ...remoteBag[idx],
            ...{
              counter: element.quantity,
              formula_name: element?.product.formula_name,
              product_id: element?.product.product_id,
              product_name: element?.product.name,
              product_logo: element?.product.logo,
            },
          }
        }
      }

      await saveShoppingCart(remoteBag)
    }
  }
  */

  const handleDeliveryDate = (locationNextDate) => {
    let day = moment.utc(locationNextDate).date()
    let month = moment.utc(locationNextDate).month()
    let year = moment.utc(locationNextDate).year()
    let minDeliveryDate = new Date(year, month, day)
    let today = new Date()

    minDeliveryDate = minDeliveryDate.getTime() < today.getTime() ? today : minDeliveryDate
    setMinDate(minDeliveryDate)
    setDate(minDeliveryDate)
  }

  function goBack() {
    step === 3 
      ? setStep(2)
      : history.push('/shopping_bag/quotation')
  }

  function changeValue(value, key) {
    let valid = value !== ''

    if (['rut', 'titular_identity'].includes(key)) {
      value = _formatRut(value)
      valid = _validateRut(value)
    }

    if (key === 'phone') {
      if (value.trim() !== '' && isNaN(value)) {
        valid = false
      }
    }

    if (key === 'email') {
      if (props.formData.phone.value.length === 0 && !errorPhoneEmpty) {
        setErrorPhoneEmpty(true)
      }
      valid = _validateMail(value)
    }

    if (key === 'full_name') {
      if (value?.trim() !== '' && isNaN(value)) {
        valid = false
      }
    }

    setFormError({...formError, [key]: true})
    props.setFormData({
      ...props.formData,
      [key]: {...props.formData[key], value: value, valid},
    })
  }

  function onClickLoadPrescription(productId, products) {
    logEvent('Prescription Opened')
    modalRef.current.showAlert({productId, products, prescriptionProduct})
  }

  /*

  async function checkInsuranceUser(identity) {
    try {
      let response = await getBeneficiaries(props?.formData?.rut?.value, props.insurance?.id)

      if (response?.data?.listBeneficiaries?.length > 0) {
        let userData = response.data.listBeneficiaries.find((beneficiary) =>
          _formatRut(beneficiary.identity) === identity.toUpperCase()
        )
        userData && onClickBenefit(userData)
      }
    }
    catch (err) {
      console.log('Failed to get insurance user')
    }
  }

  */

  async function setPrescriptionProduct(event) {
    let tmp = prescriptionProduct
    setErrorPrescription(false)
    if (Array.isArray(tmp)) {
      logEvent('Prescription Added', {
        Quantity: event?.productsChecked?.length,
      })
      event.productsChecked.map((productId) => {
        if (!tmp.some((el) => el.product_id === productId)) {
          tmp.push({
            product_id: productId,
            image: event.images[0],
            b64: event.images,
          })
        }

        dispatch(setPrescriptions([...tmp]))
        setUpdatedPrescription([...tmp])
      })
      setToast(true, toastConfig.prescriptionSuccess)
    }
  }

  function getStatusform() {
    setErrorCheckTerms(!props.formData.checkTerms.value)
    return {
      ...props.formData,
      location_name: {
        ...props.formData.location_name,
        valid: ![undefined, null, ''].includes(
          props.formData.location_name.value,
        ),
      },
      location_number: {
        ...props.formData.location_number,
        valid: ![undefined, null, ''].includes(
          props.formData.location_number.value,
        ),
      },
      address_additional: {...props.formData.address_additional, valid: true},
      full_name: {
        ...props.formData.full_name,
        valid: ![undefined, null, ''].includes(props.formData.full_name.value),
      },
      rut: {
        ...props.formData.rut,
        valid: _validateRut(props.formData.rut.value),
      },
      email: {
        ...props.formData.email,
        valid: _validateMail(props.formData.email.value),
      },
      checkTerms: {
        ...props.formData.checkTerms,
        valid: props.formData.checkTerms.value,
      },
      discount: {...props.formData.discount, valid: true},
    }
  }

  function addProducts() {
    history.replace('/search/shopping_bag/000000')
  }

  function needsPrescription(item, refund = false) {
    let itemNeedsPrescription = false
    if ((![null, undefined].includes(item?.product?.client_product_id) && refund) ||
      (item?.product?.prescription_id > 0)) {
      itemNeedsPrescription = true
    }
    return itemNeedsPrescription
  }

  async function uploadPrescription() {
    let jwtData = getJWTdata()

    let prescriptions = prescriptionProduct.filter((p) =>
      products.find(
        (el) =>
          p?.product_id == el?.product?.product_id ||
          p?.product_id == el?.product?.client_product_id,
      ),
    )
    if (jwtData) {
      let uploadResult = []

      let tmp =
        props.documents_pending === true && otherDocuments.length > 0
          ? prescriptions.concat(otherDocuments)
          : prescriptions

      let prepareUpload = await Promise.all(
        tmp
          .filter((c) => !c?.external)
          .map(async (el) => {
            let mime = base64MimeType(el?.image)
            let image = await fetch(el?.image).then((res) => res.blob())

            let fileType = acceptedFileTypes.includes(mime?.type?.split('/')[1])
              ? mime?.type?.split('/')[1]
              : null
            let fileName =
              encode64(
                new Date().toISOString() + (mime?.name ? mime.name : ''),
              ) +
              '.' +
              fileType

            return {
              file_name: fileName,
              product_id: el.product_id,
              image: image,
            }
          }),
      )

      uploadResult = tmp.filter((c) => c?.external).map((el) => el)

      for (const key in prepareUpload) {
        if (Object.hasOwnProperty.call(prepareUpload, key)) {
          const item = prepareUpload[key]

          let resp = null
          let existUploaded = uploadResult.some(
            (el) => el.file_name === item.file_name,
          )
          if (!existUploaded) {
            try {
              let formData = new FormData()
              formData.append('bucket', 's3.yapp')
              formData.append(
                'filepath',
                'img/prescription/webapp' + item.file_name,
              )

              formData.append('file', item.image, item.file_name)
              resp = await uploadFile(formData)
            } catch (error) {
              console.log(error)
            }
          } else {
            resp = {
              location: uploadResult.find(
                (el) => el.file_name === item.file_name,
              )?.url,
            }
          }

          if (resp?.location) {
            uploadResult.push({
              product_id: item.product_id,
              file_name: item.file_name,
              url: resp?.location,
            })
          }
        }
      }

      return uploadResult
    } else {
      console.log('Error JWT Data.')
      return false
    }
  }

  function setComplementaryCartValues(payload) {
    setProductComplementary(payload)
    let totalComplementary = 0
    for (const key in payload) {
      if (Object.hasOwnProperty.call(payload, key)) {
        const element = payload[key]
        totalComplementary =
          totalComplementary + element.price * element.quantity
      }
    }

    setTotalComplementaryCart(totalComplementary)
  }

  async function addPaymentMethod() {
    logEvent('Payment Method Checked')

    setLoading(true)
    let currentUrl = window.location.href
    if (currentUrl.includes('subscriptionId=')) {
      let subscriptionIndex = currentUrl.lastIndexOf('subscriptionId=')
      currentUrl = currentUrl.substr(0, subscriptionIndex)
    }

    let userData = {
      email: userInfo.profile.email,
      userId: userInfo.profile.uid,
      callbackUrl: window.location.href,
    }

    let addPaymentMethodUrl = await addCreditCard(userData)
    if (addPaymentMethodUrl?.data) {
      logEvent('Payment Method Redirected', {
        Redirected: 'True',
      })

      window.location.href = addPaymentMethodUrl.data
    } else {
      logEvent('Payment Method Redirected', {
        Redirected: 'False',
      })
      console.error('Payment method redirection url error')
    }
  }

  function setPaymentLogo(cardType) {
    let logo = DefaultCard
    switch (cardType.toLowerCase()) {
      case 'visa':
        logo = Visa
        break
      case 'master':
        logo = Mastercard
        break
      case 'dinner':
        logo = Diners
        break
      case 'magna':
        logo = Magna
        break
      case 'amex':
        logo = American
        break
      case 'redcompra':
        logo = Redcompra
        break
      default:
        break
    }
    return logo
  }

  function getFieldFromInsuranceData(field) {
    let result = null

    try {
      let decryptedData = JSON.parse(decrypt(props.userData));
      result = decryptedData[0][field];
    }
    catch (error) {
      console.error('Failed to get insurance data')
    }
    return result
  }

  function setPaymentMethod(selectedCardId) {
    let creditCardList = []
    for (let card of creditCards) {
      card.used = card.id === selectedCardId ? 1 : 0
      creditCardList.push(card)
      card.used && setSelectedPaymentMethod(card)
    }
    setCreditCards(creditCardList)
  }

  function setLocalInsurance(insuranceId) {
    let insuranceFilter = props?.insuranceList?.filter(
      (insurance) => insurance.id === insuranceId,
    )
    if (insuranceFilter?.length > 0) {
      if (insuranceFilter[0].id === null) {
        dispatch(setEnabledInsurance(false))
      }
      dispatch(setInsurance(insuranceFilter[0]))
    }
  }

  async function getPaymentMethod() {
    let creditCardList = await getCreditCard(userInfo.profile.uid)
    let selectedCard = null

    if (creditCardList?.data?.length > 0) {
      for (let card of creditCardList.data) {
        card.logo = setPaymentLogo(card.type)
        if (card.default === 1) {
          selectedCard = card
          card.used = 1
        } else {
          card.used = 0
        }
      }

      if (!selectedCard) {
        selectedCard = creditCardList.data[0]
        creditCardList.data[0].used = 1
      }

      setCreditCards(creditCardList.data)
      setSelectedPaymentMethod(selectedCard)
    }
  }

  function showInsuranceOptions() {
    let options = null

    if (props?.insuranceList?.length > 0) {
      options = props?.insuranceList.map((insurance, i) => ({
        label: (
          <div className={'d-flex align-items-center ml-4'} key={i.toString()}>
            <div className="d-flex justify-content-start align-items-start w-50 my-2">
              {insurance.logo ? (
                <img src={insurance.logo} alt={'Logo ' + insurance.name} />
              ) : (
                <p className="txt-5--regular-db text-right mt-2 mb-2">
                  {insurance.name}
                </p>
              )}
            </div>
          </div>
        ),
        value: insurance.id,
      }))
    }
    return options
  }

  function showPaymentOptions() {
    let options = null
    if (creditCards?.length > 0) {
      options = creditCards.map((card, i) => ({
        label: (
          <div
            className={
              'd-flex align-items-center ml-4' +
              (card.used ? ' payment-method-active' : '')
            }
            style={{width: '90px'}}
            key={i.toString()}>
            <div className="d-flex justify-content-center align-items-center w-75">
              <img
                className="payment-logo"
                src={card.logo}
                alt="Logo método de pago"
              />
            </div>
            <p className="txt-5--regular-db text-right">{card.last_four}</p>
          </div>
        ),
        value: card.id,
      }))
    }
    return options
  }

  function showDefaultPaymentOption() {
    let option = null
    if (selectedPaymentMethod) {
      option = {
        label: (
          <div
            className={'d-flex align-items-center ml-4'}
            style={{width: '90px'}}
            key={0}>
            <div className="d-flex justify-content-center align-items-center w-75">
              <img
                className="payment-logo"
                src={selectedPaymentMethod.logo}
                alt="Logo método de pago"
              />
            </div>
            <p className="txt-5--regular-db text-right">
              {selectedPaymentMethod.last_four}
            </p>
          </div>
        ),
        value: selectedPaymentMethod.id,
      }
    }

    return option
  }

  const checkDeliveryDate = (newDate) => {
    if (_isWeekEnd(newDate)) {
      setToast(true, toastConfig.weekendDateError)
    } else if (props.holidayList?.some((holiday) => holiday === formatDate(newDate))) {
      setToast(true, toastConfig.holidayDateError)
    } else {
      setDate(newDate)
    }
  }

  function showDefaultInsuranceOption() {
    let option = null
    if (props?.insurance) {
      option = {
        label: (
          <div
            className={'d-flex align-items-center ml-4'}
            key={props?.insurance?.id?.toString()}>
            <div className="d-flex justify-content-start align-items-start w-50">
              {props?.insurance.logo ? (
                <img
                  src={props?.insurance.logo}
                  alt={'Logo ' + props?.insurance.name}
                />
              ) : (
                <p className="txt-5--regular-db text-right mt-2 mb-2">
                  {props?.insurance.name}
                </p>
              )}
            </div>
          </div>
        ),
        value: props?.insurance.id,
      }
    }
    return option
  }

  function nextAction() {
    let hasName = props.formData.full_name.value
    let hasRut = (![undefined, null, '-'].includes(props.formData.rut.value)) && props.formData.rut.valid

    if (!validatePrescription() || !hasRut || (isInsurance && !hasName)) {
      setLoadingGlobal(false)
      setFormError({
        ...formError, 
        rut: false, 
        full_name: false 
      })
    }
    else {
      setStep(3)
      logEvent('Checkout Continued')
    }
  }

  async function clickNextAction() {
    if (isSubscription) {
      if ([undefined, null, ''].includes(frequency)) {
        setInvalidFrequency(true)
        return
      } else {
        setInvalidFrequency(false)
      }
    }
    setLoadingGlobal(true)

    if (
      Object.values(getStatusform()).some((el) => el.valid === false) ||
      !validatePrescription() ||
      errorPhoneEmpty ||
      errorPhoneFormat ||
      sending ||
      loading ||
      disabledButtonPay
    ) {
      setLoadingGlobal(false)
      /*  */
      let data = {}

      for (var key in props.formData) {
        data[key] = false
      }

      setFormError({...formError, ...data})
    }

    if (props.documents_pending === true && otherDocuments.length <= 0) {
      setLoadingGlobal(false)
      return setModalDocument(true)
    }

    if (productsComplementary?.length > 0) {
      setLoadingGlobal(false)
      clickGenerateLink()
    } else if (hasComplementary) {
      setLoadingGlobal(false)
      modalSupplement.current.openModal()
    } else if (isSubscription) {
      setLoadingGlobal(false)
      let validForm = checkForm()
      validForm && onCreateSubscription()
    } else {
      setLoadingGlobal(false)
      clickGenerateLink()
    }
  }

  const clickGenerateLink = async () => {
    try {
      setPurchase(true)
      let hash = await getHash()
      modalSupplement.current.closeModal()

      if (
        _.get(locationStorage, 'label', '') === '' ||
        _.get(props.formData, 'location_number.value', '') === ''
      ) {
        setPurchase(false)
        return props.setFormData({
          ...props.formData,
          location_name: {
            ...props.formData.location_name,
            value: locationStorage?.label,
            valid: false,
          },
        })
      } else if (errorCheckTerms) {
        setPurchase(false)
        setErrorCheckTerms(true)
        return props.setFormData({
          ...props.formData,
          checkTerms: {
            ...props.formData.checkTerms,
            valid: false,
          },
        })
      }

      let validationFormData = getStatusform()
      props.setFormData(validationFormData)

      if (!(Object.values(validationFormData).some((el) => el.valid === false))) {
        // Email validation
        if (!_validateMail(props.formData.email.value)) {
          setPurchase(false)
          return setErrorEmail(true)
        }
        // Phone value validation
        if (props.formData.phone.value.length === 0) {
          setPurchase(false)
          return setErrorPhoneEmpty(true)
        }
        // Phone format validation
        if (!_validPhone(props.formData.phone.value)) {
          setPurchase(false)
          return setErrorPhoneFormat(true)
        }
        // Products prescription validation
        if (!validatePrescription()) {
          setPurchase(false)
        }

        const jwtData = getJWTdata()

        setSending(true)
        setLoadingConfirmBuy(true)

        let productsObj = products.map((el) => {
          return {
            product_id: el.product.product_id,
            product_refund: el?.product?.refund || null,
            product_discount: el?.product_discount || 0,
            benefit: el?.benefit || 0,
            discount_id: el?.discount_id || 0,
            price_id: el.price_id,
            price: el.price,
            quantity: el.counter,
          }
        })
        let productsComplementaryObj = productsComplementary.map((el) => {
          return {
            product_id: el.product_id,
            product_refund: null,
            product_discount: 0,
            benefit: 0,
            discount_id: 0,
            price_id: el.price_id,
            price: el.price,
            quantity: el.quantity,
          }
        })

        let paymentCard = null
        if (selectedPaymentMethod) {
          paymentCard = {
            token: selectedPaymentMethod.id,
            last_four: selectedPaymentMethod.last_four,
            card_type: selectedPaymentMethod.type,
          }
        }
        let copayData = {}
        try {
          copayData = JSON.parse(decrypt(props?.userData))
        } catch (e) {}
        let prescriptions = await uploadPrescription()

        let region = props?.location?.region
        let address = _.get(locationStorage, 'label', '') + (region && `, ${region}`)
        let locationNumber = _.get(props.formData, 'location_number.value', '')
        locationNumber = locationNumber === '' ? locationNumber : `. Nº: ${locationNumber}`
        let additionalNumber = _.get(props.formData, 'address_additional.value', '')
        additionalNumber = additionalNumber === '' ? additionalNumber : `, Otro: ${additionalNumber}`

        let currentAddress = `${address}${locationNumber}${additionalNumber}`
        let currentDelivery = {
          delivery_discount_code: (deliveryCode?.code || null),
          address: {
            description: (currentAddress).trim(),
            lat: locationStorage?.lat,
            lng: locationStorage?.lng,
          },
          commune_id: locationStorage?.commune_id,
          delivery_at: formatDate(date),
        }

        let sendData = {
          hash: hash,
          user: {
            user_id: jwtData?.user_id,
            identity: props.formData.rut.value,
            name: props.formData.full_name.value,
            phone: props.formData.phone.value,
            email: props.formData.email.value,
          },
          products: productsObj,
          products_complementary: productsComplementaryObj,
          pharmacy_chain_id: item.pharmacy_chain_id,
          payment_card: paymentCard,
          payment_token: '',
          delivery: currentDelivery,
          prescriptions: prescriptions,
          refundId: refund?.id ? encrypt(refund?.id) : null,
          refund: refund?.value ? encrypt(refund.value) : null,
          deductible: deductible,
          insurance_discount_id: insuranceDiscountId,
          holder_identity: copayData?.identity_titular
            ? normalizeRut(copayData?.identity_titular)
            : null,
          beneficiary_identity: copayData?.identity
            ? normalizeRut(copayData?.identity)
            : null,
          relationship: copayData?.relationship ? copayData?.relationship : null,
          policy: copayData?.policy_id ? encrypt(copayData?.policy_id) : null,
        }

        addEventNameWithParams('checkout_product_to_pay', {
          ...sendData,
          hash: hash,
          product_cart_price: totalCart,
          complementary_product_cart_price: totalComplementaryCart,
          delivery_cost: delivery?.cost,
          complementary_delivery_cost:
            totalComplementaryCart > 0 ? complementaryDelivery?.cost : 0,
        })

        await createPreOrder(sendData, selectedPaymentMethod)

        let respPay = null
        let returnUrl = base_url + `/shopping_bag/checkout/${hash}`

        if (!selectedPaymentMethod) {
          try {
            respPay = await sendToPay(hash, returnUrl)
            if (respPay) {
              if (respPay?.data?.return_url) {
                returnUrl = respPay?.data?.return_url
              } else {
                returnUrl = respPay.data?.trx_response?.redirectUrl
              }
            }
          } catch (ignore) {
            logEvent('Payment Method Redirected', {
              Redirected: 'FALSE',
            })
            setLoadingConfirmBuy(false)
            setPurchase(false)
          }
        }

        if (selectedPaymentMethod || respPay) {
          logEvent('Payment Method Redirected', {
            Redirected: 'TRUE',
          })
          window.location.href = returnUrl
        } else {
          setPurchase(false)
          setModalErrorPayIsOpen(true)
        }
      }
    } catch (error) {
      setLoadingConfirmBuy(false)
      setPurchase(false)
    }
  }

  function validatePrescription() {
    let requiredPrescriptionProducts = products.filter(
      (el) => el.product?.prescription_id > 0,
    )

    let productWithPrescriptions = requiredPrescriptionProducts.filter((el) => {
      let product = prescriptionProduct.find(
        (p) =>
          p?.product_id === el?.product?.product_id ||
          p?.product_id === el?.product?.client_product_id,
      )

      if (![undefined, null, ''].includes(product)) {
        return (
          el?.product?.product_id === product?.product_id ||
          el?.product?.client_product_id === product?.product_id
        )
      } else {
        return false
      }
    })

    if (
      requiredPrescriptionProducts?.length !== productWithPrescriptions?.length
    ) {
      setErrorPrescription(true)
      return false
    }
    return true
  }

  async function onCreateSubscription() {
    let formData = getStatusform()
    let result = false

    try {
      setLoadingConfirmBuy(true)
      let prescriptions = await uploadPrescription()
      const jwtData = getJWTdata()

      let selectedProducts = products.filter(
        (productItem) => productItem.counter > 0,
      )
      let subscriptionAddress = locationStorage.label
      let subscriptionInfo = Object.assign({}, item)
      let productList = []
      let discountList = []

      for (const productItem of selectedProducts) {
        let product = {
          type: subscriptionTypes.special,
          product_id: productItem.product.product_id,
          subscription_product_id: productItem.subscription_product_id,
          pharmacy_chain_id: subscriptionInfo.pharmacy_chain_id,
          quantity: productItem.counter,
        }
        productList.push(product)
      }

      if (discount?.discount_value) {
        discountList.push(discount)
      }

      if (
        formData?.location_number?.value &&
        formData.location_number.value !== ''
      ) {
        subscriptionAddress += ', N°' + formData.location_number.value
      }
      if (
        formData?.address_additional?.value &&
        formData.address_additional.value !== ''
      ) {
        subscriptionAddress += '. ' + formData.address_additional.value
      }

      let subscription = {
        origin: 'yapp_web',
        user_id: jwtData?.user_id,
        payment_id: selectedPaymentMethod.id,
        pharmacy_chain_id: subscriptionInfo.pharmacy_chain_id,
        first_delivery: formatDate(date),
        delivery_cost: delivery.cost,
        customer: {
          name: formData.full_name.value,
          tax_id: formData.rut.value,
          address: subscriptionAddress,
          address_id: null,
          address_latitude: locationStorage?.lat,
          address_longitude: locationStorage?.lng,
          commune: locationStorage?.locality,
          phone: '+56' + formData.phone.value,
          comment: '',
        },
        prescription_files: prescriptions,
        products: productList,
        discounts: discountList,
        frequency: frequency.value,
        map_order: 0,
      }

      result = await saveSubscription(subscription)
    } catch (err) {
      setLoadingConfirmBuy(false)
      setPurchase(false)
    } finally {
      if (result) {
        window.location.href = result
      } else {
        setLoadingConfirmBuy(false)
      }
    }
  }

  async function saveSubscription(subscription) {
    let productQuantity = products[0].counter
    let productPrice = products[0].price
    let redirect = base_url + `/shopping_bag/subscription?result=`
    let result = 'error'
    let subscriptionId = 0

    try {
      const response = await createSubscription(subscription)
      if (response?.data?.length > 0 && response.data[0].subscriptionId) {
        subscriptionId = response.data[0].subscriptionId
        localStorage.removeItem('selectedQuotationItem')
        await removeShoppingCart()
        result = 'success'
      }
    } catch (ignore) {}

    redirect += result
    let totalOrder = 0
    let totalCarValue = 0
    try {
      totalOrder =
        productQuantity * productPrice +
        subscription.delivery_cost -
        getCurrentDiscount()
    } catch (ignore) {}

    try {
      totalCarValue = productQuantity * productPrice
    } catch (ignore) {}

    logEvent('Order Completed', {
      'Order Success': result === 'success' ? true : false,
      'Total Cart Value': totalCarValue,
      'Total Cart Size': productQuantity,
      'Total Discounts': getCurrentDiscount(),
      'Promo Code Applied': discountCode,
      'Total Shipping': subscription?.delivery_cost,
      'Order Total': totalOrder,
      'Pharmacy Id': subscription?.pharmacy_chain_id,
      'Pharmacy Name': subscription?.pharmacy_chain_name,
      'Subscription Id': subscriptionId,
      'Purchase Type': 'Benefit Yapp',
    })

    if (result === 'success') {
      logEvent('Product Purchased', {
        'Product Quantity': productQuantity,
        'Subscription Id': subscriptionId,
        'Product Name': products[0]?.product?.name,
        'Product Id': products[0]?.product?.product_id,
        'Lab Name': products[0]?.product?.lab,
        'Product Price': products[0]?.price,
        'Total Price': totalCarValue,
        'Total Discounts': getCurrentDiscount(), // TODO: Discount for that product?
        'Active Principle Name': products[0]?.product?.formula_name,
      })
    }

    return redirect
  }

  const onUseDiscountCode = async () => {
    try {
      setIsLoading(true)
      let result = null
      let errorMessage = 'El código ingresado no es válido'

      if (![null, undefined, ''].includes(discountCode)) {
        result = await getDiscountValue(discountCode, 0)
        if (result?.discount_value) {
          result.discount_code = discountCode
          errorMessage = null
          if (products?.length > 0) {
            result.subscription_product_id = products[0].subscription_product_id
          }
          logEvent('Discount Code Applied', {
            Discount: discountCode,
            Applied: true,
            'Discount Type': 'Yapp Benefit Code',
            'Product Id': products[0]?.product?.product_id,
            'Product Name': products[0]?.product?.name,
            'Lab Name': products[0]?.product?.laboratory_name,
            'Active Principle Name':
              products[0]?.product?.active_principle_name,
            'Active Principle Id': products[0]?.product?.active_principle_id,
            'User Id': userInfo?.profile?.uid,
          })
          setToast(true, toastConfig.discountSuccessMessage)
        } else if (result?.discount_message) {
          logEvent('Discount Code Applied', {
            Discount: discountCode,
            Applied: false,
            'Discount Type': 'Yapp Benefit Code',
            'Product Id': products[0]?.product?.product_id,
            'Product Name': products[0]?.product?.name,
            'Lab Name': products[0]?.product?.laboratory_name,
            'Active Principle Name':
              products[0]?.product?.active_principle_name,
            'Active Principle Id': products[0]?.product?.active_principle_id,
            'User Id': userInfo?.profile?.uid,
          })

          errorMessage = result.discount_message
        }

        setDiscount(result)
        setErrorCode(errorMessage)

        props.setFormData({
          ...props.formData,
          discount: result,
        })
      }
    } catch (ignore) {
    } finally {
      setIsLoading(false)
    }
  }

  const getDiscountValue = async (code, productWithDiscount) => {
    let userId = getJWTdata()?.user_id
    let subscriptionProductId =
      products[productWithDiscount].subscription_product_id
    let municipality = locationStorage?.locality
    let deliveryCost = delivery?.cost ? delivery.cost : 0
    let identity = props.formData.rut?.value ? props.formData.rut.value : null
    let result = null

    try {
      const response = await getSubscriptionDiscountByCode(
        userId,
        identity,
        subscriptionProductId,
        municipality,
        deliveryCost,
        totalCart,
        code,
      )
      if (response?.data?.length > 0) result = response.data[0]
    } catch (ignore) {}
    return result
  }

  const getCurrentDiscount = () => {
    const currentDiscount = discount?.discount_value
      ? discount?.discount_value || 0
      : 0
    return currentDiscount
  }

  function getQuotationDetailProducts (quotationDetail, remoteBag) {
    let subTotal = 0
    let count = 0

    let products = quotationDetail.map((el) => {
      let idx = _.findIndex(remoteBag, {product_id: el.product.product_id})
      if (idx >= 0) {
        el.counter = remoteBag[idx].counter
        el.client_benefit = remoteBag[idx].client_benefit || 0
        el.client_benefit_pharmacy = remoteBag[idx].client_benefit_pharmacy || 0
        el.product.client_product_id =
          [undefined, null, ''].includes(
            remoteBag[idx]?.client_product_id,
          ) && remoteBag[idx]?.id

        if (
          !['', null, undefined].includes(el?.product?.client_product_id)
        ) {
          el.product.count = count
          count++
        }

        subTotal += (el.price * remoteBag[idx].counter)
      }

      return el
    })

    return { products, subTotal }
  }

  const calculateProductDiscountBenefits = (productList, data = item) => {
    let hasBenefits = ![null, undefined,'FALSE'].includes(process.env.REACT_APP_CLIENT_BENEFIT)
    let currentPharmacyChain = data.pharmacy_chain_id
    let totalDiscountBenefits = 0

    try {
      for (let product of productList) {
        if (hasBenefits && (product?.client_benefit > 0 
        && product?.client_benefit_pharmacy === currentPharmacyChain)) {
          product.discount_id = parseInt(process.env.REACT_APP_CLIENT_BENEFIT_ID)
          product.product_discount = ((product.counter * product.price) * product.client_benefit) /100
          product.benefit = 1
          totalDiscountBenefits += product.product_discount
        }

        product.id = product.product.internal_product_id
        product.name = product.product.name
        product.category_id = product.product.client_product_id
        product.unit = product.price
        product.total = product.price * product.quantity
        product.prescriptionNeeded = needsPrescription(product)
        product.prescriptionRefund = needsPrescription(product, hasBenefits)
      }
      setClientDiscountBenefits(totalDiscountBenefits)
    }
    catch (err) {
      console.log('Error getting client discount benefits', err)
    }
    finally {
      setProducts(productList)
    }

    return totalDiscountBenefits
  }

  function setDeliveryValue(deliveryData = null) {
    let deliveryCost = deliveryData?.cost
    let currentDiscount = 0

    if (deliveryCode?.code && deliveryCost > 0 && deliveryCode?.value <= 100) {
      currentDiscount = (deliveryCode.value * deliveryCost) / 100
      deliveryCost -= currentDiscount
    }

    setDelivery(deliveryData)
    setDeliveryDiscount(currentDiscount)

    return deliveryCost
  }

  function setDiscountData(isSubscription, products) {
    let discountData = props.formData.discount

    if (isSubscription && discountData && products.length > 0 &&
      discountData.subscription_product_id === products[0].subscription_product_id
    ) {
      setDiscount(discountData)
      setDiscountCode(discountData.discount_code)
    }
  }

  const checkRegisterValues = (key) =>{
    let error = {}

    if (accountError) {
      switch (key) {
        case 'identity':
          if (!_validateRut(rut))
            error = { message: '* El RUT debe contener entre 8 y 9 caracteres para ser válido.' }
          break
        case 'name':
          if (name?.length < 3)
            error = { message: '*Información necesaria para continuar con tu compra.' }
          break
        case 'email':
          if (!_validateMail(email))
            error = { message: '* Encontramos un error en el formato de tu correo, por favor verifica que esté correcto.' }
          break
        case 'password':
          if (password?.length < 8)
            error = { message: '*La contraseña debe tener un mínimo de 8 caracteres' }
          else if (!isPasswordValid())
            error = { message: '*Las contraseñas no coinciden.' }
          break
        default:
          break
      }
    }
    
    return error
  }

  function checkForm() {
    setPurchase(true)
    const customerForm = getStatusform()
    props.setFormData(customerForm)
    let isValidForm = !Object.values(customerForm).some(
      (data) => data.valid === false,
    )
    if (!validatePrescription()) {
      isValidForm = false
    }
    if (!isUserYapp) {
      if (customerForm.checkTerms.value === false) {
        setErrorCheckTerms(true)
        isValidForm = false
      }
    }

    if (customerForm.phone.value.length === 0) {
      setErrorPhoneEmpty(true)
      isValidForm = false
    } else if (!_validPhone(customerForm.phone.value)) {
      setErrorPhoneFormat(true)
      isValidForm = false
    }

    if (!isValidForm) setPurchase(false)
    return isValidForm
  }

  function handleCheckTermsChange(event) {
    setErrorCheckTerms(!event.target.checked)

    props.setFormData({
      ...props.formData,
      checkTerms: {
        valid: event.target.checked,
        value: event.target.checked,
      },
    })
  }
  const retryModal = () => {
    setSending(false)
    setModalErrorPayIsOpen(false)
  }

  function setDocuments(documents) {
    setModalDocument(false)
    if (documents.length > 0) {
      setOtherDocuments(documents)
    }
  }

  function closeModalDocuments() {
    setModalDocument(false)
    setSending(false)
    setLoadingConfirmBuy(false)
  }

  function removeErrorsPhone() {
    setErrorPhoneEmpty(false)
    setErrorPhoneFormat(false)
  }
  function removeErrorEmail() {
    setErrorEmail(false)
  }

  async function onClickRefund(showToast = true) {
    !pressedButton && setPressedButton(true)
    setLoadingInsurance(true)
    setInsuranceError(false)

    try {
      // Check if products are refundable
      if (insuranceType !== 'Group') {
        showToast && setToast(true, toastConfig.refundError)
        logEvent('Refund Checked', { Success: 'FALSE'})
        return
      }

      // Check if products are refundable
      if (products.every(product => ([null, undefined, ''].includes(product.category_id)))) {
        showToast && setToast(true, toastConfig.cartHasNonRefundableProductsError)
        logEvent('Refund Checked', { Success: 'FALSE'})
        return
      }

      // Check if prescriptions needed are already loaded
      if (products.some(product => (product.prescriptionRefund && !loadedPrescription(product)))) {
        showToast && setToast(true, toastConfig.prescriptionRefundError)
        logEvent('Refund Checked', { Success: 'FALSE'})
        return
      }

      // Search for refund value
      let refundableProducts = products.filter((prod) => prod.category_id !== null)
      let result = await getInsuranceLiquidation(
        props.insurance?.id,
        props.userData,
        item.pharmacy_chain_id,
        refundableProducts
      )
      let refundValue = _.sumBy(result.data.liquidation, (product) => product.refund)

      // Process the refund results
      if (result?.data?.code_error === 0) {
        setRefundData(result.data, refundValue)
        showToast && setToast(true, toastConfig.refundSuccess)
      }
      else {
        sendRefundErrorLog(result?.data)
      }
    } 
    catch (error) {
      sendRefundErrorLog(error, true)
    }
    finally {
      setLoadingInsurance(false)
    }
  }

  function setRefundData(data, refundValue) {
    let productAux = products
    let refundableProducts = []
    let policyLimit = false
    let requestId = data?.request_id || null
    let totalOrder = subTotalCart - clientDiscountBenefits - refundValue
    let deductibleValue = _.sumBy(data?.liquidation, (product) => product.deductible);

    setChainBusinessName(data.chain_business_name || 'la farmacia');
    setChainIdentityNumber(data.chain_identity_number || null);
    handleCheckTermsChange({ target: { checked: false }});

    try {
      productAux?.map((item) => {
        let correlative = data?.liquidation?.find((d) => {
          if (item.product.client_product_id) {
            return (
              (d.product_id === item.product.count ||
                d.product_id === item.product.internal_product_id) &&
              ![undefined, null, ''].includes(d.product_id)
            )
          } else {
            return false
          }
        })

        if (correlative) {
          item.product.refund = correlative.refund

          refundableProducts.push({
            category_id: item.category_id,
            product_id: item.product.product_id,
            product_name: item.product.name,
            price: item.price,
            refund: correlative.refund
          })
        }
      })

      setProducts(productAux)
      setRefund({
        id: requestId || 0,
        value: refundValue,
      })
      setDeductible(deductibleValue)
      setTotalCart(totalOrder)
      dispatch(setApplicationNumber(requestId))
      dispatch(setUserRefund({ refund: refundValue, products: refundableProducts }))
    } 
    catch (er) {
      console.log(er)
    }

    policyLimit = _.find(data?.liquidation, (product) =>
      ['Alcanza tope en UF'].includes((product.description || '').trim()),
    )
    policyLimit && setInsurancePolicyLimit(true)
    
    if (deductibleValue > 0)
      setApplyDeductible(true)
    
    logEvent('Refund Checked', {
      Success: 'TRUE',
      Subtotal: subTotalCart - clientDiscountBenefits,
      Refund: refundValue,
      'Total Pay': totalOrder,
      'Product Quantity': data?.liquidation?.length,
      'Insurance Policy Limit': policyLimit ? 'TRUE' : 'FALSE',
      'Apply Deductible': deductibleValue > 0 ? 'TRUE' : 'FALSE'
    })
  }

  function sendRefundErrorLog(data, isException = false) {
    let errorCode = data?.code_error || ''
    let message = isException
      ? 'No fue posible calcular el valor de reembolso'
      : 'Error al calcular el valor de reembolso. ErrorCode ' + errorCode

    try {
      setContext('Error Insurance', {
        ...data,
        liquidation: JSON.stringify(data?.liquidation),
      })
      isException && Sentry.captureException(data)
      Sentry.captureMessage(message)
    } catch (ignore) {}

    if (errorCode === 5 || errorCode === -1) {
      setInsuranceError(true)
    }
    else if (errorCode === 1 || errorCode === 2) {
      dispatch(setApplicationNumber(null))
      setInsurancePolicyLimit(data?.error_message)
    }

    logEvent('Refund Checked', { Success: 'FALSE'})
  }

  async function deleteProductItem(productItem) {
    const newProducts = products.filter((product) => 
      product.product.product_id !== productItem.product.product_id
    )

    let item = localStorage.getItem('selectedQuotationItem')
    if (item) {
      item = JSON.parse(item)
      
      let subtotal = _.sumBy(newProducts, (product) => product.price * product.quantity)
      let discountBenefits = calculateProductDiscountBenefits(newProducts)
      let total = subtotal - discountBenefits + item.delivery.cost - deliveryDiscount

      item.quantity_products = item.quantity_products - 1
      item.quantity_results = item.quantity_results - 1
      item.total = subtotal
      item.total_amount = subtotal
      item.total_order = total
      item.quotation_detail = item.quotation_detail.filter(item => 
        item.product.product_id !== productItem.product.product_id
      )

      localStorage.setItem('selectedQuotationItem', JSON.stringify(item))
      setClientDiscountBenefits(discountBenefits)
      setSubTotalCart(subtotal)
      setTotalCart(total)
      setProducts(newProducts)
      setItem(item)
    }
  }

  function loadedPrescription(quotation) {
    let productId = quotation?.product?.product_id
    let loaded = prescriptionProduct?.some((prescription) => (prescription.product_id === productId))

    return loaded
  }

  async function loadBeneficiaries() {
    let identity = props.formData.titular_identity?.value
    setLoadingInsurance(true)
    setBenefit(null)

    if (insuranceType == 'General' && props.formData.rut?.value) {
      identity = props.formData.rut.value
      let response = await existUserClient(identity)

      if (response?.data?.length > 0 && response.data[0].delivery_code) {
        dispatch(setEnabledInsurance(true))
        props.setFormData({
          ...props.formData,
          titular_identity: {
            valid: true,
            value: identity,
          },
          rut: {
            valid: true,
            value: identity,
          }
        })
      }
      else {
        dispatch(setEnabledInsurance(false))
        props.setFormData({
          ...props.formData,
          rut: {
            valid: true,
            value: identity,
          },
          titular_identity: {
            valid: false,
            value: null,
          },
        })
      }
    }
    else {
      let response = await getBeneficiaries(identity, props.insurance?.id)
      if (
        response?.message === 'OK: Success' &&
        response?.data?.listBeneficiaries?.length > 0
      ) {
        setOpenModalBeneficiaries(true)
        setBeneficiaries(response.data.listBeneficiaries)
      } else {
        props.setFormData({
          ...props.formData,
          full_name: {
            valid: false,
            value: _capitalize(''),
          },
          rut: {
            valid: false,
            value: null,
          },
          titular_identity: {
            valid: false,
            value: null,
          },
        })

        dispatch(setEnabledInsurance(false))
        dispatch(setCopayUserData(''))
        dispatch(setApplicationNumber(null))
        setOpenModalError(true)
        setLocalInsurance(null)
      }
    }
    setLoadingInsurance(false)
  }

  async function onClickBenefit(userData) {
    setOpenModalBeneficiaries(false)

    if (userData) {
      let encryptedData = encrypt(userData)

      dispatch(setEnabledInsurance(true))
      props.setFormData({
        ...props.formData,
        full_name: {
          valid: true,
          value: _capitalize(userData?.name || ''),
        },
        rut: {
          valid: true,
          value: ![undefined, null, '', ' '].includes(userData?.identity)
            ? _validateRut(userData?.identity)
              ? _formatRut(userData?.identity)
              : null
            : null,
        },
        titular_identity: {
          valid: true,
          value: ![undefined, null, '', ' '].includes(
            userData?.identity_titular,
          )
            ? _validateRut(userData?.identity_titular)
              ? _formatRut(userData?.identity_titular)
              : null
            : null,
        },
      })

      dispatch(setCopayUserData(encryptedData))
    }
    setLoginData({
      ...loginData,
      show: false
    })

  }

  async function register (provider) {
    dispatch(setProvider(provider))
      if (provider === 'email') {
        _validateMail(email)
        let response = await registerPassword(
          email,
          password,
          name,
        )
      if (response.status === 'already-account') {
        actionNext(response)
      } else {
        await dispatch(fetchUser())
        await registerAccount(true)
      }
    }
  }

  const actionNext = async (response) => {
    try {
      dispatch(setProvider(response?.data?.provider.split('.')[0]))
      switch (response.status) {
        case 'success':
          await dispatch(fetchUser())
          break
        case 'no-account':
        case 'no-professional':
          await dispatch(fetchUser())
          // setIsLoading(false)
          // dispatch(changeInitialLoading(false))
          break
        case 'already-account':
          // dispatch(changeInitialLoading(false))
          // setIsLoading(false)
          setShowModalLogin({show: true, title: '¡Hemos detectado que ya tienes una cuenta!'})
          break
        default:
          // dispatch(changeInitialLoading(false))
          // setIsLoading(false)
          break
      }
    } catch (error) {
      logEvent('Create Account Completed', {
        'Account Type': response?.data?.provider.split('.')[0],
        'Account Created': false,
      })
      // dispatch(changeInitialLoading(false))
      // setIsLoading(false)
    }
  }

  function togglePatientData(e) {
    if (e.target.checked) {
      props.setFormData({
        ...props.formData,
        full_name: {
          value: props.userInfo?.userInfo.profile.name ? props.userInfo?.userInfo.profile.name : props.userInfo?.userInfo?.profile?.first_name + ' ' + props.userInfo?.userInfo?.profile?.last_name,
          valid: true,
        },
        rut: {
          value: props.userInfo.userInfo?.profile?.rut ? props.userInfo.userInfo?.profile?.rut : rut ? rut : '',
          valid: props.userInfo.userInfo?.profile?.rut ? true : rut ? true : false,
        }
      })
      setLoginData({show: true})
    } else {
      props.setFormData({
        ...props.formData,
        full_name: {
          value: '',
          valid: false,
        },
        rut: {
          value: '',
          valid: false,
        }
      })
      setLoginData({show: false})
    }
  }

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
  }

  const isPasswordValid = () => {
    let isValid = false

    if (password.length > 7 && passwordConfirmation.length > 7 
      && password.localeCompare(passwordConfirmation) === 0) {
        isValid = true
    }

    return isValid
  }

  const isAccountValid = () => {
    if (_validateMail(email) && isPasswordValid() && name?.length > 2 && policies)
      registerAccount()
    else
      setAccountError(true)
  }

  async function registerAccount(skip = false) {   
    try {
      if ((([undefined, null].includes(userInfo) || userInfo?.signInMethod === 'anonymously'))
          && skip === false) {
        return register('email')
      }

      setShowloadingCreateAccount(true)

      let response = null
      if (formIsValid) {
        let identity_temp = localStorage.getItem('identity_temp')
        let currentUser =
          skip === true
            ? JSON.parse(localStorage.getItem('login_temp_data'))
            : userInfo

        identity_temp = JSON.parse(identity_temp)

        response = await registerUser(
          email,
          password,
          name,
          identity_temp?.rut,
          identity_temp?.document,
          policies,
          currentUser,
        )
        if (response) {
          await dispatch(fetchUser())
        }
      }
      setShowloadingCreateAccount(false)

      logEvent('Create Account Completed', {
        'Success': 'TRUE',
      })
    } catch (e) {
      console.log(e)
      logEvent('Create Account Completed', {
        'Success': 'FALSE',
      })
    }
  }

  const setToast = (show, data = null) => {
    if (show) {
      setToastData(data)
      setStateToasts(true)
    }
    else {
      setStateToasts(false)
    }
  }

  const isUserYapp =
    userInfo?.profile?.uid && userInfo?.signInMethod !== 'anonymously'
      ? true
      : false

  return (
    <div className="">
      <ModalLoginCheckout 
        showModalLogin={showModalLogin.show}
        setShowModalLogin={setShowModalLogin}
        title={showModalLogin.title}
      />
      <ModalError
        isOpen={openModalError}
        setIsOpen={() => {
          setOpenModalError(false)
        }}
        title=""
        text="El rut no se encuentra registrado en esta plataforma, por favor intenta reembolsarlo manualmente..."
        btnText="Continuar sin reembolso"
        // onClick={continueWithoutInsurance}
      />
      <ModalBeneficiaries
        isOpen={openModalBeneficiaries}
        setIsOpen={() => {
          setOpenModalBeneficiaries(false)
        }}
        claimer={benefit}
        setClaimer={setBenefit}
        onClaimer={onClickBenefit}
        beneficiaries={beneficiaries}
        title="Selecciona el asegurado para quien se realizará la compra:"
        btnText="Aceptar"
      />
      <ModalUploader
        ref={modalRef}
        modalIsOpen={modalDocument}
        onSelect={setPrescriptionProduct}
      />
      <ModalUploadHome
        ref={modalHomeRef}
        closeModal={closeModalDocuments}
        modalIsOpen={modalDocument}
        setDocuments={setDocuments}
      />
      <Modal
        isOpen={modalErrorPayIsOpen}
        onAfterOpen={null}
        onRequestClose={retryModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={icErrorPay}
            alt="Error"
            className="mb-3"
            id="gtm-webapp-error-pay-method"
          />
          <p className="title-primary--xl text-center mb-30">
            Ha ocurrido un error con tu
            <br /> medio de pago.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={retryModal}>
            Volver a intentar
          </Button>
        </div>
      </Modal>
      {loadingInsurance &&
        (clientName !== 'Sura' ? (
          <LoadingScreen
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="var(--color-secondary)"
            textColor="var(--color-secondary)"
            text="Cargando información"></LoadingScreen>
        ) : (
          <LoadingSura />
        ))}
      {showPdf && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
          <div className="bioequivalent-pdf">
            <button
              className="btn-circle-close"
              onClick={() => setShowPdf(false)}>
              <i className="icon-close"></i>
            </button>
            <Viewer
              fileUrl={pdfToShow}
              defaultScale={SpecialZoomLevel.PageWidth}
              /* defaultScale={0.5} */
              renderLoader={() => {
                return (
                  <img
                    src={loadingYapp}
                    alt="loading"
                    className="loading-yapp"
                  />
                )
              }}
            />
          </div>
          <div
            className="bioequivalent-pdf-lightbox"
            /* onClick={() => setShowPdf(false)} */
          ></div>
        </Worker>
      )}
      {loadingConfirmBuy ? (
        <LoadingCheckout prePay={isUserYapp === false} />
      ) : (
        <>
          {loadingGlobal ? (
            <div className="mt-4 mb-0 pt-5">
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--alternative m-0"
              />
            </div>
          ) : (
            <>
              {clientName !== 'UC Christus' && (
                <div
                  id="gtm-webapp-wrap-checkout"
                  className="content-responsive d-flex justify-content-between py-3 px-md-0">
                  <div className="breadcrumbs c-pointer" onClick={goBack}>
                    <div className="d-flex align-items-center">
                      <i className="icon-chevron-right icon-reverse"></i>
                    </div>
                    <span
                      className="txt-4--regular-pr"
                      style={{color: 'var(--theme-color)'}}>
                      Volver
                    </span>
                  </div>
                </div>
              )}

              {/* Pasos de compra - checkout */}
              <div className='w-100 d-flex justify-content-center'>
                <div className={`${clientName === 'Sura' ? 'maxw-218' : 'maxw-325'}`}>
                  <div className='w-100'>
                    <ul className='d-flex justify-content-around txt-paragraph-gray--light text-center'>
                      <li 
                        className={`w-160 p-2 px-10 ${step === 1 && 'txt-5--regular-pr'} ${clientName === 'Sura' && 'd-none'}`} 
                      >
                        <p className='mb-1'>Identificación</p>
                        <div className={step >= 1 ? 'ellipse' : 'ellipse btn-circle-disabled'}>
                          <span>1</span>
                        </div>
                        <div className='separator-dots'>
                          <img
                            width='75px'
                            className='px-8' 
                            src={dots}
                            alt=''
                          />
                        </div>
                      </li>
                      <li 
                        className={`w-162 p-2 px-10 ${step === 3 && 'c-pointer'} ${step === 2 && 'txt-5--regular-pr'}`} 
                        onClick={() => step === 3 && setStep(2)}
                      >
                        <p className='mb-1'>Paciente</p>
                        <div className={step >= 2 ? 'ellipse' : 'ellipse btn-circle-disabled'}>
                          <span>{clientName === 'Sura' ? '1' : '2'}</span>
                        </div>
                        <div className='separator-dots'>
                          <img
                            width='75px'
                            className='px-8' 
                            src={dots}
                            alt=''
                          />
                        </div>
                      </li>
                      <li 
                        className={`w-165 p-2 px-10 ${step === 3 && 'txt-5--regular-pr'}`} 
                      >
                        <p className='mb-1'>Despacho y Pago</p>
                        <div className={step === 3 ? 'ellipse' : 'ellipse btn-circle-disabled'}>
                          <span>{clientName === 'Sura' ? '2' : '3'}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="content-checkout content-responsive">
                <div className="content-checkout-left">

                {/* Loading Screens */}
                {/* <LoadingCreateAccount /> */}

                  {step === 1 && (
                    <>
                      {!loadingCheckout ? (
                        <>
                          <h3 className={`txt-3--regular-db w-100 mb-3`}>
                            ¿Ya tienes una cuenta en Yapp?{' '}
                            <span
                              id={'gtm-webapp-register-login-btn'}
                              className="txt-3--bold-pr semi-bold color-lilac c-pointer"
                              onClick={() => setShowModalLogin({show: true, title: 'Inicia tu sesión'})}
                            >
                              Iniciar sesión
                            </span>
                          </h3>
                          <h2 className="txt-2--bold-db mb-2">
                            Crear una cuenta
                          </h2>
                          <p className='txt-4--regular-db mb-3'>Para poder continuar con tu compra debes crear una cuenta</p>
                          <div className="mb-3">
                            <div className="d-flex flex-column flex-md-row">
                              <div className="mb-3 w-100">
                                <Input
                                  externalLabel={isStyleLight}
                                  label="Correo"
                                  placeholder="Ingresa tu correo"
                                  defaultValue={props.formData?.email?.value}
                                  error={ checkRegisterValues('email') }
                                  doChange={(value) => setEmail(value)}
                                  onKeyPress={removeErrorEmail}
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                              <div className="mb-3 pr-md-2 w-md-50">
                                <Input
                                  id="gtm-webapp-name-register"
                                  error={ checkRegisterValues('name') }
                                  label="Nombre y apellido"
                                  placeholder="Nombre y apellido"
                                  // value={userName || ''}
                                  doChange={(name) => setName(name)}
                                />
                              </div>
                              <div
                                className="mb-3 w-md-50"
                                // onClick={() => inputRef.current.click()}
                              >
                                <Input
                                  id="gtm-webapp-rut-register"
                                  label="RUT"
                                  placeholder="RUT"
                                  error={ checkRegisterValues('identity') }
                                  doChange={(rut) => {
                                    if (rut.length > 2)
                                      rut = _formatRut(rut)
                                    setRut(rut)
                                  }}
                                  value={ rut || ''}
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                              <div className="mb-2 pr-md-2 w-md-50">
                                <Input
                                  id={'gtm-webapp-register-pass'}
                                  showPass
                                  label="Contraseña"
                                  error={{message: ''}}
                                  doChange={(password) => setPassword(password)}
                                />
                                <p className="txt-4--regular-g1 mb-1 mt-2">
                                  *Tu contraseña debe tener un mínimo de 8 caracteres.
                                </p>
                              </div>
                              <div className="mb-2 w-md-50">
                                <Input
                                  id={'gtm-webapp-register-repeat-pass'}
                                  showPass
                                  label="Repetir contraseña"
                                  error={ checkRegisterValues('password') }
                                  doChange={(password) => setPasswordConfirmation(password)}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-4 mt-3">
                              <label
                                className="custom-checkbox-altern align-items-start pl-3 m-0"
                                id={'gtm-webapp-register-check-terms'}>
                                <input
                                  id="gtm-webapp-register-checkbox"
                                  ref={null}
                                  disabled={false}
                                  type="checkbox"
                                  onChange={(evt) => setPolicies(evt.target.checked)}
                                />
                                <span className="checkmark-altern">
                                  <i
                                    id="gtm-webapp-register-checkbox-icon"
                                    className="icon-checkmark"
                                  />
                                </span>
                              </label>
                              <span className="txt-4--regular-db ml-1 line-16">
                                Acepto los{' '}
                                <span
                                  id="gtm-webapp-register-terms"
                                  className="txt-5--regular-pr c-pointer"
                                  onClick={() => goTo('terms')}
                                >
                                  Términos & Condiciones
                                </span>{' '}
                                y{' '}
                                <span
                                  id="gtm-webapp-register-policies"
                                  className="txt-5--regular-pr c-pointer"
                                  onClick={() => goTo('policies')}
                                >
                                  Políticas de privacidad
                                </span>
                              </span>
                            </div>
                          </div>
                          <ButtonYapp
                            // disabled={buttonDisable || validUserInfo()}
                            id={'gtm-webapp-register-btn'}
                            className="btn-default--small btn-default font--14 size-content-responsive-btn mx-auto w-100 mb-4"
                            onClick={ isAccountValid }
                            title={'Crear cuenta'}
                          />
                        </>
                      ) : <LoadingLoginAccount />
                    }
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <h2 className="txt-2--bold-db mb-2">
                        Datos del paciente
                      </h2>
                      {clientName !== 'Sura' &&
                        <>
                          <div className="d-flex align-items-center mb-4 mt-3">
                            <label
                              className="custom-checkbox-altern align-items-start pl-1 m-0"
                              id={'gtm-webapp-register-check-terms'}>
                              <input
                                id="gtm-webapp-register-checkbox"
                                ref={null}
                                disabled={false}
                                type="checkbox"
                                checked={loginData.show ? true : false}
                                onChange={(e) => togglePatientData(e)}
                              />
                              <span className="checkmark-altern">
                                <i
                                  id="gtm-webapp-register-checkbox-icon"
                                  className="icon-checkmark"
                                />
                              </span>
                            </label>
                            <span className="txt-4--regular-db ml-3 line-16">
                              La compra es para mi
                            </span>
                          </div>
                        </>
                      }
                      <div className="d-flex flex-column flex-md-row">
                        <div className="mb-2 pr-md-2 w-md-50">
                          <InputButton
                            externalLabel={isStyleLight}
                            classData={clientName === 'Sura' ? 'bg-light-ds' : ''}
                            label="Rut"
                            placeholder="Rut"
                            hideButton={ (!isInsurance && !props?.enabledInsurance) || (clientName === 'Sura') }
                            disabled={props?.enabledInsurance || clientName === 'Sura'}
                            btnText={
                              props?.enabledInsurance && insuranceType == 'Group' ? 'Cambiar' : 'Validar'
                            }
                            value={props.formData?.rut?.value}
                            onButtonClick={() => loadBeneficiaries()}
                            error={
                              [undefined, null, ''].includes(props.formData.rut?.value) && formError.rut === false
                                ? {
                                    message:
                                      '* Necesitamos el RUT del paciente para validar la receta médica.',
                                  }
                                : !props.formData?.rut?.valid &&
                                  props.formData?.rut?.value && {
                                    message:
                                      '* El RUT debe contener entre 8 y 9 caracteres para ser válido.',
                                  }
                            }
                            onChange={(value) => changeValue(value, 'rut')}
                          />
                        </div>
                        <div className="mb-2 w-md-50">
                          <Input
                            externalLabel={isStyleLight}
                            label="Nombre y apellido"
                            placeholder=""
                            defaultValue={props.formData?.full_name?.value}
                            disabled={props?.enabledInsurance && insuranceType == 'group'}
                            error={
                              formError?.full_name === false &&
                              !props.formData?.full_name?.value && {
                                message:
                                  '* Necesitamos el nombre del paciente para validar la receta médica. ',
                              }
                            }
                            doChange={(value) => changeValue(value, 'full_name')}
                          />
                        </div>
                      </div>
                      { products?.some((product) => product.prescriptionRefund) && (
                        <h2 className="txt-2--bold-db mb-2 mt-3">
                          Recetas médicas
                        </h2>
                      )}
                      <div className="mb-3">
                        { products?.map((quotation, key) => {
                          return (
                            <div key={key} className="p-0">
                              { (quotation.prescriptionRefund) && (
                                <>
                                  <ProductPrescription
                                    product={quotation.product}
                                    products={products.filter((quotation) => quotation.prescriptionRefund)}
                                    prescriptionProduct={prescriptionProduct}
                                    onClickLoadPrescription={
                                      onClickLoadPrescription
                                    }
                                  />
                                  {errorPrescription &&
                                    (!loadedPrescription(quotation)) &&
                                    quotation.product.prescription_id > 0 && (
                                      <p className="txt-6--regular-er">
                                        * Este medicamento requiere una receta
                                        médica, por favor agrega una para continuar.
                                      </p>
                                    )}
                                </>
                              )}
                            </div>
                          )
                        })}
                        <>
                          {![null, undefined, ''].includes(
                            textExtraPrescription,
                          ) && (
                            <div className="mt-3">
                              <p className="txt-6--bold-db line-14">
                                {textExtraPrescription}
                              </p>
                            </div>
                          )}
                        </>
                      </div>
                      <div className="d-flex w-100 justify-content-center mt-4 mb-4 mb-md-3 mb-lg-0">
                        <ButtonYapp
                          className="px-5"
                          onClick={nextAction}
                          // id=""
                          title="Continuar"
                          color={'--theme-color'}
                          big
                          // disabled={purchase}
                        />
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <h2 className="txt-2--bold-db mb-2">
                        Completa los datos para el despacho
                      </h2>
                      <div className='d-flex flex-column flex-md-row'>
                        <div className="mb-2 pr-md-2 position-relative w-md-50">
                          <Location
                            externalLabel={isStyleLight}
                            handleParentDeliveryCost={setDelivery} 
                            handleParentDeliveryDate={handleDeliveryDate}
                            handleParentLoading={setLoading}
                            pharmacy_chain_id={item?.pharmacy_chain_id}
                          />
                        </div>
                        <div className="mb-2 w-md-50">
                          <Input
                            externalLabel={isStyleLight}
                            label="Número"
                            placeholder="Número (Ej: 2500)"
                            defaultValue={props.formData.location_number.value}
                            doChange={(value) =>
                              changeValue(value, 'location_number')
                            }
                            error={
                              formError?.location_number === false &&
                              !props.formData?.location_number?.value
                                ? {
                                    message:
                                      '*Información necesaria para continuar con tu compra.',
                                  }
                                : !props.formData?.location_number?.valid &&
                                  props.formData?.location_number?.value && {
                                    message:
                                      '*Información necesaria para continuar con tu compra.',
                                  }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row'>
                        <div className='mb-2 pr-md-2 position-relative w-md-50'>
                          <Input
                            externalLabel={isStyleLight}
                            label="Número dpto o casa"
                            placeholder="Número dpto o casa (Ej: dpto 15/ oficina 28)"
                            defaultValue={props.formData.address_additional.value}
                            doChange={(value) =>
                              changeValue(value, 'address_additional')
                            }
                          />
                        </div>
                        <div className='mb-2 w-md-50'>
                          <Input
                            externalLabel={isStyleLight}
                            label="Teléfono"
                            placeholder="Número de teléfono"
                            maxLength={9}
                            type="tel"
                            codeArea="+56"
                            defaultValue={props.formData.phone.value}
                            error={
                              formError?.phone === false &&
                              !props.formData?.phone?.value
                                ? {
                                    message:
                                      '*Información necesaria para continuar con tu compra.',
                                  }
                                : (props.formData.phone.valid ||
                                    !errorPhoneEmpty) &&
                                  !_validPhone(props.formData.phone.value) && {
                                    message:
                                      '* El número debe contener (9) caracteres.',
                                  }
                            }
                            doChange={(value) => changeValue(value, 'phone')}
                            onKeyPress={removeErrorsPhone}
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row mb-4'>
                        <div className='mb-2 pr-md-2 position-relative w-md-50'>
                          <Input
                            externalLabel={isStyleLight}
                            label="Fecha de entrega"
                            placeholder="Fecha de entrega"
                            type="date"
                            defaultValue={![null, undefined].includes(deliveryTime)
                              ? item?.delivery?.message
                              : moment(date).format('DD-MM-YYYY')}
                            showEdit
                            secondaryValue={`+${_formatPrice(delivery?.cost)}`}
                            onClick={() => inputRef.current.click()}
                          />
                        </div>
                        <div className='mb-2 w-md-50'>
                          <Input
                            externalLabel={isStyleLight}
                            label="Correo"
                            placeholder="Ingresa tu correo"
                            defaultValue={props.formData.email.value}
                            error={
                              formError?.email === false &&
                              !props.formData?.email?.value
                                ? {
                                    message:
                                      '* Necesitamos un correo para enviarte información de seguimiento sobre tu pedido.',
                                  }
                                : (!props.formData?.email?.valid || errorEmail) &&
                                  props.formData?.email?.value && {
                                    message:
                                      '* Encontramos un error en el formato de tu correo, por favor verifica que esté correcto.',
                                  }
                            }
                            doChange={(value) => changeValue(value, 'email')}
                            onKeyPress={removeErrorEmail}
                          />
                        </div>
                      </div>
                      <div className=''>
                        {isSubscription && (
                          <>
                            <div className="d-flex">
                              <div className="w-75 mb-3">
                                <h2 className="title-primary--xl-bold mb-2">
                                  Frecuencia de compra
                                </h2>
                                <SimpleSelect
                                  options={deliveryFrequencies}
                                  value={frequency}
                                  placeholder={'Selecciona tu frecuencia'}
                                  onChange={setFrequency}
                                  className="tablet-desktop"
                                />
                                {invalidFrequency && (
                                  <p className="txt-6--regular-er mt-1 mb-2">
                                    *Debes ingresar una frecuencia de entrega para
                                    finalizar tu compra
                                  </p>
                                )}
                                <div
                                  onClick={() => setShowModalBottomSimple(true)}
                                  className="btn-open-modal-bottom-full d-flex align-items-center justify-content-between px-3">
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    <p className="txt-5--regular-db pl-2">
                                      {frequency?.label || 'Selecciona tu frecuencia'}
                                    </p>
                                  </div>
                                  <i className="icon-chevron-down color-theme"></i>
                                </div>
                                <ModalBottomSimple
                                  currentFrequency={frequency}
                                  showModal={showModalBottomSimple}
                                  onChange={setFrequency}
                                  setShowModal={setShowModalBottomSimple}
                                />
                              </div>
                              {products.length > 0 && (
                                <div className="w-25 mb-3 pl-3">
                                  <h2 className="title-primary--xl-bold mb-3">
                                    Cantidad
                                  </h2>
                                  {products.map((item, index) => (
                                    <Stepper
                                      key={index.toString()}
                                      counter={item.counter}
                                      clickUpdateCounter={(quantity) =>
                                        setCounter(index, quantity)
                                      }
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="content-checkout-right pl-0 pr-0">
                  <div className="content-checkout-shopping-detail">
                    <div className="content-pay-detail">
                      <ProductSummary
                        title="Resumen de tu compra"
                        buttonText={ clientName === 'Bice Vida' ? 'Buscar medicamento' : 'Agregar medicamentos' }
                        modifyCart={!isSubscription}
                        totalCart={totalCart - getCurrentDiscount() - delivery.cost + (deliveryDiscount ? deliveryDiscount : 0)}
                        products={products.map((el) => {
                          return {
                            ...el.product,
                            ...el,
                            prescriptionLoaded:
                              needsPrescription(el, true) &&
                              !loadedPrescription(el),
                          }
                        })}
                        onClickAction={addProducts}
                        className="pt-2"
                        refundElement={refundElement}
                        onClickApplyRefund={onClickRefund}
                        applyRefund={(text) => console.log(text)}
                        discountRefund={getCurrentDiscount()}
                        insurancePolicyLimit={insurancePolicyLimit}
                        insuranceError={insuranceError}
                        applyDeductible={applyDeductible}
                        refund={refund}
                        show={props.userData}
                        pressedButton={pressedButton}
                        isSubscription={isSubscription}
                        deleteProduct={deleteProductItem}
                      />
                      {hasComplementary &&
                        productsComplementary?.length > 0 && (
                          <ProductSummary
                            title="Productos complementarios"
                            buttonText="Agregar o quitar productos complementarios"
                            products={productsComplementary}
                            discountRefund={getCurrentDiscount()}
                            modifyCart={true}
                            totalCart={totalComplementaryCart}
                            onClickAction={() =>
                              modalSupplement.current.openModal()
                            }
                          />
                        )}

                      <div className="d-flex flex-wrap content-checkout-delivery content-bg-white-resposive">
                        <div className="d-flex w-100 justify-content-between">
                          <p className="txt-4--regular-db">
                            Costo despacho
                          </p>
                          <p className="txt-4--regular-db">
                            {_formatPrice(delivery?.cost)}
                          </p>
                        </div>
                        {totalComplementaryCart > 0 &&
                          productsComplementary?.length > 0 && (
                            <div className="d-flex w-100 justify-content-between mb-3">
                              <p className="txt-5--regular-db">
                                Costo despacho productos complementarios
                              </p>
                              <p className="txt-5--regular-db">
                                {_formatPrice(complementaryDelivery?.cost)}
                              </p>
                            </div>
                          )}
                        {deliveryCode?.code && (
                          <div className="d-flex w-100 justify-content-between mb-3">
                            <p className="txt-4--regular-db">
                              Descuento despacho{' '}
                              {(
                                clientName || ''
                              ).toUpperCase()}
                            </p>
                            <p className="txt-4--regular-db">
                              -{_formatPrice(deliveryDiscount)}
                            </p>
                          </div>
                        )}
                      </div>
                      {getCurrentDiscount() > 0 && (
                        <div className="d-flex flex-wrap content-checkout-discount content-bg-white-resposive">
                          <div className="d-flex w-100 justify-content-between pt-1 pb-2">
                            <p className="txt-4--bold-dc">
                              {discount?.short_name
                                ? discount.short_name
                                : 'Descuento aplicado'}
                            </p>
                            <p className="txt-4--bold-dc">
                              - {_formatPrice(getCurrentDiscount())}
                            </p>
                          </div>
                        </div>
                      )}
                      <div
                        className="content-checkout-total-price content-bg-white-resposive"
                        style={{borderBottom: 0}}>
                        <div className="d-flex justify-content-between w-100">
                          <h5 className="txt-4--bold-db  w-100">
                            Total a pagar
                          </h5>
                          <h5 className="txt-4--bold-db  ">
                            {_formatPrice(totalCart)}
                          </h5>
                        </div>
                        <ProductSupplementary
                          ref={modalSupplement}
                          commune={props.commune}
                          setHasComplementary={(has) =>
                            setHasComplementary(has)
                          }
                          setComplementaryDelivery={(delivery) =>
                            setComplementaryDelivery(delivery)
                          }
                          closeModal={(payload) =>
                            setComplementaryCartValues(payload)
                          }
                          onCloseModal={clickGenerateLink}
                          clickShowPdf={(pdf) => {
                            setPdf(pdf)
                            setShowPdf(true)
                          }}
                        />
                      </div>
                      {(isSubscription && step >= 2) && (
                        <div className='content-checkout-discount content-bg-white-resposive mb-3'>
                          <div>
                            <h2 className="title-primary--regular mb-2 mt-3">
                              Código de descuento
                              {isLoading && (
                                <img src={loadingYapp} alt="loading" width={30} className='loading-yapp--normal ml-2'/>
                              )}
                            </h2>
                          </div>
                          <div className="">
                            <InputButtonDiscount
                              discount={getCurrentDiscount()}
                              discountCode={discountCode}
                              setDiscountCode={setDiscountCode}
                              error={errorCode && {message: errorCode}}
                              onApply={() => {
                                !discount &&
                                  onUseDiscountCode()
                              }}
                              onRemove={() => setDiscount(null)}
                              titleButton={discountCode && 'Descuento aplicado'}
                            />
                          </div>
                        </div>
                      )}
                      {userInfo && userInfo?.signInMethod !== 'anonymously' && step === 3 && (
                        <div
                        className={
                          'content-checkout-total-price content-bg-white-resposive flex-column hr-bottom-none'
                        }>
                          <hr className="w-100 mx-0 mb-2 mt-3 hr--gray" />
                          <div className="d-flex align-items-center mb-2 mt-2">
                            <p className="txt-4--bold-db mr-2">
                              Método de pago
                            </p>
                            <i
                              data-tip="Para eliminar o modificar tu tarjeta deberás ingresar a la App de Yapp."
                              data-for="tootip-payment-desktop"
                              className="icon-info-2 icon-size tooltip-hover"
                              style={{color: 'var(--theme-color)'}}></i>
                            <ReactTooltip
                              id="tootip-payment-desktop"
                              className="tooltip-style maxw-290"
                              effect="solid"
                              place="bottom"
                              arrowColor="#FFFFFF"
                            />
                            <i
                              data-tip="Para eliminar o modificar tu tarjeta deberás ingresar a la App de Yapp."
                              data-for="tootip-payment-responsive"
                              data-event="click focus"
                              className="icon-info-2 icon-size tooltip-click"
                              style={{color: 'var(--theme-color)'}}></i>
                            <ReactTooltip
                              globalEventOff="click"
                              id="tootip-payment-responsive"
                              className="tooltip-style"
                              effect="solid"
                              place="right"
                              arrowColor="#FFFFFF"
                            />
                          </div>

                          {loading ? (
                            <div className="mt-4 mb-0">
                              {' '}
                              <img
                                src={loadingYapp}
                                alt="loading"
                                className="loading-yapp--alternative m-0"
                              />{' '}
                            </div>
                          ) : (
                            <div>
                              {creditCards.length > 0 && (
                                <SelectPaymentMethod
                                  options={showPaymentOptions()}
                                  value={showDefaultPaymentOption()}
                                  onChange={(option) =>
                                    setPaymentMethod(option.value)
                                  }
                                  placeholder={'Selecciona un método de pago'}
                                  className="tablet-desktop maxw-290"
                                />
                              )}
                              {creditCards.length > 0 && (
                                <div
                                  onClick={() =>
                                    showPaymentOptions()
                                      ? setShowModalPaymentMethod(true)
                                      : null
                                  }
                                  className="btn-open-modal-bottom d-flex align-items-center justify-content-between px-3">
                                  {[null, undefined, false].includes(
                                    selectedPaymentMethod,
                                  ) ? (
                                    <p className="txt-5--bold-g1">
                                      Selecciona un método de pago
                                    </p>
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={selectedPaymentMethod.logo}
                                          className="payment-logo"
                                          alt="Logo método de pago"
                                        />
                                      </div>
                                      <p className="txt-5--bold-db pl-2">
                                        {selectedPaymentMethod.last_four}
                                      </p>
                                    </div>
                                  )}
                                  <i className="icon-chevron-down color-theme"></i>
                                </div>
                              )}
                              <ModalSelectPaymentMethod
                                creditCards={creditCards}
                                setPaymentMethod={setPaymentMethod}
                                showModal={showModalPaymentMethod}
                                setShowModal={setShowModalPaymentMethod}
                              />

                              <div className="d-flex w-100 justify-content-center">
                                <ButtonYapp
                                  onClick={() => addPaymentMethod()}
                                  className={`maxw-214 ${
                                    creditCards.length > 0 ? ' mt-3' : 'mt-0'
                                  }`}
                                  disabled={false}
                                  color="--theme-color"
                                  stroke
                                  small
                                  title={'Agregar método de pago'}
                                />
                              </div>

                              {creditCards.length === 0 && (
                                <p className="txt-6--bold-er mt-1">
                                  * Para continuar con tu compra, necesitamos
                                  que ingreses un método de pago válido.
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {step === 3 && (
                      <>
                        <hr className="w-100 mx-0 mb-4 hr--gray" />
                        <div
                          className={
                            'd-flex align-items-center ' +
                            (errorCheckTerms ? 'mb-10' : ' mb-30')
                          }>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              checked={props.formData.checkTerms.value}
                              onChange={handleCheckTermsChange}
                            />
                            <span className="checkmark">
                              <i className="icon-checkmark"></i>
                            </span>
                          </label>
                          <div>
                            <p className="txt-paragraph line-16">
                              Acepto los{' '}
                              <span className="color-theme c-pointer">
                                <span
                                  onClick={() =>
                                    modalTerms.current.openModal()
                                  }>
                                  Términos &amp; Condiciones
                                </span>
                              </span>
                              { isInsurance ? ', ' : ' y '}
                              <span className="color-theme c-pointer">
                                <span
                                  onClick={() =>
                                    modalPrivacy.current.openModal()
                                  }>
                                  Políticas de privacidad
                                </span>
                              </span>
                              { isInsurance && (
                                <>
                                  {', y '}
                                  <span className="color-theme c-pointer">
                                    <span
                                      onClick={() =>
                                        modalInsurance.current.openModal()
                                      }>
                                      Mandato Compañía de Seguros
                                    </span>
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>

                        {errorCheckTerms && (
                          <div className="mb-30">
                            <p className="txt-6--bold-er mt-1">
                              * Para continuar con tu compra, necesitamos que
                              aceptes los términos y condiciones.
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {step === 3 && (
                      <div className="d-flex w-100 justify-content-center mt-2 mb-4 mb-md-3 mb-lg-0">
                        <ButtonYapp
                          className="px-5"
                          onClick={clickNextAction}
                          id="gtm-webapp-checkout-btn-go-pay"
                          title={isUserYapp ? 'Finalizar compra' : 'Ir a pagar'}
                          color={'--theme-color'}
                          big
                          // disabled={purchase}
                        />
                      </div>
                    )}
                    <ModalLegal
                      src={'https://landing.yapp.cl/terminos_responsivo'}
                      ref={modalTerms}
                    />
                    <ModalLegal
                      src={'https://landing.yapp.cl/privacidad_responsivo'}
                      ref={modalPrivacy}
                    />
                    <ModalLegal
                      insurance={chainBusinessName}
                      ref={modalInsurance}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {clientName === 'Pacientes' && (
        <TypeSidetab
          id='I1p4HO0C'
          buttonColor={'#497AD7'}          
          buttonText={'Sugerencias!'}
        />
      )}
      {clientName === 'Sura' && (
        <TypeSidetab
          id='wea0XraP'
          buttonColor={'#0033A0'}          
          buttonText={'Sugerencias!'}
        />
      )}

      <Application className="" theme={themeDateP}>
        <DatePicker
          ref={inputRef}
          style={{display: 'none'}}
          className="modal-datepicker-custom"
          value={date}
          minDate={minDate}
          onChange={checkDeliveryDate}
          formatStyle="large"
          locale={'es-CL'}
          isCentered={true}
        />
      </Application>

      { toastData && (
        <Toasts
          text={toastData.message}
          timer='5000'
          icon={toastData.icon}
          color={toastData.color}
          position={'bottom'}
          stateToasts={stateToasts}
          setStateToasts={setToast}
        />
      )}
    </div>
  )
}

const themeDateP = {
  rainbow: {
    palette: {
      brand: '#00a19b',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    commune: state.app.commune,
    communes: state.delivery.communes,
    documents_pending: state.user.documents_pending,
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
    config: state.app?.config,
    location: state.app?.location,
    formData: state.app?.formData,
    userData: state.user.copay_user_data,
    userInfo: state.user,
    holidayList: state.app?.holidayList,
    insuranceList: state.app?.insuranceList,
    insurance: state.user?.insurance,
    enabledInsurance: state.app?.enabledInsurance,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFormData: (formData) => dispatch(setFormData(formData)),
    setGoRouter: (router) => dispatch(setGoRouter(router)),
    setInsurance: (insurance) => dispatch(setInsurance(insurance)),
    setUserRefund: (userRefund) => dispatch(setUserRefund(userRefund)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewShoppingBagCheckout)
