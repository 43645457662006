import {useContext, useEffect, useState} from 'react'
import {
  Col,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap'
import '../PharmacyCard/PharmacyCard.css'
import {_formatPrice} from '../../Utils/tools'
import ellipse from '../../assets/img/ic_ellipse.svg'
const _ = require('lodash')

function ExamCard(props) {
  const {keyValue, firstKey, element} = props
  const [isCurrentEventKey, setIsCurrentEventKey] = useState(null)

  function ContextAwareToggle({eventKey, callback}) {
    let total = eventKey.total
    let description = eventKey.description
    eventKey = eventKey.eventKey
    const currentEventKey = useContext(AccordionContext)
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    )

    setIsCurrentEventKey(currentEventKey)

    return (
      <Accordion.Toggle
        onClick={() => decoratedOnClick()}
        as={Col}
        variant="link">
        {total && (
          <div className="d-flex">
            <div className="d-flex align-items-center w-100 content-icon-pharmacy">
              <img src={ellipse} alt="Ellipse" className={'color-theme mr-2'} />
              <p className="txt-paragraph line-14 mt-2 mb-2">{description}</p>
            </div>
            <div className="d-flex align-items-center">
              <p className="txt-paragraph line-14 mt-2 mb-2">
                {_formatPrice(total)}
              </p>
            </div>
          </div>
        )}
      </Accordion.Toggle>
    )
  }

  return (
    <>
      <div className="pharmacy-card pb-2">
        {firstKey === 0 && keyValue === 0 && (
          <div className="alert-price">
            <i className="icon-flag-pharmacy color-theme-secondary pharmacy-flag-best-price" />
            Tu opción más conveniente
          </div>
        )}
        <div className="pharmacy-card--header">
          <div className="pharmacy-card--header-left">
            <img
              src={element.health_center_logo}
              alt={element.health_center_name}
              className="pharmacy-card--header-img"
            />
            <h5 className="pharmacy-card--header-title ">
              {element.health_center_name}
            </h5>
          </div>
        </div>
        {element.total_fonasa && (
          <div className="pharmacy-card--center">
            <Accordion>
              <Card id={`gtm-webapp-quotation-pharmacy-detail${keyValue}`}>
                <Card.Header>
                  <ContextAwareToggle
                    eventKey={{
                      eventKey: keyValue + 1,
                      total: element.total_fonasa,
                      description: 'Total FONASA',
                    }}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey={keyValue + 1}>
                  <Card.Body>
                    {_.orderBy(element.exams, (exam) => exam.name, ['asc']).map(
                      (el, key) => {
                        return (
                          <div className="d-flex">
                            <div className="d-flex align-items-center w-100 content-icon-pharmacy">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {el.name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center ">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {_formatPrice(el.price_fonasa)}
                              </p>
                            </div>
                          </div>
                        )
                      },
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
        {element.total_isapre && (
          <div className="pharmacy-card--center">
            <Accordion>
              <Card id={`gtm-webapp-quotation-pharmacy-detail${keyValue}`}>
                <Card.Header>
                  <ContextAwareToggle
                    eventKey={{
                      eventKey: keyValue + 1,
                      total: element.total_isapre,
                      description: 'Total Isapre',
                    }}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey={keyValue + 1}>
                  <Card.Body>
                    {_.orderBy(element.exams, (exam) => exam.name, ['asc']).map(
                      (el, key) => {
                        return (
                          <div className="d-flex">
                            <div className="d-flex align-items-center w-100 content-icon-pharmacy">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {el.name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center ">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {_formatPrice(el.price_isapre)}
                              </p>
                            </div>
                          </div>
                        )
                      },
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
        {element.total_particular && (
          <div className="pharmacy-card--center">
            <Accordion>
              <Card id={`gtm-webapp-quotation-pharmacy-detail${keyValue}`}>
                <Card.Header>
                  <ContextAwareToggle
                    eventKey={{
                      eventKey: keyValue + 1,
                      total: element.total_particular,
                      description: 'Total Particular',
                    }}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey={keyValue + 1}>
                  <Card.Body>
                    {_.orderBy(element.exams, (exam) => exam.name, ['asc']).map(
                      (el, key) => {
                        return (
                          <div className="d-flex">
                            <div className="d-flex align-items-center w-100 content-icon-pharmacy">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {el.name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center ">
                              <p className="txt-paragraph line-14 mt-2 mb-2">
                                {_formatPrice(el.price_particular)}
                              </p>
                            </div>
                          </div>
                        )
                      },
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
      </div>
    </>
  )
}

export default ExamCard
