function EmptyView({ title, children, image, extraInfo, type }) {
  return (
    <>
      <div className="product-details-content mt-2 mb-5 d-flex flex-column justify-content-center align-items-center">
        {image && type === 1 && (
          <div className="d-flex justify-content-center align-items-center">
            <img src={image} alt="" />
          </div>
        )}
        {title && (
          <div className={`text-center maxw-584 txt-1--bold-db ${type === 1 ? 'my-3' : ''}`}>
            {children}
          </div>
        )}
        {image && type !== 1 && (
          <div className="d-flex justify-content-center align-items-center">
            <img src={image} alt="" />
          </div>
        )}
        {extraInfo && (
          <div className="text-center txt-3--light-db maxw-290 font--16">
            <p>{extraInfo}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default EmptyView;