const _get = require('lodash/get')

export const getLocalData = () => {
  let current = localStorage.getItem('LOCAL_DATA')

  try {
    let currentParse = JSON.parse(current)
    return {
      hash: _get(currentParse, 'hash'),
      client_id: process.env.REACT_APP_CLIENT_ID,
      config_site: _get(currentParse, 'site.config[0].config'),
      info_site: _get(currentParse, 'site.info'),
      client_config: _get(currentParse, 'client_config'),
    }
  } catch (error) {
    console.log('Error al obtener llave en storage')
  }
}

export const getKeyObject = (item, key) => {
  let current = localStorage.getItem(item)

  try {
    let newCurrent = JSON.parse(current)
    return _get(newCurrent, key)
  } catch (error) {
    console.log('Error al obtener llave en storage')
  }
}

export const addKey = (item, key, value) => {
  let current = localStorage.getItem(item)

  try {
    let newCurrent = JSON.parse(current)
    newCurrent[key] = value
    localStorage.setItem(item, JSON.stringify(newCurrent))
  } catch (error) {
    console.log('Error al crear llave en storage')
    localStorage.setItem(item, JSON.stringify(current))
  }
}

export const removeKey = (item, key) => {
  let current = localStorage.getItem(item)

  try {
    let newCurrent = JSON.parse(current)
    delete newCurrent[key]
    localStorage.setItem(item, JSON.stringify(newCurrent))
  } catch (error) {
    console.log('Error al crear llave en storage')
    localStorage.setItem(item, JSON.stringify(current))
  }
}
