import React, {useEffect, useState} from 'react'
import './Toasts.css'

function Toasts(props) {
  const {stateToasts, setStateToasts, color, position} = props
  const [currentStyle, setCurrentStyle] = useState({})

  useEffect(() => {
    setStyle()
    setTimeout(
      () => {
        hideToasts()
      },
      props.timer ? props.timer : 4000,
    )
  }, [stateToasts])

  function hideToasts() {
    setStateToasts(false)
  }

  function setStyle() {
    let currentStyle = {}

    if (color)
      currentStyle.backgroundColor = color
    
    if (position == 'bottom') {
      currentStyle.bottom = '80px'
      currentStyle.top = 'auto'
    }

    setCurrentStyle(currentStyle)
  }

  return (
    <div
      style={currentStyle}
      className={`content-toasts z-index-99 ${stateToasts ? 'show' : 'hide'}`}>
      <h3 className="txt-5--bold m-0">{props.text}</h3>
      <i className={props.icon}></i>
    </div>
  )
}

export default Toasts
