import React from 'react'
import Modal from 'react-modal'
import icErrorServer from '../../assets/img/error_server.svg'
import icErrorInternet from '../../assets/img/error_internet.svg'
import icAlert from '../../assets/img/ic_alert.svg'
import icAlertError from '../../assets/img/alert-error.svg'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import Remover from '../../assets/img/remover.svg'
function ModalServerError(props) {
  const {isOpen, setIsOpen, imgName, title, text, btnText, className, onClick} = props

  function closeModal () {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom maxw-380"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}>
      <a onClick={closeModal}>
        <img src={Remover} className="icon-remover" alt="Cerrar" />
      </a>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src={
            imgName === 'icErrorServer'
              ? icErrorServer
              : imgName === 'icErrorInternet'
              ? icErrorInternet
              : imgName === 'icAlertError'
              ? icAlertError
              : icAlert
          }
          alt="Error"
          className="mb-3 "
        />
        <h4 className="txt-1--bold-db">{title}</h4>
        <p
          className={`txt-1--regular text-center mb-30 ${className}`}>
          {text}
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <ButtonYapp
          className="btn-default--small btn-default txt-3--regular-se size-content-responsive-btn mx-auto w-100 mb-2"
          onClick={closeModal}
          title={btnText}
        />
      </div>
    </Modal>
  )
}

export default ModalServerError
