import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import MyShoppingCardOrder from '../../Components/Card/MyShoppingCardOrder'
import { trackingInfoByUid } from '../../Utils/api'
import { _formatPrice } from '../../Utils/tools'
import { setPaymentLogo } from '../../Utils/utils'
import { pharmacyChainUrl } from '../../Utils/constants'

import loadingYapp from '../../assets/img/yapp_loading.gif'
import BgHeaderTracking from '../../assets/img/bg_card_tracking_header.svg'
import IcTrackingRechazo from '../../assets/img/ic_tracking_rechazo.svg'
import icTrackingCancelado from '../../assets/img/ic_tracking_cancelado.svg'
import icTrackingErrorPago from '../../assets/img/ic_tracking_error_pago.svg'
import { changeLogo, showLogo, setThemeColor } from '../../redux/modules/app'


function ExternalTracking(props) {
  const history = useHistory()
  const { subscription } = useParams()
  const requestType = 'subscription'

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [subscriptionInfo, setSubscriptionInfo] = useState(null)

  useEffect(async () => {
    let subscriptionInfo = null

    if (subscription) {
      subscriptionInfo = await trackingInfoByUid(requestType, subscription)
      if (subscriptionInfo?.product_list && subscriptionInfo?.purchase_orders) {
        subscriptionInfo.product_list = JSON.parse(subscriptionInfo.product_list)
        subscriptionInfo.purchase_orders = JSON.parse(subscriptionInfo.purchase_orders)
      }
      showTrackingTheme(subscriptionInfo)
    }

    setSubscriptionInfo(subscriptionInfo)
    setLoading(false)
  }, [])

  const showTrackingTheme = (info) => {
    if (process.env.REACT_APP_DISABLED_THEME_CLIENT === 'TRUE') {
      dispatch(changeLogo(null))
      dispatch(showLogo(false))
      dispatch(
        setThemeColor({
          primary: '#6578FF',
          secondary: '#4DBAB6',
        })
      )
    } else {
      if (info?.logo === undefined) {
        dispatch(showLogo(false))
      } else {
        dispatch(showLogo(true))
        dispatch(changeLogo(info?.logo))
      }
    }
  }

  const onClickPurchaseOrder = (purchaseOrder) => {
    if (purchaseOrder?.hash) {
      history.push('../purchase-order/' + purchaseOrder.hash)
    }
  }

  return (
    <div className="wrapper-location mt-2 position-relative">
      { loading
        ? <img
            src={loadingYapp}
            alt="loading"
            className="loading-yapp--alternative m-0"
          />
        : 
          <div className="flex-grow-1 px-3 py-4">
            { subscriptionInfo
              ? <div className="flex-grow-1 px-3 pb-4">
                  <div className="content-responsive mx-auto h-100">
                    <div className="content-msd-wrapper">
                      <div className="content-msd-left">
                        <div className="mb-3">
                          <h3 className="txt-2--bold-db m-0">
                            { subscriptionInfo.type === 'convenio'
                              ? 'Convenio '
                              : 'Programación '}{' '}
                            nº {subscriptionInfo.id}
                          </h3>
                          <p className="txt-6--bold-db m-0">
                            Estado: {subscriptionInfo.status}
                          </p>
                        </div>
                        <div className="d-flex flex-column flex-lg-row w-100">
                          <div className="w-100 w-mlg-50">
                            <div className="mb-3">
                              <h6 className="txt-6--bold-g1 m-0 mb-1">Tu pedido</h6>
                              {subscriptionInfo?.product_list?.map((p, index) => (
                                <p className="txt-4--bold-db m-0 " key={ index }>
                                  {p.quantity + ' x ' + p.name}
                                </p>
                              ))}
                            </div>
                            { subscriptionInfo.customer_name &&
                              <div className="mb-3">
                                <h6 className="txt-6--bold-g1 m-0 mb-1">
                                  Nombre y apellido
                                </h6>
                                <p className="txt-4--regular-db m-0 ">
                                  {subscriptionInfo.customer_name}
                                </p>
                              </div>
                            }
                            { subscriptionInfo.customer_tax_id &&
                              <div className="mb-3">
                                <h6 className="txt-6--bold-g1 m-0 mb-1">RUT</h6>
                                <p className="txt-4--regular-db m-0 ">
                                  {subscriptionInfo.customer_tax_id}
                                </p>
                              </div>
                            }
                            { subscriptionInfo.customer_phone &&
                              <div className="mb-3">
                                <h6 className="txt-6--bold-g1 m-0 mb-1">N° de contacto</h6>
                                <p className="txt-4--regular-db m-0 ">
                                  {subscriptionInfo.customer_phone}
                                </p>
                              </div>
                            }
                            { subscriptionInfo.address &&
                              <div className="mb-3">
                                <h6 className="txt-6--bold-g1 m-0 mb-1">
                                  Dirección de entrega
                                </h6>
                                <p className="txt-4--regular-db m-0 ">
                                  {subscriptionInfo.address}
                                </p>
                              </div>
                            }
                            {/* <div className="mb-3">
                              <h6 className="txt-6--bold-g1 m-0 mb-1">
                                Nº depto o Nº casa (opcional)
                              </h6>
                              <p className="txt-4--regular-db m-0 ">Casa 3B</p>
                            </div> */}
                          </div>
                          <div className="w-100 w-lg-50">
                            <div className="mb-3">
                              <h6 className="txt-6--bold-g1 m-0 mb-1">
                                Frecuencia de entrega
                              </h6>
                              <p className="txt-4--regular-db m-0 ">
                                {subscriptionInfo?.delivery_frequency}
                              </p>
                            </div>
                            <div className="mb-3">
                              <h6 className="txt-6--bold-g1 m-0 mb-1">
                                Horario de entrega
                              </h6>
                              <p className="txt-4--regular-db m-0 ">
                                {subscriptionInfo?.delivery_hours}
                              </p>
                            </div>
                            <div className="mb-3">
                              <h6 className="txt-6--bold-g1 m-0 mb-1">Método de pago</h6>
                              <div className="d-flex align-items-center">
                                <img
                                  style={{background: '#ccc'}}
                                  src={ setPaymentLogo(subscriptionInfo?.payment_type) }
                                  height="26"
                                  width="42"
                                />
                                <p className="txt-4--regular-db m-0 ml-2">
                                  {subscriptionInfo?.payment_card}
                                </p>
                              </div>
                            </div>
                            {subscriptionInfo?.prescriptions?.length > 0 && (
                              <div className="mb-3">
                                <h6 className="txt-6--bold-g1 m-0 mb-1">
                                  Recetas médicas
                                </h6>
                                <div>
                                  {subscriptionInfo?.prescriptions?.map((p) => (
                                    <img
                                      style={{background: '#ccc'}}
                                      src={p}
                                      alt=""
                                      height="49"
                                      width="34"
                                    />
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      { subscriptionInfo.purchase_orders?.length > 0 ? (
                        <div className="content-msd-right">
                          <h3 className="txt-2--bold-db m-0 mb-3">Próxima compra</h3>
                          <div className="mb-2">
                            <MyShoppingCardOrder
                              className="mb-2"
                              doAction={() => onClickPurchaseOrder(subscriptionInfo.purchase_orders[0])}
                              data={subscriptionInfo.purchase_orders[0]}
                            />
                          </div>
                          { subscriptionInfo.purchase_orders?.length > 1 && (
                            <>
                              <h3 className="txt-2--bold-db m-0 mb-3">
                                Compras anteriores
                              </h3>
                              <div>
                                {subscriptionInfo.purchase_orders
                                  .slice(1, subscriptionInfo.purchase_orders.length)
                                  .map((item, key) => {
                                    return (
                                      <MyShoppingCardOrder
                                        key={key}
                                        doAction={() => onClickPurchaseOrder(item)}
                                        className="mb-2"
                                        data={item}
                                      />
                                    )
                                  })}
                              </div>
                            </>
                          )}{' '}
                        </div>
                      ) : (
                        <div className="content-msd-right">
                          <h3 className="txt-2--bold-db m-0 mb-3">Próxima compra</h3>
                          <div className="mb-2">
                            <MyShoppingCardOrder
                              className="mb-2"
                              doAction={() => onClickPurchaseOrder(subscriptionInfo.purchase_orders[0])}
                              data={{
                                noData: true,
                                orderDeliveryDate: subscriptionInfo?.delivery_date,
                                orderTotal: subscriptionInfo?.total,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              : <div className="content-responsive mx-auto h-100">
                  <div className="content-light-wrapper">
                    <div className="content-light-wrapper-header" style={{backgroundImage: `url(${BgHeaderTracking})`}}>
                      <h3 className="txt-3--bold-li m-0">¡Sigue tu pedido!</h3>
                    </div>
                    <div className="content-light-wrapper-body">
                      <div className="text-center pt-2 mx-auto maxw-214">
                        <img src={icTrackingCancelado} alt="" />
                        <h3 className="txt-2--regular-db m-0 mb-2">
                          Hemos tenido un problema al cargar la información de tu suscripción
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
      }
    </div>
  )
}

export default ExternalTracking
