import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

function LegalList(props) {
  const history = useHistory()

  useEffect(() => {}, [])

  function clickElement(item) {
    if (item.link) {
      return window.open(item.link)
    } else if (item.phone) {
      return
    } else {
      history.push('/legal/' + item?.name, {item})
    }
  }

  function onLoad(info) {
    // console.log(info.target.parentElement.querySelector('iframe'))
  }

  return (
    <>
      {props.data && !props.data?.link ? (
        <div className="wrapper-legal">
          {props.title ? (
            <h4 className="title-primary">{props.title}</h4>
          ) : null}
          <ul className="content-legal mb-0">
            {props.data?.items &&
              props.data?.items.map((item, key) => (
                <li
                  className="content-legal-item"
                  key={key}
                  onClick={() => clickElement(item)}>
                  <div className="content-legal-left">
                    {item.image && (
                      <div className="">
                        <img src={item.image} className="content-legal-image" />
                      </div>
                    )}
                    <div className="txt-paragraph text-color--secondary">
                      {item.name && item.phone ? (
                        <a
                          className='txt-paragraph text-color--secondary' 
                          href={`tel: ${item.phone}`}>
                          {`${item.name} (${item.phone})`}
                        </a>
                      ) : (
                        item.name
                      )}
                    </div>
                  </div>
                  <div className="content-legal-right">
                    <i className="icon-chevron-right color-theme"></i>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ) : (
        <>
          {/** <iframe onLoad={(info) => onLoad(info)} src={props.data?.link} className='content-webview' /> */}
        </>
      )}
    </>
  )
}

export default LegalList
