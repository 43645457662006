import React from 'react'
import Modal from 'react-modal'
import imgModalLogin from '../../assets/img/img_modal_login.svg'
import ButtonYapp from '../Buttons/ButtonYapp'
import { useHistory } from 'react-router-dom'
import { logEvent } from '../../Utils/utils'


function ModalLogin(props) {

  const history = useHistory()

  const { modalLoginVisible, setModalLoginVisible } = props

  const closeModalLogin = () => {
    setModalLoginVisible(false)
  }

  function goTo(page) {
    if (page === 'register') {
      logEvent('Create Account Started')
      history.push('/register', { requiredValidate: true })
    } else {
      history.push('/signin', { requiredValidate: true })
    }
  }

  return (
    <Modal
      isOpen={modalLoginVisible}
      onAfterOpen={null}
      onRequestClose={closeModalLogin}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}>
      <button className="btn-circle-close" onClick={closeModalLogin}>
        <i className="icon-close"></i>
      </button>
      <div className='d-flex flex-column justify-content-center'>
        <img src={imgModalLogin} alt='Login' className='text-center mb-3 mx-auto' width='196 ' />
        <h3 className='txt-3--light-db mb-3 mt-2 text-center mx-auto'>Para continuar con tu compra crea una cuenta o inicia sesión.</h3>
        <ButtonYapp
          id={`gtm-webapp-btn-modal-login`}
          className='mt-2 mb-3'
          onClick={() => goTo('register')}
          title={'Crear cuenta'}
        />
        <p className='txt-3--regular-db mb-2 mt-2 text-center'>¿Ya tienes una cuenta en Yapp?</p>
        <ButtonYapp
          id={`gtm-webapp-btn-modal-login`}
          onClick={() => goTo('signin')}
          stroke
          title={'Iniciar sesión'}
        />
      </div>

    </Modal>
  )
}

export default ModalLogin
