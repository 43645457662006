import React, {useEffect, useRef} from 'react'

if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
  import('./StylesUC.css').then((ignore) => {})
}

function FooterUC() {
  let scrl = useRef()

  useEffect(() => {
    /* console.log(scrl?.current?.offsetWidth, 'scrollLeft') */
  }, [])

  const onScrollLeft = () => {
    /* console.log(scrl?.current?.scrollLeft, 'scrollLeft') */
  }

  const slide = (shift) => {
    scrl.current.scrollLeft += shift
  }

  return (
    <>
    <section>
      <div className="d-lg-none d-xl-none">
        <div className="flex_img_miplan_mobile">
          <div className="footer_mobile">
            <div className="font_footer_categorias">INFORMACIÓN INSTITUCIONAL</div>
            <div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/quienes-somos">Quiénes Somos</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/christus-health">CHRISTUS Health</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/aranceles">Aranceles</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos">Indicadores Clínicos</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/mision-y-responsabilidad-social">Misión y Responsabilidad Social</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" target="_blank " href="https://www.ucchristus.cl/informacion-al-paciente/compliance">Compliance</a>
              </div>
            </div>
            <div className="font_footer_categorias mt-4">BLOG Y CONTENIDO</div>
            <div className=" ">
              <div className="font_tooter_items">
                <a className="uc-footer-link" href="https://www.ucchristus.cl/blog-salud-uc">Blog</a>
              </div>
              <div className="font_tooter_items">
                <a className="uc-footer-link" href="https://www.ucchristus.cl/informacion-al-paciente/podcast-de-salud">Podcast</a>
              </div>
            </div>
            <div className="font_footer_categorias mt-4">CONTACTO</div>
            <div className=" ">
              <div className="font_tooter_items">
                <div className="d-flex align-items-center">
                  <div>
                    <a className="uc-footer-link" href="https://www.ucchristus.cl/contactanos">Contáctanos</a>
                  </div>
                </div>
              </div>
              <div className="font_tooter_items mt-3">
                <div className="d-flex align-items-end">
                  <em style={{ transform: 'scale(0.7)', color: '#2e568a !important'}} className="fas fa-phone-alt fa-2x mr-2"></em>
                  <div>
                    <a data-sf-ec-immutable="">Mesa Central Hospital Clínico</a>
                    <div className="flex_phone align-items-baseline">
                      <div className="font_telefono">
                        <a className="uc-footer-link" href="tel:22 354 3000 " data-sf-ec-immutable="">22 354 3000</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="font_tooter_items mt-3">
                <div className="d-flex align-items-end">
                  <div>
                    <a data-sf-ec-immutable="">Mesa Central Clínica San Carlos</a>
                    <div className="flex_phone align-items-baseline">
                      <div className="font_telefono">
                        <a className="uc-footer-link" href="tel:22 754 8700 " data-sf-ec-immutable="">22 754 8700</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="font_tooter_items mt-3">
                <div className="d-flex align-items-end">
                  <em style={{ transform: 'scale(0.7)', color: '#2e568a !important'}} className="fas fa-phone-alt fa-2x mr-2"></em>
                  <div>
                    <span>Rescate</span>
                    <div className="flex_phone align-items-baseline">
                      <div className="font_telefono">
                        <a className="uc-footer-link" href="tel:800 265 265" data-sf-ec-immutable="">800 265 265</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex_row_redes_mobile mt-5">
                <a target="_blank " href="https://www.facebook.com/UCCHRISTUS "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_facebook.png?sfvrsn=13d56b24_2" alt=" "/></a>
                <a target="_blank " href="https://www.instagram.com/ucchristus/?hl=es-la "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_instagram.png?sfvrsn=fcc8890f_2" alt=" "/></a>
                <a target="_blank " href="https://twitter.com/ucchristus "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_twitter.png?sfvrsn=8724d023_2" alt=" "/></a>
                <a target="_blank " href="https://www.linkedin.com/company/ucchristus/mycompany/ "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_linkind.png?sfvrsn=4190ee36_2" alt=" "/></a>
                <a target="_blank " href="https://www.youtube.com/user/UCCHRISTUS "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_youtube.png?sfvrsn=63a748ad_2" alt=" "/></a>
                <a target="_blank " href="https://open.spotify.com/user/7x5ir8p8a0v5i0qnu1loj3xqi "><img src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_spotify.png?sfvrsn=20b382ad_2" alt=" "/></a>
              </div>
              <div className="flex_img_mobile_footer">
                <div className="flex_img_footer_mobile_home">
                  <div className="text-left mt3 w-100">
                    <img className="img1_size" src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/uc_logo_svg.svg?sfvrsn=8d69fb14_2" alt=" "/>
                    <img className="img2_size" src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/png_logo-christus.png?sfvrsn=c18e0c04_2" alt=" "/><img className="img3_size" src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/fac-medicina-uc.png?sfvrsn=8ab7d2fc_2" alt=" "/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <div className="d-none d-lg-block">
        <img
          width="100% "
          src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/footer_divisor.png?sfvrsn=6177d06f_2"
          alt=" "
        />
        <div className="flex_row fondo_footer">
          <div id="id_marking_desktop_footer_padre" className="div_footer">
            <div id="id_marking_desktop_footer_imagenes" className="footer_col">
              <div className="img1 mb-4">
                <img
                  src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/uc_logo_svg.svg?sfvrsn=8d69fb14_2"
                  alt=" "
                />
              </div>
              <div className="img2">
                <img
                  width="80%"
                  src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/png_logo-christus.png?sfvrsn=c18e0c04_2"
                  alt=" "
                />
              </div>
              <div className="img3">
                <img
                  width="95%"
                  src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/fac-medicina-uc.png?sfvrsn=8ab7d2fc_2"
                  alt=" "
                />
              </div>
            </div>
            <div
              id="id_marking_desktop_footer_informacion_institucional"
              className="footer_col">
              <div className="font_footer_categorias">
                INFORMACIÓN INSTITUCIONAL
              </div>
              <div className=" ">
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/quienes-somos">
                    Quiénes Somos
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/christus-health">
                    CHRISTUS Health
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/aranceles">
                    Aranceles
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos">
                    Indicadores Clínicos
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos">
                    Misión y Responsabilidad Social
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    target="_blank "
                    href="https://www.ucchristus.cl/informacion-al-paciente/compliance">
                    Compliance
                  </a>
                </div>
              </div>
            </div>
            <div
              id="id_marking_desktop_footer_blog_y_contenido"
              className="footer_col">
              <div className="font_footer_categorias">BLOG Y CONTENIDO</div>
              <div className=" ">
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    href="https://www.ucchristus.cl/blog-salud-uc">
                    Blog
                  </a>
                </div>
                <div className="font_tooter_items">
                  <a
                    className="uc-footer-link"
                    href="https://www.ucchristus.cl/informacion-al-paciente/podcast-de-salud">
                    Podcast
                  </a>
                </div>
              </div>
            </div>
            <div id="id_marking_desktop_footer_contacto" className="footer_col">
              <div className="font_footer_categorias">CONTACTO</div>
              <div className=" ">
                <div className="font_tooter_items">
                  <div className="d-flex align-items-center">
                    <em className="far fa-envelope fa-2x mr-2"></em>
                    <div>
                      <a
                        className="uc-footer-link"
                        href="https://www.ucchristus.cl/contactanos">
                        Contáctanos
                      </a>
                    </div>
                  </div>
                </div>
                <div className="font_tooter_items">
                  <div className="d-flex align-items-end">
                    <em
                      style={{ transform: 'scale(0.7)', color: '#2e568a !important'}}
                      className="fas fa-phone-alt fa-2x mr-2"></em>
                    <div>
                      <a data-sf-ec-immutable="">
                        Mesa Central Hospital Clínico
                      </a>
                      <div className="flex_phone align-items-baseline">
                        <div className="font_telefono">
                          <a
                            className="uc-footer-link"
                            href="tel:22 354 3000 "
                            data-sf-ec-immutable="">
                            22 354 3000
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font_tooter_items">
                  <div className="d-flex align-items-end">
                    <em style={{ transform: 'scale(0.7)', color: '#2e568a !important'}}
                      className="fas fa-phone-alt fa-2x mr-2"></em>
                    <div>
                      <a data-sf-ec-immutable="">Rescate</a>
                      <div className="flex_phone align-items-baseline">
                        <div className="font_telefono">
                          <a
                            className="uc-footer-link"
                            href="tel:800 265 265"
                            data-sf-ec-immutable="">
                            800 265 265
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex_row_redes pt-3">
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://www.facebook.com/UCCHRISTUS ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_facebook.png?sfvrsn=13d56b24_2"
                      alt=" "
                    />
                  </a>
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://www.instagram.com/ucchristus/?hl=es-la ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_instagram.png?sfvrsn=fcc8890f_2"
                      alt=" "
                    />
                  </a>
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://twitter.com/ucchristus ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_twitter.png?sfvrsn=8724d023_2"
                      alt=" "
                    />
                  </a>
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://www.linkedin.com/company/ucchristus/mycompany/ ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_linkind.png?sfvrsn=4190ee36_2"
                      alt=" "
                    />
                  </a>
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://www.youtube.com/user/UCCHRISTUS ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_youtube.png?sfvrsn=63a748ad_2"
                      alt=" "
                    />
                  </a>
                  <a
                    className="uc-footer-link uc-footer-link-rs"
                    target="_blank "
                    href="https://open.spotify.com/user/7x5ir8p8a0v5i0qnu1loj3xqi ">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/footer_spotify.png?sfvrsn=20b382ad_2"
                      alt=" "
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterUC
