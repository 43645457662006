import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import LoadingScreen from 'react-loading-screen'
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap'
import ImageUploader from 'react-images-upload'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete'

import {addKey} from '../../Utils/storage'
import {sendToPay} from '../../Utils/api'

import imgPrescription from '../../assets/img/prescription.png'

const _ = require('lodash')

function AddPrescription() {
  const location = useLocation()

  const [toPayment, setToPayment] = useState(false)
  const [imgLoaded, setImage] = useState(null)
  const [locationInput, setLocation] = useState(null)
  const [formData, setFormData] = useState({
    name: null,
    phone: null,
    email: null,
    location: {},
  })

  async function clickSendToPay() {
    try {
      setToPayment(true)
      let payload = _.get(location.state, 'payload')
      let localData = JSON.parse(localStorage.getItem('LOCAL_DATA'))
      let hash = _.get(localData, 'hash')
      const returnUrl = `${window.location.href.replace(
        location.pathname,
        '',
      )}/result/checkout/${hash}`
      let respPay = await sendToPay(
        payload.amount + payload.delivery.cost,
        returnUrl,
      )
      addKey('LOCAL_DATA', 'paymentToken', respPay.data?.token)
      addKey('LOCAL_DATA', 'paymentElement', payload)
      window.location.href = respPay.data?.trx_response?.redirectUrl
    } catch (error) {
      console.log(error)
    }
  }

  function setLocationFromInput(el) {
    geocodeByAddress(el.label).then((res) => {
      let lat = res[0].geometry.location.lat()
      let lng = res[0].geometry.location.lng()
      let item = {
        label: el.label,
        lat: lat,
        lng: lng,
      }

      setFormData({...formData, location: item})
    })
  }

  function onDrop(files, pictures) {
    setImage(pictures)
  }

  return (
    <>
      <Container className="card p-5">
        <Row className="mt-3 mb-3">
          <Col>
            <p className="txt-title">Datos de entrega</p>
            <p className="txt-black">
              Necesitamos algunos datos para continuar
            </p>
          </Col>
        </Row>
        <Col>
          <Form>
            <Form.Group>
              <Form.Label className="txt-black">Nombre:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa tu nombre"
                value={formData.name}
                onChange={(e) => {
                  setFormData({...formData, name: e.target.value})
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="txt-black">Nº de contacto:</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Ingresa tu nº de contacto"
                value={formData.phone}
                onChange={(e) => {
                  setFormData({...formData, phone: e.target.value})
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="txt-black">Correo:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingresa tu Correo"
                value={formData.email}
                onChange={(e) => {
                  setFormData({...formData, email: e.target.value})
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="txt-black">
                Dirección de entrega:
              </Form.Label>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw"
                apiOptions={{language: 'es-419', region: 'CL'}}
                selectProps={{
                  locationInput,
                  placeholder: 'Ingrese su dirección...',
                  onChange: setLocationFromInput,
                }}
              />
            </Form.Group>
          </Form>
          <p className="txt-black">Receta médica</p>
        </Col>
        <Col className="text-center">
          <Card className="w-100 mb-3">
            <Card.Body>
              <Col>
                <div>
                  {!imgLoaded ? (
                    <img
                      src={imgPrescription}
                      alt=""
                      className="m-3"
                      style={{maxWidth: '15rem'}}
                    />
                  ) : (
                    <img
                      src={imgLoaded[0]}
                      alt=""
                      className="m-3"
                      style={{maxWidth: '15rem'}}
                    />
                  )}
                </div>
                <ImageUploader
                  withIcon={true}
                  buttonText="Elegir imagen"
                  onChange={onDrop}
                  label={'Seleccionar receta médica.'}
                  imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  singleImage={true}
                />
              </Col>
            </Card.Body>
          </Card>
        </Col>
        <Col className="text-center">
          <Button
            className="btn-classic"
            variant="primary"
            onClick={clickSendToPay}>
            <Container>
              <Row>
                <Col className="txt-bold" sm={12}>
                  Pagar
                </Col>
              </Row>
            </Container>
          </Button>
        </Col>
      </Container>
      {toPayment && (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          text="Redirigiendo al medio de pago"></LoadingScreen>
      )}
    </>
  )
}

export default AddPrescription
