import {useDispatch} from 'react-redux'
import imgError from '../../assets/img/i_error404.svg'
import {changeInitialLoading, changeNotFound} from '../../redux/modules/app'

function NotFound() {
  const dispatch = useDispatch()
  dispatch(changeNotFound(true))
  dispatch(changeInitialLoading(false))
  return (
    <div className="wrapper-location">
      <h4 className="title-error text-center">Lo sentimos</h4>
      <div className="d-flex justify-content-center mb-3 ">
        <img src={imgError} className="img-error404" alt="logo" />
      </div>
      <p className="txt-paragraph line-16 text-center">
        La página que busca no existe.
      </p>
    </div>
  )
}

export default NotFound
