import {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import Modal from 'react-modal'
import {_formatRut, _validateRut, _validateMail} from '../../Utils/tools'
import {
  requestUnique,
  existProfessional,
  sendDoctorVerificationEmail,
} from '../../Utils/api'

import minsalUso from '../../assets/img/minsal-uso-racional.png'
import minsalVenta from '../../assets/img/minsal_ventas_directas.png'

import ModalWelcome from '../../Components/Modals/ModalWelcome'

import loadingYapp from '../../assets/img/yapp_loading.gif'
// import IcCotizar from '../../assets/img/cotizar_activado.svg'
import IcInteracciones from '../../assets/img/interacciones_activado.svg'
import IcProgramaPaciente from '../../assets/img/ic_programa_paciente.svg'
import IcMedicosFailet from '../../assets/img/medicos_validación_fallida.svg'
import IcMedicosSendMail from '../../assets/img/send_mail.svg'
import IcPricePharmacy from '../../assets/img/ic_precio_farmacia.svg'
import {changeInitialLoading, setHash, setIsPro} from '../../redux/modules/app'
import {getCampaign} from '../../Utils/service'
import {setUserPro} from '../../redux/modules/user'
import {addEvent, logEvent} from '../../Utils/utils'
import ModalReportedAccount from '../../Components/Modals/ModalReportedAccount'

const _ = require('lodash')

function HomePro(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = new URLSearchParams(location.search)
  const authState = useSelector((state) => state.app.sign_in)
  const campaign = params.get('campaign')
  const product = params.get('product')
  const {hash} = useParams()
  const history = useHistory()

  const [modalWelcomeIsOpen, setWelcomeIsOpen] = useState(false)
  const [objEmail, setEmail] = useState({email: '', valid: false})
  const [objIdentity, setIdentity] = useState({identity: '', valid: false})
  const [sending, setSending] = useState(false)
  const [campaignInfo, setCampaignInfo] = useState(null)
  const [sendEmailStatus, setSendEmailStatus] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [msgErrorModal, setMsgErrorModal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalReportedState, setModalReportedState] = useState(false)

  const [modalErrorIsOpen, setErrorIsOpen] = useState(false)
  const [modalSendMailIsOpen, setSendMailIsOpen] = useState(false)

  useEffect(async () => {
    let uniqueHash = hash ? hash : process.env.REACT_APP_UNIQUE_HASH
    dispatch(setHash(uniqueHash))
    if (campaign) {
      let data = await getCampaign(campaign)
      setCampaignInfo(data)
      if (data) setWelcomeIsOpen(true)
    }

    try {
      localStorage.clear()
      sessionStorage.clear()
    } catch (error) {
      console.log(error)
    }

    let result = await requestUnique(uniqueHash)
    if (!result) {
      return history.push('/no-access')
    }

    localStorage.setItem('client_id', result?.data?.config[0].client_id)

    if (product) localStorage.setItem('product', product)
    let isProfessional = result?.data?.config[0].config?.professional
    if (isProfessional) {
      dispatch(setIsPro(result?.data?.config[0].config?.professional))
    } else {
      dispatch(setIsPro(null))
    }
    if (product) localStorage.setItem('product', product)
    localStorage.setItem('hash', uniqueHash)
    /* setModalReportedState(true) */
  }, [])

  function validEmail(e) {
    setEmail({
      email: e.target.value || '',
      valid: _validateMail(e.target.value),
    })
  }

  async function nextAction() {
    let uniqueHash = hash ? hash : process.env.REACT_APP_UNIQUE_HASH
    setSending(true)
    dispatch(changeInitialLoading(true))
    let exist = await existProfessional(objIdentity.identity, objEmail.email)
    if (exist?.data?.status === 'success') {
      dispatch(changeInitialLoading(false))
      dispatch(setUserPro(true))
      return history.push('/pro/success', {
        hash: uniqueHash,
        email: objEmail.email,
        identity: objIdentity.identity,
        user_type: exist?.data?.user_type,
      })
    } else {
      dispatch(changeInitialLoading(false))
      setErrorIsOpen(true)
      dataLayerFn()
      dispatch(setUserPro(false))
    }
  }

  function dataLayerFn() {
    addEvent('error_validating_user')
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      nextAction()
      e.preventDefault()
    }
  }

  function closeModalError() {
    setErrorIsOpen(false)
    setSending(false)
    setShowErrorModal(false)
  }

  function closeModalSendMail() {
    setSendMailIsOpen(false)
    setSending(false)
    setShowErrorModal(false)
  }

  function onChangeIdentity(identity) {
    let format = _formatRut(identity)
    let rutValidation = _validateRut(identity)
    setIdentity({identity: format || identity, valid: rutValidation})
  }

  async function sendVerificationEmail() {
    setLoading(true)
    if (_validateMail(objEmail.email)) {
      const rut = objIdentity.identity.replace('.', '')
      const template = 'd-a636c0cc7d264447af51caff1ae2b43a'
      const sender = 'soportemedicos@yapp.cl'
      const userEmail = objEmail.email
      const result = await sendDoctorVerificationEmail(
        template,
        sender,
        userEmail,
        rut,
      )
      try {
        if (result.status === 204) {
          closeModalError()
          setSendMailIsOpen(true)
        } else {
          let response = await result?.json()
          if (response?.data?.status === 'error') {
            setShowErrorModal(true)
            setMsgErrorModal('Error al enviar correo.')
          } else {
            setSendEmailStatus(true)
          }
        }
      } catch (error) {
        setShowErrorModal(true)
      }
    } else {
      setShowErrorModal(true)
    }
    setLoading(false)
  }

  function goTo(page) {
    if (page === 'register') {
      history.push('/pro/register_validate', {hasOrigin: true})
    } else {
      history.push('/pro/login', {hasOrigin: true})
    }
  }
  function openContactMail(isProfessional) {
    logEvent('Yapp Contacted', {'Is Medical': isProfessional})
    document.location = isProfessional
      ? 'mailto:soportemedicos@yapp.cl'
      : 'mailto:soporte@yapp.cl'
  }

  return (
    <>
      <ModalReportedAccount
        modalReportedState={modalReportedState}
        setModalReportedState={() => setModalReportedState()}
      />
      <Modal
        isOpen={modalErrorIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalError}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          {loading ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--alternative m-0"
              />
            </div>
          ) : (
            <div>
              {showErrorModal ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    id="gtm-webapp-pro-home-mail-failed-img"
                    src={IcMedicosSendMail}
                    alt="Failed"
                    className="mb-3 w-100 maxw-80"
                  />
                  <h2 className="title-primary--xxl text-center">
                    {msgErrorModal
                      ? msgErrorModal
                      : '¡Error, demasiados intentos!'}
                  </h2>
                  <h2 className="title-primary--light mb-2 text-center">
                    Contáctate con{' '}
                    <a
                      id="gtm-webapp-home-pro-emailto-validate"
                      onClick={() => openContactMail(true)}
                      className="color-secondary title-primary--light">
                      soportemedicos@yapp.cl
                    </a>
                  </h2>
                  <div className="mx-auto mw-180">
                    <button
                      className="btn-default w-100 btn-next mt-3 mb-3"
                      onClick={closeModalError}>
                      Volver
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="title-primary--3xl-regular mb-3 text-center">
                    No hemos podido validarte como médico
                  </h2>
                  <img
                    id="gtm-webapp-pro-home-validation-failed-img"
                    src={IcMedicosFailet}
                    alt="Failed"
                    className="mb-3 w-100"
                  />
                  <div className="mx-auto">
                    <button
                      id="gtm-webapp-home-pro-btn-error-try-again"
                      className="btn-default w-100 btn-next mt-3"
                      onClick={closeModalError}>
                      Reintentar
                    </button>
                    {/* <button
                      id="gtm-webapp-home-pro-btn-error-email-validate"
                      className="btn-default w-100 btn-stroke-lg btn-next mb-4 mt-3"
                      onClick={() => sendVerificationEmail()}>
                      Validarme por correo
                    </button> */}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={modalSendMailIsOpen}
        onAfterOpen={null}
        onRequestClose={closeModalSendMail}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            id="gtm-webapp-pro-home-mail-img"
            src={IcMedicosSendMail}
            alt="Failed"
            className="mb-3 w-100 maxw-80"
          />
          <h2 className="title-primary--xxl text-center">
            ¡Te hemos enviado un correo!
          </h2>
          <h2 className="title-primary--light mb-2 text-center">
            Revisa tu correo {objEmail.email}, te hemos enviado las
            instrucciones para validarte como médico desde{' '}
            <a
              id="gtm-webapp-home-pro-emailto-validate"
              onClick={() => openContactMail(true)}
              className="color-secondary title-primary--light">
              soportemedicos@yapp.cl
            </a>
          </h2>
          <div className="mx-auto mw-180">
            <button
              className="btn-default w-100 btn-next mt-3 mb-3"
              onClick={closeModalSendMail}>
              ¡Genial!
            </button>
          </div>
        </div>
      </Modal>
      {/* Mensaje Marketing */}
      <ModalWelcome
        modalWelcomeIsOpen={modalWelcomeIsOpen}
        setWelcomeIsOpen={setWelcomeIsOpen}
        title={campaignInfo?.title}
        message={campaignInfo?.message}
      />
      <div className="flex-grow-1 bg-home-pro">
        <div className="content-home content-home-pro mb-4">
          <div className="px-3 px-md-0">
            <div className="maxw-300 mx-auto">
              <h2 className="title-primary--3xl text-center">¿Eres médico?</h2>
              <p className="title-primary--regular text-center mb-20">
                ¡Encuentra todo sobre medicamentos!
              </p>
            </div>
            <div className="content-info-home">
              <div className="content-info-home-item">
                <img
                  src={IcPricePharmacy}
                  className="mb-2 mr-3 ic-normalize-home "
                />
                <div>
                  <h3 className="txt-paragraph--bold">Buscador de precios</h3>
                  <p className="txt-paragraph--small line-14">
                    Ayuda a tus pacientes a encontrar los mejores precios en
                    farmacias.
                  </p>
                </div>
              </div>
              <div className="content-info-home-item">
                <img
                  src={IcProgramaPaciente}
                  className="mb-2 mr-3 ic-normalize-home "
                />
                <div>
                  <h3 className="txt-paragraph--bold">Programas de paciente</h3>
                  <p className="txt-paragraph--small line-14">
                    Ayuda a tus pacientes a continuar sus tratamientos con los
                    mejores beneficios.
                  </p>
                </div>
              </div>
              <div className="content-info-home-item">
                <img
                  src={IcInteracciones}
                  className="mb-2 mr-1 ic-normalize-home "
                />
                <div>
                  <h3 className="txt-paragraph--bold">
                    Interacciones medicamentosas
                  </h3>
                  <p className="txt-paragraph--small line-14">
                    Evalúa las posibles interacciones antes de prescribir.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="justify-content-center px-3 px-md-0">
            <h4 className="title-primary--regular text-center mb-md-4 pb-md-2">
              Podras hacer todo esto y mucho más...
            </h4>
            <button
              id="gtm-webapp-home-pro-btn-register"
              className="btn-default--small font--14 mx-auto size-content-responsive-btn w-md-100 mb-4"
              onClick={() => goTo('register')}>
              Crear cuenta
            </button>
            <h4 className="title-primary--regular text-center">
              ¿Ya tienes una cuenta en Yapp?
            </h4>
            <button
              id="gtm-webapp-home-pro-btn-login"
              className="btn-default--small btn-stroke font--14 mx-auto size-content-responsive-btn mb-5"
              onClick={() => goTo('login')}>
              Iniciar sesión
            </button>
            {/* <form className='content-form-home mx-auto'>
              <input id='gtm-webapp-home-pro-rut' type='text' className='input-custom-secondary mb-3' placeholder='RUT' value={objIdentity.identity} onChange={(event) => { onChangeIdentity(event.target.value) }} />
              <input id='gtm-webapp-home-pro-email' type='email' className='input-custom-secondary' placeholder='Correo' value={objEmail.email} onChange={validEmail} onKeyDown={onKeyDown} />
              <div className='mt-3 mb-2'>
                <p className='txt-paragraph--small text-center'>*Utilizamos la base de datos de la <span className='txt-line--bold'>Superintendencia de Salud.</span></p>
              </div>
              {
                (!objIdentity.valid && !objEmail.valid && objIdentity.identity?.length > 0 && objEmail.email?.length > 0) ? <p className="text-danger txt-paragraph--small mt-2 ml-2">Rut y correo inválidos</p> :
                  (!objEmail.valid && objEmail.email?.length > 0) ? <p className="text-danger txt-paragraph--small mt-2 ml-2">Correo inválido</p> :
                    (!objIdentity.valid && objIdentity.identity?.length > 0) && <p className="text-danger txt-paragraph--small mt-2 ml-2">rut inválido</p>
              }
              <Button id='gtm-webapp-home-pro-btn-send' disabled={(objEmail.valid ? false : true) || (objIdentity.valid ? false : true) || sending} className="btn-default btn-next maxw-250 mx-auto mb-4 mt-3" variant="primary" onClick={nextAction}>
                Validarme como médico
              </Button>
            </form> */}
          </div>

          <div className="d-flex justify-content-center minsal-img-container mt-2 mt-md-4 px-3 px-md-0">
            <a
              href="https://www.minsal.cl/wp-content/uploads/2016/04/MAGNETO-USO-RACIONAL-DE-MEDICAMENTOS-PARA-IMPRESION.pdf"
              target="_blank">
              <img
                src={minsalUso}
                alt="minsal uso racional"
                className="minsal-img mr-2"
              />
            </a>
            <a
              href="https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf"
              target="_blank">
              <img
                src={minsalVenta}
                alt="minsal venta directa"
                className="minsal-img"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePro
