import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Input from '../../Components/Forms/Input'
import IcForgotPassword from '../../assets/img/forgot_password.svg'
import IcPillEmail from '../../assets/img/i_pill_email.svg'
import ModalForgotPassSendEmail from '../../Components/Modals/ModalForgotPassSendEmail'
import { _validateMail } from '../../Utils/tools'
import { resetPassword } from '../../Utils/auth'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'


function ForgotPassword() {
  const history = useHistory()
  const authState = useSelector((state) => state.app.sign_in)

  const [modalForgotPassSendEmail, setModalForgotPassSendEmail] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [patientElement, setPatientElement] = useState(true)

  useEffect(() => {
    let response = _validateMail(email)
    setEmailError(!response)
  }, [email])

  const sendForgotPasswordEmail = async () => {
    if (_validateMail(email)) {
      try {
        await resetPassword(email)
        setModalForgotPassSendEmail(true)
      } catch (error) {
        setEmailError(true)
      }
    }
  }

  const redirect = () => {
    setModalForgotPassSendEmail(false)
    setTimeout(() => {
      history.push('/signin')
    }, 500)
  }

  return (
    <>
      <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
        <div className="breadcrumbs c-pointer" onClick={() => history.goBack()}>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </a>
          <span className="txt-4--regular-pr">Volver</span>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="maxw-290 mx-auto px-3 px-md-0">
          <h2 className="txt-1--bold-db text-center mb-0">
            Ayuda con la contraseña
          </h2>
          <div className="d-flex flex-column justify-content-center">
            <img
              src={!patientElement ? IcForgotPassword : IcPillEmail}
              alt="Forgot Password"
              className="img-forgot-password"
            />
          </div>
          <p className="txt-4--regular-db text-center mb-4">
            Te enviaremos un link a tu correo para que puedas cambiar tu
            contraseña.
          </p>
          <div className="mb-4 pb-2">
            <Input
              id={"gtm-webapp-forgot-password-email"}
              error={
                email.length > 0 && emailError && { message: '*Email incorrecto' }
              }
              label="Correo electrónico"
              placeholder="Correo electrónico"
              doChange={(value) => setEmail(value)}
            />
          </div>
          <ButtonYapp
            id={'gtm-webapp-forgot-password-btn-send'}
            className='btn-default--small btn-default font--14 size-content-responsive-btn mx-auto w-100 mb-4'
            onClick={() => sendForgotPasswordEmail()}
            title={'Enviar'}
          />
        </div>
      </div>
      <ModalForgotPassSendEmail
        email={email}
        modalForgotPassSendEmail={modalForgotPassSendEmail}
        setModalForgotPassSendEmail={setModalForgotPassSendEmail}
        onClick={redirect}
      />
    </>
  )
}

export default ForgotPassword
