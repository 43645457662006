import {_formatPrice} from '../../Utils/tools'
import InfoCard from '../Card/InfoCard'
import alertIcon from '../../assets/img/alert-icon.svg'
import noRefundIcon from '../../assets/img/no-refund-icon.svg'
import PrescriptionAlert from '../Alerts/PrescriptionAlert'
import ReactTooltip from 'react-tooltip'
import {useSelector} from 'react-redux'

function ProductSummary({
  title,
  buttonText,
  products,
  totalCart,
  onClickAction,
  className,
  clientDiscountBenefits,
  refundElement,
  applyRefund,
  insurancePolicyLimit,
  applyDeductible,
  deductible,
  insuranceError,
  onClickApplyRefund,
  discountRefund,
  modifyCart,
  refund,
  show,
  pressedButton,
  showAlert,
  isSubscription,
  deleteProduct,
}) {
  const enabledInsurance = useSelector((state) => state.app?.enabledInsurance);
  const isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE';
  const insuranceName = isInsurance ? ' ' + process.env.REACT_APP_CLIENT_NAME.toUpperCase() : '';
  const productList = products

  function handleDelete(item) {
    deleteProduct(item)
  }

  return (
    <div
      className={`content-checkout-medication-detail content-bg-white-resposive ${className}`}>
      <h5 className="txt-3--bold-db mt-2 mb-3 w-100">{title}</h5>
      { productList?.map((product, key) => {
        let quantity = product.counter
          ? product.counter
          : product?.quantity
          ? product?.quantity
          : null
        let show = isInsurance || (enabledInsurance && !isSubscription);

        return (
          <div key={key} className="w-100">
            <div className="d-flex w-100 justify-content-between align-items-center mb-1">
              {product.loadedPrescription && show && product?.product?.client_product_id && (
                <>
                  <img
                    src={alertIcon}
                    alt="Alert"
                    data-tip="Agrega tu receta médica para calcular tu reembolso"
                    data-for="tootip-payment-desktop"
                    className="tooltip-hover"
                  />
                  <ReactTooltip
                    id="tootip-payment-desktop"
                    className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                    effect="solid"
                    place="bottom"
                    backgroundColor="#ffffff"
                    textColor="#000000"
                    arrowColor="#FFFFFF"
                  />
                  <img
                    src={alertIcon}
                    alt="Alert"
                    data-tip="Agrega tu receta médica para calcular tu reembolso"
                    data-for="tootip-payment-responsive"
                    data-event="click focus"
                    className="tooltip-click"
                  />
                  <ReactTooltip
                    id="tootip-payment-responsive"
                    globalEventOff="click"
                    className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                    effect="solid"
                    place="bottom"
                    backgroundColor="#ffffff"
                    textColor="#000000"
                    arrowColor="#FFFFFF"
                  />
                </>
              )}

              {show && !product?.product?.client_product_id && (
                <>
                  <img
                    src={noRefundIcon}
                    alt="Producto no reembolsable"
                    data-tip="Este medicamento no es reembolsable a través de Yapp"
                    data-for="tootip-payment-desktop"
                    className="mr-1 tooltip-hover"
                  />
                  <ReactTooltip
                    id="tootip-payment-desktop"
                    className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                    effect="solid"
                    place="bottom"
                    backgroundColor="#ffffff"
                    textColor="#000000"
                    arrowColor="#FFFFFF"
                  />
                  <img
                    src={noRefundIcon}
                    alt="Producto no reembolsable"
                    data-tip="Este medicamento no es reembolsable a través de Yapp"
                    data-for="tootip-payment-responsive"
                    data-event="click focus"
                    className="tooltip-click"
                  />
                  <ReactTooltip
                    id="tootip-payment-responsive"
                    globalEventOff="click"
                    className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                    effect="solid"
                    place="bottom"
                    backgroundColor="#ffffff"
                    textColor="#000000"
                    arrowColor="#FFFFFF"
                  />
                </>
              )}

              <p className="txt-5--regular-db w-100 line-14">{product?.name}</p>
              <div className="d-flex justify-content-between mw-80">
                <p className="txt-5--regular-db mr-2">x{quantity}</p>
                <p className="txt-5--regular-db">{_formatPrice(product.price * quantity)}</p>
              </div>
              <div 
                className='d-flex align-content-center'
                onClick={() => handleDelete(product)}
              >
                <i className='icon-delete ml-2 c-pointer'></i>
              </div>
            </div>
            { (applyRefund && !product.loadedPrescription && product.refund)
              ? ( <div className="d-flex justify-content-between w-100 mb-2">
                    <div className="d-flex align-items-center">
                      <p className="txt-5--regular-db txt-bold color-insurance mr-1">
                        Aporte Seguro{insuranceName}
                      </p>
                    </div>
                    <div>
                      <p className="txt-5--regular-db txt-bold color-insurance" style={{ marginRight: '20px'}}>
                        {([undefined, null, 0].includes(refund?.value) ? '' : '-') + _formatPrice(product.refund)}
                      </p>
                    </div>
                  </div>
                )
              : ( product.product_discount > 0 &&
                  <div className="d-flex justify-content-between w-100 mb-2">
                    <div className="d-flex align-items-center">
                      <p className="txt-5--regular-db txt-bold color-insurance">
                        {process.env.REACT_APP_CLIENT_BENEFIT}
                      </p>
                    </div>
                    <div className="txt-5--regular-db benefit-badge background-insurance">
                      <p>
                        - {product.client_benefit}%
                      </p>
                    </div>
                    <div>
                      <p className="txt-5--regular-db txt-bold color-insurance" style={{ marginRight: '20px'}}>
                        {('-' + _formatPrice(product.product_discount))}
                      </p>
                    </div>
                  </div>

                )

            }
          </div>
        )
      })}

      {[null, undefined, 0].includes(productList?.length) && (
        <p className="txt-line mr-2">Sin productos</p>
      )}

      <div className="w-100">
        {modifyCart && (
          <div
          id="gtm-webapp-checkout-btn-add-product"
          className="btn-link-add-medication c-pointer"
          onClick={onClickAction}>
            <i className="icon-circle-plus" />{' '}
            <span className="m-0 ml-2">{buttonText}</span>
          </div>
        )}

        {(productList?.some(
          (c) => show && !isSubscription && c?.product?.client_product_id,
          ) ||
            (enabledInsurance && !isSubscription)) && (
          <>
            {productList?.some(
              (c) => c?.loadedPrescription && c?.product?.client_product_id,
            ) &&
              showAlert && (
                <PrescriptionAlert
                  text={
                    'Para acceder al reembolso de tus medicamentos debes agregar una receta médica'
                  }
                  alt={'Se necesita receta médica'}
                />
              )}
            <div className="px-4">
              <button
                className="btn-default--small background-insurance color-white btn-stroke font--12 mx-auto is-small size-content-responsive-btn w-md-100 apply-refund"
                onClick={onClickApplyRefund}>
                Calcular reembolso
              </button>
            </div>
          </>
        )}

        {insurancePolicyLimit && (
          <InfoCard
            text={ insurancePolicyLimit }
            icon="icon-info-2"
            className="apply-refund"
            borderColor={'--color-error-ds'}
            bgColor={'--color-white'}
            iconColor={'--color-error-ds'}
            fontColor={'--color-error-ds'}
          />
        )}
        {insuranceError && enabledInsurance && (
          <InfoCard
            text="No existe información asociada"
            icon="icon-info-2"
            className="apply-refund"
            borderColor={'--color-error-ds'}
            bgColor={'--color-white'}
            iconColor={'--color-error-ds'}
            fontColor={'--color-error-ds'}
          />
        )}

        {applyDeductible && (
          <InfoCard
            text="Aplica deducible"
            icon="icon-info-2"
            className="apply-refund"
            borderColor={'--color-dark-blue-ds'}
            bgColor={'--color-white'}
            iconColor={'--color-dark-blue-ds'}
            fontColor={'--color-dark-blue-ds'}
          />
        )}

        <hr className="w-100 mx-0 mb-3 hr--gray" />
        { deductible > 0 && (
          <>
            <div className="d-flex w-100 justify-content-between mb-3">
              <p className="txt-4--regular-db color-theme">Deducible póliza</p>
              <p className="txt-4--regular-db color-theme">{_formatPrice(deductible)}</p>
            </div>
            <hr className="w-100 mx-0 mb-3 hr--gray" />
          </>
        )}
        <div className="d-flex w-100 justify-content-between mb-1">
          <p className="txt-4--regular-db">Subtotal</p>
          <p className="txt-4--regular-db">{_formatPrice(totalCart)}</p>
        </div>
      </div>
    </div>
  )
}

export default ProductSummary
