import React, {useState, useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import ProductSearchPrincipleActive from '../../Components/ProductSearch/SearchPrincipleActive'
import {ENUM_MENU} from '../../Utils/constants'
import {getProductsWithProgram, getProgramsByType} from '../../Utils/api'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'
import ContentDetail from '../../Components/Product/Content'
import HeaderDetail from '../../Components/Product/HeaderDetail'
import FooterDetail from '../../Components/Product/FooterDetail'
import FindActivePrinciple from '../../Components/Product/FindActivePrinciple'

function MedicationSheet() {
  const history = useHistory()
  const [productId, setProductId] = useState(null)
  const [productList, setProductList] = useState([])
  const [programList, setProgramList] = useState([])
  const [product, setProduct] = useState(null)
  const [results, setResult] = useState(null)
  const fromYappApp = localStorage.getItem('fromYappApp') === 'true'
  const [contactLaboratory, setContactLaboratory] = useState(false)
  const [searchPrincipleActive, setSearchPrincipleActive] = useState(false)
  const ProductWithCodes = useSelector(
    (state) => state.user.professional_discount_code.products,
  )
  const {code} = useParams()
  const type = 'PATIENTS'

  useEffect(() => {
    if (code) {
      const isProduct = history.location.state?.isProduct
      const product = history.location.state?.product

      if (isProduct) {
        setProduct(product)
        setProductId(code)
        clickShowDetail(product, code)
      } else {
        setSearchPrincipleActive(code)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    getProducts()
    getPrograms(type)
  }, [])

  const getProducts = async () => {
    try {
      let allProducts = await getProductsWithProgram()
      if (allProducts?.data) {
        setProductList(allProducts?.data.data[0])
      }
    } catch (ignore) {}
  }

  const getPrograms = async () => {
    try {
      let allPrograms = await getProgramsByType(type)
      if (allPrograms?.data) {
        setProgramList(allPrograms?.data)
      }
    } catch (ignore) {}
  }

  function clickShowDetail(product, productId) {
    setSearchPrincipleActive(null)
    setResult({
      id: productId,
      product_id: productId,
      product: product,
      isComesFromMedicationSheet: true,
      fromActivePrinciple: history.location.state?.fromActivePrinciple,
    })
  }

  const onClickSearch = (newValue) => {
    if (
      newValue?.data?.is_product === 1 ||
      [undefined, null].includes(newValue?.data?.is_product)
    ) {
      if (newValue?.value) {
        logEvent('Price Finder Checked', {
          Origin: 'Search Option',
          'From Active Principle': false,
          'Product Id': newValue?.data?.product_id,
          'Product Name': newValue?.data?.product_name,
          'Lab Name': newValue?.data?.laboratory_name,
        })
        let id = newValue.data.product_id

        history.push(`/pro/medication-sheet/${id}`, {
          isProduct: true,
          product: newValue.data,
          fromActivePrinciple: false,
        })
      } else {
        console.log('Error value')
      }
    } else {
      logEvent('AP Medication Sheet Checked', {
        'Active Principle Id': newValue?.data?.active_principle_id,
        'Active Principle Name': newValue?.data?.active_principle_name,
      })
      addEventNameWithParams('medication_sheet_select_active_principle', {
        product_name: newValue?.label,
        active_principle_id: newValue?.data?.active_principle_id,
      })
      history.push(
        `/pro/medication-sheet/${newValue?.data?.active_principle_id}`,
        {isProduct: false, fromActivePrinciple: true},
      )
    }
  }

  function goBack() {
    history.goBack()
  }

  function onSelectProduct(product) {
    logEvent('Price Finder Checked', {
      Origin: 'Search Option',
      'From Active Principle': true,
      'Product Id': product?.product_id,
      'Product Name': product?.product_name,
      'Lab Name': product?.laboratory_name,
    })
    history.push(`/pro/medication-sheet/${product.product_id}`, {
      isProduct: product.product_id,
      product: product,
      fromActivePrinciple: true,
    })
  }

  return (
    <>
      {!fromYappApp && (
        <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span
              className="txt-3--regular-db"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>
      )}
      <div id={'gtm-medication-sheet'} className="flex-grow-1">
        <div className="content-responsive pt-4">
          <h2 className="txt-2--bold">Buscador de medicamentos</h2>
          <p className="txt-3--regular-db">
            Encuentra aquí todo el contenido relacionado al medicamento que
            buscas.
          </p>
          <div className="">
            <ProductSearchPrincipleActive
              principleActive={true}
              request_from={ENUM_MENU.medication_sheet}
              className="hpt-16"
              onClickSearch={onClickSearch}
              isNotShow={true}
              placeholder={'Busca por marca o principio activo'}
            />
            {!results && (
              <>
                <h3 className="title-home">Encuentra información sobre:</h3>
                <ul className="ul-check">
                  <li className="li-check title-primary--xl">
                    <span>Medicamentos</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Dispositivos Médicos Diabetes</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Insumos Médicos</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Fórmulas Alimenticias</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Dermatología</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Cuidado Bucal</span>
                  </li>
                  <li className="li-check title-primary--xl">
                    <span>Vacunas</span>
                  </li>
                </ul>
              </>
            )}
          </div>
          {searchPrincipleActive ? (
            <FindActivePrinciple
              active_principle_id={searchPrincipleActive}
              onSelectProduct={onSelectProduct}
            />
          ) : (
            results && (
              <>
                {productId && (
                  <>
                    <div className="d-flex justify-content-between align-items-center w-100 mt-4 mb-3">
                      <h4 className="title-primary--xl-bold mb-0">
                        Resultados de búsqueda
                      </h4>
                    </div>
                    <HeaderDetail
                      product={product}
                      ProductWithCodes={ProductWithCodes}
                      productList={productList}
                    />
                    <ContentDetail
                      results={results}
                      setContactLaboratory={setContactLaboratory}
                      contactLaboratory={contactLaboratory}
                      programList={programList}
                      productList={productList}
                    />
                    <FooterDetail
                      product={product}
                      contactLaboratory={contactLaboratory}
                    />
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default MedicationSheet
