import React, { useState } from 'react';
import Logo from '../../../assets/img/uc-logo.png'

function DesktopLogos() {
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const onSearch = () => {
    if (searchText) {
      window.location.href = `https://www.ucchristus.cl/buscador?indexCatalogue=reduc&searchQuery=${searchText}&wordsMode=AllWords`;
    }
  }

  return (
    <section className="sticky-top">
      <div className="d-none d-lg-block bg-white" id="UC-header-desktop">
        <div className="div_container_margin">
          <div className="flex_container_header">
            <div className="flex_container_header_hijo1">
              <div className="XMLID_1_">
                <img src={Logo} className="img img-fluid uc-header-logo" alt="" />
              </div>
            </div>
            <div
              id="id_marking_header_desktop"
              className="flex_container_header_hijo2">
              <div className="Group_944">
                <div className="font_header_reserva">Reserva de Hora</div>
                <div className="font_header_reserva_phone">
                  <a
                    className="color_azul"
                    href="tel:22 676 7000"
                    data-sf-ec-immutable="">
                    22 676 7000
                  </a>
                </div>
              </div>
              <div className="Group_945">
                <div className="font_header_rescate_rojo">
                  Rescate Ambulancia
                </div>
                <div className="font_header_reserva_phone">
                  <a
                    className="color_azul"
                    href="tel:800 265 265"
                    data-sf-ec-immutable="">
                    800 265 265
                  </a>
                </div>
              </div>
              <div className="Group_943 align-items-baseline">
                <div className="Group_943_img">
                  <em className="fa fa-search" aria-hidden="true"></em>
                </div>
                <a id="lupa_buscar" onClick={() => setShowSearch(true)} >
                  <div className="font_header_buscar">Buscar</div>
                </a>
              </div>
              <div className="ucchristus-header-btn-container">
                <a
                  href="https://mi.ucchristus.cl/"
                  className="uc-header-btn uc-header-btn-blue">
                  <img
                    src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/icon-btn-primary.svg?sfvrsn=ce0fc829_2"
                    className="uc-header-btn-icon"
                    alt=""
                  />
                  Portal Paciente
                </a>
                <a
                  href="https://agenda.ucchristus.cl/"
                  className="uc-header-btn uc-header-btn-purple">
                  <em className="far fa-calendar uc-header-btn-icon"></em>
                  Reserva de Hora
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        { showSearch && (
          <div id="div-buscador">
            <div className="animate__animated animate__fadeIn div_buscador">
              <div className="div_input_buscador">
                <input  id="txt_buscar_general" className="form-control"
                  type="text" placeholder="Ingrese la búsqueda"
                  onChange={(text) => setSearchText(text.target.value)}
                />
              </div>
              <a id="boton_buscar" onClick={onSearch}
                className="ml-2 mx-3 div_boton_buscador btn boton_buscador_font hover_blanco2">
                Buscar
              </a>
              <div className="div_cerrar_buscador">
                <a id="boton_buscador_cerrar" onClick={() => setShowSearch(false)} >
                  <em className="fas fa-times fa-2x mr-5 color_blanco" aria-hidden="true"></em>
                </a>
              </div>
          </div>
        </div>
        )}
      </section>
    </section>
  )
}

export default DesktopLogos;