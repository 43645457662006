import fileSaver from 'file-saver'
import {MIME_types, base_url} from './constants'
import moment from 'moment-timezone'
import amplitude from 'amplitude-js'
import {clearCookies} from './cookies'
import DefaultCard from '../assets/img/default-card.png'
import American from '../assets/img/american-logo.png'
import Diners from '../assets/img/diners-logo.png'
import Magna from '../assets/img/magna-logo.png'
import Mastercard from '../assets/img/mastercard-logo.png'
import Redcompra from '../assets/img/redcompra-logo.png'
import Visa from '../assets/img/visa-logo.png'
import * as Sentry from '@sentry/browser'
var crypto = require('crypto')
const appDownloadUrl = 'https://yappchile.cl/Xj62'

var http = require('https')

const properties = (eventName, params, clear) => {
  if (clear === true)
    return {
      event: eventName || null,
      product_id: params?.product_id || null,
      labId: params?.labId || null,
      active_principle_id: params?.active_principle_id || null,
      interaction_gender: params?.interaction_gender || null,
      interaction_height: params?.interaction_height || null,
      interaction_weight: params?.interaction_weight || null,
      interaction_age: params?.interaction_age || null,
      interaction_lactancy: params?.interaction_lactancy || null,
      program_title: params?.program_title || null,
      title: params?.title || null,
      year: params?.year || null,
    }

  if (
    !['', null, undefined].includes(eventName) &&
    !['', null, undefined].includes(params)
  ) {
    return {
      event: eventName,
      ...params,
    }
  }

  if (!['', null, undefined].includes(params)) {
    return {
      ...params,
    }
  }

  if (!['', null, undefined].includes(eventName))
    return {
      event: eventName,
    }
}

export const logEvent = (eventName, params = null) => {
  /*  try {
    var userProperties = {
      'Web Name': process.env?.REACT_APP_CLIENT_NAME,
    }
    setUserProperties(userProperties)
  } catch (ignore) {} */
  try {
    if (params) {
      amplitude.getInstance().logEvent(eventName, params)
      window.gtag('event', (eventName || '').replaceAll(' ', ''), params)
    } else {
      amplitude.getInstance().logEvent(eventName)
      window.gtag('event', (eventName || '').replaceAll(' ', ''))
    }
  } catch (ignore) {}
}

export const setUserProperties = (userProperties) => {
  try {
    amplitude.getInstance().setUserProperties(userProperties)
    window.gtag('set', 'user_properties', userProperties)
  } catch (ignore) {}
}

export const setUserPropIncrement = (eventName, number) => {
  try {
    var identify = new amplitude.Identify().add(eventName, number)
    amplitude.getInstance().identify(identify)
  } catch (ignore) {}
}

export const downloadFile = (url, fileName, fileType) => {
  //addListeners(xhr);
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    var file = new Blob([xhr.response], {
      type: MIME_types[fileType],
    })
    fileSaver.saveAs(file, fileName + '.pdf')
  }
  xhr.send()
}

export const downloadApp = () => {
  const hostName = window.location.hostname
  let origin = 'develop'

  if (hostName.includes('web.yapp.cl')) {
    origin = 'web pacientes'
  } else if (hostName.includes('pro.yapp.cl')) {
    origin = 'web médicos'
  } else {
    origin = hostName.replace('.yapp.cl', '')
  }

  logEvent('Platform Redirected', {
    Origin: origin,
    Type: 'app',
  })
  window.location.href = appDownloadUrl
}

export const shareWithColleagues = (url) => {
  if (navigator.share) {
    navigator
      .share({
        title: 'Yapp Chile',
        text: 'Visita nuestro sitio web y cotiza los medicamentos que necesitas!',
        url: url,
      })
      .then(() => {
        //console.log('Thanks for sharing!');
      })
      .catch(console.error)
  } else {
    return false
  }
}

export const programPatientMessage = (currentProgram, appState) => {
  const topic = `Hola, nos ha llegado una solicitud por parte del Dr./Dra para enviarle información sobre el programa de paciente ${currentProgram?.program_title} del laboratorio ${appState?.data?.laboratory_name} relacionado a su tratamiento.`
  const description = `A continuación se mostrará un listado de documentos relevantes, para que usted pueda acceder a los beneficios del programa ${currentProgram?.program_title}. Al hacer click en cada documento, podrá descargarlo para así utilizarlo de la forma que sea necesaria.`
  return {topic, description}
}

export const addEvent = (eventName, clear) => {
  if (!['', null, undefined].includes(eventName)) {
    window.dataLayer.push(properties(eventName, null, clear))
  }
  //console.log('addEvent', properties(eventName, null, clear))
}

export const addEventWithParams = (params, clear) => {
  if (!['', null, undefined].includes(params)) {
    window.dataLayer.push(properties(null, params, clear))
  }
  //console.log('addEventWithParams', properties(null, params, clear))
}
export const addEventNameWithParams = (eventName, params, clear) => {
  if (
    !['', null, undefined].includes(eventName) &&
    !['', null, undefined].includes(params)
  ) {
    window.dataLayer.push(properties(eventName, params, clear))
  }
  //console.log('addEventWithParams', properties(eventName, params, clear))
}

export const cleanText = (text) => {
  try {
    const textClean = text.replace(/xA0/g, ' ')
    return textClean
  } catch (e) {
    return text
  }
}
export const formatDate = (date, convertTz = true) => {
  try {
    if (typeof date !== Date) date = new Date(date)
    if (convertTz) date = changeTimeZoneDate(date)

    var monthNames = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ]
    var day = date.getUTCDate()
    var monthIndex = date.getUTCMonth()
    var year = date.getFullYear()

    return day + ' de ' + monthNames[monthIndex] + ', ' + year
  } catch (e) {
    return ''
  }
}
export const changeTimeZoneDate = (date) => {
  var tz = moment.tz(date.toISOString(), 'America/Santiago')
  let dateNow = new Date(
    tz.format('YYYY'),
    tz.format('MM') - 1,
    tz.format('DD'),
  )
  return dateNow
}

export const sha256 = (text) => {
  try {
    if (typeof text === 'string') {
      return crypto
        .createHash('sha256')
        .update(text.toLowerCase())
        .digest('base64')
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

const key = hexToBytes(process.env.REACT_APP_ENCRYPT_KEY)
const iv = hexToBytes(process.env.REACT_APP_ENCRYPT_IV)

export function encrypt(text) {
  let value = typeof text === 'object' ? JSON.stringify(text) : String(text)
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv)
  let encrypted = cipher.update(value)
  encrypted = Buffer.concat([encrypted, cipher.final()])
  return encrypted.toString('hex')
}

export function decrypt(text) {
  let newIv = Buffer.from(iv, 'hex')
  let encryptedText = Buffer.from(text, 'hex')
  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), newIv)
  let decrypted = decipher.update(encryptedText)
  decrypted = Buffer.concat([decrypted, decipher.final()])
  return decrypted.toString()
}

function hexToBytes(hex) {
  for (var bytes = [], c = 0; c < hex?.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16))
  return bytes
}

export function normalizeRut(rut, num) {
  try {
    return zeroPad(rut, num)
  } catch (error) {
    return rut
  }
}

const zeroPad = (num, places) => String(num).padStart(places, '0')

export function dgv(T) {
  //digito verificador
  var M = 0,
    S = 1
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
  return S ? S - 1 : 'k'
}

export function setPaymentLogo(cardType) {
  let logo = DefaultCard
  if (cardType) {
    switch (cardType.toLowerCase()) {
      case 'visa':
        logo = Visa
        break
      case 'master':
        logo = Mastercard
        break
      case 'dinner':
        logo = Diners
        break
      case 'magna':
        logo = Magna
        break
      case 'amex':
        logo = American
        break
      case 'redcompra':
        logo = Redcompra
        break
      default:
        break
    }
  }
  return logo
}

export function setTag(key, value) {
  Sentry.setTag(key, value)
}

export function clearWidgetSession() {
  clearCookies()
  const userPropertiesKey = 'userProperties'
  let userProperties = localStorage.getItem(userPropertiesKey)
  localStorage.clear()
  localStorage.setItem(userPropertiesKey, userProperties)
}
