import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { fetchUser, setBasicInfo } from '../../redux/modules/user'
import { changeInitialLoading, setFormData } from '../../redux/modules/app'
import { setFreeShipping, setDeliveryCode } from '../../redux/modules/delivery'
import { saveShoppingCart } from '../../Utils/service'
import { getProductByYappId } from '../../Utils/api'
import { loginAnonymoust } from '../../Utils/auth'

const apiLogin = process.env.REACT_APP_EXTERNAL_VALID_TYPE === 'API';
const clientName = process.env.REACT_APP_CLIENT_NAME  || 'Pacientes';
const forbiddenPage = '/no-access';
const homePage = '/';
const signInPage = '/signin';

function CreateShoppingCart(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('Espera un momento...')

  useEffect(() => {
    if (!apiLogin)
      addProducts();
    else
      history.push(forbiddenPage);
  },[location])

  const addProducts = async () => {
    const items = new URLSearchParams(location.search).getAll('product');
    if (!items || items.length === 0) {
      history.push(homePage);
      return;
    }

    setMessage('Preparando productos...');
    dispatch(changeInitialLoading(true));

    const userAuth = await loginAnonymoust();
    dispatch(fetchUser());
    setUserData(userAuth.data);
    setDeliveryData(userAuth.data);

    let productListData = await getProductByYappId(items);
    let shoppingBag = [];

    if (productListData.data) {
      for (let product of productListData.data) {
        if (shoppingBag.find((item) => item.product_id === product.product_id) === undefined) {
          product.counter = 1;
          product.origin = clientName;
          shoppingBag.push(product);
        }
      }
      await saveShoppingCart(shoppingBag);
    }

    dispatch(changeInitialLoading(false));
    history.push(signInPage);
  }

  const setDeliveryData = (data) => {
    dispatch(
      setDeliveryCode({
        code: data?.delivery_code,
        value: data?.delivery_value,
      }),
    )

    dispatch(
      setFreeShipping({
        code: data?.free_shipping,
        value: data?.free_shipping_value,
      }),
    )
  }

  const setUserData = (data) => {
    let userData = {
      userInfo: {
        profile: {...data}
      },
      address: null,
      address_additional: null,
      delivery_code: null,
      delivery_value: 0,
      email: null,
      free_shipping: null,
      free_shipping_value: null,
      hash: data.uid,
      identity: '',
      latitude: null,
      longitude: null,
      name: '',
      phone: null,
    }

    props.setFormData({
      ...props.formData,
      full_name: { valid: true, value: '' },
      titular_identity: { valid: true, value: '' },
      rut: { valid: true, value: '' },
      location_name: { valid: true, value: '' },
      location_number: { valid: true, value: ''},
      address_additional: { valid: true, value: '' },
      phone: { valid: true, value: '' },
      email: { valid: true, value: '' },
      checkTerms: { valid: true, value: false },
      discount: { valid: true, value: '' },
    });

    data.hash = data.uid;
    localStorage.setItem('LOCAL_DATA', JSON.stringify(data));
    sessionStorage.setItem('USER_DATA', JSON.stringify(userData));
    dispatch(setBasicInfo(null, ''));
  }

  return (
    <div style={{ paddingTop: '30px', textAlign: 'center', color: 'var(--theme-color)' }}>
      <h5>{message}</h5>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: state.app?.formData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFormData: (formData) => dispatch(setFormData(formData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateShoppingCart)
