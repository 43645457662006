import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { sendMail } from '../../Utils/api'
import { _validateMail } from '../../Utils/tools'
import ButtonYapp from '../Buttons/ButtonYapp'
import Input from '../Forms/Input'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import FailtSendModal from '../../assets/img/error_circle.svg'
import { logEvent, setUserPropIncrement } from '../../Utils/utils'

function ModalDiscountSendModal(props) {
  const {
    sendMailIsOpen,
    closeDiscountModal,
    product,
    discount
  } = props
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const [checkbox, setCheckbox] = useState(false)
  const [validatedEmail, setValidatedEmail] = useState(true)

  useEffect(() => {
    setValidatedEmail(true)
  }, [email])

  const sendEmail = async () => {
    let validate = _validateMail(email)
    if (validate) {
      setLoading(true)
      let response = await sendMail(
        email,
        'conveniosyapp@yapp.cl',
        checkbox ? props.userInfo?.profile?.email : null,
        'd-5a21c03063e84ae3b69a26492e7c1865',
        {
          name: props.userInfo?.profile?.first_name,
          lastName: props.userInfo?.profile?.last_name,
          discountCode: props.discountCode?.code,
          productImage: product?.product_image,
          productName: product?.product_name,
          discount: discount,
          formulaName: product?.formula_name,
          presentation: product?.presentation,
          laboratoryName: product?.laboratory_name
        }
      )

      logEvent('Item Shared', {
        'Shared Type': 'Yapp Benefit Code',
        'Element Shared': props.discountCode.code,
        'Shared': response.status === 204,
      })

      if (response.status === 204) {
        setStatus(1)
        setUserPropIncrement('Number of Yapp Benefit Code Shared', 1)
        setLoading(false)
      } else {
        setStatus(2)
        setLoading(false)
      }
    } else {
      setValidatedEmail(validate)
    }
  }

  const closeModal = () => {
    closeDiscountModal()
    clearState()
  }

  const clearState = () => {
    setStatus(0)
    setEmail('')
    setLoading(false)
    setCheckbox(false)
    setValidatedEmail(true)
  }

  return (
    <Modal
      isOpen={sendMailIsOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalSendMail"
      ariaHideApp={false}>
      <button className="btn-circle-close" onClick={closeModal}>
        <i className="icon-close"></i>
      </button>
      {loading ?
        <div className="d-flex justify-content-center w-100 py-5">
          <img src={loadingYapp} alt="loading" height={100} />
        </div>
        :
        <div>
          {status === 0 && (
            <div className="content-form-send-email pt-3">
              <h4 className="txt-2--bold-db mb-2">
                Comparte tu código Convenio Yapp con tus pacientes
              </h4>
              <h4 className="txt-4--regular-db mb-3">Ingresa el correo de tu paciente y le enviaremos tu código para que pueda acceder a los descuentos asociados a su tratamiento.</h4>
              <h4 className="txt-4--bold-db">Correo paciente</h4>
              <div>
                <Input
                  type="email"
                  label='Correo electrónico'
                  placeholder="ejemplo@gmail.com"
                  error={(!validatedEmail) && { message: '*Correo inválido, Verifica que esté ingresado correctamente.' }}
                  doChange={(value) => { setEmail(value) }}
                />
                {/* {sendEmailStatus === false && (
              <p className="text-danger txt-paragraph--small">
                *Correo inválido
              </p>
            )} */}
                <label
                  className="custom-checkbox-altern pl-0 mb-4 mt-2 "
                  id="gtm-webapp-ger-mail-copy">
                  <input
                    ref={null}
                    disabled={false}
                    type="checkbox"
                    onChange={(evt) => setCheckbox(evt.target.checked)}
                  />
                  <span className="checkmark-altern">
                    <i className="icon-checkmark"></i>
                  </span>
                  <span className="txt-6--regular-db custom-checkbox-txt">
                    Quiero recibir una copia en mi correo
                  </span>
                </label>
                <ButtonYapp
                  onClick={sendEmail}
                  id="gtm-webapp-modal-discount-send-email"
                  className="btn-default btn-next w-100"
                  title={'Enviar código al paciente'} />
              </div>
            </div>
          )}
          {status === 1 && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="modal-icon-send-mail mb-2">
                <i className="icon-send-2"></i>
              </span>
              <h4 className="txt-5--regular-db mb-3 text-center">
                Enviamos un correo a <span className='txt-5--bold-db'>{email}</span> con tu código Convenio Yapp y las instrucciones para que pueda acceder a los descuentos en sus tratamientos.
              </h4>
              <ButtonYapp
                id="gtm-webapp-modal-send-email-success"
                className="btn-default btn-next w-100"
                onClick={closeModal}
                title={'¡Entendido!'} />
            </div>
          )}
          {status === 2 && (
            <div className='d-flex flex-column justify-content-center'>
              <img id={"gtm-webapp-send-discount-failed-img"} src={FailtSendModal} alt='' className='img-fail-send-modal text-center mx-auto mb-4' />
              <h3 className="txt-2--bold-db mb-0 text-center">Lo sentimos</h3>
              <p className="txt-4--regular-db mb-3 text-center">El correo no pudo ser enviado. Intentalo más tarde.</p>
              <ButtonYapp
                id="gtm-webapp-modal-send-email-error"
                className="btn-default btn-next w-100 mt-2"
                onClick={closeModal}
                title={'¡Entendido!'} />
            </div>
          )}
        </div>
      }
    </Modal>
  )
}

export default ModalDiscountSendModal
