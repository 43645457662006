import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {getProductsByProgram} from '../../Utils/api'
import {productsUrl} from '../../Utils/constants'
import {logEvent} from '../../Utils/utils'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import defaultMedication from '../../assets/img/default-medication.png'
function ProgramsDetail(props) {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [programId, setProgramId] = useState(history.location.state?.item.id)
  const [productList, setProductList] = useState([])

  useEffect(() => {
    if (programId) {
      getProductsByProgram(programId).then((resp) => setProductList(resp.data))
      setLoading(false)
    }
  }, [])

  function goBack() {
    history.goBack()
  }
  const onClickProduct = (item) => {
    logEvent('Medication Sheet Checked', {
      Origin: 'Programs Details',
      'Product Id': item?.product_id,
      'Product Name': item?.product_name,
      'Lab Name': item?.laboratory_name,
      'Program Name': history?.location?.state?.program_name,
    })
    history.push(`/pro/programs-sheet-product/${item.product_id}`, {
      item: item,
      product_id: item.product_id,
      product_name: item.product_name,
      formula_name: item.formula_name,
      presentation: item.presentation,
      laboratory: item.laboratory_name,
      picture: item.picture,
      tag: item.product_tag,
      program_name: history.location?.state?.program_name,
      program_logo: history.location.state.item.logo,
      program_id: history.location?.state?.program_id,
      program_file: item.program_file,
      program_link: item.program_link,
    })
  }
  return (
    <>
      <div className="py-3">
        <div
          id={'gtm-medication-sheet-result'}
          className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center" href="#/">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span
              className="txt-4--regular-pr"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>
      </div>

      <div className="flex-grow-1">
        <div className="content-responsive">
          {loading ? (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative mx-auto mt-5"
            />
          ) : (
            <>
              <div className="d-flex align-items-center maxw-90p">
                <h2 className="txt-title  font--18">
                  Programa {location.state.item.name}
                </h2>
              </div>
              {location.state.item.link ? (
                <div className='mb-4'>
                  <span className="txt-3--bold-pr c-pointer">
                    {' '}
                    <a
                      className="color-lilac"
                      href={location.state.item.link}
                      target="_blank"
                      rel="noopener noreferrer">
                      {location.state.item.link}
                    </a>
                  </span>
                </div>
              ) : null}
              <div className="content-card-product">
                {productList.map((item, key) => (
                  <CardInteracion
                    key={key}
                    id={`gtm-webapp-programs-result-${key}`}
                    doAction={() => onClickProduct(item)}>
                    <div className="content-img-medication-sheet">
                      <img
                        src={productsUrl + item.picture}
                        alt={`nombre del producto`}
                        className="card-programs-img"
                      />
                    </div>
                    <div className="ml-14 overflow-hidden">
                      <h4 className="title-primary--xxl mb-1 pr-2 text-ellipsis--2 ">
                        {item?.product_name}{' '}
                      </h4>

                      <p className="txt-3--regular-db mb-1">
                        {item?.presentation}
                      </p>

                      <p className="txt-3--regular-db mb-1">
                        {item?.laboratory_name}
                      </p>
                      {item?.product_id === 'a37eff9d-dad3-11ea-8ff7-0a57' && (
                        <div
                          className={`${
                            item?.product_id === 'a37eff9d-dad3-11ea-8ff7-0a57'
                              ? 'chip-tag-blue'
                              : null
                          }`}>
                          Descuento
                        </div>
                      )}
                    </div>
                  </CardInteracion>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProgramsDetail
