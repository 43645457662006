import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect, useSelector} from 'react-redux'
import { Application, DatePicker } from 'react-rainbow-components'

import { addCreditCard, getCreditCard, getCreditCardResult, uploadFile, updateSubscription } from '../../Utils/api'
import { acceptedFileTypes, deliveryFrequencies } from '../../Utils/constants'
import { _isWeekEnd, _formatPrice, _validPhone, base64MimeType, encode64, formatDate, formatLongDate } from '../../Utils/tools'
import { setPaymentLogo } from '../../Utils/utils'

import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import SimpleSelect from '../../Components/Forms/SimpleSelect'
import Input from '../../Components/Forms/Input'
import SelectPaymentMethod from '../../Components/Forms/SelectPaymentMethod'
import ModalSelectPaymentMethod from '../../Components/Modals/ModalSelectPaymentMethod'
import ModalUploader from '../../Components/Modal.Uploader'
import Toasts from '../../Components/Toasts/Toasts'

import { icon_edit } from '../../assets/icons/icon-svg'

import {Viewer, Worker} from '@react-pdf-viewer/core'

const _ = require('lodash')
const moment = require('moment-timezone')

function MyShoppingSuscriptionEdit(props) {
  const history = useHistory()
  const location = useLocation()
  const modalRef = useRef()
  const datePickerRef = useRef(null)
  const userInfo = useSelector((state) => state.user.userInfo)
  const params = new URLSearchParams(location.search)
  const paymentSubscriptionId = params.get('subscriptionId')

  const [loading, setLoading] = useState(true)
  const [showModalPaymentMethod, setShowModalPaymentMethod] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)

  const [subscriptionData, setSubscriptionData] = useState(false)
  const [prescriptionList, setPrescriptionList] = useState([])
  const [localPrescriptionList, setLocalPrescriptionList] = useState([])
  const [deliveryDate, setDeliveryDate] = useState(new Date())
  const [minDeliveryDate, setMinDeliveryDate] = useState(new Date())
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false)
  const [creditCards, setCreditCards] = useState([])
  const [frequency, setFrequency] = useState(null)
  const [phone, setPhone] = useState('')
  const [invalidDate, setInvalidDate] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(async () => {
    setLoading(true)
    setSubscriptionInfo()
    setLoading(false)
  }, [])

  useEffect(async () => {
    if (paymentSubscriptionId) {
      setLoading(true)
      let creditCardSubscription = await getCreditCardResult(paymentSubscriptionId)
      setSubscriptionInfo()
      setLoading(false)

      if (creditCardSubscription?.status === 'success') {
        getPaymentMethod()
        setShowSuccess('Método de pago agregado exitosamente')
      } else {
        setShowError('Ocurrio un error vuelve a intentarlo')
      }
    }
  }, [window.location.href])

  const onGoBack = () => {
    history.replace(`/my_shopping_detail/${subscriptionData.id}`, subscriptionData)
  }

  const setSubscriptionInfo = () => {
    const subscriptionItem = location.state
    if (subscriptionItem) {
      let deliveryDate = new Date(subscriptionItem.delivery_date)
      let frequency = deliveryFrequencies.find((freq) => 
        freq.label === (subscriptionItem.delivery_frequency.replace('cada ', ''))
      )
      getPaymentMethod()      
      setSubscriptionData(subscriptionItem)
      setPhoneNumber(subscriptionItem.phone)
      setMinimalDeliveryDate(subscriptionItem)
      setDeliveryDate(deliveryDate)
      setFrequency(frequency)
      setPrescriptionList(subscriptionItem.prescriptions)
    }
  }

  const saveSubscription = async () => {
    let saved = false

    if (checkForms()) {
      if (subscriptionData) {
        setLoading(true)
        try {
          let prescriptionPathList = []
          let prescriptionRequestList = []

          for (const localPrescription of localPrescriptionList) {
            let formData = new FormData()
            formData.append('bucket', 's3.yapp')
            formData.append('filepath', 'img/prescription/webapp' + localPrescription.file_name)
            formData.append('file', localPrescription.image, localPrescription.file_name)

            prescriptionRequestList.push(uploadFile(formData))
          }

          const prescriptionResultList = await Promise.all(prescriptionRequestList)
          for (const prescriptionResult of prescriptionResultList) {
            prescriptionPathList.push({ path: prescriptionResult.location })
          }

          let subscription = {
            database: subscriptionData.id > 3000 ? 'new' : 'old',
            subscriptionId: subscriptionData.id,
            frequency: frequency.value,
            paymentId: selectedPaymentMethod.id,
            nextDelivery: formatDate(deliveryDate, false),
            phone: phone,
            status: subscriptionData.status,
            newPrescriptionList: prescriptionPathList,
            updatedBy: 'User in webapp'
          }

          const result = await updateSubscription(subscription)
          if (result?.message === 'success') {
            let updatedSubscription = Object.assign({}, subscriptionData)
            let updatedPrescriptions = localPrescriptionList.map((prescription) => { return prescription.local_prescription })

            updatedSubscription.phone = phone
            updatedSubscription.delivery_frequency = 'cada ' + frequency.label
            updatedSubscription.payment_card = selectedPaymentMethod.last_four
            updatedSubscription.payment_type = selectedPaymentMethod.type
            updatedSubscription.delivery_date = formatLongDate(deliveryDate, false)
            updatedSubscription.datetime = deliveryDate
            updatedSubscription.prescriptions = [...subscriptionData.prescriptions, ...updatedPrescriptions]
            setSubscriptionData(updatedSubscription)
            setLocalPrescriptionList([])
            saved = true
          }
        }
        catch (err) {
          saved = false
        }
        finally {
          setLoading(false)
        }
      }

      if (saved)
        setShowSuccess('Cambios guardados exitosamente')
      else
        setShowError('Ocurrio un error vuelve a intentarlo')
    }
    else {
      setShowError('Verifica los datos del formulario')
    }
  }

  const getPaymentMethod = async () => {
    let creditCardList = await getCreditCard(userInfo.profile.uid)
    let selectedCard = null

    if (creditCardList?.data?.length > 0) {
      for (let card of creditCardList.data) {
        card.logo = setPaymentLogo(card.type)
        if (card.default === 1) {
          selectedCard = card
          card.used = 1
        } else {
          card.used = 0
        }
      }

      if (!selectedCard) {
        selectedCard = creditCardList.data[0]
        creditCardList.data[0].used = 1
      }

      setCreditCards(creditCardList.data)
      setSelectedPaymentMethod(selectedCard)
    }
  }

  const setMinimalDeliveryDate = (subscriptionData) => {
    if (subscriptionData?.shortest_delivery_date) {
      let shortestDeliveryDate = subscriptionData.shortest_delivery_date
      let day = moment.utc(shortestDeliveryDate).date()
      let month = moment.utc(shortestDeliveryDate).month()
      let year = moment.utc(shortestDeliveryDate).year()

      let minDate = new Date(year, month, day)
      let today = new Date()
    
      minDate = minDate.getTime() < today.getTime()
        ? today : minDate
      setMinDeliveryDate(minDate)
    }
  }

  const setPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace('+56','')
      if (phoneNumber.length > 9 && phoneNumber.charAt(0) === '5' && phoneNumber.charAt(1) === '6') {
        phoneNumber = phoneNumber.replace('56','')
      }
      setPhone(phoneNumber)
    }
  }

  const setPaymentMethod = (selectedCardId) => {
    let creditCardList = []
    for (let card of creditCards) {
      card.used = card.id === selectedCardId ? 1 : 0
      creditCardList.push(card)
      card.used && setSelectedPaymentMethod(card)
    }
    setCreditCards(creditCardList)
  }

  const addPaymentMethod = async () => {
    setLoading(true)
    let currentUrl = window.location.href
    if (currentUrl.includes('subscriptionId=')) {
      let subscriptionIndex = currentUrl.lastIndexOf('subscriptionId=')
      currentUrl = currentUrl.substr(0, subscriptionIndex)
    }

    let userData = {
      email: userInfo.profile.email,
      userId: userInfo.profile.uid,
      callbackUrl: window.location.href,
    }

    let addPaymentMethodUrl = await addCreditCard(userData)
    if (addPaymentMethodUrl?.data) {
      window.location.href = addPaymentMethodUrl.data
    } else {
      setShowError('Ocurrio un error vuelve a intentarlo')
    }
  }

  const checkDeliveryDate = (newDate) => {
    let check = false

    if (_isWeekEnd(newDate)) {
      setInvalidDate('weekend')
    }
    else if (props.holidayList.some(holiday => holiday === formatDate(newDate)
    )) {
      setInvalidDate('holiday')
    } else {
      setDeliveryDate(newDate)
      setInvalidDate(null)
      check = true
    }

    return check
  }

  const checkPhone = () => {
    let message = ''
    if ([null, '', undefined].includes(phone)) {
      message = '* Necesitamos un número de contacto en caso de tener problemas con el despacho.'
    }
    else if (!_validPhone(phone)) {
      message = '* El número debe contener (9) caracteres.'
    }
    else if (isNaN(phone)) {
      message = '* El número debe contener solo números.'
    }
    return { message: message }
  }

  const checkForms = () => {
    let isOk = false

    if (checkPhone().message === '' &&
      checkDeliveryDate(deliveryDate) &&
      selectedPaymentMethod && frequency
    )
    isOk = true

    return isOk
  }

  const showPrescriptionModal = () => {
    modalRef.current.showAlert({ 
      productId: subscriptionData.products[0].id, 
      products: subscriptionData.products, 
      prescriptionProduct: []
    })
  }

  const onUploadPrescription = async (newPrescription) => {
    if (newPrescription?.images?.length > 0) {
      try {
        let mime = base64MimeType(newPrescription.images[0])
        let fileType = acceptedFileTypes.includes(mime?.type?.split('/')[1])
          ? mime?.type?.split('/')[1]
          : null

        if (fileType) {
          let fileName =  new Date().toISOString() + (mime?.name ? mime.name : '')
          let image = await fetch(newPrescription.images[0]).then((res) => res.blob())

          let prescription = {
            file_name: encode64(fileName) + '.' + fileType,
            local_prescription: newPrescription.images[0],
            image: image
          }

          setPrescriptionList([...prescriptionList, newPrescription.images[0] ])
          setLocalPrescriptionList([...localPrescriptionList, prescription ])
        }
        else {
          setShowError('El formato de la imagen no es válido')
        }
      }
      catch (e) {
        setShowError('Ocurrio un error al cargar la imagen')
      }
    }
  }

  function showPaymentOptions() {
    let options = null
    if (creditCards?.length > 0) {
      options = creditCards.map((card, i) => ({
        label: (
          <div
            className={
              'd-flex align-items-center ml-4' +
              (card.used ? ' payment-method-active' : '')
            }
            style={{width: '90px'}}
            key={i.toString()}>
            <div className="d-flex justify-content-center align-items-center w-75">
              <img
                className="payment-logo"
                src={card.logo}
                alt="Logo método de pago"
              />
            </div>
            <p className="txt-5--regular-db text-right">{card.last_four}</p>
          </div>
        ),
        value: card.id,
      }))
    }
    return options
  }

  function showDefaultPaymentOption() {
    let option = null
    if (selectedPaymentMethod) {
      option = {
        label: (
          <div
            className={'d-flex align-items-center ml-4'}
            style={{width: '90px'}}
            key={0}>
            <div className="d-flex justify-content-center align-items-center w-75">
              <img
                className="payment-logo"
                src={selectedPaymentMethod.logo}
                alt="Logo método de pago"
              />
            </div>
            <p className="txt-5--regular-db text-right">
              {selectedPaymentMethod.last_four}
            </p>
          </div>
        ),
        value: selectedPaymentMethod.id,
      }
    }

    return option
  }


  return (
    <>
      <div className="d-flex justify-content-between py-3 px-3">
        <div
          className="mr-auto breadcrumbs c-pointer"
          onClick={ onGoBack }>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse color-lilac"></i>
          </a>
          <span className="title-primary--regular line-14 color-lilac">
            Volver
          </span>
        </div>
      </div>
      <div className="flex-grow-1 px-3 pb-4">
        {loading ? (
          <div className="d-flex justify-content-center w-100 py-5">
            <img src={loadingYapp} alt="loading" height={70} />
          </div>
        ) : (
          <div className="content-responsive mx-auto h-100 pt-2 ">
            <h3 className="txt-3--bold m-0 mb-2">Edita tu convenio</h3>
            <h3 className="txt-3--bold m-0 mb-2">Convenio nº { subscriptionData ? subscriptionData.id : '' }</h3>

            <div className="mb-3">
              <h4 className="txt-4--bold-g1 m-0 ">Tu pedido</h4>
              { subscriptionData && (
                subscriptionData.products?.map((product, index) => (
                  <p className="txt-4--bold m-0" key={ index }>
                    { product.quantity } x { product.name }
                  </p>
                ))
              )}
            </div>

            <div>
              <h4 className="txt-4--bold-g1 m-0">Tus recetas</h4>
              <div className="d-flex flex-wrap">

              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
                { prescriptionList.map((item, index) => (
                  <div className="mb-4 mt-2 mr-3" key={index.toString()}>
                    {(item.toLowerCase().includes('.pdf')) ? (
                      <div 
                        key={index.toString()}
                        className='prescription-min'> 
                        <Viewer
                          fileUrl={item}
                          defaultScale={0.2}
                        />
                      </div>
                    ) : (
                      <div className='prescription-min'>
                        <img
                          key={index.toString()}
                          resizeMode='contain'
                          resizeMethod='resize'
                          src={ item }
                          alt="Tus recetas"
                          height="100"
                          width="100"
                        />
                      </div>
                    )}
                  </div>
                  ))
                }
              </Worker>

              </div>
            </div>

            <ButtonYapp title="Agregar receta" onClick={ showPrescriptionModal } stroke />

            <div className="mt-4 maxw-400">
              <div className="mb-2">
                <SimpleSelect
                  options={ deliveryFrequencies }
                  value={ frequency }
                  placeholder={'Frecuencia de entrega'}
                  onChange={ setFrequency }
                  className="tablet-desktop"
                />
                { !frequency && (
                  <p className="txt-6--regular-er mt-1 mb-2">
                    *Debes ingresar una frecuencia de entrega para finalizar tu compra
                  </p>
                )}
              </div>
              <div className="mb-2" onClick={() => datePickerRef.current.click()}>
                <div className="content-add-prescription pr-2">
                  <div className="position-relative w-100">
                    <label className="label-delivery color-gray-4">
                      Fecha de entrega
                    </label>
                    <p className="txt-5--regular-db mt-12">
                      { deliveryDate
                        ? moment(deliveryDate).format('DD-MM-YYYY')
                        : ''
                      }
                    </p>
                  </div>
                  <div className="d-flex align-items-center color-theme icon-edit-datepiker">
                    {icon_edit}
                  </div>
                </div>
                { invalidDate !== null && (
                  invalidDate === 'weekend'
                  ? <p className="txt-6--regular-er mt-1 mb-2">
                      *Lo sentimos, no contamos con despacho los fines de semana.
                    </p>
                  : <p className="txt-6--regular-er mt-1 mb-2">
                    *Lo sentimos, debes seleccionar un día hábil.
                  </p>
                )}
              </div>
              <div className="mb-2">
                <Input
                  label="Teléfono"
                  placeholder="Teléfono"
                  maxLength={9}
                  type="tel"
                  codeArea="+56"
                  defaultValue={ phone }
                  showIconEdit
                  error={ checkPhone() }
                  doChange={(value) => setPhone(value)}
                />
              </div>
              <div>
                <div>
                  { creditCards.length > 0 && (
                    <SelectPaymentMethod
                      options={ showPaymentOptions() }
                      value={ showDefaultPaymentOption() }
                      onChange={(option) => null}
                      placeholder={'Selecciona un método de pago'}
                      className="tablet-desktop "
                    />
                  )}
                  { creditCards.length > 0 && (
                    <div onClick={() =>
                      showPaymentOptions()
                        ? setShowModalPaymentMethod(true)
                        : null
                      }
                      className="btn-open-modal-bottom d-flex align-items-center justify-content-between px-3">
                      { [null, undefined, false].includes(selectedPaymentMethod) 
                        ? (
                        <p className="txt-5--bold-g1">
                          Selecciona un método de pago
                        </p>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              src={ selectedPaymentMethod.logo  }
                              className="payment-logo"
                              alt="Logo método de pago"
                            />
                          </div>
                          <p className="txt-5--bold-db pl-2">
                            {selectedPaymentMethod.last_four}
                          </p>
                        </div>
                      )}
                      <i className="icon-chevron-down color-theme"></i>
                    </div>
                  )}
                  <ModalSelectPaymentMethod
                    creditCards={creditCards}
                    setPaymentMethod={setPaymentMethod}
                    showModal={showModalPaymentMethod}
                    setShowModal={setShowModalPaymentMethod}
                  />

                  { /*
                  <ButtonYapp
                    onClick={() => addPaymentMethod()}
                    className={`maxw-214 ${
                      creditCards.length > 0 ? ' mt-3' : 'mt-0'
                    }`}
                    disabled={false}
                    color="--theme-color"
                    stroke
                    small
                    title={'Agregar método de pago'}
                  />
                  */
                  }

                  { creditCards.length === 0 && (
                    <p className="txt-6--bold-er mt-1">
                      * Para continuar con tu compra, necesitamos que ingreses
                      un método de pago válido.
                    </p>
                  )}
                </div>
              </div>
              <ButtonYapp title="Guardar cambios" className="px-5 mt-3" onClick={ saveSubscription }/>
            </div>
          </div>
        )}
      </div>

      <ModalUploader
        ref={ modalRef }
        modalIsOpen={ modalUpload }
        onSelect={ onUploadPrescription}
      />

      <Toasts
        text={ showSuccess }
        timer="3000"
        icon="icon-check"
        stateToasts={ showSuccess }
        setStateToasts={ setShowSuccess }
      />

      <Toasts
        text={ showError }
        timer="3000"
        icon="icon-alert"
        color="var(--color-error-ds)"
        stateToasts={ showError }
        setStateToasts={ setShowError }
      />
        
      <Application theme={ themeDateP }>
        <DatePicker
          ref={datePickerRef}
          style={{display: 'none'}}
          className="modal-datepicker-custom"
          value={ deliveryDate }
          minDate={ minDeliveryDate }
          onChange={ checkDeliveryDate }
          formatStyle="large"
          locale={'es-CL'}
          isCentered={true}
        />
      </Application>
    </>
  )
}

const themeDateP = {
  rainbow: {
    palette: {
      brand: '#00a19b',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.user,
    holidayList: state.app?.holidayList
  }
}

export default connect(mapStateToProps, null) (MyShoppingSuscriptionEdit)
