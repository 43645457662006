import React, {useEffect, useState} from 'react'
import './ButtonYapp.css'

function ButtonYapp(props) {
  const [colorBtn, setColorBtn] = useState({})

  useEffect(() => {
    getColorStyle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color, props.stroke])

  function getColorStyle() {
    if (props?.color) {
      if (props?.color.search('--color') >= 0 || props?.color.search('--theme') >= 0) {
        return setColorBtn({
          color: props.stroke ? `var(${props.color})` : 'white',
          background: props.stroke ? 'transparent' : `var(${props.color})`,
          border: `1px solid var(${props.color}) `,
        })
      }
      setColorBtn({
        color: props.stroke ? `${props.color}` : 'white',
        background: props.stroke ? 'transparent' : `${props.color}`,
        border: `1px solid ${props.color}`,
      })
    }
  }

  return (
    <>
      <button
        onClick={props.onClick}
        id={props.id}
        style={props.color && colorBtn}
        className={`btn-base ripple ${props.className ? props.className : ''} ${
          props.small ? 'is-small' : ''
        } ${props.big ? 'is-big' : ''} ${
          props.chip ? 'is-chip' : ''
        }
        ${props.stroke ? 'is-stroke' : ''} ${
          props.noWrap ? 'txt-nowrap' : ''
        }`}
        disabled={props?.disabled}
        data-tip={props?.dataTip}  
        data-for={props?.dataFor}
        data-multiline={!!props?.dataMultiline}
        data-place={props?.dataPlace}
      >
        {props.icon && <i className={`${props.icon} mr-2`}></i>}
        {props.title}
      </button>
    </>
  )
}

export default ButtonYapp
