import React from 'react'
import Image from 'react-bootstrap/Image'
import {Modal} from 'react-bootstrap'
import '../../styles/modalPreview.css'


const ModalPreview = (props) => {
  const {show, onClose, img} = props
  return (
    <>
      {img && (
        <Modal
          centered
          show={show}
          onHide={onClose}
          onAfterOpen={null}
          onRequestClose={onClose}
          withIcon={false}
          contentClassName="modal-content-preview"
          overlayClassName="modal-custom-bg"
          contentLabel="ModalTypeFormsHome">
          <Modal.Header
            closeButton
            bsPrefix={`${
              process.env.REACT_APP_CLIENT_NAME === 'UC Christus'
                ? 'modal-header-custom-uc'
                : 'modal-header-custom'
            }`}></Modal.Header>
          <Modal.Body bsPrefix="modal-content-preview">
            <Image src={img} fluid className="rounded-modal" />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ModalPreview
