import { useState, useRef, useEffect } from "react"

function Carousel({ logos, screen }) {
  const [sliderActive, setSliderActive] = useState(1);
  const [maxItems, setMaxItems] = useState(6);

  useEffect(() => {
    setMaxItems(screen > 1280 ? 6 : screen <= 1280 && screen > 768 ? 4 : 2);
    setSliderActive(1);
  }, [screen])

  const scrl = useRef();
  
  const showImage = (index) => {
    let show = false;

    if (index >= ((sliderActive - 1) * maxItems) && index < (sliderActive * maxItems)) {
      show = true;
    }
    return show;
  }

  const carouselControls = () => {
    let totalGuides = Math.ceil(logos.length / maxItems);
    let guideIndex = [];
  
    for (let i = 1; i <= totalGuides; i++) {
      guideIndex.push(
        <button
          key={`button-${i}`}
          onClick={() => setSliderActive(i)} 
          className={sliderActive === i ? 'ellipse-carousel mx-1' : 'ellipse-carousel btn-circle-disabled mx-1'}>
        </button>
      )
    }
    return guideIndex;
  }

  return (
    <div className="mb-5">
      <div
        ref={scrl}
        className="carousel-pharmacy mt-4">
        <div className={'d-flex justify-content-center align-items-center'}>
          { logos.map((pharmacy, index) => {
            if (showImage(index)) {
              return (
                <div className="mx-2 maxw-135" key={index}>
                  <img
                    className="lazyload carousel-img" 
                    alt={pharmacy.alt} 
                    title={pharmacy.title}
                    src={pharmacy.src}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        { carouselControls() }
      </div>
    </div>
  )
}

export default Carousel