import React, {useState, useRef, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {pdf} from '@react-pdf/renderer'
import {logEvent} from '../../Utils/utils'
import ModalPreviewPdf from '../../Components/Modals/ModalPreviewPdf'
import ModalSendPrices from '../../Components/Modals/ModalSendPrices'
import {getGenerateLink, sendMail} from '../../Utils/api'

import Toasts from '../../Components/Toasts/Toasts'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ProgramPDF from '../../Components/PDF/ProgramPDF'
import {prescriptionUrl, productsUrl} from '../../Utils/constants'
import defaultMedication from '../../assets/img/default-medication.png'

function ProgramMedicationSheet(props) {
  const history = useHistory()
  const [quotation, setQuotation] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const [modalImage, setModalImage] = useState([])
  const [loading, setLoading] = useState(true)
  const fromYappApp = localStorage.getItem('fromYappApp') === 'true'
  const [modalSendPricesState, setModalSendPricesState] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true)
    }
  }, [quotation])

  function onClickShowPreview(product_logo) {
    setModalImage(product_logo)
    setShowPreview(true)
  }
  const handleClose = () => {
    setShowPreview(false)
    setModalImage([])
  }
  async function showQuotation(isPDF, email) {
    setLoading(true)
    try {
      let blobURL = null

      let responseLink = quotation?.responseLink
      if (!quotation?.responseLink) {
        responseLink = await getGenerateLink([
          {
            quantity: 1,
            id: history.location.state?.product_id,
          },
        ])
        if (responseLink?.message === 'OK: Success') {
          responseLink = responseLink?.data?.url
          setQuotation({...quotation, responseLink: responseLink})
        }
      }
      if (quotation?.blobURL && isPDF) {
        window.open(quotation.blobURL)
        return
      }
      logEvent('Item Shared', {
        'Shared Type': isPDF ? 'Print' : 'Mail',
        'Element Shared': 'Program',
        'Product Id': history.location.state?.product_id,
        'Product Name': history?.location?.state.product_name,
        'Lab Name': history?.location?.state.laboratory,
      })
      if (isPDF) {
        const blob = await pdf(
          <ProgramPDF product={history.location.state} link={responseLink} />,
        ).toBlob()

        blobURL = URL.createObjectURL(blob)

        setQuotation({
          responseLink: responseLink,
          blobURL: blobURL,
        })

        window.open(blobURL)
      } else {
        setModalSendPricesState(false)
        let productData = history?.location?.state
        let programFile = prescriptionUrl + history?.location?.state?.program_file
        let response = await sendMail(
          email,
          'contacto@yapp.cl',
          '',
          'd-d604142af3c74da0ba7283d97f372974',

          {
            quotation_url: responseLink,
            programFile: programFile,
            products: [
              {
                product_name: productData?.product_name,
                product_id: productData?.product_id,
                product_img: productsUrl + productData?.picture,
                formula_name: productData?.formula_name,
                presentation: productData?.presentation,
                laboratory_name: productData?.laboratory_name,
                program_name: productData?.program_name,
                program_logo: productData?.program_logo,
                program_link: productData?.program_link,
                program_file: productData?.program_file,
              },
            ],
          },
        )
        if (response.status === 204) {
          setShowSuccess(true)
        } else if (response.status !== 204) {
          setShowError(true)
          setModalSendPricesState(true)
        }
      }
    } catch (ignore) {
    } finally {
      setLoading(false)
      setModalSendPricesState(false)
      setShowError(false)
    }
  }

  async function openLink(history) {
    logEvent('Material Checked', {
      'Material Type': 'Program',
      'Origin Material': 'Medication Sheet',
      'Material Name': history?.location?.state.program_file,
      'Program Name': history.location.state?.program_name,
      'Product Name': history?.location?.state.product_name,
      'Lab Name': history?.location?.state.laboratory,
    })
    window.open(history.location.state?.program_link, '_blank')
  }
  async function openFiles(history) {
    logEvent('Material Checked', {
      'Material Type': 'Program',
      'Origin Material': 'Medication Sheet',
      'Material Name': history?.location?.state.program_file,
      'Program Name': history?.location?.state.program_name,
      'Product Name': history?.location?.state.product_name,
      'Lab Name': history?.location?.state.laboratory,
    })
    window.open(
      prescriptionUrl + history.location.state?.program_file,
      '_blank',
    )
  }

  return (
    <>
      {!fromYappApp && (
        <div className="py-3">
          <div className="content-responsive d-flex justify-content-between py-1 px-0 px-md-0">
            <div
              className="breadcrumbs c-pointer"
              onClick={() => history.goBack()}>
              <a className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse"></i>
              </a>
              <span
                className="txt-4--regular-pr"
                style={{color: 'var(--theme-color)'}}>
                Volver
              </span>
            </div>
          </div>
        </div>
      )}
      <div id={'gtm-medication-sheet'} className="flex-grow-1">
        <div className="content-responsive">
          <h4 className="txt-title mb-3 font--18">
            Resultados de búsqueda
          </h4>
          <div className="card-product-detail align-items-center mt-2 mb-2">
            <img
              src={
                productsUrl + history.location.state?.picture ||
                defaultMedication
              }
              alt=""
              height="65"
              className="ml-1"
            />
            <div className="ml-3">
              <h4 className="title-primary--xxl font--18 m-0 w-100 mb-2">
                {history.location.state?.product_name}
              </h4>
              <p className="txt-3--regular-db mb-1">
                {history.location.state?.formula_name}
              </p>
              <p className="txt-3--regular-db mb-1">
                {history.location.state?.presentation}
              </p>
              <p className="txt-3--regular-db mb-1">
                {history.location.state?.laboratory}
              </p>
              <div
                className={`${
                  history.location.state?.tag === 'Educación al paciente'
                    ? 'chip-tag-green'
                    : history.location.state?.tag === 'Descuento'
                    ? 'chip-tag-blue'
                    : history.location.state?.product_id ===
                      'a37eff9d-dad3-11ea-8ff7-0a57'
                    ? 'chip-tag-blue'
                    : null
                }`}>
                {history.location.state?.product_id ===
                'a37eff9d-dad3-11ea-8ff7-0a57'
                  ? 'Descuento'
                  : history.location.state?.tag}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3 mb-4">
            <>
              <ButtonYapp
                className="m-1 font--16 white-space-nowrap h-34"
                disabled={false}
                title={'Enviar por correo'}
                color={'--color-primary'}
                onClick={() => setModalSendPricesState(true)}
              />
              <ButtonYapp
                className="m-1 font--16 h-34"
                disabled={false}
                title={'Imprimir'}
                stroke={true}
                color={'--color-primary'}
                onClick={() => showQuotation(true)}
              />
            </>
          </div>
          <div className="card-product-detail align-items-center justify-content-between">

              <h2 className="txt-title font--18">
                Programa {history.location.state?.program_name}
              </h2>


              {[undefined, null, 'NULL'].includes(
                history.location.state?.program_file,
              ) ? (
                <ButtonYapp
                  className="font--16 white-space-nowrap h-34"
                  disabled={false}
                  title={'Ver más'}
                  color={'--color-primary'}
                  onClick={() => openLink(history)}
                />
              ) : (
                <ButtonYapp
                  className="mw-100  m-1 font--16 h-34"
                  disabled={false}
                  title={'Ver más'}
                  color={'--color-primary'}
                  onClick={() => openFiles(history)}
                />
              )}

          </div>
        </div>
      </div>
      <ModalPreviewPdf
        show={showPreview}
        onClose={handleClose}
        img={modalImage}
      />
      <ModalSendPrices
        modalSendPricesState={modalSendPricesState}
        setModalSendPricesState={setModalSendPricesState}
        showError={showError}
        setShowError={setShowError}
        onClickButton={(email) => showQuotation(false, email)}
      />

      <Toasts
        text="Correo enviado exitosamente"
        timer="3000"
        icon="icon-check"
        stateToasts={showSuccess}
        setStateToasts={setShowSuccess}
      />
    </>
  )
}

export default ProgramMedicationSheet
