import React from 'react'
import Modal from 'react-modal'

function ModalSelectPaymentMethod(props) {
  const { creditCards, showModal, setPaymentMethod, setShowModal } = props

  function selectPaymentMethod(selectedCard) {
    setPaymentMethod(selectedCard)
    setShowModal(false)
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        className="modal-from-bottom p-0"
        closeTimeoutMS={500}
        ariaHideApp={false}
        onRequestClose={() => setShowModal(false)}
        overlayClassName="modal-custom-bg modal-bottom-transition">
        <div className="p-3">
          <p className="txt-5--bold-db">Seleciona un método de pago</p>
        </div>
        <hr className="hr-bottom-gray-2 m-0" />
        <div style={{ maxHeight: '10.75rem', overflowY: 'scroll' }}>

          {creditCards && creditCards.map((card, index) => (
            <div onClick={() => selectPaymentMethod(card.id)} className={"d-flex justify-content-between py-2 hr-bottom-gray-2" + (card.used ? " payment-method-active" : "")} key={index.toString()}>
              <div className="d-flex align-items-center ml-4" style={{ width: '90px' }}>
                <div className="d-flex justify-content-center align-items-center w-75">
                  <img src={card.logo} className="payment-logo" alt="Logo método de pago" />
                </div>
                <p className="txt-5--regular-db text-right ml-4">{card.last_four}</p>
              </div>
            </div>
          ))
          }

        </div>
      </Modal>
    </>
  )
}

export default ModalSelectPaymentMethod
