import React from 'react'

import './MyShoppingCard.css'

/* Imgs */
import BgCardMs from '../../assets/img/bg_card_ms.svg'
import IcAgreement from '../../assets/img/ic_yapp_approved.svg'

function MyShoppingCard(props) {

  return (
    <div
      className={`content-card-ms ${props?.className ? props.className : ''}`}
      onClick={props.doAction}>
      <div className="content-img-ms">
        {
          <img
            src={
              props?.data?.img === 'convenio' ? IcAgreement : props?.data?.img
            }
            alt=""
            className="img-ms"
          />
        }
      </div>
      <div
        className="content-text-ms"
        style={{
          backgroundImage: `url(${BgCardMs})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
        }}>
        <div>
          <h3 className="txt-4--bold-db m-0">{props?.data?.title}</h3>
          <p className="txt-6--bold-db m-0">{props?.data?.product}</p>
          {!props?.data?.delivery_frequency && (
            <p
              className={`txt-6--bold m-0 ${
                (props?.data?.state === 'default' && 'color-default-ms') ||
                (props?.data?.state === 'alert' && 'color-alert-ms') ||
                (props?.data?.state === 'warning' && 'color-warning-ms') ||
                (props?.data?.state === 'success' && 'color-success-ms')
              }`}>
              Estado: {props?.data?.state_value}
            </p>
          )}
          {!props?.data?.delivery_frequency && (
            <p className="txt-6--regular-db m-0">
              Fecha de entrega: {props?.data?.date}
            </p>
          )}
          {props?.data?.delivery_frequency && (
            <p className="txt-6--regular-db m-0">
              Frecuencia de entrega: {props?.data?.delivery_frequency}
            </p>
          )}
          <p className="txt-6--regular color-gray-1 m-0">
            {props?.data?.agreement_number}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <i className="icon-chevron-right color-lilac"></i>
        </div>
      </div>
    </div>
  )
}

export default MyShoppingCard
