import React, {useState} from 'react'
import ModalSignOut from '../Modals/ModalSignOut'
import ButtonYapp from '../Buttons/ButtonYapp'


function SignOut() {
  const [modalSignOutState, setModalSignOutState] = useState(false)

  return (
    <>
      <div className="d-flex justify-content-end">
        <ButtonYapp
          className="btn-base py-0 px-3 mt-2 mr-2"
          disabled={false}
          title={'Cerrar sesión'}
          stroke
          onClick={() => setModalSignOutState(true)}
        />
        <ModalSignOut
          modalSignOutState={modalSignOutState}
          setModalSignOutState={setModalSignOutState}
        />
      </div>
    </>
  )
}

export default SignOut
