import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector, connect} from 'react-redux'
import Input from '../../Components/Forms/Input'
import {
  loginApple,
  loginGoogle,
  loginFacebook,
  getRedirectResult,
  _getUserId,
  registerUser,
  registerPassword,
} from '../../Utils/auth'
import {getShoppingCart, saveShoppingCart} from '../../Utils/service'
import amplitude from 'amplitude-js'
import {
  _validateMail,
  _handleRegisterValidation,
} from '../../Utils/tools'
import {
  getConfigSite,
  validProfessional,
  usedProfessional,
} from '../../Utils/api'
import {fetchUser, setUserExtraInfo} from '../../redux/modules/user'
import ButtonRss from '../../Components/Forms/ButtonRss'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ModalError from '../../Components/Modals/ModalError'
import {
  changeInitialLoading,
  setProvider,
  setFormDataAccount,
  changeConfig,
} from '../../redux/modules/app'
import {logEvent, setUserProperties} from '../../Utils/utils'
import ModalAccountRegistered from '../../Components/Modals/ModalAccountRegistered'
const _get = require('lodash/get')

function Register(props) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')
  const [policies, setPolicies] = useState(false)
  const [inputError, setInputError] = useState({})
  const [registerError, setRegisterError] = useState(false)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formIsValid, setFormIsValid] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(false)

  const [isProfessional, setIsProfessional] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [modalServerError, setModalServerError] = useState(false)
  const [modalInternetError, setModalInternetError] = useState(false)
  const [modalSocialNetworkError, setModalSocialNetworkError] = useState(false)
  const [modalAccountExistError, setModalAccountExistError] = useState(false)

  const lastPage = useSelector((state) => state.app.lastPage)

  const userInfo = useSelector((state) => state?.user?.userInfo)
  const provider = useSelector((state) => state.app.provider)

  useEffect(() => {
    dispatch(changeInitialLoading(true))
    setIsLoading(true)
    setIsProfessional(process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE')
    async function callGetRedirectResult() {
      try {
        let response = await getRedirectResult()
        if (response) {
          actionNext(response)
        }
      } finally {
        setIsLoading(false)
        dispatch(changeInitialLoading(false))
      }
    }
    callGetRedirectResult()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userInfo && userInfo?.signInMethod !== 'anonymously') {
      setEmail(userInfo?.profile?.email || undefined)
      setName(
        (userInfo?.profile?.first_name
          ? userInfo?.profile?.first_name
          : userInfo?.profile?.name) || undefined,
      )
      setLastname(userInfo?.profile?.last_name || undefined)
    }
  }, [userInfo])

  useEffect(() => {
    let result = _handleRegisterValidation(
      name,
      lastname,
      email,
      password,
      passwordConfirmation,
      policies,
      userInfo,
    )
    setButtonDisable(result.formIsValid)
    setFormIsValid(result.formIsValid)
    setInputError(result.errors)
  }, [name, lastname, email, password, passwordConfirmation, policies,userInfo])

  const register = async (provider) => {
    try {
      dispatch(changeInitialLoading(true))
      dispatch(setProvider(provider))
      switch (provider) {
        case 'email':
          if (_validateMail(email)) {
            setIsLoading(true)
            let response = await registerPassword(
              email,
              password,
              name,
              lastname,
            )
            if (response.status === 'already-account') {
              actionNext(response)
            } else {
              await dispatch(fetchUser())
              await registerAccount(true)
            }
          } else {
            dispatch(changeInitialLoading(false))
          }
          break
        case 'google':
          loginGoogle()
          break
        case 'facebook':
          loginFacebook()
          break
        case 'apple':
          loginApple()
          break
        default:
          break
      }
    } catch (ignore) {}
  }

  const actionNext = async (response) => {
    try {
      dispatch(setProvider(response?.data?.provider.split('.')[0]))
      switch (response.status) {
        case 'success':
          await dispatch(fetchUser())
          await loadConfig()
          break
        case 'no-account':
        case 'no-professional':
          await dispatch(fetchUser())
          setIsLoading(false)
          dispatch(changeInitialLoading(false))
          break
        case 'already-account':
          dispatch(changeInitialLoading(false))
          setIsLoading(false)
          setModalAccountExistError(true)
          break
        default:
          dispatch(changeInitialLoading(false))
          setIsLoading(false)
          break
      }
    } catch (error) {
      logEvent('Create Account Completed', {
        'Account Type': response?.data?.provider.split('.')[0],
        'Account Created': false,
      })
      dispatch(changeInitialLoading(false))
      setIsLoading(false)
    }
  }
  const loadConfig = async () => {
    try {
      let user_id = await _getUserId()
      let localData = {}
      let res = null
      let arrBag = null

      if (isProfessional) {
        res = await getConfigSite()
        if (!res) {
          return history.push('/error')
        }
        localData = {
          hash: user_id,
          site: res?.data,
          origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
          client_config: {
            delivery_time: _get(res?.data, 'config[0].config.delivery_time'),
          },
        }
      } else {
        arrBag = await getShoppingCart()
        localData = localStorage.getItem('LOCAL_DATA')
        localData = JSON.parse(localData)
        localData.hash = user_id
      }

      localStorage.setItem('LOCAL_DATA', JSON.stringify(localData))

      if (arrBag) {
        await saveShoppingCart(arrBag)
      }

      try {
        amplitude.getInstance().setUserId(user_id)
        window.gtag('config', process.env.REACT_APP_GTAG, {
          user_id: user_id,
        })
        let userProperties = null
        if (isProfessional) {
          let identity_temp = localStorage.getItem('identity_temp')
          if (identity_temp?.rut) {
            usedProfessional(identity_temp?.rut).then((professional) => {
              window.gtag(
                'set',
                'user_type',
                _get(professional, 'data[0].user_type', 'user'),
              )
              userProperties = {
                'User Type': _get(professional, 'data[0].user_type', 'user'),
                'Account Type': provider,
              }
            })
          }
        } else {
          window.gtag('set', 'user_type', 'user')
          userProperties = {
            'User Type': 'user',
            'Account Type': provider,
          }
        }
        setUserProperties(userProperties)
      } catch (error) {
        console.log('Error set user gtag.')
      }

      dispatch(changeInitialLoading(false))
      await dispatch(setFormDataAccount())
      if (isProfessional) {
        await dispatch(changeConfig(res))
        dispatch(setUserExtraInfo(_get(res?.data, 'info', {})))
        let route = res?.data?.route
        if (route === '/search') {
          if (location?.label && location?.nro) {
            route =
              '/search/shopping_bag/' +
              Math.floor(Math.random() * Math.floor(100000000000000000000))
            return history.replace(route, {product: null, from: 'page'})
          }
        }
        history.replace(route, {site: res?.data})
      } else if (process.env.REACT_APP_PROFILE === 'PATIENT') {
        history.push(lastPage)
      } else {
        history.push('/shopping_bag/checkout')
      }
    } catch (error) {
      dispatch(changeInitialLoading(false))
      setIsLoading(false)
    }
  }

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
    if (page === 'login') {
      return history.push('/signin', {requiredValidate: true})
    }
  }

  async function registerAccount(skip = false) {
    try {
      if (
        ([undefined, null].includes(userInfo) ||
          userInfo?.signInMethod === 'anonymously') &&
        skip === false
      ) {
        return register('email')
      }
      let response = null
      if (formIsValid) {
        dispatch(changeInitialLoading(true))
        setButtonDisable(false)
        setRegisterError(false)
        let identity_temp = localStorage.getItem('identity_temp')
        let currentUser =
          skip === true
            ? JSON.parse(localStorage.getItem('login_temp_data'))
            : userInfo

        identity_temp = JSON.parse(identity_temp)

        response = await registerUser(
          email,
          password,
          name,
          lastname,
          identity_temp?.rut,
          identity_temp?.document,
          policies,
          currentUser,
        )
        if (response) {
          if (isProfessional) {
            await validProfessional(
              identity_temp.rut,
              currentUser?.profile?.uid,
            )
          }
          logEvent('Create Account Completed', {
            'Account Type': provider,
            'Account Created': true,
          })
          await dispatch(fetchUser())
          await loadConfig()
        }
      }
    } catch (e) {
      logEvent('Create Account Completed', {
        'Account Type': provider,
        'Account Created': false,
      })
      console.log(e)
    }
  }

  const onSignIn = () => {
    history.push('/signin', {requiredValidate: true})
  }

  return (
    <>
      <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0 mt-4">
        <div className="breadcrumbs c-pointer" onClick={() => history.goBack()}>
          <a className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </a>
          <span className="txt-4--regular-pr">Volver</span>
        </div>
      </div>
      {isLoading ? null : (
        <div className="flex-grow-1">
          <div className="content-responsive mx-auto px-3 px-md-0">
            <div className="maxw-300 mx-auto">
              <h2
                className={`txt-1--bold-db text-center ${
                  isProfessional ? 'mb-4' : null
                }`}>
                Crea tu cuenta
              </h2>
            </div>
            {([undefined, null].includes(userInfo) ||
              userInfo?.signInMethod === 'anonymously') && (
              <>
                <div className="maxw-300 mx-auto">
                  <p className="txt-3--regular-db text-center mb-4">
                    Continúa con
                  </p>
                </div>

                <div className="d-flex justify-content-between maxw-584 mx-auto">
                  <ButtonRss
                    rrss="apple"
                    className="mr-3"
                    onClick={() => register('apple')}
                  />
                  <ButtonRss
                    rrss="facebook"
                    className="mr-3"
                    onClick={() => register('facebook')}
                  />
                  <ButtonRss rrss="google" onClick={() => register('google')} />
                </div>
                <hr className="maxw-584 hr-o" data-color="#F4F6FF" />
              </>
            )}
            <div className="maxw-584 mx-auto">
              {registerError && (
                <div
                  id={'gtm-webapp-register-alert-message'}
                  className="alert-message">
                  <i
                    id={'gtm-webapp-register-alert-message-icon'}
                    className="icon-info-2"></i>
                  <p className="txt-6--bold-er ml-2">
                    La cuenta o contraseña que has introducido no es válida.
                    Intenta con una cuenta o contraseña diferente.
                  </p>
                </div>
              )}
              <form>
                <div className="d-flex flex-column flex-md-row">
                  <div className="w-100 mr-md-2">
                    <Input
                      id={'gtm-webapp-register-name'}
                      error={{message: ''}}
                      label="Nombre"
                      value={name}
                      doChange={(value) => setName(value)}
                    />
                  </div>
                  <div className="w-100">
                    <Input
                      id={'gtm-webapp-register-last-name'}
                      error={{message: ''}}
                      value={lastname}
                      label="Apellido"
                      doChange={(value) => setLastname(value)}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <Input
                    id={'gtm-webapp-register-email'}
                    error={
                      email?.length > 0 && inputError?.email
                        ? {message: '*El correo que ingresaste no es válido.'}
                        : ''
                    }
                    value={email}
                    // disabled={![undefined, null].includes(userInfo) || (userInfo?.signInMethod === 'anonymously')}
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    doChange={(value) => setEmail(value)}
                  />
                </div>
                {([undefined, null].includes(userInfo) ||
                  userInfo?.signInMethod === 'anonymously') && (
                  <div className="d-flex flex-column flex-md-row">
                    <div className="w-100 mb-2 mr-md-2">
                      <Input
                        id={'gtm-webapp-register-pass'}
                        showPass
                        label="Contraseña"
                        error={{message: ''}}
                        doChange={(value) => setPassword(value)}
                      />
                      <p className="txt-4--regular-g1 ml-3 mb-1 mt-2">
                        *Tu contraseña debe tener un mínimo de 8 caracteres.
                      </p>
                    </div>
                    <div className="w-100 mb-2">
                    <Input
                      id={'gtm-webapp-register-repeat-pass'}
                      showPass
                      label="Repetir contraseña"
                      error={
                        password?.length > 7 &&
                        password !== passwordConfirmation
                          ? {message: '*Tu contraseña no coincide.'}
                          : ''

                      }
                      doChange={(password) => setPasswordConfirmation(password)}
                    />
                    </div>
                  </div>
                )}
              </form>
              <div className="d-flex align-items-center mb-4 mt-3">
                <label
                  className="custom-checkbox-altern align-items-start pl-4 m-0"
                  id={'gtm-webapp-register-check-terms'}>
                  <input
                    id="gtm-webapp-register-checkbox"
                    ref={null}
                    disabled={false}
                    type="checkbox"
                    onChange={(evt) => setPolicies(evt.target.checked)}
                  />
                  <span className="checkmark-altern">
                    <i
                      id="gtm-webapp-register-checkbox-icon"
                      className="icon-checkmark"></i>
                  </span>
                </label>
                <span className="txt-4--regular-db ml-2 line-16">
                  Acepto los{' '}
                  <span
                    id="gtm-webapp-register-terms"
                    className="txt-5--regular-pr c-pointer"
                    onClick={() => goTo('terms')}>
                    Términos & Condiciones
                  </span>{' '}
                  y{' '}
                  <span
                    id="gtm-webapp-register-policies"
                    className="txt-5--regular-pr c-pointer"
                    onClick={() => goTo('policies')}>
                    Políticas de privacidad
                  </span>
                </span>
              </div>
              <ButtonYapp
                disabled={!buttonDisable}
                id={'gtm-webapp-register-btn'}
                className="btn-default--small btn-default font--14 size-content-responsive-btn mx-auto w-100 mb-4"
                onClick={() => registerAccount()}
                title={'Crear cuenta'}
              />

              <h3 className="txt-3--regular-db text-center w-100 mb-5">
                ¿Ya tienes una cuenta en Yapp?{' '}
                <span
                  id={'gtm-webapp-register-login-btn'}
                  className="txt-3--bold-pr semi-bold color-lilac c-pointer"
                  onClick={() => history.push('/login')}>
                  Iniciar sesión
                </span>
              </h3>
              <ModalError
                isOpen={modalServerError}
                setIsOpen={setModalServerError}
                imgName="icErrorServer"
                title="Lo sentimos"
                text="No podemos conectar con los servidores de Yapp en este momento..."
                btnText="Volver a intentar"
              />
              {/* <ModalError
                isOpen={modalAccountExistError}
                setIsOpen={setModalAccountExistError}
                imgName='icErrorServer'
                title='Lo sentimos'
                text='Su cuenta ya se encuentra registrada en nuestro sistema. Intente con la opción Iniciar Sesión del menú anterior'
                btnText='Ok'
              /> */}
              <ModalAccountRegistered
                modalAccountR={modalAccountExistError}
                setModalAccountR={setModalAccountExistError}
                onSignIn={onSignIn}
              />
              <ModalError
                isOpen={modalInternetError}
                setIsOpen={setModalInternetError}
                imgName="icErrorInternet"
                title="¡Ups!"
                text="Te has quedado sin internet. Por favor verifica tu conexión..."
                btnText="Volver a intentar"
              />
              <ModalError
                isOpen={modalSocialNetworkError}
                setIsOpen={setModalSocialNetworkError}
                imgName="icErrorServer"
                title="¡Ups!"
                text="No hemos podido conectar con tu cuenta"
                btnText="Volver a intentar"
                className="mx-4"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    isPro: state.app.isPro,
  }
}

export default connect(mapStateToProps)(Register)
