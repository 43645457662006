import React, {useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'
import {fetchUser} from '../../redux/modules/user'

import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import {_formatRut, _validateRut, _validateMail} from '../../Utils/tools'
import {useHistory} from 'react-router'

import {registerPassword, resetPassword, registerUser} from '../../Utils/auth'
import {validateProfessional, validProfessional} from '../../Utils/api'
import {
  sendDoctorValidationEmail,
  sendReportAccountEmail,
} from '../../Utils/emails'
import {logEvent} from '../../Utils/utils'

import Input from '../../Components/Forms/Input'
import imgEresMedico from '../../assets/img/fondo_banner.svg'
import imgDrLogin from '../../assets/img/dr-login.png'
import imgPC from '../../assets/img/bro.svg'
import imgTEL from '../../assets/img/Device.svg'
import imgMG from '../../assets/img/lupa.svg'
import imgMED from '../../assets/img/Interactions.svg'
import imgROCKET from '../../assets/img/rocket.svg'
import imgLiDoc from '../../assets/img/dr.svg'
import img4Med from '../../assets/img/remedios.svg'

import ModalRegiteredRut from '../../Components/Modals/ModalRegiteredRut'
import ModalFailValidate from '../../Components/Modals/ModalFailValidate'
import ModalAccountRegistered from '../../Components/Modals/ModalAccountRegistered'
import ModalError from '../../Components/Modals/ModalError'
import ModalProRegister from '../../Components/Modals/ModalProRegister'


function RegistrerProStandAlone() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)
  const [rut, setRut] = useState()
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [validRut, setValidRut] = useState(false)
  const [policies, setPolicies] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(true)

  const [modalFailValidateState, setModalFailValidateState] = useState(false)
  const [modalRegisteredRutState, setModalRegisteredRutState] = useState(false)
  const [modalAccountExistError, setModalAccountExistError] = useState(false)
  const [modalAccountError, setModalAccountError] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let rawRut = params.get('rut')
    if (![undefined, null, 'NULL'].includes(rawRut)) {
      validateParams(rawRut)
    }
  }, [])

  const validateParams = async (rawRut) => {
    setButtonDisable(true)
    const result = await validateProfessional(_formatRut(rawRut), ' ')
    let data = result?.data?.length > 0 ? result?.data[0] : null
    let validated = data?.status === 'available' ? 'True' : 'False'
    let status =
      data?.status === 'available'
        ? 'Available'
        : data?.status === 'used'
        ? 'Used'
        : 'Failured'

    if (validated === 'True') {
      logEvent('Account Validated', {
        Status: status,
        Validated: validated,
      })
    }
    onResults(result)
    setButtonDisable(false)
  }
  const onChangeIdentity = (value) => {
    let format = _formatRut(value)
    let rutValidation = _validateRut(value)
    setRut(format || value)
    setValidRut(rutValidation)
    if (rutValidation === true) {
      setButtonDisable(false)
    }
  }

  const validateUser = async () => {
    setButtonDisable(true)
    const result = await validateProfessional(rut, ' ')
    let data = result?.data?.length > 0 ? result?.data[0] : null
    let validated = data?.status === 'available' ? 'True' : 'False'
    let status =
      data?.status === 'available'
        ? 'Available'
        : data?.status === 'used'
        ? 'Used'
        : 'Failured'

    if (validated === 'True') {
      logEvent('Account Validated', {
        Status: status,
        Validated: validated,
      })
    }
    onResults(result)
    setButtonDisable(false)
  }

  const validateUserInfo = async () => {
    setButtonDisable(true)
    if (_validateMail(email)) {
      const provider = 'email'
      let identityDocument = ''
      let nameArray = userName.split(' ')
      let response = await registerPassword(
        email,
        password,
        nameArray[0],
        nameArray[1],
      )

      if (response.status === 'already-account') {
        setModalAccountExistError(true)
      } else if (response.status === 'success') {
        return registerUserInfo(
          provider,
          nameArray[0],
          nameArray[1],
          identityDocument,
        )
      } else {
        setModalAccountError(true)
      }
    }
    setButtonDisable(false)
  }
  const registerUserInfo = async (
    provider,
    firstName,
    lastName,
    identityDocument,
  ) => {
    await dispatch(fetchUser())
    let currentUser = JSON.parse(localStorage.getItem('login_temp_data'))
    let response = await registerUser(
      email,
      password,
      firstName,
      lastName,
      rut,
      identityDocument,
      policies,
      currentUser,
    )

    if (response) {
      await validProfessional(rut, currentUser?.profile?.uid)
      logEvent('Create Account Completed', {
        'Account Type': provider,
        'Account Created': true,
      })
      goTo('home')
    } else {
      setModalAccountError(true)
    }
    setButtonDisable(false)
  }

  const retryValidation = () => {
    setModalFailValidateState(false)
    validateUser()
  }

  const onResults = (result) => {
    if (result.data.user_id === null && result.data.exist === 1) {
      setStep(2)
      logEvent('Validated Doctor', {
        Doctor:'True',
        'User Type':	'New User',
      })
    } else if (result.data.exist === 0) {
      setModalAccountError(true)
      logEvent('Validated Doctor', {
        Doctor:'False',
        'User Type':	' ',
      })
    } else if (result.data.user_id !== null) {
      setModalRegisteredRutState(true)
      logEvent('Validated Doctor', {
        'User Type':	'Existing User',
        Doctor:'True'
      })
    } else {
      setModalFailValidateState(true)
    }
  }

  const validUserInfo = () => {
    return !(
      policies &&
      validRut &&
      _validateMail(email) &&
      userName?.length > 2 &&
      password?.length > 7 &&
      password === passwordConfirmation
    )
  }

  const handleDoctorValidationEmail = async (email) => {
    try {
      const result = await sendDoctorValidationEmail(rut, email)
      return result.status === 204 ? true : false
    } catch (error) {
      return false
    }
  }

  const handleReportAccountEmail = async (email) => {
    try {
      const result = await sendReportAccountEmail(email)
      return result.status === 204 ? true : false
    } catch (error) {
      return false
    }
  }

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
    if (page === 'home') {
      return history.push('/pro/menu')
    }
    if (page === 'login') {
      return history.push('/signin', {requiredValidate: true})
    }
    if (page === 'register') {
      return history.push('/register_pro')
    }
  }

  return(
    <>
      <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0 mt-12">
        <div id="unete">
          {step === 1 && (
            <div className="content-form-register-pro p-4">
              <div className="content-form-register-pro-spaces p-0 p-md-4">
                <div className="txt-2--bold-db text-center mb-3">
                  ¿Ya tienes una cuenta?
                </div>
                <div
                  className="txt-3--bold-pr w-75 mx-auto my-2 py-2 px-2 text-white c-pointer text-center"
                  onClick={() => goTo('login')}
                  style={{
                    backgroundColor: '#6578FF',
                    borderRadius: 20,
                  }}>
                  Inicia Sesión
                </div>
                <div className="mb-3">
                  <div className="txt-2--bold-db mt-4 mb-1">Crear cuenta</div>
                  <div className="txt-4--regular-db mb-2">
                    Ingresa tu Rut para validarte como médico
                  </div>
                  <Input
                    id="gtm-webapp-rut-validate"
                    error={
                      !validRut && rut?.length > 0 && {message: '*rut inválido'}
                    }
                    label="Ingresa tu RUT"
                    placeholder="Ingresa tu RUT"
                    value={rut || ''}
                    doChange={(value) => onChangeIdentity(value)}
                  />
                </div>
                <ButtonYapp
                  title="Crear cuenta"
                  className="w-75 mx-auto mb-3 is-stroke"
                  onClick={validateUser}
                  disabled={buttonDisable}
                />
                <p className="txt-5--regular  text-center">
                  Para validar si eres médico, utilizamos la base de datos de la
                  Superintendencia de Salud.
                </p>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h4 className="txt-title text-center maxw-500 py-4">
                Te hemos validado como médico, por favor llena el formulario para
                registrarte
              </h4>
              <div className="content-form-register-pro p-4 m-0-auto">
                <h4 className="title-widget pt-3 pb-2">Crear una cuenta</h4>
                <div className="">
                  <div className="mb-2">
                    <Input
                      id="gtm-webapp-email-register"
                      error={false && {message: '*Correo inválido'}}
                      label="Correo"
                      placeholder="Correo"
                      value={email || ''}
                      doChange={(email) => setEmail(email)}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      id="gtm-webapp-name-register"
                      error={false && {message: '*Nombre inválido'}}
                      label="Nombre y apellido"
                      placeholder="Nombre y apellido"
                      value={userName || ''}
                      doChange={(name) => setUserName(name)}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      id="gtm-webapp-rut-register"
                      label="RUT"
                      placeholder="RUT"
                      value={rut || ''}
                    />
                  </div>
                  <div className="w-100 mb-2">
                    <Input
                      id={'gtm-webapp-register-pass'}
                      showPass
                      label="Contraseña"
                      error={{message: ''}}
                      doChange={(password) => setPassword(password)}
                    />
                    <p className="txt-4--regular-g1 mb-1 mt-2">
                      *Tu contraseña debe tener un mínimo de 8 caracteres.
                    </p>
                  </div>
                  <div className="w-100 mb-2">
                    <Input
                      id={'gtm-webapp-register-repeat-pass'}
                      showPass
                      label="Repetir contraseña"
                      error={
                        password?.length > 7 && password !== passwordConfirmation
                          ? {message: '*Tu contraseña no coincide.'}
                          : ''
                      }
                      doChange={(password) => setPasswordConfirmation(password)}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-4 mt-3">
                    <label
                      className="custom-checkbox-altern align-items-start pl-4 m-0"
                      id={'gtm-webapp-register-check-terms'}>
                      <input
                        id="gtm-webapp-register-checkbox"
                        ref={null}
                        disabled={false}
                        type="checkbox"
                        onChange={(evt) => setPolicies(evt.target.checked)}
                      />
                      <span className="checkmark-altern">
                        <i
                          id="gtm-webapp-register-checkbox-icon"
                          className="icon-checkmark"
                        />
                      </span>
                    </label>
                    <span className="txt-4--regular-db  line-16 font--14 ">
                      Acepto los{' '}
                      <span
                        id="gtm-webapp-register-terms"
                        className="txt-5--regular-pr c-pointer font--14"
                        onClick={() => goTo('terms')}>
                        Términos & Condiciones
                      </span>{' '}
                      y{' '}
                      <span
                        id="gtm-webapp-register-policies"
                        className="txt-5--regular-pr c-pointer font--14"
                        onClick={() => goTo('policies')}>
                        Políticas de privacidad
                      </span>
                    </span>
                  </div>
                  <ButtonYapp
                    /* disabled={buttonDisable || validUserInfo()} */
                    id={'gtm-webapp-register-btn'}
                    className="btn-default--small btn-default font--16 size-content-responsive-btn mx-auto w-100 mb-4"
                    onClick={validateUserInfo}
                    title={'Crear cuenta'}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <ModalRegiteredRut
          modalRegisteredRutState={modalRegisteredRutState}
          email={email}
          setModalRegisteredRutState={setModalRegisteredRutState}
          resetPassword={() => resetPassword(email)}
          sendEmail={(email) => handleReportAccountEmail(email)}
        />
        <ModalFailValidate
          modalFailValidateState={modalFailValidateState}
          setModalFailValidateState={setModalFailValidateState}
          sendEmail={(email) => handleDoctorValidationEmail(email)}
          retry={retryValidation}
        />
        <ModalAccountRegistered
          modalAccountR={modalAccountExistError}
          setModalAccountR={setModalAccountExistError}
          onSignIn={() => goTo('login')}
        />
        <ModalError
        isOpen={modalAccountError}
        setIsOpen={setModalAccountError}
        imgName="icAlertError"
        title=""
        text="Al parecer tu Rut no está inscrito en la base de datos de la Superintendencia de Salud. "
        btnText="Cerrar"
      />
      </div>
    </>
  )
}

export default RegistrerProStandAlone
