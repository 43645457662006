import {Button} from 'react-bootstrap'
import {useHistory} from 'react-router'
import Location from '../../Components/Location'

function Page(props) {
  const history = useHistory()
  return (
    <>
      <div className="d-flex justify-content-between py-3 mx-2">
        <div className="breadcrumbs">
          <div
            onClick={() => history.goBack()}
            className="d-flex align-items-center">
            <i className="icon-arrow-back"></i>
          </div>{' '}
          <span>Cambiar dirección</span>
        </div>
      </div>
      <div className="mb-4 mx-3">
        <div className="content-location mb-3">
          <Location />
        </div>
        <div className="d-flex w-100 justify-content-center pt-2">
          <Button
            className="btn-default--small btn-next m-auto"
            variant="primary"
            onClick={() => history.goBack()}>
            Volver
          </Button>
        </div>
      </div>
    </>
  )
}

export default Page
