import Modal from 'react-modal'
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import StepperLocal from '../../Components/Stepper'
import { _formatPrice } from '../../Utils/tools'
import { getComplementary } from '../../Utils/api'
import _get from 'lodash/get'

import loadingYapp from '../../assets/img/yapp_loading.gif'
import { getComplementaryCart, saveComplementaryCart } from '../../Utils/service'

const ProductSupplementary = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [productsSelected, setProductsSelected] = useState([])
  const [isShowedPdf, setIsShowed] = useState(false)

  useEffect(() => {
    setLoading(true)
    getComplementary(props.commune?.id).then(async (resp) => {
      if (resp.data) {
        let quotation = _get(resp, 'data[0].quotation_detail', null)
        let delivery = _get(resp, 'data[0].delivery', null)
        setProducts(quotation)
        props.setHasComplementary(quotation ? true : false)
        props.setComplementaryDelivery(delivery ? delivery : null)
      }

      await refreshCheckbox()
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })

  }, [])

  async function refreshCheckbox() {
    let dataComplementary = await getComplementaryCart()
    setProductsSelected(dataComplementary)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
      closeModal: closeModal
    }
  })

  const selectProduct = async (action, item, quantity) => {
    if (action === true) {
      if (!productsSelected.some(el => el.product_id === item?.product.product_id)) {
        let newArray = productsSelected
        newArray.push({
          ...item?.product,
          price: item?.price_with_discount,
          price_id: item?.price_id,
          fractional_price: item?.fractional_price,
          quantity: quantity ? quantity : 1
        })
        setProductsSelected([...newArray])
      } else {
        let newArray = productsSelected
        if (quantity > 0) {
          newArray = newArray.map(el => {
            if (el.product_id === item?.product.product_id) {
              return {
                ...item?.product,
                price: item?.price_with_discount,
                price_id: item?.price_id,
                fractional_price: item?.fractional_price,
                quantity: quantity ? quantity : 1
              }
            }
            return el
          })
          setProductsSelected([...newArray])
        } else {
          setProductsSelected(productsSelected.filter(el => el.product_id !== item?.product.product_id))
        }
      }
    } else {
      setProductsSelected(productsSelected.filter(el => el.product_id !== item?.product.product_id))
    }
  }

  async function saveComplementary() {
    setLoading(true)
    await saveComplementaryCart(productsSelected)
    props.closeModal(productsSelected)
    setLoading(false)
    closeModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => document.body.style.overflow = 'hidden'}
      onAfterClose={() => document.body.style.overflow = 'unset'}
      onRequestClose={closeModal}
      className='modal-custom p-0'
      overlayClassName='modal-custom-bg'
      contentLabel='Modal1'
      preventScroll={true}
      ariaHideApp={false}
      style={{
        top: '200%',
      }}
    >
      <div className='d-flex flex-column justify-content-center align-items-center background-green pb-3 pt-4 common-top-radius'>
        <p className='title-primary--xl color-white text-center'>Productos complementarios</p>
      </div>
      <div className='pl-3 pr-2'>
        <p className='txt-paragraph my-3'>¿Quieres agregar alguno de estos productos a tu compra?</p>
        <p className='txt-line--bold color-green pb-3'>Precio preferencial para pacientes Clínica Alemana</p>
        <div className='overflow-y-auto modal-inside'>
          {loading && <img src={loadingYapp} alt='loading' className='loading-yapp--alternative' />}
          {
            products?.map(item => {
              let currentItemSelected = productsSelected.filter(el => el.product_id === item?.product.product_id).shift()
              return (
                <div className='d-flex align-items-center pb-3'>
                  <label className='custom-checkbox'>
                    <input type='checkbox' checked={productsSelected.some(el => el.product_id === item?.product.product_id)} onChange={(event) => selectProduct(event.target.checked, item)} />
                    <span className='checkmark'><i className='icon-checkmark'></i></span>
                  </label>
                  <div className='product-border p-1' onClick={() =>{
                    if(![undefined, null, ''].includes(item?.product?.data_sheet)){
                      setIsShowed(true)
                      props.clickShowPdf(item?.product?.data_sheet)
                    }
                  }}>
                    <img src={item?.product?.logo} className='img-with-border' alt='products' />
                  </div>
                  <div className='w-50 mx-2'>
                    <p className='txt-line--bold pb-1'>{item?.product?.name}</p>
                    <p className='txt-sufix-through color-gray-4 pb-1'>{_formatPrice(item?.price)}</p>
                    <p className='txt-line color-green'>{_formatPrice(item?.price_with_discount)}</p>
                  </div>
                  <div className='pr-1'>
                    <StepperLocal counter={currentItemSelected?.quantity ? currentItemSelected.quantity : 0} clickUpdateCounter={(counter) => {
                      selectProduct((counter > 0), item, counter)
                    }} />
                  </div>
                </div>
              )
            })
          }

          {
            [undefined, null].includes(products) && <p className='txt-line pb-1 pt-3'>Sin productos.</p>
          }

        </div>
        <p className='txt-line color-green pb-1 pt-3'>Costo de despacho extra: $3.990</p>
        <p className='txt-line color-green pb-4'>(Llegará en un pedido separado al de tus medicamentos)</p>
        <div className='d-flex justify-content-center align-items-center flex-column px-2'>
          <Button className='btn-default--small max-w-none mb-3' variant='primary' disabled={false} onClick={saveComplementary}>
            Agregar a la compra
          </Button>
          <Button className='btn-default--small btn-stroke max-w-none mb-3' variant='primary' onClick={() => props.onCloseModal()}>
            Continuar sin agregar más productos
          </Button>
        </div>
      </div>
    </Modal>
  )
})

export default ProductSupplementary