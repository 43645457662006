import logo from '../../assets/img/pill_waiting.png'
import loadingYapp from '../../assets/img/yapp_loading.gif' 

const LoadingLoginAccount = () => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='d-flex justify-content-center w-50 pb-5'>
        <img src={loadingYapp} alt="loading" className='loading-yapp--normal'/>
      </div>
      <div>
        <h2 className='title-primary--xxl mb-5'>En unos segundos entrarás a tu cuenta</h2>
        <div className='d-flex justify-content-center mb-5'>
          <img src={logo} alt="Loading" />
        </div>
      </div>
    </div>
  )
}

export default LoadingLoginAccount