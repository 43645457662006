import propTypes from 'prop-types'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Geocode from 'react-geocode'
import {addEvent} from '../../Utils/utils'
import { theme } from '../../Utils/constants'

const _ = require('lodash')

Geocode.setApiKey('AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw')
Geocode.setLanguage('es-419')
Geocode.setRegion('cl')

function LocationAutocomplete({locationInput, onBlur, onFocus, onPress}) {
  async function setLocationFromInput(el) {
    try {
      let item
      let geoResp = await Geocode.fromAddress(el.label)
      let results = _.get(geoResp, 'results[0].address_components')

      let locality = null
      let locality_aux = null
      let street_number = null
      let region = null
      let label = null

      for (const key in results) {
        if (results.hasOwnProperty(key)) {
          const element = results[key]
          let type = _.get(element, 'types')

          if (_.includes(type, 'administrative_area_level_1')) {
            region = element.long_name
          }
          if (_.includes(type, 'administrative_area_level_3')) {
            locality = element.long_name
          }
          if (_.includes(type, 'locality')) {
            locality_aux = element.long_name != 'Santiago de Chile'
              ? element.long_name
              : 'Santiago'
          }
          if (_.includes(type, 'street_number')) {
            street_number = element.long_name
          }
        }
      }

      locality = locality ?? locality_aux
      label =  el.label

      if (label?.length > 0 && locality?.length > 0) {
        let indexOfLocality = label.lastIndexOf(locality) + locality.length
        label = label.substr(0, indexOfLocality)
      }

      let lat = geoResp.results[0].geometry.location.lat
      let lng = geoResp.results[0].geometry.location.lng
      item = {
        region: region,
        label: label,
        lat: lat,
        lng: lng,
        locality: locality,
        street_number: street_number,
      }
      onPress(el, item)
    } catch (error) {
      console.log(error)
    }
  }

  function onFocusGoogle(el) {
    onFocus(el)
    addEvent('location_search_focus')
  }

  const searchStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius_input,
      height: '48px',
      paddingLeft: '16px',
      zIndex: 1050,
      border: '1px solid #E9E6FE;',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '10px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'var(--color-gray1-ds)',
      fontSize: '10px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
    }),
  }
  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw"
      apiOptions={{language: 'es-419', region: 'CL'}}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['cl'],
        },
      }}
      selectProps={{
        locationInput,
        placeholder: 'Calle (Ej: Apoquindo)',
        noOptionsMessage: (item) => {
          let msgLoc = ''
          if (item.inputValue === '') {
            msgLoc = 'Ingresa una dirección'
          } else {
            msgLoc =
              'No se encontraron resultados, por favor intentalo nuevamente'
          }
          return msgLoc
        },
        onChange: setLocationFromInput,
        onFocus: onFocusGoogle,
        styles: searchStyle,
      }}
    />
  )
}

LocationAutocomplete.propTypes = {
  onBlur: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
}

export default LocationAutocomplete
