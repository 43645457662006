import firebase from 'firebase/app'
import 'firebase/firestore'
import _, {now} from 'lodash'
import {getHash, getCurrentDate, getJWTdata} from '../Utils/tools'
const _get = require('lodash/get')

const PRE_ORDER_STATUS = {
  WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
}

function getClientId() {
  let clientId = null
  try {
    clientId = process.env.REACT_APP_CLIENT_ID
  } catch (error) {
    console.error('Failed to get client id')
  }
  return clientId
}

export const saveShoppingCart = async (cart) => {
  try {
    let hash = getHash()
    let clientId = getClientId()

    return await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('shopping_bag')
      .doc(hash)
      .set({
        products: cart,
        updated_at: getCurrentDate(),
      })
  } catch (error) {
    console.log('saveShoppingCart Error', error)
    return false
  }
}

export const getShoppingCart = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('shopping_bag')
      .doc(hash)
      .get()
    let data = resp.data()
    let cart = Array.isArray(data?.products) ? data?.products : []
    return cart
  } catch (error) {
    console.log('getShoppingCart Error', error)
    return false
  }
}

export const saveExamsCart = async (cart) => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    return await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('exams_bag')
      .doc(hash)
      .set({
        exams: cart,
        updated_at: getCurrentDate(),
      })
  } catch (error) {
    console.log('saveExamsCart Error', error)
    return false
  }
}

export const getExamsCart = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('exams_bag')
      .doc(hash)
      .get()
    let data = resp.data()
    let cart = Array.isArray(data?.exams) ? data?.exams : []
    return cart
  } catch (error) {
    console.log('getExamsCart Error', error)
    return false
  }
}

export const getRecentlyViewed = async () => {
  try {
    let products = []
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('web_app_user')
      .doc(hash)
      .collection('recently_viewed')
      .orderBy('updated_at', 'desc')
      .limit(10)
      .get()

    for (const doc of resp.docs) {
      products.push({...doc.data()})
    }

    products = _.orderBy(products, (product) => product.updated_at.seconds, [
      'desc',
    ])

    return products
  } catch (error) {
    console.log('getShoppingCart Error', error)
    return false
  }
}

export const saveRecentlyViewed = async (products) => {
  try {
    let cont = 0

    products = products.map((product) => {
      product.updated_at = new Date(new Date().setMilliseconds(cont++))
      return product
    })

    let hash = getHash()
    let clientId = getClientId()

    products.forEach(async (product) => {
      await firebase
        .firestore()
        .collection('web_app')
        .doc(clientId)
        .collection('web_app_user')
        .doc(hash)
        .collection('recently_viewed')
        .doc(product.product_id)
        .set(product)
    })
  } catch (error) {
    console.log('saveComplementaryCart Error', error)
    return false
  }
}

export const saveComplementaryCart = async (complementaryCart) => {
  try {
    let hash = getHash()
    let clientId = getClientId()

    let currentBag = (
      await firebase
        .firestore()
        .collection('web_app')
        .doc(clientId)
        .collection('shopping_bag')
        .doc(hash)
        .get()
    ).data()
    if (currentBag) {
      let elementToUpdate = {
        products: currentBag?.products,
        complementary: complementaryCart,
        updated_at: getCurrentDate(),
      }

      await firebase
        .firestore()
        .collection('web_app')
        .doc(clientId)
        .collection('shopping_bag')
        .doc(hash)
        .update(elementToUpdate)
    } else {
    }
  } catch (error) {
    console.log('saveComplementaryCart Error', error)
    return false
  }
}

export const getComplementaryCart = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('shopping_bag')
      .doc(hash)
      .get()
    let data = resp.data()
    let complementary = Array.isArray(data?.complementary)
      ? data?.complementary
      : []
    return complementary
  } catch (error) {
    console.log('getComplementaryCart Error', error)
    return false
  }
}

export const getCount = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('shopping_bag')
      .doc(hash)
      .get()
    let data = resp.size
    let cart = Array.isArray(data?.products) ? data?.products : []
    return cart
  } catch (error) {
    console.log('getShoppingCart Error', error)
    return false
  }
}

export const getCampaign = async (data) => {
  try {
    let campaign = await firebase
      .firestore()
      .collection('campaigns')
      .doc(data)
      .get()
    return campaign.data()
  } catch (error) {
    console.log('getCampaign Error', error)
    return false
  }
}

export const createPreOrder = async (data, hasPaymentMehod) => {
  let status = hasPaymentMehod
    ? PRE_ORDER_STATUS.WAITING_FOR_CONFIRMATION
    : PRE_ORDER_STATUS.WAITING_FOR_PAYMENT

  try {
    let hash = getHash()
    let clientId = getClientId()
    await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('pre_order')
      .doc(hash)
      .set({
        data: data,
        status: status,
        updated_at: getCurrentDate(),
      })
  } catch (error) {
    console.log('createPreOrder Error', error)
    return false
  }
}

export const getPreOrder = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('pre_order')
      .doc(hash)
      .get()
    return resp.data()
  } catch (error) {
    console.log('getPreOrder Error', error)
    return false
  }
}

export const removeShoppingCart = async () => {
  try {
    let hash = getHash()
    let clientId = getClientId()
    await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('shopping_bag')
      .doc(hash)
      .delete()
  } catch (error) {
    console.log('removeShoppingCart Error', error)
    return false
  }
}

export const getUserInfo = async (clientId = null, jwtToken = null) => {
  try {
    const jwtData = getJWTdata(jwtToken)
    if (!clientId) clientId = getClientId()
    let resp = await firebase
      .firestore()
      .collection('web_app')
      .doc(clientId)
      .collection('web_app_user')
      .doc(jwtData?.user_id)
      .get()
    let data = resp.data()
    return data
  } catch (error) {
    console.log('getUserInfo Error', error)
    return false
  }
}

export const getUser = async (uid) => {
  try {
    let resp = await firebase.firestore().collection('users').doc(uid).get()
    let data = resp.data()
    return data
  } catch (error) {
    console.log('getUserInfo Error', error)
    return false
  }
}

export const saveLocation = async (location) => {
  try {
    const jwtData = getJWTdata()
    if (location && jwtData?.user_id) {
      let clientId = getClientId()
      if (
        (
          await firebase
            .firestore()
            .collection('web_app')
            .doc(clientId)
            .collection('web_app_user')
            .doc(jwtData?.user_id)
            .get()
        ).data()
      ) {
        await firebase
          .firestore()
          .collection('web_app')
          .doc(clientId)
          .collection('web_app_user')
          .doc(jwtData?.user_id)
          .update({location: location})
      } else {
        await firebase
          .firestore()
          .collection('web_app')
          .doc(clientId)
          .collection('web_app_user')
          .doc(jwtData?.user_id)
          .set({location: location})
      }

      try {
        let userData = JSON.parse(sessionStorage.getItem('USER_DATA'))
        userData.address = location.label
        sessionStorage.setItem('USER_DATA', JSON.stringify(userData))
      } catch (ignore) {}
    } else {
      console.log('Undefined location')
    }
  } catch (error) {
    console.log('saveLocation Error', error)
    return false
  }
}
