import Modal from 'react-modal'
import {useDispatch} from 'react-redux'
import {useState, forwardRef, useImperativeHandle} from 'react'
import {Button} from 'react-bootstrap'
import ImageUploader from 'react-images-upload'
import Remover from '../../assets/img/remover.svg'

const ModalUploadHome = forwardRef((props, ref) => {
  const [personalDocument, setPersonalDocument] = useState([])
  const [otherDocument, setOtherDocument] = useState([])

  const onDropPersonalDocument = (personalDocument) => {
    setPersonalDocument(personalDocument)
  }
  const onDropOtherDocument = (otherDocument) => {
    setOtherDocument(otherDocument)
  }

  function nextAction() {
    let tmp = personalDocument.concat(otherDocument)
    let documents = []

    for (const key in tmp) {
      const element = tmp[key]
      documents.push({
        product_id: null,
        image: element,
      })
    }

    props.setDocuments(documents)
  }

  const fileContainerStyle = {
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    transition: 'none',
    borderRadius: 0,
    alignItems: 'start',
    display: 'block',
  }

  const fileButtonStyles = {
    background: 'var(--color-white)',
    border: '1px solid var(--color-primary)',
    color: 'var(--color-primary)',
    textAlign: 'center',
    borderRadius: '2rem',
    fontSize: 'var(--font-12)',
    lineHeight: '19px',
    margin: '0',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={null}
      onRequestClose={props.closeModal}
      className="modal-custom p-0"
      overlayClassName="modal-custom-bg"
      contentLabel="Modal1"
      ariaHideApp={false}
      id="gtm-webapp-modal-checkout-inscription">
      <div className="d-flex flex-column justify-content-center align-items-center pt-5 common-top-radius">
        <a onClick={props.closeModal}>
          <img src={Remover} className="icon-remover" alt="Cerrar" />
        </a>
        <div className="pb-3 px-3">
          <p className="title-primary--xxl text-center">
            ¡Inscríbete como vecino/a
          </p>
          <p className="title-primary--xxl text-center mb-4">
            de {process.env.REACT_APP_CLIENT_NAME} !
          </p>
          <p className="txt-line-lh mb-1">
            1.{' '}
            <span className="txt-line--bold-lh">
              Agrega una foto de tu cédula de identidad.{' '}
            </span>
            En caso del paciente ser un{' '}
            <span className="txt-line--bold-lh">menor de edad</span>, se deben
            agregar cédulas del menor y de su madre/padre.
          </p>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <ImageUploader
              withIcon={false}
              buttonText="Agregar foto"
              onChange={onDropPersonalDocument}
              label={''}
              imgExtension={['.jpg', '.jpeg', '.png']}
              maxFileSize={5242880}
              withPreview={true}
              fileContainerStyle={fileContainerStyle}
              buttonStyles={fileButtonStyles}
              buttonClassName={'gtm-webapp-modal-checkout-upload-id'}
            />
          </div>
          <p className="txt-line--bold-lh mt-4">
            2. Agrega una foto de documento que acredite que paciente vive en{' '}
            {process.env.REACT_APP_CLIENT_NAME}
          </p>
          <p className="txt-line-lh mb-1">
            Ej: Foto cuenta de luz o tarjeta de vecino
          </p>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <ImageUploader
              withIcon={false}
              buttonText="Agregar foto"
              onChange={onDropOtherDocument}
              label={''}
              imgExtension={['.jpg', '.jpeg', '.png']}
              maxFileSize={5242880}
              withPreview={true}
              fileContainerStyle={fileContainerStyle}
              buttonStyles={fileButtonStyles}
              buttonClassName={'gtm-webapp-modal-checkout-upload-address'}
            />
          </div>
          <div className="d-flex w-100 justify-content-center mt-3">
            <Button
              id="gtm-webapp-button-checkout-inscription"
              className="btn-default width-auto px-4"
              disabled={
                personalDocument.length === 0 || otherDocument.length === 0
              }
              onClick={nextAction}>
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default ModalUploadHome
