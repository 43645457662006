import { useEffect, useState } from 'react'
import useScreenSize from '../../Hooks/useScreenSize'
import BenefitCards from './BenefitCards'
import './SectionBenefitCards.css'

function SectionBenefitCards({ products }) {
  const {width, height} = useScreenSize()
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    if (width > 768) {
      setIsMobile(false)
    } else if (width > 380 && width <= 768) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [width])

  return (
    process.env.REACT_APP_CLIENT_NAME === 'Sura' && (
      <div className='p-3'>
        <div className={`text-center ${isMobile ? 'maxw-250' : 'maxw-500'} mx-auto mt-5`}>
          <h2 className="title-home-sura mb-2 mb-2">
            Medicamentos con beneficios SURA
          </h2>
          <hr className='hr-separation'></hr>
        </div>
        <div className='my-5'>
          <p className={"subtitle-home-sura px-3 text-center"}>
            Revisa la lista completa de medicamentos con descuento adicional{' '}
            <a target="_blank"
              href='http://www.yapp.cl/wp-content/uploads/2023/04/SURA_Listado-Productos-Antiseguro_marzo2023.pdf'>
              aquí
            </a>
          </p>
        </div>
        <div className='section-benefits-cards'>
          {products.map((product, index) => {
            return(
              <div key={index}>
                <BenefitCards data={product} />
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}

export default SectionBenefitCards