import React from 'react'
import './BadgesRefundable.css'
import ReactTooltip from 'react-tooltip'

function BadgesRefundable(props) {
  const {label, className, color, dataTip} = props
  const backgroundColor = (![null,undefined,'','FALSE'].includes(color)) ? color : null

  return (
    <>
      <div
        className={`badges-refundable txt-5--regular
          ${className ? className : ''}`
        }
        style={backgroundColor && { backgroundColor: backgroundColor }}
        data-tip={dataTip || ''}
        data-for={dataTip ? "tootip-badge" : ""}
      >
        <span className="text-ellipsis">{label}</span>
      </div>
      <ReactTooltip
        id="tootip-badge"
        className="tooltip-style shadow rounded-3 title-primary--regular p-3 maxw-290"
        effect="solid"
        place="top"
        backgroundColor="#ffffff"
        textColor="#000000"
        arrowColor="#FFFFFF"
      />
    </>
  )
}

export default BadgesRefundable