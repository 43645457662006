import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import Modal from 'react-modal'
import {clearUserState} from '../redux/modules/user'
import {clearAppState} from '../redux/modules/app'
import { _formatRut, _validateMail, _validateRut } from '../Utils/tools'
import { existUserClient } from '../Utils/api'

import {
  changeInitialLoading,
  setIsPro,
  changeConfig,
} from '../redux/modules/app'
import {setFreeShipping, setDeliveryCode} from '../redux/modules/delivery'

import {
  setBasicInfo,
  setDocumentsPending,
  setUserExtraInfo,
} from '../redux/modules/user'
import icErrorServer from '../assets/img/error_server.svg'
import imgVidaCamaraHeart from '../assets/img/vida_camara_heart.png'
import DiscountCard from '../Components/Card/DiscountCard'
import Input from '../Components/Forms/Input'
import ButtonYapp from '../Components/Buttons/ButtonYapp'
import LoadingSura from '../Components/Loading/LoadingSura'
import { pharmaciesLogos, suraBenefitsPublicUrl } from '../Utils/constants'
import {logEvent} from '../Utils/utils'
import {getApiToken, signOut} from '../Utils/auth'
import TypeSidetab from '../Components/TypeForm/TypeSidetab'
import check from '../assets/img/checkblue.png'
import Carousel from '../Components/Carousel/Carousel'

import checkBg from '../assets/img/Shape.png'
import InsuranceBenefits from '../Components/BenefitCards/InsuranceBenefits'
import SectionBenefitCards from '../Components/BenefitCards/SectionBenefitCards'

import pig from '../assets/img/pig.png'
import biceVidaPet from '../assets/img/bice-vida-welcome.png'
import farmaciaFacil from '../assets/img/farmacia-facil.png'
import useScreenSize from '../Hooks/useScreenSize'

const apiLogin = process.env.REACT_APP_EXTERNAL_VALID_TYPE === 'API';
const clientName = process.env.REACT_APP_CLIENT_NAME  || 'Pacientes';
const isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE';
const onLogin = process.env.REACT_APP_ON_LOGIN  === 'TRUE';
const suraLoader = clientName === 'Sura';
const vidaCamaraLoader = clientName === 'Vida Cámara';

const skipLogin = process.env.REACT_APP_SKIP_LOGIN === 'TRUE';
const hidePromotionMsg = process.env.REACT_APP_HIDE_PROMOTION === 'TRUE';
const homePet = clientName === 'Bice Vida' ? biceVidaPet : pig;
const homePetAlt = clientName === 'Bice Vida' ? 'Mascota Bice Vida' : 'Chanchito ahorro';

const limitExceededMessage = 'Ha alcanzado el límite máximo permitido de consultas por RUT. ¿Deseas continuar sin validación?';
const errorValidationMessage = isInsurance
  ? clientName !== 'Vida Security'
    ? 'No podemos validarte como asegurado ' + clientName.toUpperCase() + ' en estos momentos. ¿Deseas continuar sin validación?'
    : 'Tu póliza no tiene reembolso en línea. ¿Deseas continuar?'
  : 'No hemos podido validarte como usuario.';
const webRedirectUrl = 'https://web.yapp.cl/';
const _ = require('lodash')

function Home() {
  const {hash} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const themeColor = useSelector((state) => state.app.themeColor)
  const textExtraHome = useSelector((state) => state.app.text_extra_home)
  const [appState, setAppState] = useState({
    email: '',
    identity: '',
    policies: false,
    valid: false,
  })
  const [sending, setSending] = useState(false)
  const [validType, setValidationType] = useState(null)
  const [subtitle, setSubtitle] = useState(null)
  const [showRegister, setShowRegister] = useState(null)
  const [showLimitExceededMessage, setShowLimitExceededMessage] = useState(false)
  const [modalErrorServerIsOpen, setModalErrorServerIsOpen] = useState(false)
  const [modalErrorValidation, setErrorValidation] = useState(false)
  const [modalNotRegistered, setModalNotRegistered] = useState(false)
  const [clientLoading, setClientLoading] = useState(false)
  const userInfo = useSelector((state) => state.user.userInfo)
  const {width} = useScreenSize()
  const [isDesktop, setIsDesktop] = useState()
  const [isTablet, setIsTablet] = useState()
  const [isMobile, setIsMobile] = useState()
  const [productsWithInsuranceBenefits, setProductsWithInsuranceBenefits] = useState([])

  useEffect(() => {
    if (width > 768) {
      setIsDesktop(true)
      setIsTablet(false)
      setIsMobile(false)
    } else if (width > 380 && width <= 768) {
      setIsTablet(true)
      setIsDesktop(false)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
      setIsTablet(false)
    }
  }, [width])

  useEffect(async () => {
    if ([undefined, null, 'NULL'].includes(process.env?.REACT_APP_EXTERNAL_VALID_TYPE)) {
      if (userInfo?.signInMethod) {
        return history.replace('/search')
      } else {
        return
      }
    }

    if (skipLogin && onLogin) {
      if (userInfo?.signInMethod) {
        return history.replace('/search')
      } else {
        dispatch(clearUserState())
        setValidationType(process.env.REACT_APP_EXTERNAL_VALID_TYPE)
      }
    } else {
      setValidationType(process.env.REACT_APP_EXTERNAL_VALID_TYPE)
      dispatch(changeInitialLoading(true))
      setClientLoading(true)
      try {
        try {
          sessionStorage.clear()
          let token = await getApiToken(false);
          if (!token) {
            localStorage.clear();
          }
        } catch (error) {
          console.log(error)
        }
      } catch (ignore) {
        dispatch(changeInitialLoading(false))
        setClientLoading(false)
      }
      dispatch(changeInitialLoading(false))
      setClientLoading(false)
    }
    getProductsWithInsuranceBenefits()
  }, [])

  const getProductsWithInsuranceBenefits= async () =>{
    try {
      if (productsWithInsuranceBenefits.length == 0) {
        let result = await fetch(suraBenefitsPublicUrl,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
        let data = await result.json();
        setProductsWithInsuranceBenefits(data)
      }
    }
    catch (error) {
      console.log('GetProductsWithInsuranceBenefits Error')
    }
  }

  function validEmail(e) {
    setAppState({email: e, valid: _validateMail(e)})
  }

  function validIdentity(e) {
    setAppState({
      identity: _formatRut(e),
      valid: _validateRut(e),
    })
  }

  async function nextAction(infoUser) {
    if (
      [undefined, null, 'NULL'].includes(
        process.env?.REACT_APP_EXTERNAL_VALID_TYPE,
      )
    ) {
      dispatch(changeInitialLoading(false))
      return history.push('/signin')
    }

    if (skipLogin && onLogin) {
      setSending(true)
      dispatch(changeInitialLoading(true))
      logEvent('Session Started')
      let client = await existUserClient(appState.identity)
      let clientData = _.get(client, 'data[0]', null)
      if (!clientData?.identity) {
        setSending(false)
        dispatch(changeInitialLoading(false))
        return setErrorValidation(true)
      } else {
        dispatch(changeInitialLoading(false))
        return history.push('/signin')
      }
    } else {
      setSending(true)
      dispatch(changeInitialLoading(true))
      if (process.env.REACT_APP_PROFILE !== 'INSURANCE') {
        logEvent('Session Started')
      }
      if (validType === 'identity') {
        try {
          let validation = [undefined, null].includes(infoUser)
            ? await existUserClient(appState.identity)
            : infoUser;
          let userData = _.get(validation, 'data[0]', null)
          setShowLimitExceededMessage(validation?.status === 429);

          if (userData?.identity) {
            dispatch(
              setDeliveryCode({
                code: userData?.delivery_code,
                value: userData?.delivery_value,
              }),
            )
            dispatch(
              setFreeShipping({
                code: userData?.free_shipping,
                value: userData?.free_shipping_value,
              }),
            )

            sessionStorage.setItem('USER_DATA', JSON.stringify(userData))
          } else {
            if (showRegister) {
              setModalNotRegistered(true)
              setSending(false)
              dispatch(changeInitialLoading(false))
              return
            }
            dispatch(changeInitialLoading(false))
            return setErrorValidation(true)
          }
        } catch (error) {
          console.log('Error:', error)
          return
        }
      }
      try {
        if (
          validType === 'identity' &&
          process.env.REACT_APP_PROFILE === 'INSURANCE'
        ) {
          await signOut()
          dispatch(clearUserState())
          dispatch(clearAppState())
          dispatch(changeInitialLoading(false))
          dispatch(setBasicInfo(appState.email, appState.identity))
          return history.push('/signin')
        }

        /*
        let uniqueHash = hash ? hash : process.env.REACT_APP_UNIQUE_HASH
        let result = await requestGenerateLink(uniqueHash, {
          email: appState.email,
          identity: appState.identity,
        })

        if (result?.data?.url) {
          dispatch(setBasicInfo(appState.email, appState.identity))

          let hash = _.last(result.data.url.split('/'))
          getConfigPage(hash)
            .then(async (res) => {
              if (!res) {
                return history.push('/error')
              }

              localStorage.setItem('JWT', res?.data?.token)
              localStorage.setItem(
                'LOCAL_DATA',
                JSON.stringify({
                  hash: hash,
                  origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
                  site: res?.data,
                  client_config: {
                    delivery_time: _.get(
                      res?.data,
                      'config[0].config.delivery_time',
                    ),
                  },
                }),
              )

              try {
                let jwtData = getJWTdata()

                window.gtag('set', 'user_id', jwtData?.user_id)
                window.gtag(
                  'set',
                  'user_type',
                  _.get(res, 'data.user_type', 'user'),
                )
              } catch (error) {
                console.log('Error set user gtag.')
              }

              dispatch(setUserExtraInfo(_.get(res?.data, 'info', {})))
              let route = res?.data?.route
              if (route === '/search') {
                if (location?.label && location?.nro) {
                  route =
                    '/search/shopping_bag/' +
                    Math.floor(
                      Math.random() * Math.floor(100000000000000000000),
                    )
                  return history.replace(route, {product: null, from: 'page'})
                }
              }

              let productId = localStorage.getItem('product')
              if (productId) {
                history.push(`/product/detail/${productId}`, {
                  product_id: productId,
                  external_vademecum: true,
                })
                localStorage.removeItem('product')
              } else {
                history.replace(route, {site: res?.data})
              }
            })
            .catch((ignore) => {})
            .finally(() => {
              dispatch(changeInitialLoading(false))
            })

          // window.location.replace(url)
        } else {
          setModalErrorServerIsOpen(true)
          dispatch(changeInitialLoading(false))
        }
        */
      } catch (error) {
        console.log(error)
        setModalErrorServerIsOpen(true)
        dispatch(changeInitialLoading(false))
      }
    }
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      nextAction()
      e.preventDefault()
    }
  }

  function clickContinue() {
    let infoUser = {}
    infoUser.data = [
      {
        identity: appState.identity,
        email: null,
        name: null,
        address: null,
        address_additional: null,
        phone: null,
        latitude: null,
        longitude: null,
      },
    ]

    setModalNotRegistered(false)
    dispatch(setDocumentsPending(true))
    nextAction(infoUser)
  }

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
    if (page === 'signin') {
      history.push('/signin', {hasOrigin: true})
    }
  }

  const onErrorButton = () => {
    setErrorValidation(false)
    setSending(false)
    logEvent('Session Started', {
      'Validated Insurance Policy': 'FALSE',
      'Insurance Type': 'External'
    })
    if (isInsurance)
      window.location.replace(webRedirectUrl)
  }

  return (
    <>
      {![null, undefined, false].includes(clientLoading) && suraLoader && (
        <LoadingSura />
      )}
      {![null, undefined, false].includes(clientLoading) && vidaCamaraLoader && (
        <div className="vida-camara-loader">
          <img
            src={imgVidaCamaraHeart}
            className="vida-camara-loader-img"
            alt="Corazón de carga"
          />
        </div>
      )}
      <div
        className={`flex-grow-1 pt-md-2 ${
          skipLogin ? 'd-flex align-items-center justify-content-center' : ''
        }`}
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-new-home mb-4">
          { clientName === 'Bice Vida' &&
            <div className="justify-content-center text-center mx-auto mt-3">
              <img src={farmaciaFacil} class={"mw-100"} alt="Farmacia fácil" />
            </div>
          }
          <div className="text-center mx-auto mt-5">
            { clientName === 'Sura'
              ? <>
                  <p className={"subtitle-home-sura px-3"}>
                    Te ayudamos a encontrar los mejores precios en farmacias{' '}
                  </p>
                  <br />
                  <h2 className={"title-home-sura mb-2"}>
                    Aprovecha un 30% de descuento en categorías de productos no reembolsables{' '}
                  </h2>
                  <br />
                </>
              : <>
                  <h2 className={"title-home-vs mb-2"}>
                    ¡Cotiza, compara y ahorra!{' '}
                  </h2>
                  <p className={"subtitle-home-sura px-3"}>
                    ¡Te ayudaremos a encontrar los mejores precios en farmacias,
                    siempre!
                  </p>
                </>
            }
          </div>
          {hidePromotionMsg && (
            <div className={textExtraHome ? 'mb-1 ' : 'mb-4'}>
              <DiscountCard color={'--theme-color'} />
            </div>
          )}
          <div className={`d-flex justify-content-center mx-auto mt-4 
            ${isMobile ? 'maxw-135' : 'maxw-200'} ${clientName === 'Bice Vida' ? '' : 'mb-4'}
          `}>
            <img className='w-100' src={homePet} alt={homePetAlt} />
          </div>
          {![null, undefined, ''].includes(textExtraHome) && (
            <div className="maxw-300 mx-auto mb-4">
              <p className="txt-6--regular-g1">{textExtraHome}</p>
            </div>
          )}
          <div className="justify-content-center mb-5 mx-3">
            <form className="maxw-300 mx-auto">
              {validType === 'email' && (
                <>
                  <div>
                    <Input
                      id="gtm-webapp-home-email"
                      type="email"
                      doChange={validEmail}
                      onKeyDown={onKeyDown}
                      label="Ingresa tu correo"
                      placeholder="Ingresa tu correo"
                    />
                  </div>
                </>
              )}
              {validType === 'identity' && (
                <>
                  <div>
                    <Input
                      id="gtm-webapp-home-rut"
                      type="text"
                      label={'Ingresa tu Rut'}
                      placeholder={'Ingresa tu Rut'}
                      value={appState.identity}
                      doChange={(e) => validIdentity(e)}
                      onKeyDown={onKeyDown}
                    />
                  </div>
                </>
              )}
              {!appState.valid &&
                (appState.email?.length > 0 ||
                  appState.identity?.length > 0) && (
                  <p className="text-danger txt-paragraph--small mt-2 ml-2">
                    {appState.email?.length > 0
                      ? '* Correo inválido'
                      : appState.identity?.length > 0
                      ? '* Rut inválido'
                      : '* Error en la validación.'}
                  </p>
                )}

              { !apiLogin &&
                <ButtonYapp
                  className="mb-4 mx-auto px-5 mt-4"
                  id="gtm-webapp-home-btn-send"
                  title="Ir a cotizar"
                  onClick={() => nextAction()}
                  big
                  color={'--theme-color'}
                  disabled={
                    (!appState.valid ||
                      appState.email?.length + appState.identity?.length <= 0 ||
                      sending) &&
                    ![undefined, null, 'NULL'].includes(
                      process.env?.REACT_APP_EXTERNAL_VALID_TYPE,
                    )
                    /* !policiesState || */
                  }
                />
              }
            </form>
          </div>
          <SectionBenefitCards products={productsWithInsuranceBenefits} />
          { process.env.REACT_APP_PROFILE === 'INSURANCE' && (
            <InsuranceBenefits clientName={clientName} check={check} checkBg={checkBg} isMobile={isMobile} />
          )}

          <div className='pt-3 pb-1'>
            <div className={`text-center ${isMobile ? 'maxw-250 mb-5' : 'maxw-400'} mx-auto mt-5`}>
              <h2 className="title-home-sura mt-4 mb-2">
                Farmacias para comprar
              </h2>
              <hr className='hr-separation'></hr>
            </div>

            <Carousel logos={ pharmaciesLogos.filter((item) => item.onlineShopping == true) }
              screen={width}
              controls={ isDesktop ? 3 : isTablet ? 4 : 7 }
            />
          </div>

          <div className='pb-5'>
            <div className={`text-center ${isMobile ? 'maxw-250 mb-5' : 'maxw-400'} mx-auto mt-5`}>
              <h2 className="title-home-sura mt-4 mb-2">
                Farmacias para cotizar
              </h2>
              <hr className='hr-separation'></hr>
            </div>

            <Carousel logos={ pharmaciesLogos.filter((item) => item.onlineShopping == false) }
              screen={ width }
              controls={ isDesktop ? 2 : isTablet ? 2 : 4 }
            />
          </div>
        </div>

        {clientName === 'Vida Security' && (
          <TypeSidetab 
            id='jjdc5aSF'
            buttonColor={'#282E55'}          
            buttonText={'Sugerencias'}
          />
        )}
        {clientName === 'Sura' && (
          <TypeSidetab
            id='wea0XraP'
            buttonColor={'#0033A0'}          
            buttonText={'Sugerencias!'}
          />
        )}

        <Modal
          isOpen={modalErrorServerIsOpen}
          onAfterOpen={null}
          onRequestClose={() => setModalErrorServerIsOpen(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={icErrorServer}
              alt="Error"
              className="mb-3"
              id="gtm-webapp-error-server-one"
            />
            <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
            <p className="title-primary--xl text-center mb-30">
              No podemos conetar con los servidores de Yapp en este momento...
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Button
              className="btn-default--small btn-next mb-3"
              variant="primary"
              onClick={() => {
                setModalErrorServerIsOpen(false)
                setSending(false)
              }}>
              Volver a intentar
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={modalErrorValidation}
          onAfterOpen={null}
          onRequestClose={() => setErrorValidation(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h2 className="title-primary--3xl-regular mb-3 text-center">
              { showLimitExceededMessage ? limitExceededMessage : errorValidationMessage }
            </h2>
            <div className="mx-auto">
              <button
                className="btn-default w-100 btn-next mt-3"
                onClick={onErrorButton}>
                { isInsurance ? 'Continuar' : 'Reintentar'}
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modalNotRegistered}
          onAfterOpen={null}
          onRequestClose={() => setModalNotRegistered(false)}
          className="modal-custom"
          overlayClassName="modal-custom-bg"
          contentLabel="Modal1"
          ariaHideApp={false}
          id="gtm-webapp-home-no-registered-modal">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="title-primary--xxl txt-center">
              ¡Ops! No estás registrado
            </p>
            <p className="txt-paragraph-lh mt-3">
              {'Antes de finalizar tu compra, te pediremos que adjuntes la foto de tu carnet de identidad y algún documento que acredite que actualmente eres vecino de ' +
                clientName +
                '.'}
            </p>
            <div className="mx-auto">
              <button
                className="btn-default w-100 btn-next mt-3"
                id="gtm-webapp-home-no-registered-button"
                onClick={() => {
                  clickContinue(false)
                }}>
                Continuar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Home
