import React, {useEffect, useState} from 'react'
import defaultMedication from '../../assets/img/default-medication.png'
import DiscountCardImg from '../../assets/img/descuentoCard.svg'
import ProgramCardImg from '../../assets/img/programaCard.svg'
import ReactTooltip from 'react-tooltip'

function HeaderDetail(props) {
  const {product, ProductWithCodes, productList} = props
  const [discountCode, setDiscountCode] = useState(false)
  const [program, setProgram] = useState(false)

  useEffect(() => {
    if (product) {
      const hasCode = ProductWithCodes?.find(
        (p) => p?.product_id === product?.product_id,
      )
      const hasProgram = productList?.find(
        (p) => p?.product_id === product?.product_id,
      )
      if (hasCode) {
        setDiscountCode(true)
      }
      if (hasProgram) {
        setProgram(true)
      }
    } else {
      return false
    }
  }, [product, ProductWithCodes, productList])

  return (
    <>
      {![undefined, null].includes(product) && (
        <div className="card-product-detail">
          <div className="d-flex align-items-center">
            <img
              src={product.product_logo || defaultMedication}
              alt=""
              height="70"
              className=""
            />
            <div className="ml-3">
              <h4 className="title-primary--xxl m-0 w-100 mb-1">
                {product?.product_name}
              </h4>
              <p className="txt-4--regular">{product?.formula_name}</p>
              <p className="txt-4--regular">{product?.presentation}</p>
              <p className="txt-4--regular">{product?.laboratory_name}</p>
            </div>
            <div className="ml-40">
              <img
                src={program ? ProgramCardImg : null}
                alt=""
                height="32"
                data-tip="Medicamento con programa de paciente"
                data-for="tooltip-product-program"
                className=""
              />
              <ReactTooltip
                id="tooltip-product-program"
                className="tooltip-style shadow rounded-3 title-primary--regular text-center p-3 maxw-250"
                effect="solid"
                place="top"
                backgroundColor="#ffffff"
                textColor="#000000"
                arrowColor="#FFFFFF"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderDetail
