import React from 'react'

function InfoCard(props) {
  var styles =  {
    container: {
      color: `var(${props.borderColor})`,
      backgroundColor: `var(${props.bgColor})`
    }
  };
  
  return (
    <div
      className={`alert-message-without-color mx-auto ${props.className}`}
      style={styles.container}>
      <i className={props.icon} style={props.iconColor && {color: `var(${props.iconColor})`}}></i>
      <p className="txt-6--bold ml-2" style={props.fontColor && {color: `var(${props.fontColor})`}}>
        {props.text}
      </p>
    </div>
  )
}

export default InfoCard
