import {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {base_url} from '../../../Utils/constants'
import * as Sentry from '@sentry/react'
import {setContext} from '@sentry/react'

import {_formatPrice, _validateMail, getHash} from '../../../Utils/tools'
import {
  createPurchaseOrder,
  getConfigPage,
  getConfigSite,
  sendMail,
} from '../../../Utils/api'
import {removeShoppingCart} from '../../../Utils/service'
import imgResultError from '../../../assets/img/i_error.svg'
import imgResultNew from '../../../assets/img/i_success_new.svg'
import loadingYapp from '../../../assets/img/yapp_loading.gif'
import {
  setThemeColor,
  changeLogo,
  showLogo,
  setConfig,
  setGoRouter,
} from '../../../redux/modules/app'
import {
  addEventNameWithParams,
  logEvent,
  downloadApp,
} from '../../../Utils/utils'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import TypeSidetab from '../../../Components/TypeForm/TypeSidetab'
import Input from '../../../Components/Forms/Input'
import Modal from 'react-modal'
import {Button} from 'react-bootstrap'
import imgSendSuccess from '../../../assets/img/change_pass_check_ok.svg'

const _ = require('lodash')

function ShoppingBagCheckout(props) {
  const [statusOrder, setStatusOrder] = useState(null)
  const [referrals, setReferrals] = useState([''])
  const [emails, setEmails] = useState([{email: '', show_error: false}])
  const deliveryCode = useSelector((state) => state?.delivery?.delivery_code)
  const userRefund = useSelector((state) => state?.user?.userRefund)

  const applicationNumber = useSelector((state) => state.app.applicationNumber)
  const prescriptions = useSelector((state) => state.app.prescriptions)
  const onePharmacy = useSelector((state) => state.app.one_pharmacy)
  const insurance = useSelector((state) => state.app.insurance)
  const dispatch = useDispatch()

  const isInsurance = process.env.REACT_APP_PROFILE === 'INSURANCE'
  const showReferrals = process.env.REACT_APP_CLIENT_NAME === 'Sura'
  const userInfo = useSelector((state) => state?.user?.userInfo)
  const [modalIsOpen, setIsOpen] = useState(false)

  const addReferral = () => {
    let referralList = [...referrals]
    referralList.push('')
    saveEmail(referralList.length -1, '')
    setReferrals(referralList)
  }

  const sendEmail = async () => {
    let emailsList = [...emails]
    emails.map(async (item, index) =>{
      if(!_validateMail(item.email)){
        item.show_error = true
      }else{
        let response = await sendMail(
          item.email,
          'conveniosyapp@yapp.cl',
          null,
          'd-53904331c3b2465a8658de48b9eeadf3',
          {
            webbapp_subject: (userInfo?.profile.first_name)?
            userInfo?.profile.first_name + " te invita a ahorrar y reembolsar en línea con Yapp" :
            "Te invitamos a ahorrar y reembolsar en línea con Yapp"
          }
        )
        if (response.status === 204) {
          setIsOpen(true)
          emailsList[index] = {email: '', show_error: false}
          logEvent('Item Shared', {
            'Shared Type':  'Mail',
            'Element Shared': 'Referral Program',
            'Product Id': ''
          })
        } else {
          console.log("ERROR")
        }
      }
      setEmails(emailsList)
    })
  }

  const saveEmail = (index, email) =>{
    let emailsList = [...emails]
    emailsList[index] = {email: email, show_error: false}
    setEmails(emailsList)
  }

  const isValidEmail = (index) =>{
    let msg = {}
    if (emails[index].show_error){
      msg = {message:
        '* El correo que ingresaste no es válido.',
      }
    }
    return msg
  }

  function closeModal() {
    setIsOpen(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
        let router = []
        dispatch(props.setGoRouter(router))
      }
    } catch (ignore) {}

    let hash = null
    try {
      hash = getHash()
    } catch (ignore) {}

    if (props.disabledThemeCheckout === true) {
      dispatch(changeLogo(null))
      dispatch(showLogo(false))
      dispatch(
        setThemeColor({
          icon_tutorial: '#282E55',
          primary: '#6578FF',
          secondary: '#4DBAB6',
        }),
      )
    } else {
      if (hash) {
        try {
          let result =
            process.env.REACT_APP_ON_LOGIN === 'TRUE' || isInsurance ||
            (process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE' &&
              [undefined, null, 'NULL'].includes(
                process.env.REACT_APP_EXTERNAL_VALID_TYPE,
              ))
              ? await getConfigSite()
              : await getConfigPage(hash)

          if (result) {
            const themeColor =
              result?.data.config[0].config?.custom_styles?.theme_color

            dispatch(
              showLogo(process.env.REACT_APP_SHOW_LOGO === 'TRUE'),
            )
            dispatch(changeLogo(process.env.REACT_APP_LOGO))
            if (themeColor) {
              dispatch(setThemeColor(themeColor))
            }
            dispatch(setConfig(_.get(result?.data, 'config[0].config', {})))
          }
        } catch (ignore) {}
      }
    }

    let purchaseOrderStatus = 'declined'
    let purchaseOrder = localStorage.getItem('selectedQuotationItem')
    try {
      if (purchaseOrder) purchaseOrder = JSON.parse(purchaseOrder)
    } catch (error) {
      purchaseOrder = false
    }

    try {
      let resp = await createPurchaseOrder(hash)
      if (
        resp?.data?.status === 'approvedTransaction' ||
        resp?.data?.status === 'preApprovedTransaction'
      ) {
        purchaseOrderStatus = resp.data.status
      }

      if (purchaseOrderStatus !== 'declined') {
        addEventNameWithParams('checkout_product_to_pay_result', {
          hash: hash,
          status: purchaseOrderStatus,
        })
        await savePurchaseOrderTag(purchaseOrder, resp.data.id)
        if (
          ['preApprovedTransaction', 'approvedTransaction'].includes(
            purchaseOrderStatus,
          )
        ) {
          setStatusOrder(true)
          await removeShoppingCart()
          localStorage.removeItem('selectedQuotationItem')
        } else {
          window.location.href = base_url + '/shopping_bag/post_shopping'
        }
      } else {
        setStatusOrder(false)
        addEventNameWithParams('checkout_product_to_pay_result', {
          hash: hash,
          status: purchaseOrderStatus,
        })
        await savePurchaseOrderTag(purchaseOrder, false)
      }
    } catch (error) {
      console.log(error)
      setStatusOrder(false)
      await savePurchaseOrderTag(purchaseOrder, false)
      if (hash) {
        addEventNameWithParams('checkout_product_to_pay_result', {
          hash: hash,
          status: purchaseOrderStatus,
        })
      }
    }
  }, [])

  const savePurchaseOrderTag = async (purchaseOrder, result) => {
    let success = result ? true : false
    let productQuantity = 0
    let totalCart = 0
    let totalDiscounts = 0
    let totalShipping = 0
    let orderTotal = 0

    if (purchaseOrder?.quotation_detail?.length > 0) {
      let productList = purchaseOrder.quotation_detail
      let refundableProducts = userRefund?.products || []
      let refundable = userRefund?.refund > 0 ? 'TRUE' : 'FALSE'

      for (const product of productList) {
        let productId = product.product.product_id
        let productDiscount = product.quantity * product.client_discount
        productQuantity += product.quantity
        totalCart += product.quantity * product.price
        totalDiscounts += productDiscount

        if (success) {
          let refundableProduct = isInsurance && refundableProducts.some((item) => item.product_id === productId)
          let refundType = refundableProduct 
            ? 'Refundable'
            : ![null, undefined].includes(product.client_benefit)
              ? 'Benefit'
              : 'None'

          logEvent('Product Purchased', {
            'Product Quantity': product.quantity,
            'Order Id': result ? result : 0,
            'Product Name': product.product.name,
            'Product Id': productId,
            'Lab Name': product.product.lab,
            'Product Price': product.price,
            'Total Price': product.quantity * product.price,
            'Total Discounts': productDiscount,
            'Active Principle Name': product.product.formula_name,
            'Refundable Type': refundType,
            'Refundable Product': refundableProduct ? 'TRUE' : 'FALSE',
          })
        }
      }

      totalShipping = purchaseOrder.delivery.cost
      if (deliveryCode?.code && deliveryCode?.value <= 100 && totalShipping > 0) {
        totalShipping = totalShipping - ((deliveryCode.value * totalShipping) / 100)
      }
      
      totalDiscounts = totalDiscounts + userRefund.refund
      orderTotal = totalCart - totalDiscounts + totalShipping

      logEvent('Order Completed', {
        'Order Success': success,
        'Total Cart Value': totalCart,
        'Total Cart Size': productQuantity,
        'Total Discounts': totalDiscounts,
        'Promo Code Applied': null,
        'Total Shipping': totalShipping,
        'Order Total': orderTotal,
        'Pharmacy Id': purchaseOrder.pharmacy_chain_id,
        'Pharmacy Name': purchaseOrder.pharmacy_chain_name,
        'Order Id': result ? result : 0,
        'Purchase Type': 'Spot',
        'Refundable Order': refundable,
      })
    }
  }

  const goToUc = () => {
    window.location.href = 'https://www.ucchristus.cl/'
  }

  return (
    <div className="flex-grow-1 content-responsive mx-auto mb-5">
      {![undefined, null].includes(statusOrder) ? (
        <>
          <div className="px-3 px-md-0">
            <div className="maxw-300 card-messege my-5 mx-auto">
              <img
                id={
                  statusOrder
                    ? 'gtm-webapp-checkout-result-img-success'
                    : 'gtm-webapp-checkout-result-img-error'
                }
                src={statusOrder ? imgResultNew : imgResultError}
                alt="Success"
                className="mb-4 img-message"
              />
              <h4 className={`txt-1--bold-db mb-3 text-center`}>
                {statusOrder
                  ? '¡Hemos procesado tu pedido con éxito!'
                  : 'No hemos procesado tu pedido con éxito...'}
              </h4>

              {statusOrder && (
                <>
                  <p className={'txt-paragraph text-center line-14'}>
                    Te hemos enviado un correo para que le hagas seguimiento a
                    tu pedido.
                  </p>
                </>
              )}
              {statusOrder && (
                <>
                  {![undefined, null, false].includes(onePharmacy) && (
                    <>
                      <p className="txt-paragraph text-center mt-3">
                        <span className="txt-paragraph--bold">
                          *La farmacia revisará la validez de los documentos
                        </span>
                      </p>
                      <p className="txt-paragraph text-center">
                        <span className="txt-paragraph--bold">
                          de vecino/a de {process.env.REACT_APP_CLIENT_NAME}.
                        </span>{' '}
                        Si no están correctos,
                      </p>
                      <p className="txt-paragraph text-center">
                        pueden rechazar el pedido y generaremos la
                      </p>
                      <p className="txt-paragraph text-center">
                        devolución del dinero.
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          
          { showReferrals && statusOrder &&
            <div className="d-flex flex-column justify-content-center align-items-center mx-auto mb-5">
                <h4 className={`txt-1--bold-db mb-2 text-center`}>
                  ¡Por que lo buenos se comparte!
                </h4>
                <h4 className={`txt-2--bold-db mb-3 text-center`}>
                  Enviale a tus colegas esta alianza y ayúdanos a ahorrar en sus tratamientos.
                </h4>
              <div className='align-items-center w-50'>
                { referrals.map((item, index) =>
                  <div className='my-2' key={index.toString} >
                    <Input
                      label="Ingresa el correo de tu colega"
                      doChange={(email)=> saveEmail(index, email)}
                      error={isValidEmail(index)}
                      value={[...emails][index].email}
                    />
                  </div>
                )}
              </div>
              <div className='mb-3 w-md-50 text-center'>
                <a className="color-lilac txt-3--bold-db" onClick={addReferral}>
                  <i className={'mx-2 fas fa-plus-circle'}> </i>
                  Agregar mas correos de colegas
                </a>
              </div>
              <div className='text-center'>
                <ButtonYapp
                  onClick={sendEmail}
                  id="gtm-webapp-shared-aliance-send-email"
                  className="btn-default txt-3--bold-db"
                  title={'Compartir alianza'}
                />
              </div>
            </div>
          }

          <div className="d-flex justify-content-center  mb-4">
            <p className="title-primary--regular text-center maxw-250">
              ¿Te gustaría cotizar tus medicamentos
              <br /> siempre antes de comparlos? ¡Descarga
              <br /> nuestra app en tu celular!
            </p>
          </div>
          <div className="d-flex w-100 justify-content-center pt-2">
            <ButtonYapp
              id="gtm-webapp-checkout-result-btn-download"
              className="mx-auto px-2"
              disabled={false}
              title={process.env.REACT_APP_CLIENT_NAME==='UC Christus'
                  ? 'Ir a UCCHRISTUS'
                  : 'Descargar yapp'}
              color={'--theme-color'}
              onClick={process.env.REACT_APP_CLIENT_NAME==='UC Christus'
                ? goToUc
                : downloadApp
              }
              small
            />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center w-100 py-5">
          <img src={loadingYapp} alt="loading" height={70} />
        </div>
      )}

      {process.env.REACT_APP_CLIENT_NAME === 'Vida Security' && (
        <TypeSidetab 
          id='jjdc5aSF'
          buttonColor={'#282E55'}          
          buttonText={'Sugerencias'}
        />
      )}
      {process.env.REACT_APP_CLIENT_NAME === 'Sura' && (
        <TypeSidetab
          id='wea0XraP'
          buttonColor={'#0033A0'}          
          buttonText={'Sugerencias!'}
        />
      )}
      {process.env.REACT_APP_CLIENT_NAME === 'Pacientes' && (
        <TypeSidetab
          id='I1p4HO0C'
          buttonColor={'#497AD7'}          
          buttonText={'Sugerencias!'}
        />
      )}
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={()=>{}}
        onRequestClose={()=>{}}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}>
        <h4 className="title-modal-altern mb-1 txt-1--bold-db">¡Alianza compartida!</h4>
        <div className="d-flex flex-column justify-content-center mb-3">
          <img
            src={ imgSendSuccess }
            className="modalt-img"
            style={{ boxShadow: '0 0px 0px', width: '30%' }}
          />
          <p className="txt-paragraph text-center txt-3--bold">
            Hemos enviado todos los correos con éxito. Muchas gracias por apoyarnos.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next btn-stroke"
            variant="primary"
            onClick={closeModal}>
              <h5 style={{ top: '5px', position: 'relative', fontWeight: 'bold'}} className="font-regular">Cerrar</h5>
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setGoRouter: (router) => dispatch(setGoRouter(router)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBagCheckout)
