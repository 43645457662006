import React, {useEffect, useState} from 'react'
import {formatDate} from '../../Utils/utils'
import { _formatPrice } from '../../Utils/tools'

import './MyShoppingCardOrder.css'

function MyShoppingCardOrder(props) {
  const [colorStatus, setColorStatus] = useState('success')

  useEffect(() => {
    let color = 'success'
        switch (props.data?.orderStatus) {
          case 'Rechazado':
          case 'Cancelado':
            color = 'warning'
            break

          default:
            break
        }
        setColorStatus(color)
  }, [])

  return (
    <div
      className={`content-card-mso ${props?.className ? props.className : ''}`}
      onClick={!props.data?.noData ? props.doAction: null}>
      <div className="content-txt-mso">
        {!props.data?.noData &&
        <h3 className="txt-4--bold-db m-0"> { 'Nº orden de compra: ' + props.data?.orderId}</h3>
        }
        {!props.data?.noData &&
        <p
          className={`txt-6--bold ${
            (colorStatus === 'default' && 'color-default-ms') ||
            (colorStatus === 'alert' && 'color-alert-ms') ||
            (colorStatus === 'warning' && 'color-warning-ms') ||
            (colorStatus === 'success' && 'color-success-ms')
          }`}>
          Estado: {props.data?.orderStatusText}
        </p>
        }
        <p className="txt-6--regular-db">Fecha entrega: {isNaN(new Date(props.data?.orderDeliveryDate)) ? props.data?.orderDeliveryDate :  formatDate(props.data?.orderDeliveryDate, false)}</p>
        {props.data?.orderTotal > 0 && <p className="txt-6--regular-db">Total a cobrar: {_formatPrice(props.data?.orderTotal)}</p>}
      </div>
      {!props.data?.noData &&
      <div className="icon-mso">
        <i className="icon-chevron-right color-lilac"></i>
      </div>}
    </div>
  )
}

export default MyShoppingCardOrder
