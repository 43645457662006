import React from 'react'
import { useHistory } from 'react-router'
import Comming from '../../Components/Comming/Comming'

function Share() {

  return (
    <div className='flex-grow-1 px-3 pb-4'>
      <div className='content-responsive mx-auto h-100 pt-4 d-flex align-items-start align-items-lg-center'>
        <Comming />
      </div>
    </div>
  )
}

export default Share