import React from 'react'

import {useHistory} from 'react-router'
import {logEvent} from '../../Utils/utils'
import imgEresMedico from '../../assets/img/fondo_banner.svg'
import imgDrLogin from '../../assets/img/dr-login.png'
import imgPC from '../../assets/img/bro.svg'
import imgTEL from '../../assets/img/Device.svg'
import imgMG from '../../assets/img/lupa.svg'
import imgMED from '../../assets/img/Interactions.svg'
import imgROCKET from '../../assets/img/rocket.svg'
import imgLiDoc from '../../assets/img/dr.svg'
import img4Med from '../../assets/img/remedios.svg'


function NewRegisterPro() {
  const history = useHistory()

  function goTo(page) {
    if (page === 'policies') {
      return window.open('https://yapp.cl/privacidad')
    }
    if (page === 'terms') {
      return window.open('https://yapp.cl/terminos')
    }
    if (page === 'home') {
      return history.push('/pro/menu')
    }
    if (page === 'login') {
      return history.push('/signin', {requiredValidate: true})
    }
    if (page === 'register') {
      logEvent('Register Doctor')
      return history.push('/register_pro')
    }
  }

  return (
    <div style={{backgroundColor: '#F4F6FF'}}>
      <div
        className="section-one px-3 px-lg-0"
        style={{
          backgroundImage: `url(${imgEresMedico})`,
          backgroundSize: 'cover',
        }}>
        <div className="content-responsive-1300 bg-content-landing-top pt-4 pt-lg-2 mb-4 d-flex flex-row align-items-center flex-lg-row justify-content-between pb-4 pb-lg-0">
          <div className="w-25 mr-4 d-flex align-items-end h-100">
            <img src={imgDrLogin} className="d-none d-lg-inline" />
          </div>
          <div className="">
            <h3 className="txt-36--bold text-white m-0">
              <strong>¿Eres médico?</strong>
            </h3>
            <p className="txt-30--bold text-white my-4">
              Regístrate en Yapp y ayuda a tus pacientes a mantenerse adheridos
              a sus tratamientos
            </p>
            <div
              className="txt-3--bold-pr mx-auto my-2 py-3 px-2 text-white c-pointer text-center color-primary background-white w-250"
              onClick={() => goTo('register')}
              style={{
                borderRadius: 100,
              }}>
              Registrate como médico
            </div>
          </div>
        </div>
      </div>

      <div className="section-three px-3 px-lg-0" id="cotizador">
        <br />
        <hr className="hr-yellow my-4" />
        <br />
        <div className="w-100  my-4 pb-4">
          <h3 className="txt-40--bold m-0 text-center">
            Plataformas de Yapp médicos
          </h3>
        </div>

        <div className="d-flex align-items-strecth justify-content-center my-4 flex-wrap flex-lg-nowrap">
          <div className="white-card-info blue-border d-flex flex-column aling-items-center justify-content-start text-center bg-white mr-lg-4 mb-4 mb-lg-0">
            <img src={imgPC} className="mx-auto mb-4" />
            <h4 className="title-big">En tu computador</h4>
            <p className="txt-2--regular">
              Instala el aplicativo Yapp en el computador de tu consulta y/o
              personal para ayudar a tus pacientes en sus tratamientos y mejorar
              tu atención de salud.
            </p>
            <p className="new-element">Nuevo</p>
            <a
              href="https://yapp.cl/medicos/instala-yapp-medicos/"
              className="w-75 mx-auto mt-4 py-2 is-stroke px-4"
              style={{borderRadius: '30px'}}>
              Ver más
            </a>
          </div>
          <div className="white-card-info d-flex flex-column aling-items-center justify-content-center text-center bg-white mx-2 ml-lg-4">
            <img src={imgTEL} className="mx-auto mb-4" />
            <h4 className="title-big">En tu bolsillo</h4>
            <p className="txt-2--regular">
              Te acompañamos siempre en tu bolsillo. Descarga Yapp y encuentra
              tus medicamentos disponibles al mejor precio.
            </p>
          </div>
        </div>
        <br />
        <br />
        <hr className="hr-yellow my-4" />
        <br />
        <div className="w-100  my-4 pb-4">
          <h3 className="txt-40--bold m-0 text-center">
            Funcionalidades de Yapp médicos
          </h3>
        </div>
        <div className="d-flex align-items-stretch justify-content-center my-4 flex-wrap flex-lg-nowrap">
          <div className="white-card-info d-flex flex-column aling-items-center justify-content-start text-center bg-white mr-lg-4">
            <img src={imgMG} className="mx-auto mb-4" />
            <h4 className="title-big">En tu computador</h4>
            <p className="txt-2--regular">
              Instala el aplicativo Yapp en el computador de tu consulta y/o
              personal para ayudar a tus pacientes en sus tratamientos y mejorar
              tu atención de salud.
            </p>
          </div>
          <div className="white-card-info d-flex flex-column aling-items-center justify-content-start text-center bg-white mx-lg-4 my-4 my-lg-0">
            <img src={imgMED} className="mx-auto mb-4" />
            <h4 className="title-big">En tu bolsillo</h4>
            <p className="txt-2--regular">
              Te acompañamos siempre en tu bolsillo. Descarga Yapp y encuentra
              tus medicamentos disponibles al mejor precio.
            </p>
          </div>
          <div className="white-card-info d-flex flex-column aling-items-center justify-content-start text-center bg-white ml-lg-4">
            <img src={imgROCKET} className="mx-auto mb-4" />
            <h4 className="title-big">En tu bolsillo</h4>
            <p className="txt-2--regular">
              Te acompañamos siempre en tu bolsillo. Descarga Yapp y encuentra
              tus medicamentos disponibles al mejor precio.
            </p>
          </div>
        </div>
        <br />
        <br />
        <hr className="hr-yellow my-4" />
        <br />
        <div className="w-100  my-4 pb-4">
          <h3 className="txt-40--bold m-0 text-center">
            Resultados de Yapp médicos
          </h3>
        </div>
        <div className="d-flex align-items-stretch justify-content-center my-4 flex-wrap flex-lg-nowrap">
          <div
            className="d-flex flex-column aling-items-center justify-content-center text-center mr-lg-4"
            style={{
              maxWidth: '330px',
              minWidth: '325px',
              maxHeight: '400px',
              padding: '20px 15px',
              borderRadius: '10px',
            }}>
            <img src={imgLiDoc} className="mx-auto mb-4 w-25" />
            <h4 className="title-big">9 de cada 10</h4>
            <p className="txt-2--regular">
              Médicos que nos conocen nos recomiendan
            </p>
          </div>
          <div
            className="d-flex flex-column aling-items-center justify-content-center text-center mr-lg-4"
            style={{
              maxWidth: '330px',
              minWidth: '325px',
              maxHeight: '400px',
              padding: '20px 15px',
              borderRadius: '10px',
            }}>
            <img src={img4Med} className="mx-auto mb-4 w-25" />
            <h4 className="title-big">Aumenta x 4</h4>
            <p className="txt-2--regular">
              La adherencia a tratamiento de tus pacientes
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewRegisterPro
