import React from 'react'
import Select, {components} from 'react-select'
import './styles/CustomSelect.css'

function CustomSelect(props) {
  const CustomSelectStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: 'var(--color-white)',
      borderRadius: '8px',
      height: '40px',
      paddingLeft: '16px',
      zIndex: 1050,
      boxShadow: '0 rgba(0,0,0,0)',
      border: '1px solid var(--color-gray2-ds);',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--color-dark-blue-ds)',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--color-gray1-ds)',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'block',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '31px',
      zIndex: 1051,
      border: '1px solid var(--color-ligthlilac-3)',
    }),
    menuList: (provided) => ({
      ...provided,
      color: 'var(--color-dark-blue-ds)',
      fontSize: '12px',
      padding: '8px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'var(--color-gray-1)',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.08)' : null,
      borderBottom: '1px solid var(--color-gray2-ds)',
      color: 'var(--color-dark-blue-ds)',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.08)',
        fontSize: '12px',
      },
    }),
  }

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="txt-5--regular-db">Cargando...</span>
      </components.NoOptionsMessage>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="box-arrow-select">
            <i className="icon-chevron-down color-lilac" />
          </div>
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <>
      <Select
        components={{DropdownIndicator, NoOptionsMessage}}
        options={props.options}
        styles={CustomSelectStyle}
        placeholder={'Seleccionar categoría'}
        onChange={props.onChange}
        value={typeof props.value !== "undefined" ? props.options.filter((option) => option.value === props.value) : undefined}
      />
    </>
  )
}

export default CustomSelect
