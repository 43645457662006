import React from 'react'
import './DiscountCard.css'
import chanchito from '../../assets/img/chanchito.png'
import biceVidaPet from '../../assets/img/bice-vida-welcome.png'

function DiscountCard(props) {
  const homePet = process.env.REACT_APP_CLIENT_NAME === 'Bice Vida' ? biceVidaPet : chanchito;
  const homePetAlt = process.env.REACT_APP_CLIENT_NAME === 'Bice Vida' ? 'Mascota Bice Vida' : 'Chanchito ahorro';

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center">
          <img
            id={`gtm-webapp-discount-img`}
            src={homePet}
            alt={homePetAlt}
            className="chanchito my-2"
          />
        </div>
        <p
          className="txt-2--bold text-center"
          style={props.color && { color: `var(${props.color})` }}>
          Ahorra hasta
        </p>
        <div className="d-flex justify-content-center my-2">
          <div
            className="container-discount justify-content-center"
            style={props.color && { borderRight: `1px solid var(${props.color})` }}>
            <p
              className="txt-1--bold text-center"
              style={props.color && { color: `var(${props.color})` }}>
              53%
            </p>
            <p
              className="txt-6--regular text-center"
              style={props.color && { color: `var(${props.color})` }}>
              en Diabetes
            </p>
          </div>
          <div
            className="container-discount justify-content-center"
            style={props.color && { borderRight: `1px solid var(${props.color})` }}>
            <p
              className="txt-1--bold text-center"
              style={props.color && { color: `var(${props.color})` }}>
              49%
            </p>
            <p
              className="txt-6--regular text-center"
              style={props.color && { color: `var(${props.color})` }}>
              en Antidepresivos
            </p>
          </div>
          <div className="container-discount justify-content-center">
            <p
              className="txt-1--bold text-center"
              style={props.color && { color: `var(${props.color})` }}>
              37%
            </p>
            <p
              className="txt-6--regular text-center"
              style={props.color && { color: `var(${props.color})` }}>
              en Anticonceptivos
            </p>
          </div>
        </div>
        <p
          className="txt-3--bold text-center"
          style={props.color && { color: `var(${props.color})` }}>
          ...y mucho más!
        </p>
      </div>
    </div>
  )
}

export default DiscountCard
