const _ = require('lodash')

const RESTORE_CART = 'RESTORE_CART'
const SHOW_BUTTON_CART = 'SHOW_BUTTON_CART'
const ADD_PRODUCT_CART = 'ADD_PRODUCT_CART'
const REMOVE_PRODUCT_CART = 'REMOVE_PRODUCT_CART'
const UPDATE_COUNTER = 'UPDATE_COUNTER'

const initialState = {
  products: {},
  productsPharmacy: [],
  countProductsPharmacy: 0,
  statusButtonCart: false,
  totalCart: 0,
  recentlyViewed: []
}

export default function reducer(state = initialState, action) {
  let temp = {}
  let total
  let newProducts = {}

  switch (action.type) {
    case RESTORE_CART:
      let tmp = {}

      if (action.payload.key === 'productsPharmacy') {
        tmp[action.payload.key] = action.payload.value

        let count = 0
        for (let index = 0; index < action.payload.value.length; index++) {
          const element = action.payload.value[index]
          if (element.products) {
            for (let index = 0; index < element.products.length; index++) {
              count = count + element.products[index].counter
            }
          }
        }

        tmp['countProductsPharmacy'] = count
        let newState = {
          ...state,
          ...tmp,
        }

        return newState
      } else {
        return state
      }
    case SHOW_BUTTON_CART:
      return {
        ...state,
        statusButtonCart: action.status,
      }
    case ADD_PRODUCT_CART:
      let tempAdd = state.productsPharmacy

      // Pharmacy proc
      let indexPharmacy = _.findIndex(tempAdd, {
        pharmacy_id: action.pharmacy.pharmacy_id,
      })
      if (indexPharmacy === -1) {
        tempAdd.push({
          pharmacy_id: action.pharmacy.pharmacy_id,
          pharmacy_chain_name: action.pharmacy.pharmacy_chain_name,
          pharmacy_chain_logo: action.pharmacy.pharmacy_chain_logo,
          delivery: action.pharmacy.delivery,
          products: [],
        })

        indexPharmacy = _.findIndex(tempAdd, {
          pharmacy_id: action.pharmacy.pharmacy_id,
        })
      }

      // Product proc
      let indexProduct = _.findIndex(tempAdd[indexPharmacy].products, {
        id: action.product.id,
      })
      if (indexProduct === -1) {
        tempAdd[indexPharmacy].products.push({
          ...action.product,
          ...{ counter: action.counter },
        })
      } else {
        tempAdd[indexPharmacy].products[indexProduct].counter =
          tempAdd[indexPharmacy].products[indexProduct].counter + 1
      }

      localStorage.setItem('CART', JSON.stringify(tempAdd))

      return {
        ...state,
        productsPharmacy: tempAdd,
        countProductsPharmacy: state.countProductsPharmacy + 1,
      }
    case REMOVE_PRODUCT_CART:
      temp = {}

      temp[action.product.id] = {
        ...action.product,
        ...{ counter: action.counter },
      }
      newProducts = {
        ...state.products,
        ...temp,
      }

      if (action.counter === 0) {
        delete newProducts[action.product.id]
      }

      total = getTotal(newProducts)
      return {
        ...state,
        products: newProducts,
        totalCart: total,
      }
    case UPDATE_COUNTER:
      let tmpUpdateCounter = state.productsPharmacy
      let indexPharmacyCounter = _.findIndex(state.productsPharmacy, {
        pharmacy_id: action.pharmacyId,
      })
      let indexProductCounter = _.findIndex(
        state.productsPharmacy[indexPharmacyCounter].products,
        { id: action.productId },
      )

      if (indexProductCounter !== -1) {
        if (action.counter > 0) {
          tmpUpdateCounter[indexPharmacyCounter].products[
            indexProductCounter
          ].counter = action.counter
        } else {
          tmpUpdateCounter = tmpUpdateCounter[
            indexPharmacyCounter
          ].products.splice(indexProductCounter, 1)
        }
      }

      let totalCount = getCounterByPharmacy(tmpUpdateCounter)
      localStorage.setItem('CART', JSON.stringify(tmpUpdateCounter))
      return {
        ...state,
        products: tmpUpdateCounter,
        countProductsPharmacy: totalCount,
      }
    default:
      return state
  }
}

export function restoreCart(payload) {
  return {
    type: RESTORE_CART,
    payload,
  }
}

export function showButtonCart(status) {
  return {
    type: SHOW_BUTTON_CART,
    status,
  }
}

export function addProduct(product, pharmacy, counter) {
  return {
    type: ADD_PRODUCT_CART,
    product,
    pharmacy,
    counter,
  }
}

export function removeProduct(product, counter) {
  return {
    type: REMOVE_PRODUCT_CART,
    product,
    counter,
  }
}

export function updateCounter(pharmacyId, productId, counter) {
  return {
    type: UPDATE_COUNTER,
    pharmacyId,
    productId,
    counter,
  }
}

function getTotal(newProducts) {
  let total = 0
  for (const key in newProducts) {
    if (Object.hasOwnProperty.call(newProducts, key)) {
      const element = newProducts[key]
      total = total + element.amount * element.counter
    }
  }

  return total
}

function getCounterByPharmacy(data) {
  let count = 0

  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    for (let index = 0; index < element.products.length; index++) {
      const item = element.products[index]
      count = count + item.counter
    }
  }

  return count
}

