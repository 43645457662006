import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'

import ProductSearch from '../../Components/ProductSearch'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import ProductSearchPrincipleActive from '../../Components/ProductSearch/SearchPrincipleActive'
import {getProductByActivePrinciple} from '../../Utils/api'
import {_formatPrice} from '../../Utils/tools'

import productImg from '../../assets/img/prescription.png'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import proIconLoading from '../../assets/img/proIconLoading.svg'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'
import {ENUM_MENU} from '../../Utils/constants'
const _ = require('lodash')

function PriceFinderResult() {
  const history = useHistory()
  const [productInput] = useState(history.location.state?.product_input)
  const [products, setProducts] = useState(null)
  const [loading, setLoading] = useState(null)

  const resultSearch = useSelector((state) => state.search.result)

  useEffect(() => {
    const productInput = history.location.state.product_input
    let activePrincipleID = productInput?.data?.active_principle_id
    getProducts(activePrincipleID)
  }, [])

  function goBack() {
    history.goBack()
  }

  const onClickSearch = (newValue) => {
    if (newValue?.data?.is_product === 1) {
      if (newValue?.value) {
        let id = newValue.data.product_id
        addEventNameWithParams('price_finder_result_select_product', {
          product_id: id,
        })

        onClickOption(id, newValue.data)
      } else {
        console.log('Error value')
      }
    } else {
      logEvent('AP Price Finder Checked', {
        'Active Principle Id': newValue?.data?.active_principle_id,
        'Active Principle Name': newValue?.data?.active_principle_name,
      })
      addEventNameWithParams('price_finder_select_active_principle', {
        product_name: newValue?.label,
        active_principle_id: newValue?.data?.active_principle_id,
      })

      getProducts(newValue?.data?.active_principle_id)
    }
  }

  const getProducts = (activePrincipleID) => {
    if (activePrincipleID) {
      setLoading(true)
      getProductByActivePrinciple(activePrincipleID, resultSearch)
        .then((response) => {
          if (response?.data) {
            const products = _.orderBy(
              response.data,
              (product) => parseInt(product.price),
              ['asc'],
            )
            setProducts(products)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  const onClickOption = (productId, product) => {
    addEventNameWithParams('show_medication_sheet', {
      from: 'price_finder_result',
      product_id: productId,
    })
    logEvent('Price Finder Checked', {
      Origin: 'Menu',
      'Product Id': productId,
      'Product Name': product?.product_name,
      'Lab Name': product?.laboratory_name,
      'Search Type': 'Active Principle',
    })
    history.push(`/pro/price_finder_detail/${productId}`, {
      product_id: productId,
      product: product,
    })
  }

  return (
    <>
      {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
        <div className="breadcrumbs c-pointer" onClick={goBack}>
          <div className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </div>
          <span className="title-primary--regular line-14">Volver</span>
        </div>
      </div>}
      <div className="flex-grow-1">
        <div className="content-responsive mx-auto">
          <div className="py-2 px-3 px-md-0">
            <h2 className="title-primary--xxl m-0">Buscador de precios</h2>
            <ProductSearchPrincipleActive
              principleActive={true}
              request_from={ENUM_MENU.price_finder}
              product_input={productInput}
              onClickSearch={onClickSearch}
              isNotShow={true}
              placeholder={'Busca por marca o principio activo'}
            />
          </div>
        </div>
        <hr className="hr--gray" />
        <div className="content-responsive mx-auto">
          {loading ? (
            <div className='text-center image-center'>
              <h2 className='title-primary--3xl-bold mt-3'>En breve verás tu búsqueda</h2>
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--pro m-0"
              />
              <img
                src={proIconLoading}
                alt='Loading icon' 
                className='maxw-290'
              />
            </div>
          ) : (
            <div className="py-4 px-3 px-md-0">
              <h2 className="title-primary--xl-bold mb-3">
                Selecciona un producto
              </h2>
              <div className="content-card-product">
                {_.orderBy(products, (p) => [p.product_name, p.presentation], ['asc', 'asc'])?.map((item, key) => (
                  <CardInteracion
                    key={key}
                    id={`gtm-webapp-medication-sheet-result-${key}`}
                    doAction={() => onClickOption(item?.product_id, item)}>
                    <div className="content-img-medication-sheet">
                      <img
                        src={
                          item?.product_logo ? item?.product_logo : productImg
                        }
                        alt={`nombre del producto`}
                        className="img-mw-35"
                      />
                    </div>
                    <div className="ml-14 overflow-hidden">
                      <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2">
                        {item?.product_name}
                      </h2>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.formula_name}
                      </p>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.presentation}
                      </p>
                      <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                        {item?.laboratory_name}
                      </p>
                      <p className="txt-paragraph--bold m-0 line-16 text-ellipsis--2">
                        Desde {_formatPrice(item?.price)}
                      </p>
                    </div>
                  </CardInteracion>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PriceFinderResult
