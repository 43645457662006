import React from 'react'
import ButtonYapp from '../Buttons/ButtonYapp'

function SearchTypeToggle(props) {
  return (
    <>
      {process.env.REACT_APP_ENABLED_EXAMS === 'TRUE' && (
        <>
          <ButtonYapp
            className="mr-2"
            color={'--theme-color'}
            stroke={!props.medicaments}
            title="Medicamentos"
            onClick={props.medicamentsOnClick}
          />

          <ButtonYapp
            className="ml-2"
            color={'--theme-color'}
            stroke={!props.exams}
            title="Exámenes Médicos"
            onClick={props.examsOnClick}
          />
        </>
      )}
    </>
  )
}

export default SearchTypeToggle
