import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {_formatPrice} from '../../Utils/tools'

import productImg from '../../assets/img/prescription.png'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import CardInteracion from '../CardInteraction/CardInteracion'
import {getProductByActivePrinciple} from '../../Utils/api'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'
import proIconLoading from '../../assets/img/proIconLoading.svg'

const _ = require('lodash')

function FindActivePrinciple(props) {
  const history = useHistory()
  const [products, setProducts] = useState(null)
  const [loading, setLoading] = useState(null)

  const resultSearch = useSelector((state) => state.search.result)

  useEffect(() => {
    if (props?.active_principle_id) {
      getProducts(props.active_principle_id)
    }
  }, [props.active_principle_id])

  const getProducts = (activePrincipleID) => {
    if (activePrincipleID) {
      setLoading(true)
      getProductByActivePrinciple(activePrincipleID, resultSearch)
        .then((response) => {
          if (response?.data) setProducts(response?.data)
          setLoading(false)
        })
        .catch((ignore) => {
          setLoading(false)
        })
    }
  }

  const onClickOption = (productId, product) => {
    addEventNameWithParams('show_medication_sheet', {
      from: 'medication_sheet',
      product_id: productId,
    })
    props.onSelectProduct(product)
  }

  return (
    <>
      <div className="flex-grow-1">
        <div className="content-responsive mx-auto">
          <div className="content-responsive mx-auto">
            {loading ? (
              <div className="text-center image-center">
                <h2 className="title-primary--3xl-bold mt-3">
                  En breve verás tu búsqueda
                </h2>
                <img
                  src={loadingYapp}
                  alt="loading"
                  className="loading-yapp--pro m-0"
                />
                <img
                  src={proIconLoading}
                  alt="Loading icon"
                  className="maxw-290"
                />
              </div>
            ) : (
              <div className="py-4 px-3 px-md-0">
                <h2 className="title-primary--xl-bold mb-3">
                  Selecciona un medicamento
                </h2>
                {Array.isArray(products) && products.length > 0 ? (
                  <div className="content-card-product">
                    {_.orderBy(
                      products,
                      (p) => [p.product_name, p.presentation],
                      ['asc', 'asc'],
                    )?.map((item, key) => (
                      <CardInteracion
                        key={key}
                        id={`gtm-webapp-medication-sheet-result-${key}`}
                        doAction={() => onClickOption(item?.product_id, item)}>
                        <div className="content-img-medication-sheet">
                          <img
                            src={
                              item?.product_logo
                                ? item?.product_logo
                                : productImg
                            }
                            alt={`nombre del producto`}
                            className="img-mw-35"
                          />
                        </div>
                        <div className="ml-14 overflow-hidden">
                          <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2 ">
                            {item?.product_name}{' '}
                          </h2>
                          <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                            {item?.formula_name}
                          </p>
                          <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                            {item?.presentation}
                          </p>
                          <p className="txt-paragraph--small m-0 line-14">
                            {item?.laboratory_name}
                          </p>
                          <p className="txt-paragraph--bold m-0 line-16 text-ellipsis--2">
                            Desde {_formatPrice(item?.price)}
                          </p>
                        </div>
                      </CardInteracion>
                    ))}
                  </div>
                ) : (
                  <div className="content-card-product">
                    <p className="txt-paragraph m-0 line-14 text-ellipsis--2 ">
                      Sin resultados.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FindActivePrinciple
