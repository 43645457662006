import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'
import Geocode from 'react-geocode'
const _ = require('lodash')
const numeral = require('numeral')

Geocode.setApiKey('AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw')
Geocode.setLanguage('es-419')
Geocode.setRegion('cl')
Geocode.enableDebug()

export const getHash = () => {
  let localData = JSON.parse(localStorage.getItem('LOCAL_DATA'))
  return _.get(localData, 'hash')
}

export const getJWTdata = (jwt) => {
  if (!jwt) jwt = localStorage.getItem('JWT')
  try {
    let data = jwt_decode(jwt)
    return data
  } catch (error) {
    console.log('Error al obtener data JWT', error)
    return false
  }
}

export const _formatPrice = (price) => {
  return numeral(price).format('$0,0').replace(/,/g, '.')
}

export const _validateRut = (rut) => {
  try {
    let cRut = rut.replace(/^(0+)/g, '')
    cRut = cRut.replace(/[\.-]/g, '')
    cRut = cRut.toUpperCase()
    var patt = /^\d{1,8}[0-9K]$/
    var ok = patt.test(cRut)
    var cStr = cRut.slice(0, -1)
    var cDig = cRut.slice(-1)
    var nSum = 0
    var nVal = 0
    var cVal = ''

    if (ok) {
      for (let nMul = 2; cStr !== ''; nMul = nMul === 7 ? 2 : nMul + 1) {
        nSum += Number(cStr.slice(-1)) * nMul
        cStr = cStr.slice(0, -1)
      }
      nVal = 11 - (nSum % 11)
      switch (nVal) {
        case 11:
          cVal = '0'
          break
        case 10:
          cVal = 'K'
          break
        default:
          cVal = nVal.toString()
      }
      ok = cVal === cDig
    }

    return ok
  } catch (ignore) {
    return false
  }
}

export const _validateDocument = (document, skip) => {
  if (skip) {
    return true
  }
  try {
    let cDocument = document.replace(/[\.]/g, '')
    if (cDocument.length >= 9 && cDocument.length <= 10) {
      return true
    }
    return false
  } catch (ignore) {
    return false
  }
}

const split = (str, index) => {
  return [str.slice(0, index), str.slice(index)]
}

export const _cleanRut = (value) => {
  return typeof value === 'string' ? value.replace(/[^0-9kK]+/g, '').toUpperCase() : ''
}

export const _formatRut = (value, useDots = true) => {
  let [rutDigits, verificatorDigit] = split(_cleanRut(value), -1)
  if (useDots) {

    rutDigits = rutDigits.replace(/^0+/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  return `${rutDigits}-${verificatorDigit}`
}

export const _separateRut = (rut) => {
  rut = _formatRut(rut)
  try {
    let identity = {
      number: null,
      dv: null,
    }
    var actual = rut.replace(/^0+/, '')
    if (actual !== '' && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, '')
      var actualLimpio = sinPuntos.replace(/-/g, '')
      identity.number = actualLimpio.substring(0, actualLimpio.length - 1)

      identity.dv = actualLimpio.substring(actualLimpio.length - 1)
    }
    return identity
  } catch (ignore) {
    return null
  }
}

export const _validateMail = (email) => {
  /* const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i */
  const expression =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return expression.test(String(email).toLowerCase())
}

export const _validatePassword = (password) => {
  const expression =
    /^([a-zA-Z0-9_-\u0600-\u065f\u066a-\u06EF\u06fa-\u06ff\ufb8a\u067e\u0686\u06af\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd92-\ufdc7\ufe70-\ufefc\uFDF0-\uFDFD]+){8,20}$/
  return expression.test(String(password).toLowerCase())
}

export const _validPhone = (phone) => {
  try {
    let valid = phone.trim() !== '' && phone.trim().length !== 9
    return valid === false
  } catch (ignore) {
    return false
  }
}

export const getCurrentDate = () => {
  var tz = moment.tz(new Date().toISOString(), 'America/Santiago')
  let dateNow = new Date(
    tz.format('YYYY'),
    tz.format('MM') - 1,
    tz.format('DD'),
  )
  return dateNow
}

export const changeTimeZoneDate = (date) => {
  var tz = moment.tz(date.toISOString(), 'America/Santiago')
  let dateNow = new Date(
    tz.format('YYYY'),
    tz.format('MM') - 1,
    tz.format('DD'),
  )
  return dateNow
}
export const _isWeekEnd = (date) => {
  var tz = moment.tz(date.toISOString(), 'America/Santiago')
  return [0, 6].includes(tz.weekday())
}

export const diffDays = (nextDate) => {
  let dateNow = moment.tz(new Date().toISOString(), 'America/Santiago')
  let dateNext = moment.tz(new Date(nextDate).toISOString(), 'America/Santiago')
  return dateNext.diff(dateNow, 'days')
}

export const encode64 = (input) => {
  let keyStr =
    'ABCDEFGHIJKLMNOP' +
    'QRSTUVWXYZabcdef' +
    'ghijklmnopqrstuv' +
    'wxyz0123456789+/' +
    '='

  input = escape(input)
  let output = ''
  let chr1,
    chr2,
    chr3 = ''
  let enc1,
    enc2,
    enc3,
    enc4 = ''
  let i = 0

  do {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }

    output =
      output +
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4)
    chr1 = chr2 = chr3 = ''
    enc1 = enc2 = enc3 = enc4 = ''
  } while (i < input.length)

  return output
}

export const getLocationTool = async (label) => {
  try {
    let item
    let geoResp = await Geocode.fromAddress(label)
    const results = _.get(geoResp, 'results[0].address_components')
    let locality = null
    let street_number = null
    for (const key in results) {
      if (results.hasOwnProperty(key)) {
        const element = results[key]
        let type = _.get(element, 'types')
        if (_.includes(type, 'administrative_area_level_3')) {
          locality = element.long_name
        }
        if (_.includes(type, 'street_number')) {
          street_number = element.long_name
        }
      }
    }

    let lat = geoResp.results[0].geometry.location.lat
    let lng = geoResp.results[0].geometry.location.lng
    item = {
      label: label,
      lat: lat,
      lng: lng,
      locality: locality,
      nro: street_number,
    }

    return item
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getMonthByIndex = (index) => {
  try {
    var monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    return monthNames[index - 1]
  } catch (error) {
    return ''
  }
}

export const formatDate = (date, convertTz = true) => {
  if (typeof date !== Date) date = new Date(date)

  var tz
  if (convertTz) tz = moment.tz(date.toISOString(), 'America/Santiago')
  else tz = moment.tz(date.toISOString(), 'UTC')

  let dateNow = tz.format('YYYY-MM-DD')
  return dateNow
}

export const formatLongDate = (date, convertTz = true) => {
  if (typeof date !== Date) date = new Date(date)

  var tz
  if (convertTz) tz = moment.tz(date.toISOString(), 'America/Santiago')
  else tz = moment.tz(date.toISOString(), 'UTC')

  let dateNow = tz.format()
  return dateNow
}

export const _handleRegisterValidation = (
  name,
  lastname,
  email,
  password,
  confirmPassword,
  policies,
  currentUser,
) => {
  let errors = {}
  let formIsValid = true

  if (!(name?.length > 0)) {
    formIsValid = false
    errors['name'] = '*Mensaje de error.'
  }

  if (!(lastname?.length > 0)) {
    formIsValid = false
    errors['lastname'] = '*Mensaje de error.'
  }

  if (!_validateMail(email)) {
    formIsValid = false
    errors['email'] = '*Mensaje de error.'
  }

  if ([undefined, null].includes(currentUser)) {
    if (!_validatePassword(password)) {
      formIsValid = false
      errors['password'] = '*Mensaje de error.'
    }

    if (!(password === confirmPassword)) {
      formIsValid = false
      errors['confirmPassword'] = '*Mensaje de error.'
    }
  }
  if (!policies) {
    formIsValid = false
    errors['policies'] = '*Mensaje de error.'
  }
  return {formIsValid, errors}
}
export const maskEmail = (email) => {
  var ans = ''
  var S = email
  S = S.toLowerCase()
  var parts = S.split('@')
  var first = ''
  for (var i = 0; i < parts[0].length; i++) {
    if (i != 0 && i != parts[0].length - 1) {
      first += '*'
    } else {
      first += parts[0][i]
    }
  }
  ans = first + '@' + parts[1]
  return ans
}

export const _getDv = (
  T, //digito verificador
) => {
  var M = 0,
    S = 1
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11

  return S ? S - 1 : 'K'
}

export const _capitalize = (str) => {
  try {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  } catch (ignore) {
    return str
  }
}

export const base64MimeType = (encoded) => {
  var result = null

  if (typeof encoded !== 'string') {
    return result
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

  if (mime && mime.length) {
    result = {
      type: mime[1],
      name: mime[0]?.split(';')[1].replace('name=', ''),
    }
  }

  return result
}

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const calcPageBreak = (value) => {
  try {
    return value === 11 || (value - 11) % 21 === 0
  } catch (ignore) {
    return false
  }
}

export const validateApiToken = (currentToken, currentUid) => {
  let validToken = null;
  
  try {
    let jwtData = getJWTdata(currentToken);
    let currentDate = new Date();
    let expiredAtDate = new Date(0);

    if (jwtData && currentUid === jwtData.user_id) {
      currentDate.setMinutes(currentDate.getMinutes() + 30);
      expiredAtDate.setUTCSeconds(jwtData.exp);
      validToken = expiredAtDate > currentDate ? currentToken : null;
    }
  } 
  catch (error) {
    console.error('Failed to validate token', error)
  }
  return validToken;
}