import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Comming from '../../Components/Comming/Comming'
import { logEvent } from '../../Utils/utils'

function Pillbox() {
  const history = useHistory()

  useEffect(() => {
    logEvent('Pillbox Checked')
  }, [])

  const goBack = () => {
    history.goBack()
  }
  return (
    <div className='flex-grow-1 px-3 pb-4'>
      <div className='content-responsive mx-auto h-100 pt-4 d-flex align-items-start align-items-lg-center'>
        <Comming
          onClick={goBack} />
      </div>
    </div>
  )
}

export default Pillbox