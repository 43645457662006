import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import ButtonShare from '../../Components/Buttons/ButtonShare'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'

import IcAgreement from '../../assets/img/ic_agreement.svg'
import productImg from '../../assets/img/prescription.png'
import Toasts from '../../Components/Toasts/Toasts'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import CustomSelect from '../../Components/Forms/CustomSelect'
import ModalDiscountSendModal from '../../Components/Modals/ModalDiscountSendEmail'
import {logEvent} from '../../Utils/utils'
const _ = require('lodash')

function Agreement(props) {
  const history = useHistory()
  const [stateToasts, setStateToasts] = useState(false)
  const [sendMailIsOpen, setSendMailIsOpen] = useState(false)
  const [checkbox, setCheckbox] = useState(null)
  const [discountEmail, setDiscountEmail] = useState(null)
  const [optionsData, setOptionsData] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])

  const products = _.orderBy(
    props.professionalDiscountCode.products,
    (product) => product.product_name,
    ['asc'],
  )
  const categories = props.professionalDiscountCode.categories

  useEffect(() => {
    let newCategories = _.filter(categories, (category) =>
      _.find(products, {category_id: category.id}),
    )
    let category = newCategories.map((item) => {
      return {
        label: item?.category_name,
        value: item?.id,
      }
    })
    setOptionsData([...[{label: 'todas', value: null}], ...category])
    setFilteredProducts([...products])
  }, [])

  function closeDiscountModal() {
    setSendMailIsOpen(false)
  }

  function copyCodeToClipboard() {
    logEvent('Item Copied', {
      'Item Type': 'Yapp Benefit Code',
      'Element Copied': props.professionalDiscountCode.code,
    })
    const tempInput = document.createElement('input')
    tempInput.value = props.professionalDiscountCode.code
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setStateToasts(true)
  }

  function goBack() {
    history.goBack()
  }

  function goTo(product) {
    logEvent('Medication Sheet Checked', {
      Origin: 'Yapp Benefit',
      'Product Id': product.product_id,
      'Product Name': product?.product_name,
      'Lab Name': product?.laboratory_name,
      'Category Id': product?.category_id,
      // 'Active Principle Origin': '',
      // 'Lab Id': '',
      // 'Active Principle Id': '',
      // 'Active Principle Name': '',
      // 'Category Name': '',
      // 'Material Charged': ''
    })
    history.push(`/product/detail/${product.product_id}`, {
      product_id: product.product_id,
      product: product,
      isComesFromMedicationSheet: true,
    })
  }

  function applyFilter(value) {
    if (value?.value === null) return setFilteredProducts(products)
    let newFilteredProducts = _.filter(products, {category_id: value?.value})
    setFilteredProducts([...newFilteredProducts])
  }

  return (
    <>
      {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && <div>
        <div className="content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
          <div
            className="breadcrumbs c-pointer"
            onClick={() => history.goBack()}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span className="title-primary--regular line-14">Volver</span>
          </div>
        </div>
      </div>}
      <div id="gtm-price-finder" className="flex-grow-1 px-3">
        <div className="content-responsive mx-auto">
          <div className="text-center">
            <h4 className="title-primary--xxl mb-3">Tu código Convenio Yapp</h4>
            <p className="txt-paragraph line-14 mb-3 px-4 px-md-0">
              Comparte este código con tus pacientes para entregarles descuentos
              en sus tratamientos {' '}
              <span className="txt-paragraph--bold">
                canjeables desde la web y/o app.
              </span>
            </p>
          </div>
          <div className="maxw-584 mx-auto d-flex flex-column flex-lg-row pb-3">
            <div className="conetnt-card-code mb-3 mb-lg-0 mx-auto">
              <div className="card-code">
                <div className="d-flex ">
                  <img
                    src={IcAgreement}
                    alt="Agreement"
                    className="img-agreement"
                  />
                  <div className="d-flex flex-column justify-content-center ml-2">
                    <h4 className="txt-paragraph--bold color-gray-4 m-0">
                      Tu código
                    </h4>
                    <h4 className="title-primary m-0">
                      {props.professionalDiscountCode.code}
                    </h4>
                  </div>
                </div>
                <div>
                  <h4
                    className="txt-paragraph--bold color-lilac m-0 c-pointer"
                    onClick={copyCodeToClipboard}>
                    Copiar
                  </h4>
                </div>
              </div>
            </div>
            {/* <div className="d-flex align-items-center pl-lg-3 mx-auto content-share-send-email">
              <ButtonYapp
                className="btn-share-send-email"
                onClick={() => {
                  logEvent('Item Share Opened', {
                    'Shared Type': 'Yapp Benefit Code',
                    'Element Shared': props.professionalDiscountCode.code,
                  })
                  setSendMailIsOpen(true)
                }}
                title={'Compartir código con pacientes'}
              />
            </div> */}
          </div>
          <ModalDiscountSendModal
            closeDiscountModal={closeDiscountModal}
            sendMailIsOpen={sendMailIsOpen}
            discountCode={props.professionalDiscountCode}
            userInfo={props.userInfo}
          />
          <div className="mt-3">
            <h4 className="title-primary mb-2">
              Listado de medicamentos adheridos
            </h4>
            <div className="w-100 mb-3">
              <CustomSelect options={optionsData} onChange={applyFilter} />
            </div>
            <div className="content-card-product mb-5">
              {filteredProducts.map((item, key) => (
                <CardInteracion
                  key={key}
                  id={`gtm-webapp-agreement-${key}`}
                  doAction={() => goTo(item)}>
                  <div className="content-img-medication-sheet">
                    <img
                      src={
                        item?.product_image ? item?.product_image : productImg
                      }
                      alt={`nombre del producto`}
                      className="img-mw-35"
                    />
                  </div>
                  <div className="ml-14 overflow-hidden">
                    <h2 className="title-primary mb-0 line-16 pr-2 text-ellipsis--2">
                      {item.product_name}
                    </h2>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                      {item.formula_name}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                      {item.presentation}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14">
                      {item.laboratory_name}
                    </p>
                    <p className="txt-line--bold m-0 line-14 color-lilac">
                      {item.discount}
                    </p>
                  </div>
                </CardInteracion>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Toasts
        text="Código copiado"
        timer="5000"
        icon="icon-check"
        stateToasts={stateToasts}
        setStateToasts={setStateToasts}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    professionalDiscountCode: state.user.professional_discount_code,
    userInfo: state.user.userInfo,
  }
}

export default connect(mapStateToProps)(Agreement)
