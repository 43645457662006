import Modal from 'react-modal'
import {useState, forwardRef, useImperativeHandle} from 'react'
import Remover from '../../assets/img/remover.svg'

const ModalLegal = forwardRef((props, ref) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
    }
  })

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={null}
      onRequestClose={closeModal}
      className="modal-custom p-0 modal-legal"
      overlayClassName="modal-custom-bg"
      contentLabel="Modal1"
      ariaHideApp={false}>
      <div className="d-flex flex-column justify-content-center align-items-center pt-5 common-top-radius overflow-auto">
        <div onClick={closeModal}>
          <img src={Remover} className="icon-remover" alt="Cerrar" />
        </div>
        { props.src && 
          <iframe
            title="iframe"
            className="iframe-legal"
            src={props.src}>
          </iframe>
        }
        { props.insurance &&
          <div className='my-4 text-justify w-75'>
            <h1 className='text-center txt-2--bold-db mb-2'>
              Mandato Compañía de Seguros
            </h1>
            <h1 className='text-center txt-4--regular-db mb-2'>
              Servicios de Información YAPP SpA
            </h1>
            <div className={'text-center mb-4'}>
              <img style={{height: '150px'}} src={'https://landing.yapp.cl/static/img/main.ade67a8.png'} />
            </div>
            <h6 className='txt-5--regular-db mb-4'>
              El suscrito otorga mandato a <b>{props.insurance}</b> para que esta, en su nombre y representación, procese el 
              siniestro de salud correspondiente a la compra de los medicamentos detallados en este documento y entregue a 
              <span className='txt-5--bold-db mb-4'> Servicios de Información YAPP SpA</span> (“YAPP”), 
              en representación de su aseguradora, el monto del deducible indicado en este documento,
              liberándola de rendir cuenta de dicho mandato.
            </h6>
            <h6 className='txt-5--regular-db mb-2'>
              Asimismo, el suscrito autoriza a YAPP a procesar su siniestro de salud y sus datos personales de acuerdo
              con los términos y condiciones y políticas de privacidad contenidas en
              <span><a href={'https://yapp.cl/mb-tyc'} target="_blank"> Términos &amp; Condiciones</a></span>
            </h6>
          </div>
        }
      </div>
    </Modal>
  )
})

export default ModalLegal
