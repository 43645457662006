import React from 'react'
import Image from 'react-bootstrap/Image'
import {Modal} from 'react-bootstrap'
import {Viewer, Worker, SpecialZoomLevel} from '@react-pdf-viewer/core'
import '../../styles/modalPreview.css'
const ModalPreview = (props) => {
  const {show, onClose, img} = props

  return (
    <>
      {img && (
        <Modal
          centered
          show={show}
          onHide={onClose}
          onRequestClose={onClose}
          contentClassName="modal-content-preview-pdf"
          overlayClassName="modal-custom-bg"
          contentLabel="ModalTypeFormsHome">
          <Modal.Header
            closeButton
            bsPrefix={`${
              process.env.REACT_APP_CLIENT_NAME === 'UC Christus'
                ? 'modal-header-custom-uc'
                : 'modal-header-custom-pdf'
            }`}></Modal.Header>
          <Modal.Body bsPrefix="modal-content-preview-pdf">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
              <div className="mb-2 mt-1 mr-2">
                {img?.includes('.pdf') ? (
                  <div className="programs-pdf">
                    <Viewer
                      fileUrl={img}
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  </div>
                ) : (
                  <Image
                    src={img}
                    fluid
                    className="rounded-modal image-container-pdf "
                  />
                )}
              </div>
            </Worker>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ModalPreview
