import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Input from '../../Components/Forms/Input'
import ModalSuccessChangePass from '../../Components/Modals/ModalSuccessChangePass'
import { changePassword } from '../../Utils/auth'
import { _validatePassword } from '../../Utils/tools'

function ChangePassword() {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [reNewPassword, setReNewPassword] = useState('')
  const [validForm, setValidForm] = useState(true)
  const [buttonDisable, setButtonDisabled] = useState(false)
  const [error, setError] = useState({})
  const [unexpectedError, setUnexpectedError] = useState(false)
  const [successful, setSuccessfull] = useState(false)
  const [modalOpenChangePass, setModalOpenChangePass] = useState(false)
  const [modalStatusChangePass, setModalStatusChangePass] = useState(false)
  const [passRef, setPassRef] = useState()
  const oldPass = useRef()

  useEffect(() => {
    let formIsValid = true
    let errors = {}

    if (password.length === 0) {
      formIsValid = false
    }

    if (!_validatePassword(newPassword)) {
      formIsValid = false
      errors['newPassword'] = '*Contraseña inválida.'
    }

    if (!(newPassword === reNewPassword)) {
      formIsValid = false
      errors['reNewPassword'] = '*Contraseña no coincide.'
    }

    setValidForm(formIsValid)
    setButtonDisabled(formIsValid)
    setError(errors)
  }, [password, newPassword, reNewPassword])

  async function handleChangePassword() {
    clearLocalStates()
    if (validForm) {
      setButtonDisabled(false)
      let response = await changePassword(password, newPassword)
      switch (response) {
        case 'wrong-password':
          setError({ password: 'Contraseña incorrecta' })
          break
        case null:
          setUnexpectedError(true)
          setModalStatusChangePass(false)
          break
        case true:
          setSuccessfull(true)
          setModalOpenChangePass(true)
          setModalStatusChangePass(true)
          setPassword('')
          setNewPassword('')
          setReNewPassword('')
          break
        default:
      }
    }
  }

  function goBack() {
    history.goBack()
  }

  function clearLocalStates() {
    setUnexpectedError(false)
    setSuccessfull(false)
    setError({})
  }

  return (
    <>
      <div className='py-3 px-3'>
        <div className="content-responsive mx-auto d-flex justify-content-between">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="txt-4--regular-pr">Volver</span>
          </div>
        </div>
      </div>
      <div className="flex-grow-1">
        {/* {successful && <div>Cambio de contraseña exitoso!</div>} */}
        {/* {unexpectedError && <div>Erro extraño :(</div>} */}
        <div className="maxw-290 mx-auto px-3 px-md-0">
          <h2 className="title-primary--3xl text-center mb-2">
            Contraseña nueva
          </h2>
          <p className="title-primary--light text-center mb-3">
            Crea tu nueva contraseña, debe tener un mínimo de 8 caracteres.
          </p>
          <div className="mb-3">
            <Input
              id={"gtm-webapp-change-password-current-pass"}
              showPass
              label="Contraseña actual"
              placeholder="Contraseña actual"
              value={password || ''}
              error={{ message: error?.password }}
              doChange={(value) => setPassword(value)}
            />
          </div>
          <div className="mb-3">
            <Input
              id={"gtm-webapp-change-password-new-pass"}
              showPass
              label="Crear contraeña nueva"
              placeholder="Crear contraeña nueva"
              value={newPassword || ''}
              error={newPassword.length > 0 && { message: error?.newPassword }}
              doChange={(value) => setNewPassword(value)}
            />
          </div>
          <div className="mb-4">
            <Input
              id={"gtm-webapp-change-password-repeat-new-pass"}
              showPass
              label="Repetir contraseña nueva"
              value={reNewPassword || ''}
              placeholder="Repetir contraseña nueva"
              error={
                reNewPassword.length > 0 && { message: error?.reNewPassword }
              }
              doChange={(value) => setReNewPassword(value)}
            />
          </div>
          <button
            disabled={!buttonDisable}
            id="gtm-webapp-change-password-btn-send"
            className="btn-default--small btn-default font--14 mx-auto mb-5"
            onClick={() => handleChangePassword()}>
            Cambiar la contraseña
          </button>
        </div>
      </div>
      <ModalSuccessChangePass
        modalOpenChangePass={modalOpenChangePass}
        setModalOpenChangePass={setModalOpenChangePass}
        status={modalStatusChangePass}
      />
    </>
  )
}

export default ChangePassword
