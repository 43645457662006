import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {logEvent, formatDate} from '../../Utils/utils'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import { GetOrders, GetSubscriptions, GetRegularSubscriptions, getPrescriptionFiles } from '../../Utils/api'

import PillyNoShopping from '../../assets/img/ic_pilly_no_shopping.svg'
import MyShoppingCard from '../../Components/Card/MyShoppingCard'
import {pharmacyChainUrl} from '../../Utils/constants'
import {_getUserId} from '../../Utils/auth'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import IcAgreement from '../../assets/img/ic_agreement.svg'
const _ = require('lodash')

function MyShopping() {
  const history = useHistory()

  const [ordersData, setOrdersData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setLoading(true)

    try {
      let user_id = await _getUserId()
      let orders = GetOrders()
      let subscriptions = GetSubscriptions()
      let regularSubscriptions = GetRegularSubscriptions(user_id)
      let prescriptionFiles = getPrescriptionFiles()
      let dataOrders = []

      Promise.all([orders, subscriptions, regularSubscriptions, prescriptionFiles]).then((resultData) => {
        dataOrders = setPurchaseOrderData(resultData[0].data)
        let dataSubscriptions = setSubscriptionData(resultData[1].data, resultData[3])
        let dataRegularSubscriptions = setRegularSubscriptionData(resultData[2].data, resultData[3])

        dataOrders = _.orderBy(
          dataOrders.concat(dataRegularSubscriptions).concat(dataSubscriptions),
          (order) => order.datetime,
          ['desc'],
        )
      })
      .catch((err) => {
        console.log('Error in multiple promises')
      })
      .finally(() => {
        setOrdersData(dataOrders)
        setLoading(false)
      })

    } catch (ignore) {}
  }, [])

  const setPurchaseOrderData = (purchaseOrderData) => {
    let dataOrders = []
    if (purchaseOrderData?.length > 0) {
      try {
        dataOrders = purchaseOrderData.map((c) => {
          let colorStatus = c.status === 'Cancelado'
            ? 'warning'
            : 'success'

          return {
            id: c.orderId,
            img: pharmacyChainUrl + c.pharmacyChainLogo,
            title: c.pharmacyChainName,
            product: c.products + (c.products > 1 ? ' productos' : ' producto'),
            state_value: c.orderStatusText,
            status: c.status,
            state: colorStatus,
            datetime: new Date(c.nextDelivery),
            date: formatDate(new Date(c.nextDelivery)),
            delivery_frequency: null,
            agreement_number: 'Nº order de compra: ' + c.orderId,
            type: 'oc',
            total: c.orderTotal,
            phone: c.phone,
            address: c.address,
            reject_status: c.reject_status,
            reject_message: c.reject_message,
          }
        })
      }
      catch (err) {
        console.log('Error setting purchase orders')
      }
    }
    return dataOrders
  }

  const setSubscriptionData = (subscriptionData, prescriptionFiles) => {
    let dataSubscriptions = []
    if (subscriptionData?.length > 0) {
      try {
        dataSubscriptions = subscriptionData.map((c) => {
          let prescriptionList = setPublicLink(c.prescriptionList, prescriptionFiles)
          let countProducts = JSON.parse(c.productList).length
          let colorStatus = c.status === 'Cancelado'
            ? 'warning'
            : 'success'

          return {
            id: c.subscriptionId,
            can_be_edited: c.canBeEdited,
            img: IcAgreement,
            title: c.subscriptionTitle,
            product:
              countProducts + (countProducts > 1 ? ' productos' : ' producto'),
            state_value: c.status,
            status: c.status,
            state: colorStatus,
            datetime: new Date(c.nextDelivery),
            date: formatDate(new Date(c.nextDelivery)),
            delivery_frequency: 'cada ' + c.frequency + ' días',
            agreement_number: 'Nº convenio: ' + c.subscriptionId,
            type: 'convenio',
            total: c.nextValue,
            phone: c.phone,
            address: c.address,
            client_name: c.client_name,
            identity: c.clientTax,
            delivery_hours: c.deliveryHours,
            payment_type: c.paymentType,
            payment_card: c.paymentCard,
            prescriptions: prescriptionList,
            purchase_orders: JSON.parse(c.purchaseOrderList),
            products: JSON.parse(c.productList),
          }
        })
      }
      catch (err) {
        console.log('Error setting subscriptions')
      }
    }
    return dataSubscriptions
  }

  const setRegularSubscriptionData = (regularSubscriptionsData, prescriptionFiles) => {
    let dataRegularSubscriptions = []
    if (regularSubscriptionsData?.length > 0) {
      try {
        dataRegularSubscriptions = regularSubscriptionsData.map((c) => {
          let prescriptionList = setPublicLink(c.prescriptionList, prescriptionFiles)
          let countProducts = JSON.parse(c.productList).length
          let colorStatus = c.status === 'Cancelado'
            ? 'warning'
            : 'success'
          let specialSubscription =
            JSON.parse(c.productList).filter((p) => p.type === 'special')
              .length > 0

          return {
            id: c.subscriptionId,
            can_be_edited: c.canBeEdited,
            img: specialSubscription
              ? IcAgreement
              : pharmacyChainUrl + c.pharmacyChainLogo,
            title: specialSubscription
              ? c.subscriptionSpecialTitle
              : c.subscriptionTitle,
            product:
              countProducts + (countProducts > 1 ? ' productos' : ' producto'),
            state_value: c.status,
            status: c.status,
            state: colorStatus,
            delivery_date: c.nextDelivery,
            shortest_delivery_date: c.shortestDelivery,
            datetime: new Date(c.nextDelivery),
            date: formatDate(new Date(c.nextDelivery)),
            delivery_frequency: 'cada ' + c.frequency + ' días',
            agreement_number:
              (specialSubscription ? 'Nº convenio: ' : 'Nº programación: ') +
              c.subscriptionId,
            type: specialSubscription ? 'convenio' : 'regular',
            total:
              (specialSubscription
                ? _.sumBy(JSON.parse(c.productList), (c) => c.price)
                : c.nextValue) +
              c.deliveryCost -
              c.discountValue,
            phone: c.phone,
            address: c.address,
            client_name: c.clientName,
            identity: c.clientTax,
            delivery_hours: c.deliveryHours,
            payment_type: c.paymentType,
            payment_card: c.paymentCard,
            prescriptions: prescriptionList,
            purchase_orders: JSON.parse(c.purchaseOrderList),
            products: JSON.parse(c.productList),
          }
        })
      }
      catch (err) {
        console.log('Error setting regular subscriptions')
      }
    }
    return dataRegularSubscriptions
  }

  const setPublicLink = (prescriptionList, prescriptionFiles) => {
    let publicLinks = []

    try {
      prescriptionList = JSON.parse(prescriptionList)

      for (const prescription of prescriptionList) {
        let prescriptionItem = prescriptionFiles.data.find(file => file.prescription_file === prescription)
        if (prescriptionItem) {
          publicLinks.push(prescriptionItem.url)
        }
      }
    }
    catch (err) {
      console.log('Error setting prescription files')
    }
    
    return publicLinks
  }

  const goTo = (item) => {
    logEvent('Purchase Checked', {
      'Purchase Type':
        item.type === 'convenio'
          ? 'Yapp Benefit'
          : item.type === 'regular'
          ? 'Purchase Scheduled'
          : 'Spot',
      Status: ['Rechazado', 'Entregado', 'Cancelado'].includes(item.status)
        ? 'inactive'
        : 'active',
    })
    if (['convenio', 'regular'].includes(item.type)) {
      history.push(`/my_shopping_detail/${item.id}`, item)
    } else {
      history.push(`/my_shopping_tracking/${item.id}`, item)
    }
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className="flex-grow-1 px-3 pb-4">
      {loading ? (
        <div className="d-flex justify-content-center w-100 py-5">
          <img src={loadingYapp} alt="loading" height={70} />
        </div>
      ) : (
        <div className="content-responsive mx-auto h-100 pt-4 ">
          {ordersData?.length <= 0 ? (
            <div>
              <div className="mb-4">
                <h3 className="txt-1--bold-db m-0">Tus compras</h3>
              </div>
              <div className="mx-auto d-flex flex-column align-items-center">
                <img
                  src={PillyNoShopping}
                  alt="Pilly"
                  className="img-pilly mb-3"
                />
                <div className="text-center">
                  <p className="txt-3--light-db mb-2">
                    No tienes compras aún,
                    <br />
                    ¡pero eso podría cambiar!
                  </p>
                  <ButtonYapp
                    title={'Ir a comprar'}
                    className="mx-auto"
                    onClick={() => history.push('/search')}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-4">
                <h3 className="txt-1--bold-db m-0">Tus compras</h3>
                <p className="txt-3--light-db">
                  Conoce el estado de tus compras
                </p>
              </div>
              {ordersData.filter(
                (c) =>
                  !['Rechazado', 'Entregado', 'Cancelado'].includes(c.status),
              ).length > 0 && (
                <div className="mb-3">
                  <h3 className="mb-0 txt-3--bold-db mb-2">Compras activas</h3>
                  <div className="d-flex flex-wrap">
                    {ordersData &&
                      ordersData
                        .filter(
                          (c) =>
                            !['Rechazado', 'Entregado', 'Cancelado'].includes(
                              c.status,
                            ),
                        )
                        .map((item, key) => {
                          return (
                            <MyShoppingCard
                              doAction={() => goTo(item)}
                              data={item}
                              key={key}
                              className="card-ms"
                            />
                          )
                        })}
                  </div>
                </div>
              )}
              {ordersData.filter((c) =>
                ['Rechazado', 'Entregado', 'Cancelado'].includes(c.status),
              ).length > 0 && (
                <div>
                  <h3 className="mb-0 txt-3--bold-db mb-2">
                    Compras inactivas
                  </h3>
                  <div className="d-flex flex-wrap">
                    {ordersData &&
                      ordersData
                        .filter((c) =>
                          ['Rechazado', 'Entregado', 'Cancelado'].includes(
                            c.status,
                          ),
                        )
                        .map((item, key) => {
                          return (
                            <MyShoppingCard
                              doAction={() => goTo(item)}
                              data={item}
                              key={key}
                              className="card-ms"
                            />
                          )
                        })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MyShopping
