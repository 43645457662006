import React, {useState, useEffect, useMemo} from 'react'
import {useHistory} from 'react-router'
import {connect, useSelector} from 'react-redux'
import {logEvent} from '../../Utils/utils'
import AsyncSelect from 'react-select/async'
/* imgs */
import IcAgreement from '../../assets/img/ic_yapp_approved.svg'
import icSearch from '../../assets/img/ic_search.svg'
import icSearchSecondary from '../../assets/img/ic_search_secondary.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import CustomSelect from '../../Components/Forms/CustomSelect'
import productImg from '../../assets/img/prescription.png'
import CardProduct from '../../Components/CardProduct/CardProduct'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import {getProfessionalDiscountCode} from '../../Utils/api'
import Iframe from 'react-iframe'
import {getJWTdata} from '../../Utils/tools'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import {theme} from '../../Utils/constants'
const debounce = require('lodash/debounce')
const _ = require('lodash')

function NewAgreement(props) {
  const history = useHistory()

  const typeFormUrl = useSelector((state) => state?.app?.config?.typeform_url)
  const identity = useSelector((state) => state.user.identity)
  const [optionsData, setOptionsData] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [products, setProducts] = useState([])
  const [appState, setAppState] = useState({
    loading: false,
    inputValue: '',
    groupedOptions: [],
  })
  /*  useEffect(() => {
    let additionalMenu = []
    let products = []
    let categories = []
    const fetchData = async () => {
      try {
        let jwtData = getJWTdata()
        additionalMenu = await getProfessionalDiscountCode(
          jwtData?.user_id,
          identity,
        )
        if (additionalMenu.data[0]?.length > 0) {
          categories = additionalMenu.data[2]
          products = _.orderBy(
            additionalMenu.data[1],
            (product) => product.product_name,
            ['asc'],
          )
          setProducts(products)
          let newCategories = _.filter(categories, (category) =>
            _.find(products, {category_id: category.id}),
          )
          let category = newCategories.map((item) => {
            return {
              label: item?.category_name,
              value: item?.id,
            }
          })
          setOptionsData([...[{label: 'todas', value: null}], ...category])
          setFilteredProducts([...products])
        }
      } catch (ignore) {
      } finally {
        setAppState({loading: false})
      }
    }
    fetchData()
  }, []) */

  function goBack() {
    history.goBack()
  }
  const onClickLaboratory = (item, productList) => {
    logEvent('Discount Checked', {
      Type: 'Discount',
      'Origin Material': 'Discount',
      'Lab Name': item?.laboratory_name,
      'Lab Id': item?.laboratory_id,
    })
    history.push(`/pro/new_agreement/detail/${item.lab_name}`, {
      item: item,
      name: item.lab_name,
      lab_id: item.laboratory_id,
      lab_image: item.lab_image,
    })
  }
  // TODO: Mock data, delete after
  const laboratories = {
    data: [
      /* {
        lab_name: 'Astrazeneca',
        lab_id: 29,
        lab_image:
          'https://s3.us-east-2.amazonaws.com/s3.design/Laboratorios/astrazeneca.png',
      }, */
      {
        lab_name: 'Itf-labomed',
        laboratory_id: 59,
        lab_image:
          'https://s3.us-east-2.amazonaws.com/s3.design/Laboratorios/itf_labomed.png',
      },
      {
        lab_name: 'Colgate',
        laboratory_id: 26,
        lab_image: 'https://i.imgur.com/QLoTFW8.png',
      },
    ],
  }

  const productList = {
    message: 'OK: Success',
    data: [
      [
        {
          product_id: '2c13ccc2-cdf8-11eb-8e9b-067f',
          internal_product_id: 16858,
          product_name:
            'Colgate Periogard Enjuague Bucal Sin Alcohol X 250 Mililitros',
          product_img: '16858_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 8990,
          discount_price: 5394,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '838b5002-0c1f-11ec-a606-067f',
          internal_product_id: 107150,
          product_name: 'Crema Dental elmex Anticaries X 90 Gramos',
          product_img: '107150_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7490,
          discount_price: 4494,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '6908c929-430b-11ec-a606-067f',
          internal_product_id: 107904,
          product_name: 'Crema Dental elmex Sensitive X 110 Gramos',
          product_img: '107904_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7490,
          discount_price: 4494,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ae8a3-430b-11ec-a606-067f',
          internal_product_id: 107905,
          product_name:
            'Cepillo de Dientes elmex Sensitive Extra Suave X 1 unidad',
          product_img: '107905_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ce1f4-430b-11ec-a606-067f',
          internal_product_id: 107906,
          product_name: 'Cepillo de Dientes elmex Anticaries Suave X 1 unidad',
          product_img: '107906_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ee574-430b-11ec-a606-067f',
          internal_product_id: 107907,
          product_name: 'Cepillo de Dientes elmex Ultra Suave X 1 unidad',
          product_img: '107907_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '69110452-430b-11ec-a606-067f',
          internal_product_id: 107908,
          product_name: 'Enjuague Bucal elmex Sensitive X 400 Mililitros',
          product_img: '107908_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7690,
          discount_price: 4614,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '838b54b6-0c1f-11ec-a606-067f',
          internal_product_id: 107151,
          product_name: 'Enjuague Bucal elmex Anticaries X 400 Mililitros',
          product_img: '107151_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7690,
          discount_price: 4614,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '2c17d783-cdf8-11eb-8e9b-067f',
          internal_product_id: 25190,
          product_name: 'Colgate Periogard Pasta Dental X 90 Gramos',
          product_img: '25190_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 6890,
          discount_price: 4134,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '2c17d768-cdf8-11eb-8e9b-067f',
          internal_product_id: 25189,
          product_name:
            'Colgate Periogard Cepillo Dental Extra Suave X 1 Unidad',
          product_img: '25189_v3.jpg',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 5690,
          discount_price: 3414,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '3395128c-4efc-11ed-b758-02a9',
          internal_product_id: 109108,
          product_name: 'Sonirem 10 Mg / Ml X 30 Mililitros',
          product_img: '109108_v3.jpg',
          product_family: 'periogard',
          laboratory_id: 59,
          laboratory_name: 'Itf-labomed',
          presentation: '30 Mililitros',
          formula_name: 'Zolpidem 10 mg / ml',
          list_price: null,
          discount_price: null,
          discount_value: null,
          discount: null,
          product_file:
            'https://s3.us-east-2.amazonaws.com/s3.yapp/pdf/programs/Cup%C3%B3n_ITF.pdf',
        },
      ],
    ],
  }

  const categories = {
    data: [
      {
        id: '8daab789-8cd3-11eb-a7da-06db',
        category_name: 'antihipertensivos',
      },
      {
        id: '8daaecb7-8cd3-11eb-a7da-06db',
        category_name: 'antiulcerosos',
      },
      {
        id: '8daaa108-8cd3-11eb-a7da-06db',
        category_name: 'diabetes',
      },
      {
        id: '8dabdd9c-8cd3-11eb-a7da-06db',
        category_name: 'hipoglicemiantes antidiabéticos',
      },
      {
        id: '8dabde40-8cd3-11eb-a7da-06db',
        category_name: 'hipolipemiantes hipocolesterolemiantes',
      },
    ],
  }

  const goTo = (item, productList) => {
    logEvent('Discount Checked', {
      Type: 'Discount',
      'Origin Material': 'Discount',
      'Lab Name': item?.laboratory_name,
      'Lab Id': item?.laboratory_id,
    })
    history.push(
      `/pro/new_agreement/detail/${item.data?.laboratory_name}?product_id=${item.data?.product_id}`,
      {
        product_id: item.data.product_id,
        product: item,
        lab_id: item.data.laboratory_id,
        name: item.data.laboratory_name,
        lab_image:
          laboratories.data.find(
            (lab) => lab.laboratory_id === item.data.laboratory_id,
          )?.lab_image || null,
      },
    )
  }

  function applyFilter(value) {
    if (value?.value === null) return setFilteredProducts(products)
    let newFilteredProducts = _.filter(products, {category_id: value?.value})
    setFilteredProducts([...newFilteredProducts])
  }

  const promiseOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let prod = productList?.data[0]?.filter((product) =>
        product.product_name.toLowerCase().includes(inputValue.toLowerCase()),
      )
      if (prod.length > 0) {
        let group = prod.map((p) => {
          return {value: p.product_id, label: p.product_name, data: p}
        })
        let options = []
        options.push({
          label: 'Products',
          options: group,
        })
        setAppState({groupedOptions: options})
        callback(options)
      }
    }
  }
  function onChange(value) {
    if (value?.value === null) return setFilteredProducts(products)
    let newFilteredCards = _.filter(products, {product_id: value?.value})
    setFilteredProducts([...newFilteredCards])
  }
  const onFocus = (value) => {
    return setFilteredProducts(products)
  }
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )
  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  const borderColor = theme?.border_color
    ? `1px solid ${theme?.border_color}`
    : props.themeColor
    ? `1px solid var(${props.themeColor})`
    : '1px solid #6578FF'

  const searchProductsStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius,
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: borderColor,
      '&:hover': {
        border: borderColor,
      },
      ':before': {
        backgroundImage: `url(${icSearchSecondary})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 0,
        height: '41px',
        width: '41px',
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '20px',
      zIndex: 1049,
      border: '1px solid #E9E6FE',
      paddingTop: '20px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '14px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      padding: '4px 10px',
      position: 'relative',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
            top: '50%',
            transform: 'translateY(-50%)',
          }
        : {},
    }),
  }
  return (
    <div className="flex-grow-1 px-3">
      <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
        <div className="breadcrumbs c-pointer" onClick={goBack}>
          <div className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </div>
          <span
            className="txt-4--regular-pr"
            style={{color: 'var(--theme-color)'}}>
            Volver
          </span>
        </div>
      </div>
      {appState.loading ? (
        <div className="d-flex justify-content-center w-100 py-5">
          {/* <img src={loadingYapp} alt="loading" height={100} /> */}
        </div>
      ) : laboratories.data?.length > 0 ? (
        <>
          <div className="content-responsive pt-4">
            <p className="title-primary--regular mb-4">
              Comparte programas para pacientes y ayúdalos en sus tratamientos
              crónicos
            </p>
            <h2 className="title-primary--xxl ">Buscador de medicamentos</h2>

            <div className="mb-2">
              <AsyncSelect
                className="search-product z-index-9999"
                id="gtm-webapp-search-products-legacy"
                isClearable
                onFocus={onFocus}
                onChange={goTo}
                noOptionsMessage={() => 'Sin resultados...'}
                placeholder="Busca tu producto"
                formatGroupLabel={formatGroupLabel}
                defaultOptions={appState.groupedOptions}
                loadOptions={loadSuggestions}
                styles={searchProductsStyle}
              />
            </div>
            <div className="content-new-agreement px-0">
              <div className="m-0 mb-2">
                {/*  <h2 className="title-primary--xxl ">Buscar por categoría</h2>
              </div>
              <div>
                <div className="w-100 mb-3">
                  <CustomSelect options={optionsData} onChange={applyFilter} />
                </div> */}
                <h2 className="title-primary--xxl m-0 mb-3">
                  Laboratorios con cupones
                </h2>
                {laboratories.data.map((item, key) => (
                  <CardInteracion
                    key={item.laboratory_id}
                    id={`gtm-webapp-programs-result-${key}`}
                    doAction={() => onClickLaboratory(item, productList)}>
                    <div className="content-img-new-agreement">
                      <img
                        src={item?.lab_image}
                        alt={`nombre del producto`}
                        className="card-agreements-img"
                      />
                    </div>
                    <div className="ml-14 overflow-hidden">
                      <h3 className="semi-bold line-16 font--16 text-ellipsis--2 font--16 m-0">
                        {item?.lab_name}{' '}
                      </h3>
                    </div>
                  </CardInteracion>
                ))}
                {/* <div className="content-card-product">
                  {filteredProducts.map((item, key) => (
                    <CardProduct
                      key={key}
                      id={`gtm-webapp-newagreement-${key}`}
                      doAction={() => goTo(item)}>
                      <div className="content-img-new-agreement">
                        <img
                          src={
                            item?.product_image
                              ? item?.product_image
                              : productImg
                          }
                          alt={`nombre del producto`}
                          className="w-100"
                        />
                      </div>
                      <div className="ml-2 overflow-hidden">
                        <h2 className="title-primary mb-0 line-16 pr-2 text-ellipsis--2">
                          {item.product_name}
                        </h2>
                        <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                          {item.formula_name}
                        </p>
                        <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2 ">
                          {item.presentation}
                        </p>
                        <p className="txt-paragraph--small m-0 line-14">
                          {item.laboratory_name}
                        </p>
                        <p className="txt-line--bold m-0 line-14 color-lilac">
                          {item.discount}
                        </p>
                      </div>
                    </CardProduct>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Iframe
          url={typeFormUrl}
          width="100%"
          id="myId"
          className="content-type-form pt-3"
        />
      )}
    </div>
  )
}
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url(${icSearch})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 22,
    width: 22,
  },
})

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '1em',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.8rem',
    borderColor: 'transparent',
    fontFamily: 'var(--font-light)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? null
        : null,
      color: '#282e55',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : null),
      },
    }
  },
  input: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1em',
    fontFamily: 'var(--font-light)',
    color: '#282e55',
    height: '3rem',
  }),
  placeholder: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1.2em',
    fontFamily: 'var(--font-light)',
    color: '#BDBDBD',
  }),
  singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
}
const mapStateToProps = (state) => {
  return {
    professionalDiscountCode: state.user.professional_discount_code,
    userInfo: state.user.userInfo,
  }
}

export default connect(mapStateToProps)(NewAgreement)
