import {useState} from 'react'
import {useLocation} from 'react-router'
import {useDispatch} from 'react-redux'
import {requestGenerateLink} from '../../Utils/api'
import {base_url} from '../../Utils/constants'
import IcMedicosSuccess from '../../assets/img/medicos_validacion_exitosa.svg'
import {changeInitialLoading} from '../../redux/modules/app'

function SuccessHome(props) {
  const [sending, setSending] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()

  async function goMenu() {
    if (location.state?.hash && location.state?.email) {
      setSending(true)
      dispatch(changeInitialLoading(true))
      let result = await requestGenerateLink(
        location.state?.hash,
        {email: location.state.email},
        {identity: location.state.identity},
        {user_type: location.state.user_type},
      )
      if (result?.data?.url) {
        let urlObj = new URL(result.data.url)
        let url =
          process.env.REACT_APP_ENV_HOST === 'LOCAL'
            ? result.data.url.replace(urlObj.origin, base_url)
            : result.data.url
        dispatch(changeInitialLoading(false))
        window.location.replace(url)
      } else {
        dispatch(changeInitialLoading(false))
        console.log('Error al redireccionar a la URL')
      }
    }
  }

  return (
    <div className="flex-grow-1 mt-md-2">
      <div className="content-home content-home-pro mb-4">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h2
            id="gtm-webapp-home-pro-txt-sucess"
            className="title-primary--3xl mb-3">
            ¡Has sido validado con éxito!
          </h2>
          <img
            id="gtm-webapp-home-pro-img-sucess"
            src={IcMedicosSuccess}
            alt="success"
            className="mb-3 img-doctors"
          />
          <button
            id="gtm-webapp-home-pro-btn-sucess"
            className="btn-default btn-next mb-4 mt-3"
            disabled={sending}
            onClick={() => goMenu()}>
            Comenzar
          </button>
        </div>
      </div>
    </div>
  )
}

export default SuccessHome
