import { useEffect, useState, memo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Geocode from 'react-geocode'
import Modal from 'react-modal'
import LocationAutocomplete from './LocationAutocomplete'

import { getUserInfo, saveLocation } from '../Utils/service'
import { setLocation, setCommune } from '../redux/modules/app'
import { getDeliveryCommunes, getDeliveryPriceByPharmacyAndMunicipality } from '../Utils/api'

import Input from './Forms/Input'

const _ = require('lodash')

function Location(props) {
  const alert = useAlert()
  const history = useHistory()

  const [requestLocation, setRequestLocation] = useState(false)
  const [locationInput, setLocationInput] = useState(null)
  const [requestNumber, setRequestNumber] = useState(false)
  const [nroLocation, setNroLocation] = useState(null)
  const [listCommunes, setCommunes] = useState(null)

  const [isLoadingRemoteLocation, setLoadingRemoteLocation] = useState(true)
  const [isCurrentLocation, setIsCurrentLocation] = useState(false)
  const [positionWatchId, setPositionWatchId] = useState(null)
  const [currentPosition, setCurrentPosition] = useState(null)
  const [isAppleDevice, setIsAppleDevice] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpenIvalidLoc, setIsOpenIvalidLoc] = useState(false)

  const onePharmacy = useSelector((state) => state.app.one_pharmacy)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  function closeModalInvalidLoc() {
    setIsOpenIvalidLoc(false)
  }

  useEffect(() => {
    Geocode.setApiKey('AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw')
    Geocode.setLanguage('es-419')
    Geocode.setRegion('cl')
    Geocode.enableDebug()
    props.setLocation(null)
    setLocationInput(null)
    setNroLocation(null)
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      setIsAppleDevice(true)
    }
    return () => {
      positionWatchId && navigator.geolocation.clearWatch(positionWatchId)
    }
  }, [])

  useEffect(() => {
    setLoadingRemoteLocation(true)
    getUserInfo()
      .then((resp) => {
        if (resp?.location) {
          let location = resp?.location

          if (location) {
            setLocationComponent(
              location?.region || null,
              location?.label,
              location?.lat,
              location?.lng,
              location?.locality,
              location?.commune_id,
              location?.nro,
              true
            )
            setRequestLocation(false)
            setLoadingRemoteLocation(false)
          }
        } else {
          props.setLocation(null)
          setRequestLocation(true)
          setLoadingRemoteLocation(false)
        }
      })
      .catch((err) => {
        setLoadingRemoteLocation(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (
      currentPosition?.coords?.latitude &&
      currentPosition?.coords?.longitude
    ) {
      getLocationText(
        currentPosition.coords.latitude,
        currentPosition.coords.longitude,
      )
    }
  }, [currentPosition])

  useEffect(() => {
    getDeliveryCommunes()
      .then((el) => {
        if (el?.data) setCommunes(el?.data.map((el) => el.name).join(', '))
      })
      .catch((err) => {
        console.log(err)
        return history.push('/error')
      })
  }, [])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function afterOpenModalInvalidLoc() {
    // references are now sync'd and can be accessed.
  }

  async function updateGlobalCommune(newLocation, firstTimeComponentRendering = false){
    try {
      if (!newLocation) { return; }

      if ( (newLocation?.commune_id !== props.globalCommune.id) && firstTimeComponentRendering ) {
        const newGlobalCommune = {
          id: newLocation.commune_id,
          name: newLocation.locality
        }
        props.setCommune(newGlobalCommune)
        return;
      }

      if ( !firstTimeComponentRendering && (newLocation?.commune_id !== props.globalCommune.id) ) {
        props.handleParentLoading && props.handleParentLoading(true)

        let pharmacyChainId = props.pharmacy_chain_id
        let item = localStorage.getItem('selectedQuotationItem')

        if (pharmacyChainId || item) {
          if (!pharmacyChainId)
            item = JSON.parse(item)

          pharmacyChainId = pharmacyChainId ?? item.pharmacy_chain_id

          const newdata = await getDeliveryPriceByPharmacyAndMunicipality(pharmacyChainId, newLocation?.commune_id)
          if ( !newdata?.cost || !newdata?.delivery_date ) {
            props.handleParentLoading && props.handleParentLoading(false)
            setIsOpenIvalidLoc(true)
            props.setLocation(null)
            return;
          }
          const newGlobalCommune = {
            id: newLocation.commune_id,
            name: newLocation.locality
          }
          props.setCommune(newGlobalCommune)
          props.handleParentDeliveryCost({cost: newdata?.cost})
          props.handleParentDeliveryDate(newdata?.delivery_date)
          props.handleParentLoading(false)
        }
      }
    }
    catch (err) {
      console.log('Error updating global commune', err)
    }
  }

  const changeLocation = () => {
    props.setLocation(null)
    updateGlobalCommune(null)
    setLocationInput(null)
    setNroLocation(null)
    setRequestLocation(true)
  }

  const setLocationComponent = (
    region,
    label,
    lat,
    lng,
    locality,
    communeId,
    nro = null,
    firstComponentRendering = false
  ) => {
    let item = {
      region: region,
      label: label,
      lat: lat,
      lng: lng,
      locality: locality,
      commune_id: communeId,
      nro: nro,
    }

    props.setLocation(item)
    updateGlobalCommune(item, firstComponentRendering)
    saveLocation(item)
    setLocationInput(item)
  }

  function onPress(element, item) {
    let communeFilter = props.communes.filter(
      (el) => el.name === item?.locality,
    )
    if (communeFilter?.length === 0) {
      setRequestLocation(false)
      openModalInvalidLoc()
    } else {
      let street_number = props.location?.nro
      if (![undefined, null].includes(item.street_number)) {
        street_number = item.street_number
      }

      setLocationComponent(
        item.region,
        item.label,
        item.lat,
        item.lng,
        item.locality,
        _.get(communeFilter, '[0].id'),
        street_number,
      )
      setRequestLocation(false)
    }
  }

  function changeNroLocation(el) {
    props.setLocation({ ...locationInput, nro: el.target.value })
    saveLocation({ ...locationInput, nro: el.target.value })
    setLocationInput({ ...locationInput, nro: el.target.value })
    setNroLocation(el.target.value)
  }

  function onBlur() {
    setRequestLocation(false)
  }
  function onFocus() {

  }

  function getLocationText(latitude, longitude) {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const geoResp = _.get(response.results, '[0].address_components')
        let region = null
        let locality = null
        for (const key in geoResp) {
          if (geoResp.hasOwnProperty(key)) {
            const element = geoResp[key]
            let type = _.get(element, 'types')
            if (_.includes(type, 'administrative_area_level_1')) {
              region = element.long_name
            }
            if (_.includes(type, 'administrative_area_level_3')) {
              locality = element.long_name
            }
          }
        }

        let item = {
          region: region,
          label: _.get(response.results, '[0].formatted_address'),
          lat: latitude,
          lng: longitude,
          locality: locality,
        }

        let communeFilter = props.communes?.filter((el) => el.name === locality)
        if (communeFilter?.length === 0) {
          setRequestLocation(false)
          alert.show(
            'La ubicación ingresada no esta entre las comunas que despachamos.',
          )
        } else {
          setLocationComponent(
            item.region,
            item.label,
            item.lat,
            item.lng,
            item.locality,
            _.get(communeFilter, '[0].id'),
          )
          setRequestLocation(false)
        }
      },
      (error) => {
        console.error(error)
      },
    )
  }

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      function (resp) {
        setCurrentPosition(resp)
      },
      (err) => {
        alert.error(
          'Si no logramos obtener tu Ubicación revisa los permisos en tus preferencias de seguridad.',
        )
        if (isAppleDevice) {
          watchCurrentLocation()
        } else {
          setIsCurrentLocation(false)
        }
      },
      { timeout: 3000 },
    )
  }

  function watchCurrentLocation() {
    let id = navigator.geolocation.watchPosition(
      (resp) => {
        setCurrentPosition(resp)
      },
      (err) => {
        console.log('Err', err)
      },
    )
    setPositionWatchId(id)
  }

  async function onChangeInput(event) {
    let tmp = !isCurrentLocation
    setIsCurrentLocation(tmp)

    if (tmp) {
      if (navigator.geolocation) {
        getCurrentLocation()
      } else {
        console.log('Sin geo')
      }
    } else {
      positionWatchId && navigator.geolocation.clearWatch(positionWatchId)
    }
  }

  function blurNumber(e) {
    if (e.type === 'keydown' && e.key === 'Enter') {
      setRequestNumber(false)
    } else if (e.type === 'blur') {
      setRequestNumber(false)
    }
  }

  function openModalInvalidLoc() {
    setIsOpenIvalidLoc(true)
  }

  return (
    <div className="location-cotent-wrap">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}>
        <h4 className="title-modal-altern mb-3">Lo sentimos...</h4>
        <div className="d-flex flex-column justify-content-center mb-3">
          <p className="txt-paragraph text-center">
            La farmacia que seleccionaste no tiene despacho a la nueva
            dirección.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => { }}>
            Buscar farmacias nuevamente
          </Button>
          <Button
            className="btn-default--small btn-next btn-stroke"
            variant="primary"
            onClick={closeModal}>
            Usar la dirección anterior
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenIvalidLoc}
        onAfterOpen={afterOpenModalInvalidLoc}
        onRequestClose={closeModalInvalidLoc}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}
        id="gtm-webapp-modal-communes-no-location">
        {![undefined, null, false].includes(onePharmacy) ? (
          <>
            <h4 className="title-modal text-left mb-3">Lo sentimos</h4>
            <p className="txt-paragraph line-16 mb-4">
              Solo despachamos a vecinos de {listCommunes} que{' '}
              <b>actualmente</b> vivan en la comuna.
            </p>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Button
                id="gtm-webapp-error-communes-no-location"
                className="btn-default--small btn-next"
                variant="primary"
                onClick={closeModalInvalidLoc}>
                Oh... ok :(
              </Button>
            </div>
          </>
        ) : (
          <>
          {process.env.REACT_APP_CLIENT_NAME === 'Clínica Alemana'
          ?
          <>
            <h4 className="title-modal text-left mb-3">
              Mil disculpas... aún no tenemos despacho a tu comuna
            </h4>
            <div className="d-flex flex-column justify-content-center mb-3">
              <p className="txt-paragraph mb-2">
                <b>Comunas con despacho: </b>
              </p>
              <p className="txt-paragraph line-16"> {listCommunes}. </p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Button
                className="btn-default--small btn-next"
                variant="primary"
                onClick={closeModalInvalidLoc}>
                Ok
              </Button>
            </div>
          </>
          :
          <>
            <h4 className="title-modal-altern text-center mb-3">
              Lo sentimos...
            </h4>
            <div className="d-flex flex-column justify-content-center mb-3">
              <p className="title-primary--regular text-center mb-2">
                La farmacia seleccionada no tiene despacho para esta comuna.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Button
                className="btn-default--small btn-next"
                variant="primary"
                onClick={closeModalInvalidLoc}>
                Modificar dirección
              </Button>
            </div>
          </>
          }
          </>
        )}
      </Modal>
      {!isLoadingRemoteLocation && (
        <div className="d-flex align-items-center flex-fill content-location">
          {!props.location?.label || requestLocation === true ? (
            <div
              id="gtm-webapp-search-location"
              className="content-location w-100">
              {props.externalLabel && <label className="txt-3--bold m-0 mb-2">Agregar dirección</label>}
              <LocationAutocomplete
                locationInput={locationInput}
                onPress={onPress}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              {[undefined, null, ''].includes(locationInput?.label) && <p className="txt-6--regular-er mt-1 mb-2">{'*Información necesaria para continuar con tu compra.'}</p>}
            </div>
          ) : (
            <div className="w-100">
              {props.externalLabel && <label className="txt-3--bold m-0">Agregar dirección</label>}
              <Input
                label="Calle (Ej: Apoquindo)"
                placeholder="Calle (Ej: Apoquindo)"
                defaultValue={locationInput?.label ? locationInput.label : '...'}
                readOnly
                onClick={() => changeLocation()}
                error={
                  [undefined, null, ''].includes(locationInput?.label) && { message: '*Información necesaria para continuar con tu compra.' }
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const localStyle = {
  btnChangeLocation: {
    container: {},
    label: { color: '#6779e0', cursor: 'pointer', fontWeight: 'bold' },
  },
}

const mapStateToProps = (state) => {
  return {
    location: state.app.location,
    communes: state.delivery.communes,
    globalCommune: state.app.commune,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (logo) => dispatch(setLocation(logo)),
    setCommune: (commune) => dispatch(setCommune(commune)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Location))
