import React, {useRef, useState, useEffect} from 'react'

const TextArea = ({
  data,
  placeholder,
  error,
  name,
  label,
  defaultValue,
  externalLabel,
  id,
  rows,
  cols,
  ...props
}) => {
  const [labelActive, setLabelActive] = useState(false)
  const [currentValue, setCurrentValue] = useState('')

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef?.current?.value.length > 0) {
      setLabelActive(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef?.current?.value])

  useEffect(() => {
    setCurrentValue(defaultValue || '')
    if (!defaultValue) {
      setLabelActive(false)
    } else {
      setLabelActive(true)
    }
  }, [defaultValue])

  useEffect(() => {
    if (![null, undefined].includes(inputRef.current.value)) {
      if (inputRef.current.value.length > 0) {
        setLabelActive(true)
      } else {
        setLabelActive(false)
      }
    } else {
      setLabelActive(false)
    }
  }, [inputRef])
  return (
    <>
      <div className="input-yapp">
        <div className="content-textarea-bg">
          <div className="position-relative">
            <textarea
              id={id}
              name={name}
              ref={inputRef}
              placeholder={externalLabel ? placeholder : ''}
              className={`input-custom-textarea font-regular w-100 ${
                label && (externalLabel ? '' : 'input-label-center')
              }`}
              rows={rows}
              value={currentValue}
              {...props}
              cols={cols}>
              
            </textarea>
          </div>
          {externalLabel
            ? null
            : label && (
                <label
                  className={`label ${labelActive && 'active'}`}
                  htmlFor={id}>
                  {labelActive ? label : placeholder ? placeholder : label}
                </label>
              )}
        </div>
        {error && (
          <p className="txt-6--regular-er mt-1 mb-2">{error.message}</p>
        )}
      </div>
    </>
  )
}

export default TextArea
