import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'
import Modal from 'react-modal'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import FailValidate from '../../assets/img/alert-red.svg'
import { _validateMail, maskEmail } from '../../Utils/tools'
import Input from '../Forms/Input'
import Remover from '../../assets/img/remover.svg'
function ModalRegiteredRut(props) {
  const {
    modalRegisteredRutState,
    setModalRegisteredRutState,
    resetPassword,
    sendEmail,
    email
  } = props
  const history = useHistory()
  const [stage, setStage] = useState(1)
  const [hiddenEmail, setHiddenEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [mailValid, setMailValid] = useState(null)

  function onChangeEmail(email) {
    if (_validateMail(email)) {
      setMailValid(email)
    } else {
      setMailValid(null)
    }
  }

  function handleResetPassword() {
    resetPassword()
    setStage(2)
  }

  function handleReportAccount() {
    setStage(3)
  }

  async function handleSendEmail() {
    setLoading(true)
    if (![undefined, null, ''].includes(mailValid)) {
      let value = await sendEmail(mailValid)
      if (value) {
        setHiddenEmail(maskEmail(mailValid))
        setStage(4)
      } else {
        setStage(5)
      }
    }
    setLoading(false)
  }

  function closeModalRegisteredRut(params) {
    setStage(1)
    // setEmail('')
    setLoading(false)
    setMailValid(false)
    setHiddenEmail('')
    setModalRegisteredRutState(false)
  }

  return (
    <Modal
      isOpen={modalRegisteredRutState}
      onAfterOpen={null}
      onRequestClose={closeModalRegisteredRut}
      className="modal-custom maxw-400"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}>
      
      <a onClick={closeModalRegisteredRut}>
        <img src={Remover} className="icon-remover" alt="Cerrar" />
      </a>
      {stage === 1 && (
        <div>
          <div className='w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-2'>
          <img id={"gtm-webapp-validation-failed-img"} src={FailValidate} alt='' className='img-fail-validate mb-4' />
            
            {email ? (
              <p className="title-primary--xxl  line-26 mb-3 text-center">
                El RUT que ingresaste ya se encuentra asociado al correo <br />{' '}
                {email} asociado a la cuenta Yapp.
              </p>
            ) : (
              <p className="title-widget mb-4 line-26 mb-3 text-center ">
                El Rut que ingresaste ya se encuentra asociado a una cuenta Yapp
              </p>
            )}
          </div>
          <button
            id={"gtm-webapp-rut-already-exist-ok-btn"}
            className="btn-default--small font--16 btn-next mx-auto mb-2"
            onClick={() => history.push('/signin')}>
            Iniciar sesión
          </button>
          {/* <button
            className="btn-default--small font--14 btn-stroke mx-auto"
            onClick={() => handleReportAccount()}>
            Reportar y pedir ayuda
          </button> */}
        </div>
      )}
      {stage === 2 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="modal-icon-send-mail mb-2">
            <i id={"gtm-webapp-rut-already-exist-send-email-success-icon"} className="icon-send-2"></i>
          </span>
          <h4 className="txt-paragraph--light line-16 mb-3 text-center">
            Enviaremos las instrucciones al correo {email} para que
            puedas restablecer tu cuenta.
          </h4>
          <button
            id="gtm-webapp-rut-already-exist-send-email-success"
            className="btn-default btn-next w-100"
            onClick={closeModalRegisteredRut}>
            ¡Entendido!
          </button>
        </div>
      )}
      {stage === 3 && (
        <div>
          {loading ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3 mb-4">
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--normal m-0"
              />
            </div>
          ) : (
            <div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
                <h2 className="title-primary mb-2">
                  ¡Gracias por reportar esta cuenta!
                </h2>
                <p className="txt-paragraph--light line-14 text-center">
                  Hemos iniciado el proceso de revisión. <br />
                  Para continuar necesitamos que nos indiques un correo para
                  contactarte.
                </p>
                <div className="mb-2 w-100 mt-3 mb-4">
                  <Input
                    id="gtm-webapp-rut-already-exist-contact-email-input"
                    error={
                      [undefined, null, ''].includes(mailValid) && { message: '*Correo inválido' }
                    }
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    doChange={(value) => onChangeEmail(value)}
                  />
                </div>
              </div>
              <button
                id="gtm-webapp-rut-already-exist-contact-email-btn"
                className="btn-default--small font--14 btn-next mx-auto"
                disabled={[undefined, null, ''].includes(mailValid)}
                onClick={handleSendEmail}>
                Enviar
              </button>
            </div>
          )}
        </div>
      )}
      {stage === 4 && (
        <div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center w-100 mt-3">
            <span className="modal-icon-send-mail mb-4 d-flex">
              <i className="icon-send-2"></i>
            </span>
            <h4 className="txt-paragraph--light line-14 mb-4 text-center">
              Enviaremos instrucciones al correo {hiddenEmail} para ayudarte con
              tu cuenta.
            </h4>
          </div>
          <button
            id="gtm-webapp-rut-already-exist-intructions-btn"
            className="btn-default--small font--14 btn-next mx-auto"
            onClick={() => {
              closeModalRegisteredRut()
              setStage(1)
            }}>
            ¡Ok!
          </button>
        </div>
      )}
    </Modal>
  )
}

export default ModalRegiteredRut
