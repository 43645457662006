import React, {useEffect} from 'react'
import {Switch, useLocation} from 'react-router-dom'
import {GuardProvider, GuardedRoute} from 'react-router-guards'
import {useDispatch, useSelector} from 'react-redux'
import Home from './Pages/Home'
import Beneficiaries from './Pages/Cia/Beneficiaries'
import Page from './Pages/Page'
import NotFound from './Pages/Errors/NotFound'
import NoAccess from './Pages/Errors/NoAccess'
import AddPrescription from './Pages/Prescription/AddPrescription'
import Location from './Pages/Location'
import ShoppingBag from './Pages/Search/ShoppingBag'
import SearchExam from './Pages/Search/Exam'
import ExamQuotation from './Pages/Search/Exam/Quotation'
import ShoppingBagQuotation from './Pages/Search/ShoppingBag/Quotation'
import LandingByCategory from './Pages/Category/LandingByCategory/LandingByCategory'
import ShoppingBagCheckout from './Pages/Search/ShoppingBag/Checkout'
import PostShopping from './Pages/Search/ShoppingBag/PostShopping'
import PostShoppingSuccess from './Pages/Search/ShoppingBag/PostShoppingSuccess'
import ShoppingBagSuccess from './Pages/Search/ShoppingBag/ShoppingBagSuccess'
import ShoppingBagCheckoutResult from './Pages/Search/ShoppingBag/CheckoutResult'
import Bioequivalent from './Pages/Search/ShoppingBag/Bioequivalent'
import SubscriptionResult from './Pages/Search/ShoppingBag/SubscriptionResult'
import ProductDetail from './Pages/Product/Detail'
import ProductBioequivalent from './Pages/Product/Bioequivalent'
import Legal from './Pages/Legal'
import HomePro from './Pages/Pro/Home'
import SuccessHome from './Pages/Pro/Success'
import Laboratory from './Pages/Pro/Laboratory'
import DrugsInteractions from './Pages/Pro/DrugsInteractions'
import FailDrugsInteraction from './Pages/Pro/FailDrugsInteraction'
import NewDrugs from './Pages/Pro/NewDrugs'
import MedicationSheet from './Pages/Pro/MedicationSheet'
import MedicationSheetProduct from './Pages/Pro/MedicationSheetProduct'
import ProgramMedicationSheet from "./Pages/Pro/ProgramMedicationSheet"
import PriceFinder from './Pages/Pro/PriceFinder'
import PriceFinderResult from './Pages/Pro/PriceFinderResult'
import PriceFinderDetail from './Pages/Pro/PriceFinderDetail'
import PriceFinderProduct from './Pages/Pro/PriceFinderProduct'
import Tracking from './Pages/Tracking'
import ExternalDetail from './Pages/Tracking/ExternalDetail'
import ExternalTracking from './Pages/Tracking/ExternalTracking'
import Programs from './Pages/Pro/Programs'
import ProgramsDetail from './Pages/Pro/ProgramsDetail'
import PDFViewer from './Pages/PDFViewer'
import ChangePassword from './Pages/Login/ChangePassword'
import Agreement from './Pages/Pro/Agreement'
import LogIn from './Pages/Login/LogIn'
import LoginJwt from './Pages/Login/LoginJwt'
import LoginToken from './Pages/Login/LoginToken'
import SignIn from './Pages/Login/SignIn'
import SignInWidget from './Pages/Login/SigninWidget'
import RegisterPro from './Pages/Login/Register'
import NewRegisterPro from './Pages/Login/NewRegisterPro'
import LoginWidget from './Pages/Login/LoginWidget'
import ForgotPassword from './Pages/Login/ForgotPassword'
import RegisterSuccess from './Pages/Account/RegisterSuccess'
import InitialSearch from './Pages/Search/InitialSearch'
import {setHeaderName, setLastPage, setIsPro} from './redux/modules/app'
import Pillbox from './Pages/Pillbox/Pillbox'
import Share from './Pages/Share/Share'
import {headerName} from './Utils/constants'
import Explore from './Pages/Pro/Explore'
import NewAgreement from './Pages/Pro/NewAgreement'
import MyShopping from './Pages/MyShopping/MyShopping'
import MyShoppingDetail from './Pages/MyShopping/MyShoppingDetail'
import MyShoppingTracking from './Pages/MyShopping/MyShoppingTracking'
import MyShoppingSuscriptionEdit from './Pages/MyShopping/MyShoppingSuscriptionEdit'
import LaboratoryDetail from './Pages/Pro/LaboratoryDetail'
import NewShoppingBagCheckout from './Pages/Search/ShoppingBag/NewCheckout'
import Onboarding from './Pages/Onboarding/Onboarding'
import NewAgreementDetail from './Pages/Pro/NewAgreementDetail'
import RegistrerProStandAlone from './Pages/Login/RegisterProStandAlone'
import CreateShoppingCart from './Pages/MyShopping/CreateShoppingCart'
import QuestionsAndAnswers from './Pages/QuestionsAndAnswers'


const requireLogin = async (to, from, next) => {
  // if auth is required  
  if (to.meta.auth) {
    // if user hasn't a valid jwt token and an active session then redirect to /no-access
    if (to.meta.noConditionRequired) {
      next()
    } else if (to.meta.noTokenRequired) {
      if (!to.meta.authState) {
        next.redirect('/no-access')
      } else {
        next()
      }
    } else {
      if (
        [null, undefined].includes(localStorage.getItem('JWT')) ||
        !to.meta.authState
      ) {
        if (process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE') {
          next.redirect('/signin')
        } else {
          next.redirect('/')
        }
      } else {
        next()
      }
    }
  } else {
    if (
      ![null, undefined].includes(localStorage.getItem('JWT')) &&
      to.meta.authState &&
      to.meta.blocked
    ) {
      if (process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE') {
        next.redirect('/signin')
      } else {
        next.redirect('/')
      }
    } else {
      next()
    }
  }
}

const CreateRoutes = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const userInfo = useSelector((state) => state.user.userInfo)
  const authState = useSelector((state) => state.app.sign_in)

  useEffect(() => {
    if (process.env.REACT_APP_PROFILE === 'WIDGET') {
      dispatch(setIsPro(true))
    }
    if (!['/signin', '/register'].includes(location.pathname.toLowerCase())) {
      dispatch(setLastPage(location.pathname))
    }

    if ((!headerName[location.pathname] || location.pathname === '/search') && userInfo?.profile?.first_name) {
      dispatch(
        setHeaderName(
          `Hola, ${
            ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE)
              ? 'Dr/Dra.'
              : ''
          } ${userInfo?.profile?.first_name || ''}`,
        ),
      )
    } else {
      dispatch(setHeaderName(headerName[location.pathname]))
    }
  }, [location.pathname])

  return (
    <GuardProvider
      guards={[requireLogin]}
      loading={() => <div style={{flex: 1}} />}
      error={NotFound}>
      <Switch>
        {process.env.REACT_APP_PROFILE === 'WIDGET' ? (
          <GuardedRoute exact path="/">
            <LoginWidget />
          </GuardedRoute>
        ) : process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE' ? (
          <GuardedRoute exact path="/">
            <Home />
          </GuardedRoute>
        ) : (process.env?.REACT_APP_ON_LOGIN === 'FALSE' &&
            process.env?.REACT_APP_EXTERNAL_LOGIN === 'FALSE' &&
            process.env.REACT_APP_SKIP_LOGIN === 'TRUE') ||
          ['PATIENT'].includes(process.env.REACT_APP_PROFILE) ? (
          <GuardedRoute exact path="/">
            <SignIn />
          </GuardedRoute>
        ) : ![undefined, '', 'FALSE'].includes(
            process.env.REACT_APP_UNIQUE_HASH,
          ) ? (
          <GuardedRoute exact path="/">
            <Home />
          </GuardedRoute>
        ) : [undefined, '', 'NULL'].includes(process.env.REACT_APP_PROFILE) &&
          process.env.REACT_APP_SKIP_LOGIN === 'TRUE' ? (
          <GuardedRoute exact path="/">
            <SignIn />
          </GuardedRoute>
        ) : (
          <GuardedRoute exact path="/">
            <LogIn />
          </GuardedRoute>
        )}
        <GuardedRoute exact path="/page/:hash">
          <Page />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/home/:hash"
          meta={{auth: false, authState: authState, blocked: true}}>
          <Home />
        </GuardedRoute>
        {(process.env?.REACT_APP_ON_LOGIN === 'FALSE' &&
          process.env?.REACT_APP_EXTERNAL_LOGIN === 'FALSE' &&
          process.env.REACT_APP_SKIP_LOGIN === 'TRUE') ||
        ['PATIENT'].includes(process.env.REACT_APP_PROFILE) ? (
          <GuardedRoute
            exact
            path="/home"
            meta={{auth: false, authState: authState, blocked: true}}>
            <SignIn />
          </GuardedRoute>
        ) : (
          <GuardedRoute
            exact
            path="/home"
            meta={{auth: false, authState: authState, blocked: true}}>
            <Home />
          </GuardedRoute>
        )}
        <GuardedRoute
          exact
          path="/login"
          meta={{auth: false, authState: authState, blocked: true}}>
          <LogIn />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/login/:fcmToken"
          meta={{auth: false, blocked: false}}>
          <LoginToken />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/auth"
          meta={{auth: false, blocked: false}}>
          <LoginJwt />
        </GuardedRoute>
        {process.env.REACT_APP_PROFILE === 'INSURANCE' && (
          <GuardedRoute
            exact
            path="/beneficiaries"
            meta={{auth: true, noConditionRequired: true}}>
            <Beneficiaries />
          </GuardedRoute>
        )}
        <GuardedRoute
          exact
          path="/pro/home/:hash"
          meta={{auth: false, authState: authState, blocked: true}}>
          <HomePro />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/home"
          meta={{auth: false, authState: authState, blocked: true}}>
          <HomePro />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/signin"
          meta={{auth: false, authState: authState, blocked: false}}>
          <SignIn />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/signinwidget"
          meta={{auth: false, authState: authState, blocked: false}}>
          <SignInWidget />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/register_validate"
          meta={{auth: false, authState: authState, blocked: false}}>
          <NewRegisterPro />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/register"
          meta={{auth: false, authState: authState, blocked: false}}>
          <RegisterPro />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/register_pro"
          meta={{auth: false, authState: authState, blocked: false}}>
          <RegistrerProStandAlone />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/registersuccess"
          meta={{auth: false, authState: authState, blocked: false}}>
          <RegisterSuccess />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/forgot_password"
          meta={{auth: false, authState: authState, blocked: false}}>
          <ForgotPassword />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/change_password"
          meta={{auth: true, authState: authState, blocked: false}}>
          <ChangePassword />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/success"
          meta={{
            auth: true,
            authState: authState,
            blocked: false,
            noTokenRequired: true,
          }}>
          <SuccessHome />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/laboratory"
          meta={{auth: true, authState: authState, blocked: false}}>
          <Laboratory />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/agreement"
          meta={{auth: true, authState: authState, blocked: false}}>
          <Agreement />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/new_agreement"
          meta={{auth: true, authState: authState, blocked: false}}>
          <NewAgreement />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/new_agreement/detail/:name"
          meta={{auth: true, authState: authState, blocked: false}}>
          <NewAgreementDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/drug_interactions"
          meta={{auth: true, authState: authState, blocked: false}}>
          <DrugsInteractions />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/drug_interactions/fail"
          meta={{auth: true, authState: authState, blocked: false}}>
          <FailDrugsInteraction />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/price_finder"
          meta={{auth: true, authState: authState, blocked: false}}>
          <PriceFinder />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/price_finder_result"
          meta={{auth: true, authState: authState, blocked: false}}>
          <PriceFinderResult />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/price_finder_product"
          meta={{auth: true, authState: authState, blocked: false}}>
          <PriceFinderProduct />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/price_finder_detail/:id"
          meta={{auth: true, authState: authState, blocked: false}}>
          <PriceFinderDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/new_drugs"
          meta={{auth: true, authState: authState, blocked: false}}>
          <NewDrugs />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/onboarding"
          meta={{auth: false, authState: authState, blocked: false}}>
          <Onboarding />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/medication-sheet"
          meta={{auth: true, authState: authState, blocked: false}}>
          <MedicationSheet />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/medication-sheet/:code"
          meta={{auth: true, authState: authState, blocked: false}}>
          <MedicationSheet />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/medication-sheet-product"
          meta={{auth: true, authState: authState, blocked: false}}>
          <MedicationSheetProduct />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/programs/detail/:name"
          meta={{auth: true, authState: authState, blocked: false}}>
          <ProgramsDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/programs-sheet-product/:id"
          meta={{auth: true, authState: authState, blocked: false}}>
          <ProgramMedicationSheet />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/laboratory/detail/:name"
          meta={{auth: true, noConditionRequired: true}}>
          <LaboratoryDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/programs"
          meta={{auth: true, authState: authState, blocked: false}}>
          <Programs />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pro/menu"
          meta={{auth: true, authState: authState, blocked: false}}>
          <Explore />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/search"
          meta={{
            auth: true,
            noConditionRequired: true,
            description:
              process.env.REACT_APP_CLIENT_NAME === 'UC Christus'
                ? 'Red de Salud UC CHRISTUS'
                : 'Inicio',
          }}>
          {process.env.REACT_APP_PROFILE === 'PATIENT' ? (
            <InitialSearch />
          ) : (
            <ShoppingBag />
          )}
        </GuardedRoute>
        <GuardedRoute exact path="/categories/:category_id">
          <LandingByCategory />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pilbox"
          meta={{auth: true, noConditionRequired: true}}>
          <Pillbox />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/share"
          meta={{auth: true, noConditionRequired: true}}>
          <Share />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pilbox"
          meta={{auth: true, noConditionRequired: true}}>
          <Pillbox />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/my_shopping"
          meta={{auth: true, noConditionRequired: true}}>
          <MyShopping />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/my_shopping_detail/:rand"
          meta={{auth: true, noConditionRequired: true}}>
          <MyShoppingDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/my_shopping_tracking/:rand"
          meta={{auth: true, noConditionRequired: true}}>
          <MyShoppingTracking />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/my_shopping_detail/:rand/edit"
          meta={{auth: true, noConditionRequired: true}}>
          <MyShoppingSuscriptionEdit />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/design/pro_register"
          meta={{auth: false, noConditionRequired: true}}>
          <NewRegisterPro />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/search/shopping_bag/:rand"
          meta={{
            auth: true,
            noConditionRequired: true,
            description:
              process.env.REACT_APP_CLIENT_NAME === 'UC Christus'
                ? 'Red de Salud UC CHRISTUS'
                : 'Inicio',
          }}>
          <ShoppingBag />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/search/bioequivalent/:rand"
          meta={{auth: true, noConditionRequired: true}}>
          <Bioequivalent />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/search/exam"
          meta={{auth: true, noConditionRequired: true}}>
          <SearchExam />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/exam/quotation"
          meta={{auth: true, noConditionRequired: true}}>
          <ExamQuotation />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_bag/quotation"
          meta={{auth: true, noConditionRequired: true}}>
          <ShoppingBagQuotation />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_bag/checkout"
          meta={{auth: true, noConditionRequired: true}}>
          {process.env.REACT_APP_PROFILE === 'PATIENT' || process.env.REACT_APP_CLIENT_NAME === 'Sura'
            ? <NewShoppingBagCheckout />
            : <ShoppingBagCheckout />
          }
        </GuardedRoute>
        {/* <GuardedRoute
          exact
          path="/loading_checkout"
          meta={{auth: true, noConditionRequired: true}}>
          <LoadingCheckout />
        </GuardedRoute> */}
        <GuardedRoute
          exact
          path="/shopping_bag/post_shopping"
          meta={{auth: false}}>
          <PostShopping />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_bag/post_shopping/success"
          meta={{auth: false}}>
          <PostShoppingSuccess />
        </GuardedRoute>
        <GuardedRoute exact path="/shopping_bag/success" meta={{auth: false}}>
          <ShoppingBagSuccess />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_bag/subscription"
          meta={{auth: false}}>
          <SubscriptionResult />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_bag/checkout/:hash"
          meta={{auth: true, noConditionRequired: true}}>
          <ShoppingBagCheckoutResult />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/shopping_cart/create"
          meta={{auth: false, blocked: false}}>
          <CreateShoppingCart />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/prescription/add"
          meta={{auth: true, noConditionRequired: true}}>
          <AddPrescription />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/product/detail/:id"
          meta={{auth: true, noConditionRequired: true}}>
          <ProductDetail />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/product/bioequivalent"
          meta={{auth: true, noConditionRequired: true}}>
          <ProductBioequivalent />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/pdf/viewer"
          meta={{auth: true, noConditionRequired: true}}>
          <PDFViewer />
        </GuardedRoute>
        <GuardedRoute exact path="/legal">
          <Legal />
        </GuardedRoute>
        <GuardedRoute exact path="/legal/:content">
          <Legal />
        </GuardedRoute>
        <GuardedRoute
          exact
          path="/location"
          meta={{auth: true, noConditionRequired: true}}>
          <Location />
        </GuardedRoute>
        <GuardedRoute exact path="/tracking/:hash">
          <Tracking />
        </GuardedRoute>
        <GuardedRoute exact path="/tracking/purchase-order/:purchaseOrder">
          <ExternalTracking />
        </GuardedRoute>
        <GuardedRoute exact path="/tracking/subscription/:subscription">
          <ExternalDetail />
        </GuardedRoute>
        <GuardedRoute exact path="/faq">
          <QuestionsAndAnswers />
        </GuardedRoute>
        <GuardedRoute path="/no-access">
          <NoAccess />
        </GuardedRoute>
        <GuardedRoute path="*" component={NotFound} />
        <GuardedRoute
          path="/error"
          meta={{auth: true, noConditionRequired: true}}>
          <NotFound />
        </GuardedRoute>
      </Switch>
    </GuardProvider>
  )
}
export default CreateRoutes
