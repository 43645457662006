import { useState } from 'react'
import Modal from 'react-modal'
import ButtonYapp from '../Buttons/ButtonYapp'
import { addEventNameWithParams } from '../../Utils/utils'


function ModalSuscriptionSurvey(props) {
  const { showSurvey, setShowSurvey, surveySent, subscriptionId } = props

  const [ surveyChecks, setSurveyChecks] = useState([])
  const [ surveyOptions, setSurveyOptions] = useState([
    'Tuve problemas con el despacho',
    'No me gustó el servicio',
    'Terminó mi tratamiento',
    'Cambiaré de medicamento',
    'Me suscribí por error',
    'Me voy de viaje',
    'Encontré un mejor precio',
    'Otro motivo'
  ])

  const onSurveyCheck = (option, check) => {
    let checks = [...surveyChecks]
    let index = checks.indexOf(option)

    if (index !== -1 && !check) {
      checks.splice(index, 1)
    }
    else if (check) {
      checks.push(option)
    }

    setSurveyChecks(checks)
  }

  const sendSurvey = async () => {
    let tags = ''
    for (const item of surveyChecks) {
      switch (item) {
        case 'Tuve problemas con el despacho': tags = tags.concat('delivery_issue;')
          break
        case 'No me gustó el servicio': tags = tags.concat('bad_service;')
          break
        case 'Terminó mi tratamiento': tags = tags.concat('finished;')
          break
        case 'Cambiaré de medicamento': tags = tags.concat('change_med;')
          break
        case 'Me suscribí por error': tags = tags.concat('error;')
          break
        case 'Me voy de viaje': tags = tags.concat('trip;')
          break
        case 'Encontré un mejor precio': tags = tags.concat('better_price;')
          break
        case 'Otro motivo': tags = tags.concat('other;')
          break
        default:
          break
      }
    }

    addEventNameWithParams('click_send_survey', {
      'Subscription ID': subscriptionId,
      survey: tags
    })

    closeSurvey()
    surveySent(true)
  }

  const closeSurvey = () => {
    setSurveyChecks([])
    setShowSurvey(false)
  }

  return (
    <>
      <Modal
        isOpen={ showSurvey }
        onAfterOpen={null}
        onRequestClose={ closeSurvey }
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalSuscriptionHome"
        ariaHideApp={false}>
        <button
          className="btn-circle-close"
          onClick={ closeSurvey }>
          <i className="icon-close"></i>
        </button>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <h3 className="txt-3--light mb-4">
              Cuéntanos el motivo de tu cancelación para{' '}
              <b className="txt-3--bold">
                ayudarnos a mejorar tu experiencia.
              </b>
            </h3>

            { surveyOptions.map((option, index) => (
              <div className="d-flex align-items-center mb-4 mt-2" key={ index }>
                <label
                  className="custom-checkbox-altern align-items-start pl-4 m-0"
                  id={'gtm-webapp-register-check-terms'}>
                  <input
                    id="gtm-webapp-register-checkbox"
                    ref={null}
                    disabled={false}
                    type="checkbox"
                    onChange={(event) => onSurveyCheck(option, event.target.checked)}
                  />
                  <span className="checkmark-altern">
                    <i
                      id="gtm-webapp-register-checkbox-icon"
                      className="icon-checkmark"
                    />
                  </span>
                </label>
                <span className="txt-5--regular-db ml-2 line-16">
                  { option }
                </span>
              </div>
              ))
            }

            <div className="d-flex justify-content-center align-items-center">
              <ButtonYapp
                disabled={ surveyChecks.length === 0 }
                title="Enviar"
                big
                className="mb-3 ml-1 w-100"
                onClick={ sendSurvey }
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalSuscriptionSurvey
