import { useEffect, useState, memo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Geocode from 'react-geocode'
import Modal from 'react-modal'
import AsyncSelect from 'react-select/async'
import useScreenSize from '../../Hooks/useScreenSize'

import { getUserInfo, saveLocation } from '../../Utils/service'
import { setLocation, setCommune, changeInitialLoading } from '../../redux/modules/app'
import { getDeliveryCommunes } from '../../Utils/api'

import biceVidaPet from '../../assets/img/bice-vida-search.png'
import icSearch from '../../assets/img/ic_search.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import icLocation from '../../assets/img/ic_location_blue.svg'
import { addEvent, logEvent } from '../../Utils/utils'

const _ = require('lodash')
const debounce = require('lodash/debounce')
const clientName = process.env.REACT_APP_CLIENT_NAME  || 'Yapp Chile'

function CommunesSearch(props) {
  const dispatch = useDispatch()
  const alert = useAlert()
  const history = useHistory()
  const { width } = useScreenSize()

  const [requestLocation, setRequestLocation] = useState(false)
  const [locationInput, setLocationInput] = useState(null)
  const [localCommune, setLocalCommune] = useState(null)
  const [communeDefault, setCommuneDefault] = useState(null)
  const [listCommunes, setCommunes] = useState(null)

  const [isLoadingRemoteLocation, setLoadingRemoteLocation] = useState(true)
  const [isCurrentLocation, setIsCurrentLocation] = useState(false)
  const [positionWatchId, setPositionWatchId] = useState(null)
  const [currentPosition, setCurrentPosition] = useState(null)
  const [isAppleDevice, setIsAppleDevice] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpenIvalidLoc, setIsOpenIvalidLoc] = useState(false)

  const onePharmacy = useSelector((state) => state.app.one_pharmacy)
  const userExtraInfo = useSelector((state) => state.user.userExtraInfo)

  const [appState, setAppState] = useState({
    loading: false,
    inputValue: '',
    groupedOptions: [],
    communes: [],
  })

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  function closeModalInvalidLoc() {
    setIsOpenIvalidLoc(false)
  }

  useEffect(() => {
    Geocode.setApiKey('AIzaSyDvFBaWaotkeA0xuMSveqQ6VK-XJxAMNSw')
    Geocode.setLanguage('es-419')
    Geocode.setRegion('cl')
    Geocode.enableDebug()

    if (navigator.appVersion.indexOf('Mac') !== -1) {
      setIsAppleDevice(true)
    }
    return () => {
      positionWatchId && navigator.geolocation.clearWatch(positionWatchId)
    }
  }, [])

  useEffect(() => {

    setLoadingRemoteLocation(true)
    getUserInfo()
      .then((resp) => {
        if (props.commune?.name) {
          let commune = props.commune.name
          if (commune) {
            showLocation(false)
            setLoadingRemoteLocation(false)
          }
        } else {
          props.setLocation(null)
          showLocation(true)
          setLoadingRemoteLocation(false)
        }
      })
      .catch((err) => {
        setLoadingRemoteLocation(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (
      currentPosition?.coords?.latitude &&
      currentPosition?.coords?.longitude
    ) {
      getLocationText(
        currentPosition.coords.latitude,
        currentPosition.coords.longitude,
      )
    }
  }, [currentPosition])

  useEffect(() => {
    dispatch(changeInitialLoading(true))
    getDeliveryCommunes()
      .then((el) => {
        if (el?.data) {
          if (el?.data?.length === 1) {
            setCommuneDefault(el?.data[0])
          }

          let communeFilter = []
          if (userExtraInfo?.patient?.address?.commune_id) {
            communeFilter = el.data.filter(
              (el) => el.id === userExtraInfo.patient?.address?.commune_id
            )
          }

          if (userExtraInfo?.patient?.address?.commune) {
            communeFilter = el.data.filter(
              (el) => el.name.toLowerCase() === userExtraInfo.patient?.address?.commune.trim().toLowerCase()
            )
          }

          setCommunes(el?.data.map((el) => el.name).join(', '))

          let comm = el?.data.filter((commune) => commune.name.toLowerCase())
          if (comm.length > 0) {
            let group = comm.map((c) => {
              return { value: c.id, label: c.name, data: c }
            })
            let options = []
            options.push({
              label: 'Comunas',
              options: group,
            })
            setAppState({ groupedOptions: options })
          }
          if (communeFilter?.length > 0) {
            let commune = communeFilter[0]
            commune = { name: commune.name, id: commune.id }
            logEvent('Location Selected', { Location: commune.name })
            dispatch(setCommune(commune))
            setLocalCommune(commune)
            showLocation(false)
            if (typeof props?.nextAction !== 'undefined') props?.nextAction()
          }
        }
      })
      .catch((err) => {
        console.log(err)
        return history.push('/error')
      }).finally(() => {
        dispatch(changeInitialLoading(false))
      })
  }, [])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function afterOpenModalInvalidLoc() {
    // references are now sync'd and can be accessed.
  }

  const showLocation = (status) => {
    setRequestLocation(status)
    props?.setChangingCommune(status);
  }

  const setLocationComponent = (
    label,
    lat,
    lng,
    locality,
    communeId,
    nro = null,
  ) => {
    let item = {
      label: label,
      lat: lat,
      lng: lng,
      locality: locality,
      commune_id: communeId,
      nro: nro,
    }

    props.setLocation(item) // action
    saveLocation(item) // service
    setLocationInput(item) // localState
  }

  function onPress(element, item) {
    let communeFilter = props.communes.filter(
      (el) => el.name === element?.label,
    )
    if (communeFilter?.length === 0) {
      showLocation(false)
    } else {
      logEvent('Location Selected', { Location: element.data.name })
      dispatch(setCommune(element.data))
      setLocalCommune(element.data)
      showLocation(false)
      if (typeof props?.nextAction !== 'undefined') props?.nextAction()
    }
  }

  function getLocationText(latitude, longitude) {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const geoResp = _.get(response.results, '[0].address_components')
        let locality = null
        for (const key in geoResp) {
          if (geoResp.hasOwnProperty(key)) {
            const element = geoResp[key]
            let type = _.get(element, 'types')
            if (_.includes(type, 'administrative_area_level_3')) {
              locality = element.long_name
            }
          }
        }

        let item = {
          label: _.get(response.results, '[0].formatted_address'),
          lat: latitude,
          lng: longitude,
          locality: locality,
        }

        let communeFilter = props.communes?.filter((el) => el.name === locality)
        if (communeFilter?.length === 0) {
          showLocation(false)
          alert.show(
            'La ubicación ingresada no esta entre las comunas que despachamos.',
          )
        } else {
          setLocationComponent(
            item.label,
            item.lat,
            item.lng,
            item.locality,
            _.get(communeFilter, '[0].id'),
          )
          showLocation(false)
        }
      },
      (error) => {
        console.error(error)
      },
    )
  }

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      function (resp) {
        setCurrentPosition(resp)
      },
      (err) => {
        console.log('Err', err)
        alert.error(
          'Si no logramos obtener tu Ubicación revisa los permisos en tus preferencias de seguridad.',
        )
        if (isAppleDevice) {
          watchCurrentLocation()
        } else {
          setIsCurrentLocation(false)
        }
      },
      { timeout: 3000 },
    )
  }

  function watchCurrentLocation() {
    let id = navigator.geolocation.watchPosition(
      (resp) => {
        setCurrentPosition(resp)
      },
      (err) => {
        console.log('Err', err)
      },
    )
    setPositionWatchId(id)
  }

  async function onChangeInput(event) {
    let tmp = !isCurrentLocation
    setIsCurrentLocation(tmp)

    if (tmp) {
      if (navigator.geolocation) {
        getCurrentLocation()
      } else {
        console.log('Sin geo')
      }
    } else {
      positionWatchId && navigator.geolocation.clearWatch(positionWatchId)
    }
  }

  function openModalInvalidLoc() {
    setIsOpenIvalidLoc(true)
  }

  function onFocusInput(e) {
    addEvent('location_search_focus')
  }

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const promiseOptions = async (inputValue, callback) => {
    if (inputValue.length > 1 && props.communes?.length >= 1) {
      let comm = props.communes.filter((commune) =>
        commune.name.toLowerCase().includes(inputValue.toLowerCase()),
      )
      if (comm.length > 0) {
        let group = comm.map((c) => {
          return { value: c.id, label: c.name, data: c }
        })
        let options = []
        options.push({
          label: 'Comunas',
          options: group,
        })
        setAppState({ groupedOptions: options })
        callback(options)
      }
    } else {
      setAppState({ groupedOptions: [] })
    }
  }

  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  const searchProductsStyle = {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      height: '38px',
      boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
      paddingLeft: '44px',
      border: '1px solid #E9E6FE;',
      '&:hover': {
        border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
      },
      ':before': {
        backgroundImage: `url(${icSearch})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: '21px',
        width: '21px',
        position: 'absolute',
        left: '12px',
        overflow: 'visible',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '10px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
          backgroundImage: `url(${icEnter})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '22px',
          backgroundPosition: 'right',
          content: '"Seleccionar"',
          color: '#828282',
          fontSize: '8px',
          display: 'flex',
          flexColumn: 'row',
          alignItems: 'center',
          marginRight: 8,
          height: '15px',
          width: '74px',
          position: 'absolute',
          right: '0',
          overflow: 'visible',
        }
        : {},
    }),
  }

  const searchProductsStyleSecondary = {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      height: '48px',
      boxShadow: '0',
      paddingLeft: '16px',
      border: '1px solid #DDE0EC;',
      '&:hover': {
        border: isFocused ? '1px solid #DDE0EC;' : '1px solid #DDE0EC;',
      },

    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A9ABBB',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '10px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
          backgroundImage: `url(${icEnter})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '22px',
          backgroundPosition: 'right',
          content: '"Seleccionar"',
          color: '#828282',
          fontSize: '8px',
          display: 'flex',
          flexColumn: 'row',
          alignItems: 'center',
          marginRight: 8,
          height: '15px',
          width: '74px',
          position: 'absolute',
          right: '0',
          overflow: 'visible',
        }
        : {},
    }),
  }

  const newStyle = {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '12px',
      height: '48px',
      boxShadow: '0',
      paddingLeft: '16px',
      zIndex: 1052,
      border: props.themeColor ? `1px solid var(${props.themeColor})` : '1px solid #DDE0EC;',
      '&:hover': {
        border: isFocused
          ? props.themeColor ? `1px solid var(${props.themeColor})` : '1px solid #DDE0EC;'
          : props.themeColor ? `1px solid var(${props.themeColor})` : '1px solid #DDE0EC;',
      },

    }),
    singleValue: (provided) => ({
      ...provided,
      color: props.themeColor ? `var(${props.themeColor})` : '#828282',
      fontSize: '12px',
      paddingLeft: '16px',
      '&::before': {
        content: '"\\e928"',
        fontFamily: 'icomoon !important',
        width: '10px',
        height: '15px',
        color: 'var(--theme-color)',
        position: 'absolute',
        top: '0',
        left: '0'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
      paddingTop: '20px'
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '12px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      color: isSelected ? 'var(--color-primary-ds)' : '#828282',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
          backgroundImage: `url(${icEnter})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '22px',
          backgroundPosition: 'right',
          content: '"Seleccionar"',
          color: '#828282',
          fontSize: '8px',
          display: 'flex',
          flexColumn: 'row',
          alignItems: 'center',
          marginRight: 8,
          height: '15px',
          width: '74px',
          position: 'absolute',
          right: '0',
          overflow: 'visible',
        }
        : {},
    }),
  }

  return (
    <div className="location-cotent-wrap">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}>
        <h4 className="title-modal-altern mb-3">Lo sentimos...</h4>
        <div className="d-flex flex-column justify-content-center mb-3">
          <p className="txt-paragraph text-center">
            La farmacia que seleccionaste no tiene despacho a la nueva
            dirección.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Button
            className="btn-default--small btn-next mb-3"
            variant="primary"
            onClick={() => { }}>
            Buscar farmacias nuevamente
          </Button>
          <Button
            className="btn-default--small btn-next btn-stroke"
            variant="primary"
            onClick={closeModal}>
            Usar la dirección anterior
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenIvalidLoc}
        onAfterOpen={afterOpenModalInvalidLoc}
        onRequestClose={closeModalInvalidLoc}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal"
        ariaHideApp={false}>
        {![undefined, null, false].includes(onePharmacy) ? (
          <>
            <h4 className="title-modal text-left mb-3">Lo sentimos</h4>
            <p className="txt-paragraph line-16">
              Solo despachamos a vecinos de {listCommunes} que{' '}
              <b>actualmente</b> vivan en la comuna.
            </p>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Button
                className="btn-default--small btn-next"
                variant="primary"
                onClick={closeModalInvalidLoc}>
                Oh... ok :(
              </Button>
            </div>
          </>
        ) : (
          <>
            <h4 className="title-modal text-left mb-3">
              Mil disculpas... aún no tenemos despacho a tu comuna
            </h4>
            <div className="d-flex flex-column justify-content-center mb-3">
              <p className="txt-paragraph mb-2">
                <b>Comunas con despacho: </b>
              </p>
              <p className="txt-paragraph line-16"> {listCommunes}. </p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Button
                className="btn-default--small btn-next"
                variant="primary"
                onClick={closeModalInvalidLoc}>
                Ok
              </Button>
            </div>
          </>
        )}
      </Modal>

      {!isLoadingRemoteLocation && (
        <div className="content-location">
          {props.newStyle && <>
            <div
              id="gtm-webapp-search-location"
              className="content-location w-100">
              <AsyncSelect
                className="search-product"
                id="gtm-webapp-search-products"
                isClearable
                cacheOptions
                onFocus={onFocusInput}
                onChange={onPress}
                onMenuClose={() => showLocation(false)}
                noOptionsMessage={() => 'Sin resultados...'}
                placeholder="Ingresa tu comuna"
                formatGroupLabel={formatGroupLabel}
                defaultOptions={appState.groupedOptions}
                loadOptions={loadSuggestions}
                styles={newStyle}
                defaultValue={props.commune?.name ? { label: props.commune?.name } : null}
              />
            </div>
          </>}

          {!props.newStyle && <>
          {!props.commune?.name || requestLocation === true ? (
            <>
              { clientName === 'Bice Vida' && (
                <div style={{marginBottom: width <= 768 ? (width <= 400 ? '-65px' : '-45px') : '-30px'}}
                  class={`d-flex ${width <= 768 ? 'justify-content-end' : 'justify-content-center'}`}>
                  <img className='' src={biceVidaPet} style={width <= 768 ? {maxWidth: '100px'} : {}} alt={'Bice Vida'} />
                </div>
              )}

              {!props.isNotShow &&
                <p className={`txt-4--bold-db mb-2 ${width <= 768 ? 'w-75' : 'w-100'}`}>
                  { clientName === 'Bice Vida'
                    ? 'Elige la comuna en la cual deseas cotizar y recibir con despacho a domicilio'
                    : 'Ingresa la comuna en donde recibirás tu medicamento'
                  }
                </p>
              }
              <div
                id="gtm-webapp-search-location"
                className="content-location w-100">
                <AsyncSelect
                  className="search-product"
                  id="gtm-webapp-search-products"
                  isClearable
                  cacheOptions
                  onFocus={onFocusInput}
                  onChange={onPress}
                  onMenuClose={() => showLocation(false)}
                  noOptionsMessage={() => 'Sin resultados...'}
                  placeholder="Ingresa tu comuna"
                  formatGroupLabel={formatGroupLabel}
                  defaultOptions={appState.groupedOptions}
                  loadOptions={loadSuggestions}
                  styles={
                    props.styleSecondary
                      ? searchProductsStyleSecondary
                      : searchProductsStyle
                  }
                />
              </div>
            </>
          ) : (
            <div className="m-0 p-0 content-location-text">
              <p className="txt-3--bold-resp mb-2">Buscando para</p>
              <div className="d-flex flex-row align-items-center w-100">
                <i className="icon-location color-theme"></i>
                <span
                  className="txt-primary link pl-2 w-100 text-truncate"
                  onClick={() => showLocation(true)}>
                  <span className="txt-paragraph color-theme text-truncate">
                    {props.commune?.name ? props.commune.name : '...'}
                  </span>
                </span>
              </div>
            </div>
          )}
          </>}
        </div>
      )}
      <>
        <div className="d-flex align-items-center find-location">
          {!isAppleDevice && false && (
            <>
              <div className="content-responsive">
                <Form.Check
                  type="switch"
                  id="gtm-webapp-search-location-switch"
                  className="switch-default"
                  checked={isCurrentLocation}
                  onChange={(event) => onChangeInput(event)}
                />
              </div>
              <div className="mw-100">
                <p className="txt-paragraph--small text-color--secondary pl-2">
                  Buscar en mi ubicación actual{' '}
                </p>
              </div>
            </>
          )}
        </div>
      </>
    </div>
  )
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url(${icSearch})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 22,
    width: 22,
  },
})

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '1em',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const mapStateToProps = (state) => {
  return {
    location: state.app.location,
    commune: state.app.commune,
    communes: state.delivery.communes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (logo) => dispatch(setLocation(logo)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(CommunesSearch))
