import React from 'react'

import './CardProduct.css'

function CardProduct(props) {
  return (
    <>
      <div
        id={props.id}
        className={`card-product-simple c-pointer ${props.className}`}
        onClick={props.doAction}>
        <div className={`d-flex align-items-center ${props?.hideArrow ? 'maxw-90p' : ''}`}>
          {props.children}
        </div>
      </div>
    </>
  )
}

export default CardProduct
