import React, {useState, useEffect} from 'react'

import {changeLogo, setFormData} from '../../redux/modules/app'
import {connect, useDispatch, useSelector} from 'react-redux'
import ButtonFalseInput from '../../Components/Buttons/ButtonFalseInput'
import ModalNoValidInsurance from '../../Components/Modals/ModalNoValidInsurance'
import {useHistory} from 'react-router-dom'
import {getBeneficiaries} from '../../Utils/api'
import LoadingSura from '../../Components/Loading/LoadingSura'
import LoadingScreen from 'react-loading-screen'
import Modal from 'react-modal'
import {setCopayUserData} from '../../redux/modules/user'
import {encrypt} from '../../Utils/utils'
import * as Sentry from '@sentry/react'
import {setContext} from '@sentry/react'
import {
  _formatRut,
  _validateMail,
  _validateRut,
  _getDv,
  _capitalize,
} from '../../Utils/tools'
import {logEvent} from '../../Utils/utils'
import {setDeliveryCode} from '../../redux/modules/delivery'
import TypeSidetab from '../../Components/TypeForm/TypeSidetab'

const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes'

function Beneficiaries(props) {
  const dispatch = useDispatch()
  const [modalNoValidInsurance, setModalNoValidInsurance] = useState(false)
  const [benefit, setBenefit] = useState(null)
  const history = useHistory()
  const [showErrorLoginInsurance, setShowErrorLoginInsurance] = useState(false)
  const [errorLoginInsurance, setErrorLoginInsurance] = useState('')
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  const [loading, setLoading] = useState(false)
  const deliveryCode = useSelector((state) => state?.delivery?.delivery_code)
  const freeShipping = useSelector((state) => state?.delivery?.free_shipping_code)

  useEffect(async () => {
    let userData = sessionStorage.getItem('USER_DATA');
    if (userData) {
      userData = JSON.parse(userData);
      showBeneficiaryList(userData);
    }
    else {
      setErrorLoginInsurance({ message: `No hemos podido validarte como usuario`});
      setShowErrorLoginInsurance(true);
    }
  }, []);

  async function showBeneficiaryList(userData) {
    setLoading(true);
    let beneficiaryList = await getBeneficiaryList(userData.identity);

    if (beneficiaryList.length > 0) {
      if (beneficiaryList[0].active === 1) {
        if (freeShipping?.code) {
          dispatch(setDeliveryCode(freeShipping));
        }

        let currentUser = beneficiaryList.filter((u) => u.identity === userData.identity);
        setClaimer(currentUser)

        logEvent('Session Started', {
          'Validated Insurance Policy': 'TRUE',
          'Insurance Type': 'Group'
        });
      }
      else {
        showExpiredInsurance();
      }
    }
    else {
      showIdentityNotFound(deliveryCode?.code);
    }
    setLoading(false);
  }

  async function getBeneficiaryList(claimerIdentity) {
    let beneficiaryList = [];
    try {
      let result = await getBeneficiaries(claimerIdentity);
      if (result?.data?.listBeneficiaries?.length > 0) {
        beneficiaryList = [...result.data.listBeneficiaries];
      }
    }
    catch (error) {
      setContext(`Error GetBeneficiaryList for ${claimerIdentity}`);
      Sentry.captureMessage(`GetBeneficiaryList`, error);
    }
    return beneficiaryList;
  }

  function setClaimer(userData, goBack = false) {
    if (goBack) {
      history.push('/')
    } else {
      if (userData) {
        let encryptedData = encrypt(userData)

        let identity = userData?.identity
        let titular_identity = userData?.holder_identity || userData?.identity_titular

        props.setFormData({
          ...props.formData,
          full_name: {
            ...props.formData.full_name,
            value: _capitalize(userData?.name || ''),
          },
          rut: {
            ...props.formData.rut,
            value: ![undefined, null, '', ' '].includes(identity)
              ? _validateRut(identity)
                ? _formatRut(identity)
                : null
              : null,
          },
          titular_identity: {
            ...props.formData.titular_identity,
            value: ![undefined, null, '', ' '].includes(titular_identity)
              ? _validateRut(titular_identity)
                ? _formatRut(titular_identity)
                : null
              : null,
          },
          email: {
            ...props.formData.email,
            value: ![undefined, null, '', ' '].includes(userData?.email)
              ? _validateMail(userData?.email)
                ? userData?.email?.toLowerCase()
                : null
              : null,
          },
        })

        dispatch(setCopayUserData(encryptedData))
      }
      history.push('/search')
    }
  }

  function getBeneficiariesByType(list, hasHolders) {
    if (hasHolders) {
      return list.filter((c) => c.relationship?.toUpperCase() === 'TITULAR'
        || c.relationship?.toUpperCase() === 'HOLDER'
      );
    }
    else {
      return list.filter((c) => c.relationship?.toUpperCase() !== 'TITULAR'
        && c.relationship?.toUpperCase() !== 'HOLDER'
     );
    }
  }

  function showExpiredInsurance() {
    setErrorLoginInsurance({
      message: `Tu póliza no se encuentra vigente. Puedes contactarte con tu compañía de seguro para mayor información.`,
      goBack: true,
    })
    setShowErrorLoginInsurance(true);
  }

  function showIdentityNotFound(deliveryCode) {
    if (deliveryCode) {
      logEvent('Session Started', {
        'Validated Insurance Policy': 'FALSE',
        'Insurance Type': 'External'
      })
      setErrorLoginInsurance({
        message:
          ['Sura', 'Vida Security'].includes(clientName)?
             'No podemos validarte como asegurado ' + clientName.toUpperCase() + ' en estos momentos. ¿Deseas continuar sin validación?'
            : 'Tu rut no se encuentra registrado. Puedes contactarte con tu compañía de seguro para mayor información.',
        goBack: !['Sura', 'Vida Security'].includes(clientName),
      })
      setShowErrorLoginInsurance(true);
    }
    else {
      logEvent('Session Started', {
        'Validated Insurance Policy': 'TRUE',
        'Insurance Type': 'General'
      })
      setClaimer()
    }
  }

  return (
    <>
      {loading &&
        (clientName !== 'Sura' ? (
          <LoadingScreen
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="var(--color-secondary)"
            textColor="var(--color-secondary)"
            text="Buscando información"></LoadingScreen>
        ) : (
          <LoadingSura />
        ))}
      {!showErrorLoginInsurance && (beneficiaryList.length > 0 || holderList.length > 0) && (
        <>
          {clientName !== 'UC Christus' && (
            <div className="w-100 content-responsive mx-auto d-flex justify-content-between py-3 px-3 px-md-0">
              <div
                className="breadcrumbs c-pointer"
                onClick={() => {
                  history.push('/')
                }}>
                <a className="d-flex align-items-center">
                  <i className="icon-chevron-right icon-reverse"></i>
                </a>
                <span className="title-primary--regular line-14">Volver</span>
              </div>
            </div>
          )}
          <div className="flex-grow-1 pb-md-2">
            <div className="content-responsive mx-auto mb-5">
              <div className="mx-auto px-3">
                <p className="txt-3--bold-db mb-4">
                  Selecciona para quien necesitas comprar medicamentos
                </p>

                <p className="txt-5--bold-db mb-2">Titular</p>

                { holderList.map((holder, index) => (
                  <ButtonFalseInput text={holder.name} onClick={() => setClaimer(holder)} key={`h-${index}`} />
                ))}

                { beneficiaryList.length > 0 && (
                  <p className="txt-5--bold-db mb-2 mt-4">Beneficiarios</p>
                )}

                { beneficiaryList.map((beneficiary, index) => (
                  <ButtonFalseInput text={beneficiary.name} onClick={() => setClaimer(beneficiary)} key={`b-${index}`} />
                ))}

              </div>
            </div>
            <ModalNoValidInsurance
              modalNoValidInsurance={modalNoValidInsurance}
              setModalNoValidInsurance={setModalNoValidInsurance}
            />
          </div>
        </>
      )}

      {clientName === 'Vida Security' && (
        <TypeSidetab 
          id='jjdc5aSF'
          buttonColor={'#282E55'}          
          buttonText={'Sugerencias'}
        />
      )}
      {clientName === 'Sura' && (
        <TypeSidetab 
          id='wea0XraP'
          buttonColor={'#0033A0'}          
          buttonText={'Sugerencias!'}
        />
      )}

      <Modal
        isOpen={showErrorLoginInsurance}
        onAfterOpen={null}
        onRequestClose={() => setShowErrorLoginInsurance(false)}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="title-primary--3xl-regular mb-3 text-center">
            {errorLoginInsurance.message}
          </h2>
          <div className="mx-auto">
            <button
              className="btn-default w-100 btn-next mt-3"
              onClick={() => {
                setShowErrorLoginInsurance(false)
                if (errorLoginInsurance.goBack)
                  setClaimer(null, errorLoginInsurance.goBack)
                else
                  window.location.replace("https://web.yapp.cl/")
              }}>
              {errorLoginInsurance.goBack ? 'Entendido' : 'Continuar'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: state.app?.formData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
    setFormData: (formData) => dispatch(setFormData(formData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries)
