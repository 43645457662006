import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import ProductSearch from '../../Components/ProductSearch/index'
import {ENUM_MENU} from '../../Utils/constants'
import {drugInteraction} from '../../Utils/api'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'
import {changeInitialLoading} from '../../redux/modules/app'
import {useDispatch, useSelector} from 'react-redux'
import parse from 'html-react-parser'
import FailDrugsInteraction from './FailDrugsInteraction'
import '../../styles/fix-vidal.css'

const currentYear = new Date().getFullYear()

function DrugsInteractions() {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.app.initial_loading)
  const [productList, setProductList] = useState([])
  const [gender, setGender] = useState(null)
  const [weight, setWeight] = useState(null)
  const [age, setAge] = useState(null)
  const [height, setHeight] = useState(null)
  const [pregnancy, setPregnancy] = useState(false)
  const [lactancy, setLactancy] = useState(false)
  const [kidneyFailure, setKidneyFailure] = useState(false)
  const [allergie, setAllergie] = useState(false)
  const [pathologie, setPathologie] = useState(false)
  const [html, setHtml] = useState(null)
  const history = useHistory()
  const pregnancyRef = useRef()
  const lactancyRef = useRef()
  const kidneyRef = useRef()
  const allergieRef = useRef()
  const pathologieRef = useRef()
  const genderRef = useRef()
  const weightRef = useRef()
  const heightRef = useRef()
  const birthDayRef = useRef()

  const fromYappApp = localStorage.getItem('fromYappApp') === 'true'
  const goBack = () => {
    history.goBack()
  }
  const scrollTo = useRef(null)
  const runScroll = () => scrollTo.current.scrollIntoView()

  useEffect(() => {
    setHtml(null)
  }, [productList])

  const dataValidation = async () => {
    setHtml(null)
    dispatch(changeInitialLoading(true))
    logEvent('Drug Interaction Checked', {
      'Product Quantity': productList.length,
    })
    const info = {
      products: productList.map((p) => {
        logEvent('Drug Interaction Product Checked', {
          'Product Id': p.product_id,
          'Product Name': p.product_name,
          'Lab Name': p.laboratory_name,
          // TODO:
          // Active Principle Id
          // Active Principle Name
          // Category Id
          // Category Name
          // Lab Id
        })
        return p.product_id
      }),
      gender: gender,
      weight: weight,
      birth_day: age
        ? new Date(`${currentYear - age}-06-25T00:00:00+00:00`)
        : null,
      height: height,
      pregnancy: pregnancy,
      lactancy: lactancy,
      kidney: kidneyFailure,
      allergie: allergie,
      pathologies: pathologie ? 'ALL' : null,
    }
    try {
      const response = await drugInteraction(info)
      setHtml(response)
    } catch (error) {
      console.log(error)
    }
    dispatch(changeInitialLoading(false))
    dataLayerFn()
    runScroll()
  }

  function dataLayerFn() {
    let products = productList.map((p) => {
      return {product_id: p.product_id, product_name: p.product_name}
    })
    addEventNameWithParams('analysis_drug_interactions', {
      interaction_gender: gender,
      interaction_height: height,
      interaction_weight: weight,
      interaction_age: age,
      interaction_lactancy: lactancy,
      products: products,
    })
  }

  const removeFromProductList = (index) => {
    let array = [...productList]
    array.splice(index, 1)
    setProductList(array)
  }

  const onClickSearch = (product) => {
    if (product?.value) {
      const productId = productList.find((p) => p.product_id === product.value)
      addEventNameWithParams('drugs_interaction_selected_product', {
        product_id: product?.value,
        product_name: product.label,
      })

      if (!productId) {
        setProductList([...productList, product.data])
      } else {
        console.log('Error value')
      }
    }
  }

  const genderSelection = (value) => {
    setGender(value)
    if (value === 'MALE' && pregnancy) pregnancyRef.current.click()
    if (value === 'MALE' && lactancy) lactancyRef.current.click()
  }

  const onClear = () => {
    setGender(null)
    setWeight(null)
    setAge(null)
    setHeight(null)
    genderRef.current.value = ''
    weightRef.current.value = ''
    heightRef.current.value = ''
    birthDayRef.current.value = ''
    if (lactancyRef.current.checked) {
      lactancyRef.current.checked = false
    }
    setHtml(null)
  }

  return (
    <>
      {!fromYappApp && (
        <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span
              className="txt-4--regular-pr"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>
      )}
      {false ? (
        <FailDrugsInteraction />
      ) : (
        <div className="flex-grow-1 content-responsive py-2">
          <p className="title-quotation mb-3">
          Obtén información sobre interacciones entre medicamentos.
          </p>
          <h2 className="txt-3--bold mb-2 ">Medicamentos a interactuar </h2>
          <p className="txt-3--regular mb-3">Agrega mínimo 2 medicamentos </p>
          <div className="content-drug-interacion-search">
            <ProductSearch
              styleSecondary={true}
              showValue={true}
              request_from={ENUM_MENU.drug_interactions}
              onClickSearch={onClickSearch}
              isNotShow={true}
              placeholder={'Busca por marca o principio activo'}
              vidal={1}
              clearInput={true}
            />
          </div>
          {productList.map((product, index) => (
            <span key={index} className="tag-result mr-2 mb-2">
              {product.product_name}
              <i
                className="icon-close c-pointer color-lilac ml-2"
                onClick={() => removeFromProductList(index)}></i>
            </span>
          ))}
          <button
            id="gtm-webapp-drugs-interactive-btn-analysis"
            className="btn-default btn-next mx-auto mt-2 mb-4"
            disabled={!(productList.length > 1) || loading}
            onClick={() => dataValidation()}>
            Ver análisis de interacciones
          </button>

          {html && (
            <div ref={scrollTo} className="content-responsive mx-auto">
              {parse(html)}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DrugsInteractions
