import {getDeliveryCommunes} from '../../Utils/api'

const SET_COMMUNES = 'SET_COMMUNES'
const SET_DELIVERY_CODE = 'SET_DELIVERY_CODE'
const SET_FREE_SHIPPING = 'SET_FREE_SHIPPING'

const initialState = {
  communes: [],
  delivery_code: null,
  free_shipping_code: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMMUNES:
      return {
        ...state,
        communes: action.communes,
      }
    case SET_DELIVERY_CODE:
      return {
        ...state,
        delivery_code: action.delivery_code,
      }
    case SET_FREE_SHIPPING:
      return {
        ...state,
        free_shipping_code: action.free_shipping_code,
      }
    default:
      return state
  }
}

export function setCommunes(communes) {
  return {
    type: SET_COMMUNES,
    communes,
  }
}

export function setDeliveryCode(delivery_code) {
  return {
    type: SET_DELIVERY_CODE,
    delivery_code,
  }
}

export function setFreeShipping(free_shipping_code) {
  return {
    type: SET_FREE_SHIPPING,
    free_shipping_code,
  }
}

export function fetchCommunes() {
  return async (dispatch) => {
    getDeliveryCommunes()
      .then((el) => {
        dispatch(setCommunes(el?.data ? el?.data : []))
      })
      .catch(console.log)
  }
}
