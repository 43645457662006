import {useEffect, useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {_formatPrice} from '../../../Utils/tools'
import {getExamQuotation} from '../../../Utils/api'
import {getExamsCart} from '../../../Utils/service'
import {changeLogo, showLogo, setThemeColor} from '../../../redux/modules/app'
import {addEventNameWithParams, logEvent} from '../../../Utils/utils'
import Modal from 'react-modal'
import loadingYapp from '../../../assets/img/yapp_loading.gif'
import icErrorProducts from '../../../assets/img/error_products.svg'
import ExamCard from '../../../Components/Card/ExamCard'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'

import ModalLogin from '../../../Components/Modals/ModalLogin'
import SubscriptionCard from '../../../Components/Card/SubscriptionCard'

const _ = require('lodash')

function ExamQuotation(props) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [appQuotationState, setAppQuotationState] = useState({data: null})
  const [loading, setLoading] = useState(true)
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [isDesktopWidth, setIsDesktopWidth] = useState(null)
  const [modalErrorExamIsOpen, setModalErrorExamIsOpen] = useState(false)
  const [bioequivalentList, setBioequivalentList] = useState([])
  const [modalLoginVisible, setModalLoginVisible] = useState(false)
  const [subscriptionInfo, setSubscriptionInfo] = useState(null)

  const userInfo = useSelector((state) => state.user.userInfo)
  const locationStorage = useSelector((state) => state.app.commune)
  const onePharmacy = useSelector((state) => state.app.one_pharmacy)
  const themeColor = useSelector((state) => state.app.themeColor)
  const textSubscriptionSection = useSelector(
    (state) => state.app.text_subscription_section,
  )

  const patientProfile = process.env.REACT_APP_PROFILE === 'PATIENT'

  useEffect(() => {
    if (props.disabledThemeCheckout === true) {
      dispatch(changeLogo(props.clientLogo))
      dispatch(showLogo(true))
      dispatch(setThemeColor(props.themeColor))
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let arrBag = await getExamsCart()

    if (!arrBag) history.replace('/search/exam')

    searchData(arrBag)
  }, [])

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [isDesktopWidth])

  function goBack() {
    history.push('/search/exam')
  }

  const handleWindowSizeChange = () => {
    setIsDesktopWidth(window.innerWidth > 1024)
  }

  function searchData(arrBag) {
    let examIds = arrBag.map((el) => {
      return {id: el.code_fonasa, result_id: el?.resultSearch?.result_id}
    })

    getExamQuotation(examIds)
      .then((resp) => {
        if (!resp) {
          return history.push('/error')
        }

        try {
          arrBag.map((c) => {
            let result = resp?.data?.find((d) =>
              d.exams?.find((e) => e.code === c.code_fonasa),
            )
            logEvent('Medical Exam Checked', {
              'Category Name': c.category_name,
              'Type Exam': c.type_exam,
              'Code Fonasa': c.code_fonasa,
              'Exam Name': c.exam_name,
              Result: [undefined, null, false].includes(result)
                ? 'False'
                : 'True',
            })
          })
        } catch (ignore) {
        }
        for (let index = 0; index < resp.data?.length; index++) {
          resp.data[index]['total'] = resp.data.total_particular
          resp.data[index]['quantity_results'] = resp.data[index].exams.length
        }

        if (resp?.data) {
          orderByFilterQuotation(resp?.data)
        } else {
          setModalErrorExamIsOpen(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setModalErrorExamIsOpen(true)
      })
  }

  function orderByFilterQuotation(data) {
    let presentKeys = []
    let newArr = [] //=_.chain(data).groupBy("quantity_results").value()
    newArr.push(data)

    for (const key in newArr) {
      if (Object.hasOwnProperty.call(newArr, key)) {
        const element = newArr[key]
        presentKeys.push(Number(key))
        newArr[key] = _.orderBy(
          element,
          ['quantity_results', 'total'],
          ['desc', 'asc'],
        )
      }
    }

    presentKeys = presentKeys
      .sort()
      .reverse()
      .map((el) => newArr[el])

    for (let i = 0; i < presentKeys.length; i++) {
      for (let j = 0; j < presentKeys[i].length; j++) {
        presentKeys[i][j].exams.sort((a, b) => a.name.localeCompare(b.name))
      }
    }

    let fullResults = presentKeys[0]
    fullResults = _.orderBy(
      fullResults,
      ['quantity_results', 'total'],
      ['desc', 'asc'],
    )

    setAppQuotationState({
      loading: false,
      fullResults: [fullResults],
    })
  }

  function sendData(item) {
    /*
    logEvent('Checkout Started', {
      'Total Cart Size': item.quantity_results,
      'Total Cart Value': item.total,
      'Total Discounts': 0,
      'Pharmacy Id': item.pharmacy_chain_id,
      'Pharmacy Name': item.pharmacy_chain_name,
      'Purchase Type': 'Spot',
    })

    try {
      addEventNameWithParams('click_selected_quotation', {
        pharmacy_chain_id: item?.pharmacy_chain_id,
        pharmacy_chain_name: item?.pharmacy_chain_name,
        products: item?.quotation_detail?.map((el) => {
          return {
            product_id: el?.product?.product_id,
            name: el?.product?.name,
            lab: el?.product?.lab,
            formula_name: el?.product?.formula_name,
            quantity: el?.quantity,
            price: el?.price,
          }
        }),
      })
    } catch (ignore) {}

    localStorage.setItem('selectedQuotationItem', JSON.stringify(item))
    if (process.env?.REACT_APP_ON_LOGIN === 'TRUE') {
      if (userInfo?.signInMethod === 'anonymously') {
        setModalLoginVisible(true)
        return
      }
    }

    history.push('/shopping_bag/checkout')
    */
  }

  function sendDataSubscription() {
    /*
    if (process.env?.REACT_APP_ON_LOGIN === 'TRUE') {
      if (userInfo?.signInMethod === 'anonymously') {
        setModalLoginVisible(true)
        return
      }
    }

    history.push('/shopping_bag/checkout')
    */
  }

  const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}>
      <i className="icon-chevron-right"></i>
    </div>
  )

  const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}>
      <i className="icon-chevron-right"></i>
    </div>
  )

  function bioequivalentRemplace(props) {
    let path =
      '/search/bioequivalent/' +
      Math.floor(Math.random() * Math.floor(100000000000000000000))
    history.push(path, {productSelect: props, pathname: location.pathname})
  }

  return (
    <>
      <div
        className="content-wrap"
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && <div className="content-responsive d-flex justify-content-between py-3 px-3 px-md-0">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span
              className="txt-4--regular-pr"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>}
      </div>
      <div
        className="flex-grow-1 content-wrap"
        style={
          themeColor?.background_color && {
            background: `${themeColor?.background_color}`,
          }
        }>
        <div className="content-responsive mt-2">
          {loading ? (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative m-0"
            />
          ) : (
            <>
              <div className="d-flex justify-content-between py-3">
                <div
                  id="gtm-webapp-quotation-filter"
                  className="select-custom d-none">
                  <select
                    style={localStyle.customSelect}
                    className={props.class}
                    name={props.name}
                    id={props.id}>
                    <option value={props.value}>Más económica</option>
                  </select>
                </div>
              </div>
              <div className="content-quotetation mb-5">
                {appQuotationState.fullResults &&
                  appQuotationState.fullResults.map((e, i) => {
                    return (
                      <div key={i} className={`content-quotetation--item`}>
                        <div
                          className={`content-pharmacies ${
                            subscriptionInfo
                              ? ''
                              : i === 0 && 'cards-best-price'
                          }`}
                          key={i}>
                          {e.map((element, key) => {
                            let subtotal = 0
                            let total = subtotal

                            return (
                              <ExamCard
                                key={key}
                                keyValue={subscriptionInfo ? key + 1 : key}
                                firstKey={i}
                                element={element}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalErrorExamIsOpen}
        onAfterOpen={null}
        onRequestClose={() => goBack()}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="Modal1"
        ariaHideApp={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={icErrorProducts}
            alt="Error"
            className="mb-3"
            id="gtm-webapp-error-exam-search-no-found"
          />
          <h4 className="title-primary--3xl-bold">Lo sentimos</h4>
          <p className="title-primary--xl text-center mb-30">
            No hemos encontrado resultados para tu búsqueda.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <ButtonYapp
            className="maxw-214 w-100"
            onClick={() => goBack()}
            color={'--theme-color'}
            title="Volver atrás"
          />
        </div>
      </Modal>
      <ModalLogin
        modalLoginVisible={modalLoginVisible}
        setModalLoginVisible={setModalLoginVisible}
      />
    </>
  )
}

const localStyle = {
  spinner: {
    position: 'relative',
    height: '40px',
    width: '40px',
    top: '10rem',
    left: 'calc(50% - 20px)',
    color: 'var(--color-primary)',
  },
}

const mapStateToProps = (state) => {
  return {
    logo: state.app.logo,
    disabledThemeCheckout: state.app.config?.disabled_theme_checkout,
    themeColor: state.app.config?.custom_styles?.theme_color,
    clientLogo: state.app.config?.logo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLogo: (logo) => dispatch(changeLogo(logo)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamQuotation)
