import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { menuUC, subMenuUC } from './constants';

const webHost = 'https://www.ucchristus.cl/';

function MobileMenu (props) {
  const [searchText, setSearchText] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [subMenuList, setSubMenuList] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    let menu = [];
    for (let item in menuUC) {
      menu.push(menuUC[item]);
    }
    setMenuList(menu);
  }, []);

  const onItem = (item) => {
    let subMenu = [];
    
    if (!item.link) {
      for (let subItem in subMenuUC) {
        if (subMenuUC[subItem].parent.title === item.title) {
          subMenu.push(subMenuUC[subItem]);
        }
      }

      setSubMenuList(subMenu);
      setShowSubMenu(subMenu.length > 0);
    }
    else {
      window.location.href = (item.host || webHost) + item.link;
    }
  }

  const onSubItem = (subItem) => {
    if (subItem.items?.length > 0) {
      if (subItem.title === selectedItem.title)
        setSelectedItem({});
      else
        setSelectedItem(subItem);
    }
    else if (subItem.link || subItem.host) {
      window.location.href = (subItem.host || webHost) + subItem.link;
    }
  }

  const onSelectedSubItem = (selectedItem) => {
    if (selectedItem.link || selectedItem.host)
      window.location.href = (selectedItem.host || webHost) + selectedItem.link;
  }

  const onSearch = () => {
    if (searchText) {
      window.location.href = `${webHost}buscador?indexCatalogue=reduc&searchQuery=${searchText}&wordsMode=AllWords`;
    }
  }
  return (
    <Modal isOpen={props?.show} onRequestClose={()=> props?.hide()} ariaHideApp={false} className="h-100 w-100 overflow-y-auto">
      <div
        className="animate__animated animate__fadeInLeft"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal_dialog_centered modal_dialog_centered modal_dialog_centered modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  onClick={()=> props?.hide()}>
                  <em className="fas fa-times"></em>
                </button>
              </div>
              { !showSubMenu && (
                <div
                  id="id_marking_mobile_menu_padre"
                  className="d-flex flex-column gap1 mt-4 w-100">
                  <div id="id_marking_mobile_menu_botones_y_buscar">
                    <a
                      href="https://agenda.ucchristus.cl/"
                      className="uc-header-btn uc-header-btn-purple uc-header-btn-mobile mt-4">
                      <img
                        src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/svg_calendar_blanco.svg?sfvrsn=8aacfcba_2"
                        alt=""
                        className='uc-header-btn-icon'
                      />
                      Reserva de Hora
                    </a>
                    <a
                      href="https://mi.ucchristus.cl/"
                      className="uc-header-btn uc-header-btn-blue uc-header-btn-mobile">
                      <img
                        src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/svg_icon_user_azul.svg?sfvrsn=7c1bf7b6_2"
                        alt=""
                        className='uc-header-btn-icon'
                      />
                      Portal Paciente
                    </a>
                    <div className="uc-header-btn uc-header-btn-blue uc-header-btn-mobile">
                      <input
                        id="txt_buscar_general_mobile"
                        type="text"
                        className="form-control w-100 h-100 border-0"
                        onChange={(text) => setSearchText(text.target.value)}
                      />
                      <div className="input-group-append h-100">
                        <button
                          className="uc-header-btn-blue search-btn-mobile"
                          type="button"
                          id="boton_buscar_mobile"
                          onClick={onSearch}>
                          <em className="fa fa-search pr-1" aria-hidden="true"></em>
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>
                  { menuList.map((item, index) => (
                    <div key={index}>
                      <span onClick={() => onItem(item)}>
                        <div className="ml-07 d-flex justify-content-between font_mobile_menu_item pt-0"
                          href={item.link ? (webHost + item.link) : "#"}
                        >
                          {item.title}
                          <em className={`${index != 5 ? 'fa fa-angle-right' : ''} uc-menu-mobile-item-arrow`}></em>
                        </div>
                      </span>
                      <hr />
                    </div>
                  ))}
                  <a
                    href="https://www.ucchristus.cl/seguro-complementario-santander/home"
                    className="uc-header-btn uc-header-btn-blue uc-header-btn-insurance-mobile w-100"
                    sfref="[f669d9a7-009d-4d83-ddaa-000000000002]BCEB3FDA-5C7D-438C-8FCA-D4E233EB99A4">
                    <img
                      src="https://d328k6xhl3lmif.cloudfront.net/docs/default-source/svg/logo-seguro-complementario-link.svg"
                      data-sf-ec-immutable=""
                    />
                  </a>
                  <div id="id_marking_mobile_menu_mesa_central_rescate ">
                    <div className="uc-menu-mobile-rescate-card">
                      <div className="row p-3">
                        <div className="col-6 uc-menu-mobile-rescate-card-text-container">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/phone-icon-mob.png?sfvrsn=582ef362_2"
                            className="uc-menu-mobile-rescate-card-icon"
                            alt=""
                            sfref="[images%7COpenAccessDataProvider]f841b60f-576f-45fe-8ed3-f732bd0d1693"
                            data-sf-ec-immutable=""
                          />
                          <p className="uc-menu-mobile-rescate-card-text">
                            Reserva de Hora
                          </p>
                        </div>
                        <div className="col-6 uc-menu-mobile-rescate-card-link-container">
                          <a
                            href="tel:226767000"
                            className="uc-menu-mobile-rescate-card-link"
                            data-sf-ec-immutable="">
                            22 676 7000
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="uc-menu-mobile-rescate-card">
                      <div className="row p-3">
                        <div className="col-6 uc-menu-mobile-rescate-card-text-container">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/phone-icon-mob.png?sfvrsn=582ef362_2"
                            className="uc-menu-mobile-rescate-card-icon"
                            alt=""
                            sfref="[images%7COpenAccessDataProvider]f841b60f-576f-45fe-8ed3-f732bd0d1693"
                            data-sf-ec-immutable=""
                          />
                          <p className="uc-menu-mobile-rescate-card-text">
                            Mesa Central Hospital Clínico
                          </p>
                        </div>
                        <div className="col-6 uc-menu-mobile-rescate-card-link-container">
                          <a
                            href="tel:223543000"
                            className="uc-menu-mobile-rescate-card-link"
                            data-sf-ec-immutable="">
                            22 354 3000
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="uc-menu-mobile-rescate-card">
                      <div className="row p-3">
                        <div className="col-6 uc-menu-mobile-rescate-card-text-container">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/phone-icon-mob.png?sfvrsn=582ef362_2"
                            className="uc-menu-mobile-rescate-card-icon"
                            alt=""
                            sfref="[images%7COpenAccessDataProvider]f841b60f-576f-45fe-8ed3-f732bd0d1693"
                            data-sf-ec-immutable=""
                          />
                          <p className="uc-menu-mobile-rescate-card-text">
                            Mesa Central Clínica San Carlos
                          </p>
                        </div>
                        <div className="col-6 uc-menu-mobile-rescate-card-link-container">
                          <a
                            href="tel:227548700"
                            className="uc-menu-mobile-rescate-card-link"
                            data-sf-ec-immutable="">
                            22 754 8700
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="uc-menu-mobile-rescate-card">
                      <div className="row p-3">
                        <div className="col-6 uc-menu-mobile-rescate-card-text-container">
                          <img
                            src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/iconos/phone-icon-mob.png?sfvrsn=582ef362_2"
                            className="uc-menu-mobile-rescate-card-icon"
                            alt=""
                            sfref="[images%7COpenAccessDataProvider]f841b60f-576f-45fe-8ed3-f732bd0d1693"
                            data-sf-ec-immutable=""
                          />
                          <p
                            className="uc-menu-mobile-rescate-card-text"
                            style={{color: '#e84040', fontWeight: 'bold'}}>
                            Rescate
                          </p>
                        </div>
                        <div className="col-6 uc-menu-mobile-rescate-card-link-container">
                          <a
                            href="tel:800 265 265"
                            className="uc-menu-mobile-rescate-card-link"
                            style={{color: '#e84040'}}
                            data-sf-ec-immutable="">
                            800 265 265
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-left mt-3">
                    <div>
                      <img
                        src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/fac-medicina-uc.png?sfvrsn=8ab7d2fc_2"
                        className="img img-fluid"
                        alt=" "
                        sfref="[images%7COpenAccessDataProvider]8136983e-9881-4a41-84d1-93539f39b283"
                        data-sf-ec-immutable=""
                      />
                    </div>
                    <div style={{display: 'flex'}}>
                      <img
                        src="https://d328k6xhl3lmif.cloudfront.net/images/default-source/p-100-images/logos/png_logo-christus.png?sfvrsn=c18e0c04_2"
                        style={{maxWidth: '210px'}}
                        className="img img-fluid"
                        alt=" "
                        sfref="[images%7COpenAccessDataProvider]e1c74a21-af49-465a-ac26-8ffa6073957f"
                        data-sf-ec-immutable=""
                      />
                    </div>
                  </div>
                </div>
              )}

              { showSubMenu && (
                <>
                  <div className="mt-5" key={'subitem'}>
                    <span onClick={() => setShowSubMenu(false)} className="mt-5">
                      <div className="ml-07 d-flex justify-content-left font_mobile_menu_item">
                        <em className="fa fa-angle-left uc-menu-mobile-item-arrow mr-3"></em>
                        {subMenuList[0].parent.title}
                      </div>
                    </span>
                    <hr />
                  </div>
                  { subMenuList.map((item, index) => (
                    <>
                      { item.title
                        ?
                          <>
                            <span onClick={() => onSubItem(item)} key={`sub-${index}`}>
                              <div className="ml-07 d-flex justify-content-between font_mobile_menu_item subitem">
                                {item.title}
                                <em className="fa fa-angle-down uc-menu-mobile-item-arrow"></em>
                              </div>
                            </span>
                            <hr />

                            { selectedItem?.title === item.title && (
                              selectedItem.items.map((subItem, subIndex) => (
                                <>
                                  <span onClick={() => onSelectedSubItem(subItem)}>
                                    <div className="ml-07 d-flex justify-content-between font_mobile_menu_item selected-subitem"
                                      href={subItem.link ? (webHost + subItem.link) : "#"}
                                    >
                                      {subItem.text}
                                    </div>
                                  </span>
                                  <hr />
                                </>
                              ))
                            )}
                          </>
                        : 
                          
                          ( item.items.map((subItem, subIndex) => (
                            <>
                              <span onClick={() => onSubItem(subItem)} key={`sub-sub-${subIndex}`}>
                                <div className="ml-07 d-flex justify-content-between font_mobile_menu_item subitem">
                                  {subItem.text}
                                </div>
                              </span>
                              <hr />
                            </>
                          )))
                      }
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal animate__animated animate__fadeInLeft"
        id="informacion_paciente_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal-dialog modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em className="fas fa-times"></em>
                </button>
              </div>
              <div className="d-flex flex-column mt-4 w-100">
                <div className="d-flex justify-content-left align-content-center mt-4">
                  <button
                    type="button"
                    className="mt-2 close font_mobile_menu_item_back"
                    data-toggle="modal"
                    data-target="#myModal"
                    data-dismiss="modal"
                    aria-label="Close">
                    <em
                      className="fa fa-chevron-left mr-4"
                      aria-hidden="true"></em>
                    Información al paciente
                  </button>
                </div>

                <div className="uc-menu-mobile" id="uc-accordion-menu-mob">
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Informacion_paciente_tab1">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Informacion_paciente_collapse1"
                          aria-expanded="false"
                          aria-controls="Informacion_paciente_collapse1">
                          Información
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="Informacion_paciente_collapse1"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab1"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item1"
                              href="https://www.ucchristus.cl/informacion-al-paciente/atencion-preferente"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]7DFC758B-493E-477A-92B3-43AF589C28CB"
                              data-sf-ec-immutable="">
                              Ley de Atención Preferente
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item2"
                              href="https://www.ucchristus.cl/informacion-al-paciente/ley-de-derechos-y-deberes"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]5442D142-3A82-49A6-835E-FACC1B4DF915"
                              data-sf-ec-immutable="">
                              Ley Derechos y Deberes
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item3"
                              href="https://www.ucchristus.cl/informacion-al-paciente/paciente-de-regiones"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]40E07C06-EB19-4C46-9FB9-E4FBA9D9F8C4"
                              data-sf-ec-immutable="">
                              Paciente de Regiones
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item4"
                              href="https://www.ucchristus.cl/informacion-al-paciente/paciente-internacional"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]4F7CCF22-8CC8-415C-8B17-A26E1FFF47E2"
                              data-sf-ec-immutable="">
                              Paciente Internacional
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item5"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/urgencia"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]D4864594-2AED-4AA7-965D-89E89573609C"
                              data-sf-ec-immutable="">
                              Servicio de Urgencias
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Informacion_paciente_tab2">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Informacion_paciente_collapse2"
                          aria-expanded="false"
                          aria-controls="Informacion_paciente_collapse2">
                          UC CHRISTUS
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="Informacion_paciente_collapse2"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab2"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item6
                                "
                              href="https://www.ucchristus.cl/apoyoemocional"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]43A4B67B-74C9-40C2-B544-2F5DD20DF230"
                              data-sf-ec-immutable="">
                              Apoyo Emocional Colaboradores
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item7"
                              href="https://www.ucchristus.cl/informacion-al-paciente/christus-health"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]55F10E2F-34BF-4700-BDA9-E01AA6158A89"
                              data-sf-ec-immutable="">
                              CHRISTUS Health / Compliance
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item8"
                              href="https://www.ucchristus.cl/informacion-al-paciente/quienes-somos"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]7DD3DFB2-314C-4E07-A14A-2434FFA17B62"
                              data-sf-ec-immutable="">
                              Quiénes Somos
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item9"
                              href="https://www.ucchristus.cl/informacion-al-paciente/mision-y-responsabilidad-social"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]5ED08E32-C4E2-4A98-AAAB-2401576AF9AC"
                              data-sf-ec-immutable="">
                              Misión y Responsabilidad
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item10"
                              href="https://www.ucchristus.cl/contacto-trabaja-con-nosotros"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]D3481A70-6211-4014-A757-2673AFDF139B"
                              data-sf-ec-immutable="">
                              Trabaja con Nosotros
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Informacion_paciente_tab3">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Informacion_paciente_collapse3"
                          aria-expanded="false"
                          aria-controls="Informacion_paciente_collapse3">
                          Indicadores
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="Informacion_paciente_collapse3"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab3"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item11"
                              href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/foco-calidad"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]FE4EACE2-B0A3-47EA-9341-68C509BBE34A"
                              data-sf-ec-immutable="">
                              Foco Calidad
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item12"
                              href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/satisfaccion"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]E450E3AB-9831-4F04-8332-282A606FE846"
                              data-sf-ec-immutable="">
                              Satisfacción
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item13"
                              href="https://www.ucchristus.cl/informacion-al-paciente/indicadores-clinicos/seguridad-del-paciente"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]893430D3-AACC-4B33-A804-17F04C9897F7"
                              data-sf-ec-immutable="">
                              Seguridad del Paciente
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Informacion_paciente_tab4">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Informacion_paciente_collapse4"
                          aria-expanded="false"
                          aria-controls="Informacion_paciente_collapse4">
                          Conéctate
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="Informacion_paciente_collapse4"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab4"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item14"
                              href="https://www.ucchristus.cl/blog-salud-uc"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]C71F0391-6BD5-4399-B034-21530B7B717A"
                              data-sf-ec-immutable="">
                              Blog
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item15"
                              href="https://www.ucchristus.cl/blog-salud-uc/uc-christus-conecta/home-charlas-y-talleres"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]20ACC2EC-FD9E-4F53-8C77-E0475187D366"
                              data-sf-ec-immutable="">
                              Charlas
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item16"
                              href="https://www.ucchristus.cl/informacion-al-paciente/podcast-de-salud"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]FA006570-5E40-4E06-99F8-FE982C5FF94E"
                              data-sf-ec-immutable="">
                              Podcast
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item17"
                              href="https://www.ucchristus.cl/informacion-al-paciente/red-de-mensajes"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]2665EC97-3BF9-4CEE-B267-2AEA38C77179"
                              data-sf-ec-immutable="">
                              Red de Mensajes
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Informacion_paciente_tab5">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Informacion_paciente_collapse5"
                          aria-expanded="false"
                          aria-controls="Informacion_paciente_collapse5">
                          Servicios Clínicos
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="Informacion_paciente_collapse5"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab5"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item18"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/unidades/unidad-de-rescate"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]9E67CDEA-667A-4A86-B240-BEAE057E1C98"
                              data-sf-ec-immutable="">
                              Unidad de Rescate
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item19"
                              href="https://www.ucchristus.cl/daisy"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]8187DB34-8D9E-49B5-8305-D42FF199DC2E"
                              data-sf-ec-immutable="">
                              Enfermería
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item20"
                              href="https://www.ucchristus.cl/yapp"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]A826E238-DC3A-4F0A-B0BE-32457779D673"
                              data-sf-ec-immutable="">
                              Farmacia en Línea
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item21"
                              href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual/telemedicina"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]873A2477-9022-452D-B8E2-D2C7F4E5882C"
                              data-sf-ec-immutable="">
                              Telemedicina
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item22"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/telemedicina-internacional"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]D24A50AB-89E5-40CD-BA6D-45EEC4786C66"
                              data-sf-ec-immutable="">
                              Telemedicina Internacional
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item23"
                              href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/servicios"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]30FE30C8-A459-4908-8468-F7A7058A53C1"
                              data-sf-ec-immutable="">
                              Todos los Servicios clínicos
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item24"
                              href="https://www.ucchristus.cl/informacion-al-paciente/uc-christus-en-casa"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]93DB2DA1-853B-41F2-B0D5-DDDA9327F7BA"
                              data-sf-ec-immutable="">
                              UC CHRISTUS en Casa
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item25"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/banco-de-sangre"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]B363C6B2-885E-453C-B38A-FD26DE2C7A1D"
                              data-sf-ec-immutable="">
                              Banco de Sangre
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="InformacionAlPaciente_informacion_menu_Item26"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/servicios/vacunatorios/vacunatorio"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]DD624E08-4EF2-48EA-BC59-44FA678029C4"
                              data-sf-ec-immutable="">
                              Vacunatorio
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal animate__animated animate__fadeInLeft"
        id="medico_y_especialidades_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal-dialog modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em className="fas fa-times"></em>
                </button>
              </div>
              <div className="d-flex flex-column mt-4 w-100">
                <button
                  type="button"
                  className="mt-2 close font_mobile_menu_item_back"
                  data-toggle="modal"
                  data-target="#myModal"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em
                    className="fa fa-chevron-left mr-4"
                    aria-hidden="true"></em>
                  Médicos y Especialidades
                </button>

                <div className="uc-menu-mobile" id="uc-accordion-menu-mob">
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Medicos_especialidades_tab1">
                      <span className="mb-0">
                        <a
                          id="MedicosYEspecialidades_ProgramasYCentros_menu_item1"
                          href="https://www.ucchristus.cl/busca-tu-profesional/nuestros-profesionales"
                          sfref="[f669d9a7-009d-4d83-ddaa-000000000002]667346CA-B66B-44BB-9E4C-E6D053F2AA34"
                          data-sf-ec-immutable="">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button">
                            Nuestros Profesionales
                          </button>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Medicos_especialidades_tab2">
                      <span className="mb-0">
                        <a
                          id="MedicosYEspecialidades_ProgramasYCentros_menu_item2"
                          href="https://www.ucchristus.cl/especialidades-y-servicios/especialidades"
                          sfref="[f669d9a7-009d-4d83-ddaa-000000000002]E628FCD7-B28F-4BC8-9B4A-9254510D51ED"
                          data-sf-ec-immutable="">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button">
                            Especialidades
                          </button>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="card uc-menu-mobile-category">
                    <div
                      className="card-header uc-menu-mobile-category-button-container"
                      id="Medicos_especialidades_tab3">
                      <span className="mb-0">
                        <button
                          className="uc-menu-mobile-category-button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree">
                          Programas y Centros
                          <em
                            className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                            style={{color: '#000000'}}></em>
                        </button>
                      </span>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="Informacion_paciente_tab3"
                      data-parent="#uc-accordion-menu-mob">
                      <div className="card-body uc-menu-mobile-submenu-container">
                        <ul className="uc-menu-mobile-submenu">
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="MedicosYEspecialidades_ProgramasYCentros_menu_item3"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/centros"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]65FCC6D3-5231-406C-BD2C-F842B7AD52A1"
                              data-sf-ec-immutable="">
                              Centros
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="MedicosYEspecialidades_ProgramasYCentros_menu_item4"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/programas"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]E6361545-482F-4034-A811-F3CEA9E57249"
                              data-sf-ec-immutable="">
                              Programas
                            </a>
                          </li>
                          <li className="uc-menu-mobile-submenu-item">
                            <a
                              id="MedicosYEspecialidades_ProgramasYCentros_menu_item5"
                              href="https://www.ucchristus.cl/especialidades-y-servicios/unidades"
                              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]CD601D69-E481-41F0-BE9C-24A906B4D65B"
                              data-sf-ec-immutable="">
                              Unidades
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal animate__animated animate__fadeInLeft"
        id="examanes_y_procedimientos_nodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal-dialog modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em className="fas fa-times"></em>
                </button>
              </div>
              <div className="d-flex flex-column gap1 mt-4 w-100">
                <button
                  type="button"
                  className="mt-2 close font_mobile_menu_item_back"
                  data-toggle="modal"
                  data-target="#myModal"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em
                    className="fa fa-chevron-left mr-4"
                    aria-hidden="true"></em>
                  Exámenes y Procedimientos
                </button>
                <div
                  id="id_marking_mobile_eyp_examenes"
                  className="card card_p100_blog mt-4">
                  <div className="uc-menu-mobile" id="uc-accordion-menu-mob">
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab1">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse1"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse1">
                            Clínicas y Hospitales
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse1"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab1"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item1"
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]B201682F-AECB-4028-BAA8-9A6EE5564459"
                                data-sf-ec-immutable="">
                                Clínica San Carlos
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item2"
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]A97B8EA8-40A3-455B-98F4-0238AF5B7FC2"
                                data-sf-ec-immutable="">
                                Hospital Clínico
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab2">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse2"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse2">
                            Laboratorio Clínico
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse2"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab2"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item3"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras/domicilios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]9D497155-3A2A-4271-BB2B-F701127ACB23"
                                data-sf-ec-immutable="">
                                Atención Domiciliaria
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item4"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/sobre-el-laboratorio/laboratorios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3722A0A6-B7F7-4B08-AA41-6C81C7953007"
                                data-sf-ec-immutable="">
                                Laboratorio Clínico
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item5"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]039BF75A-28B0-4D8B-A91B-8A9714D2F1C6"
                                data-sf-ec-immutable="">
                                Unidades de Toma de Muestras
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab3">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse3"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse3">
                            Centros Médicos
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse3"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab3"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item6"
                                href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara"
                                className="ExamenesYProcedimientos_menu_item"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]24A2800E-4FE5-4F74-B8D1-E2441D802E4B"
                                data-sf-ec-immutable="">
                                Centro Médico Alcántara
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item7"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]32A0C0E3-8006-4B4E-B7D1-75A8C19C55AB"
                                data-sf-ec-immutable="">
                                Centro Médico Bilbao
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item8"
                                href="https://www.ucchristus.cl/seguimos-creciendo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]F7EDE8E0-2D19-41F0-9D31-BE82116A1D18"
                                data-sf-ec-immutable="">
                                Centro Médico Escuela Militar
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item9"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]39540267-95F1-4A9E-BCF3-AEFA6454AAEB"
                                data-sf-ec-immutable="">
                                Centro Médico Irarrázabal
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item10"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]DDB50767-7FE9-4C24-8842-CA9BF819CDA5"
                                data-sf-ec-immutable="">
                                Centro Médico Lira
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item11"
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/servicios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]30FE30C8-A459-4908-8468-F7A7058A53C1"
                                data-sf-ec-immutable="">
                                Centro Médico Marcoleta
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item12"
                                href="https://www.ucchristus.cl/seguimos-creciendo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]F7EDE8E0-2D19-41F0-9D31-BE82116A1D18"
                                data-sf-ec-immutable="">
                                Centro Médico Providencia
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item13"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]45674A6F-F489-4F46-B24C-FA43F3F95FE9"
                                data-sf-ec-immutable="">
                                Centro Médico San Jorge
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item14"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]B6D505B3-C934-4241-80A3-8BD0C8C39D49"
                                data-sf-ec-immutable="">
                                Centro Médico San Joaquín
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item15"
                                href="https://www.ucchristus.cl/centros-medicos/centro-medico-santa-lucia"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]8C2DB390-D7E2-4790-82F4-0F1458929313"
                                data-sf-ec-immutable="">
                                Centro Médico Santa Lucía
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab4">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse4"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse4">
                            Centros Médicos Regiones
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse4"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab4"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item16"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]902FFCDA-AB65-46BA-960D-2BC8D95C00C3"
                                data-sf-ec-immutable="">
                                Centro Médico Rancagua
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab5">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse5"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse5">
                            Cesfam
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse5"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab5"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item17"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]FA29E6BA-5E00-47C6-B1D8-5E9873BF48B5"
                                data-sf-ec-immutable="">
                                Centro Ancora Juan Pablo ll
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item18"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3E755EA8-1C9B-4187-9A4C-CAB0FA970282"
                                data-sf-ec-immutable="">
                                Centro Ancora San Alberto Hurtado
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item19"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3AECD429-417B-4E44-ADE0-8E67710E3149"
                                data-sf-ec-immutable="">
                                Centro Ancora Teresa de Calcuta
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab6">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse6"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse6">
                            Atención Virtual
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse6"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab6"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ExamenesYProcedimientos_menu_item20"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]E133DEE0-08F2-474A-BAFF-05A157411D9B"
                                data-sf-ec-immutable="">
                                Centro Médico Virtual
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal animate__animated animate__fadeInLeft"
        id="red_de_atencion_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal-dialog modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em className="fas fa-times"></em>
                </button>
              </div>
              <div className="d-flex flex-column gap1 mt-4 w-100">
                <button
                  type="button"
                  className="mt-2 close font_mobile_menu_item_back"
                  data-toggle="modal"
                  data-target="#myModal"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em
                    className="fa fa-chevron-left mr-4"
                    aria-hidden="true"></em>
                  Red de Atención
                </button>
                <div
                  id="id_marking_mobile_rda_cyh"
                  className="card card_p100_blog mt-4">
                  <div className="uc-menu-mobile" id="uc-accordion-menu-mob">
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab1">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse1"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse1">
                            Clínicas y Hospitales
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse1"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab1"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item1"
                                href="https://www.ucchristus.cl/red-de-atencion/clinica-san-carlos-de-apoquindo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]B201682F-AECB-4028-BAA8-9A6EE5564459"
                                data-sf-ec-immutable="">
                                Clínica San Carlos
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item2"
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]A97B8EA8-40A3-455B-98F4-0238AF5B7FC2"
                                data-sf-ec-immutable="">
                                Hospital Clínico
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab2">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse2"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse2">
                            Laboratorio Clínico
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse2"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab2"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item3"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras/domicilios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]9D497155-3A2A-4271-BB2B-F701127ACB23"
                                data-sf-ec-immutable="">
                                Atención Domiciliaria
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item4"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/sobre-el-laboratorio/laboratorios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3722A0A6-B7F7-4B08-AA41-6C81C7953007"
                                data-sf-ec-immutable="">
                                Laboratorio Clínico
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item5"
                                href="https://www.ucchristus.cl/examenes-y-procedimientos/laboratorio-clinico/toma-de-muestras"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]039BF75A-28B0-4D8B-A91B-8A9714D2F1C6"
                                data-sf-ec-immutable="">
                                Unidades de Toma de Muestras
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab3">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse3"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse3">
                            Centros Médicos
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse3"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab3"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item6"
                                href="https://www.ucchristus.cl/red-de-atencion/edificio-medico-alcantara"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]24A2800E-4FE5-4F74-B8D1-E2441D802E4B"
                                data-sf-ec-immutable="">
                                Centro Médico Alcántara
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="
                                    RedDeAtencion_menu_item7"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-de-diagnostico-bilbao"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]32A0C0E3-8006-4B4E-B7D1-75A8C19C55AB"
                                data-sf-ec-immutable="">
                                Centro Médico Bilbao
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item8"
                                href="https://www.ucchristus.cl/seguimos-creciendo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]F7EDE8E0-2D19-41F0-9D31-BE82116A1D18"
                                data-sf-ec-immutable="">
                                Centro Médico Escuela Militar
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item9"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-irarrazaval"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]39540267-95F1-4A9E-BCF3-AEFA6454AAEB"
                                data-sf-ec-immutable="">
                                Centro Médico Irarrázabal
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item10"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-lira"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]DDB50767-7FE9-4C24-8842-CA9BF819CDA5"
                                data-sf-ec-immutable="">
                                Centro Médico Lira
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item11"
                                href="https://www.ucchristus.cl/red-de-atencion/hospital-clinico/servicios"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]30FE30C8-A459-4908-8468-F7A7058A53C1"
                                data-sf-ec-immutable="">
                                Centro Médico Marcoleta
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item12"
                                href="https://www.ucchristus.cl/seguimos-creciendo"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]F7EDE8E0-2D19-41F0-9D31-BE82116A1D18"
                                data-sf-ec-immutable="">
                                Centro Médico Providencia
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item13"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-jorge"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]45674A6F-F489-4F46-B24C-FA43F3F95FE9"
                                data-sf-ec-immutable="">
                                Centro Médico San Jorge
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item14"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-san-joaquin"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]B6D505B3-C934-4241-80A3-8BD0C8C39D49"
                                data-sf-ec-immutable="">
                                Centro Médico San Joaquín
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item15"
                                href="https://www.ucchristus.cl/centros-medicos/centro-medico-santa-lucia"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]8C2DB390-D7E2-4790-82F4-0F1458929313"
                                data-sf-ec-immutable="">
                                Centro Médico Santa Lucía
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab4">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse4"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse4">
                            Centros Médicos Regiones
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse4"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab4"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item16"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-médico-rancagua"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]902FFCDA-AB65-46BA-960D-2BC8D95C00C3"
                                data-sf-ec-immutable="">
                                Centro Médico Rancagua
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab5">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse5"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse5">
                            Cesfam
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse5"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab5"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item17"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-juan-pablo-ii"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]FA29E6BA-5E00-47C6-B1D8-5E9873BF48B5"
                                data-sf-ec-immutable="">
                                Centro Ancora Juan Pablo ll
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item18"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-san-alberto-hurtado"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3E755EA8-1C9B-4187-9A4C-CAB0FA970282"
                                data-sf-ec-immutable="">
                                Centro Ancora San Alberto Hurtado
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item19"
                                href="https://www.ucchristus.cl/red-de-atencion/cesfam-madre-teresa-de-calcuta"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]3AECD429-417B-4E44-ADE0-8E67710E3149"
                                data-sf-ec-immutable="">
                                Centro Ancora Teresa de Calcuta
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Red_atencion_tab6">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Red_atencion_collapse6"
                            aria-expanded="false"
                            aria-controls="Red_atencion_collapse6">
                            Atención Virtual
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Red_atencion_collapse6"
                        className="collapse"
                        aria-labelledby="Red_atencion_tab6"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="RedDeAtencion_menu_item20"
                                href="https://www.ucchristus.cl/red-de-atencion/centro-medico-virtual"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]E133DEE0-08F2-474A-BAFF-05A157411D9B"
                                data-sf-ec-immutable="">
                                Centro Médico Virtual
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal animate__animated animate__fadeInLeft"
        id="planes_de_salud_y_convenios"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal_dialog_p100_home modal_dialog_p100_home modal-dialog modal_dialog_centered modal-dialog-centered"
          role="document">
          <div className="modal-content fade_menu_mobile">
            <div className="modal-body uc-menu-mobile-modal-body">
              <div className="text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em className="fas fa-times"></em>
                </button>
              </div>
              <div className="d-flex flex-column gap1 mt-4 w-100">
                <button
                  type="button"
                  className="mt-2 close font_mobile_menu_item_back"
                  data-toggle="modal"
                  data-target="#myModal"
                  data-dismiss="modal"
                  aria-label="Close">
                  <em
                    className="fa fa-chevron-left mr-4"
                    aria-hidden="true"></em>
                  Convenios
                </button>
                <div
                  id="id_marking_mobile_psc_convenios"
                  className="card card_p100_blog mt-4">
                  <div className="uc-menu-mobile" id="uc-accordion-menu-mob">
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Convenios_tab1">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Convenios_collapse1"
                            aria-expanded="false"
                            aria-controls="Convenios_collapse1">
                            Convenios
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Convenios_collapse1"
                        className="collapse"
                        aria-labelledby="Convenios_tab1"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item1"
                                href="https://www.ucchristus.cl/convenios-uc-christus"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]1E4E14C8-93A0-4A5C-A961-86CFE57CE797"
                                data-sf-ec-immutable="">
                                Convenios
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item2"
                                href="https://www.miplanintegral.cl/"
                                data-sf-ec-immutable="">
                                Mi Plan Salud Integral (Colaboradores)
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item3"
                                href="https://www.ucchristus.cl/convenios/fonasa"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]EDD92C45-CEBF-44DD-BBA2-C54E761778D3"
                                data-sf-ec-immutable="">
                                FONASA
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item4"
                                href="https://www.ucchristus.cl/convenios/isapres"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]4A9F121A-6B5C-4B01-933A-9ACF51B6FE3F"
                                data-sf-ec-immutable="">
                                Isapres
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card uc-menu-mobile-category">
                      <div
                        className="card-header uc-menu-mobile-category-button-container"
                        id="Convenios_tab2">
                        <span className="mb-0">
                          <button
                            className="uc-menu-mobile-category-button"
                            type="button"
                            data-toggle="collapse"
                            data-target="#Convenios_collapse2"
                            aria-expanded="false"
                            aria-controls="Convenios_collapse2">
                            Aranceles
                            <em
                              className="fas fa-chevron-down uc-menu-mobile-category-button-arrow"
                              style={{color: '#000000'}}></em>
                          </button>
                        </span>
                      </div>
                      <div
                        id="Convenios_collapse2"
                        className="collapse"
                        aria-labelledby="Convenios_tab2"
                        data-parent="#uc-accordion-menu-mob">
                        <div className="card-body uc-menu-mobile-submenu-container">
                          <ul className="uc-menu-mobile-submenu">
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item5"
                                href="https://www.ucchristus.cl/informacion-al-paciente/aranceles"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]DEF75A68-3DB5-457C-991F-19F6FB67F63D"
                                data-sf-ec-immutable="">
                                Aranceles
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item6"
                                href="https://pagos.ucchristus.cl/cuenta"
                                data-sf-ec-immutable="">
                                Paga tu Cuenta
                              </a>
                            </li>
                            <li className="uc-menu-mobile-submenu-item">
                              <a
                                id="ConveniosYAranceles_menu_item7"
                                href="https://www.ucchristus.cl/presupuesto#"
                                sfref="[f669d9a7-009d-4d83-ddaa-000000000002]D15B8373-D83F-450C-832B-0E32276D47A8"
                                data-sf-ec-immutable="">
                                Pide tu Presupuesto
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MobileMenu;