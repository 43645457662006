import React from 'react'
import './NewTrackingStep.css'
import { useEffect, useState } from 'react'
import { stepNewTrackerPre, stepNewTrackerPos } from '../../Utils/constants'


function NewTrackingStep(props) {
  const [trackStep, setTrackingStep] = useState([])

  useEffect(() => {
    let steps = []

    if (!props.isPrepaid) {
      steps = stepNewTrackerPos
      switch (props.status) {
        case 'Entregado':
          steps = [
            {
              text: 'Esperando confirmación de la farmacia',
              icon: 'icon-store-check',
              description:
                '*El cobro de tu pedido se realizará una vez se confirme por la farmacia.',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido pagado', icon: 'icon-pay', status: 'active' },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: 'active' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: 'active' },
          ]
          break
        case 'EnCamino':
          steps = [
            {
              text: 'Esperando confirmación de la farmacia',
              icon: 'icon-store-check',
              description:
                '*El cobro de tu pedido se realizará una vez se confirme por la farmacia.',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido pagado', icon: 'icon-pay', status: 'active' },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: 'active' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        case 'Confirmado':
          steps = [
            {
              text: 'Esperando confirmación de la farmacia',
              icon: 'icon-store-check',
              description:
                '*El cobro de tu pedido se realizará una vez se confirme por la farmacia.',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido pagado', icon: 'icon-pay', status: 'active' },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: '' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        case 'PorConfirmar':
          steps = [
            {
              text: 'Esperando confirmación de la farmacia',
              icon: 'icon-store-check',
              description:
                '*El cobro de tu pedido se realizará una vez se confirme por la farmacia.',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: '',
            },
            { text: 'Pedido pagado', icon: 'icon-pay', status: '' },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: '' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        default:
          break
      }
    } else {
      steps = stepNewTrackerPre
      switch (props.status) {
        case 'Entregado':
          steps = [
            {
              text: 'Pedido pagado',
              icon: 'icon-store-check',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: 'active' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: 'active' },
          ]
          break
        case 'EnCamino':
          steps = [
            {
              text: 'Pedido pagado',
              icon: 'icon-store-check',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: 'active' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        case 'Confirmado':
          steps = [
            {
              text: 'Pedido pagado',
              icon: 'icon-store-check',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: 'active',
            },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: '' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        case 'Pagado':
          steps = [
            {
              text: 'Pedido pagado',
              icon: 'icon-store-check',
              status: 'active',
            },
            {
              text: 'Confirmado por farmacia',
              icon: 'icon-pharmacy-check',
              status: '',
            },
            { text: 'Pedido en camino', icon: 'icon-delivery', status: '' },
            { text: 'Pedido entregado', icon: 'icon-box-check', status: '' },
          ]
          break
        default:
          break
      }
    }

    setTrackingStep(steps)
  }, [props])

  return (
    <div className="content-new-tracking-step">
      <ul className="new-tracking">
        {trackStep &&
          trackStep.map((step, key) => (
            <li key={key} className={`new-tracking-item ${step.status}`}>
              <div className="new-tracking-item-dots" />
              <div
                className={`new-step-track--by-step ${step.status === 'active'
                  ? 'new-step-track--by-step-active'
                  : ''
                  }`}
              />
              <div className="w-100">
                <div className="new-step-track--description w-100">
                  <i className={step.icon}></i>
                  <span className="w-100 text-md-center">{step.text}</span>
                </div>
                {step?.description && (
                  <div className="ml-2 ml-md-0">
                    <p className="txt-6--regular-db text-md-center">
                      {step.description}
                    </p>
                  </div>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default NewTrackingStep
