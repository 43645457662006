import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  useAccordionToggle,
} from 'react-bootstrap'
import {useContext, useEffect, useState} from 'react'
import {addEventNameWithParams} from '../../Utils/utils'
import parse from 'html-react-parser'

import loadingYapp from '../../assets/img/yapp_loading.gif'

// eslint-disable-next-line no-unused-vars
const _ = require('lodash')

function ContextAwareToggle({children, eventKey, callback}) {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className="d-flex " onClick={() => decoratedOnClick()}>
      <div className="d-flex align-items-center w-100">
        <p className={`txt-paragraph ${isCurrentEventKey ? 'txt-bold' : ''}`}>
          {eventKey}
        </p>
      </div>
      <div className="d-flex align-items-center">
        <i
          className={`icon-chevron-down color-lilac ${
            isCurrentEventKey ? 'icon-up' : ''
          }`}></i>
      </div>
    </div>
  )
}

function Items(props) {
  const [toggle, setToggle] = useState()
  const [vad, setVad] = useState([undefined])
  let items = []

  useEffect(() => {
    if (!vad.includes(toggle)) {
      setVad((prevState) => [...prevState, toggle])
      addEventNameWithParams(
        `gtm-product-detail-vademecum-${toggle?.toLowerCase()}`,
        {
          product_name: props.data?.product_name,
          product_id: props.data?.product_id,
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle])

  for (const key in props.data?.more_information) {
    if (Object.hasOwnProperty.call(props.data?.more_information, key)) {
      let element = props.data?.more_information[key]

      if (![null, undefined].includes(element)) {
        if (Array.isArray(element)) continue

        if (['Posology', 'posology', 'Posología'].includes(key)) {
          continue
        }

        items.push(
          <Card key={key}>
            <Card.Header>
              <Accordion.Toggle
                onClick={() => setToggle(key)}
                as={Col}
                variant="link"
                eventKey={key}>
                <ContextAwareToggle
                  eventKey={
                    key.includes('_html') ? key.replace('_html', '') : key
                  }
                />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={key}>
              <Card.Body>
                <div className="d-flex justify-content-between mb-1">
                  <p className="txt-paragraph mb-1">
                    {!key.includes('_html') ? element : parse(element)}
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>,
        )
      }
    }
  }

  return <Accordion defaultActiveKey={0}>{items}</Accordion>
}

function ProductDetail(props) {
  const {appState} = props
  return (
    <div className="w-100 position-relative">
      {appState?.loading === false &&
      ![undefined, null].includes(appState.data) ? (
        <>
          <div className="content-card-info-pro">
            {appState.data?.more_information ? (
              <>
                <div className="toggle-default line-gray">
                  <Items data={appState.data} />
                </div>
              </>
            ) : (
              <div className="txt-paragraph color-gray-4 p-3 text-center">
                Sin información
              </div>
            )}
          </div>
        </>
      ) : (
        <img src={loadingYapp} alt="loading" className="loading-yapp" />
      )}
    </div>
  )
}

export default ProductDetail
