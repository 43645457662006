import { base_url, searchUrl } from '../../../Utils/constants'
import ButtonYapp from '../../../Components/Buttons/ButtonYapp'
import icPillCalendar from '../../../assets/img/i_pill_calendar.svg'
import { downloadApp, logEvent } from '../../../Utils/utils'
import { useHistory } from 'react-router'


function PostShopping(props) {
  let history = useHistory()
  const onKeepShopping = () => {
    //window.location.href = searchUrl
    history.push('/my_shopping')
  }

  return (
    <div className='content-responsive mx-auto px-3 mb-5'>
      <div className='maxw-436 mx-auto mt-5'>
        <div className='simple-card pt-5 pb-4 px-3 mb-3'>
          <p className='txt-1--bold-db mb-4 text-center'>¡Tu programación ha sido exitosa!</p>
          <div className='d-flex justify-content-center mb-4'>
            <img src={icPillCalendar} style={{ width: '8.75rem', height: '6.75rem' }} alt='Calendario' />
          </div>
          <p className='txt-5--regular-db text-center mb-3 px-4'>El despacho se cobrará en cada envío y el costo total se realizará <span className='txt-5--bold-db'>48 hrs antes</span> de cada compra.</p>
          <p className='txt-5--regular-db text-center mb-5 px-4'>Para gestionar tus compra, ingresa a la App de <span className='txt-5--bold-db'>Yapp</span></p>
          <ButtonYapp
            className="mx-auto size-content-responsive-btn w-100"
            onClick={downloadApp}
            color="--theme-color"
            title={'Ir a la App'}
          />
        </div>
        <ButtonYapp
          className="mx-auto size-content-responsive-btn w-100"
          onClick={onKeepShopping}
          color="--theme-color"
          title={'Ir a Tus compras'}
          stroke
        />
      </div>
    </div>
  )
}

export default PostShopping
