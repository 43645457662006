import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
//import {hideBtnBack} from '../../../Utils/constants'

import DesktopLogos from './DesktopLogos';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import Logo from '../../../assets/img/uc-logo.png'
import PowerByYapp from '../../../assets/img/power_by_header_new.svg';

if (process.env.REACT_APP_CLIENT_NAME === 'UC Christus') {
  import('./StylesUC.css').then((ignore) => {})
}

function HeaderUC(props) {
  const history = useHistory()

  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(0);

  const isSubMenuActive = (index) => {
    if (subMenuActive !== index) {
      setSubMenuActive(index)
    } else if (subMenuActive === index) {
      setSubMenuActive(0)
    }
  }

  const onNavigate = (c) => {
    if (c?.target) {
      window.open(c.go, c.target)
    } else {
      history.push(c.go)
    }
  }

  return (
    <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.6.0/css/bootstrap.min.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"/>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@splidejs/splide@4.0.7/dist/css/splide.min.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>

      <section>
        <div className="d-lg-none d-xl-none">
          <div
            id="id_marking_mobile_header"
            className="header_mobile flex_row_header_mobile">
            <div className="flex_column_header_mobile">
              <button className="color_user_header_mobile btn_menu_mobile navbar-toggler navbar_toggler_p100_home"
                id="navbarDropdown"
                type="button"
                onClick={()=> setShowMenuMobile(true)}>
                <em className="fas fa-bars"></em>
              </button>
              <span className="portal_header_font">Menu</span>
            </div>
            <a
              className="acolor_negro logo_header_mobile"
              href="https://www.ucchristus.cl/home"
              sfref="[f669d9a7-009d-4d83-ddaa-000000000002]045A4757-3874-4103-B29D-924C155A45B7">
              <img src={Logo} className="uc-header-logo" alt="" />
            </a>
            <a
              className="acolor_negro"
              href="https://mi.ucchristus.cl/"
              data-sf-ec-immutable="">
              <div className="flex_column_header_mobile">
                <em className="color_morado far fa-user fa-1x"></em>
                <div className="portal_header_font mt-2">Portal</div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <MobileMenu show={showMenuMobile} hide={() => setShowMenuMobile(false)}/>
      <section>
        <div className="d-lg-none d-xl-none">
          <div className="rescate_font_mobile mobile_rescate d-flex flex-md-row justify-content-center align-items-center">
            <a
              href="tel:800265265"
              style={{color: 'white', fontSize: '18px'}}
              data-sf-ec-immutable="">
              <em className="fas fa-phone-alt mt-1 mr-3"></em>
              <span className="rescate_font_mobile">Rescate Ambulancia </span>
              <span
                className="font_telefono color_blanco"
                style={{color: 'white !important'}}>
                800 265 265
              </span>
            </a>
          </div>
        </div>
      </section>

      {/* Desktop */}
      <DesktopLogos />
      <DesktopMenu />

      <section className="uc-header-placeholder mb-4"></section>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    goRouter: state.app.goRouter,
  }
}

export default connect(mapStateToProps)(HeaderUC)
