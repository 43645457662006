import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
/* Imgs */
import avatar from '../assets/img/avatar_admin.svg'
import {clearAppState, setOpenMenu} from '../redux/modules/app'
import {clearUserState} from '../redux/modules/user'
import {signOut} from '../Utils/auth'
import {base_url, shareMessages} from '../Utils/constants'
import {logEvent, shareWithColleagues} from '../Utils/utils'
import SidenavMenu from './SidenavMenu/SidenavMenu'
import ModalShareWithColleages from './Modals/ModalShareWithColleages'
import loadingBar from '../assets/img/loading_bar.gif'

function NewSidebar(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const userInfo = useSelector((state) => state?.user?.userInfo)
  const isOpenMenu = useSelector((state) => state?.app?.openMenu)
  const [loginState, setLoginState] = useState(false)
  const [modalState, setModalState] = useState(false)
  const url = base_url
  const {shareProduct} = shareMessages
  const initialLoading = useSelector((state) => state.app.initial_loading)

  useEffect(() => {
    if (userInfo !== null && userInfo.signInMethod !== 'anonymously') {
      setLoginState(true)
    } else {
      setLoginState(false)
    }
  }, [userInfo])

  function goTo(page) {
    dispatch(setOpenMenu(false))
    if (page === 'register') {
      logEvent('Create Account Started')
      history.push('/register', {requiredValidate: true})
    } else if (page === 'signin') {
      history.push('/signin', {requiredValidate: true})
    } else if (page === 'change_password') {
      history.push('/change_password', {requiredValidate: true})
    }
  }

  function onSignOut() {
    signOut().then()
    dispatch(clearUserState())
    dispatch(clearAppState())
    logEvent('Logged Out')
    if (history.location.pathname === '/') return window.location.reload(false)
    return window.open(base_url, '_self')
  }

  function modalShare() {
    dispatch(setOpenMenu(false))
    onShare()
  }

  const onShare = () => {
    let result = shareWithColleagues(url)
    if (result === false) {
      setModalState(true)
    }
  }
  const onClickFrecuentQuestion = () => {
    window.open('https://yapp.cl/preguntas-frecuentes/', '_black')
  }

  return (
    <>
      <ModalShareWithColleages
        modalState={modalState}
        url={url}
        setModalState={setModalState}
        title={shareProduct.title}
        description={shareProduct.description}
      />

      <div
        className={`sidebar-menu bg-white ${
          isOpenMenu && 'sidebar-menu-active'
        }`}>
        {initialLoading ? (
          <>
            <img src={loadingBar} className="w-100" style={{height: '5px'}} />
          </>
        ) : (
          <>
            <div className="card-session py-3 text-center">
              <img src={avatar} className="avatar mb-2" alt="avatar" />
              {!loginState ? (
                <>
                  <p
                    className="txt-4--bold-db mb-1 px-3 color-lilac c-pointer"
                    onClick={() => goTo('signin')}>
                    Iniciar sesión
                  </p>
                  <p
                    className="txt-5--regular-db px-3 color-lilac c-pointer"
                    onClick={() => goTo('register')}>
                    Crear cuenta
                  </p>
                </>
              ) : (
                <>
                  <p className="txt-4--bold-db mb-1 px-3">
                    {userInfo?.profile.first_name} {userInfo?.profile.last_name}
                  </p>
                  <p className="txt-5--regular-db px-3">
                    {userInfo?.profile.email}
                  </p>
                </>
              )}
            </div>
            {loginState && (
              <div>
                <SidenavMenu />
              </div>
            )}
            {loginState ? (
              <>
                <div className="px-3 py-4"></div>
                <div className="mt-auto">
                  { ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE) && (
                    <button
                      id="gtm-webapp-menu-pro-btn-share"
                      className="main-footer px-3 d-flex align-items-center w-100 bg-white"
                      onClick={modalShare}>
                      <p className="txt-4--regular-pr btn-sidebar-share">
                        <i className="icon-share mr-2 "></i>Compartir con
                        colegas
                      </p>
                    </button>
                  )}{' '}
                  <button
                    id="gtm-webapp-header-account-admin-signout-btn"
                    className="main-footer px-3 d-flex align-items-center w-100 bg-white"
                    onClick={onClickFrecuentQuestion}>
                    <p className="txt-4--regular-pr color-theme">Preguntas frecuentes</p>
                  </button>
                  {userInfo?.signInMethod === 'password' && (
                    <button
                      id="gtm-webapp-header-account-admin-change-password-btn"
                      className="main-footer px-3 d-flex align-items-center w-100 bg-white"
                      onClick={() => goTo('change_password')}>
                      <p className="txt-4--regular-pr color-theme">Cambiar contraseña</p>
                    </button>
                  )}
                  <button
                    id="gtm-webapp-header-account-admin-signout-btn"
                    className="main-footer px-3 d-flex align-items-center w-100 bg-white"
                    onClick={onSignOut}>
                    <p className="txt-4--regular-pr color-theme">Cerrar sesión</p>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="px-3 py-4"></div>
                <div className="mt-auto">
                  <button
                    id="gtm-webapp-header-account-admin-signout-btn"
                    className="main-footer px-3 d-flex align-items-center w-100 bg-white"
                    onClick={onClickFrecuentQuestion}>
                    <p className="txt-4--regular-pr">Preguntas frecuentes</p>
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div
        className="overlay c-pointer"
        onClick={() => dispatch(setOpenMenu(false))}
      />
    </>
  )
}

export default NewSidebar
