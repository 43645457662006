import {useDispatch} from 'react-redux'
import {useState} from 'react'
import AsyncSelect from 'react-select/async'
import {autocomplete} from '../../Utils/api'

import icSearchSecondary from '../../assets/img/ic_search_secondary.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import {addEvent, addEventNameWithParams, logEvent} from '../../Utils/utils'
import {theme} from '../../Utils/constants'

import {setResultSearch as setResultSearchRdx} from '../../redux/modules/search'

const debounce = require('lodash/debounce')
const clientName = process.env.REACT_APP_CLIENT_NAME || 'Pacientes'

function SearchPrincipleActive(props) {
  const dispatch = useDispatch()
  const [resultSearch, setResultSearch] = useState(null)
  const [appState, setAppState] = useState({
    loading: false,
    products: [],
    inputValue: '',
    groupedOptions: [],
  })

  const formatGroupLabelSecondary = (data) => (
    <div style={groupStylesSecondary}>
      <span>{data.label}</span>
    </div>
  )

  const promiseOptions = debounce(async (inputValue, callback) => {
    // Only run the autocomplete if the input value has at least 4 characters
    if (inputValue.length >= 4) {
      // Convert the input value to uppercase
      const parsedInputValue = inputValue.toUpperCase()
      // Fetch the autocomplete data
      let products = await autocomplete(
        parsedInputValue,
        props.vidal,
        props.request_from,
      )

      if (products?.data) {
        setResultSearch({
          result_id: products.result_id,
          request_from: props.request_from,
        })

        let group = products.data.map((c) => {
          return {value: c.product_id, label: c.product_name, data: c}
        })
        let amplitude = products.data
          .filter((c) => c.product_internal_id !== null)
          .map((c, index) => {
            return {
              product_id: c.product_internal_id,
              lab_id: c.laboratory_id,
              position: index + 1,
            }
          })

        let options = []

        if (props.principleActive) {
          options.push({
            label: 'Principio Activo',
            options: group.filter(
              (item) =>
                item.data?.is_product === 0 &&
                item.data?.category === 'Principio Activo',
            ),
          })
        }

        options.push({
          label: 'Nombre',
          options: group.filter(
            (item) =>
              item.data?.is_product === 1 && item.data?.category === 'Nombre',
          ),
        })
        setAppState({groupedOptions: options})

        //logEvent sending only the first 10 results
        const firstTen = amplitude.filter((val) => val !== null).slice(0, 10)
        logEvent('Product Found', {
          'Product searched': parsedInputValue,
          Results: firstTen,
        })

        callback(options)
      } else {
        setResultSearch(null)
        logEvent('Product not Found', {
          'Product searched': parsedInputValue,
        })
        callback([])
      }
    }
  }, 1000) // Debounce for 1 second

  const localDebounce = debounce((inputValue, callback) => {
    promiseOptions(inputValue, callback)
  }, 300)

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  const onChangeInput = (newValue) => {
    if (props?.onClickSearch) {
      if (props.from === 'search') {
        addEventNameWithParams('quotation_select_product', {
          product_id: newValue?.value,
          product_name: newValue?.label,
        })
      }

      if (props.clearInput) {
        setAppState({...appState, groupedOptions: []})
      }

      dispatch(
        setResultSearchRdx({
          ...resultSearch,
          is_active_principle: newValue?.data?.is_product === 0,
          active_principle_id: newValue?.data?.active_principle_id,
        }),
      )
      return props.onClickSearch(newValue)
    }
  }
  const borderColor = theme?.border_color
    ? `1px solid ${theme?.border_color}`
    : props.themeColor
    ? `1px solid var(${props.themeColor})`
    : '1px solid #6578FF'

  const styleProductPA = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius,
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: borderColor,
      '&:hover': {
        border: borderColor,
      },
      ':before': {
        backgroundImage: `url(${icSearchSecondary})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 0,
        height: '41px',
        width: '41px',
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#282e55',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '20px',
      zIndex: 1049,
      border: '1px solid #E9E6FE',
      paddingTop: '20px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '14px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      padding: '4px 10px',
      position: 'relative',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
            top: '50%',
            transform: 'translateY(-50%)',
          }
        : {},
    }),
  }

  function onFocusInput(e) {
    addEvent('product_search_focus')
  }

  function menuListValue(option) {
    return (
      <div className="m-0 mr-5">
        <h4 className="title-primary--light m-0">{option.label}</h4>
        {option?.data?.laboratory_name !== '-' &&
          option.data.laboratory_name && (
            <p className="txt-paragraph--small m-0 laboratory-name">
              {option.data.laboratory_name}
            </p>
          )}
      </div>
    )
  }

  const placeholderSearch = props.placeholder
    ? props.placeholder
    : 'Busca tus medicamentos aquí'

  return (
    <div className={`py-3 py-md-4 ${props.className}`}>
      {!props.isNotShow && (
        <h5 className="title-primary">{ clientName === 'Bice Vida' ? 'Buscar medicamento' : 'Agregar medicamentos' }</h5>
      )}
      <AsyncSelect
        className="search-product"
        classNamePrefix="react-select"
        id="gtm-webapp-search-products-principle-active"
        isClearable
        cacheOptions
        onFocus={onFocusInput}
        onChange={onChangeInput}
        noOptionsMessage={() => 'Sin resultados...'}
        placeholder={placeholderSearch}
        controlShouldRenderValue={false}
        formatGroupLabel={formatGroupLabelSecondary}
        defaultOptions={appState.groupedOptions}
        loadOptions={loadSuggestions}
        styles={styleProductPA}
        getOptionLabel={(option) => menuListValue(option)}
      />
    </div>
  )
}

const groupStylesSecondary = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#282E55',
  borderBottom: '1px solid #DDE0EC',
  paddingTop: '0',
  marginTop: '-5px',
  marginBottom: '9px',
  fontFamily: 'var(--font-bold)',
  paddingBottom: '9px',
  fontSize: '14px',
  textTransform: 'Capitalize',
}

export default SearchPrincipleActive
