import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {connect, useSelector} from 'react-redux'
import {ENUM_MENU} from '../../Utils/constants'

import ProductSearchPrincipleActive from '../../Components/ProductSearch/SearchPrincipleActive'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import {_formatPrice} from '../../Utils/tools'
import {getBioequivalents, getProfessionalDiscountCode} from '../../Utils/api'
import productImg from '../../assets/img/prescription.png'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import proIconLoading from '../../assets/img/proIconLoading.svg'

const _orderBy = require('lodash/orderBy')

function MedicationSheetProduct(props) {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [productId, setProductId] = useState(history.location.state?.product_id)
  const [product, setProduct] = useState(history.location.state?.product)
  const [bio, setBio] = useState(null)
  const [codes, setCodes] = useState(null)

  useEffect(() => {
    if (productId) {
      getBioequivalentsProducts(productId)

      getProfessionalDiscounts(productId)
    } else {
      history.push('/error')
    }
  }, [])

  const onClickSearch = async (newValue) => {
    setLoading(true)
    if (
      newValue?.data?.is_product === 1 ||
      [undefined, null].includes(newValue?.data?.is_product)
    ) {
      if (newValue?.value) {
        let id = newValue.data.product_id
        addEventNameWithParams('price_medication_sheet_select_product', {
          product_id: id,
        })
        clearLocalStates()

        setProductId(id)
        setProduct(newValue.data)
        getBioequivalentsProducts(newValue.data.product_id)
        getProfessionalDiscounts(newValue.data.product_id)

        history.replace('/pro/medication-sheet-product', {
          product_id: id,
          product: newValue.data,
        })
      } else {
        console.log('Error value')
        setLoading(false)
      }
    } else {
      addEventNameWithParams(
        'medication_sheet_product_select_active_principle',
        {
          product_name: newValue?.label,
          active_principle_id: newValue?.data?.active_principle_id,
        },
      )

      history.push('/pro/medication-sheet-result', {
        product_input: newValue,
      })
    }
  }

  const getBioequivalentsProducts = async (dataProductId) => {
    try {
      let bioProducts = await getBioequivalents(dataProductId)
      if (bioProducts?.data) {
        let filterBio = _orderBy(bioProducts?.data, ['price_from'], ['asc'])
        setBio(filterBio)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const getProfessionalDiscounts = async (dataProductId) => {
    try {
      let productWithCode = await getProfessionalDiscountCode(dataProductId)
      if (productWithCode?.data) {
        let filterCodes = _orderBy(
          productWithCode?.data,
          ['price_from'],
          ['asc'],
        )
        setCodes(filterCodes)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const clearLocalStates = () => {
    setProductId(null)
    setProduct(null)
  }

  const onClickOption = (itemProductId, itemProduct, typeSelected) => {
    logEvent('Medication Sheet Checked', {
      Origin: 'Menu',
      'Product Id': itemProductId,
      'Product Name': itemProduct?.product_name,
      'Lab Name': itemProduct?.laboratory_name,
      'Search Type':
        typeSelected === 'product_result'
          ? 'Product Result'
          : 'Product Similar',
    })
    switch (typeSelected) {
      case 'product_result':
        addEventNameWithParams('medication-sheet-products--product-result', {
          product_id: itemProductId,
        })
        break
      case 'product_similar':
        addEventNameWithParams('medication-sheet-products--product-similar', {
          product_id: itemProductId,
        })
        break
    }

    history.push(`/product/detail/${itemProductId}`, {
      product_id: itemProductId,
      product: itemProduct,
      isComesFromMedicationSheet: true,
    })
  }

  function goBack() {
    history.goBack()
  }

  return (
    <>
      <div className="py-3 px-3 ">
        <div className="content-responsive mx-auto d-flex justify-content-between ">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <div className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </div>
            <span className="title-primary--regular line-14">Volver</span>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 px-3">
        <div className="content-responsive mx-auto">
          <div className="py-2">
            <h2 className="title-primary--xxl m-0">Buscador de medicamentos</h2>
            <div className="">
              <ProductSearchPrincipleActive
                principleActive={true}
                request_from={ENUM_MENU.price_finder}
                className="hpt-16"
                onClickSearch={onClickSearch}
                isNotShow={true}
                vidal={2}
                placeholder={'Busca por marca o principio activo'}
              />
            </div>
          </div>
        </div>
        <hr className="hr--gray" />
        <div className="content-responsive mx-auto">
          {loading ? (
            <div className="text-center image-center">
              <h2 className="title-primary--3xl-bold mt-3">
                En breve verás tu búsqueda
              </h2>

              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--pro m-0"
              />
              <img
                src={proIconLoading}
                alt="Loading icon"
                className="maxw-290"
              />
            </div>
          ) : (
            <div className="py-4 px-3 px-md-0">
              <h2 className="title-primary--xl-bold mb-3">Resultado</h2>
              {product && (
                <CardInteracion
                  id={`gtm-webapp-medication-sheet-product-selected`}
                  className="mb-4"
                  doAction={() =>
                    onClickOption(
                      product?.product_id,
                      product,
                      'product_result',
                    )
                  }>
                  <div className="content-img-medication-sheet">
                    <img
                      src={
                        product?.product_logo
                          ? product?.product_logo
                          : productImg
                      }
                      alt={`nombre del producto`}
                      className="img-mw-35"
                    />
                  </div>
                  <div className="ml-14 overflow-hidden">
                    <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2">
                      {product?.product_name}
                    </h2>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.formula_name}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.presentation}
                    </p>
                    <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                      {product?.laboratory_name}
                    </p>
                    {product?.price && (
                      <p className="txt-paragraph--bold m-0 line-16 text-ellipsis--2">
                        Desde {_formatPrice(product?.price)}
                      </p>
                    )}
                  </div>
                </CardInteracion>
              )}
              {Array.isArray(bio) && bio.length > 0 && (
                <>
                  <h2 className="title-primary--xl-bold mb-3">
                    Productos relacionados
                  </h2>
                  <div className="content-card-product">
                    {bio?.map((item, key) => (
                      <CardInteracion
                        key={key}
                        id={`gtm-webapp-medication-sheet-result-relations-${key}`}
                        doAction={() =>
                          onClickOption(
                            item?.product_id,
                            item,
                            'product_similar',
                          )
                        }>
                        <div className="content-img-medication-sheet">
                          <img
                            src={
                              item?.product_logo
                                ? item?.product_logo
                                : productImg
                            }
                            alt={`nombre del producto`}
                            className="img-mw-35"
                          />
                        </div>
                        <div className="ml-14 overflow-hidden">
                          <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2">
                            {item?.product_name}
                          </h2>
                          <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                            {item?.formula_name}
                          </p>
                          <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                            {item?.presentation}
                          </p>
                          <p className="txt-paragraph--small m-0 line-14 text-ellipsis--2">
                            {item?.laboratory_name}
                          </p>
                          {/* <p className='txt-paragraph--bold m-0 line-16 text-ellipsis--2'>Desde {_formatPrice(item?.price_from)}</p> */}
                        </div>
                      </CardInteracion>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    professionalDiscountCode: state.user.professional_discount_code,
    userInfo: state.user.userInfo,
  }
}

export default connect(mapStateToProps)(MedicationSheetProduct)
