import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ButtonFalseInput from '../../Components/Buttons/ButtonFalseInput'

function ModalBeneficiaries(props) {
  const {
    isOpen,
    setIsOpen,
    title,
    btnText,
    className,
    beneficiaries,
    onClaimer,
    claimer,
    setClaimer,
  } = props

  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  useEffect(() => {
    let currentBeneficiaries = [];
    let currentHolders = [];

    if (beneficiaries?.length > 0) {
      currentBeneficiaries = getBeneficiariesByType(beneficiaries, false);
      currentHolders = getBeneficiariesByType(beneficiaries, true);
    }

    setBeneficiaryList(currentBeneficiaries);
    setHolderList(currentHolders);
  },[beneficiaries]);

  function getBeneficiariesByType(list, hasHolders) {
    if (hasHolders) {
      return list.filter((c) => c.relationship?.toUpperCase() === 'TITULAR'
        || c.relationship?.toUpperCase() === 'HOLDER'
      );
    }
    else {
      return list.filter((c) => c.relationship?.toUpperCase() !== 'TITULAR'
        && c.relationship?.toUpperCase() !== 'HOLDER'
     );
    }
  }

  function isSelected(item) {
    return claimer?.identity === item.identity && claimer?.name === item.name;
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={null}
      onRequestClose={() => setIsOpen(false)}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}
    >
      <button className="btn-circle-close" onClick={() => setIsOpen(false)}>
        <i className="icon-close"></i>
      </button>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className={`txt-3--light-db text-center mb-30 ${className}`}>
          {title}
        </p>
      </div>

      { holderList.length > 0 && (
        <div className={'pb-3'}>
          <p className={`txt-3--bold-db pb-2 ${className}`}>Titular</p>
          { holderList.map((holder, key) => (
            <div className="d-flex align-items-center py-1" onClick={() => setClaimer(holder)} key={`h-${key}`}>
              <label className="custom-checkbox">
                <input type="checkbox" checked={isSelected(holder)} disabled={true} />
                <span className="checkmark">
                  <i className="icon-checkmark"></i>
                </span>
              </label>
              <ButtonFalseInput text={holder.name} key={`txt-h-${key}`} />
            </div>
          ))}
        </div>
      )}

      { beneficiaryList.length > 0 && (
        <div className={'pb-4'}>
          <p className={`txt-3--bold-db pb-2 ${className}`}>Beneficiarios</p>
          { beneficiaryList.map((beneficiary, key) => (
            <div className="d-flex align-items-center py-1" onClick={() => setClaimer(beneficiary)} key={`b-${key}`}>
              <label className="custom-checkbox">
                <input type="checkbox" checked={isSelected(beneficiary)} disabled={true} />
                <span className="checkmark">
                  <i className="icon-checkmark"></i>
                </span>
              </label>
              <ButtonFalseInput text={beneficiary.name} key={`txt-b-${key}`} />
            </div>
          ))}
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column">
        <ButtonYapp
          className="btn-default--small btn-next btn-theme"
          disabled={[undefined, null, ''].includes(claimer)}
          onClick={() => onClaimer(claimer)}
          title={btnText}
        />
      </div>
    </Modal>
  )
}

export default ModalBeneficiaries
