import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

const title = 'PREGUNTAS FRECUENTES (FAQ)';
const questionsAndAnswers = [
  {
    question: 'Tengo dudas con el estado de mi pedido',
    answer: `Al finalizar tu compra te enviamos un correo con los detalles de tu pedido,
      ahí podrás encontrar un link para hacer seguimiento del estado de tu orden de compra.<br /><br />
      Si no lo encuentras, no olvides revisar en las promociones y/o spam.<br /><br />
      Si aún tienes problemas con el pedido, no dudes en escribirnos a <a href="mailto:soporte@yapp.cl">soporte@yapp.cl</a>.`
  },
  {
    question: 'Rechazaron mi pedido por problemas con la receta',
    answer: `Si hubo un problema con la receta, Yapp se contactará contigo para confirmar que tengas alguna receta válida,
      si no la tienes, realizaremos el reembolso de tu compra en un plazo máximo de 10 días hábiles.<br /><br />
      No te preocupes por tu bonificación de Seguro, no se descontará de tu saldo, simplemente se anulará la transacción.<br /><br />
      Si nadie te ha contactado en el plazo de 24 horas desde el rechazo, puedes escribirnos a <a href="mailto:soporte@yapp.cl">soporte@yapp.cl</a>.`
  },
  {
    question: 'Rechazaron mi pedido por problemas de stock',
    answer: `Sí hubo un problema de stock con tu pedido, realizaremos el reembolso de tu compra en un plazo máximo de 10 días hábiles.<br /><br />
      No te preocupes por tu bonificación de Seguros, no se descontará de tu saldo, simplemente se anulará la transacción.<br /><br />
      Si después de 10 días aún no recibes tu reembolso puedes escribirnos a <a href="mailto:soporte@yapp.cl">soporte@yapp.cl</a>.`
  },
  {
    question: 'Me cancelaron/ rechazaron el pedido y no me han reembolsado',
    answer: `Si se canceló o rechazó tu pedido, realizaremos el reembolso de tu compra en un plazo máximo de 10 días hábiles.<br /><br />
      No te preocupes por tu bonificación de Seguros, no se descontará de tu saldo, simplemente se anulará la transacción.<br /><br />
      Si después de 10 días aún no recibes tu reembolso puedes escribirnos a <a href="mailto:soporte@yapp.cl">soporte@yapp.cl</a>.`
  },
  {
    question: 'Tengo fecha de entrega, pero mi pedido aún no se ha confirmado',
    answer: `Ten en cuenta que tu pedido puede ser confirmado por la farmacia desde el día de la compra hasta el día programado de entrega.<br /><br />
      Si ya pasó el día de entrega y tu pedido aún no ha sido confirmado, puedes escribirnos a <a href="mailto:soporte@yapp.cl">soporte@yapp.cl</a>.`
  },
  {
    question: 'Estoy buscando un medicamento que uso de manera regular pero no me aplica el reembolso',
    answer: `Recuerda, para que aplique el reembolso correctamente, debes tener una póliza vigente, no debes haber cumplido el tope anual
      y no deberías estar aplicando deducible de tu póliza.<br /><br />
      Si aún tienes problemas puedes contactarte directamente con el corredor de tu póliza de seguro para obtener ayuda adicional.`
  }
];

function QuestionsAndAnswers(props) {
  const [activeItem, setActiveItem] = useState({});
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    let itemList = [];
    for (let i = 0; i < questionsAndAnswers.length; i++) {
      itemList.push({...questionsAndAnswers[i], id: i});
    }
    setQuestionList(itemList);
  }, []);

  const onClick = (item) => {
    if (activeItem.id !== item.id) {
      setActiveItem(item);
    }
    else {
      setActiveItem({});
    }
  }

  return (
    <div className="wrapper-location position-relative">
      <div className="content-light-wrapper my-5">
        <div className="content-light-wrapper-header background-theme p-0">
          <h6 className="m-2 p-2 pl-3">{title}</h6>
        </div>
        <div className="content-light-wrapper-body py-2 px-0">
          { questionList.map((item) => (
            <div key={item.id} onClick={() => onClick(item)}
              className={"content-card-light c-pointer py-3 px-4 my-0 " + (activeItem.id === item.id ? 'txt-3--bold-db' : 'txt-3--regular-db')}
              style={{ borderBottomStyle: (item.id + 1 === questionList.length ? 'none' : 'inset'), borderRadius: '0px' }}
            >
              <Row>
                <Col xs={10}>
                  {item.question}
                </Col>
                <Col className="text-right" xs={2} style={{ fontSize: 'larger' }}>
                  <i className={"color-theme-secondary " + (activeItem.id === item.id ? "fa fa-angle-up" : "fa fa-angle-down")} />
                </Col>
              </Row>
              { activeItem.id === item.id && (
                <Row className="content-card-light-body text-justify txt-3--regular-db pl-3 pr-5 pt-3">
                  <h6>{parse(item.answer)}</h6>
                </Row>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QuestionsAndAnswers