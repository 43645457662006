const SET_RESULT = 'SET_RESULT'

const initialState = {
  result: {
    result_id: null,
    request_from: null,
    is_active_principle: false,
    active_principle_id: null,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESULT:
      return {
        ...state,
        result: action.result,
      }
    default:
      return state
  }
}

export function setResultSearch(result) {
  return {
    type: SET_RESULT,
    result,
  }
}
