import {React} from 'react'
import {
  View,
  Text,
  Document,
  Page,
  Font,
  Image,
  Svg,
  Link,
  Path,
  StyleSheet,
} from '@react-pdf/renderer'
import QRCode from 'qrcode-svg'
import {pharmacyChainUrl} from '../../Utils/constants'
import {_formatPrice} from '../../Utils/tools'
import NunitoRegular from '../../assets/fonts/Nunito-Regular.ttf'
import NunitoBold from '../../assets/fonts/Nunito-Bold.ttf'
import NunitoLight from '../../assets/fonts/Nunito-Light.ttf'

Font.registerHyphenationCallback((word) => [word])

Font.register({
  family: 'Nunito',

  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'light',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoLight,
      fontWeight: 'ultralight',
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingHorizontal: 8,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  footerContainer: {
    marginTop: 'auto',
    marginBottom: 10,
    flexDirection: 'row',
    display: 'flex',
    flexFlow: 'row',
    width: 650,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerText: {
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 'light',
    textAlign: 'center',
    paddingHorizontal: 16,
    with: 480,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginHorizontal: 12,
    marginBottom: 4,
    paddingVertical: 2,
    paddingHorizontal: 2,
    width: 100,
    height: 32,
  },
  logo: {
    width: 65,
    height: 22,
    marginBottom: 10,
  },
  InfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 744,
    marginVertical: 0,
    marginHorizontal: 12,
    paddingBottom: 0,
    paddingTop: 0,
  },
  border: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: 1,
    borderBottomColor: '#A9ABBB',
    paddingVertical: 4,
    marginLeft: 10,
    width: 550,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    lineHeight: 1.9,
    marginLeft: 0,
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'Nunito',
    fontWeight: 'light',
    maxWidth: 364,
    lineHeight: 1.4,
    marginBottom: 8,
    letterSpacing: 0.3,
  },
  qr: {
    display: 'flex',
    width: 120,
    height: 120,
    alignItems: 'center',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    order: 2,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    order: 1,
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 90,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#dde0ec',
    marginVertical: 0,
    paddingVertical: 0,
    maxWidth: 555,
    marginLeft: 10,
  },
  productText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    lineHeight: 1.4,
    marginTop: 12,
    color: '#282E55',
  },
  productTitle: {
    fontSize: 14,
    lineHeight: 1.9,
    marginHorizontal: 16,
    marginTop: 18,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: '#282E55',
  },
  productCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingTop: 0,
    paddingBottom: 5,
  },
  cardRight: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    order: 2,
  },
  imgProduct: {
    display: 'flex',
    height: 45,
    paddingRight: 5,
  },
  subtitleCard: {
    fontSize: 12,
    fontFamily: 'Nunito',
    maxWidth: 344,
    lineHeight: 1.3,
  },
  priceCard: {
    minHeight: 80,
    maxHeight: 140,
    maxWidth: 178,
    minWidth: 178,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#dde0ec',
    marginHorizontal: 4,
    marginVertical: 4,
    paddingBottom: 8,
    paddingTop: 8,
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 744,
    paddingBottom: 16,
    // paddingTop: 4,
    marginHorizontal: 4,
    paddingHorizontal: 6,
  },
  pricesHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#dde0ec',
  },
  imgPharmacy: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
    width: 34,
    height: 34,
    paddingHorizontal: 6,
    paddingVertical: 3,
    objectFit: 'contain',
  },
  pricesContent: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 6,
  },
  priceFull: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    paddingHorizontal: 6,
  },
  priceFraction: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    paddingTop: 4,
    paddingHorizontal: 6,
    marginBottom: 4,
    paddingBottom: 2,
  },
  address: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    paddingTop: 4,
    paddingHorizontal: 6,
    paddingBottom: 4,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#dde0ec',
  },
  normalText: {
    fontSize: 12,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    maxWidth: 344,
    lineHeight: 1.9,
    color: 'rgb(40, 46, 85)',
  },
  thinText: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: 'Nunito',
    lineHeight: 1.
  },
})

function QuotationPDF(props) {
  const {pharmacyPrices} = props
  const qrcode = new QRCode({
    content: props.link,
    width: 120,
    height: 120,
    join: true,
    ecl: 'Q',
    container: 'svg-viewbox',
  })
  const qrCodeSvgString = qrcode.svg({container: 'none'})
  const pathStartIndex = qrCodeSvgString.indexOf(' d="') + 4

  const pathEndIndex = qrCodeSvgString.indexOf('"', pathStartIndex)
  const qrCodeSvgPathString = qrCodeSvgString.slice(
    pathStartIndex,
    pathEndIndex,
  )
  const productList = [
    {
      ...props.product,
      priceList: pharmacyPrices,
    },
  ]
  const TEXT_BREAK_LENGTH = 28

  const TextUrl = ({url = ''}) => {
    return (
      <View
        style={{
          alignSelf: 'flex-start',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <Text
          style={{
            fontWeight: 'bold',
            lineHeight: 1.9,
            fontSize: 12,
            color: 'rgb(0,123,255)',
            flexWrap: 'wrap',
          }}>
          {splitUrl(url).map((line, index) => (
            <Link src={url} key={index}>
              {line}
            </Link>
          ))}
        </Text>
      </View>
    )
  }

  const splitUrl = (url) => {
    if (!url) return []
    if (url.length < TEXT_BREAK_LENGTH) return [url]
    const slice = url.slice(0, TEXT_BREAK_LENGTH)
    const rest = url.slice(TEXT_BREAK_LENGTH)
    return [slice, ...splitUrl(rest)]
  }

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.headerContainer} fixed>
          <Image
            style={styles.logo}
            src="https://s3.us-east-2.amazonaws.com/s3.yapp/logo-yapp.png"
          />
        </View>
        <View style={styles.InfoContainer}>
          <View style={styles.left}>
            <Text style={styles.title}>
              Cotización del medicamento indicado por tu médico
            </Text>
            <Text style={[styles.subtitle, {flexWrap: 'wrap'}]}>
              Revisa la cotización del medicamento que te ha prescrito tu médico
              para que elijas dónde comprar de manera informada. Escanea el
              código QR para conocer el precio del medicamento en la comuna que
              desees y además, puedes comprar con despacho a domicilio en Yapp.
            </Text>
          </View>
          <View style={styles.right}>
            <Svg style={styles.qr}>
              <Path d={qrCodeSvgPathString} fill="black" />
            </Svg>
          </View>
        </View>
        <View style={styles.border}></View>
        <Text style={styles.productTitle}>Tus medicamentos cotizados</Text>
        {productList.map((product) => (
          <View wrap>
            <View style={styles.productContainer}>
              <View style={styles.productCard}>
                <View style={styles.cardRight}>
                  <Image
                    style={styles.imgProduct}
                    source={{
                      uri: product.product_logo,
                      method: 'GET',
                      headers: {'Cache-Control': 'no-cache'},
                      body: '',
                    }}
                  />
                </View>
                <View style={styles.cardLeft}>
                  <Text style={styles.productText}>{product.product_name}</Text>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'light',
                      fontSize: 12,
                      lineHeight: 1.6,
                      color: '#282E55',
                    }}>
                    {product.formula_name}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'light',
                      fontSize: 12,
                      lineHeight: 1.6,
                      color: '#282E55',
                    }}>
                    {product.presentation}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'light',
                      fontSize: 12,
                      lineHeight: 1.6,
                      color: '#282E55',
                    }}>
                    {product.laboratory_name}
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.productTitle}>Precios en farmacias</Text>
            <View style={styles.pricesContainer} wrap>
              {product.priceList.map((price, i) => (
                <View
                style={[
                  styles.priceCard,
                  {marginBottom: 6},
                ]}>
                  <View style={styles.pricesHeader}>
                    <Image
                      style={styles.imgPharmacy}
                      source={{
                        uri: pharmacyChainUrl + price.pharmacyChainLogo,
                        method: 'GET',
                        headers: {'Cache-Control': 'no-cache'},
                        body: '',
                      }}
                    />
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: 'wrap',
                        fontSize: '12',
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'rgb(40, 46, 85)',
                      }}>
                      {price.pharmacyChainName}
                    </Text>
                  </View>
                  <View style={styles.pricesContent}>
                    <View
                      style={[
                        styles.priceFull,
                        {justifyContent: 'space-between'},
                      ]}>
                      <Text style={styles.normalText}>Precio lista</Text>
                      <Text style={styles.normalText}>
                        {_formatPrice(price.listPrice)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.priceFraction,
                      {justifyContent: 'space-between'},
                    ]}>
                    <Text
                      style={[styles.thinText, {color: 'rgb(118,118,118)'}]}>
                      Precio fraccionado
                    </Text>
                    <Text
                      style={[styles.thinText, {color: 'rgb(118,118,118)'}]}>
                      {_formatPrice(price.fractionalPrice)}
                    </Text>
                  </View>
                  {(price.pharmacyChainAddress || price.pharmacyChainUrl) && (
                    <View
                      style={[
                        styles.address,
                        {
                          justifyContent: 'space-between',
                          color: 'rgb(40, 46, 85)',
                        },
                      ]}>
                      {price.pharmacyChainAddress > 1 ||
                      price.pharmacyChainAddress === null ? (
                        <View
                          style={{
                            alignSelf: 'flex-start',
                            alignItems: 'center',
                            justifyContent: 'center',

                            flexDirection: 'column',
                          }}>
                          <TextUrl url={price.pharmacyChainUrl} />
                        </View>
                      ) : (
                        <>
                          <Text
                            style={[
                              styles.thinText,
                              {color: 'rgb(33,37,41)', flexWrap: 'wrap'},
                            ]}>
                            {price.pharmacyChainAddress}
                          </Text>
                          <Text
                            style={[
                              styles.thinText,
                              {color: 'rgb(33,37,41)', flexWrap: 'wrap'},
                            ]}>
                            Teléfono: {price.pharmacyPhone}
                          </Text>
                        </>
                      )}
                    </View>
                  )}
                </View>
              ))}
            </View>
          </View>
        ))}

        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
            *Todos los precios y disponibilidad están sujetos a cambios según
            las farmacias, te recomendamos contactar a la farmacia antes de
            dirigirte a la sucursal a comprar.
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default QuotationPDF
