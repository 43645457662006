import React from 'react'
import Modal from 'react-modal'

import IcPassOk from '../../assets/img/change_pass_check_ok.svg'
import IcPassFail from '../../assets/img/change_pass_check_error.svg'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signOut } from '../../Utils/auth'
import { clearUserState } from '../../redux/modules/user'
import { clearAppState } from '../../redux/modules/app'

function ModalSuccessChangePass(props) {
  const { modalOpenChangePass, setModalOpenChangePass, status } = props
  const history = useHistory()
  const dispatch = useDispatch()

  function closeModalChangePass() {
    setModalOpenChangePass(false)
  }
  function closeAccount() {
    signOut().then()
    dispatch(clearUserState())
    dispatch(clearAppState())
    setModalOpenChangePass(false)

    history.push('/signin')
  }

  return (
    <Modal
      isOpen={modalOpenChangePass}
      onAfterOpen={null}
      onRequestClose={closeModalChangePass}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalWelcomeHome"
      ariaHideApp={false}>
      {status ? (
        <div className="text-center">
          <img id={`gtm-webapp-change-password-success-img`} src={IcPassOk} alt="Change pass" className="mb-4" />
          <h2 className="title-primary mb-3 text-center">
            Tu contraseña ha sido
            <br /> cambiada con éxito
          </h2>
          <button
            id={`gtm-webapp-change-password-success-btn`}
            className="btn-default--small font--14 btn-next mx-auto"
            onClick={() => closeAccount()}>
            ¡Ok!
          </button>
        </div>
      ) : (
        <div className="text-center">
          <img id={`gtm-webapp-change-password-failed-img`} src={IcPassFail} alt="Change pass" className="mb-4" />
          <h2 className="title-primary mb-2 text-center">Lo sentimos</h2>
          <h2 className="txt-paragraph mb-3 text-center">
            No logramos realizar el cambio de contraseña, intentalo nuevamente.
          </h2>
          <button
            id={`gtm-webapp-change-password-failed-btn`}
            className="btn-default--small font--14 btn-next mx-auto"
            onClick={() => closeModalChangePass()}>
            Reintentar
          </button>
        </div>
      )}
    </Modal>
  )
}

export default ModalSuccessChangePass
