import './styles/styles.css'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import firebase from 'firebase/app'

import store from './redux/store'
import ThemeProvider from './ThemeProvider'
import Wrapper from './Wrapper'
import amplitude from 'amplitude-js'

import {logEvent, setUserProperties, setTag, clearWidgetSession} from './Utils/utils'
import {clearCookies} from './Utils/cookies'

if (process.env.REACT_APP_PROFILE === 'WIDGET') {
  if (!store.getState().user.identity.length) {
    clearWidgetSession()
  }
} else {
  if (process.env.REACT_APP_PROFILE !== 'MEDICAL') {
    if (JSON.parse(localStorage.getItem("LOCAL_DATA"))?.origin
        !== process.env.REACT_APP_CLIENT_NAME.toLowerCase()
      ) {
      clearCookies()
      localStorage.clear()
      sessionStorage.clear()
    }
  }
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRESTORE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRESTORE_MSG_SENDING_ID,
  appId: process.env.REACT_APP_FIRESTORE_APP_ID,
  measurementId: process.env.REACT_APP_FIRESTORE_MEASUREMENT_ID,
}

let idAmplitude = process.env?.REACT_APP_AMPLITUDE || '2e3b5344765863c70af891337b886b9c'

amplitude.getInstance().init(idAmplitude, null, {
  includeFbclid: true,
  includeGclid: true,
  includeReferrer: true,
  includeUtm: true,
  unsetParamsReferrerOnNewSession: true,
  logAttributionCapturedEvent: false,
})

const clientName = process.env?.REACT_APP_CLIENT_NAME || null

if (clientName) {
  var userProperties = {
    'Web Name': process.env?.REACT_APP_CLIENT_NAME,
  }
  setUserProperties(userProperties)

  setTag('Web Name', process.env?.REACT_APP_CLIENT_NAME)
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

logEvent('Platform Opened')

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <div className="content-app d-flex flex-column">
          <Router>
            <Wrapper />
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default App
