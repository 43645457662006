import { React, useState, useEffect, useRef } from 'react'
import './onboarding.css'
import {useHistory} from 'react-router-dom'
import on1 from '../../assets/img/onboarding1.svg'
import on2 from '../../assets/img/onboarding2.svg'
import on3 from '../../assets/img/onboarding3.svg'
import on4 from '../../assets/img/onboarding4.svg'

function Onboarding() {

  const TOTAL_SLIDES = 3

  const history = useHistory()

  const [current, setCurrent] = useState(0)
  const ref = useRef(null)
  
  const prev = () => {
    history.replace('/pro/menu')
  }

  const next = () => {
    if ( current >= TOTAL_SLIDES ) history.replace('/pro/menu')
    else setCurrent(current + 1)
  }
  
  const desired = e => {
    setCurrent(Number(e.target.id))
  }
  
  useEffect(() => {
    ref.current.style.transition = 'all 0.2s ease-in-out'
    ref.current.style.transform = `translateX(-${current}00%)`
  }, [current])


  return (
    <div className='wrapper'>
      <div className='frame'>
        
        <div className='box-container' ref={ref}>
          <div className='box'>
            <img src={on1} alt="" />
            
            <div className="box-text">
              <h2>Información y precios de medicamentos</h2>
              <h3>Comparte con tus pacientes los precios de los medicamentos en diferentes farmacias. Además podrás ver el vademécum de los medicamentos.</h3>
            </div>
          </div>
          <div className='box'>
          <img src={on2} alt="" />
            
            <div className="box-text">
              <h2>Información y precios de medicamentos</h2>
              <h3>Comparte con tus pacientes los precios de los medicamentos en diferentes farmacias. Además podrás ver el vademécum de los medicamentos.</h3>
            </div>
          </div>
          <div className='box'>
          <img src={on3} alt="" />
            
            <div className="box-text">
              <h2>Información y precios de medicamentos</h2>
              <h3>Comparte con tus pacientes los precios de los medicamentos en diferentes farmacias. Además podrás ver el vademécum de los medicamentos.</h3>
            </div>
          </div>
          <div className='box'>
          <img src={on4} alt="" />
            
            <div className="box-text">
              <h2>Información y precios de medicamentos</h2>
              <h3>Comparte con tus pacientes los precios de los medicamentos en diferentes farmacias. Además podrás ver el vademécum de los medicamentos.</h3>
            </div>
          </div>
        </div>
        <div className='button-2-container'>
          {[0, 1, 2, 3].map(num => (
            <div
              className={`button-2 ${num === current && 'active-tab'}`}
              onClick={desired}
              id={num}
              key={num}
            />
          ))}
        </div>
        <div className='button-container'>
          <div className='skipButton' onClick={prev}>Saltar</div>
          <div className='nextButton' onClick={next}>Siguiente</div>
        </div>
      
      </div>
    </div>
  )
}

export default Onboarding