import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {logEvent} from '../../Utils/utils'
import {getLabs} from '../../Utils/api'
import ImgLab from '../../assets/img/lab_disabled.svg'
import ImgLoading from '../../assets/img/loading_bar.gif'

function CardLab(props) {
  const history = useHistory()

  const goTo = (item) => {
    logEvent('Contact Laboratory Checked', {
      Origin: 'Lab Contact Info',
      'Lab Name': item?.name,
      'Lab Id': item?.id,
      'Contact Type': 'Mail',
    })
    history.push(`/laboratory/detail/${item.name}`, {
      lab_name: item.name,
      item: item,
    })
  }

  return (
    <>
      {props.data.map((item, key) => {
        if ([undefined, null, '', 0].includes(item?.accept_contact)) {
          return false
        }

        return (
          <div key={key} className="card-lab" onClick={() => goTo(item)}>
            <div className="d-flex align-items-center flex-column">
              <div style={{width:"120px", height:"120px"}} className="content-img mb-3 mt-2">
                <img
                  alt="labimg"
                  src={
                    ![undefined, null].includes(item?.image)
                      ? item.image
                      : ImgLab
                  }
                  className="img-product"
                />
              </div>
              <h3 className="title-primary--xl color-lilac mb-2 text-center">
                {item.name}
              </h3>
            </div>
          </div>
        )
      })}
    </>
  )
}

function Laboratory() {
  const history = useHistory()
  const [labs, setLabs] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchLabs = async () => {
      setLoading(true)
      let result = await getLabs()
      if (!result) {
        return history.push('/error')
      }

      setLabs(result?.data)
      setLoading(false)
    }
    fetchLabs()
  }, [])

  function goBack() {
    history.goBack()
  }

  return (
    <>
      {loading && (
        <img
          alt="labloading"
          className="w-100"
          style={localStyle.loadBar}
          src={ImgLoading}
        />
      )}
      <div className="">
        <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="txt-4--regular-pr">Volver</span>
          </div>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="content-responsive">
          <h2 className="txt-2--bold ">Contactar Laboratorios</h2>

          <div className="labs-container mt-4">
            <CardLab data={labs} />
          </div>
        </div>
      </div>
    </>
  )
}

const localStyle = {
  loadBar: {
    height: '0.5rem',
  },
}

export default Laboratory
