import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {productsUrl, pharmacyChainUrl} from '../../Utils/constants'
import {_formatPrice} from '../../Utils/tools'
import icSearch from '../../assets/img/ic_search.svg'
import icSearchSecondary from '../../assets/img/ic_search_secondary.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'
import {logEvent} from '../../Utils/utils'
import {getGenerateLink, sendMail} from '../../Utils/api'
import ModalSendPrices from '../../Components/Modals/ModalSendPrices'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import Toasts from '../../Components/Toasts/Toasts'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import {theme} from '../../Utils/constants'
import AgreementPDF from '../../Components/PDF/AgreementPDF'
import {pdf} from '@react-pdf/renderer'
import AsyncSelect from 'react-select/async'
const debounce = require('lodash/debounce')
const _ = require('lodash')

function NewAgreementDetail(props) {
  const history = useHistory()

  const [quotation, setQuotation] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pharmacyPrices, setPharmacyPrices] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [products, setProducts] = useState([])
  const [modalSendPricesState, setModalSendPricesState] = useState({
    showModal: false,
    item: {},
  })
  const [appState, setAppState] = useState({
    loading: false,
    inputValue: '',
    groupedOptions: [],
  })

  const productList = {
    message: 'OK: Success',
    data: [
      [
        {
          product_id: '2c13ccc2-cdf8-11eb-8e9b-067f',
          internal_product_id: 16858,
          product_name:
            'Colgate Periogard Enjuague Bucal Sin Alcohol X 250 Mililitros',
          product_img: '16858_v3.jpg',
          product_family: 'periogard',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 8990,
          discount_price: 5394,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '838b5002-0c1f-11ec-a606-067f',
          internal_product_id: 107150,
          product_name: 'Crema Dental elmex Anticaries X 90 Gramos',
          product_img: '107150_v3.jpg',
          product_family: 'elmex anticaries',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7490,
          discount_price: 4494,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '6908c929-430b-11ec-a606-067f',
          internal_product_id: 107904,
          product_name: 'Crema Dental elmex Sensitive X 110 Gramos',
          product_img: '107904_v3.jpg',
          product_family: 'elmex sensitive',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7490,
          discount_price: 4494,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ae8a3-430b-11ec-a606-067f',
          internal_product_id: 107905,
          product_name:
            'Cepillo de Dientes elmex Sensitive Extra Suave X 1 unidad',
          product_img: '107905_v3.jpg',
          product_family: 'elmex sensitive',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ce1f4-430b-11ec-a606-067f',
          internal_product_id: 107906,
          product_name: 'Cepillo de Dientes elmex Anticaries Suave X 1 unidad',
          product_img: '107906_v3.jpg',
          product_family: 'elmex anticaries',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '690ee574-430b-11ec-a606-067f',
          internal_product_id: 107907,
          product_name: 'Cepillo de Dientes elmex Ultra Suave X 1 unidad',
          product_img: '107907_v3.jpg',
          product_family: 'elmex sensitive',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 4990,
          discount_price: 2994,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '69110452-430b-11ec-a606-067f',
          internal_product_id: 107908,
          product_name: 'Enjuague Bucal elmex Sensitive X 400 Mililitros',
          product_img: '107908_v3.jpg',
          product_family: 'elmex sensitive',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7690,
          discount_price: 4614,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '838b54b6-0c1f-11ec-a606-067f',
          internal_product_id: 107151,
          product_name: 'Enjuague Bucal elmex Anticaries X 400 Mililitros',
          product_img: '107151_v3.jpg',
          product_family: 'elmex anticaries',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 7690,
          discount_price: 4614,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '2c17d783-cdf8-11eb-8e9b-067f',
          internal_product_id: 25190,
          product_name: 'Colgate Periogard Pasta Dental X 90 Gramos',
          product_img: '25190_v3.jpg',
          product_family: 'periogard',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 6890,
          discount_price: 4134,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '2c17d768-cdf8-11eb-8e9b-067f',
          internal_product_id: 25189,
          product_name:
            'Colgate Periogard Cepillo Dental Extra Suave X 1 Unidad',
          product_img: '25189_v3.jpg',
          product_family: 'periogard',
          laboratory_id: 26,
          laboratory_name: 'Colgate',
          list_price: 5690,
          discount_price: 3414,
          discount_value: 40,
          discount: '40% de descuento',
        },
        {
          product_id: '3395128c-4efc-11ed-b758-02a9',
          internal_product_id: 109108,
          product_name: 'Sonirem 10 Mg / Ml X 30 Mililitros',
          product_img: '109108_v3.jpg',
          product_family: 'periogard',
          laboratory_id: 59,
          laboratory_name: 'Itf-labomed',
          presentation: '30 Mililitros',
          formula_name: 'Zolpidem 10 mg / ml',
          list_price: null,
          discount_price: null,
          discount_value: null,
          discount: null,
          product_file:
            'https://s3.us-east-2.amazonaws.com/s3.yapp/pdf/programs/Cup%C3%B3n_ITF.pdf',
        },
      ],
    ],
  }

  const handleShowModal = (item) => {
    setModalSendPricesState({
      showModal: true,
      item: item,
    })
  }

  async function handleSendEmail(email, item) {
    let productData = modalSendPricesState.item

    if (!productData) {
      console.error('Product data is missing or invalid.')
      setShowError(true)
      return
    }

    try {
      const responseLink = await getGenerateLink([
        {
          quantity: 1,
          id: productData.product_id,
        },
      ])

      if (!responseLink || responseLink.message !== 'OK: Success') {
        return console.error('Error generating agreement link')
      }
      const productLink = responseLink.data

      let response = await sendMail(
        email,
        'contacto@yapp.cl',
        '',
        productData.product_id === '3395128c-4efc-11ed-b758-02a9'
          ? 'd-d604142af3c74da0ba7283d97f372974'
          : 'd-7f7012aa45a340d28cc3b9ff983d2754',
        {
          programFile: productData.product_file,
          products: [
            {
              discount_price: productData.discount_price,
              discount_value: productData.discount_value,
              internal_product_id: productData.internal_product_id,
              laboratory_id: productData.laboratory_id,
              laboratory_name: productData.laboratory_name,
              list_price: productData.list_price,
              product_id: productData.product_id,
              product_img: productsUrl + productData.product_img,
              product_family: productData.product_family,
              product_name: productData.product_name,
              product_link: productLink.url,
            },
          ],
        },
      )

      if (response.status === 204) {
        logEvent('Item Shared', {
          'Shared Type': 'Mail',
          'Element Shared': 'Discount',
          'Product Id': productData?.product_id,
          'Product Name': productData?.product_name,
          'Lab Name': productData?.laboratory_name,
        })
        setShowSuccess(true)
        setButtonDisabled(true)
        window.location.reload()
      } else if (response.status !== 204) {
        console.error(`Send mail failed with status code: ${response.status}`)
        setShowError(true)
      }
    } catch (error) {
      console.error(`Send mail failed with error: ${error}`)
      setShowError(true)
    }
  }
  const handlePrint = async (item) => {
    if (!item || !item.product_id) {
      return console.error('Error: Invalid item provided')
    }

    try {
      let blobURL = null
      const responseLink = await getGenerateLink([
        {
          quantity: 1,
          id: item.product_id,
        },
      ])

      if (!responseLink || responseLink.message !== 'OK: Success') {
        return console.error('Error generating agreement link')
      }

      const responseData = responseLink.data
      if (!responseData || !responseData.url) {
        return console.error('Error: Invalid link data received')
      }
      logEvent('Item Shared', {
        'Shared Type': 'Print',
        'Element Shared': 'Discount',
        'Product Id': item?.product_id,
        'Product Name': item?.product_name,
        'Lab Name': item?.laboratory_name,
      })
      const pdfData = await pdf(
        <AgreementPDF product={item} link={responseData.url} />,
      ).toBlob()

      if (!pdfData) {
        return console.error('Error creating PDF document')
      }

      blobURL = URL.createObjectURL(pdfData)

      if (typeof window === 'undefined') {
        return console.error('Error: Window object is not available')
      }

      window.open(blobURL)
    } catch (error) {
      console.error(`Error printing agreement: ${error.message}`)
    }
  }

  const handleSelect = (selectedOption) => {
    setSelectedProductId(selectedOption.value)
  }

  const promiseOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let prod = productList?.data[0]?.filter((product) =>
        product.product_name.toLowerCase().includes(inputValue.toLowerCase()),
      )
      if (prod.length > 0) {
        let group = prod.map((p) => {
          return {value: p.product_id, label: p.product_name, data: p}
        })
        let options = []
        options.push({
          label: 'Products',
          options: group,
        })
        setAppState({groupedOptions: options})
        callback(options)
      }
    }
  }

  const goTo = (item, productList) => {
    /* logEvent('Medication Sheet Checked', {
      Origin: 'Yapp Benefit',
      'Product Id': product.product_id,
      'Product Name': product?.product_name,
      'Lab Name': product?.laboratory_name,
      'Category Id': product?.category_id,
    }) */
  }
  function onChange(value) {
    if (value?.value === null) return setFilteredProducts(products)
    let newFilteredCards = _.filter(products, {product_id: value?.value})
    setFilteredProducts([...newFilteredCards])
  }
  const onFocus = (value) => {
    return setFilteredProducts(products)
  }
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )
  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }
  const borderColor = theme?.border_color
    ? `1px solid ${theme?.border_color}`
    : props.themeColor
    ? `1px solid var(${props.themeColor})`
    : '1px solid #6578FF'

  const searchProductsStyle = {
    control: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: theme.border_radius,
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: borderColor,
      '&:hover': {
        border: borderColor,
      },
      ':before': {
        backgroundImage: `url(${icSearchSecondary})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '" "',
        display: 'block',
        marginRight: 0,
        height: '41px',
        width: '41px',
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '20px',
      zIndex: 1049,
      border: '1px solid #E9E6FE',
      paddingTop: '20px',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '14px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, {isFocused}) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      padding: '4px 10px',
      position: 'relative',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
      },
      ':before': isFocused
        ? {
            backgroundImage: `url(${icEnter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '22px',
            backgroundPosition: 'right',
            content: '"Seleccionar"',
            color: '#828282',
            fontSize: '8px',
            display: 'flex',
            flexColumn: 'row',
            alignItems: 'center',
            marginRight: 8,
            height: '15px',
            width: '74px',
            position: 'absolute',
            right: '0',
            overflow: 'visible',
            top: '50%',
            transform: 'translateY(-50%)',
          }
        : {},
    }),
  }

  return (
    <>
      <div className="py-3">
        <div
          id={'gtm-medication-sheet-result'}
          className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
          <div
            className="breadcrumbs c-pointer"
            onClick={() => history.goBack()}>
            <a className="d-flex align-items-center" href="#/">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span
              className="txt-4--regular-pr"
              style={{color: 'var(--theme-color)'}}>
              Volver
            </span>
          </div>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="content-responsive">
          {loading ? (
            <img
              src={loadingYapp}
              alt="loading"
              className="loading-yapp--alternative mx-auto mt-5"
            />
          ) : (
            <>
              <div className="d-flex fle-row align-items-center mb-3">
                <div className="brand-img-new-details">
                  <img
                    src={history?.location?.state?.lab_image}
                    alt={`nombre del producto`}
                    className="brand-agreements-img"
                  />
                </div>
                <div className="ml-14 overflow-hidden">
                  <h3 className="semi-bold line-16 font--18 text-ellipsis--2 font--16 m-0">
                    {history?.location?.state?.name}
                  </h3>
                </div>
                {selectedProductId && (
                  <button
                    className="btn-link txt-3--regular-pr ml-auto"
                    onClick={() => setSelectedProductId(null)}>
                    Mostrar todos
                  </button>
                )}
              </div>
              <div className="mb-4">
                <AsyncSelect
                  className="search-product"
                  id="gtm-webapp-search-products-legacy"
                  isClearable
                  onFocus={onFocus}
                  onChange={handleSelect}
                  noOptionsMessage={() => 'Sin resultados...'}
                  placeholder="Busca tu producto"
                  formatGroupLabel={formatGroupLabel}
                  defaultOptions={appState.groupedOptions}
                  loadOptions={loadSuggestions}
                  styles={searchProductsStyle}
                />
              </div>
              {productList.data[0]
                .filter((item) => {
                  const params = new URLSearchParams(history?.location?.state)
                  const paramProductId = params.get('product_id')
                  const paramLabId = history?.location?.state?.lab_id
                  return (
                    (!paramProductId || item.product_id === paramProductId) &&
                    (!selectedProductId ||
                      item.product_id === selectedProductId) &&
                    (!paramLabId || item.laboratory_id === paramLabId)
                  )
                })
                .map((item, key) => {
                  return (
                    <div
                      className="agreement-card mb-3"
                      key={item?.internal_product_id}>
                      <div className="agreement-card-content">
                        <img
                          src={productsUrl + item.product_img}
                          alt="product"
                        />
                        <div className="flex-1">
                          <h4 className="title-primary--xxl m-0 w-100 mb-2">
                            {item?.product_name}{' '}
                          </h4>
                          <p className="txt-4--bold mb-1 font--16">
                            {item?.laboratory_name}
                          </p>

                          <div className="d-flex flex-row">
                            {item?.discount === null ? (
                              <p className="txt-4--bold b mt-1 mb-0 font--16">
                                {item.formula_name}{' '}
                              </p>
                            ) : (
                              <p className="txt-4--bold b mt-1 mb-0 mr-4 font--16">
                                {_formatPrice(item.discount_price)}
                              </p>
                            )}
                            {item?.description !== undefined ? (
                              <p className="txt-4--regular b mt-1 mb-0 font--16">
                                {item.description}
                              </p>
                            ) : null}

                            <p
                              className={`${
                                item?.discount === null
                                  ? 'd-none'
                                  : 'txt-4--regular mb-0 bg-primary-color text-white rounded-light padding-light'
                              }`}>
                              -40%
                            </p>
                          </div>
                          {item.product_id ===
                          '3395128c-4efc-11ed-b758-02a9' ? (
                            <p className="txt-4--bold b mt-1 mb-0 mr-4 font--16">
                              {item.presentation}{' '}
                            </p>
                          ) : (
                            <p className="txt-4--bold b mt-1 mb-0 mr-4 font--16">
                              {_formatPrice(item.list_price)}
                            </p>
                          )}
                          {item?.product_link !== undefined ? (
                            <div>
                              <ButtonYapp
                                small
                                className=" m-1 txt-3--bold white-space-nowrap"
                                title={'Solicitar muestra'}
                                onClick={() => {
                                  window.open(item.product_link, '_blank')
                                  logEvent('Item Shared', {
                                    'Shared Type': 'typeform',
                                  })
                                }}
                              />

                              <p className="text--4--regular">
                                *Máximo 1 unidad
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="agreement-card-footer">
                        {item?.product_link === undefined ? (
                          <>
                            <ButtonYapp
                              small
                              className=" m-1 txt-3--bold white-space-nowrap"
                              title={'Enviar por correo'}
                              onClick={() => handleShowModal(item)}
                            />
                            <ButtonYapp
                              small
                              className=" m-1 txt-3--bold"
                              disabled={false}
                              title={'Imprimir'}
                              stroke={true}
                              color={'--color-primary'}
                              onClick={() =>
                                item.product_id ===
                                '3395128c-4efc-11ed-b758-02a9'
                                  ? window.open(item.product_file, '_blank')
                                  : handlePrint(item)
                              }
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </div>
      {modalSendPricesState.showModal && (
        <ModalSendPrices
          modalSendPricesState={modalSendPricesState}
          setModalSendPricesState={setModalSendPricesState}
          item={modalSendPricesState.item}
          showError={showError}
          setShowError={setShowError}
          onClickButton={(email, item) => handleSendEmail(email, item)}
        />
      )}

      <Toasts
        text="Correo enviado exitosamente"
        timer="3000"
        icon="icon-check"
        stateToasts={showSuccess}
        setStateToasts={setShowSuccess}
      />
    </>
  )
}
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundImage: `url(${icSearch})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 22,
    width: 22,
  },
})

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '1em',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.8rem',
    borderColor: 'transparent',
    fontFamily: 'var(--font-light)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? null
        : null,
      color: '#282e55',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : null),
      },
    }
  },
  input: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1em',
    fontFamily: 'var(--font-light)',
    color: '#282e55',
    height: '3rem',
  }),
  placeholder: (styles) => ({
    ...styles,
    ...dot(),
    fontSize: '1.2em',
    fontFamily: 'var(--font-light)',
    color: '#BDBDBD',
  }),
  singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
}
export default NewAgreementDetail
