import React, {useEffect, useState, useReducer} from 'react'
import QRCode from 'qrcode-svg'
import {useSelector} from 'react-redux'
import {sendMail} from '../../Utils/api'
import {useLocation, useHistory} from 'react-router-dom'
import Input from '../../Components/Forms/Input'
import {_validateMail} from '../../Utils/tools'
import {logEvent} from '../../Utils/utils'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import TextArea from '../../Components/Forms/TextArea'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import Toasts from '../../Components/Toasts/Toasts'
import ModalError from '../../Components/Modals/ModalError'
import Whatsapp from '../../assets/img/whatsapp.png'

function LaboratoryDetail(props) {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [phone, setPhone] = useState(null)
  const [labEmail, setLabEmail] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const userInfo = useSelector((state) => state.user.userInfo)
  const identity = useSelector((state) => state.user.identity)
  const initialsValues = {
    fullName: ' ',
    email: '',
    message: ' ',
  }
  const [formValues, setFormValues] = useReducer(
    (curVals, newVals) => ({...curVals, ...newVals}),
    initialsValues,
  )
  const {fullName, email, message} = formValues

  useEffect(() => {
    if (location.state.item) {
      setPhone(location.state.item?.phone)
      setLabEmail(location.state.item?.email)
    }
  }, [location])

  function goBack() {
    history.goBack()
  }

  function handleFormChange(event) {
    const {name, value} = event.target
    setFormValues({[name]: value})
    if (fullName.length > 0 && email.length > 0 && message.length >= 10) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }

  const sendEmail = async () => {
    let validate = _validateMail(labEmail)
    if (validate) {
      setLoading(true)
      let response = await sendMail(
        labEmail,
        'contacto@yapp.cl',
        '',
        'd-38393492a87445069f909f8ab30c5ae8',
        {
          identity,
          formValues,
        },
      )

      logEvent('Contact Request Checked', {
        'Laboratory contacted': location.state.item?.name,
        Email: location.state.item?.email,
      })

      if (response.status === 204) {
        setShowSuccess(true)
        setLoading(false)
        setButtonDisabled(true)
      } else {
        setShowError(true)
        setLoading(false)
      }
    } else {
      setLabEmail(null)
    }
  }

  const qrData = `https://wa.me/${phone}?text=Hola%2C%20me%20gustar%C3%ADa%20contactarme%20con%20un%20representante%20del%20laboratorio`

  const qrcode = new QRCode({
    content: qrData,
    padding: 0,
    width: 120,
    height: 120,
    color: '#000000',
    background: '#ffffff',
    join: true,
    ecl: 'Q',
    container: 'svg-viewbox',
  })

  const qrCodetoSvg = qrcode.svg()

  return (
    <div>
      <div className="content-wrap px-0">
        <div className="content-responsive d-flex justify-content-between py-1">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="txt-4--regular-pr">Volver</span>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center w-100 py-5">
          <img src={loadingYapp} alt="loading" height={100} />
        </div>
      ) : (
        <>
          <div className="flex-grow-1 content-responsive">
            <div className="mb-3 mt-2">
              <div className="d-flex align-items-center mb-0">
                <h3 className="txt-2--bold">{location.state.item?.name}</h3>
              </div>
              {![null, undefined, ''].includes(location.state.item.phone) && (
                <div>
                  <p className="txt-3--bold m-0 mb-1">
                    {['+569'].includes(
                      location.state.item.phone.substring(0, 4),
                    )
                      ? 'Escanea el código QR para acceder al whatsapp o llama al número telefónico'
                      : null}
                  </p>

                  {location.state.item.link ? (
                    <p className="txt-3--bold m-0 mb-4">
                      Página web:
                      <span className="txt-3--bold-pr c-pointer">
                        <a
                          className="color-lilac"
                          href={location.state.item.link}
                          target="_blank"
                          rel="noopener noreferrer">
                          {location.state.item.link}
                        </a>
                      </span>
                    </p>
                  ) : null}
                  <p className="txt-3--bold m-0 mb-4">
                    Número telefónico:
                    <span className="txt-3--bold-pr c-pointer">
                      {location.state.item.phone}
                    </span>
                  </p>
                </div>
              )}
            </div>
            {![null, undefined, ''].includes(location.state.item.phone) &&
              ['+569'].includes(location.state.item.phone.substring(0, 4)) && (
                <div
                  className="content-card-light position-relative mb-3"
                  style={{width: '140px', height: '140px', margin: '0 auto'}}>
                  <div className="img-corner">
                    <img alt="logo-whatsapp" src={Whatsapp} />
                  </div>
                  <div className="m-0 mb-3">
                    <div dangerouslySetInnerHTML={{__html: qrCodetoSvg}} />
                  </div>
                </div>
              )}
          </div>
          <div className="content-responsive mb-3 mt-2">
            <h3 className="txt-3--bold-db">Quiero que me contacten</h3>
            <h3 className="txt-4--regular-db font--14 m-0 mb-3">
              Por favor llena el formulario para que el laboratorio te contacte
            </h3>
          </div>

          <div className="content-responsive">
            <div className="mb-2">
              <Input
                type="text"
                value={fullName}
                name="fullName"
                id="gtm-webapp-name-register"
                error={fullName.length === 0 && {message: '*Requerido'}}
                label="Nombre y apellido"
                placeholder="Nombre y apellido"
                onChange={handleFormChange}
              />
            </div>
            <div className="mb-2 ">
              <Input
                id="gtm-webapp-validation-email-input"
                value={email}
                name="email"
                error={
                  !isValid && {
                    message:
                      email.length <= 0 ? '*Obligatorio' : '*Correo inválido',
                  }
                }
                label="Correo electrónico"
                placeholder="Correo electrónico"
                onChange={handleFormChange}
              />
            </div>
            <TextArea
              name="message"
              value={message}
              error={
                (message.length === 0 || message.length < 10) && {
                  message: '*Requerido y mínimo 10 caracteres',
                }
              }
              id="message"
              onChange={handleFormChange}
              label="Cuéntanos en que podemos ayudarte..."
              rows="5"
            />
            <div className="d-flex justify-content-center mt-4 mb-4">
              <ButtonYapp
                className="px-5"
                disabled={buttonDisabled}
                color={'--color-primary'}
                big
                title="Enviar"
                onClick={sendEmail}
              />
            </div>
          </div>
        </>
      )}
      <ModalError
        isOpen={showError}
        setIsOpen={setShowError}
        imgName="icErrorServer"
        text="Ocurrió un error, por favor vuelve a intentarlo"
        btnText="Aceptar"
        className="mx-4"
      />
      <Toasts
        text="Correo enviado exitosamente"
        timer="3000"
        icon="icon-check"
        stateToasts={showSuccess}
        setStateToasts={setShowSuccess}
      />
    </div>
  )
}

export default LaboratoryDetail
