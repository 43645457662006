import React from 'react'

import AppleBrand from '../../assets/img/apple.svg'
import FacebookBrand from '../../assets/img/facebook.svg'
import GoogleBrand from '../../assets/img/google.svg'

function ButtonsRss({ ...props }) {
  return (
    <>
      <button
        id={`gtm-webapp-rss-btn-${props.rrss}`}
        onClick={props.onClick}
        className={`btn-rrss 
        ${props.className} ${props.rrss === 'apple' ? 'btn-apple' : ''} ${props.rrss === 'facebook' ? 'btn-facebook' : ''
          } ${props.rrss === 'google' ? 'btn-google' : ''}
      `}>
        {props.rrss === 'apple' && <img src={AppleBrand} alt="Apple" />}
        {props.rrss === 'facebook' && (
          <img src={FacebookBrand} alt="Facebook" />
        )}
        {props.rrss === 'google' && <img src={GoogleBrand} alt="Google" />}
      </button>
    </>
  )
}

export default ButtonsRss
