import React from 'react'
import Modal from 'react-modal'

import IcAdmin from '../../assets/img/avatar_admin.svg'

function ModalAccountAdmin(props) {
  const { modalAccount, setModalAccount, userInfo, onSignOut, goTo } = props
  function closeModalAccount(params) {
    setModalAccount(false)
  }

  return (
    <>
      <Modal
        isOpen={modalAccount}
        onAfterOpen={null}
        onRequestClose={closeModalAccount}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalReported"
        ariaHideApp={false}>
        <button id={`gtm-webapp-header-account-admin-closed-btn`} className="btn-circle-close" onClick={closeModalAccount}>
          <i id={`gtm-webapp-header-account-admin-closed-icon`} className="icon-close"></i>
        </button>
        <div className="d-flex flex-column align-items-center justify-content-center mb-4 pb-2">
          <img src={userInfo?.profile?.picture ? userInfo?.profile?.picture : IcAdmin} alt="" className="img-avatar-account mb-3" />
          <h2 className="title-primary--xxl text-center mb-0">
            {userInfo?.profile?.first_name} {userInfo?.profile?.last_name}
          </h2>
          <p className="title-primary--light text-center mb-3">
            {userInfo?.profile?.email}
          </p>
          <hr className="hr--gray w-100" />
          {userInfo?.signInMethod === 'password' &&
            <p
              id={`gtm-webapp-header-account-admin-change-password-btn`}
              className="title-primary--light color-lilac c-pointer text-center mt-3"
              onClick={goTo}>
              Cambiar contraseña
            </p>}
        </div>

        <button
          id={`gtm-webapp-header-account-admin-signout-btn`}
          className="btn-default--small font--14 btn-account mx-auto"
          onClick={onSignOut}>
          Cerrar sesión
        </button>
      </Modal>
    </>
  )
}

export default ModalAccountAdmin
