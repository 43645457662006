import React, {useContext, useEffect, useState, useReducer, useRef} from 'react'
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  useAccordionToggle,
} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Modal from 'react-modal'

import CustomSelect from '../../Components/Forms/CustomSelect'
import RadioGroup from '../../Components/Forms/RadioGroup'
import {changeInitialLoading} from '../../redux/modules/app'
import {getNewReleases} from '../../Utils/api'
import {getMonthByIndex} from '../../Utils/tools'
import {cleanText, logEvent} from '../../Utils/utils'
import productImg from '../../assets/img/prescription.png'
const _orderBy = require('lodash/orderBy')

function HeaderToggle({title, eventKey, callback}) {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className="d-flex " onClick={() => decoratedOnClick()}>
      <div className="d-flex align-items-center w-100">
        <p className="title-primary--xxl-regular">{title}</p>
      </div>
      <div className="d-flex align-items-center">
        <i
          className={`icon-chevron-down color-theme ${
            isCurrentEventKey ? 'icon-up icon-reverse' : ''
          }`}></i>
      </div>
    </div>
  )
}

function updateFilters(state, action) {
  if (!action) {
    return {category: null, release: null}
  }

  let new_state = {...state}
  new_state[action.field] = action.filter

  return new_state
}

function NewDrugs(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [optionsData, setOptionsData] = useState([])
  const [modal, setModal] = useState(false)
  const [expandedItem, setExpandedItem] = useState('0')
  const [filters, changeFilters] = useReducer(updateFilters, {})

  useEffect(() => {
    dispatch(changeInitialLoading(true))
    getNewReleases()
      .then((resp) => {
        let category = resp?.data?.category.map((item) => {
          return {
            label: item?.category_name,
            value: item?.id,
          }
        })
        setOptionsData([...[{label: 'todas', value: null}], ...category])

        let data = resp?.data?.result

        if (data) {
          data = data.map((item) => {
            return {
              ...item,
              detail: _orderBy(item.detail, 'month', 'desc'),
            }
          })

          setData(data)
        }
        dispatch(changeInitialLoading(false))
      })
      .catch((err) => {
        console.error(err)
        dispatch(changeInitialLoading(false))
      })

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function goBack() {
    history.goBack()
  }

  function goToProductDetail(product, year) {
    logEvent('Medication Sheet Checked', {
      Origin: 'New Releases',
      'Product Id': product?.id,
      'Product Name': product?.name,
      'Lab Name': product?.laboratory_name,
    })

    if (product.vademecum_id) {
      history.push(`/product/detail/${product.id}`, {
        product_id: product.id,
        product: {
          ...product,
          product_id: product.id,
          product_name: product?.name,
        },
        external_vademecum: false,
      })
    } else {
      setModal(true)
    }
  }

  const itemsRef = useRef([])

  const slide = (shift, index, year) => {
    let nav = document.getElementById('nav' + year + (index + 1))
    nav.scrollLeft += shift
  }

  const releaseTypes = [
    'Nueva Molécula',
    'Nueva Indicación',
    'Nueva Presentación',
  ]
  function filteredReleases() {
    return data
      .map((year) => ({
        year: year.year,
        detail: year.detail
          .map((month) => ({
            month: month.month,
            detail: month.detail.filter(
              (itemProduct) =>
                (!filters.release ||
                  itemProduct?.product?.group_new_releases ===
                    filters.release) &&
                (!filters.category ||
                  itemProduct?.product?.category_id === filters.category),
            ),
          }))
          .filter(({month, detail}) => detail.length),
      }))
      .filter(({year, detail}) => detail.length)
  }

  return (
    <>
      <div className="content-responsive d-flex justify-content-between py-2 py-md-4 py-lg-4">
        <div className="breadcrumbs c-pointer" onClick={goBack}>
          <div className="d-flex align-items-center">
            <i className="icon-chevron-right icon-reverse"></i>
          </div>
          <span
            className="txt-4--regular-pr"
            style={{color: 'var(--theme-color)'}}>
            Volver
          </span>
        </div>
      </div>
      <div className="flex-grow-1 content-responsive">
          <div className="py-3 h-100 content-new-drugs d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">
              <h3 className="title-primary--xxl-regular mb-2 d-inline-block">
                Nuevos lanzamientos
              </h3>
              <button className="btn-link txt-3--regular-pr" onClick={(value) => changeFilters()}>
                Borrar filtros
              </button>
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-block">
                <RadioGroup
                  name="release"
                  options={releaseTypes}
                  onChange={(value) =>
                    changeFilters({field: 'release', filter: value})
                  }
                  value={filters.release}
                />
              </div>
            </div>
            <div className="content-filter-new-drugs z-index-9999">
              <CustomSelect
                options={optionsData}
                onChange={(value) =>
                  changeFilters({field: 'category', filter: value.value})
                }
                value={filters.category}
              />
            </div>

            {data.length > 0 && <hr className="hr--lilac toggle-default" />}
            <div className="content-card-info-pro-new-release z-index-99">
              <div className="toggle-default line-lilac mb-4">
                <Accordion
                  defaultActiveKey={expandedItem}
                  onSelect={(e) => {
                    if (e !== null) {
                      setExpandedItem(e)
                    }
                  }}>
                  {filteredReleases().map((year, yearIndex) => (
                    <Card key={yearIndex}>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Col}
                          className=""
                          variant="link"
                          eventKey={`${yearIndex}`}>
                          <HeaderToggle
                            title={year.year}
                            eventKey={`${yearIndex}`}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={`${yearIndex}`}>
                        <Card.Body className="inner-padding">
                          {year?.detail.map((month, monthIndex) => {
                            return (
                              <div
                                ref={(e) => (itemsRef.current[yearIndex] = e)}
                                className=""
                                key={monthIndex}>
                                <div className="d-flex justify-content-between w-100 mb-2">
                                  <p
                                    className={`txt-3--regular-db mb-2  ${
                                      monthIndex !== 0 && 'mt-2 mt-md-3 '
                                    }`}>
                                    {getMonthByIndex(month.month)}
                                  </p>
                                  <div className="carousel-nav">
                                    <button
                                      onClick={() =>
                                        slide(-140, monthIndex, year?.year)
                                      }
                                      className="carousel-prev ">
                                      <i className="icon-chevron-left notification-icon "></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        slide(+140, monthIndex, year?.year)
                                      }
                                      className="carousel-next">
                                      <i className="icon-chevron-right notification-icon"></i>
                                    </button>
                                  </div>
                                </div>

                                <div
                                  className={'content-new-drugs'}
                                  id={`nav${year?.year}${monthIndex + 1}`}>
                                  {month?.detail.map(
                                    (release, releaseIndex) => (
                                      <div
                                        id={`gtm-weapp-new-drugs-produt-${releaseIndex}`}
                                        className="product-detail c-pointer"
                                        key={releaseIndex}
                                        onClick={() =>
                                          goToProductDetail(
                                            release?.product,
                                            year.year,
                                          )
                                        }>
                                        <div className="product-detail-content-img d-flex justify-content-center text-center">
                                          <img
                                            src={
                                              release?.product?.image
                                                ? release?.product?.image
                                                : productImg
                                            }
                                            className="product-detail-img mx-auto"
                                            alt="Paracetamol 100 mg/mL x 15 mL"
                                          />
                                        </div>
                                        <div className="content-txt">
                                          <p className="title-primary--xl ">
                                            {cleanText(release?.product?.name)}
                                          </p>
                                          <p className="txt-paragraph--small line-14">
                                            {release?.product?.composition}
                                          </p>
                                          <p className="txt-paragraph--small line-14">
                                            {release?.product?.presentation}
                                          </p>
                                          <p className="txt-paragraph--small line-14">
                                            {release?.laboratory}
                                          </p>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            )
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
       
      </div>
      <Modal
        isOpen={modal}
        onAfterOpen={null}
        onRequestClose={() => null}
        className="modal-custom"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalSendMail"
        ariaHideApp={false}>
        <button className="btn-circle-close" onClick={() => setModal(false)}>
          <i className="icon-close"></i>
        </button>
        <div>
          <p className="title-primary--xxl">Próximamente</p>
        </div>
      </Modal>
    </>
  )
}

export default NewDrugs
