import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {logEvent} from '../../Utils/utils'
import {_formatPrice} from '../../Utils/tools'
import {pharmacyChainUrl, prescriptionUrl} from '../../Utils/constants'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import proIconLoading from '../../assets/img/proIconLoading.svg'
import ModalSendPrices from '../../Components/Modals/ModalSendPrices'
import {getProductPricesById, getGenerateLink, sendMail} from '../../Utils/api'

import Toasts from '../../Components/Toasts/Toasts'
import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import QuotationPDF from '../../Components/PDF/QuotationPDF'
import {pdf} from '@react-pdf/renderer'

function PriceFinderDetail(props) {
  const history = useHistory()

  const [quotation, setQuotation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pharmacyPrices, setPharmacyPrices] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [modalSendPricesState, setModalSendPricesState] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [programFile, setProgramFile] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setQuotation(null)
        if (props.results && props.results.product_id) {
          const productWithProgram = props.productList?.find(
            (p) => p?.product_id === props?.results?.product_id,
          )
          if (productWithProgram) {
            setProgramFile(productWithProgram?.program_file)
          }
          const result = await getProductPricesById(props.results.product_id)
          if (result?.data?.prices) {
            setPharmacyPrices(result.data.prices)
          }
        } else {
          history.push('/error')
        }
      } catch (ignore) {}
      setLoading(false)
    }

    fetchData()
  }, [props.results, props.productList])

  async function showQuotation(isPDF, email) {
    setLoading(true)

    try {
      let blobURL = null
      let responseLink = quotation?.responseLink
      if (!quotation?.responseLink) {
        responseLink = await getGenerateLink([
          {
            quantity: 1,
            id: props?.results?.product_id,
          },
        ])
        if (responseLink?.message === 'OK: Success') {
          responseLink = responseLink?.data?.url
          setQuotation({...quotation, responseLink: responseLink})
        }
      }
      if (quotation?.blobURL && isPDF) {
        window.open(quotation.blobURL)
        return
      }
      logEvent('Item Shared', {
        'Shared Type': isPDF ? 'Print' : 'Mail',
        'Element Shared': 'Price',
        'Product Id': props?.results?.product_id,
        'Product Name': props.results?.product_name,
        'Lab Name': props.results?.laboratory_name,
      })
      if (isPDF) {
        const blob = await pdf(
          <QuotationPDF
            pharmacyPrices={pharmacyPrices}
            product={props?.results?.product}
            link={responseLink}
          />,
        ).toBlob()

        blobURL = URL.createObjectURL(blob)

        setQuotation({
          responseLink: responseLink,
          blobURL: blobURL,
        })

        window.open(blobURL)
      } else {
        setModalSendPricesState(false)
        let productData = props?.results?.product
        let response = await sendMail(
          email,
          'contacto@yapp.cl',
          '',
          'd-068ace49332d4522bc9c3815c4e7cd7b',
          {
            quotation_url: responseLink,
            program_file: programFile ? prescriptionUrl + programFile : null,
            products: [
              {
                product_name: productData?.product_name,
                product_img: productData?.product_logo,
                formula_name: productData?.formula_name,
                presentation: productData?.presentation,
                laboratory_name: productData?.laboratory_name,
                quotation: pharmacyPrices.map((c) => ({
                  pharmacy_name: c.pharmacyChainName,
                  pharmacy_address: c.pharmacyChainAddress,
                  pharmacy_phone: c.pharmacyPhone,
                  pharmacy_url: c.pharmacyChainUrl,
                  pharmacy_logo: pharmacyChainUrl + c.pharmacyChainLogo,
                  list_price: _formatPrice(c.listPrice),
                  fractional_price: _formatPrice(c.fractionalPrice),
                })),
              },
            ],
          },
        )
        if (response.status === 204) {
          setShowSuccess(true)
          setButtonDisabled(true)
        } else if (response.status !== 204) {
          setShowError(true)
        }
      }
    } catch (ignore) {
    } finally {
      setLoading(false)
      setModalSendPricesState(false)
      setShowError(false)
    }
  }

  return (
    <>
      <div className="flex-grow-1">
        <div>
          {loading ? (
            <div className="text-center image-center">
              <h2 className="title-primary--3xl-bold mt-3">
                En breve verás tu búsqueda
              </h2>
              <img
                src={loadingYapp}
                alt="loading"
                className="loading-yapp--pro m-0"
              />
              <img
                src={proIconLoading}
                alt="Loading icon"
                className="maxw-290"
              />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between mt-2 mb-4">
                {pharmacyPrices?.length > 0 && (
                  <>
                    <ButtonYapp
                      className="px-2 m-1 txt-3--bold h-34"

                      disabled={buttonDisabled}
                      title={'Enviar por correo'}
                      color={'--color-primary'}
                      onClick={() => setModalSendPricesState(true)}
                    />
                    <ButtonYapp
                      className="m-1 txt-3--bold h-34"
                      disabled={false}
                      title={'Imprimir'}
                      stroke={true}
                      color={'--color-primary'}
                      onClick={() => showQuotation(true)}
                    />
                  </>
                )}
              </div>
              <div className="mb-4 mt-4">
                {pharmacyPrices?.length > 0 && (
                  <h4 className="txt-2--bold line-14 mt-3 mb-1">
                    Precios en farmacias
                  </h4>
                )}
                <div className="content-card-result-pharmacy px-0">
                  {pharmacyPrices?.length > 0 ? (
                    pharmacyPrices.map((item, key) => (
                      <div key={key} className="card-result-pharmacy">
                        <div className="header-card-result-pharmacy">
                          <img
                            src={`${process.env.REACT_APP_REPO_IMG_PHARM_LOGO_SERV_URL}${item.pharmacyChainLogo}`}
                            alt="pharmacy"
                            className="card-result-pharmacy-img"
                          />
                          <h4 className="txt-3--bold m-0 ml-2">
                            {item.pharmacyChainName}
                          </h4>
                        </div>
                        <div className="content-card-result-pharmacy">
                          <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                            <p className="txt-3--bold">Precio lista</p>
                            <p className="txt-4--bold text-right">
                              {_formatPrice(item.listPrice)}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <p className="txt-4--regular">Precio fraccionado</p>
                            <p className="txt-5--regular text-right">
                              {_formatPrice(item.fractionalPrice)}{' '}
                              {item.typeFractionation}
                            </p>
                          </div>
                        </div>
                        {(item.pharmacyChainUrl ||
                          item.pharmacyChainAddress) && (
                          <div className="border-top-gray mt-2">
                            {item.pharmacyChainAddress > 1 ||
                            item.pharmacyChainAddress === null ? (
                              <a
                                href={item.pharmacyChainUrl}
                                target="_blank"
                                rel='noreferrer'
                                className="txt-4--regular">
                                {item.pharmacyChainUrl}
                              </a>
                            ) : (
                              <>
                              <p className="txt-4--regular">
                                {item.pharmacyChainAddress}
                              </p>
                              <p className="txt-4--regular">
                              Teléfono: {item.pharmacyPhone}
                            </p></>
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="color-gray-4 text-center">
                        No hay resultados disponibles 😢
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalSendPrices
        modalSendPricesState={modalSendPricesState}
        setModalSendPricesState={setModalSendPricesState}
        showError={showError}
        setShowError={setShowError}
        onClickButton={(email) => showQuotation(false, email)}
      />

      <Toasts
        text="Correo enviado exitosamente"
        timer="3000"
        icon="icon-check"
        stateToasts={showSuccess}
        setStateToasts={setShowSuccess}
      />
    </>
  )
}

export default PriceFinderDetail
