import {useState} from 'react'
import {searchProductsStyleSecondary} from '../ProductSearch/styles'
import {examsAutocomplete} from '../../Utils/api'
import AsyncSelect from 'react-select/async'

const _get = require('lodash/get')
const debounce = require('lodash/debounce')
const _ = require('lodash')

function SearchTypeToggle(props) {
  const [resultSearch, setResultSearch] = useState(null)
  const [appState, setAppState] = useState({
    loading: false,
    exams: [],
    inputValue: '',
    groupedOptions: [],
  })

  const placeholder = 'Buscar examen'

  const onChangeInput = (newValue) => {
    try {
      if (newValue?.data) {
        newValue.data = {...newValue?.data, resultSearch}
      }
      return props.onClickSearch(newValue)
    } catch (ignore) {}
  }

  const promiseOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let exams = await examsAutocomplete(inputValue)
      if (_get(exams, 'data')) {
        setResultSearch({
          result_id: _get(exams, 'result_id'),
        })

        let group = exams.data.map((c) => {
          return {
            value: c.code_fonasa,
            label: c.code_fonasa + ' - ' + c.exam_name,
            data: c,
            type_exam: c.type_exam,
          }
        })
        group = _.groupBy(group, (c) => c.type_exam)

        const propertyNames = Object.keys(group)

        let options = propertyNames.map((el) => {
          return {
            label: el,
            options: group[el],
          }
        })

        setAppState({groupedOptions: options})
        callback(options)
      } else {
        setResultSearch(null)
        callback([])
      }
    }
  }

  const localDebounce = debounce(
    (inputValue, callback) => promiseOptions(inputValue, callback),
    200,
  )

  const loadSuggestions = (inputValue, callback) => {
    localDebounce(inputValue, callback)
  }

  return (
    <div>
      <h5 className="title-primary mt-3 mb-3">
        Busca el nombre o código de tu examen
      </h5>
      <AsyncSelect
        id="gtm-webapp-search-exams"
        isClearable
        cacheOptions
        // onFocus={onFocusInput}
        onChange={onChangeInput}
        noOptionsMessage={() => 'Sin resultados...'}
        placeholder={placeholder}
        controlShouldRenderValue={false}
        // formatGroupLabel={formatGroupLabel}
        defaultOptions={appState.groupedOptions}
        loadOptions={loadSuggestions}
        styles={searchProductsStyleSecondary(props)}
        // value={appState.inputValue}
      />
    </div>
  )
}

export default SearchTypeToggle
