import React from 'react'
import Modal from 'react-modal'
import {base_url} from '../../Utils/constants'
import IcPillDoctor from '../../assets/img/i_pill_doctor_yay.svg'
import {
  //buttons
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  // icons
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share'
import {logEvent} from '../../Utils/utils'

const ModalShareWithColleges = (props) => {
  const {modalState, setModalState, url} = props

  const changeModalState = (close, status = false) => {
    logEvent('Item Shared', {'Shared Type': 'Web Médicos', Success: status})
    setModalState(close)
  }

  return (
    <Modal
      isOpen={modalState}
      onRequestClose={() => changeModalState(!modalState)}
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="Modal"
      ariaHideApp={false}>
      <button
        className="btn-circle-close"
        onClick={() => changeModalState(false)}>
        <i className="icon-close"></i>
      </button>
      <img
        src={IcPillDoctor}
        alt="Compartir"
        height="150"
        className="mb-3 mt-4"
      />
      <h3 className="title-primary--xl text-center">
        Selecciona una opción para compartir:
      </h3>

      <div className="d-flex flex-row justify-content-center mb-1">
        <EmailShareButton
          url={url}
          subject={'Yapp Chile'}
          body="Visita nuestro sitio web y cotiza los medicamentos que necesitas!"
          className="mx-2 mb-4 mt-2 btn-circle-shared btn-ic-email-share">
          <EmailIcon size={32} round />
        </EmailShareButton>
        <FacebookShareButton
          onClick={() => {
            changeModalState(!modalState, true)
          }}
          url={url}
          quote={
            'Visita nuestro sitio web y cotiza los medicamentos que necesitas!'
          }
          className="mx-2 mb-4 mt-2 btn-circle-shared">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <WhatsappShareButton
          onClick={() => {
            changeModalState(!modalState, true)
          }}
          url={url}
          title={'Yapp Chile'}
          separator="  "
          className="mx-2 mb-4 mt-2 btn-circle-shared">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </Modal>
  )
}

export default ModalShareWithColleges
