import { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Application, DatePicker } from 'react-rainbow-components'

import { _isWeekEnd, formatDate } from '../../Utils/tools'
import ButtonYapp from '../Buttons/ButtonYapp'
import ImageAlert from '../../assets/img/alert.svg'
import ImageOk from '../../assets/img/change_pass_check_ok.svg'
import { icon_edit } from '../../assets/icons/icon-svg'


const moment = require('moment-timezone')

function ModalSuscription(props) {
  const { subscriptionData, showModal, setShowModal, type, nextStep, defaultMessage } = props
  const datePickerRef = useRef(null)

  const [ message, setMessage ] = useState(defaultMessage)
  const [ messageIcon, setMessageIcon ] = useState(ImageAlert)
  const [ showDateSelection, setShowDateSelection ] = useState(false)
  const [ invalidDate, setInvalidDate ] = useState('*Debes seleccionar tu proxima fecha de entrega.')
  const [ deliveryDate, setDeliveryDate ] = useState(null)
  const [ minDeliveryDate, setMinDeliveryDate ] = useState(null)
  const [ barTitle, setBarTitle] = useState('¿Deseas guardar tu suscripción?')
  const [ actionTitle, setActionTitle] = useState('Guardar')

  useEffect(() => {
    if (type === 'pause') {
      setBarTitle('¿Deseas pausar tu suscripción?')
      setActionTitle('Si, pausar')
    }
    else if (type === 'cancel') {
      setBarTitle('¿Deseas cancelar tu suscripción?')
      setActionTitle('Si, cancelar')
    }
    else if (type === 'active') {
      setBarTitle('¿Deseas activar tu suscripción?')
      setActionTitle('Si, activar')
    }
    else {
      setBarTitle('¿Deseas guardar tu suscripción?')
      setActionTitle('Guardar')
    }
  }, [type])

  useEffect(() => {
    if (subscriptionData?.shortest_delivery_date) {
      let day = moment.utc(subscriptionData?.shortest_delivery_date).date()
      let month = moment.utc(subscriptionData?.shortest_delivery_date).month()
      let year = moment.utc(subscriptionData?.shortest_delivery_date).year()
      let minDeliveryDate = new Date(year, month, day)
      let today = new Date()
    
      minDeliveryDate = minDeliveryDate.getTime() < today.getTime()
        ? today : minDeliveryDate
      setMinDeliveryDate(minDeliveryDate)
    }
  }, [subscriptionData])

  const onSaveSubscriptionDate = () => {
    let subscriptionEdited = Object.assign({}, subscriptionData)
    subscriptionEdited.delivery_date = deliveryDate

    setBarTitle('¿Deseas guardar tu suscripción?')
    setShowDateSelection(false)
    nextStep(subscriptionEdited)
  }

  const onAccept = () => {
    if (type === 'active') {
      setBarTitle('Selecciona la fecha de tu próxima entrega')
      setShowDateSelection(true)
    }
    else {
      nextStep()
    }
  }

  const checkDeliveryDate = (newDate) => {
    if (_isWeekEnd(newDate)) {
      setInvalidDate('Lo sentimos, no contamos con despacho los fines de semana.')
    }
    else if (props.holidayList.some(holiday => holiday === formatDate(newDate)
    )) {
      setInvalidDate('Lo sentimos, debes seleccionar un día hábil.')

    } else {
      setDeliveryDate(newDate)
      setInvalidDate(null)
    }
  }

  return (
    <Modal
      isOpen={ showModal }
      onAfterOpen={null}
      onRequestClose={ () => setShowModal(false) }
      className="modal-custom"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalSuscriptionHome"
      ariaHideApp={false}
    >
      <button className="btn-circle-close" onClick={() => setShowModal(false) }>
        <i className="icon-close"></i>
      </button>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={ showDateSelection ? ImageOk : messageIcon } alt="Alerta" className="mb-4" />
        <h4 className="txt-3--bold text-center  mb-3">
          { barTitle }
        </h4>
        { message && (
          <p className="title-primary--regular text-center mb-30">
            { message }
          </p>
        )}

        { showDateSelection
          ? <div className="mb-2" onClick={() => datePickerRef.current.click()}>
              <div className="content-add-prescription-modal pr-2">
                <div className="position-relative w-100">
                  <label className="label-delivery color-gray-4">
                    Fecha de entrega
                  </label>
                  <p className="txt-5--regular-db mt-12">
                    { deliveryDate
                      ? moment(deliveryDate).format('DD-MM-YYYY')
                      : ''
                    }
                  </p>
                </div>
                <div className="d-flex align-items-center color-theme icon-edit-datepiker">
                  { icon_edit }
                </div>
              </div>

              { (invalidDate) &&
                <div className="d-flex justify-content-center align-items-center">
                  <p className="txt-6--regular-er mt-12">
                    { invalidDate }
                  </p>
                </div>
              }

              <div className="d-flex justify-content-center align-items-center">
                <ButtonYapp
                  disabled={ deliveryDate === null }
                  title={ 'Guardar' }
                  big
                  className="mt-3 mb-3 mx-1 w-120"
                  onClick={ () => onSaveSubscriptionDate() }
                />
              </div>
            </div>
          : <div className="d-flex justify-content-center align-items-center">
              <ButtonYapp
                title={ 'No' }
                className="mb-3 mx-2 w-120"
                stroke
                big
                onClick={() => setShowModal(false)}
              />
              <ButtonYapp
                title={ actionTitle }
                big
                className="mb-3 mx-1 w-120"
                onClick={ () => onAccept() }
              />
            </div>
        }

        <Application theme={{ rainbow: { palette: { brand: '#00a19b' }}}} >
          <DatePicker
            ref={ datePickerRef }
            style={{display: 'none'}}
            className="modal-datepicker-custom"
            value={ new Date(subscriptionData.delivery_date) }
            minDate={ minDeliveryDate }
            onChange={ checkDeliveryDate }
            formatStyle="large"
            locale={'es-CL'}
            isCentered={true}
          />
        </Application>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    holidayList: state.app?.holidayList
  }
}

export default connect(mapStateToProps, null)  (ModalSuscription)
