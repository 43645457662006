import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../redux/modules/user'
import { loginCustomToken } from '../../Utils/auth'

function LoginToken() {
  const params = useParams()
  const dispatch = useDispatch()
  const marshallUrl = 'https://marshall.yapp.cl/sign_in'

  useEffect(() => {
    loginByToken()
  },[params?.fcmToken])

  const loginByToken = async () => {
    localStorage.setItem('fromYappApp', navigator.userAgent.includes('YappApp'))

    if (![null, undefined, ''].includes(params?.fcmToken)) {
      let login = await loginCustomToken(params.fcmToken)
      if (login?.status === 'success') {
        dispatch(fetchUser())
        let loginData = '?login=true'
        if (login?.data?.identity)
          loginData += `&rut=${login?.data?.identity}`

        window.location.replace(marshallUrl + loginData)
      }
      else {
        window.location.replace(marshallUrl)
      }
    }
  }

  return (
    <div style={{ paddingTop: '30px', textAlign: 'center' }}>
      Espera un momento mientras cargamos tu contenido...
    </div>
  )
}

export default LoginToken
