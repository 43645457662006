import {useContext, useState} from 'react'
import {
  Col,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap'
import './PharmacyCard.css'
import {_formatPrice} from '../../Utils/tools'
import ModalPreview from '../../Components/Modals/ModalPreview'
import ButtonYapp from '../Buttons/ButtonYapp'
import {useSelector} from 'react-redux'

function PharmacyCard(props) {
  const {
    keyValue,
    firstKey,
    pharmacyEl,
    total,
    subtotal,
    sendData,
    deliveryTime,
    bioequivalentRemplace,
    withSubscription,
  } = props
  const clientName = useSelector((state) => state.app.client_name)
  const [isCurrentEventKey, setIsCurrentEventKey] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const [modalImage, setModalImage] = useState([])
  const showQuotationDeliveryCost = useSelector(
    (state) => state?.app?.config?.show_quotation_delivery_cost,
  )
  const goToBioequivalent = ({formula_id, product_id, name, logo, lab}) => {
    let item = {
      count: 1,
      formula_id,
      product_id,
      product_name: name,
      product_logo: logo,
      laboratory_name: lab,
    }

    bioequivalentRemplace(item)
  }

  const handleClose = () => {
    setShowPreview(false)
    setModalImage({})
  }

  function onClickShowPreview(product_logo) {
    setModalImage(product_logo)
    setShowPreview(true)
  }

  function ContextAwareToggle({eventKey, callback, subtotal}) {
    const currentEventKey = useContext(AccordionContext)
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    )

    setIsCurrentEventKey(currentEventKey)


   
    return (
      <Accordion.Toggle
        onClick={() => decoratedOnClick()}
        as={Col}
        variant="link">
        <div className="d-flex flex-column align-items-center w-100">
          {pharmacyEl.quotation_detail?.map((bag) => {
            return (
              <>
                <div key={bag.price_id + 'price'} className="d-flex justify-content-between w-100 mb-2">
                  <div className="d-flex align-items-center">
                    <i className="icon-circle-check color-theme-secondary mr-2" />
                    <p className="txt-5--regular-db m-0">{bag.product.name}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className={`txt-5--regular-db ml-2 m-0 ${bag.client_discount && "has-benefit"}`}>
                      {_formatPrice(bag.price * bag.quantity)}
                    </span>
                  </div>
                </div>

                { bag.client_discount > 0 && (
                  <div key={bag.price_id + 'benefit'}
                    className="d-flex justify-content-between w-100 mb-2">
                    <div className="d-flex align-items-center">
                      <p className="txt-5--regular-db color-insurance">
                        {process.env.REACT_APP_CLIENT_BENEFIT}
                      </p>
                      <div className="txt-5--regular-db benefit-badge background-insurance">
                        <p>
                          - {bag.client_benefit}%
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="txt-5--regular-db color-insurance">
                        - {_formatPrice(bag.quantity * bag.client_discount)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )
          })}
          <div className="d-flex mt-2 mb-2">
            <span className="txt-5--regular-pr">Ver detalles</span>{' '}
            <i
              className={`icon-chevron-down color-lilac ml-1 ${
                isCurrentEventKey ? 'icon-up' : ''
              }`}></i>
          </div>
        </div>
      </Accordion.Toggle>
    )
  }

  return (
    <>
      <div className="pharmacy-card pb-2">
        {firstKey === 0 && keyValue === 0 && !withSubscription && (
          <div className="alert-price">
            <i className="icon-flag-pharmacy color-theme-secondary pharmacy-flag-best-price mr-1" />
            <p className="m-0 pl-3">Tu opción más conveniente</p>
          </div>
        )}
        <div className="pharmacy-card--header">
          <div className="pharmacy-card--header-left">
            <img
              src={pharmacyEl.pharmacy_chain_logo}
              alt={pharmacyEl.pharmacy_chain_name}
              className="pharmacy-card--header-img"
            />
            <h5 className="pharmacy-card--header-title ">
              {pharmacyEl.pharmacy_chain_name}
            </h5>
          </div>
          <div className="pharmacy-card--header-right">
            <div className="content-price">
              <i className="icon-circle-price"></i> {_formatPrice(subtotal)}
            </div>
          </div>
        </div>
        <div className="pharmacy-card--center">
          <Accordion>
            <Card id={`gtm-webapp-quotation-pharmacy-detail${keyValue}`}>
              <Card.Header>
                <ContextAwareToggle
                  eventKey={keyValue + 1}
                  subtotal={subtotal}
                />
              </Card.Header>
              <Accordion.Collapse eventKey={keyValue + 1}>
                <Card.Body>
                  <p className="txt-bold txt-secondary mb-3">Medicamentos</p>
                  {pharmacyEl.quotation_detail?.map((item, key) => {
                    return (
                      <div key={key} className="pb-4 content-detail">
                        <img
                          src={item.product.logo}
                          alt="product"
                          width="50"
                          className="mb-2 content-detail-img"
                          onClick={() =>
                            onClickShowPreview(item.product.logo)
                          }
                        />
                        <div className="d-flex align-items-start mb-3">
                          <div>
                            <p className="txt-paragraph">{item.quantity}</p>
                          </div>
                          <div className="ml-2 ">
                            <p className="txt-paragraph mb-1">
                              {item.product.name}
                            </p>
                            <p className="txt-5--regular-g1">
                            {item.product.formula_name}
                            </p>
                            {/* <p className="txt-paragraph--small line-14 color-disabled">35 Comprimidos recubiertos</p> */}
                            <p className="txt-5--regular-g1">
                              {item.product.lab}
                            </p>

                            <p className="txt-4--bold-db mt-3 mb-1">
                              {_formatPrice(item.price)}
                            </p>
                            <p className="txt-4--regular-g1">
                              {'Precio fraccionado ' +
                                _formatPrice(item.fractional_price)}
                            </p>
                            {item.bioequivalent === 0 && (
                              <p className="txt-paragraph color-disabled mt-3">
                                No hay bioequivalentes para este producto
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          {item.bioequivalent === 1 && (
                            <ButtonYapp
                              id={`gtm-webapp-btn-show-bioequivalent-${key}`}
                              onClick={() => goToBioequivalent(item.product)}
                              title={'Ver alternativas similares'}
                              color={'--theme-color-secondary'}
                              small
                              stroke
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          {pharmacyEl.sell === 1 ? (
            <Card>
              <Card.Header>
                <div className="d-flex">
                  <div className="d-flex w-100 justify-content-between mb-3">
                    {!deliveryTime && (
                      <div className="d-flex content-icon-pharmacy">
                        <i className="icon-circle-time mr-2" />
                        <p className="txt-paragraph">
                          {pharmacyEl.delivery.message}
                        </p>
                      </div>
                    )}
                    {showQuotationDeliveryCost && (
                      <p className="txt-paragraph">
                        + {_formatPrice(pharmacyEl.delivery?.cost)}
                      </p>
                    )}
                    {deliveryTime && (
                      <p className="txt-paragraph color-theme line-16">
                        {pharmacyEl.delivery.message} <br />
                        <b>Beneficio</b> para pacientes {clientName}
                      </p>
                    )}
                  </div>
                </div>
              </Card.Header>
            </Card>
          ) : (
            <div className="pt-0"></div>
          )}
        </div>

        <div className={`pharmacy-card--bottom ${pharmacyEl.sell === 0 ? 'bt-gray-5' : ''}`}>
          {pharmacyEl.sell === 0 ? (
            <ButtonYapp
              className="mx-auto px-2"
              disabled
              small
              onClick={() => null}
              title="No disponible para la compra en Yapp"
            />
          ) : (
            <ButtonYapp
              id={`gtm-webapp-quotation-pharmacy-btn-buy${keyValue}`}
              className="mx-auto px-5"
              small
              onClick={() => sendData(pharmacyEl)}
              color={'--theme-color'}
              title="Comprar"
            />
          )}
        </div>
        <ModalPreview show={showPreview} onClose={handleClose} img={modalImage} />
      </div>
    </>
  )
}

export default PharmacyCard
