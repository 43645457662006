import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import ProductSearch from '../../Components/ProductSearch'
import ProductSearchPrincipleActive from '../../Components/ProductSearch/SearchPrincipleActive'
import {ENUM_MENU} from '../../Utils/constants'
import {addEventNameWithParams, logEvent} from '../../Utils/utils'

function PriceFinder(props) {
  const history = useHistory()
  const [productId, setProductId] = useState(null)
  const [product, setProduct] = useState(null)

  const onClickSearch = (newValue) => {
    if (
      newValue?.data?.is_product === 1 ||
      [undefined, null].includes(newValue?.data?.is_product)
    ) {
      if (newValue?.value) {
        let id = newValue.data.product_id
        addEventNameWithParams('price_finder_select_product', {
          product_id: id,
          product_name: newValue?.label,
        })
        setProductId(id)
        setProduct(newValue.data)
        clickShowDetail(id, newValue?.data)
      } else {
        console.log('Error value')
      }
    } else {
      logEvent('AP Price Finder Checked', {
        'Active Principle Id': newValue?.data?.active_principle_id,
        'Active Principle Name': newValue?.data?.active_principle_name,
      })
      addEventNameWithParams('price_finder_active_principle', {
        product_name: newValue?.label,
        active_principle_id: newValue?.data?.active_principle_id,
      })

      history.push('/pro/price_finder_result', {
        product_input: newValue,
      })
    }
  }

  const clickShowDetail = (id, productData) => {
    addEventNameWithParams('show_medication_sheet', {
      from: 'price_finder',
      product_name: productData?.product_name,
      product_id: id,
    })

    history.push('/pro/price_finder_product', {
      product_id: id,
      product: productData,
    })
  }

  function goBack() {
    history.goBack()
  }
  return (
    <>
      <div className="py-3 px-3">
        <div
          id="gtm-webapp-price-finder"
          className="content-responsive mx-auto d-flex justify-content-between">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="title-primary--regular line-14">Volver</span>
          </div>
        </div>
      </div>
      <div id="gtm-price-finder" className="flex-grow-1 px-3">
        <div className="content-responsive mx-auto pt-4">
          <h2 className="title-primary--xxl ">Buscador de precios</h2>
          <p className="txt-paragraph line-16">
            Encuentra información sobre el costo de medicamentos en distintas
            farmacias y encuentra las opciones mas convenientes.
          </p>
          <div className="">
            <ProductSearchPrincipleActive
              principleActive={true}
              request_from={ENUM_MENU.price_finder}
              className="hpt-16"
              onClickSearch={onClickSearch}
              isNotShow={true}
              vidal={2}
              placeholder={'Busca por marca o principio activo'}
            />
          </div>
          {/* <button id='gtm-webapp-btn-price-finder' className='btn-default--small btn-next btn-primary mb-4 mx-auto' disabled={productId === null} onClick={clickShowDetail}>Buscar precios</button> */}
        </div>
      </div>
    </>
  )
}

export default PriceFinder
