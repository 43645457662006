import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Input from '../../Components/Forms/Input'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changeConfig, changeInitialLoading, changeLoginCheckoutLoading, setFormDataAccount, setProvider } from '../../redux/modules/app'
import { fetchUser, setUserExtraInfo } from '../../redux/modules/user'
import { getCartPreArmed, getConfigSite, usedProfessional } from '../../Utils/api'
import { getRedirectResult, loginAnonymoust, loginApple, loginCustomToken, loginFacebook, loginGoogle, signIn, signOut } from '../../Utils/auth'
import { getShoppingCart, saveShoppingCart } from '../../Utils/service'
import { _validateMail } from '../../Utils/tools'
import { logEvent, setUserProperties } from '../../Utils/utils'
import ButtonYapp from '../Buttons/ButtonYapp'
import ButtonsRss from '../Forms/ButtonRss'
import ModalError from '../../Components/Modals/ModalError'

const _get = require('lodash/get')

function ModalLoginCheckout(props) {
  const {showModalLogin, setShowModalLogin} = props

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)

  const isProfessional = process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'
  const [modalServerError, setModalServerError] = useState(false)
  const [modalInternetError, setModalInternetError] = useState(false)
  const [modalSocialNetworkError, setModalSocialNetworkError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const skipLogin =
    location.state?.requiredValidate === true
      ? 'FALSE'
      : process.env.REACT_APP_ONBOARDING_REQUIRED === 'TRUE'
      ? 'TRUE'
      : process.env.REACT_APP_SKIP_LOGIN

  const userInfo = useSelector((state) => state.user.userInfo)
  const lastPage = useSelector((state) => state.app.lastPage)
  const customToken = location.state?.custom_token

  function closeModalLogin () {
    setShowModalLogin(false)
  }

  useEffect(async () => {
    try {
      setIsLoading(true)
      dispatch(changeInitialLoading(true))
      let response = await getRedirectResult()
      if (response) {
        actionNext(response, true)
      } else {
        if (
          skipLogin === 'TRUE' ||
          (process.env.REACT_APP_EXTERNAL_LOGIN === 'TRUE' &&
            [undefined, null, 'NULL'].includes(
              process.env.REACT_APP_EXTERNAL_VALID_TYPE,
            )) ||
          process.env.REACT_APP_PROFILE === 'INSURANCE'
        ) {
          if (userInfo?.signInMethod) {
            if (process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE') {
              setIsLoading(false)
              dispatch(changeInitialLoading(false))
              return history.push('/pro/menu')
            }
            await loadCarPreArmed()
            setIsLoading(false)
            dispatch(changeInitialLoading(false))
            history.push('/shopping_bag/checkout')
          } else {
            let response = {}
            await signOut()
            if (customToken) {
              response = await loginCustomToken(customToken)
            } else {
              response = await loginAnonymoust()
            }
            actionNext(response)
          }
          return
        } else {
          dispatch(changeInitialLoading(false))
          setIsLoading(false)
        }
      }
    } catch (ignore) {
      signOut().then()
      setIsLoading(false)
      dispatch(changeInitialLoading(false))
    }
  }, [])

  const loadCarPreArmed = async () => {
    try {
      const urlSearchParams = new URLSearchParams(location.search)
      let cartPreArmedId = urlSearchParams.get('CartPreArmedId')

      if (urlSearchParams) {
        let count = 0
        for (var value of urlSearchParams.values()) {
          switch (value) {
            case 'whatsapp':
            case 'codigo':
            case 'saxenda':
              count++
              break

            default:
              break
          }
        }
        if (count === 3) {
          cartPreArmedId = process.env.REACT_APP_CART_PRE_ARMED_ID
        }
      }

      if (cartPreArmedId) {
        let carPreArmed = await getCartPreArmed(cartPreArmedId)
        if (carPreArmed?.data?.length > 0) {
          let arrFirestore = await getShoppingCart()
          arrFirestore = arrFirestore.concat(
            carPreArmed.data.map((c) => {
              return {
                ...c,
                resultSearch: {
                  result_id: cartPreArmedId,
                  request_from: 'car-preamed',
                },
              }
            }),
          )
          await saveShoppingCart(arrFirestore)
        }
      }
    } catch (ignore) {}
  }

  const login = async (provider) => {
    try {
      dispatch(changeLoginCheckoutLoading(true))
      dispatch(changeInitialLoading(true))
      dispatch(setProvider(provider))
      setLoginError(false)
      switch (provider) {
        case 'email':
          if (_validateMail(email)) {
            setIsLoading(true)
            let response = await signIn(email, password, 'email')
            actionNext(response, true)
          } else {
            dispatch(changeInitialLoading(false))
            setLoginError(true)
          }
          break
        case 'google':
          loginGoogle()
          break
        case 'facebook':
          loginFacebook()
          break
        case 'apple':
          loginApple()
          break
        default:
          break
      }
    } catch (ignore) {}
  }

  const actionNext = async (response, skipConfig = false) => {
    try {
      switch (response.status) {
        case 'success':
          dispatch(fetchUser())
          let user_id = response?.data?.uid
          let localData = {}
          let res = null
          let arrBag = null
          if (skipConfig === false || isProfessional) {
            res = await getConfigSite()
            if (!res) {
              return history.push('/error')
            }
            localData = {
              hash: user_id,
              origin: process.env.REACT_APP_CLIENT_NAME.toLowerCase(),
              site: res?.data,
              client_config: {
                delivery_time: _get(
                  res?.data,
                  'config[0].config.delivery_time',
                ),
              },
            }
          } else {
            arrBag = await getShoppingCart()
            localData = localStorage.getItem('LOCAL_DATA')
            localData = JSON.parse(localData)
            localData.hash = user_id
            setShowModalLogin(false)
          }

          localStorage.setItem('LOCAL_DATA', JSON.stringify(localData))

          if (skipConfig !== false && arrBag?.length > 0) {
            await saveShoppingCart(arrBag)
          }
          try {
            let userProperties = null

            if (isProfessional && response?.data?.rut) {
              usedProfessional(response?.data?.rut).then((professional) => {
                let user_type = _get(professional, 'data[0].user_type', 'user')
                window.gtag('set', 'user_type', user_type)
                userProperties = {
                  'User Type': user_type,
                  'Account Type': response?.data?.provider,
                }

                setUserProperties(userProperties)
              })
            } else {
              window.gtag('set', 'user_type', 'user')
              userProperties = {
                'User Type': skipLogin === 'TRUE' ? 'anonymous' : 'user',
                'Account Type': response?.data?.provider,
              }

              setUserProperties(userProperties)
            }

            let accountType = response?.data?.provider
              ? response?.data?.provider.split('.')[0]
              : 'anonymous'
            if (accountType !== 'anonymous') {
              logEvent('Logged In', {
                'Account Type': accountType,
                'Logged In Success': true,
                'User Type': 'Existing User',
              })
            }
          } catch (error) {
            console.log('Error set user gtag.')
          }

          if (skipConfig === false || isProfessional) {
            dispatch(changeConfig(res))
            dispatch(setUserExtraInfo(_get(res?.data, 'info', {})))
            let route = res?.data?.route
            if (route === '/search') {
              await loadCarPreArmed()
              if (location?.label && location?.nro) {
                route =
                  '/search/shopping_bag/' +
                  Math.floor(Math.random() * Math.floor(100000000000000000000))
                return history.replace(route, {product: null, from: 'page'})
              }
            }
            history.replace(route, {site: res?.data})
          } else if (process.env.REACT_APP_PROFILE === 'PATIENT') {
            dispatch(changeInitialLoading(false))
            await dispatch(setFormDataAccount())
            history.push(lastPage)
          } else {
            dispatch(changeInitialLoading(false))
            await dispatch(setFormDataAccount())
            history.push('/shopping_bag/checkout')
          }
          break
        case 'no-account': // data en authentication
          dispatch(fetchUser())
          logEvent('Logged In', {
            'Account Type': response?.data?.provider.split('.')[0],
            'Logged In Success': false,
            'User Type': 'New User',
          })
          history.replace(
            process.env.REACT_APP_IS_PROFESSIONAL === 'TRUE'
              ? '/register_validate'
              : '/register',
          )
          break
        case 'no-professional': // data en la bd / data en authentication
          dispatch(fetchUser())
          logEvent('Logged In', {
            'Account Type': response?.data?.provider.split('.')[0],
            'Logged In Success': false,
            'User Type': 'Existing User',
          })
          history.replace('/register_validate')
          break
        default:
          dispatch(changeInitialLoading(false))
          setLoginError(true)
          setIsLoading(false)
          break
      }
    } catch (error) {
      console.log(error)
      dispatch(changeInitialLoading(false))
      setLoginError(true)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={showModalLogin}
      onAfterOpen={null}
      onRequestClose={closeModalLogin}
      className="modal-custom-login"
      overlayClassName="modal-custom-bg"
      contentLabel="ModalReported"
      ariaHideApp={false}>
      <button className="btn-circle-close" onClick={closeModalLogin}>
        <i className="icon-close"></i>
      </button>
      <div className="flex-grow-1">
        <div className="content-responsive-sm px-1 px-md-0">
          <div className="maxw-300 mx-auto mt-5">
            <h2 className={`txt-1--bold-db text-center`}>{ props.title }</h2>
          </div>
          <div className="maxw-300 mx-auto mt-2">
            <p className="txt-3--regular-db text-center mb-3">Continúa con</p>
          </div>
          <div className="d-flex justify-content-between maxw-584 mx-auto">
            <ButtonsRss
              rrss="apple"
              className="mr-3"
              onClick={() => login('apple')}
            />
            <ButtonsRss
              rrss="facebook"
              className="mr-3"
              onClick={() => login('facebook')}
            />
            <ButtonsRss rrss="google" onClick={() => login('google')} />
          </div>
          <hr className="maxw-584 hr-o"  />
          <div className="maxw-320 mx-auto">
            {loginError && (
              <div
                id={'gtm-webapp-login-alert-message'}
                className="alert-message">
                <i
                  id={'gtm-webapp-login-alert-message-icon'}
                  className="icon-info-2"></i>
                <p className="txt-6--bold-er ml-2">
                  La cuenta o contraseña que has introducido no es válida.
                  Intenta con una cuenta o contraseña diferente.
                </p>
              </div>
            )}
            <form>
              <div className="mb-3">
                <Input
                  id={'gtm-webapp-login-email'}
                  // error={{message: ''}}
                  label="Correo electrónico"
                  placeholder="Correo electrónico"
                  doChange={(value) => setEmail(value)}
                />
              </div>
              <div className="mb-2">
                <Input
                  id={'gtm-webapp-login-password'}
                  showPass
                  label="Contraseña"
                  placeholder="Contraseña"
                  // error={{message: ''}}
                  doChange={(value) => setPassword(value)}
                />
              </div>
            </form>
            <div className="d-flex justify-content-end">
              <p
                className="title-primary--regular color-lilac c-pointer mb-35"
                onClick={() => history.push('/forgot_password')}>
                Olvidé mi contraseña
              </p>
            </div>
            <ButtonYapp
              id={'gtm-webapp-login-btn'}
              className="btn-default--small btn-default font--14 size-content-responsive-btn mx-auto w-100 mb-4"
              onClick={() => login('email')}
              title={'Ingresar'}
            />
            <ModalError
              isOpen={modalServerError}
              setIsOpen={setModalServerError}
              imgName="icErrorServer"
              title="Lo sentimos"
              text="No podemos conectar con los servidores de Yapp en este momento..."
              btnText="Volver a intentar"
            />
            <ModalError
              isOpen={modalInternetError}
              setIsOpen={setModalInternetError}
              imgName="icErrorInternet"
              title="¡Ups!"
              text="Te has quedado sin internet. Por favor verifica tu conexión..."
              btnText="Volver a intentar"
            />
            <ModalError
              isOpen={modalSocialNetworkError}
              setIsOpen={setModalSocialNetworkError}
              imgName="icErrorServer"
              title="¡Ups!"
              text="No hemos podido conectar con tu cuenta"
              btnText="Volver a intentar"
              className="mx-4"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalLoginCheckout