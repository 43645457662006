import icSearch from '../../assets/img/ic_search.svg'
import icEnter from '../../assets/img/ic_enter_search.svg'

const searchProductsStyle = {
  control: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: '#fff',
    borderRadius: '8px',
    height: '38px',
    boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
    paddingLeft: '44px',
    zIndex: 1050,
    border: '1px solid #E9E6FE;',
    '&:hover': {
      border: isFocused ? '1px solid #E9E6FE;' : '1px solid #E9E6FE;',
    },
    ':before': {
      backgroundImage: `url(${icSearch})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: '21px',
      width: '21px',
      position: 'absolute',
      left: '12px',
      overflow: 'visible',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#828282',
    fontSize: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#BDBDBD',
    fontSize: '12px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0',
    fontSize: '12px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0 0 8px 8px',
    boxShadow: '0 4px 10px rgba(0,0,0,.1)',
    borderWidth: '0',
    top: '23px',
    zIndex: 1051,
    border: '1px solid #E9E6FE',
  }),
  menuList: (provided) => ({
    ...provided,
    color: '#282E55',
    fontSize: '10px',
  }),
  menuPortal: (provided) => ({
    ...provided,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#828282',
    fontSize: '12px',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: 'rgba(196, 196, 196, 0.3)',
      fontSize: '10px',
    },
    ':before': isFocused
      ? {
        backgroundImage: `url(${icEnter})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '22px',
        backgroundPosition: 'right',
        content: '"Seleccionar"',
        color: '#828282',
        fontSize: '8px',
        display: 'flex',
        flexColumn: 'row',
        alignItems: 'center',
        marginRight: 8,
        height: '15px',
        width: '74px',
        position: 'absolute',
        right: '0',
        overflow: 'visible',
      }
      : {},
  }),
}

const searchProductsStyleSecondary = (props) => { 
  return {
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '22px',
      height: '42px',
      boxShadow: '0 rgba(0,0,0,.1)',
      paddingLeft: '21px',
      zIndex: 1050,
      border: props.themeColor
        ? `1px solid var(${props.themeColor})`
        : '1px solid #6578FF',
      '&:hover': {
        border: isFocused
          ? props.themeColor
            ? `1px solid var(${props.themeColor})`
            : '1px solid #6578FF'
          : props.themeColor
            ? `1px solid var(${props.themeColor})`
            : '1px solid #6578FF',
      },
      ':before': {
        backgroundColor: props.themeColor ? `var(${props.themeColor})` : '#6578FF',
        borderRadius: '30px',
        content: '"\\e900"',
        fontFamily: 'icomoon',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        marginRight: 0,
        height: '41px',
        width: '41px',
        position: 'absolute',
        left: 'calc(100% - 40px)',
        overflow: 'visible',
        zIndex: 999,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#BDBDBD',
      fontSize: '12px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '75%',
      whiteSpace: 'nowrap',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 4px 10px rgba(0,0,0,.1)',
      borderWidth: '0',
      top: '23px',
      zIndex: 1051,
      border: '1px solid #E9E6FE',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#282E55',
      fontSize: '10px',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#828282',
      fontSize: '12px',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.3)' : null,
      color: isSelected ? 'var(--color-dark-blue-ds)' : '#828282',
      ':active': {
        ...provided[':active'],
        backgroundColor: 'rgba(196, 196, 196, 0.3)',
        fontSize: '10px',
        color: '#828282'
      },
      ':before': isFocused
        ? {
          backgroundImage: `url(${icEnter})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '22px',
          backgroundPosition: 'right',
          content: '"Seleccionar"',
          color: '#828282',
          fontSize: '8px',
          display: 'flex',
          flexColumn: 'row',
          alignItems: 'center',
          marginRight: 8,
          height: '15px',
          width: '74px',
          position: 'absolute',
          right: '0',
          overflow: 'visible',
        }
        : {},
    }),
  }
}

export { searchProductsStyle, searchProductsStyleSecondary }