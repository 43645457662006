import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import Routes from './router'
import Header from './Components/Header'
import Loading from './Components/Loading'
import NewFooter from './Components/NewFooter'
import NewSidebar from './Components/NewSidebar'
import NewHeader from './Components/NewHeader'
import {pathArr, pathArrPro} from './Utils/constants'
import {useDispatch, useSelector} from 'react-redux'
import {setModalTypeFormsState} from './redux/modules/app'
import {authStateChanged} from './Utils/auth'
import ModalTypeForms from './Components/Modals/ModalTypeForms'
import Iframe from 'react-iframe'
import TypePopup from './Components/TypeForm/TypePopup'
import SignOut from './Components/SignOut/SignOut'

const Wrapper = (props) => {
  const fromYappApp = localStorage.getItem('fromYappApp') === 'true'

  const location = useLocation()
  const dispatch = useDispatch()
  const modalTypeFormsState = useSelector((state) => state.app.modalTypeForm)
  const typeFormUrl = useSelector((state) => state.app.typeFormUrl)

  useEffect(() => {
    dispatch(authStateChanged())
  }, [])

  const openTypeForm = () => {
    dispatch(setModalTypeFormsState(!modalTypeFormsState))
  }

  return (
    <>
      {(location.pathname !== '/') && (process.env.REACT_APP_SIGN_OUT === 'TRUE') && !fromYappApp ? 
        (
          <SignOut />
        )
        :
        (null)
      }
      {(process.env.REACT_APP_PROFILE === 'WIDGET') &&
          (
            <TypePopup />
          )
        }
      {typeFormUrl && (
        <ModalTypeForms
          btnClose={true}
          modalState={modalTypeFormsState}
          setModalState={openTypeForm}>
          <Iframe
            url={typeFormUrl}
            width="100%"
            id="myId"
            className="content-type-form"
          />
        </ModalTypeForms>
      )}
      {(!pathArr.includes(location.pathname) &&
        process.env.REACT_APP_PROFILE === 'PATIENT') ||
      (!pathArrPro.includes(location.pathname) &&
        ['MEDICAL', 'WIDGET'].includes(process.env.REACT_APP_PROFILE)) ? (
        <>
          {!fromYappApp && process.env.REACT_APP_HIDE_MENU !== 'TRUE' && (
            <NewHeader />
          )}
          <div className="d-flex">
            {process.env.REACT_APP_HIDE_MENU !== 'TRUE' && <NewSidebar />}
            <div
              className={
                (process.env.REACT_APP_HIDE_MENU === 'TRUE' && 'mt-0') +
                ' content-navegation'
              }>
              <div className={'content-scroll-navigation'} style={{height: "100vh", backgroundColor:"var(--color-background-primary)"}}>
                <Loading />
                <Routes />
                {process.env.REACT_APP_HIDE_MENU !== 'TRUE' && (
                  <div className="new-footer">
                    {!fromYappApp && <NewFooter />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!fromYappApp && process.env.REACT_APP_HIDE_MENU !== 'TRUE' && (
            <Header />
          )}
          <Loading />
          <Routes />
          {!fromYappApp && process.env.REACT_APP_HIDE_MENU !== 'TRUE' && (
            <div className="new-footer">
              {' '}
              <NewFooter />{' '}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Wrapper
