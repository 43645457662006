import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MyShoppingCardOrder from '../../Components/Card/MyShoppingCardOrder'
import { formatDate, setPaymentLogo } from '../../Utils/utils'
import { pharmacyChainUrl } from '../../Utils/constants'
import { updateSubscription } from '../../Utils/api'
import { _formatPrice } from '../../Utils/tools'

import ButtonYapp from '../../Components/Buttons/ButtonYapp'
import ModalSuscription from '../../Components/Modals/ModalSuscription'
import ModalSuscriptionSurvey from '../../Components/Modals/ModalSuscriptionSurvey'
import Toasts from '../../Components/Toasts/Toasts'
import loadingYapp from '../../assets/img/yapp_loading.gif'
import { Viewer, Worker } from '@react-pdf-viewer/core'

const _ = require('lodash')

function MyShoppingDetail() {
  const history = useHistory()
  const location = useLocation()
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [prescriptions, setPrescriptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let orders = []
    let prescriptionList = []
    if (location.state) {
      orders = _.orderBy(location.state.purchase_orders, ['id'], ['desc'])
      prescriptionList = location.state.prescriptions
    }

    setPurchaseOrders(orders)
    setPrescriptions(prescriptionList)
    setLoading(false)
  }, [])

  const showConfirmationModal = (type) => {
    setModalType(type)
    setShowModal(true)
  }

  const editSubscription = async (newData, option, confirmed = false) => {
    try {
      let data = newData
        ? newData
        : Object.assign({}, location.state)

      setShowModal(false)
      setModalType(null)

      if (option === 'cancel' && !confirmed) {
        setShowSurvey(true)
      }
      else if (option === 'pause' || option === 'active') {
        confirmed = true
      }

      if (confirmed && (option === 'cancel' || option === 'active' || option === 'pause')) {
        setLoading(true)
        let subscription = buildSubscriptionData(data, option)
        const result = await updateSubscription(subscription)
        setLoading(false)

        if (result.message === 'success') {
          data.status = subscription.status
          history.replace({ pathname: `/my_shopping_detail/${data.id}`}, data)
          setShowSuccess(true)
        }
        else {
          setShowError(true)
        }
      }
    }
    catch (err) {
      setShowError(true)
    }
  }

  const buildSubscriptionData = (data, option) => {
    let subscription = null
    let newStatus = null

    if (option === 'active') {
      newStatus = 'Activo'
    }
    else if (option === 'cancel') {
      newStatus = 'Cancelado'
    }
    else if (option === 'pause') {
      newStatus = 'Inactivo'
    }

    try{
      let frequency = Number((data.delivery_frequency.replace('cada ', '')).replace(' días', ''))

      subscription = {
        database: data.id > 3000 ? 'new' : 'old',
        subscriptionId: data.id,
        frequency: frequency,
        paymentId: data.payment_card,
        nextDelivery: data.delivery_date,
        phone: data.phone,
        status: newStatus,
        updatedBy: 'User in webapp'
      }
    }
    catch (err) {
      setShowError(true)
    }

    return subscription
  }

  const onPurchaseOrder = (item) => {
    let colorStatus = 'success'
    switch (item?.orderStatus) {
      case 'Rechazado':
      case 'Cancelado':
        colorStatus = 'warning'
        break

      default:
        break
    }
    item = {
      id: item?.orderId,
      img: pharmacyChainUrl + item?.pharmacyChainLogo,
      title: item?.pharmacyChainName,
      product:
        item?.orderQuantity +
        (item?.orderQuantity > 1 ? ' productos' : ' producto'),
      state_value: item?.orderStatusText,
      status: item?.orderStatus,
      state: colorStatus,
      date: formatDate(new Date(item?.orderDeliveryDate)),
      delivery_frequency: null,
      agreement_number: 'Nº order de compra: ' + item?.orderId,
      type: 'oc',
      total: item?.orderTotal,
      phone: item?.phone,
      address: item?.address,
      reject_status: item?.reject_status,
      reject_message: item?.reject_message,
    }
    history.push(`/my_shopping_tracking/${item.id}`, item)
  }

  const onEdit = () => {
    const subscriptionItem = location.state
    history.push(`/my_shopping_detail/${subscriptionItem.id}/edit`, subscriptionItem)
  }

  const onGoBack = () => {
    history.replace(`/my_shopping`)
  }

  return (
    loading
      ? <div className="d-flex justify-content-center w-100 py-5">
          <img src={loadingYapp} alt="loading" height={70} />
        </div>
      : <>
          <div className="d-flex justify-content-between py-3 px-3">
            <div
              className="mr-auto breadcrumbs c-pointer"
              onClick={ onGoBack }>
              <a className="d-flex align-items-center">
                <i className="icon-chevron-right icon-reverse color-lilac"></i>
              </a>
              <span className="title-primary--regular line-14 color-lilac">
                Volver
              </span>
            </div>
          </div>
          <div className="flex-grow-1 px-3 pb-4">
            <div className="content-responsive mx-auto h-100">
              <div className="content-msd-wrapper">
                <div className="content-msd-left">
                  <div className="mb-3">
                    <h3 className="txt-2--bold-db m-0">
                      { location.state?.type === 'convenio'
                        ? 'Convenio '
                        : 'Programación '}{' '}
                      nº {location.state?.id}
                      { location.state?.can_be_edited &&
                        <div className="title-primary--regular color-lilac d-inline line-16 c-pointer ml-2 m-0" onClick={ onEdit }>
                          Editar
                        </div>
                      }
                    </h3>

                    <p className="txt-6--bold-db m-0">
                      Estado: {location.state?.status}
                    </p>
                  </div>
                  <div className="d-flex flex-column flex-lg-row w-100">
                    <div className="w-100 w-mlg-50">
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">Tu pedido</h6>
                        {location.state?.products?.map((p, key) => (
                          <p key={key} className="txt-4--bold-db m-0 ">
                            {p.quantity + ' x ' + p.name}
                          </p>
                        ))}
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">
                          Nombre y apellido
                        </h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.client_name}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">RUT</h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.identity}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">N° de contacto</h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.phone}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">
                          Dirección de entrega
                        </h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.address}
                        </p>
                      </div>
                      {/* <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">
                          Nº depto o Nº casa (opcional)
                        </h6>
                        <p className="txt-4--regular-db m-0 ">Casa 3B</p>
                      </div> */}
                    </div>
                    <div className="w-100 w-lg-50">
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">
                          Frecuencia de entrega
                        </h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.delivery_frequency}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">
                          Horario de entrega
                        </h6>
                        <p className="txt-4--regular-db m-0 ">
                          {location.state?.delivery_hours}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h6 className="txt-6--bold-g1 m-0 mb-1">Método de pago</h6>
                        <div className="d-flex align-items-center">
                          <img
                            alt={'Logo de tarjeta'}
                            style={{ background: '#ccc' }}
                            src={setPaymentLogo(location.state?.payment_type)}
                            height="26"
                            width="42"
                          />
                          <p className="txt-4--regular-db m-0 ml-2">
                            {location.state?.payment_card}
                          </p>
                        </div>
                      </div>
                      { prescriptions?.length > 0 && (
                        <div className="mb-3">  
                          <h6 className="txt-6--bold-g1 m-0 mb-1">
                            Recetas médicas
                          </h6>
                          <div className="d-flex flex-wrap">
                          
                          <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
                            { prescriptions.map((item, key) => (
                              <div className="mb-2 mt-1 mr-2" key={key.toString()}>
                                {(item.toLowerCase().includes('.pdf')) ? (
                                  <div 
                                    key={key}
                                    className='prescription-min-sm'
                                    > 
                                    <Viewer
                                      fileUrl={item}
                                      defaultScale={0.06}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    key={key}
                                    style={{ background: '#ccc' }}
                                    src={ item }
                                    alt=""
                                    height="49"
                                    width="34"
                                  />
                                )}

                                {/* <img
                                  key={key}
                                  style={{ background: '#ccc' }}
                                  src={ item }
                                  alt=""
                                  height="49"
                                  width="34"
                                /> */}
                              </div>
                            ))}
                          </Worker>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  { location.state?.status === 'Activo' &&
                    <div className="content-btn d-flex flex-wrap flex-lg-nowrap align-items-center mt-4">
                      <ButtonYapp
                        onClick={() => showConfirmationModal('pause')}
                        className="w-100 mr-md-3 mb-3"
                        stroke
                        title="Pausar suscripción"
                      />
                      <ButtonYapp
                        onClick={() => showConfirmationModal('cancel')}
                        className="w-100 mb-3 mr-md-3"
                        color={'#DB6580'}
                        stroke
                        title="Cancelar suscripción"
                      />
                    </div>
                  }

                  { location.state?.status !== 'Activo' &&
                    <div className="content-btn d-flex flex-wrap flex-lg-nowrap align-items-center mt-4 maxw-250 mr-auto">
                      <ButtonYapp
                        onClick={() => showConfirmationModal('active')}
                        className="w-100"
                        stroke
                        title="Activar suscripción"
                      />
                    </div>
                  }
                </div>
                { purchaseOrders?.length > 0 ? (
                  <div className="content-msd-right">
                    <h3 className="txt-2--bold-db m-0 mb-3">Próxima compra</h3>
                    <div className="mb-2">
                      <MyShoppingCardOrder
                        className="mb-2"
                        doAction={() => onPurchaseOrder(purchaseOrders[0])}
                        data={purchaseOrders[0]}
                      />
                    </div>
                    {purchaseOrders?.length > 1 && (
                      <>
                        <h3 className="txt-2--bold-db m-0 mb-3">
                          Compras anteriores
                        </h3>
                        <div>
                          {purchaseOrders
                            .slice(1, purchaseOrders.length)
                            .map((item, key) => {
                              return (
                                <MyShoppingCardOrder
                                  key={key}
                                  doAction={() => onPurchaseOrder(item)}
                                  className="mb-2"
                                  data={item}
                                />
                              )
                            })}
                        </div>
                      </>
                    )}{' '}
                  </div>
                ) : (
                  <div className="content-msd-right">
                    <h3 className="txt-2--bold-db m-0 mb-3">Próxima compra</h3>
                    <div className="mb-2">
                      <MyShoppingCardOrder
                        className="mb-2"
                        doAction={() => onPurchaseOrder(purchaseOrders[0])}
                        data={{
                          noData: true,
                          orderDeliveryDate: location.state?.date,
                          orderTotal: location.state?.total,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              { showModal &&
                <ModalSuscription
                  type={ modalType }
                  showModal={ showModal }
                  setShowModal={ (show) => setShowModal(show) }
                  nextStep={ (newData = null) => editSubscription(newData, modalType) }
                  subscriptionData={ location.state }
                />
              }

              <ModalSuscriptionSurvey
                subscriptionId={ location.state?.id }
                showSurvey={ showSurvey }
                setShowSurvey={ setShowSurvey }
                surveySent={ (sent) => editSubscription(null, 'cancel', sent) }
              />

              <Toasts
                text="Cambios guardados exitosamente"
                timer="3000"
                icon="icon-check"
                stateToasts={ showSuccess }
                setStateToasts={ setShowSuccess }
              />

              <Toasts
                text="Ocurrio un error vuelve a intentarlo"
                timer="3000"
                icon="icon-alert"
                color="var(--color-error-ds)"
                stateToasts={ showError }
                setStateToasts={ setShowError }
              />

            </div>
          </div>
        </>
  )
}

export default MyShoppingDetail
