import React, { useState } from 'react'
import Modal from 'react-modal'
import ButtonYapp from '../Buttons/ButtonYapp'
import ContextAwareToggle from '../AccordionSelectProduct/AccordionSelectProduct'

function ModalTeledocSelectProduct(props) {
  const { visible, setVisible, alternatives, setAlternatives, updateShoppingCart } = props
  const [showErrors, setShowErrors] = useState(false)

  const closeAlternatives = async () => {
    if (!(alternatives.some((item) => item.selectedProduct === null))) {
      updateShoppingCart()
      setVisible(false)
    }
    else {
      setShowErrors(true)
    }
  }

  return (
    <>
      <Modal
        isOpen={visible}
        onAfterOpen={null}
        onRequestClose={null}
        className="modal-custom modal-custom-teldoc"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalReported"
        ariaHideApp={false}>
        <div className="content-modal-vh">
          <div className="content-header-scroll">
            <p className="txt-3--light-db mb-3 mx-3">
              Selecciona la/s opción/es de medicamentos que te gustaría cotizar
              para:
            </p>
            <hr className="hr--gray w-100" />
          </div>
          <div className="content-middle-modal">
            <div className="">
              <ContextAwareToggle
                alternatives={ alternatives } 
                setAlternatives={ setAlternatives }
                showErrors={ showErrors }
              />
            </div>
          </div>
          <div className="footer-modal-scroll ">
            <hr className="hr--gray w-100 mb-3 pt-2" />
            <ButtonYapp
              id={`gtm-webapp-teledoct-add-card-btn`}
              className="maxw-214 w-100 mx-auto"
              color={'--theme-color'}
              onClick={() => closeAlternatives()}
              title={'Agregar al carro'}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalTeledocSelectProduct
