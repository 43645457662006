

import { useEffect, useState } from 'react'

function InsuranceBenefits(props) {
  const [clientName, setClientName] = useState();
  const [check, setCheck] = useState();
  const [checkBg, setCheckBg] = useState();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
   if (props) {
      setClientName(props.clientName);
      setCheck(props.check);
      setCheckBg(props.checkBg);
      setIsMobile(props.isMobile);
    } 
  })
  
  return (
    <>
      <div className={`text-center ${isMobile ? 'maxw-250' : 'maxw-400'} mx-auto mt-5`}>
        <h2 className="title-home-sura mb-2 mb-2">
          Disfruta de estos beneficios
        </h2>
        <hr className='hr-separation'></hr>
      </div>
      <div className="maxw-800 mx-auto mt-4 px-3">
        { clientName === 'Bice Vida' && (
            <>
              <div className='mb-3'>
                <div className='d-inline-block check-item'>
                  <i className="fa fa-check color-theme-secondary mr-2" />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Reembolso en línea
                </p>
              </div>
              <div className='mb-3'>
                <div className='d-inline-block check-item'>
                  <i className="fa fa-check color-theme-secondary mr-2" />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Compara precios en diferentes farmacias
                </p>
              </div>
              <div className=''>
                <div className='d-inline-block check-item'>
                  <i className="fa fa-check color-theme-secondary mr-2" />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Con despacho a domicilio
                </p>
              </div>
            </>
          )
        }
        { clientName === 'Vida Security' && (
            <>
              <div className='mb-3'>
                <div className='d-inline-block check-item'>
                  <img className='mr-2' src={check} alt='' />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Reembolso en línea
                </p>
              </div>
              <div className='mb-3'>
                <div className='d-inline-block check-item'>
                  <img className='mr-2' src={check} alt='' />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Compara precios en diferentes farmacias
                </p>
              </div>
              <div className=''>
                <div className='d-inline-block check-item'>
                  <img className='mr-2' src={check} alt='' />
                </div>
                <p className="txt-xl-subtitle mb-3 d-inline">
                  Envío 50% gratis
                </p>
              </div>
            </>
          )
        }
        { clientName === 'Sura' && (
            <div className={`d-flex ${!isMobile ? 'justify-content-between' : 'flex-column align-items-center'}  mt-5 mb-5`}>
              <div className='info-card mb-3'>
                <h3 className='title-home-lg-sura mb-4'>Como asegurado Seguros Colectivos SURA:</h3>
                <p className="text-home-light mb-2">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Comparar precio online
                </p>
                <p className="text-home-light mb-2">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Elegir la farmacia con mejor precio
                </p>
                <p className="text-home-light mb-3">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Reembolsar 100% en línea
                </p>
                <p className="text-home-light">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Envío gratuito de compra con receta médica
                </p>
              </div>
              <div className='info-card mb-3'>
                <h3 className='title-home-lg-sura mb-4'>Si tienes cualquier otro seguro en Seguros SURA:</h3>
                <p className="text-home-light mb-2">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Comparar precio online
                </p>
                <p className="text-home-light mb-2">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Elegir la farmacia con mejor precio
                </p>
                <p className="text-home-light">
                  <img className='mr-2 pb-1' src={checkBg} alt='' />
                  Envío gratuito de compra con receta médica
                </p>
              </div>
            </div>
          )
        }
        
      </div>
    </>
  )
}

export default InsuranceBenefits