import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import CardInteracion from '../../Components/CardInteraction/CardInteracion'
import {_formatPrice} from '../../Utils/tools'

import productImg from '../../assets/img/prescription.png'
import {logEvent} from '../../Utils/utils'

function PriceFinderResult() {
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const bio = history.location.state.bio
  const path = history.location.state.path

  useEffect(() => {
    setLoading(false)
  }, [])

  function goBack() {
    history.goBack()
  }

  const onClickOption = (productId, product) => {
    let currPath = path.split('/')[2]

    logEvent(
      currPath === 'price_finder_detail'
        ? 'Price Finder Checked'
        : 'Medication Sheet Checked',
      {
        Origin: 'Bioequivalent',
        'Product Id': productId,
        'Product Name': product?.product_name,
        'Lab Name': product?.laboratory_name,
      },
    )
    history.push(`/pro/medication-sheet/${productId}`, {
      isProduct: product.product_id,
      product: product,
    })
  }

  return (
    <>
      {process.env.REACT_APP_CLIENT_NAME !== 'UC Christus' && (
        <div className="py-3 px-3">
        <div className="content-responsive mx-auto d-flex justify-content-between py-1 px-0 px-md-0">
          <div className="breadcrumbs c-pointer" onClick={goBack}>
            <a className="d-flex align-items-center">
              <i className="icon-chevron-right icon-reverse"></i>
            </a>
            <span className="txt-4--regular-pr">Volver</span>
          </div>
        </div>
      </div>
      )}
      <div className="flex-grow-1">
        <div className="content-responsive mx-auto">
          <div className="py-4 px-3 px-md-0">
            <h2 className="title-primary--xl-bold mb-3">Alternativas similares</h2>
            <div className="content-card-product">
              {bio?.map((item, key) => (
                <CardInteracion
                  key={key}
                  id={`gtm-webapp-bioequivalent-pro-result-${key}`}
                  doAction={() => onClickOption(item?.product_id, item)}>
                  <div className="content-img-medication-sheet">
                    <img
                      src={item?.product_logo ? item?.product_logo : productImg}
                      alt={`nombre del producto`}
                      className="img-mw-65"
                    />
                  </div>
                  <div className="ml-14 overflow-hidden">
                    <h2 className="title-primary--xl-bold mb-1 line-16 pr-2 text-ellipsis--2 ">
                      {item?.product_name}{' '}
                    </h2>
                    <p className="txt-paragraph m-0 line-14 text-ellipsis--2 ">
                      {item?.formula_name}
                    </p>
                    <p className="txt-paragraph m-0 line-14 text-ellipsis--2 ">
                      {item?.presentation}
                    </p>
                    <p className="txt-paragraph m-0 line-14">
                      {item?.laboratory_name}
                    </p>
                    {['/pro/price_finder_detail'].includes(path) && (
                      <p className="txt-paragraph txt-bold mb-1">
                        Desde: {_formatPrice(item.price_from)}
                      </p>
                    )}
                  </div>
                </CardInteracion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceFinderResult
