import React from 'react'
import Modal from 'react-modal'

function ModalTypeForms(props) {
  const {modalState, setModalState, url} = props

  function closeModalTypeFormsHome() {
    setModalState(false)
  }

  function onFocus() {}

  return (
    <>
      <Modal
        isOpen={modalState}
        onAfterOpen={null}
        onRequestClose={closeModalTypeFormsHome}
        className="modal-type-forms"
        overlayClassName="modal-custom-bg"
        contentLabel="ModalTypeFormsHome"
        ariaHideApp={false}>
        {props.btnClose && (
          <button
            className="btn-circle-close"
            onClick={closeModalTypeFormsHome}>
            <i className="icon-close"></i>
          </button>
        )}
        <div>{props.children}</div>
      </Modal>
    </>
  )
}

export default ModalTypeForms
