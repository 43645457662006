import React from 'react';
import { buttonMenuUC, menuUC, subMenuUC } from './constants';

const webHost = 'https://www.ucchristus.cl/';

function DesktopMenu() {

  const menuItem = (item) => {
    return (
      <a className="nav-link uc-megamenu-dropdown py-3"
        href={item.link ? (webHost + item.link) : "#"}
        id="navbarDropdown"
        role="button"
        data-mdb-toggle="dropdown"
        aria-expanded="false">
        { item.title }
        { !item.link &&
          <em className="ml-2 fa fa-chevron-down fa-1x" aria-hidden="true"></em>
        }
      </a>
    )
  }

  const subMenuItem = (data) => {
    return (
      <>
        <span className="uc-megamenu-group-title">{data.title}</span>
        <ul className="uc-list">
          { data.items.map((option, key) => (
            option.type !== 'button' &&
            <li key={`${data.title}${key}`}>
              <a href={`${(option.host || webHost)}${option.link}`} className="uc-text">{option.text}</a>
            </li>
          ))}
        </ul>

        { data.items.map((option, key) => (
          option.type === 'button' &&
          <div className='mt-3' key={key}>
            <a href={option.link ? (webHost + option.link) : "#"} className="uc-megamenu-btn">
              {option.text}
            </a>
          </div>
        ))}
      </>
    )
  }

  return (
    <section>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container-fluid px-0">
          <div className="collapse navbar-collapse uc-megamenu-container px-1">
            <ul className="navbar-nav me-auto ps-lg-0 w-100 uc-megamenu-dropdown-container">
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.information)}
                <div
                  className="dropdown-menu w-100 mt-0 uc-megamenu-dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                  <div className="uc-megamenu w-100">
                    <div className="row my-4">
                      <div className="col-4">
                        {subMenuItem(subMenuUC.information)} <br/>
                        {subMenuItem(subMenuUC.ucchristus)}
                      </div>
                      <div className="col-4 uc-megamenu-border-right uc-megamenu-border-left">
                        {subMenuItem(subMenuUC.indicators)} <br/>
                        {subMenuItem(subMenuUC.connect)}
                      </div>
                      <div className="col-4">
                        {subMenuItem(subMenuUC.clinicalServices)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.specialities)}
                <div
                  className="dropdown-menu uc-megamenu-dropdown-menu w-100 mt-0"
                  aria-labelledby="navbarDropdown"
                  style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                  <div className="uc-megamenu w-100">
                    <div className="row my-4">
                      <div className="col-4">
                        {subMenuItem(subMenuUC.professionals)}
                      </div>
                      <div className="col-4 uc-megamenu-border-left">
                        {subMenuItem(subMenuUC.programs)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.procedures)}
                <div
                  className="dropdown-menu uc-megamenu-dropdown-menu w-100 mt-0"
                  aria-labelledby="navbarDropdown"
                  style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                  <div className="uc-megamenu w-100">
                    <div className="row my-4">
                      <div className="col-4">
                        {subMenuItem(subMenuUC.procedures)}
                      </div>
                      <div className="col-4 uc-megamenu-border-left">
                        {subMenuItem(subMenuUC.results)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.careNetwork)}
                <div
                  className="dropdown-menu w-100 mt-0 uc-megamenu-dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                  <div className="uc-megamenu w-100">
                    <div className="row my-4">
                      <div className="col-4">
                        {subMenuItem(subMenuUC.hospitals)} <br/>
                        {subMenuItem(subMenuUC.laboratory)}
                      </div>
                      <div className="col-4 uc-megamenu-border-left uc-megamenu-border-right">
                        {subMenuItem(subMenuUC.medicalCenters)} <br/>
                        {subMenuItem(subMenuUC.regionalMedicalCenters)}
                      </div>
                      <div className="col-4">
                        {subMenuItem(subMenuUC.cesfam)} <br/>
                        {subMenuItem(subMenuUC.virtualAssitant)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.agreements)}
                <div
                  className="dropdown-menu uc-megamenu-dropdown-menu w-100 mt-0"
                  aria-labelledby="navbarDropdown"
                  style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                  <div className="uc-megamenu w-100">
                    <div className="row my-4">
                      <div className="col-4">
                        {subMenuItem(subMenuUC.agreement)}
                      </div>
                      <div className="col-4 uc-megamenu-border-left">
                        {subMenuItem(subMenuUC.duty)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item uc-megamenu-nav-item dropdown dropdown-hover position-static">
                {menuItem(menuUC.healthPlan)}
              </li>
              <li
                className="nav-item uc-megamenu-nav-item dropdown dropdown-hover"
                style={{width: '175px !important'}}>
                <a
                  href="https://www.ucchristus.cl/seguro-complementario-santander/home"
                  className="uc-santander-nav-btn"
                  sfref="[f669d9a7-009d-4d83-ddaa-000000000002]BCEB3FDA-5C7D-438C-8FCA-D4E233EB99A4">
                  <img src="https://d328k6xhl3lmif.cloudfront.net/docs/default-source/svg/logo-seguro-complementario-link.svg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  )
}

export default DesktopMenu;