import React from 'react'

function ButtonShare(props) {
  return (
    <>
      <div
        className={`text-center ${props.className} ${
          props.lite ? 'content-btn-square-share-lite' : ''
        }`}>
        <button
          className={`btn-square-share ripple ${
            props.lite ? 'btn-square-share-lite' : ''
          }`}
          onClick={props.onClick}>
          {props.icon && (
            <i className={`icon-btn-square-share ${props.icon}`}></i>
          )}
        </button>
        {props.title && (
          <h6
            className={`txt-paragraph--small color-lilac text-btn-square-share 
            ${props.lite ? 'mt-1' : 'mt-2'} `}>
            {props.title}
          </h6>
        )}
      </div>
    </>
  )
}

export default ButtonShare
